<app-index-container label="{{ 'localities' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.ADD_LOCALITY_LOOKUP)"
    mat-raised-button
    color="primary"
    (click)="addLocalityDialog()"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addLocality' | translate }}
  </button>
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>{{ 'country' | translate }}:</mat-label>
        <mat-select
          #countryId
          (selectionChange)="onCountryChange($event.value.id)"
        >
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>{{ 'city' | translate }}:</mat-label>
        <mat-select #cityId formControlName="city">
          <mat-option *ngFor="let city of localityCities" [value]="city">
            {{ city.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-md-4">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <!-- City Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef>{{ 'city' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.parentName }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_LOCALITY_LOOKUP,
                permissions.VIEW_LOCALITY_LOOKUP
              ])
            "
            color="primary"
            (click)="addLocalityDialog(row.parentId, row.id)"
          >
            <mat-icon>edit</mat-icon>{{ 'edit' | translate }}
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
