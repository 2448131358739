<app-index-container [label]="'complianceReport' | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
<!--      <mat-form-field appearance="outline" class="col">-->
<!--        <mat-label>{{ 'branch' | translate }}:</mat-label>-->
<!--        <mat-select #branchId formControlName="branch">-->
<!--          <mat-option *ngIf="branches.length > 1" [value]="allOption">-->
<!--            {{ allOption.code + '-' + allOption.title }}-->
<!--          </mat-option>-->
<!--          <mat-option *ngFor="let branch of branches" [value]="branch">-->
<!--            {{ branch.code + '-' + branch.title }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->

      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'startDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          formControlName="startDate"
          [value]="fromDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #startDate></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'endDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          formControlName="endDate"
          [value]="toDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #endDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button mat-raised-button type="submit" color="primary">
        <i class="fa-solid fa-magnifying-glass"></i>
        {{ 'search' | translate }}
      </button>
    </div>
  </form>

  <table
    table
    matTableExporter
    mat-table
    [dataSource]="data"
    #exporter="matTableExporter"
    class="table"
  >
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'date' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ convertDateToLocaleString(row.date) }}
      </td>
    </ng-container>
    <!-- numberOfRemittances Column -->
    <ng-container matColumnDef="numberOfRemittances">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'numberOfRemittances' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span
          style="color: #0074d9; cursor: pointer"
          (click)="displayComplianceReportDetail(row?.transactionIds)"
        >
          {{ row.numberOfRemittances }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
