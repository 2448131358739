import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LookupDto } from '../_common/data/dto/api.dto';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  MonthlyCommissionFilter,
  ReportsService,
} from './data/reports.service';
import { MonthlyCommissionsDto } from './data/dto/monthlyCommission.dto';
import { SystemBranchCodes } from '../_common/enums/systemBranches.enums';
import { Utilities } from '../_common/utils';
import {SnackBarService} from "../_common/snackBar.service";

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-monthly-commission',
  templateUrl: './monthlyCommission.component.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MonthlyCommissionsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: FormGroup;
  date = new UntypedFormControl(moment(), [Validators.required]);
  dataThere: boolean = false;

  permissions = Permissions.reportManagement.MONTHLY_COMMISSIONS_REPORT;
  loading = false;
  resultsLength = 0;
  settlementTypes: LookupDto[] = [];
  statuses: LookupDto[] = [];
  branches: GetBranchesDto[] = [];
  displayedColumns: string[] = [
    'number',
    'branchCode',
    'commission',
    'date',
    'status',
  ];
  data = new MatTableDataSource<MonthlyCommissionsDto>();

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthService,
    private reportService: ReportsService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ branches, statuses }) => {
      this.branches = [...branches].filter(
        (branch) => branch.code !== SystemBranchCodes.TKCPO,
      );
      this.statuses = statuses.sort((a: LookupDto, b: LookupDto) =>
        a.name.localeCompare(b.name),
      );
    });

    this.form = this.fb.group({
      date: this.date,
      branch: [null, [Validators.required]],
      status: [null, [Validators.required]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const date = moment(this.form.value.date).format('YYYY-MM-DD');
      const { branch, status } = this.form.value;
      const filters: MonthlyCommissionFilter = {};
      filters.date = date;
      filters.branchId = branch.id;
      filters.statusId = status.id;

      this.reportService.getMonthlyCommissions(filters).subscribe((data) => {
        if (data.length) {
          this.dataThere = true;
        }
        this.data.data = data;
        this.data.paginator = this.paginator;
      },({message}) => {
        this.snackBar.open(message);
      });
    }
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>,
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }
}
