import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
  } from '@angular/core';
 
  import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
  } from '@angular/material/dialog';
  import { DomSanitizer } from '@angular/platform-browser';
  import { AttachmentsPopupComponent } from './attachmentsPopup.component';
  
  @Component({
    selector: 'app-manual-journal-attachment',
    templateUrl: './manualJournalDocPopup.component.html',
  })
  export class ManualJournalDocPopupComponent implements OnInit {
    @ViewChild('docFile') docFileElem!: ElementRef;
    docFile?: File;
    docFileUrl: any;
    isFileSelected: boolean = false;
    isPdfFile: boolean = false;
  
    constructor(
      public dialogRef: MatDialogRef<ManualJournalDocPopupComponent>,
      @Inject(MAT_DIALOG_DATA) public data: '',
      private sanitizer: DomSanitizer,
      private dialog: MatDialog,
    ) {}

    ngOnInit() {
       
      }

    onDocAttachmentSelected(e: Event) {
      //@ts-ignore
      const file = e.target.files[0];
      const reader = new FileReader();
      this.docFile = file;
      reader.onload = (event: any) => {
        this.isFileSelected = true;
        this.docFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          event.target.result,
        );
        if (
          this.docFileUrl.changingThisBreaksApplicationSecurity.indexOf(
            'data:application/pdf',
          ) > -1
        ) {
          this.isPdfFile = true;
        }
      };
      reader.readAsDataURL(file);
    }
  
    closeDialog() {
      this.dialogRef.close();
    }
  
    onSubmit() {
      if (this.isFileSelected) {
        this.dialogRef.close({
          docFile: this.docFile,
        });
      }
    }
  
    onViewAttachment(imageURL: string[] | undefined) {
      this.dialog.open(AttachmentsPopupComponent, {
        width: '850px',
        data: { imageURL: [imageURL], isPdfFile: this.isPdfFile },
      });
    }
  }
  