import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { SettlementComponent } from './settlement.component';
import {
  SettlementStatusResolver,
  SettlementTypesResolver,
} from './data/settlement.resolver';
import {
  BeneficiaryBranchesResolver,
  BranchesResolver,
} from '../branch/data/branches.resolver';
import { SettlementDetailsComponent } from './settlementDetails.component';

const {
  settlementManagement: { settlements },
} = Permissions;

const routes: Routes = [
  {
    path: 'settlements',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: Object.values(settlements) },
        resolve: {
          settlementTypes: SettlementTypesResolver,
          settlers: BranchesResolver,
          beneficiaries: BeneficiaryBranchesResolver,
          statuses: SettlementStatusResolver,
        },
        component: SettlementComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        data: { permissions: [settlements.CREATE_SETTLEMENT] },
        resolve: {
          settlementTypes: SettlementTypesResolver,
          settlers: BranchesResolver,
          beneficiaries: BeneficiaryBranchesResolver,
        },
        component: SettlementDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettlementRoutesModule {}
