<app-index-container label="{{ 'Kitework Transactions' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row">
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
                <input matInput #remittanceCode formControlName="remittanceCode" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'Report Reference' | translate }}:</mat-label>
                <input matInput #ReportReference formControlName="ReportReference" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" [value]="fromDate.value" />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'End Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" [value]="toDate.value" />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3">
                <mat-label>{{ 'Flow' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="flow">
                    <mat-radio-button class="material-radio" checked value="Out" style="margin-left: 5px">{{ 'OUT' |
                        translate
                        }}</mat-radio-button>

                    <mat-radio-button class="material-radio" checked value="In" style="margin-left: 5px">{{ 'In' |
                        translate
                        }}</mat-radio-button>

                    <mat-radio-button class="material-radio" value="" style="margin-left: 10px">
                        {{ 'All' | translate }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-12 col-md-6 col-lg-4 mt-2">
                <mat-label>{{ 'BOP Category' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="bopCategory">
                    <div class="radio-group-row d-flex flex-wrap">
                        <mat-radio-button class="material-radio" value="401" style="margin: 5px;">
                            {{ '401 Gifts' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="416" style="margin: 5px;">
                            {{ '416 Migrant Worker Remittances' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="417" style="margin: 5px;">
                            {{ '417 Foreign national contract Worker Remittances' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="400" style="margin: 5px;">
                            {{ '400 Reversals' | translate }}
                        </mat-radio-button>
                        <mat-radio-button class="material-radio" value="" style="margin: 5px; margin-top: 5px;">
                            {{ 'All' | translate }}
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>


        <div class="d-flex justify-content-end">

            <button (click)="exportToExcel()" mat-raised-button type="button" color="accent" style="margin-right: 10px;">
                <i class="fa-solid fa-file-export"></i>
                {{ 'Export' | translate }}
            </button>

            <button mat-raised-button type="submit" color="primary">
                <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
            </button>
        </div>
    </form>
    <table table mat-table [dataSource]="remittances" class="table" id="Kiteworktransactions">
        <!-- Number Column -->
        <ng-container matColumnDef="#">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>

        <!-- Ad Code Column -->
        <ng-container matColumnDef="adCode">
            <th mat-header-cell *matHeaderCellDef>{{ 'Ad Code' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.adCode }}</td>
        </ng-container>
        
        <ng-container matColumnDef="adName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Ad Name' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.adName">{{ row.adName }}</td>
        </ng-container>
        
        <ng-container matColumnDef="reportingQualifier">
            <th mat-header-cell *matHeaderCellDef>{{ 'Reporting Qualifier' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.reportingQualifier }}</td>
        </ng-container>
        
        <ng-container matColumnDef="valueDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Value Date' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ formatDate(row.valueDate) }}</td>
        </ng-container>
        
        <ng-container matColumnDef="flow">
            <th mat-header-cell *matHeaderCellDef>{{ 'Flow' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.flow }}</td>
        </ng-container>
        
        <ng-container matColumnDef="trnReferenceNumber">
            <th mat-header-cell *matHeaderCellDef>{{ 'Trn Reference Number' | translate }}</th>
            <td mat-cell *matCellDef="let row" [matTooltip]="row.trnReferenceNumber">{{ row.trnReferenceNumber }}</td>
        </ng-container>
        
        <ng-container matColumnDef="sequence">
            <th mat-header-cell *matHeaderCellDef>{{ 'Sequence' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.sequence }}</td>
        </ng-container>
        
        <ng-container matColumnDef="subSequence">
            <th mat-header-cell *matHeaderCellDef>{{ 'Sub Sequence' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.subSequence }}</td>
        </ng-container>
        
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ 'Status' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.status }}</td>
        </ng-container>
        
        <ng-container matColumnDef="residentName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Resident Name' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.residentName }}</td>
        </ng-container>
        
        <ng-container matColumnDef="residentSurname">
            <th mat-header-cell *matHeaderCellDef>{{ 'Resident Surname' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.residentSurname }}</td>
        </ng-container>
        
        <ng-container matColumnDef="currency">
            <th mat-header-cell *matHeaderCellDef>{{ 'Currency' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.currency }}</td>
        </ng-container>
        
        <ng-container matColumnDef="randAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'Rand Amount' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.randAmount }}</td>
        </ng-container>
        
        <ng-container matColumnDef="foreignAmount">
            <th mat-header-cell *matHeaderCellDef>{{ 'Foreign Amount' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.foreignAmount }}</td>
        </ng-container>
        
        <ng-container matColumnDef="bopCategory">
            <th mat-header-cell *matHeaderCellDef>{{ 'Bop Category' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.bopCategory }}</td>
        </ng-container>
        
        <ng-container matColumnDef="subBopCategory">
            <th mat-header-cell *matHeaderCellDef>{{ 'SubBop Category' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.subBopCategory }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                {{ 'noRecordsAvailable' | translate }}
            </td>
        </tr>
    </table>


    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>