import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-finsurv-modification-change-log',
  templateUrl: './finsurvModificationChangeLogPopup.component.html',
  styleUrls: [],
})
export class FinsurvModificationChangeLogPopupComponent {
  Approved = '';

  constructor(
    public dialogRef: MatDialogRef<FinsurvModificationChangeLogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.data = JSON.parse(this.data);
  }
}
