<app-index-container label="{{ 'customerReport' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'startDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          formControlName="startDate"
          [value]="fromDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'endDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          formControlName="endDate"
          [value]="toDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #endDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</app-index-container>
