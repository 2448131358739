import { Component, HostBinding, Input } from '@angular/core';
import { Permissions } from '../../enums/permissions.enums';
import { AuthService } from 'src/app/auth/data/auth.service';
import { UserTypes } from '../../enums/userTypes.enums';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.less'],
  // host: {
  //   id: 'sidebarMenu',
  //   class: 'col-12 col-md-3 col-lg-2 d-md-block collapse'
  // }
})
export class SidenavComponent {
  @HostBinding('id') id: string = 'sidebarMenu';
  @HostBinding('class') class: string =
    'col-12 col-md-3 col-lg-2 d-md-block collapse';
  @Input() userPermissions: string[] = [];

  isReferal: boolean = false;
  constructor(
    public authService: AuthService,
  ) { 
    this.isReferal = this.authService.currentUserValue?.userTypeName === UserTypes.REFERAL;
  }

  canActivate(menu: string, subMenu: string = ''): boolean {
    // Extract only the active menu permissions
    const activePermissions = Object.entries(Permissions)
      .filter(([, value]) => value.isActive)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {} as Record<string, any>);
  
    const menuPermissions = activePermissions[menu];
  
    if (!menuPermissions) {
      return false;
    }
  
    const requiredPermissions: string[] = subMenu
      ? Object.values(menuPermissions[subMenu] || {}) as string[]
      : Object.keys(menuPermissions).reduce(
          (rp: string[], sm) => [
            ...rp,
            ...(Object.values(menuPermissions[sm] || {}) as string[]),
          ],
          [] as string[],
        );
  
    return requiredPermissions.some((p) => this.userPermissions.includes(p));
  }
}
