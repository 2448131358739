<h1 mat-dialog-title>{{ 'mobileOwnershipVerification' | translate }}</h1>
<p>{{ 'mobileOwnerShipMessage' | translate }}</p>
<div mat-dialog-content>
  <div *ngIf="data.customerWithSameMobile.length > 0">
    <div class="row" style="margin: 10px">
      <table class="table table-bordered table-hover">
        <thead>
        <tr>
          <th>{{ '#' | translate }}</th>
          <th>{{ 'fullname' | translate }}</th>
          <th>{{ 'mobile' | translate }}</th>
        </tr>
        </thead>
        <tbody *ngIf="data.customerWithSameMobile.length">
        <tr *ngFor="let customer of getDuplicateCustomers; let i = index">
          <td>{{ i + 1 }}</td>
          <td class="text-nowrap">{{ getFullName(customer.firstName, customer.middleName, customer.lastName) }}</td>
          <td class="text-nowrap">{{ customer.mobilePhone }}</td>
        </tr>
        </tbody>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5]"
        [pageSize]="pageSize"
        [length]="data.customerWithSameMobile.length"
        (page)="onPageChange($event)"
      ></mat-paginator>

    </div>
  </div>

  <div *ngIf="showVerificationSection" class="m-2" style="display: flex; justify-content: center;">
    <mat-form-field>
      <mat-label>{{ 'enterOtpSentToCustomerMobile' | translate }}:</mat-label>
      <input matInput placeholder="{{ 'otp' | translate }}" [(ngModel)]="otp" name="otp" required />
      <mat-error *ngIf="!otp">{{ 'otpIsRequired' | translate }}</mat-error>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end mt-1">
  <button
    *ngIf="showVerificationSection"
    mat-raised-button
    color="primary"
    [disabled]="!otp"
    (click)="verifyOTP()"
  >
    {{ 'continue' | translate }}
  </button>
  <button
    *ngIf="showVerifyMobileBtn"
    mat-raised-button
    color="primary"
    (click)="onVerifyMobile(data?.mobilePhone)"
  >
    Verify Mobile
  </button>
  <button
    mat-raised-button
    mat-dialog-close="close"
    class="ms-1"
    color="secondary"
  >
    {{ 'close' | translate }}
  </button>
</div>
