import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { GetOtpTypes, UserDto } from './dto/user/users.dto';
import { BranchesService } from '../../branch/data/branches.service';
import { GetBranchesDto } from '../../branch/data/dto/branches.dto';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';

@Injectable()
export class UserByIdResolver implements Resolve<any> {
  constructor(private usersService: UsersService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<UserDto> {
    const { userId } = route.params;
    return this.usersService.getUserById(userId);
  }
}

@Injectable()
export class BranchesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<GetBranchesDto[]> {
    return this.branchesService.getBranches();
  }
}

@Injectable()
export class OtpTypesResolver implements Resolve<any> {
  constructor(private usersService: UsersService) {}
  resolve(): Observable<GetOtpTypes[]> {
    return this.usersService.getOtpTypes();
  }
}


@Injectable()
export class LoginHistoryStatusesResolver implements Resolve<any> {
  constructor(private usersService: UsersService) {}
  resolve(): Observable<LookupDto[]> {
    return this.usersService.getLoginHistoryStatus();
  }
}
