import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../_common/enums/permissions.enums';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AuthService } from '../auth/data/auth.service';
import { BranchesService } from '../branch/data/branches.service';
import { SettlementService } from './data/settlement.service';
import { CreateSettlementDto } from './data/dto/settlement.dto';
import {
  GetBranchCurrencyDto,
  SearchBranchesDto,
} from '../branch/data/dto/branches.dto';
import { SettlementAddReceiptPopupComponent } from './settlementAddReceiptPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { regExp } from '../_common/data/validationRules';
import { forkJoin } from 'rxjs';
import { setDecimals } from '../_common/utils';

@Component({
  selector: 'app-settlement-details',
  templateUrl: './settlementDetails.component.html',
})
export class SettlementDetailsComponent implements OnInit {
  form!: FormGroup;

  permissions = Permissions.settlementManagement.settlements;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;

  settlementTypes: LookupDto[] = [];
  settlers: SearchBranchesDto[] = [];
  beneficiaries: SearchBranchesDto[] = [];
  accounts: LookupDto[] = [];
  exchangeRates: GetBranchCurrencyDto[] = [];
  title = '';
  accountCurrency: string = '';
  settlerExchangeRate?: number = undefined;
  amount?: number = undefined;
  branchCurrency: string = '';
  settlerBaseCurrencyRate?: number = undefined;
  settlerBaseCurrencyAmount?: number = undefined;

  constructor(
    private fb: FormBuilder,
    private settlementService: SettlementService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
    public branchService: BranchesService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({ settlementTypes, settlers, beneficiaries }) => {
        this.settlementTypes = settlementTypes;
        this.settlers = settlers;
        this.beneficiaries = beneficiaries;

        this.form = this.fb.group({
          type: [null, [Validators.required]],
          settler: [null, [Validators.required]],
          beneficiary: [null, [Validators.required]],
          account: [null, [Validators.required]],
          description: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(200),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          settlerGlAmount: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          settlerExchangeRate: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          amount: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          settlerBaseCurrencyAmount: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          turkishExchangeRate: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ]
        });
      },
    );

    this.form.controls['settlerGlAmount'].valueChanges.subscribe((value) => {
      this.amount = undefined;
      this.settlerBaseCurrencyAmount = undefined;
      this.form.get('amount')?.setValue(this.amount);
      this.form
        .get('settlerBaseCurrencyAmount')
        ?.setValue(this.settlerBaseCurrencyAmount);

      if (this.settlerExchangeRate && this.settlerBaseCurrencyRate) {
        const amount = value / this.settlerExchangeRate;
        this.amount = setDecimals(amount);
        this.settlerBaseCurrencyAmount = setDecimals(
          amount * this.settlerBaseCurrencyRate,
        );
        this.form.get('amount')?.setValue(this.amount);
        this.form
          .get('settlerBaseCurrencyAmount')
          ?.setValue(this.settlerBaseCurrencyAmount);
      }
    });
  }

  onSettlerBranchChange(settlerId: number) {
    this.resetAmounts();

    forkJoin([
      this.branchService.getBranchAccounts(settlerId),
      this.branchService.getBranchExchangeRates(
        settlerId,
        'Exchange Rate for Settlements',
      ),
    ]).subscribe(
      ([accounts, exchangeRates]) => {
        this.accounts = accounts;
        this.exchangeRates = exchangeRates;

        const settler = this.form.get('settler')?.value;
        this.branchCurrency = settler.baseCurrency.split(' ').pop() || '';
        const baseExRate = this.exchangeRates.find(
          (e) => e.currencyId === settler.baseCurrencyId,
        );
        if (!baseExRate) {
          this.snackBar.open('Branch base currency exchange rate not found.');
          this.resetBranch();
          return;
        }

        this.settlerBaseCurrencyRate = baseExRate.normRate;
      },
      ({ message }) => {
        this.snackBar.open(message);
        this.resetBranch();
      },
    );
  }

  onAccountChange(accountId: number) {
    this.resetAmounts();

    const selectedAccount = this.accounts.find((a) => a.id === accountId);
    const exchangeRate = this.exchangeRates.find(
      (e) => e.currencyId === selectedAccount?.currencyId,
    );
    if (!exchangeRate) {
      this.resetAmounts();
      this.snackBar.open('Settlement exchange rate not found for this account');
      return;
    }

    this.accountCurrency = exchangeRate.currency.split(' ').pop() || '';
    this.settlerExchangeRate = exchangeRate.normRate;
    this.form.get('settlerExchangeRate')?.setValue(this.settlerExchangeRate);
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.getRawValue() || {};
      const createSettlementDto: CreateSettlementDto = {
        settlementTypeId: formValues.type.id,
        settlerId: formValues.settler.id,
        beneficiaryId: formValues.beneficiary.id,
        settlerComments: formValues.description,
        settlerAccountId: formValues.account.id,
        settlerGlAmount: formValues.settlerGlAmount,
        settlerExchangeRate: this.settlerExchangeRate || 0,
        amount: this.amount || 0,
        settlerBaseCurrencyAmount: this.settlerBaseCurrencyAmount || 0,
        settlerBaseCurrencyRate: this.settlerBaseCurrencyRate || 0,
        turkishExchangeRate: formValues.turkishExchangeRate || 0,
      };
      this.settlementService.createSettlement(createSettlementDto).subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.resetBtn?.nativeElement.click();
          this.addSettlementDialog(resp.id);
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  addSettlementDialog(settlementId: number) {
    this.settlementService
      .getSettlementDetails(settlementId)
      .subscribe((settlement) => {
        this.dialog.open(SettlementAddReceiptPopupComponent, {
          width: '850px',
          data: settlement,
        });
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }

  resetBranch() {
    this.form.controls['settler'].reset();
    this.form.controls['account'].reset();
    this.accounts = [];
    this.exchangeRates = [];
    this.branchCurrency = '';
    this.settlerBaseCurrencyRate = undefined;
  }

  resetAmounts() {
    this.accountCurrency = '';
    this.settlerExchangeRate = undefined;
    this.amount = undefined;
    this.settlerBaseCurrencyAmount = undefined;
    this.form.get('settlerGlAmount')?.setValue('');
    this.form.get('settlerExchangeRate')?.setValue('');
    this.form.get('amount')?.setValue('');
    this.form.get('settlerBaseCurrencyAmount')?.setValue('');
  }
}
