<app-index-container label="{{ 'trialBalance' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select
          formControlName="branch"
          (selectionChange)="onBranchChange($event.value)"
        >
          <mat-option *ngFor="let branch of branches" [value]="branch">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'requestDate' | translate }}</mat-label>
        <input
          matInput
          [max]="today"
          [matDatepicker]="endDate"
          formControlName="endDate"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #endDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="showInBranchCurrencyCb" class="row mt-2">
      <mat-label>{{ 'showInBranchCurrency' | translate }}:</mat-label>
      <mat-checkbox
        matInput
        class="mb-3"
        color="primary"
        formControlName="showInBranchCurrency"
      >
        {{ branchCurrency }}
      </mat-checkbox>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</app-index-container>
