<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'clientProtectionAccountDetail' | translate }}{{' ('+data.reportCurrency+')'}}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table id="clientProtectionReport" class="table table-bordered table-hover">
        <tbody *ngIf="data.clientProtectionDetails.length">
        <tr>
          <th>{{ '#' | translate }}</th>
          <th>{{ 'date' | translate }}</th>
          <th>{{ 'transactionId' | translate }}</th>
          <th *ngIf="data.reportCurrency !== 'TRY'">{{ 'taajSettlements' | translate }}</th>
          <th>{{ 'incomingRemittances' | translate }}</th>
          <th>{{ 'outgoingRemittances' | translate }}</th>
          <th *ngIf="data.reportCurrency !== 'TRY'">{{ 'paidFromTaajSettlements' | translate }}</th>
          <th>{{ 'toBeDeposited' | translate }}</th>
          
        </tr>
        <tr *ngFor="let remittance of data.clientProtectionDetails; let i = index">
          <!-- Number Column -->
          <td>{{ i + 1 }}</td>
          <td class="text-nowrap">{{ remittance.date }}</td>
          <td class="text-nowrap">{{ remittance.code }}</td>
          <td class="text-nowrap" *ngIf="data.reportCurrency !== 'TRY'">{{ remittance.taajSettlement | number:'1.2-2'}}</td>
          <td class="text-nowrap">{{ remittance.incomingRemittanceAmount | number:'1.2-2' }}</td>
          <td class="text-nowrap">{{ remittance.outgoingRemittanceAmount | number:'1.2-2' }}</td>
          <td class="text-nowrap" *ngIf="data.reportCurrency !== 'TRY'">{{ remittance.paidFromTaajSettlement | number:'1.2-2' }}</td>
          <td class="text-nowrap">{{ remittance.tobeDepositedIntoClientProtectionAccount | number:'1.2-2' }}</td>
        </tr>
        </tbody>
        <tbody>
          <tr class="table-borderless">
            <td colspan="3" class="text-center"><strong>{{'grandTotal' | translate}}</strong></td>
            <td class="text-nowrap" *ngIf="data.reportCurrency !== 'TRY'">{{ data.totalTaajSettlements | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ data.totalIncomingRemittances | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ data.totalOutgoingRemittances | number:'1.2-2' }}</td>
            <td class="text-nowrap" *ngIf="data.reportCurrency !== 'TRY'">{{ data.totalPaidFromTaajSettlements | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ data.totalToBeDepositedIntoClientProtectionAccount | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-right">
    <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
    <button
      mat-button
      class="btn btn-outline-primary"
      [useExistingCss]="true"
      printSectionId="report"
      ngxPrint
    >
      {{ 'print' | translate }}
    </button>
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
      {{ 'export' | translate }}
    </button>
  </div>
</div>

