import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { BranchesService } from '../branch/data/branches.service';
import {
  ReportsService,
  UnpaidRemittanceReportDetailFilter,
  UnpaidRemittanceReportFilter,
} from './data/reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { UnpaidRemittanceReportDto } from './data/dto/reports.dto';
import { MatDialog } from '@angular/material/dialog';
import { UnpaidRemittanceReportPopupComponent } from './unpaidRemittanceReportPopup.component';
import {SnackBarService} from "../_common/snackBar.service";

@Component({
  selector: 'app-unpaidremittancereport',
  templateUrl: './unpaidRemittanceReport.component.html',
})
export class UnpaidRemittanceReportComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  branches: any[] = [];
  dataThere: boolean = false;
  selected: any;
  displayedColumns: string[] = [
    'number',
    'unpaidAmount',
    'unpaidCommission',
    'totalAmountWithComm',
    'numberOfRemittances',
  ];
  data = new MatTableDataSource<UnpaidRemittanceReportDto>();
  date = new UntypedFormControl(new Date(), [Validators.required]);
  constructor(
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService,
    private reportService: ReportsService,
    private dialog: MatDialog,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.branchesService.getBranches().subscribe((b) => {
      b.forEach((branch) => {
        this.branches.push(branch);
      });
    });

    this.form = this.fb.group({
      branchId: [null, Validators.required],
      requestedDate: this.date,
      isSubBranchesIncluded: [null],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const { branchId, requestedDate, isSubBranchesIncluded } =
        this.form.value;
      const filter: UnpaidRemittanceReportFilter = {
        branchId: branchId,
        requestedDate: requestedDate,
        isSubBranchesIncluded: isSubBranchesIncluded || false,
      };

      this.reportService.getUnpaidRemittanceReport(filter).subscribe((resp) => {
        if (resp.length) {
          this.dataThere = true;
        }
        this.data.data = resp;
        this.data.paginator = this.paginator;
      }, ({ message }) => {
        this.snackBar.open(message);
      });
    }
  }

  disPlayRemitDetails(id: string) {
    const filter: UnpaidRemittanceReportDetailFilter = {
      remittanceIds: id,
    };
    this.reportService
      .getUnpaidRemittanceReportDetail(filter)
      .subscribe((resp) => {
        const dialogRef = this.dialog.open(
          UnpaidRemittanceReportPopupComponent,
          {
            data: { data: resp },
            width: '1000px',
            maxHeight: '90vh',
            position: { top: '8rem', left : '15rem' },
          },
        );

        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            return;
          }
        });
      },  ({ message }) => {
        this.snackBar.open(message);
      });
  }
}
