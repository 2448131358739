import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ReportsService } from './data/reports.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerRiskRatingScoreDetailsPopupComponent } from './customer-risk-rating-score-details-popup.component';

@Component({
  selector: 'app-customer-risk-rating-report',
  templateUrl: './customer-risk-rating-report.component.html',
  styleUrls: ['./customer-risk-rating-report.component.less']
})
export class CustomerRiskRatingReportComponent implements OnInit {

    form!: UntypedFormGroup;
  resultsLength = 0;
   displayedColumns: string[] = [
    '#', 'branchCode', 'lowRiskRatingCustomers', 'mediumRiskRatingCustomers', 'highRiskRatingCustomers'
  ];

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  remittances = new MatTableDataSource<any>();
  constructor(
    private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private reportService: ReportsService,
  ) { }
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);

  ngOnInit(): void {
    this.form = this.fb.group({
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onSubmit() {
    const { startDate, endDate } = this.form.value;
    const filter: any = {};

    if(startDate) filter.startDate = startDate;
    if(endDate) filter.endDate = endDate;

    this.reportsService.getCustomerRiskratingReport(filter)
      .subscribe((data)=>{
        this.remittances.data = data;
        this.remittances.paginator = this.paginator;
      },({ message }) => {
        // this.snackBar.open(message)
        this.remittances.data = [];
       }
      );
  }

   viewCustomerRiskRatingDetails( customerRiskRatingScore: string) {
      
    let filter = {
      startDate: this.form.value?.startDate,
      endDate: this.form.value?.endDate,
      customerRiskRatingScore: customerRiskRatingScore,
    }
    this.reportService
        .getCustomerRiskratingReportDetail(
          filter,
        )
        .subscribe((data) => {
          const dialogRef = this.dialog.open(CustomerRiskRatingScoreDetailsPopupComponent, {
            data: {data: data },
            width: '1000px',
            maxHeight: '90vh',
          });

          dialogRef.afterClosed().subscribe((resp) => {
            if (resp === 'close') {
              return;
            }
          });
        }, ({ message }) => {
          this.snackBar.open(message);
        });
  }

}
