import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { AuthService } from '../../auth/data/auth.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import { LocalityPopupComponent } from './localityPopup.component';

@Component({
  selector: 'app-localities',
  templateUrl: './localities.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class LocalitiesComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  displayedColumns: string[] = ['number', 'name', 'city', 'actions'];
  data = new MatTableDataSource<LookupsDto>();
  permissions = Permissions.lookupsManagement.localities;
  cities!: LookupDto[];
  localityCities: LookupDto[] = [];
  countries!: LookupDto[];

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public lookupsService: LookupsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ countries, cities }) => {
      this.countries = countries;
      this.cities = cities;
    });

    this.form = this.fb.group({
      city: [null],
    });
    this.lookupsService.localities.subscribe((localities) => {
      this.data.data = localities;
      setTimeout(() => {
        this.data.paginator = this.paginator;
      }, 100);
    });
  }

  onSubmit() {
    if (this.form.controls['city'].value) {
      this.lookupsService.getLocalities(this.form.controls['city'].value.id);
    }
  }

  onCountryChange(countryId: number) {
    this.localityCities = this.cities.filter(
      (city) => city.parentId === countryId,
    );
  }

  addLocalityDialog(cityId?: number, localityId?: number) {
    if (cityId) {
      this.lookupsService
        .getLocality(cityId, localityId)
        .subscribe((locality) => {
          this.dialog.open(LocalityPopupComponent, {
            data: {
              locality: locality,
              cities: this.cities,
              countries: this.countries,
              cityId: cityId,
            },
            width: '700px',
          });
        });
    } else {
      this.dialog.open(LocalityPopupComponent, {
        data: { cities: this.cities, countries: this.countries },
        width: '700px',
      });
    }
  }
}
