<h1 mat-dialog-title>{{ 'updateDocument' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-2 overflow-auto">
    <form
      form
      class="container-fluid flex-column p-0"
      name="form"
      [formGroup]="form"
      #documentForm
      novalidate
    >
      <mat-card>
        <mat-card-title>{{ 'document' | translate }}</mat-card-title>
        <mat-card-content>
          <div class="row">
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'documentTypes' | translate }}</mat-label>
              <mat-select
                formControlName="docType"
                (selectionChange)="onDoctypeSelected($event)"
              >
                <mat-option
                  *ngFor="let docType of docTypes"
                  [value]="docType"
                  >{{ docType.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col" *ngIf="requiredFields?.documentNumberIsRequired">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ (isPassportDocTypeSelected ? 'passportNumber' : 'docNumber') | translate }}:</mat-label>
                <input
                  matInput
                  #primaryDocNumber
                  formControlName="docNumber"
                  (focusout)="onFocusOut($event)"
                />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('docNumber'))"
              ></app-validation-error>
            </div>
            <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.documentIssuerIsRequired">
              <mat-label>{{ 'docIssuedBy' | translate }}:</mat-label>
              <input matInput #docIssuedBy formControlName="docIssuedBy" />
            </mat-form-field>
          </div>

          <div class="row">
            <div *ngIf="requiredFields?.idNumberIsRequired" class="col">
              <mat-form-field appearance="outline" class="col-md-12 col-sm-12">
                <mat-label>{{ 'idNumber' | translate }}:</mat-label>
                <input
                  matInput
                  #idNumber
                  formControlName="idNumber"
                />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('idNumber'))"
              ></app-validation-error>
            </div>

            <div *ngIf="requiredFields?.serialNumberIsRequired" class="col">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'serialNumber' | translate }}:</mat-label>
                <input
                  matInput
                  #serialNumber
                  formControlName="serialNumber"
                />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('serialNumber'))"
              ></app-validation-error>
            </div>
          </div>

          <div class="row">
            <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.issueDateIsRequired">
              <mat-label>{{ 'docIssueDate' | translate }}</mat-label>
              <input
                matInput
                [max]="today"
                [matDatepicker]="docIssueDate"
                formControlName="docIssueDate"
              />
              <mat-hint>DD-MM-YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="docIssueDate"
              ></mat-datepicker-toggle>
              <mat-datepicker startView="month" #docIssueDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.expiryDateIsRequired">
              <mat-label>{{ 'docExpiryDate' | translate }}</mat-label>
              <input
                matInput
                [min]="today"
                [matDatepicker]="docExpiryDate"
                formControlName="docExpiryDate"
              />
              <mat-hint>DD-MM-YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="docExpiryDate"
              ></mat-datepicker-toggle>
              <mat-datepicker startView="month" #docExpiryDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="row mt-2">
            <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.motherNameIsRequired">
              <mat-label>{{ 'motherName' | translate }}:</mat-label>
              <input matInput #motherName formControlName="motherName" />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('motherName'))"
            ></app-validation-error>

            <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.nationalityIsRequired">
              <mat-label>{{ 'nationality' | translate }}</mat-label>
              <mat-select formControlName="nationality">
                <mat-option
                  *ngFor="let country of allCountries"
                  [value]="country"
                >{{ country.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row">
            <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.fatherNameIsRequired">
              <mat-label>{{ 'fatherName' | translate }}:</mat-label>
              <input matInput #fatherName formControlName="fatherName" />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('fatherName'))"
            ></app-validation-error>
          </div>
          <div class="row">
            <div class="col">
              <mat-checkbox
                matInput
                class="mb-3"
                color="primary"
                formControlName="isActive"
              >
                {{ 'active' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div appearance="outline" class="col-md-6 pt-2">
              <input
                type="file"
                style="display: none"
                accept=".png, .jpg, .jpeg, .pdf"
                (change)="onDocAttachmentSelected($event)"
                #customerDocFile
              />
              <button
                mat-mini-fab
                color="primary"
                class="mx-1"
                type="button"
                (click)="docFileElem.nativeElement.click()"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
              <mat-label *ngFor="let file of docFile"
                >{{ file?.name }}<span *ngIf="file?.name">, </span></mat-label
              >
            </div>
            <div class="col-md-2" *ngFor="let file of docFile; let i = index">
              <div *ngIf="file && file?.type !== 'application/pdf'">
                <img
                  class="img-thumbnail"
                  [src]="file.url"
                  style="width: auto; height: 100px; margin-right: 3px"
                />
                <div>
                  <mat-icon (click)="clearSelectedDocAttachment(file)"
                    >cancel</mat-icon
                  >
                </div>
              </div>
              <div
                class="col-6"
                *ngIf="file && file?.type === 'application/pdf'"
              >
                <mat-icon
                  style="font-size: 100px; color: #a6a6a6; margin-right: 50px"
                  >picture_as_pdf</mat-icon
                >
                <div>
                  <mat-icon (click)="clearSelectedDocAttachment(file)"
                    >cancel</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'updateDocument' | translate }}
  </button>
</div>
