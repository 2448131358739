import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../../_common/data/http.service';
import {
  BsmvReport,
  BtransFilter,
  CbkRemittanceReportDto,
  CbkReportDetailFilter,
  CbkReportFilter,
  ClientProtectionReport,
  IncomingRemittancesReport,
  OutgoingRemittancesReport
} from "./Dto/CbkRemittance.dto";



@Injectable()
export class CbkReportService {
  constructor(private httpService: HttpService) {}

  getIncomingRemittance(
    filter: CbkReportFilter,
  ): Observable<CbkRemittanceReportDto[]> {
    return this.httpService.post<CbkRemittanceReportDto[]>(
      'remittance/incomingremittancereport',
      filter,
    );
  }

  getIncomingRemittanceDetail(
    filter: CbkReportFilter,
  ): Observable<IncomingRemittancesReport> {
    return this.httpService.get<IncomingRemittancesReport>(
      'remittance/incomingremittancereportdetail',
      filter,
    );
  }

  getOutgoingRemittance(
    filter: CbkReportFilter,
  ): Observable<CbkRemittanceReportDto[]> {
    return this.httpService.post<CbkRemittanceReportDto[]>(
      'remittance/outgoingremittancereport',
      filter,
    );
  }

  getOutgoingRemittanceDetail(
    filter: CbkReportFilter,
  ): Observable<OutgoingRemittancesReport> {
    return this.httpService.get<OutgoingRemittancesReport>(
      'remittance/outgoingremittancereportdetail',
      filter,
    );
  }

  getBsmvTaxReportDetail(
    filter: CbkReportFilter,
  ): Observable<BsmvReport> {
    return this.httpService.get<BsmvReport>(
      'remittance/bsmvtaxreportdetail',
      filter,
    );
  }

  getClientProtectionAccountReport(
    filter: CbkReportFilter,
  ): Observable<CbkRemittanceReportDto[]> {
    return this.httpService.post<CbkRemittanceReportDto[]>(
      'remittance/clientprotectionaccountreport',
      filter,
    );
  }

  getClientProtectionAccountReportDetail(
    filter: CbkReportFilter,
  ): Observable<ClientProtectionReport> {
    return this.httpService.get<ClientProtectionReport>(
      'remittance/clientprotectionaccountreportdetail',
      filter,
    );
  }

  getClientProtectionCustomerReport(
    filter: CbkReportFilter,
  ): Observable<ClientProtectionReport> {
    return this.httpService.get<ClientProtectionReport>(
      'remittance/clientprotectioncustomerreport',
      filter,
    );
  }

  getParquetReport(filter: BtransFilter): Observable<any> {
    return this.httpService.post<any>('remittance/report/parquet', filter);
  }

  getParquetReports(type: string): Observable<any> {debugger
    return this.httpService.get<any>('remittance/report/parquet/list', {type});
  }

  getParquetFile(path: string, fileType: string): Observable<any> {
    return this.httpService.getAttachment('remittance/report/parquet/attachment', { path });
  }

  deleteParquetReport(id: number): Observable<any> {
    return this.httpService.post('remittance/report/parquet/delete', id);
  }

  regenerateParquetReport(filter: BtransFilter): Observable<any> {
    return this.httpService.post('remittance/report/parquet/regenerate', filter);
  }
}
