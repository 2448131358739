<app-form-container [label]="title | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'name' | translate }}:</mat-label>
          <input matInput #name formControlName="name" type="text" />
        </mat-form-field>
        <app-validation-error
          [control]="$any(form.get('name'))"
        ></app-validation-error>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'userType' | translate }}:</mat-label>
          <mat-select #userTypeId formControlName="userTypeId">
            <mat-option
              *ngFor="let userType of userTypes"
              [value]="userType.id"
            >
              {{ userType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-label>{{ 'status' | translate }}:</mat-label>
        <mat-radio-group formControlName="isActive">
          <mat-radio-button [checked]="roleStatus === true" [value]="true">{{
            'active' | translate
            }}</mat-radio-button>&nbsp;
          <mat-radio-button [checked]="roleStatus === false" [value]="false">{{
            'inactive' | translate
            }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'description' | translate }}:</mat-label>
        <textarea
          matInput
          #description
          formControlName="description"
        ></textarea>
      </mat-form-field>
      <app-validation-error
        [control]="$any(form.get('description'))"
      ></app-validation-error>
    </div>
    <p class="h5">{{ 'permissions' | translate }}:</p>
    <mat-accordion>
      <div class="row mb-3">
        <div class="col" *ngFor="let group of permissions">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-checkbox
                color="primary"
                [checked]="group.completed"
                [indeterminate]="group.indeterminate"
                (change)="selectAllSubGroups($event.checked, group.id)"
                (click)="$event.stopPropagation()"
                (keydown)="$event.stopPropagation()"
              >
                {{ group.name | translate }}
              </mat-checkbox>
            </mat-expansion-panel-header>
            <mat-accordion>
              <div class="ps-3" *ngFor="let subGroup of group.subGroups">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-checkbox
                      color="warn"
                      [checked]="subGroup.completed"
                      [indeterminate]="subGroup.indeterminate"
                      (change)="
                        selectAllPermissionsOfSubgroup(
                          $event.checked,
                          subGroup.id,
                          group.id
                        )
                      "
                      (click)="$event.stopPropagation()"
                      (keydown)="$event.stopPropagation()"
                    >
                      {{ subGroup.name | translate }}
                    </mat-checkbox>
                  </mat-expansion-panel-header>
                  <div
                    class="d-block ps-3"
                    [formGroup]="permissionsForm"
                    *ngFor="let permission of subGroup.permissions"
                  >
                    <mat-checkbox
                      class="mx-1"
                      color="accent"
                      [(ngModel)]="permission.isChecked"
                      formControlName="{{ permission.id }}"
                      (change)="
                        checkMe(
                          $event.checked,
                          permission.id,
                          subGroup.id,
                          group.id
                        )
                      "
                    >
                      <div class="row">
                        <div class="col-12">
                          {{ permission.name | translate }}
                        </div>
                      </div>
                    </mat-checkbox>
                  </div>
                </mat-expansion-panel>
                &nbsp;
              </div>
            </mat-accordion> </mat-expansion-panel
          >&nbsp;
        </div>
      </div>
    </mat-accordion>
    <div class="d-flex justify-content-end">
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/users/roles"
      >
        {{ 'back' | translate }}
      </button>
      <button
        *ngIf="addMode"
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        (click)="uncheckAll()"
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="
          authService.isUserAuthorized([
            rolesPermissions.CREATE_ROLE,
            rolesPermissions.UPDATE_ROLE
          ])
        "
        type="submit"
        color="primary"
      >
        {{ (addMode ? 'addRole' : 'updateRole') | translate }}
      </button>
    </div>
  </form>
</app-form-container>
