import { UntypedFormGroup } from '@angular/forms';

export class CustomValidators {
  static matchPasswords(password: string, confPassword: string) {
    return (formGroup: UntypedFormGroup) => {
      const passwordInput = formGroup.controls[password];
      const confirmPasswordInput = formGroup.controls[confPassword];
      if (
        confirmPasswordInput.errors &&
        !confirmPasswordInput.errors.matchPasswords
      )
        return;
      if (passwordInput.value !== confirmPasswordInput.value) {
        confirmPasswordInput.setErrors({ matchPasswords: true });
      } else {
        confirmPasswordInput.setErrors(null);
      }
    };
  }
  static conditionalRequired(filed: string, anotherField: string) {
    return (formGroup: UntypedFormGroup) => {
      const filedInput = formGroup.controls[filed];
      const anotherFieldInput = formGroup.controls[anotherField];
      if (filedInput.errors && !filedInput.errors.required) return;
      if (anotherFieldInput.errors && !anotherFieldInput.errors.required)
        return;
      if (filedInput.value && !anotherFieldInput.value) {
        anotherFieldInput.setErrors({ required: true });
        filedInput.setErrors(null);
      } else if (!filedInput.value && anotherFieldInput.value) {
        filedInput.setErrors({ required: true });
        anotherFieldInput.setErrors(null);
      } else {
        filedInput.setErrors(null);
        anotherFieldInput.setErrors(null);
      }
    };
  }
}
