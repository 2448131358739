import {
  GetBranchesDto,
  GetBranchCurrencyDto,
} from 'src/app/branch/data/dto/branches.dto';
import { GetCustomerDto, UpsertCustomerDto } from '../../../customer/data/dto/customer.dto';

export interface RemittancesDto {
  id: number;
  code: string;
  senderName: string;
  senderMobile: string;
  receiverName: string;
  receiverMobile: string;
  sendingBranchCode: string;
  amount: number;
  currency: string;
  status: string;
  complianceStatus: string;
  ssTransactionId: string;
  canApprove: boolean;
  canApproveCompliances: boolean;
  canPay: boolean;
  canReturn: boolean;
  canResolveAmbiguousRemittance: boolean;
  canBlock: boolean;
  canUnblock: boolean;
  canTrace: boolean;
  isTraced: number;
  canUpdateReceiverName: boolean;
  canUpdateReceiverPhone: boolean;
  smsSent: boolean;
  canUnfreeze: boolean;
  canViewPayBackReceipt: boolean;
  sendDate: Date;
  partnerRemittanceId: number;
  canUploadSendReceipt: boolean;
  canUploadPayReceipt: boolean;
  canUploadSenderSignedForm: boolean;
  canUploadReceiverSignedForm: boolean;
  canViewRemittanceReceiptDocument: boolean;
}

export interface RemittanceDto {
  id: number;
  code: string;
  senderFirstName: string;
  senderMiddleName?: string;
  senderLastName: string;
  senderMobilePhone: string;
  receiverFirstName: string;
  receiverMiddleName?: string;
  receiverLastName: string;
  receiverMobilePhone: string;
  remittanceType: string;
  remittanceSubType: string;
  amount: number;
  receiverCountry: string;
  status: string;
  complianceStatus: string;
}

interface CustomerDocumentDto {
  id: number;
  customerId: number;
  documentTypeId: number;
  documentType: string;
  documentPaths: any;
  documentDetails: any;
  createdDate: Date;
}

export interface RemittancesDetailsDto {
  id: number;
  code: string;
  sender: GetCustomerDto;
  senderAddressCountry: string;
  senderAddressCity: string;
  senderAddressLocality: string;
  senderAddressSublocality: string;
  senderAddressStreet: string;
  senderAddressHouseNumber: string;
  receiver: GetCustomerDto;
  receiverAddressCountry: string;
  receiverAddressCity: string;
  receiverAddressLocality: string;
  receiverAddressSublocality: string;
  receiverAddressStreet: string;
  receiverAddressHouseNumber: string;
  senderPrimaryDoc?: CustomerDocumentDto;
  receiverPrimaryDoc?: CustomerDocumentDto;
  remittanceTypeId: string;
  remittanceSubTypeId: number;
  remittanceSubType: string;
  amount: number;
  commission: number;
  statusId: number;
  status: string;
  complianceStatus: string;
  sendDate: Date;
  payDate: Date;
  paymentType: string;
  sendingUserName: string;
  receivingUserName: string;
  sendingBranch: GetBranchesDto;
  receivingBranch: GetBranchesDto;
  receiverCountry: string;
  purpose: string;
  receivingAmount: number;
  receiverCountryCurrency: string;
  senderAccountCurrency: string;
  receiverAccountCurrency: string;
  payingAmount: number;
  senderComments: string;
  receiverComments: string;
  liraExchangeRate: string;
  payingExchangeRate: string;
  remittanceReceipt: any;
}

export interface RemittancesHistoryDto {
  id: number;
  code: string;
  senderFullName: string;
  receiverFullName: string;
  remittanceSubType: string;
  amount: number;
  commission: number;
  totalAmount: number;
  status: string;
  complianceStatus: string;
  sendDate: Date;
  paymentType: string;
  sendingBranch: string;
  receivingBranch: string;
  receivingCountry: string;
  partnerRemittanceId: string;
  partnerStatus: string;
  verificationPin: number;
  isTraced: boolean;
  remittanceHistories: RemittanceHistoriesDto[];
  remittanceApprovals: RemittanceApprovalsDto[];
  isTaajRemittance?: boolean;
  sendingCountry?: string;
  remittancePurpose: string;
  remittanceRelationship: string,
  sendingLocalAmount: number;
  localCommissionAmount: number;
  receivingAmount: number;

  destinationScore?: string;
  remittanceVolumeScore?: string;
  overallCustomerRiskRatingScore?: string;
  remittancePurposeScore?: string;
  occupationScore?: string;
  sendingCurrencyCode?: string;
  receivingCurrencyCode?: string;
}

export interface RemittanceHistoriesDto {
  id: number;
  action: string;
  comments: string;
  date: Date;
  user: string;
  snapshot: string;
}

export interface CreateRemittanceDto {
  senderCustomerId: number;
  senderCustomer: UpsertCustomerDto;
  receiverCustomer: UpsertCustomerDto;
  receiverCustomerId: number;
  remittanceSubTypeId: number;
  remittanceTypeDetails?: any;
  primaryDocId?: number;
  secondaryDocId?: number;
  receiverCityId?: number;
  receiverCountryId: number;
  paymentTypeId: number;
  purposeId: number;
  relationshipId: number;
  amount: number;
  senderAccountId: number;
  sendingExchangeRate: number;
  sendingAmount: number;
  sendingBranchExchangeRate: number;
  sendingBranchAmount: number;
  commission: number;
  sendingCommission: number;
  sendingBranchCommission: number;
  isCommissionIncludedInAmount: boolean;
  payingExchangeRate?: number;
  payingAmount: number;
  senderComments?: string;
  complianceComments?: string;
  receiverAccountNumber?: string;
  receiverBankAccount?: string;
  receivingAmount: number;
  receivingExchangeRate: number;
  receivingCountryCurrencyId: number;
  authorizedSignatureFilePath?: string;
  conductorId?: number;
}

export interface PayRemittanceDto {
  remittanceId: number;
  primaryDocId?: number;
  secondaryDocId?: number;
  receiverComments?: string;
  receiverAccountId: number;
  payingExchangeRate: number;
  payingAmount: number;
  payingBranchExchangeRate: number;
  payingBranchAmount: number;
  complianceComments?: string;
  receiverCustomerId?: number;
  senderCustomerId?: number;
  remittancePin?: number;
  conductorId?: number;
  authorizedSignatureFilePath?: number;
  purposeId?: number;
  relationshipId?: number;
}

export interface PaybackRemittanceDto {
  remittanceId: number;
  receiverAccountId: number;
  partnerRemittanceId?: number;
}

export interface RemittanceSubType {
  id: number;
  name: string;
  remittanceTypeId: number;
  remittanceType: string;
  displayOrder: number;
  isVerificationRequired: boolean;
  canReceiverNameChanged: boolean;
  currency: string;
  countries: string[];
}

export interface RemittanceType {
  id: number;
  name: string;
  remittanceSubTypes: RemittanceSubType[];
}

export interface CalculateRemittanceAmountsReqDto {
  changedField: string;
  remittanceSubTypeId: number;
  receiverCountryId: number;
  tellerAccountId: number;
  sendingAmount: number;
  totalSendingAmount: number;
  receivingAmount: number;
  receivingCurrencyId: number;
  [key: string]: string | number;
}

export interface CalculateRemittanceAmountsResDto {
  sendingAmount: number;
  sendingCommission: number;
  minSendingCommission: number;
  maxSendingCommission: number;
  receivingAmount: number;
  sendingExchangeRate: number;
  currencyToCurrencyExchangeRate: number;
  receiverExchangeRate: number;
  sendingBranchExchangeRate: number;
  sendingBranchAmount: number;
  sendingBranchCommission: number;
  amount: number;
  commission: number;
  sendingAccountCurrency: string;
  sendingBranchCurrency: string;
}

export interface ComplianceMissingResponseDto {
  complianceDetails: string;
  remittanceData: Array<any>;
}

export interface RemittanceComplianceDto {
  complianceData: any;
  complianceDetails: string;
  primaryDocRequired: boolean;
  secondaryDocRequired: boolean;
  isAttachmentRequired: boolean;
  additionalInfoRequired: boolean;
  remittanceData: {
    code: string;
    name: string;
    amount: number;
    date: string;
  }[];
}

export interface RemittanceForPaymentResponseDto {
  id: number;
  code: string;
  senderCustomers: GetCustomerDto[];
  receiverCustomers: GetCustomerDto[];
  remittanceType: string;
  remittanceSubType: string;
  amount: number;
  currency: string;
  exchangeRate: number;
  receiverCountry: string;
  status: string;
  commission: number;
  verificationCode: string;
  compliances: RemittanceComplianceDto[];
  purposeId?: number;
  relationshipId?: number;
  isTaajRemittance: boolean;
  conductorId?: number;
}

export interface RemittanceForPaymentResponse {
  remittance: RemittanceForPaymentResponseDto;
  payingBranchExchangeRate: number;
  payingBranchAmount: number;
  payingBranchCurrency: string;
  branchExchangeRates: GetBranchCurrencyDto[];
}

export interface RemittanceViolationsDto {
  branchViolations: BranchViolationResponseDto[];
  complianceViolations: ComplianceMissingResponseDto[];
}

export interface ApproveRemittanceDto {
  remittanceId: number;
  isApproved: boolean;
  comments: string;
}
export interface BlockUnblockRemittanceDto {
  remittanceId: number;
  block: boolean;
  comments: string;
  reqData?: any;
}

export interface RemittanceApprovalsDto extends ApproveRemittanceDto {
  approvalType: string;
}

export interface ReturnRemittanceDto {
  remittanceId: number;
  type: string;
  comments: string;
}

export interface TraceRemittanceDto {
  remittanceId: number;
  tracingOptionId: number;
  comments: string;
}

export interface ComplianceMissingResponseDto {
  complianceDetails: string;
  primaryDocRequired: boolean;
  secondaryDocRequired: boolean;
  isAttachmentRequired: boolean;
  additionalInfoRequired: boolean;
  remittanceData: Array<any>;
  complianceData: Array<any>;
  matchedAlieas: Array<any>;
}

export interface BranchViolationResponseDto {
  branchId: number;
  configurationType: string;
  approvalType: string;
}
export interface RemittanceReceiverUpdateDto {
  remittanceId: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  canUpdateReceiverName: boolean;
  canUpdateReceiverPhone: boolean;
}
