import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomerService } from './customer.service';
import { LookupDto } from '../../_common/data/dto/api.dto';
import {
  CustomerDocTypesResponseDto,
  GetCustomerDto, GetCustomerTypesDto,
} from './dto/customer.dto';

@Injectable()
export class CustomerDocumentTypesResolver implements Resolve<LookupDto[]> {
  constructor(private customerService: CustomerService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<CustomerDocTypesResponseDto[]> {
    return this.customerService.getCustomerDocTypes();
  }
}

@Injectable()
export class CustomerGenderResolver implements Resolve<LookupDto[]> {
  constructor(private customerService: CustomerService) {}
  resolve(): Observable<LookupDto[]> {
    return this.customerService.getCustomerGenders();
  }
}

@Injectable()
export class CustomerOccupationsResolver implements Resolve<LookupDto[]> {
  constructor(private customerService: CustomerService) {}
  resolve(): Observable<LookupDto[]> {
    return this.customerService.getCustomerOccupations();
  }
}

@Injectable()
export class CustomerSourceOfIncomeResolver implements Resolve<LookupDto[]> {
  constructor(private customerService: CustomerService) {}
  resolve(): Observable<LookupDto[]> {
    return this.customerService.getCustomerSourceOfIncome();
  }
}

@Injectable()
export class CustomerByIdResolver implements Resolve<any> {
  constructor(private customerService: CustomerService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<GetCustomerDto[]> {
    const { customerId } = route.params;
    return this.customerService.getCustomers(customerId);
  }
}

@Injectable()
export class CustomerTypesResolver implements Resolve<GetCustomerTypesDto[]> {
  constructor(private customerService: CustomerService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<GetCustomerTypesDto[]> {
    return this.customerService.getCustomerTypes();
  }
}
