import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GetConductor, upsertConductor} from "./data/dto/customer.dto";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {CustomerService} from "./data/customer.service";
import {SnackBarService} from "../_common/snackBar.service";
import moment from "moment";

@Component({
  selector: 'app-customer-doc',
  templateUrl: './editConductorPopup.component.html',
})
export class EditConductorPopupComponent implements OnInit{
  private conductor: any;
  form!: UntypedFormGroup;
  allCountries: any;

  maxDOB = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate(),
  );

  constructor(
    public dialogRef: MatDialogRef<EditConductorPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      conductor: GetConductor
      countries: any
    },
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private customerService: CustomerService,
    private snackBar: SnackBarService,

  ) {}

  ngOnInit() {
    this.conductor = this.data.conductor;
    this.allCountries = this.data.countries;
    this.form = this.fb.group({
      conductorFirstName: [
        this.conductor.firstName,
      ],
      conductorLastName: [this.conductor.lastName, [Validators.required]],
      conductorMobilePhone: [this.conductor.lastName, [Validators.required]],
      conductorPlaceOfBirth: [this.conductor.placeOfBirth, [Validators.required]],
      conductorDateOfBirth: [this.conductor.dateOfBirth, [Validators.required]],
      conductorPosition: [this.conductor.position, [Validators.required]],
      conductorNationality: [ this.allCountries.find((country: any) => country.id == this.conductor.nationalityId), [Validators.required]],
      conductorFartherName: [this.conductor.fartherName, [Validators.required]],
      conductorMotherName: [this.conductor.motherName, [Validators.required]],
    })

  }

  onSubmit(){

    if (this.form.valid) {
      const formValues = this.form.getRawValue();
      const conductorDto: upsertConductor = {
        id: this.conductor?.id,
        firstName: formValues.conductorFirstName,
        lastName: formValues.conductorLastName,
        mobile: formValues.conductorMobilePhone,
        dateOfBirth: moment(formValues.conductorDateOfBirth).format('YYYY-MM-DD'),
        placeOfBirth: formValues.conductorPlaceOfBirth,
        position: formValues.conductorPosition,
        fartherName: formValues.conductorFartherName,
        motherName: formValues.conductorMotherName,
        nationalityId: formValues.conductorNationality?.id
      };

      this.customerService.upsertConductor(conductorDto).subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close({success: true});
        },
        ({message}) => {
          this.snackBar.open(message);
          this.dialogRef.close({success: false});
        },
      );
    }
  }
}
