import { Component, HostBinding, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthService } from './data/auth.service';
import { passwordRegex } from '../user/data/users.service';
import { SnackBarService } from '../_common/snackBar.service';
import { LoginRequest } from './data/loginRequest.dto';
import { CustomValidators } from '../_common/data/customValidators';
import { TranslateService } from '@ngx-translate/core';
import {LoginTypes} from "../_common/enums/loginTypes.enums";

@Component({
  selector: 'app-login',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./login.component.less'],
  // host: {
  //   class: 'w-100'
  // }
})
export class ResetPasswordComponent implements OnInit {
  @HostBinding('class') class: string = 'w-100';
  forceResetPwdForm!: UntypedFormGroup;
  isLogin = true;
  isForceResetPassword = false;
  pageTitle!: string;
  message!: string;
  optIsselected = false;
  loginRequest: LoginRequest = {
    username: '',
    password: '',
    otpCode: '',
    loginType: '',
    newPassword: '',
    forceResetPassword: false,
    forceOtpConfiguration: ''
  };

  username!: string;
  password!: string;
  passwordPattern = passwordRegex;
  uId!:number;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBar: SnackBarService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {

    this.isForceResetPassword = true;
      if (this.authService.isLoggedIn) {
        this.router.navigateByUrl('/dashboard');
      }
      this.forceResetPwdForm = this.fb.group(
        {
          newPassword: [null, [Validators.required]],
          confirmPassword: [null, [Validators.required]],
          code: [null, [Validators.required]],
        },
        {
          validators: CustomValidators.matchPasswords(
            'newPassword',
            'confirmPassword',
          ),
        },
      );

    this.uId = this.route.snapshot.queryParams['t'];
  }

  resetPassword() {
    if (this.forceResetPwdForm.valid) {
      this.loginRequest.newPassword = this.forceResetPwdForm?.value?.newPassword;
      this.loginRequest.otpCode = this.forceResetPwdForm?.value?.code;
      this.loginRequest.loginType =  LoginTypes.FORGOTPASSWORD;
      this.loginRequest.uid =  this.uId;
      this.authService.login(this.loginRequest).subscribe(
        ({message}) => {
          this.snackBar.open(message);
          localStorage.clear();
          window.location.href = '/login';
        },
        ({ message }) => {
          this.snackBar.open(message);

          if(message == 'tokenExpired'){
            setTimeout(()=> {
              localStorage.clear();
              window.location.href = '/login';
            }, 2000);
          }
        },
      );
    }
  }
}
