import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../../_common/snackBar.service';
import {regExp} from "../../_common/data/validationRules";
import { Utilities } from '../../_common/utils';
import { RolesService } from '../data/roles.service';
import { PermissionsDto } from '../data/dto/role/permissions.dto';

interface SubGroup {
  id: number;
  name: string;
  permissions: PermissionsDto[];
}

interface Group {
  id: number;
  name: string;
  subGroups: SubGroup[];
}

@Component({
  selector: 'app-role-request-approval',
  templateUrl: './roleRequestApprovalPopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})

export class RoleRequestApprovalPopupComponent implements OnInit {
  comments: string = '';
  type: string = '';

  form!: UntypedFormGroup;
  permissions!: any[];

  constructor(
    public dialogRef: MatDialogRef<RoleRequestApprovalPopupComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private roleService: RolesService,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) {}

  closeDialog() {
    this.dialogRef.close({
      success: false
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      statusType: ['approveRoleRequest', [Validators.required]],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });

    this.permissions = this.data.permissions.reduce((groupedPermissions: Group[], permission: PermissionsDto) => {
      let group = groupedPermissions.find(g => g.id === permission.groupId);

      if (!group) {
        group = {
          id: permission.groupId,
          name: permission.groupName,
          subGroups: [],
        };
        groupedPermissions.push(group);
      }

      let subGroup = group.subGroups.find(sg => sg.id === permission.subGroupId);

      if (!subGroup) {
        subGroup = {
          id: permission.subGroupId,
          name: permission.subGroupName,
          permissions: [],
        };
        group.subGroups.push(subGroup);
      }

      subGroup.permissions.push(permission);

      return groupedPermissions;
    }, [] as Group[]);
  }

  onSubmit() {
    if (this.form.valid) {
      this.roleService
        .updateRoleRequestStatus({
          id: this.data.id,
          statusType: this.form.value.statusType,
          comments: this.form.value.comments,
          roleData: this.data
        })
        .subscribe(
          (resp) => {
            this.snackBar.open(resp?.message,
            );
            this.dialogRef.close({
              success: true,
            });
          },
          ({ message }) => {
            this.snackBar.open(message);
            this.dialogRef.close({
              success: false,
            });
          },
        );
    }
  }
  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
