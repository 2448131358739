<div class="declaration-container">
    <h2>Declaration</h2>
    <p>
        I, <strong>{{ name }}</strong>, hereby declare that:
    </p>
    <ul>
        <li>I have read this document and know and understand the contents thereof;</li>
        <li>The information furnished above is in all respects both true and correct;</li>
        <li>The currency applied for will only be used for the specific purpose stated herein;</li>
        <li>
            The documentation presented in support of this application is in all respects authentic;
        </li>
        <li>
            I have been informed of the limit applicable to the above transaction and confirm that this
            limit will not be exceeded as a result of the conclusion of this transaction; and
        </li>
        <li>
            I consent to this information being provided to the South African Revenue Service and/or the
            Financial Intelligence Centre.
        </li>
    </ul>
</div>