<app-index-container label="{{ 'logs' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col">
        <mat-label>{{ 'relativeHoursAgo' | translate }}:</mat-label>
        <mat-slider
          min="1"
          max="24"
          step="1"
          discrete
          thumbLabel
          [displayWith]="formatLabel"
          #sinceHours
          formControlName="sinceHours"
        >
          <input matSliderThumb />
        </mat-slider>
        {{ form.value.sinceHours }}
      </div>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'reqId' | translate }}:</mat-label>
        <input matInput #reqId formControlName="reqId" />
      </mat-form-field>
      <div class="col">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- requestId Column -->
    <ng-container matColumnDef="requestId">
      <th mat-header-cell *matHeaderCellDef>{{ 'requestId' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.requestId }}</td>
    </ng-container>
    <!-- accessedAt Column -->
    <ng-container matColumnDef="accessedAt">
      <th mat-header-cell *matHeaderCellDef>{{ 'accessedAt' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.accessedAt }}</td>
    </ng-container>
    <!-- username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>{{ 'username' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.username }}</td>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>
    <!-- path Column -->
    <ng-container matColumnDef="path">
      <th mat-header-cell *matHeaderCellDef>{{ 'path' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.path }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a mat-menu-item (click)="onViewLogClick(row.id)">
            <mat-icon>receipt</mat-icon>{{ 'viewLog' | translate }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
