import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { AuthService } from '../../auth/data/auth.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import { CitiesPopupComponent } from './citiesPopup.component';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class CitiesComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  displayedColumns: string[] = ['number', 'name','code', 'country', 'actions'];
  data = new MatTableDataSource<LookupsDto>();
  permissions = Permissions.lookupsManagement.cities;
  countries!: LookupDto[];

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public lookupsService: LookupsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ countries }) => {
      this.countries = countries;
    });

    this.form = this.fb.group({
      countryId: [null],
    });
    this.lookupsService.cities.subscribe((cities) => {
      this.data.data = cities;
      setTimeout(() => {
        this.data.paginator = this.paginator;
      }, 100);
    });
  }

  onSubmit() {
    if (this.form.controls['countryId'].value) {
      this.lookupsService.getCities(this.form.controls['countryId'].value.id);
    }
  }

  addCityDialog(countyId?: number, cityId?: number) {
    if (cityId) {
      this.lookupsService.getCity(countyId, cityId).subscribe((city) => {
        this.dialog.open(CitiesPopupComponent, {
          data: { city: city, countries: this.countries, countryId: countyId },
          width: '700px',
        });
      });
    } else {
      this.dialog.open(CitiesPopupComponent, {
        data: { countries: this.countries },
        width: '700px',
      });
    }
  }
}
