import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { UserRequestApprovalPopupComponent } from './userRequestApprovalPopup.component';
import { LookupDto } from '../_common/data/dto/api.dto';
import { Utilities } from '../_common/utils';
import { UserRequestFilter, UsersService } from './data/users.service';
import { SearchUserRequests } from './data/dto/user/users.dto';
import {SnackBarService} from "../_common/snackBar.service";

@Component({
  selector: 'app-user-requests',
  templateUrl: './userRequests.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class UserRequestsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.userManagement.userRequests;
  resultsLength = 0;
  loading = false;
  userTypes: LookupDto[] = [];
  displayColumns: string[] = [
    'number',
    'firstName',
    'lastName',
    'username',
    'email',
    'mobile',
    'status',
    'requestDate',
    'statusChangeDate',
    'statusChangeComments',
    'actions',
  ];
  data = new MatTableDataSource<SearchUserRequests>();
  statuses: LookupDto[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UsersService,
    public authService: AuthService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ userTypes, statuses }) => {
      this.userTypes = userTypes;
      this.statuses = statuses;
    });

    this.form = this.fb.group({
      userType: [null],
      status: [null],
    });
  }

  onSubmit() {
    const { userType, status } = this.form.value;
    const filters: UserRequestFilter = {};

    if (userType) filters.userTypeId = userType.id;
    if (status) filters.statusId = status.id;

    this.userService.getUserRequests(filters).subscribe((data)=>{
      this.data.data = data;
      this.data.paginator = this.paginator;
      this.resultsLength = data.length;
    },error => {
      this.snackBar.open(error?.message);
    })
  }

  onUserRequestAction(id: number) {
    const dialogRef = this.dialog.open(UserRequestApprovalPopupComponent, {
      width: '1000px',
      disableClose: true,
      data: this.data.data.find((r) => r.id == id),
      panelClass: 'dialog-content',
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      if (resp.success) this.onSubmit();
    });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
