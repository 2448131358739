<app-index-container label="{{ 'settlementReport' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label class="mb-1">{{ 'direction' | translate }}:</mat-label>
        <mat-select
          formControlName="direction"
          (selectionChange)="onDirectionChange($event.value)"
        >
          <mat-option value="0">{{
            'selectSettlerAndBeneficiary' | translate
          }}</mat-option>
          <mat-option value="incoming">{{ 'incoming' | translate }}</mat-option>
          <mat-option value="outgoing">{{ 'outgoing' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'settler' | translate }}:</mat-label>
        <mat-select formControlName="settler">
          <mat-option *ngFor="let settler of settlers" [value]="settler">
            {{
              settler.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : settler.code + ' - ' + settler.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'beneficiary' | translate }}:</mat-label>
        <mat-select formControlName="beneficiary">
          <mat-option
            *ngFor="let beneficiary of beneficiaries"
            [value]="beneficiary"
          >
            {{
              beneficiary.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : beneficiary.code + ' - ' + beneficiary.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'startDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          formControlName="startDate"
          [value]="fromDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'endDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          formControlName="endDate"
          [value]="toDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #endDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row">
      <h4>{{ 'status' | translate }}:</h4>
      <div class="col" *ngFor="let status of statuses">
        <mat-checkbox
          color="primary"
          [value]="convertToString(status.id)"
          (change)="checkStatus($event)"
        >
          {{ status.name | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</app-index-container>
