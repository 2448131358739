<mat-card class="overflow-auto mat-elevation-z8 m-3 px-3">
  <mat-card-title>{{ 'profile' | translate }}</mat-card-title>
  <mat-card-content>
    <mat-tab-group>
      <mat-tab label="{{ '2FactorAuthentication' | translate }}">
        <mat-card>
          <mat-card-content *ngIf="!enableForm">
            <div class="row" *ngIf="!user.isTwoFactorAuthenticationEnabled">
              <h2>{{ 'selectTwoFactorAuthentication' | translate }}</h2>
              <div class="row">
                <div class="col">
                  <form
                    form
                    class="container-fluid flex-column p-0"
                    name="form"
                    [formGroup]="form"
                    novalidate
                  >
                    <div class="row">
                      <mat-radio-group formControlName="otpType" class="mb-4">
                        <mat-radio-button
                          *ngFor="let otp of otpTypes"
                          [value]="otp"
                          class="col px-3"
                          (change)="showSteps(otp.name)"
                        >
                          {{ otp.name | translate }}
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row" *ngIf="googleSteps">
                <hr />
                <div class="row">
                  <h2>{{ 'stepsToEnablegoogle2fa' | translate }}</h2>
                </div>
                <div class="row">
                  <p>1- {{ 'googleSteps1' | translate }}</p>
                  <p>2- {{ 'googleSteps2' | translate }}</p>
                  <p>3- {{ 'googleSteps3' | translate }}</p>
                </div>
                <div class="row">
                  <div class="col">
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                      ><img
                        src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                        height="50px"
                        width="100px"
                    /></a>
                    &nbsp;
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      ><img
                        src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                        height="50px"
                        width="100px"
                    /></a>
                  </div>
                </div>
                <hr />
                <div class="row" *ngIf="showQrCodeImg">
                  <div class="row">
                    <div class="col d-flex justify-content-center">
                      <img
                        [src]="imgUrl"
                        height="140px"
                        width="140px"
                        style="margin-bottom: 10px"
                      />
                    </div>
                    <div class="col">
                      <form
                        form
                        class="container-fluid flex-column p-0"
                        name="form"
                        [formGroup]="googleOtpForm"
                        novalidate
                      >
                        <div class="row">
                          <mat-form-field appearance="outline" class="col">
                            <mat-label>{{ 'googleOtp' | translate }}</mat-label>
                            <input
                              required
                              matInput
                              #googleOtp
                              formControlName="googleOtp"
                            />
                          </mat-form-field>
                        </div>
                        <div class="row">
                          <div class="col d-flex justify-content-center">
                            <button
                              mat-raised-button
                              color="primary"
                              (click)="setEnabled()"
                              *ngIf="submitBtn"
                            >
                              {{ 'submit' | translate }}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <hr />
                </div>
                <div class="row">
                  <div class="col d-flex justify-content-center">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="onSubmit()"
                      *ngIf="!submitBtn"
                    >
                      {{ 'continue' | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="smsSteps">
                <hr />
                <div class="row">
                  <h2>{{ 'stepsToEnableSms2fa' | translate }}</h2>
                </div>
                <div class="row">
                  <p>{{ 'smsSteps1' | translate }}</p>
                </div>
                <hr />
                <div class="row" *ngIf="showSmsFieldBtn">
                  <div class="col d-flex justify-content-center">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="showSmsCodeField()"
                    >
                      {{ 'continue' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row" *ngIf="smsCodeField">
                  <div class="col">
                    <form
                      form
                      class="container-fluid flex-column p-0"
                      name="form"
                      [formGroup]="smsForm"
                      novalidate
                    >
                      <div class="row">
                        <mat-form-field appearance="outline" class="col">
                          <mat-label>{{ 'smsCode' | translate }}</mat-label>
                          <input
                            required
                            matInput
                            #smsCode
                            formControlName="smsCode"
                          />
                        </mat-form-field>
                      </div>
                      <div class="row">
                        <div class="col">
                          <button
                            class="btn btn-outline-success float-end"
                            color="primary"
                            (click)="checkSmsCode()"
                          >
                            {{ 'verifyCode' | translate }}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="user.isTwoFactorAuthenticationEnabled">
              <p>
                {{ 'youAlreadyEnabled' | translate }} - {{ enabledOtpName }}
              </p>
            </div>
          </mat-card-content>
          <div class="row" *ngIf="enableForm">
            <p>
              {{
                'youHaveSuccessfullyEnabledTwoFactorAuthentication' | translate
              }}
            </p>
            <p>{{ 'YouWillBePrompted' | translate }}</p>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="{{ 'changePassword' | translate }}">
        <app-form-container label="{{ 'changePassword' | translate }}">
          <form
            form
            class="container-fluid flex-column p-0"
            name="passWordChangeform"
            [formGroup]="passWordChangeform"
            novalidate
            (submit)="onPasswordChange()"
          >
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'currentPassword' | translate }}:</mat-label>
                <input
                  type="password"
                  matInput
                  #currentPassword
                  formControlName="currentPassword"
                  autocomplete="new-password"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'newPassword' | translate }}:</mat-label>
                <input
                  type="password"
                  matInput
                  #newPassword
                  formControlName="newPassword"
                  [pattern]="passwordPattern"
                  autocomplete="new-password"
                />
                <mat-error *ngIf="passWordChangeform.value.newPassword">{{
                  'passwordPattern' | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'confirmNewPassword' | translate }}:</mat-label>
                <input
                  type="password"
                  matInput
                  #confirmNewPassword
                  formControlName="confirmNewPassword"
                  autocomplete="new-password"
                />
                <mat-error *ngIf="passWordChangeform.value.newPassword">{{
                  'passwordMismatch' | translate
                }}</mat-error>
              </mat-form-field>
            </div>
            <div align="end">
              <button mat-raised-button type="submit" color="primary">
                {{ 'changePassword' | translate }}
              </button>
            </div>
          </form>
        </app-form-container>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
