import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RemittanceService } from './data/remittance.service';
import { MatDialog } from '@angular/material/dialog';
import { OrmCustomersComponent } from '../customer/ormcustomers.component';
import { OrmRemittancesComponent } from './ormremittance.component';
import { SnackBarService } from '../_common/snackBar.service';
import { BranchesService } from '../branch/data/branches.service';
import { Router } from '@angular/router';
import { Permissions } from '../_common/enums/permissions.enums';

@Component({
  selector: 'app-ormdashboard',
  templateUrl: './orm.component.html',
  styleUrls: ['./orm.component.css'],
})
export class OrmComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['status', 'count', 'refresh']; 
  permissions = Permissions.remittanceManagement.ormdashboard;
  dataSource = new MatTableDataSource<CustomerSummary>([
    { status: 'Unverified customers', count: 0, statusId: null, isLoading: false },
    { status: 'Verified customers', count: 0, statusId: null, isLoading: false },
    { status: 'Rejected customers', count: 0, statusId: null, isLoading: false },
    { status: 'Total customers', count: 0, statusId: null, isLoading: false }
  ]);

  dataSource2 = new MatTableDataSource<RemitanceSummary>([
    { status: 'Sending', count: 0, statusId:null, isLoading: false },
    { status: 'Cancelled', count: 0, statusId: null, isLoading: false },
    { status: 'Pending', count: 0, statusId: null, isLoading: false },
    { status: 'Pending Approval', count: 0, statusId: null, isLoading: false },
    { status: 'Returned', count: 0, statusId: null, isLoading: false },
    { status: 'Paid', count: 0, statusId: null, isLoading: false },
  ]);
  startDate: Date | null = null
  endDate: Date | null = null;
  branchCode: null | null = null;
  branchStatus: null | null = null;
  branches: any[] = [];
  selectedBranch: number;
  intervalId: any;
  unverifiedCustomersCount: { count: number; statusId: number; } | undefined;
  customerDataCount: CustomerSummary[] | undefined;
  remittanceDataCount: RemitanceSummary[] | undefined;
  isOrmDashboard: boolean = false;

  constructor(
    private remittanceService: RemittanceService,
    private dialog: MatDialog,
    private snackbar: SnackBarService,
    private branchesService: BranchesService,
    private router: Router
  ) { 
    this.selectedBranch = this.branches.length > 0 ? this.branches[0].id : undefined;
  }

  ngOnInit(): void {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate());
    this.endDate = new Date();
    this.endDate.setDate(this.startDate.getDate());
    this.branchesService.getbranches().subscribe(({ appliedFilters, data }) => {
      this.branches = data.filter(branch => branch.code === 'ZATAYO');
      this.selectedBranch = this.branches.length > 0 ? this.branches[0].id : undefined;
    });
    this.loadBranches();
    this.getRemittanceSummaries(this.startDate, this.endDate);
    this.getCustomerSummaries(this.startDate, this.endDate);

    this.intervalId = setInterval(() => {
      if (this.startDate && this.endDate) {
        this.getRemittanceSummaries(this.startDate, this.endDate);
        this.getCustomerSummaries(this.startDate, this.endDate);
      }
    }, 60000);

    if (this.router.url == '/remittances/ormdashboard') {
      this.isOrmDashboard = true;
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  loadBranches(): void {
    const filters = this.branchCode || this.branchStatus
      ? {
        branchCode: this.branchCode ?? '',
        branchStatus: this.branchStatus ?? '',
      }
      : undefined;

    this.branchesService.searchBranches(filters);
  }

  onSubmit() {
    if (this.startDate && this.endDate) {
      this.getCustomerSummaries(this.startDate, this.endDate);
      this.getRemittanceSummaries(this.startDate, this.endDate);
    } else {
      this.snackbar.open('please select start date and end date');
    }
  }
  
   setLoading(isLoading: boolean, status: string): void {
    const index = this.dataSource.data.findIndex(data => data.status === status);
    if (index !== -1) {
      this.dataSource.data[index].isLoading = isLoading;
      this.dataSource._updateChangeSubscription();  
    }
  }
  
   updateSummary(status: string, count: number, statusId: number | null): void {
    const index = this.dataSource.data.findIndex(data => data.status === status);
    if (index !== -1) {
      this.dataSource.data[index].count = count;
      this.dataSource.data[index].statusId = statusId;
      this.customerDataCount = this.dataSource.data;
      this.dataSource._updateChangeSubscription();  
    }
  }

  setRemittanceLoading(isLoading: boolean, status: string): void {
    const index = this.dataSource2.data.findIndex(data => data.status === status);
    if (index !== -1) {
      this.dataSource2.data[index].isLoading = isLoading;
      this.dataSource2._updateChangeSubscription();  
    }
  }
  
   updateRemittanceSummary(status: string, count: number, statusId: number | null): void {
    const index = this.dataSource2.data.findIndex(data => data.status === status);
    if (index !== -1) {
      this.dataSource2.data[index].count = count;
      this.dataSource2.data[index].statusId = statusId;
      this.remittanceDataCount = this.dataSource2.data;
      this.dataSource2._updateChangeSubscription();  
    }
  }
  
  
  getUnverifiedCustomersSummary(startDate: Date, endDate: Date): void {
    this.setLoading(true, 'Unverified customers');
    this.remittanceService.getUnverifiedCustomersSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        // this.unverifiedCustomersCount!.count = result.count;
        // this.unverifiedCustomersCount!.count = result.id;
        this.updateSummary('Unverified customers', result.count, result.id);
        this.setLoading(false, 'Unverified customers');
      }
    });
  }
   
  
  getVerifiedCustomersSummary(startDate: Date, endDate: Date): void {
    this.setLoading(true, 'Verified customers');
    this.remittanceService.getVerifiedCustomersSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateSummary('Verified customers', result.count, result.id);
        this.setLoading(false, 'Verified customers');
      }
    });
  }
  
  getRejectedCustomersSummary(startDate: Date, endDate: Date): void {
    this.setLoading(true, 'Rejected customers');
    this.remittanceService.getRejectedCustomersSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateSummary('Rejected customers', result.count, result.id);
        this.setLoading(false, 'Rejected customers');
      }
    });
  }
  
  getTotalCustomersSummary(startDate: Date, endDate: Date): void {
    this.setLoading(true, 'Total customers');
    this.remittanceService.getTotalCustomersSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateSummary('Total customers', result.count, result.id);
        this.setLoading(false, 'Total customers');
      }
    });
  }
  
  getCustomerSummaries(startDate: Date, endDate: Date): void {
    this.getUnverifiedCustomersSummary(startDate, endDate);
    this.getVerifiedCustomersSummary(startDate, endDate);
    this.getRejectedCustomersSummary(startDate, endDate);
    this.getTotalCustomersSummary(startDate, endDate);
  }

  getSendingRemittanceSummary(startDate: Date, endDate: Date): void {
    this.setRemittanceLoading(true, 'Sending');
    this.remittanceService.getSendingRemittanceSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateRemittanceSummary('Sending', result.count, result.id);
        this.setRemittanceLoading(false, 'Sending');
      }
    });
  }

  getCancelledRemittanceSummary(startDate: Date, endDate: Date): void {
    this.setRemittanceLoading(true, 'Cancelled');
    this.remittanceService.getCancelledRemittanceSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateRemittanceSummary('Cancelled', result.count, result.id);
        this.setRemittanceLoading(false, 'Cancelled');
      }
    });
  }


  getPendingRemittanceSummary(startDate: Date, endDate: Date): void {
    this.setRemittanceLoading(true, 'Pending');
    this.remittanceService.getPendingRemittanceSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateRemittanceSummary('Pending', result.count, result.id);
        this.setRemittanceLoading(false, 'Pending');
      }
    });
  }

  getPedningAprovalRemittanceSummary(startDate: Date, endDate: Date): void {
    this.setRemittanceLoading(true, 'Pending Approval');
    this.remittanceService.getPedningAprovalRemittanceSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateRemittanceSummary('Pending Approval', result.count, result.id);
        this.setRemittanceLoading(false, 'Pending Approval');
      }
    });
  }

  getReturnedRemittanceSummary(startDate: Date, endDate: Date): void {
    this.setRemittanceLoading(true, 'Returned');
    this.remittanceService.getReturnedRemittanceSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateRemittanceSummary('Returned', result.count, result.id);
        this.setRemittanceLoading(false, 'Returned');
      }
    });
  }

  getPaidRemittanceSummary(startDate: Date, endDate: Date): void {
    this.setRemittanceLoading(true, 'Paid');
    this.remittanceService.getPaidRemittanceSummary(startDate, endDate, this.selectedBranch)
    .subscribe({
      next: (result: {[key: string]: any}) => {
        this.updateRemittanceSummary('Paid', result.count, result.id);
        this.setRemittanceLoading(false, 'Paid');
      }
    });
  }

  getRemittanceSummaries(startDate: Date, endDate: Date) {
    this.getSendingRemittanceSummary(startDate, endDate)
    this.getCancelledRemittanceSummary(startDate, endDate)
    this.getPendingRemittanceSummary(startDate, endDate)
    this.getPedningAprovalRemittanceSummary(startDate, endDate)
    this.getPaidRemittanceSummary(startDate, endDate)
    this.getReturnedRemittanceSummary(startDate, endDate)
  }

  onCustomerCountClick(statusId: number | null, limit: number): void {
    if (statusId !== null) {
      const dialogRef = this.dialog.open(OrmCustomersComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { statusId: statusId, startDate: this.startDate, endDate: this.endDate, limit: limit},
        panelClass: 'scrollable-dialog'
      });
      dialogRef.afterClosed().subscribe(() => {
        this.onSubmit()
      });
    }
  }
  
  onRemittanceCountClick(statusId: number | null, limit: number): void {
    if (statusId !== null) {
      this.dialog.open(OrmRemittancesComponent, {
        width: '1200px',
        height: '90vh',  
        disableClose: false,
        data: { statusId: statusId, startDate: this.startDate, endDate: this.endDate, limit: limit},
        panelClass: 'scrollable-dialog'
      });
    }
  }

  onCustomerRefreshClick(element: any): void {

    element.isLoading = true;
    this.dataSource.data = [...this.dataSource.data];
  
    switch (element.status) {
      case 'Unverified customers':
        this.remittanceService.getUnverifiedCustomersSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource.data = [...this.dataSource.data];  
          },
          error: (error) => {
            console.error('Error fetching unverified customer summaries', error);
            element.isLoading = false;  
            this.dataSource.data = [...this.dataSource.data];  
          }
        });
        break;
      case 'Verified customers':
        this.remittanceService.getVerifiedCustomersSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource.data = [...this.dataSource.data];  
          },
          error: (error) => {
            console.error('Error fetching verified customer summaries', error);
            element.isLoading = false;  
            this.dataSource.data = [...this.dataSource.data];
          }
        });
        break;
      case 'Rejected customers':
        this.remittanceService.getRejectedCustomersSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false;
            this.dataSource.data = [...this.dataSource.data]; 
          },
          error: (error) => {
            console.error('Error fetching rejected customer summaries', error);
            element.isLoading = false;
            this.dataSource.data = [...this.dataSource.data];
          }
        });
        break;
      case 'Total customers':
        this.remittanceService.getTotalCustomersSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false;
            this.dataSource.data = [...this.dataSource.data];
          },
          error: (error) => {
            console.error('Error fetching total customer summaries', error);
            element.isLoading = false;
            this.dataSource.data = [...this.dataSource.data]; 
          }
        });
        break;
      default:
        console.warn('Unknown status for refresh:', element.status);
        element.isLoading = false;  
        this.dataSource.data = [...this.dataSource.data];  
    }
  }  

  onRemittanceRefreshClick(element: RemitanceSummary) {

    element.isLoading = true;
    this.dataSource2.data = [...this.dataSource2.data];

    switch (element.status) {
      case 'Sending':
        this.remittanceService.getSendingRemittanceSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          },
          error: (error) => {
            console.error('Error fetching sending summaries', error);
            element.isLoading = false;  
            this.dataSource2.data = [...this.dataSource2.data];
          }
        });
        break;
      case 'Cancelled':
        this.remittanceService.getCancelledRemittanceSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          },
          error: (error) => {
            console.error('Error fetching cancelled summaries', error);
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          }
        });
        break;
      case 'Pending':
        this.remittanceService.getPendingRemittanceSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data];           
          },
          error: (error) => {
            console.error('Error fetching pending summaries', error);
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          }
        });
        break;
      case 'Pending Approval':
        this.remittanceService.getPedningAprovalRemittanceSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data];           
          },
          error: (error) => {
            console.error('Error fetching pending approval summaries', error);
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          }
        });
        break;
      case 'Returned':
        this.remittanceService.getReturnedRemittanceSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          },
          error: (error) => {
            console.error('Error fetching returned with commission summaries', error);
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          }
        });
        break;
      case 'Paid':
        this.remittanceService.getPaidRemittanceSummary(this.startDate!, this.endDate!, this.selectedBranch).subscribe({
          next: (data: any) => {
            element.count = data.count;
            element.statusId = data.id;
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          },
          error: (error) => {
            console.error('Error fetching paid summaries', error);
            element.isLoading = false; 
            this.dataSource2.data = [...this.dataSource2.data]; 
          }
        });
        break;
      default:
        console.warn('Unknown status for refresh:', element.status);
        element.isLoading = false; 
        this.dataSource2.data = [...this.dataSource2.data]; 
    }
  }  
}


interface CustomerSummary {
  status: string;
  count: number;
  statusId: number | null;
  isLoading?: boolean;
}

interface RemitanceSummary {
  status: string;
  count: number;
  statusId: number | null;
  isLoading: boolean
}

