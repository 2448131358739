// fix popups for all entities

import { Injectable } from '@angular/core';
import {
  of,
  interval,
  map,
  switchMap,
  forkJoin,
  Subscription,
  BehaviorSubject,
  Observable,
} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { sortBy } from 'lodash';
import { environment } from '../../../environments/environment';
import { HttpService } from './http.service';
import { AuthService } from '../../auth/data/auth.service';
import {
  SearchBranchCommissionRequestsResponseDto,
  SearchBranchCurrencyDto,
  SearchBranchLimitRequestsResponseDto,
  SearchManualJournalDto,
} from '../../branch/data/dto/branches.dto';
import { RemittanceCommissionConfigurationsRequestsDto } from '../../remittance/data/dto/commissionConfigurations.dto';
import { Permissions } from '../enums/permissions.enums';
import { RemittancesDto } from '../../remittance/data/dto/remittances.dto';
import {SearchRoleRequestsDto} from "../../user/data/dto/role/roles.dto";
import {SearchUserRequests} from "../../user/data/dto/user/users.dto";

type NotificationItemData =
  | SearchBranchCommissionRequestsResponseDto
  | SearchBranchCurrencyDto
  | SearchBranchLimitRequestsResponseDto
  | SearchManualJournalDto
  | RemittanceCommissionConfigurationsRequestsDto
  | RemittancesDto
  | SearchRoleRequestsDto
  | SearchUserRequests;

export interface Notification {
  id: number;
  type:
    | 'limitRequest'
    | 'commissionRequest'
    | 'currencyRequest'
    | 'commissionConfigRequest'
    | 'mJRequest'
    | 'remittanceApproval'
    | 'roleRequest'
    | 'userRequest';
  branchCode?: string;
  requestedBy: string;
  date: Date;
  primaryApprovalRequired: boolean;
  secondaryApprovalRequired?: boolean;
  itemData: NotificationItemData;
}

@Injectable({ providedIn: 'root' })
export class PoolingService {
  private poolingInterval = environment.poolingInterval;
  private intervalHandler?: Subscription;
  private poolingStarted: boolean = false;
  private _notifications = new BehaviorSubject<Notification[]>([]);

  notifications: Observable<Notification[]>;

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
  ) {
    this.notifications = this._notifications;
  }

  get canApproveRequests() {
    return this.authService.isUserAuthorized([
      Permissions.branchManagement.limitChangeRequests
        .UPDATE_BRANCH_LIMIT_CHANGE_REQUESTS,
      Permissions.branchManagement.commissionChangeRequests
        .UPDATE_BRANCH_COMMISSION_CHANGE_REQUESTS,
      Permissions.branchManagement.rateChangeRequests
        .UPDATE_BRANCH_RATE_CHANGE_REQUESTS,
      Permissions.remittanceManagement.commissionConfiguration
        .UPDATE_REMIT_COMM_CONFIG,
      Permissions.manualJournals.journals.APPROVE_MANUAL_JOURNAL,
      Permissions.manualJournals.journals.REJECT_MANUAL_JOURNAL,
      Permissions.remittanceManagement.remittances.APPROVE_BRANCH_VIOLATION,
      Permissions.remittanceManagement.remittances.APPROVE_COMPLIANCE_VIOLATION,
      Permissions.userManagement.roleRequests.UPDATE_ROLE_REQUESTS,
      Permissions.userManagement.userRequests.UPDATE_USER_REQUESTS,
    ]);
  }

  get canPool() {
    return this.canApproveRequests && this.poolingInterval;
  }

  get pooling() {
    return this.poolingStarted;
  }

  start() {
    if (this.intervalHandler) {
      this.intervalHandler.unsubscribe();
      this.intervalHandler = undefined;
    }

    if (this.canPool) {
      this.refreshData();
      this.intervalHandler = interval(this.poolingInterval)
        .pipe(switchMap(this.fetchData))
        .subscribe();
    }
  }

  stop() {
    this.intervalHandler?.unsubscribe();
  }

  refreshData() {
    this.fetchData().subscribe();
  }

  private fetchData = () => {
    this.poolingStarted = true;
    return forkJoin([
      this.getData<SearchBranchLimitRequestsResponseDto[]>(
        'branch/branchLimitApprovals/search',
        Permissions.branchManagement.limitChangeRequests
          .UPDATE_BRANCH_LIMIT_CHANGE_REQUESTS,
      ),
      this.getData<SearchBranchCommissionRequestsResponseDto[]>(
        'branch/branchCommissionApprovals/search',
        Permissions.branchManagement.commissionChangeRequests
          .UPDATE_BRANCH_COMMISSION_CHANGE_REQUESTS,
      ),
      this.getData<SearchBranchCurrencyDto[]>(
        'branch/currencies/search',
        Permissions.branchManagement.rateChangeRequests
          .UPDATE_BRANCH_RATE_CHANGE_REQUESTS,
      ),
      this.getData<RemittanceCommissionConfigurationsRequestsDto[]>(
        'remittance/commissionconfiguration/request/list',
        Permissions.remittanceManagement.commissionConfiguration
          .UPDATE_REMIT_COMM_CONFIG,
      ),
      this.getData<SearchManualJournalDto[]>('branch/journal/search', [
        Permissions.manualJournals.journals.APPROVE_MANUAL_JOURNAL,
        Permissions.manualJournals.journals.REJECT_MANUAL_JOURNAL,
      ]),
      this.getData<RemittancesDto[]>(
        'remittance/search',
        [
          Permissions.remittanceManagement.remittances.APPROVE_BRANCH_VIOLATION,
          Permissions.remittanceManagement.remittances
            .APPROVE_COMPLIANCE_VIOLATION,
        ],
        { statusId: 4, direction: 2 },
      ),
      this.getData<SearchRoleRequestsDto[]>(
        'role/request/list',
        [
          Permissions.userManagement.roleRequests.UPDATE_ROLE_REQUESTS,
        ],
        { statusId: 4},
      ),
      this.getData<SearchUserRequests[]>(
        'user/request/list',
        [
          Permissions.userManagement.userRequests.UPDATE_USER_REQUESTS,
        ],
        { statusId: 3},
      ),
    ])
      .pipe(
        map(
          ([
            limitRequests,
            commissionRequests,
            currenciesRequests,
            commConfigRequests,
            journalRequests,
            remittances,
            roleRequests,
            userRequests,
          ]) => {
            this.poolingStarted = false;
            let data: Notification[] = [];
            data = data.concat(
              limitRequests
                .filter((r) => r.canApprove)
                .map(PoolingService.mapLimitReq),
            );
            data = data.concat(
              commissionRequests
                .filter((r) => r.canApprove)
                .map(PoolingService.mapCommissionReq),
            );
            data = data.concat(
              currenciesRequests
                .filter((r) => r.canApprove)
                .map(PoolingService.mapCurrencyReq),
            );
            data = data.concat(
              commConfigRequests
                .filter((r) => r.canApprove)
                .map(PoolingService.mapCommConfigReq),
            );
            data = data.concat(
              journalRequests
                .filter((r) => r.canApprove || r.canReject)
                .map(PoolingService.mapMJReq),
            );
            data = data.concat(
              remittances
                .filter((r) => r.canApprove || r.canApproveCompliances)
                .map(PoolingService.mapRemittance),
            );
            data = data.concat(
              roleRequests
                .filter((r) => r.canApprove)
                .map(PoolingService.mapRoleReq),
            );
            data = data.concat(
              userRequests
                .filter((r) => r.canApprove)
                .map(PoolingService.mapUserReq),
            );
            data = sortBy(data, (d) => d.date);
            this._notifications.next(data);

            return true;
          },
        ),
      )
      .pipe(
        catchError(() => {
          this.poolingStarted = false;
          return of(false);
        }),
      );
  };

  private getData<T>(
    url: string,
    permissions: string | string[],
    params: any = undefined,
  ) {
    if (!this.authService.isUserAuthorized(permissions)) {
      return of([] as unknown as T);
    }

    return this.httpService
      .get<T>(url, {
        ...(params ? params : { statusId: 4 }),
        __backgroundRequest: true,
      })
      .pipe(catchError((error) => of(error)))
      .pipe(
        map((resp: T | Error) =>
          resp instanceof Error ? ([] as unknown as T) : resp,
        ),
      );
  }

  private static mapLimitReq(
    lr: SearchBranchLimitRequestsResponseDto,
  ): Notification {
    return {
      id: lr.id,
      type: 'limitRequest',
      date: lr.createdDate,
      branchCode: lr.branchCode,
      requestedBy: lr.requestedBy,
      primaryApprovalRequired: lr.canApprove,
      itemData: lr,
    };
  }

  private static mapCommissionReq(
    cr: SearchBranchCommissionRequestsResponseDto,
  ): Notification {
    return {
      id: cr.id,
      type: 'commissionRequest',
      date: cr.createdDate,
      branchCode: cr.branchCode,
      requestedBy: cr.requestedBy,
      primaryApprovalRequired: cr.canApprove,
      itemData: cr,
    };
  }

  private static mapCurrencyReq(cr: SearchBranchCurrencyDto): Notification {
    return {
      id: cr.id,
      type: 'currencyRequest',
      date: cr.createdDate,
      branchCode: cr.branchCode,
      requestedBy: cr.requestedBy,
      primaryApprovalRequired: cr.canApprove,
      itemData: cr,
    };
  }

  private static mapCommConfigReq(
    cr: RemittanceCommissionConfigurationsRequestsDto,
  ): Notification {
    return {
      id: cr.id,
      type: 'commissionConfigRequest',
      date: cr.createdDate,
      requestedBy: cr.requestedBy,
      primaryApprovalRequired: cr.canApprove,
      itemData: cr,
    };
  }

  private static mapMJReq(cr: SearchManualJournalDto): Notification {
    return {
      id: cr.manualJournalId,
      type: 'mJRequest',
      date: cr.journalDate,
      branchCode: cr.branchCode,
      requestedBy: cr.username,
      primaryApprovalRequired: cr.canApprove,
      itemData: cr,
    };
  }

  private static mapRemittance(cr: RemittancesDto): Notification {
    return {
      id: cr.id,
      type: 'remittanceApproval',
      date: cr.sendDate,
      branchCode: cr.sendingBranchCode,
      requestedBy: cr.complianceStatus,
      primaryApprovalRequired: cr.canApprove,
      secondaryApprovalRequired: cr.canApproveCompliances,
      itemData: cr,
    };
  }

  private static mapRoleReq(
    cr: SearchRoleRequestsDto,
  ): Notification {
    return {
      id: cr.id,
      type: 'roleRequest',
      date: cr.createdDate,
      requestedBy: cr.requestedBy,
      primaryApprovalRequired: cr.canApprove,
      itemData: cr,
    };
  }

  private static mapUserReq(
    cr: SearchUserRequests,
  ): Notification {
    return {
      id: cr.id,
      type: 'userRequest',
      date: cr.createdDate,
      branchCode: cr.branch,
      requestedBy: cr.requestedBy,
      primaryApprovalRequired: cr.canApprove,
      itemData: cr,
    };
  }

}
