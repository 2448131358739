<app-index-container label="{{ 'Finsurv Reconciliation Report' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row">
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" [value]="fromDate.value" />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" [value]="toDate.value" />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="row mt-2">
            <div class="col-sm-6 col-md-6 col-lg-3 mt-2">
                <mat-label>{{ 'Transaction Category' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="transactionCategory">
                    <div class="radio-group-row">
                        <mat-radio-button class="material-radio" value="1" style="margin-left: 5px">{{ 'Reportable Transactions' | translate }}</mat-radio-button>
                        <!-- <mat-radio-button class="material-radio" value="2" style="margin-left: 5px">{{ 'Non-Reportable Transactions' | translate }}</mat-radio-button>
                        <mat-radio-button class="material-radio" value="3" style="margin-left: 5px">{{ 'Interbank Transactions' | translate }}</mat-radio-button> -->
                    </div>
                </mat-radio-group>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button mat-raised-button type="submit" color="primary">
                <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
            </button>
        </div>
    </form>
    <table table mat-table [dataSource]="remittances" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="#">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        <!-- date Column -->
        <!-- Partner Remittance ID Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ 'Date' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.sDate }}</td>
        </ng-container>

        <ng-container matColumnDef="batchNo">
            <th mat-header-cell *matHeaderCellDef>{{ 'Batch No' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.batchNo }}</td>
        </ng-container>

        <ng-container matColumnDef="totalTransactions">
            <th mat-header-cell *matHeaderCellDef> {{ 'Total transactions' | translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.totalTransactions > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                    (click)="row.totalTransactions > 0 ? viewReconciliationDetails(row.sDate, 1, row.batchNo) : null">
                    {{ row.totalTransactions }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="waitingTobeSubmitted">
            <th mat-header-cell *matHeaderCellDef> {{ 'Waiting To be Submitted' | translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.totalNotSubmitted > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}" (click)="row.totalNotSubmitted > 0 ? viewReconciliationDetails(row.sDate, 2, row.batchNo) : null">
                {{ row.totalNotSubmitted }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="submittedToSARBAndWaitingFinalResponse">
            <th mat-header-cell *matHeaderCellDef>{{ 'Submitted To SARB and waiting final response' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.totalSubmittedWaitingResponse > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                    (click)="row.totalSubmittedWaitingResponse > 0 ? viewReconciliationDetails(row.sDate, 3, row.batchNo) : null">
                {{ row.totalSubmittedWaitingResponse }}
                </span>
            </td>
        </ng-container>

        <!-- submittedToSARBAndFailed Column -->
        <ng-container matColumnDef="submittedToSARBAndFailed">
            <th mat-header-cell *matHeaderCellDef>{{ 'Submitted to SARB and failed' | translate }}</th>
            <td mat-cell *matCellDef="let row">
            <span [ngStyle]="row.totalSubmittedFailed > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                (click)="row.totalSubmittedFailed > 0 ? viewReconciliationDetails(row.sDate, 4, row.batchNo) : null">
                {{ row.totalSubmittedFailed }}
            </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="fullyReportedToSARB">
            <th mat-header-cell *matHeaderCellDef>{{ 'Fully reported to SARB' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.totalReported > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                    (click)="row.totalReported > 0 ? viewReconciliationDetails(row.sDate, 5, row.batchNo) : null">
                {{ row.totalReported }}
                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                {{ 'noRecordsAvailable' | translate }}
            </td>
        </tr>
    </table>
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>