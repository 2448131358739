import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import * as XLSX from "xlsx";
import { Utilities } from '../_common/utils';
import { OutgoingRemittancesReport } from "./data/Dto/CbkRemittance.dto";



@Component({
  selector: 'app-outgoing-report-popup',
  templateUrl: './outgoing-report-popup.html',
})
export class OutgoingReportPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<OutgoingReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: OutgoingRemittancesReport,
  ) { }

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
      ? Utilities.convertDateToLocaleString(date, '')
      : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('outgoingReport'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'outgoingReport');
    XLSX.writeFile(
      wb,
      'Outgoing Remittance Report' +
      this.convertDateToLocaleString(new Date(), false) +
      '.xlsx',
    );
  }
}
