<app-form-container [label]="'accountActivityReport' | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select
          #branchId
          formControlName="branchId"
          (selectionChange)="onBranchChange($event.value)"
        >
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'account' | translate }}:</mat-label>
        <mat-select
          formControlName="accountId"
          (selectionChange)="onAccountChange($event.value)"
        >
          <mat-option *ngFor="let account of accounts" [value]="account.id">
            {{ account.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'from' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="requestedDateStart"
          formControlName="startDate"
          [value]="startDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="requestedDateStart"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #requestedDateStart></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'to' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="requestedDateEnd"
          formControlName="endDate"
          [value]="endDate.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="requestedDateEnd"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #requestedDateEnd></mat-datepicker>
      </mat-form-field>
    </div>
    <div *ngIf="showInAccCurrencyCb" class="row mt-2">
      <mat-label>{{ 'showInAccCurrency' | translate }}:</mat-label>
      <mat-checkbox
        matInput
        class="mb-3"
        color="primary"
        formControlName="showInAccCurrency"
      >
        {{ accountCurrency }}
      </mat-checkbox>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button mat-raised-button type="submit" color="primary">
        {{ 'search' | translate }}
      </button>
    </div>
  </form>
</app-form-container>
