<aside id="sidebar" class="sidebar">
  <ul class="sidebar-nav list-unstyled" id="sidebar-nav">
    <li class="nav-item">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        routerLink="/dashboard"
        routerLinkActive="routerActive"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <i class="fa-solid fa-house mx-2"></i>
        <span class="">{{ 'dashboard' | translate }}</span>
      </a>
    </li>

    <!--  USER MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('userManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#userManagement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-user mx-2"></i>
        <span class="">{{ 'userManagement' | translate }}</span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>

      <ul
        id="userManagement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('userManagement', 'roles')"
        >
          <a class="align-items-center" routerLink="users/roles">
            <i class="fa-solid fa-user-lock"></i>
            <span class="ms-1">{{ 'roles' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('userManagement', 'roleRequests')"
        >
          <a class="align-items-center" routerLink="users/roles/requests">
            <i class="fa-solid fa-user-lock"></i>
            <span class="ms-1">{{ 'roleRequests' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('userManagement', 'users')"
        >
          <a class="align-items-center" routerLink="users">
            <i class="fa-solid fa-user-group"></i>
            <span class="ms-1">{{ 'users' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('userManagement', 'userRequests')"
        >
          <a class="align-items-center" routerLink="users/requests">
            <i class="fa-solid fa-users"></i>
            <span class="ms-1">{{ 'userRequests' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('userManagement', 'logs')"
        >
          <a class="align-items-center" routerLink="users/logs">
            <i class="fa-solid fa-user-group"></i>
            <span class="ms-1">{{ 'logs' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('userManagement', 'loginHistories')"
        >
          <a class="align-items-center" routerLink="users/loginhistories">
            <i class="fa-solid fa-history"></i>
            <span class="ms-1">{{ 'loginHistory' | translate }}</span>
          </a>
        </li>
      </ul>
    </li>

    <!--  BRANCH MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('branchManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#branchManagement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-building-user mx-2"></i>
        <span class="">{{ 'branchManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="branchManagement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('branchManagement', 'branches')"
        >
          <a class="align-items-center" routerLink="branches">
            <i class="fa-solid fa-building-user"></i>
            <span class="ms-1">{{ 'branches' | translate }}</span>
          </a>
        </li>
<!--        <li-->
<!--          routerLinkActive="routerActive"-->
<!--          [routerLinkActiveOptions]="{ exact: true }"-->
<!--          *ngIf="canActivate('branchManagement', 'branchCommissions')"-->
<!--        >-->
<!--          <a class="align-items-center" routerLink="branches/branchCommissions">-->
<!--            <i class="fa-solid fa-coins"></i>-->
<!--            <span class="ms-1">{{ 'branchCommissions' | translate }}</span>-->
<!--          </a>-->
<!--        </li>-->
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('branchManagement', 'rateChangeRequests')"
        >
          <a
            class="align-items-center"
            routerLink="branches/rateChangeRequests"
          >
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{ 'rateChangeRequests' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('branchManagement', 'limitChangeRequests')"
        >
          <a
            class="align-items-center"
            routerLink="branches/limitChangeRequests"
          >
            <i class="fa-solid fa-sack-dollar"></i>
            <span class="ms-1">{{ 'branchLimitRequests' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('branchManagement', 'commissionChangeRequests')"
        >
          <a
            class="align-items-center"
            routerLink="branches/commissionChangeRequests"
          >
            <i class="fa-solid fa-hand-holding-dollar"></i>
            <span class="ms-1">{{
              'branchCommissionRequests' | translate
            }}</span>
          </a>
        </li>
      </ul>
    </li>

    <!--  MANUAL JOURNALS   -->
    <li class="nav-item" *ngIf="canActivate('manualJournals')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#journalsManagement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-book mx-2"></i>
        <span class="">{{ 'journalsManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="journalsManagement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('manualJournals', 'journals')"
        >
          <a class="align-items-center" routerLink="branches/journals">
            <i class="fa-solid fa-book"></i>
            <span class="ms-1">{{ 'manualJournals' | translate }}</span>
          </a>
        </li>
      </ul>
    </li>

    <!--  CUSTOMER MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('customerManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#customerManagement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-building-user mx-2"></i>
        <span class="">{{ 'customerManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="customerManagement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('customerManagement', 'customers')"
        >
          <a class="align-items-center" routerLink="customers">
            <i class="fa-solid fa-building-user"></i>
            <span class="ms-1">{{ 'customers' | translate }}</span>
          </a>
        </li>

        <li
        routerLinkActive="routerActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="canActivate('customerManagement', 'blackList')"
      >
        <a class="align-items-center" routerLink="customers/blacklist">
          <i class="fa-solid fa-user-slash"></i>
          <span class="ms-1">{{ 'blackList' | translate }}</span>
        </a>
      </li>

      <li
      routerLinkActive="routerActive"
      [routerLinkActiveOptions]="{ exact: true }"
      *ngIf="canActivate('customerManagement', 'blackListSanctionAuditLogs')"
    >
      <a class="align-items-center" routerLink="customers/audit-logs">
        <i class="fa-solid fa-clipboard-list"></i>
        <span class="ms-1">{{ 'BlacklistSanctionedAuditLogs' | translate }}</span>
      </a>
    </li>
    <li
      routerLinkActive="routerActive"
      [routerLinkActiveOptions]="{ exact: true }"
      *ngIf="canActivate('customerManagement', 'ClientScreening')"
    >
      <a class="align-items-center" routerLink="customers/client-screening">
        <i class="fa-solid fa-clipboard-list"></i>
        <span class="ms-1">{{ 'ClientScreening' | translate }}</span>
      </a>
    </li>
      </ul>
    </li>

    <!--  REMITTANCE  MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('remittanceManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#remittanceManagement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-sack-dollar mx-2"></i>
        <span class="">{{ 'remittanceManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="remittanceManagement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('remittanceManagement', 'remittances')"
        >
          <a class="align-items-center" routerLink="remittances">
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">{{ 'remittances' | translate }}</span>
          </a>
        </li>
        <li
        routerLinkActive="routerActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="canActivate('remittanceManagement', 'ormdashboard')"
      >
        <a class="align-items-center" routerLink="remittances/ormdashboard">
          <i class="fa-solid fa-money-bill-transfer"></i>
          <span class="ms-1">{{ 'ormDashboard' | translate }}</span>
        </a>
      </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('remittanceManagement', 'ambiguousRemittances')"
        >
          <a class="align-items-center" routerLink="remittances/ambiguousremittances">
            <i class="fa-solid fa-circle-exclamation"></i>
            <span class="ms-1">{{ 'ambiguousRemittances' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('remittanceManagement', 'commissionConfiguration')"
        >
          <a
            class="align-items-center"
            routerLink="remittances/commissionconfigs"
          >
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{ 'remitCommConfigs' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="
            canActivate(
              'remittanceManagement',
              'commissionConfigurationRequests'
            )
          "
        >
          <a
            class="align-items-center"
            routerLink="remittances/commissionconfigs/commissionConfigurationRequests"
          >
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">
              {{ 'commissionConfigurationRequests' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('remittanceManagement', 'notifications')"
        >
          <a class="align-items-center" routerLink="notifications">
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{ 'notifications' | translate }}</span>
          </a>
        </li>

        <li routerLinkActive="routerActive" [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('remittanceManagement', 'finsurvTransactions')">
          <a class="align-items-center" routerLink="remittances/finsurvtransactions">
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{'Finsurv Transactions' | translate }}</span>
          </a>
        </li>

        <li routerLinkActive="routerActive" [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('remittanceManagement', 'finsurv')">
          <a class="align-items-center" routerLink="remittances/finsurvreports">
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{'Finsurv Reports' | translate }}</span>
          </a>
        </li>

      </ul>
    </li>

    <!-- Referal -->

    <li class="nav-item" *ngIf="canActivate('REFERAL_MANAGEMENT') && isReferal">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#referal-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-sack-dollar mx-2"></i>
        <span class="">{{ 'referal' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="referal-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('REFERAL_MANAGEMENT', 'referals')"
        >
          <a class="align-items-center" routerLink="referals">
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">
              {{ 'referalDashboard' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </li>


    <!--  COMPLIANCE  MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('complianceManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#compliance-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-sack-dollar mx-2"></i>
        <span class="">{{ 'complianceManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="compliance-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="
            canActivate('complianceManagement', 'complianceConfigurations')
          "
        >
          <a class="align-items-center" routerLink="compliances/configuration">
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">
              {{ 'complianceConfigurations' | translate }}
            </span>
          </a>
        </li>
        
        <li routerLinkActive="routerActive" [routerLinkActiveOptions]="{ exact: true }" *ngIf="canActivate('complianceManagement', 'searchSanctions')">
          <a class="align-items-center" routerLink="compliances/searchSanctions">
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">
              {{ 'Search Sanctions' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </li>

    <!-- Account Management-->

    <li class="nav-item" *ngIf="canActivate('accountsManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#accountsmanagement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-user-group mx-2"></i>
        <span class="">{{ 'accountsManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="accountsmanagement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('accountsManagement', 'accounts')"
        >
          <a class="align-items-center" routerLink="branches/accounts">
            <i class="fa-solid fa-user"></i>
            <span class="ms-1">
              {{ 'accounts' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </li>

    <!--  SETTLEMENT  MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('settlementManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#settlement-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-sack-dollar mx-2"></i>
        <span class="">{{ 'settlementManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="settlement-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('settlementManagement', 'settlements')"
        >
          <a class="align-items-center" routerLink="settlements">
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">
              {{ 'settlements' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </li>

    <!--  REPORT  MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('reportManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#report-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-folder mx-2"></i>
        <span class="">{{ 'reportManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="report-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
<!--        <li-->
<!--          routerLinkActive="routerActive"-->
<!--          [routerLinkActiveOptions]="{ exact: true }"-->
<!--          *ngIf="canActivate('reportManagement', 'reports')"-->
<!--        >-->
<!--          <a-->
<!--            class="align-items-center"-->
<!--            routerLink="reports/view/monthlyCommissions"-->
<!--          >-->
<!--            <i class="fa-solid fa-file-invoice"></i>-->
<!--            <span class="ms-1">-->
<!--              {{ 'monthlyCommissions' | translate }}-->
<!--            </span>-->
<!--          </a>-->
<!--        </li>-->
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'REMITANCE_ROPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/remittanceReports"
          >
            <i class="fa-solid fa-file-invoice-dollar"></i>
            <span class="ms-1">{{ 'remittanceReport' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'TRIAL_BALANCE_REPORT')"
        >
          <a class="align-items-center" routerLink="reports/view/trialbalance">
            <i class="fa-solid fa-file-invoice-dollar"></i>
            <span class="ms-1">
              {{ 'trialBalance' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'BRANCH_STATEMENT_REPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/branchstatement"
          >
            <i class="fa-solid fa-file-invoice-dollar"></i>
            <span class="ms-1">
              {{ 'branchStatement' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'SETTLEMENT_REPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/settlementreport"
          >
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">
              {{ 'settlementReport' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'ACCOUNT_ACTIVITY_REPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/accountActivityReports"
          >
            <i class="fa-solid fa-solid fa-receipt"></i>
            <span class="ms-1">{{ 'accountActivityReport' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'UNPAID_REMITTANCE_REPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/unpaidremittancereport"
          >
            <i class="fa-solid fa-solid fa-receipt"></i>
            <span class="ms-1">{{ 'unPaidRemittanceReport' | translate }}</span>
          </a>
        </li>
        <li
        routerLinkActive="routerActive"
        [routerLinkActiveOptions]="{ exact: true }"
        *ngIf="canActivate('reportManagement', 'COMPLIANCE_REPORT')"
      >
        <a
          class="align-items-center"
          routerLink="reports/view/compliancereport"
        >
          <i class="fa-solid fa-solid fa-sack-dollar"></i>
          <span class="ms-1">{{ 'complianceReport' | translate }}</span>
        </a>
      </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'PARTNER_SETTLEMENT_REPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/partnersettlementreport"
          >
            <i class="fa-solid fa-money-bill-transfer"></i>
            <span class="ms-1">{{ 'partnerSettlementReport' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'CUSTOMER_REPORT')"
        >
          <a
            class="align-items-center"
            routerLink="reports/view/customerreport"
          >
            <i class="fa-solid fa-building-user"></i>
            <span class="ms-1">{{ 'customerReport' | translate }}</span>
          </a>
        </li>

        <li routerLinkActive="routerActive" [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'FINSURV_RECONCILIATION_REPORT')">
          <a class="align-items-center" routerLink="reports/view/finsurvreconciliationreport">
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{'Finsurv Reconciliation Report' | translate }}</span>
          </a>
        </li>

        <li routerLinkActive="routerActive" [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'CUSTOMER_RISK_RATING_REPORT')">
          <a class="align-items-center" routerLink="reports/view/customerriskratingreport">
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{'Customer Risk Rating Report' | translate }}</span>
          </a>
        </li>

        <li routerLinkActive="routerActive" [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('reportManagement', 'KITEWORK_TRANSACTIONS')">
          <a class="align-items-center" routerLink="reports/view/kiteworktransactions">
            <i class="fa-solid fa-coins"></i>
            <span class="ms-1">{{'kitework Transactions' | translate }}</span>
          </a>
        </li>

      </ul>
    </li>

    <!-- CBK reports   MANAGEMENT-->
    <li class="nav-item" *ngIf="canActivate('CBK_REPORT')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#cbk-report-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fas fa-file-invoice-dollar mx-2"></i>
        <span class="">{{ 'cbkReports' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="cbk-report-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('CBK_REPORT', 'incomingRemittance')"
        >
          <a
            class="align-items-center"
            routerLink="cbkreports/view/incomingremittance"
          >
            <i class="fas fa-money-check-alt"></i>
            <span class="ms-2">
              {{ 'incomingReport' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('CBK_REPORT', 'outgoingRemittance')"
        >
          <a
            class="align-items-center"
            routerLink="cbkreports/view/outgoingremittance"
          >
            <i class="fa fa-exchange-alt"></i>
            <span class="ml-1">
              {{ 'outgoingReport' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('CBK_REPORT', 'bsmvTax')"
        >
          <a
            class="align-items-center"
            routerLink="cbkreports/view/bsmvtx"
          >
            <i class="fa-solid fa fa-money-bill-wave"></i>
            <span class="ml-1">
              {{ 'bsmvTax' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('CBK_REPORT', 'clientProtection')"
        >
          <a
            class="align-items-center"
            routerLink="cbkreports/view/clientprotectionaccount"
          >
            <i class="fa fa-shield-alt"></i>
            <span class="ml-1">
              {{ 'clientProtectionAccount' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('CBK_REPORT', 'clientProtectionCustomer')"
        >
          <a
            class="align-items-center"
            routerLink="cbkreports/view/clientprotectioncustomer"
          >
            <i class="fa fa-shield-alt"></i>
            <span class="ml-1">
              {{ 'clientProtectionCustomerReport' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('CBK_REPORT', 'btransReport')"
        >
          <a
            class="align-items-center"
            routerLink="cbkreports/view/generateparquetreports"
          >
            <i class="fa fa-flag-checkered"></i>
            <span class="ml-1">
              {{ 'btransReports' | translate }}
            </span>
          </a>
        </li>
      </ul>
    </li>

    <!--  LOOKUPS  MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('lookupsManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#lookups-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-search-plus mx-2"></i>
        <span class="">{{ 'lookupsManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="lookups-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'remittancePurposes')"
        >
          <a
            class="align-items-center"
            routerLink="lookups/view/remittancePurposes"
          >
            <mat-icon>adjust</mat-icon>
            <span class="ms-1">
              {{ 'remittancePurposes' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'remittanceRelationships')"
        >
          <a
            class="align-items-center"
            routerLink="lookups/view/remittanceRelationships"
          >
            <mat-icon>layers</mat-icon>
            <span class="ms-1">
              {{ 'remittanceRelationships' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'occupations')"
        >
          <a class="align-items-center" routerLink="lookups/view/occupations">
            <mat-icon>work</mat-icon>
            <span class="ms-1">
              {{ 'occupations' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'sourceOfFunds')"
        >
          <a class="align-items-center" routerLink="lookups/view/sourceOfFunds">
            <mat-icon>credit_card</mat-icon>
            <span class="ms-1">
              {{ 'sourceOfFunds' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'cities')"
        >
          <a class="align-items-center" routerLink="lookups/view/cities">
            <mat-icon>location_city</mat-icon>
            <span class="ms-1">
              {{ 'cities' | translate }}
            </span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'localities')"
        >
          <a class="align-items-center" routerLink="lookups/view/localities">
            <mat-icon>apartment</mat-icon>
            <span class="ms-1"> {{ 'localities' | translate }}</span>
          </a>
        </li>
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('lookupsManagement', 'customerDocumentTypes')"
        >
          <a
            class="align-items-center"
            routerLink="lookups/view/customerdocumenttypes"
          >
            <mat-icon>folder</mat-icon>
            <span class="ms-1"> {{ 'customerDocumentTypes' | translate }}</span>
          </a>
        </li>
      </ul>
    </li>

    <!--  Support Tickets  MANAGEMENT -->

    <li class="nav-item" *ngIf="canActivate('ticketManagement')">
      <a
        class="nav-link btn btn-toggle rounded collapsed p-2 ms-0 d-inline-flex"
        data-bs-target="#support-nav"
        data-bs-toggle="collapse"
        href="#"
      >
        <i class="fa-solid fa-headset mx-2"></i>
        <span class="">{{ 'ticketManagement' | translate }} </span>
        <i class="fa fa-angle-right align-item-center ms-auto p-1"></i>
      </a>
      <ul
        id="support-nav"
        class="nav-content collapse btn-toggle-nav list-unstyled fw-normal"
        data-bs-parent="#sidebar-nav"
      >
        <li
          routerLinkActive="routerActive"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="canActivate('ticketManagement', 'tickets')"
        >
          <a class="align-items-center" routerLink="support/tickets">
            <i class="fa-solid fa-headset mx-2"></i>
            <span class="ms-1">{{ 'supportTickets' | translate }}</span>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</aside>
