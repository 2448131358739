import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-remittance-trace',
  templateUrl: './remittanceTracePopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class RemittanceTracePopupComponent implements OnInit {
  comments: string = '';
  type: string = '';
  tracingOptions: LookupDto[] = [];

  form!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemittanceTracePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LookupDto[],
    private fb: UntypedFormBuilder,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.tracingOptions = this.data;
    this.form = this.fb.group({
      tracingOption: [null, [Validators.required]],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
