<h1 mat-dialog-title>{{ 'updateStatus' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-1 overflow-y-auto">
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2 overflow-hidden"
      *ngIf="data"
    >
      <div
        class="d-flex align-items-center h-9x bg-light px-2 border-bottom border-dark"
      >
        <b>{{ 'userRequestDetails' | translate }}</b>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'userType' | translate }}:
          <p>{{ data.userType }}</p>
        </div>
        <div class="col">
          {{ 'requestingUser' | translate }}:
          <p>{{ data.requestedBy }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'firstName' | translate }}:
          <p>{{ data.firstName }}</p>
        </div>
        <div class="col">
          {{ 'lastName' | translate }}:
          <p>{{ data.lastName }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'email' | translate }}:
          <p>{{ data.email }}</p>
        </div>
        <div class="col">
          {{ 'mobile' | translate }}:
          <p>{{ data.mobilePhone }}</p>
        </div>
        <div class="col">
          {{ 'branch' | translate }}:
          <p>{{ data.branch }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'username' | translate }}:
          <p>{{ data.username }}</p>
        </div>
        <div class="col">
          {{ 'role' | translate }}:
          <p>{{ data.role }}</p>
        </div>
        <div class="col">
          {{ 'isTeller' | translate }}:
          <p>{{ data.isTeller }}</p>
        </div>
        <div class="col">
          {{ 'requestDate' | translate }}:
          <p>{{ convertDateToLocaleString(data.createdDate) }}</p>
        </div>
        <div class="col">
          {{ 'requesterComments' | translate }}:
          <p>{{ data.requesterComments }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'isActive' | translate }}:
          <p>{{ data.isActive }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- ... -->
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-label class="mb-4">{{ 'status' | translate }}:</mat-label>
      <mat-radio-group formControlName="statusType" class="mb-4">
        <mat-radio-button value="approveUserRequest">
          {{ 'approveUserRequest' | translate }}
        </mat-radio-button>
        <mat-radio-button value="rejectUserRequest">
          {{ 'rejectUserRequest' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">{{ 'close' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!form.valid"
    (click)="onSubmit()"
  >
    {{ 'update' | translate }}
  </button>
</div>
