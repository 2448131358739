import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AttachmentsPopupComponent} from "./attachmentsPopup.component";


@Component({
  selector: 'remittance-receipt-popup.component',
  templateUrl: './remittanceReceiptPop.component.html'
})
export class RemittanceReceiptPopupComponent implements OnInit {
  title: string = '';

  constructor(
    public dialogRef: MatDialogRef<RemittanceReceiptPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      remittanceDocuments: any
    },
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onViewAttachment(attachments: string[] | undefined) {
    this.dialog.open(AttachmentsPopupComponent, {
      width: '1000px',
      data: {attachments, isReceiptAttachment: true},
    });
  }

}
