import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/data/auth.service';
import { BranchesService } from './data/branches.service';
import {
  BranchConfiguration,
  SearchManualJournalDto,
  UpdateManualJournalDto,
} from './data/dto/branches.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { Permissions } from '../_common/enums/permissions.enums';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-manual-journal-receipt',
  templateUrl: './manualJournalReceiptPopup.component.html',
  styleUrls: ['./journalReceipt.css'],
})
export class ManualJournalReceiptPopupComponent implements OnInit {
  permissions = Permissions.manualJournals.journals;
  branchConfigurations: BranchConfiguration[] = [];
  branchLanguage!: string;
  canApprove: boolean = false;
  canReject: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ManualJournalReceiptPopupComponent>,
    public dialog: MatDialog,
    public authService: AuthService,
    public branchService: BranchesService,
    private translateService: TranslateService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: SearchManualJournalDto,
  ) {}

  ngOnInit() {
    this.branchService.branchById(this.data.branchId).subscribe((branches) => {
      this.branchConfigurations = branches.find(
        (branch) => branch.id === this.data.branchId,
      )?.branchConfigurations as BranchConfiguration[];
      this.branchLanguage = this.branchConfigurations.find(
        (bc) => bc.configurationType === 'language',
      )?.configuration.branchLanguage;
      this.translateService.use(this.branchLanguage);
    }, ({ message }) => {
      this.snackBar.open(message);
    });
    this.branchService
      .getManualJournalDetails(this.data.manualJournalId)
      .subscribe(
        (data) => {
          this.data = data;
          this.canApprove = data.canApprove;
          this.canReject = data.canReject;
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  checkNullValue(value: string | undefined) {
    if (value === null) value = '';
    return value;
  }

  updateJournal(id: number, type: 'approve' | 'reject') {
    const dto: UpdateManualJournalDto = { id };
    (type === 'approve'
      ? this.branchService.approveManualJournal(dto)
      : this.branchService.rejectManualJournal(dto)
    ).subscribe(
      () => {
        this.snackBar.open(type === 'approve' ? 'manualJournalApprovedSuccess' : 'manualJournalRejectedSuccess');
        this.dialogRef.close({
          success: true,
        });
      },
      ({ message }) => {
        this.snackBar.open(message);
        this.dialogRef.close({
          success: false,
        });
      },
    );
  }
}
