import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IncomingRemittanceComponent } from './incoming-remittance.component';
import { CbkReportsRoutesModule } from "./cbkreports.routes.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ReactiveFormsModule} from "@angular/forms";
import {ContainersModule} from "../_common/components/containers/containers.module";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatTableModule} from "@angular/material/table";
import {MatTableExporterModule} from "mat-table-exporter";
import {MatPaginatorModule} from "@angular/material/paginator";
import {CbkReportService} from "./data/cbkReports.service";
import {OutgoingRemittanceComponent} from "./outgoingremittance.component";
import {BsmvTaxComponent} from "./bsmvtax.component";
import {ClientProtectionAccountComponent} from "./client-protection-account.component";
import {NgxPrintModule} from "ngx-print";
import {MatDialogModule} from "@angular/material/dialog";
import { BsmvReportPopupComponent } from './bsmv-report-popup';
import { ClientProtectionReportPopupComponent } from './clientProtection-report-popup';
import { IncomingReportPopupComponent } from './incoming-report-popup';
import { OutgoingReportPopupComponent } from './outgoing-report-popup';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ParquetReportComponent } from './parquet-report.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {ClientProtectionCustomerReportComponent} from "./clientProtection-customer-report.component";
import {ClientProtectionCustomerReportPopupComponent} from "./clientProtection-customer-report-popup.component";



@NgModule({
  declarations: [
    IncomingRemittanceComponent,
    OutgoingRemittanceComponent,
    BsmvTaxComponent,
    ClientProtectionAccountComponent,
    BsmvReportPopupComponent,
    ClientProtectionReportPopupComponent,
    IncomingReportPopupComponent,
    OutgoingReportPopupComponent,
    ParquetReportComponent,
    ClientProtectionCustomerReportComponent,
    ClientProtectionCustomerReportPopupComponent
  ],
    imports: [
        CommonModule,
        CbkReportsRoutesModule,
        TranslateModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        ContainersModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatTableExporterModule,
        MatPaginatorModule,
        NgxPrintModule,
        MatDialogModule,
        MatSelectModule,
        MatIconModule,
        MatMenuModule,
    ]
    ,
  providers: [CbkReportService]
})
export class CbkReportsModule { }
