<h1 mat-dialog-title>{{ 'unblockRemittance' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-2 overflow-auto">
    <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
      <mat-label>
        {{ 'pleaseGiveDetailsAboutWhyYouUnblockingThisRemittance' | translate }}
      </mat-label>
      <textarea
        matInput
        placeholder="{{ 'unblockingComments' | translate }}"
        [(ngModel)]="comments"
        required
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button *ngIf="!comments" mat-button [mat-dialog-close]="comments" disabled>
    {{ 'unblock' | translate }}
  </button>
  <button *ngIf="comments" mat-button [mat-dialog-close]="comments">
    {{ 'unblock' | translate }}
  </button>
</div>
