<h1 mat-dialog-title style="text-align: center;cursor: move;"  cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ 'Finsurv Transactions' | translate }}</h1>
<div mat-dialog-content>
    <div role="content" style="font-size: 12px; margin-top: 5px" id="report">
        <div class="row" style="margin: 10px;">
            <table id="finsurvTransactions" class="table table-bordered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th class="text-nowrap">{{ 'Ad Code' | translate }}</th>
                        <th class="text-nowrap">{{ 'Ad Name' | translate }}</th>
                        <th class="text-nowrap">{{ 'Reporting Qualifier' | translate }}</th>
                        <th class="text-nowrap">{{ 'Value Date' | translate }}</th>
                        <th class="text-nowrap">{{ 'Flow' | translate }}</th>
                        <th class="text-nowrap">{{ 'Trn Reference Number' | translate }}</th>
                        <th class="text-nowrap">{{ 'Sequence' | translate }}</th>
                        <th class="text-nowrap">{{ 'Sub Sequence' | translate }}</th>
                        <th class="text-nowrap">{{ 'Status' | translate }}</th>
                        <th class="text-nowrap">{{ 'Resident Name' | translate }}</th>
                        <th class="text-nowrap">{{ 'Resident Surname' | translate }}</th>
                        <th class="text-nowrap">{{ 'Company Name' | translate }}</th>
                        <th class="text-nowrap">{{ 'Currency' | translate }}</th>
                        <th class="text-nowrap">{{ 'Rand Amount' | translate }}</th>
                        <th class="text-nowrap">{{ 'Foreign Amount' | translate }}</th>
                        <th class="text-nowrap">{{ 'Bop Category' | translate }}</th>
                        <th class="text-nowrap">{{ 'SubBop Category' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let remittance of data.data">
                        <td class="text-nowrap">{{ remittance.adCode }}</td>
                        <td class="text-nowrap">{{ remittance.adName }}</td>
                        <td class="text-nowrap">{{ remittance.reportingQualifier }}</td>
                        <td class="text-nowrap">{{ formatDate(remittance.valueDate) }}</td>
                        <td class="text-nowrap">{{ remittance.flow }}</td>
                        <td class="text-nowrap">{{ remittance.trnReferenceNumber }}</td>
                        <td class="text-nowrap">{{ remittance.sequence }}</td>
                        <td class="text-nowrap">{{ remittance.subSequence }}</td>
                        <td class="text-nowrap">{{ remittance.status }}</td>
                        <td class="text-nowrap">{{ remittance.residentName }}</td>
                        <td class="text-nowrap">{{ remittance.residentSurname }}</td>
                        <td class="text-nowrap">{{ remittance.companyName }}</td>
                        <td class="text-nowrap">{{ remittance.currency }}</td>
                        <td class="text-nowrap">{{ remittance.randAmount | number:'1.2-2' }}</td>
                        <td class="text-nowrap">{{ remittance.foreignAmount | number:'1.2-2' }}</td>
                        <td class="text-nowrap">{{ remittance.bopCategory }}</td>
                        <td class="text-nowrap">{{ remittance.subBopCategory }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-button mat-dialog-close>{{ 'Close' | translate }}</button>
    <!-- <button mat-button class="btn btn-outline-primary" [useExistingCss]="true" printSectionId="report" ngxPrint>
        {{ 'Print' | translate }}
    </button> -->
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
        {{ 'Export' | translate }}
    </button>
</div>