import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-remittance-return',
  templateUrl: './remittanceReturnPopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class RemittanceReturnPopupComponent implements OnInit {
  comments: string = '';
  type: string = '';

  form!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemittanceReturnPopupComponent>,
    private fb: UntypedFormBuilder,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      status: ['returnWithCommission', [Validators.required]],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
