import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from '../../_common/snackBar.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import {regExp} from "../../_common/data/validationRules";

@Component({
  selector: 'app-remittance-purposes',
  templateUrl: './remittancePurposesPopup.component.html',
})
export class RemittancePurposesPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  remittancePurpose: LookupsDto;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupsService: LookupsService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<RemittancePurposesPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: LookupsDto[],
  ) {
    this.remittancePurpose = data[0];
    if (this.remittancePurpose) {
      this.addMode = false;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      purposeName: [this.remittancePurpose?.name, [Validators.required]],
      purposeCode: [ Number(this.remittancePurpose?.code), [Validators.required, Validators.pattern(regExp.numeric)]],
      isActive: [this.remittancePurpose?.isActive || this.addMode],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const remittancePurpose: LookupsDto = {
        name: formValues.purposeName,
        code: formValues.purposeCode,
        isActive: formValues.isActive || false,
      };

      const handler = this.remittancePurpose
        ? this.lookupsService.updateRemittancePurpose({
            id: this.remittancePurpose.id,
            ...remittancePurpose,
          })
        : this.lookupsService.createRemittancePurpose(remittancePurpose);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
