import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as XLSX from "xlsx";
import { Utilities } from '../_common/utils';


@Component({
  selector: 'app-clientProtection-customer-report-popup',
  templateUrl: './clientProtection-customer-report-pop.component.html',
})
export class ClientProtectionCustomerReportPopupComponent {

  numberOfRemittancesGrandTotal: number = 0;
  sumOfRemittancesGrandTotal: number = 0;
  constructor(
    public dialogRef: MatDialogRef<ClientProtectionCustomerReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientProtectionCustomer: any,
      reportCurrency: string
    },
  ) {
    this.numberOfRemittancesGrandTotal = data.clientProtectionCustomer?.reduce((sum :number, entry : any) => sum + parseInt(entry.numberOfRemittances, 10), 0);
    this.sumOfRemittancesGrandTotal = data.clientProtectionCustomer?.reduce((sum :number, entry : any) => sum + parseInt(entry.totalOfRemittances, 10), 0);
  }

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
      ? Utilities.convertDateToLocaleString(date, '')
      : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('clientProtectionReport'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Client Protection Customer');
    XLSX.writeFile(
      wb,
      'Client Protection Customer Report' +
      this.convertDateToLocaleString(new Date(), false) +
      '.xlsx',
    );
  }
}
