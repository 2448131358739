import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-remittance-resolve',
  templateUrl: './remittanceResolvePopup.component.html',
})
export class RemittanceResolvePopupComponent implements OnInit {

  form!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemittanceResolvePopupComponent>,
    private fb: UntypedFormBuilder,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }
}
