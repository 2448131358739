import moment from "moment";

export const remittanceDocPath = "uploads/remittance";
export const setDecimals = (number: number | string, precision = 5) => {
  const precisionMultiplier = Math.pow(10, precision);
  number = parseFloat(number.toString());
  return Math.round(number * precisionMultiplier) / precisionMultiplier;
};

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY'
},
display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
},
};

export class Utilities {
  public static convertDateToLocaleString (date: Date, type: string) {
    if (date) {
      return type === 'date' ? moment(date).format('DD-MM-YYYY') : moment(date).format('DD-MM-YYYY HH:mm:ss');
    }
    return '';
  };

}

export const maskNumber = (number: string) =>{
  var maskedNumber
    if(number)
      maskedNumber = "x".repeat(number.length - 3) + number.substr(-3);

  return maskedNumber;
}

export const splitFullName = (fullName?: string, excludeMiddleName?: boolean) => {
  const [firstName, ...restNames] = fullName?.split(' ') || [];
  const lastName = excludeMiddleName ? restNames.join(' ') : restNames.pop();
  const middleName = excludeMiddleName ? null : restNames.join(' ');

  return { firstName: firstName || '', middleName, lastName: lastName || '' };
};

export const extractDocumentPath = (url: string, defaultDocumentPath : string) => {
  const index = url.indexOf(defaultDocumentPath);

  if (index !== -1) {
    return url.substring(index + defaultDocumentPath.length);
  } else {
    return null;
  }
}
