import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { NotificationFilter, NotificationsService } from './data/notifications.service';
import {
  GetNotificationsDto,
  RetryNotificationDto,
} from './data/dto/notifications.dto';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { RemittanceService } from '../remittance/data/remittance.service';
import { RemittanceHistoryPopupComponent } from '../remittance/remittanceHistoryPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class NotificationsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.remittanceManagement.notifications;
  resultsLength = 0;
  loading = false;
  displayedColumns: string[] = [
    'number',
    'remittanceCode',
    'detail',
    'status',
    'lastAttemptMessage',
    'retryCount',
    'lastRetryDate',
    'actions',
  ];
  data = new MatTableDataSource<GetNotificationsDto>();
  notificationStatus: any = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private notificationsService: NotificationsService,
    public authService: AuthService,
    private remittanceService: RemittanceService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      remittanceCode: [null],
      statusId: [null],
    });
    this.notificationsService.getStatuses().subscribe((status) => {
      this.notificationStatus = status;
    });

    this.notificationsService.getNotifications.subscribe(
      ({ appliedFilters, data }) => {
        this.loading = false;
        this.data.data = data;
        this.data.paginator = this.paginator;
        this.resultsLength = data.length;
        this.form.controls['remittanceCode'].setValue(
          appliedFilters?.remittanceCode,
        );
        this.form.controls['statusId'].setValue(appliedFilters?.statusId);
      },
    );
  }

  search() {
    const { statusId, remittanceCode } = this.form.value;
    const filters: NotificationFilter = {};
    if(statusId) filters.statusId = statusId;
    if(remittanceCode) filters.remittanceCode = remittanceCode;
    this.notificationsService.searchNotifications(filters);
  }
  onRetryNotificationClick(id: number) {
    this.notificationsService
      .retry({ id } as RetryNotificationDto)
      .subscribe((resp) => {
        this.loading = false;
      });
  }

  onViewHistoryClick(remittanceId: number) {
    this.remittanceService
      .getRemittanceHistory(remittanceId)
      .subscribe((remittanceHistory) => {
        this.dialog.open(RemittanceHistoryPopupComponent, {
          disableClose: true,
          width: '900px',
          data: remittanceHistory,
        });
      });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
