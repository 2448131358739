import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackBarService } from '../_common/snackBar.service';
import { CustomerService } from './data/customer.service';
import { CustomerDocTypesResponseDto } from './data/dto/customer.dto';
import { CustomerDocumentDto } from './data/dto/customerDocument.dto';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-customer-doc-edit',
  templateUrl: './customerDocEditPopup.component.html',
})
export class CustomerDocEditPopupComponent implements OnInit {
  // @ts-ignore
  @ViewChild('customerDocFile') docFileElem: ElementRef;

  // @ts-ignore
  @ViewChild('documentForm') documentForm: ElementRef;

  form!: UntypedFormGroup;
  today = new Date();
  docTypes: CustomerDocTypesResponseDto[] = [];
  selectedDoc!: CustomerDocumentDto;
  documentPathArray: string[] | undefined;
  fileType: string = '';
  docFile: any[] = [];
  customerDocFile?: File[] = [];
  customerId!: number;
  isFileSelected = false;
  docTypeSelected!: CustomerDocTypesResponseDto;
  private selectedDoctype: any;
  private numberOfAttachmentsRequired: number = 0;
  public requiredFields: any;
  public isPassportDocTypeSelected: boolean = false;

  public allCountries: any = [];
  constructor(
    public dialogRef: MatDialogRef<CustomerDocEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      docId: number;
      customerId: number;
      docInfo: CustomerDocumentDto[];
      docTypes: CustomerDocTypesResponseDto[];
      countries: any
    },
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private customerService: CustomerService,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.docTypes = this.data.docTypes;
    this.docTypes = [...this.docTypes].filter((f) => f.isActive);
    this.allCountries = this.data?.countries;
    this.selectedDoc = this.data.docInfo.find(
      (f) => f.id === this.data.docId,
    ) as CustomerDocumentDto;

    const docTypeInfo = this.docTypes.find(dc => dc.id == this.selectedDoc.documentTypeId);
    this.requiredFields = JSON.parse(docTypeInfo?.requiredFields || '{}');
    this.isPassportDocTypeSelected = this.selectedDoctype?.name?.trim()?.toLowerCase() == 'passport';
    setTimeout(() => {
      this.documentPathArray = this.selectedDoc.documentPaths?.map(
        (o) => Object.values(o)[0],
      );
      if (this.documentPathArray && this.documentPathArray!.length) {
        this.documentPathArray?.forEach((url) => {
          this.customerService.getCustomerDocument(url).subscribe(
            (blob) => {
              if (blob) {
                this.fileType = 'application/png';
                if (url.indexOf('.pdf') > -1) {
                  blob = new Blob([blob], {type: 'application/pdf'});
                  this.fileType = 'application/pdf';
                }
                this.docFile?.push({
                  type: this.fileType,
                  name: '',
                  url: this.sanitizer.bypassSecurityTrustResourceUrl(
                    URL.createObjectURL(blob),
                  ),
                });
              }
            },
            (err) => {
              this.snackBar.open(err.message);
            },
          );
        });
      }
    }, 100);
    this.form = this.fb.group({
      docNumber: [
        this.selectedDoc.documentDetails?.number,
        [],
      ],
      docIssueDate: [
        this.selectedDoc.documentDetails?.issueDate,
        [Validators.required],
      ],
      docExpiryDate: [
        this.selectedDoc.documentDetails?.expiryDate,
        [Validators.required],
      ],
      docIssuedBy: [
        this.selectedDoc.documentDetails?.issuedBy,
        [Validators.required],
      ],
      docType: [
        this.docTypes.find((f) => f.id === this.selectedDoc.documentTypeId),
        [Validators.required],
      ],
      isActive: [this.selectedDoc.isActive],
      idNumber: [this.selectedDoc?.documentDetails?.idNumber],
      serialNumber: [this.selectedDoc?.documentDetails?.serialNumber],
      motherName: [this.selectedDoc?.documentDetails?.motherName],
      fatherName: [this.selectedDoc?.documentDetails?.fatherName],
      nationality: [this.allCountries.find((f: { id: any; }) => f.id === this.selectedDoc?.documentDetails?.nationality)],
    });
    if(this.requiredFields?.idNumberIsRequired) {
      this.form.controls['idNumber']?.enable();
      // @ts-ignore
      this.form.get('idNumber')?.setValidators([
        // @ts-ignore
        docTypeInfo?.minimumLength ? Validators.required : null,
        // @ts-ignore
        Validators.minLength(docTypeInfo?.minimumLength),
        // @ts-ignore
        Validators.maxLength(docTypeInfo?.maximumLength),
      ]);
    } else {
      this.form.controls['idNumber']?.patchValue('');
      this.unsetFieldRequired('idNumber');
    }
    if(this.requiredFields?.serialNumberIsRequired) {
      this.form.get('serialNumber')?.enable();
      this.form.get('serialNumber')?.setValidators([
        // @ts-ignore
        docTypeInfo?.minimumLength ? Validators.required : null,
        //@ts-ignore
        Validators.minLength(docTypeInfo?.minimumLength),
        //@ts-ignore
        Validators.maxLength(docTypeInfo?.maximumLength),
      ]);
    } else {
      this.form.controls['serialNumber']?.patchValue('');
      this.unsetFieldRequired('serialNumber');
    }

    if(!this.requiredFields?.issueDateIsRequired) {
      this.unsetFieldRequired('docIssueDate');
    } else {
      this.setFieldRequired('docIssueDate');
    }
    if(!this.requiredFields?.expiryDateIsRequired) {
      this.unsetFieldRequired('docExpiryDate');
    } else {
      this.setFieldRequired('docExpiryDate')
    }

    if(!this.requiredFields?.documentIssuerIsRequired) {
      this.unsetFieldRequired('docIssuedBy');
    } else {
      this.setFieldRequired('docIssuedBy');
    }

    if(!this.requiredFields?.documentNumberIsRequired) {
      this.unsetFieldRequired('docNumber');
    } else {
      this.form.get('docNumber')?.setValidators([
        // @ts-ignore
        docTypeInfo?.minimumLength ? Validators.required : null,
        //@ts-ignore
        Validators.minLength(docTypeInfo?.minimumLength),
        //@ts-ignore
        Validators.maxLength(docTypeInfo?.maximumLength),
      ]);
      this.form.get('docNumber')?.updateValueAndValidity();
    }

    if(this.requiredFields?.motherNameIsRequired) {
      this.form.controls['motherName']?.enable();
      this.form.get('motherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('motherName')?.updateValueAndValidity();
    } else {
      this.form.controls['motherName']?.patchValue('');
      this.unsetFieldRequired('motherName');
    }

    if(this.requiredFields?.nationalityIsRequired) {
      this.form.controls['nationality']?.enable();
      this.form.get('nationality')?.setValidators([
        Validators.required
      ]);
      this.form.get('nationality')?.updateValueAndValidity();
    } else {
      this.form.controls['nationality']?.patchValue('');
      this.unsetFieldRequired('nationality');
    }

    if(this.requiredFields?.fatherNameIsRequired) {
      this.form.controls['fatherName']?.enable();
      this.form.get('fatherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('fatherName')?.updateValueAndValidity();
    } else {
      this.form.controls['fatherName']?.patchValue('');
      this.unsetFieldRequired('fatherName');
    }

  }

  onSubmit() {
    if (!this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.documentForm.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]',
          );
          invalidControl.focus();
          break;
        }
      }
      return;
    }
    if (this.form.valid) {
      // @ts-ignore
      if (this.customerDocFile?.length < this.numberOfAttachmentsRequired) {
        this.snackBar.open(
          `${this.numberOfAttachmentsRequired} attachments required.`,
        );
        return;
      }
        if (!this.customerDocFile) {
          this.isFileSelected = false;
        } else {
          this.dialogRef.close({
            docData: this.prepareDocumentPayload(
              this.form.value,
              this.customerId,
            ),
            docAttachments: this.customerDocFile,
          });
        }
    }
  }

  onDocAttachmentSelected(e: Event) {
    if (this.form.controls['docType'].value) {
      this.docTypeSelected = this.form.controls['docType'].value;
      const reader = new FileReader();
      if (this.docTypeSelected.isTwoPageAttachmentRequired) {
        if (this.customerDocFile!.length > 1) {
          this.snackBar.open(
            `${this.docTypeSelected.name} requires only two attachments.`,
          );
        } else {
          //@ts-ignore
          const file: File[] = e.target.files;
          for (let i = 0; i < file.length; i++) {
            const index = i;
            if (this.docFile.length && this.docFile[i].name === file[i].name) {
              this.snackBar.open(`File already selected with this name`);
              return;
            }
            this.customerDocFile?.push(file[i]);
            reader.onload = (event: any) => {
              this.isFileSelected = true;
              this.docFile?.push({
                type: file[index].type,
                name: file[index].name,
                url: event.target.result,
              });
            };
            reader.readAsDataURL(file[i]);
          }
        }
      } else {
        if (this.customerDocFile!.length > 0) {
          this.snackBar.open(
            `${this.docTypeSelected.name} requires only one file attachment.`,
          );
        } else {
          //@ts-ignore
          const file = e.target.files[0];
          this.customerDocFile?.push(file);
          reader.onload = (event: any) => {
            this.isFileSelected = true;
            this.docFile?.push({
              type: file.type,
              name: file.name,
              url: event.target.result,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }

  clearSelectedDocAttachment(file: any) {
    this.customerDocFile = this.customerDocFile?.filter(
      (list) => list.name !== file.name,
    );
    this.docFile = this.docFile?.filter((list) => list.name !== file.name);
    this.docFileElem.nativeElement.value = null;
  }

  prepareDocumentPayload(
    formValues: any,
    customerId: number,
  ): CustomerDocumentDto {
    return {
      id: this.data.docId,
      customerId,
      documentTypeId: formValues['docType'].id,
      documentDetails: {
        number: formValues['docNumber'],
        issueDate: formValues['docIssueDate'] || null,
        expiryDate: formValues['docExpiryDate'] || null,
        issuedBy: formValues['docIssuedBy'] || null,
        idNumber: formValues[`idNumber`] || null,
        serialNumber: formValues[`serialNumber`] || null,
        requiredFields: this.selectedDoctype?.requiredFields,
        motherName : formValues[`motherName`] || null,
        fatherName : formValues[`fatherName`] || null,
        nationality : formValues[`nationality`].id || null
      },
      isActive: formValues.isActive,
    };
  }

  onFocusOut(event: any) {
    const regexLiteral = this.selectedDoctype?.regularExpression;
    if(regexLiteral) {
      const [, pattern, flags] = regexLiteral?.match(/\/(.*)\/(.*)/);
      const regex = new RegExp(pattern, flags);
      if (!regex.test(event.target.value)) {
        this.form.get('docNumber')?.setErrors({invalid: true});
      } else {
        this.form.get('docNumber')?.setErrors(null);
      }
    }
  }
  onDoctypeSelected(event: any) {
    this.selectedDoctype = event.value;
    this.requiredFields = JSON.parse(event.value?.requiredFields || '{}');
    this.isPassportDocTypeSelected = this.selectedDoctype?.name?.trim()?.toLowerCase() == 'passport';

    if(this.requiredFields?.idNumberIsRequired) {
      this.form.controls['idNumber']?.enable();
      this.form.get('idNumber')?.setValidators([
        // @ts-ignore
        event.value?.minimumLength ? Validators.required : null,
        Validators.minLength(event.value?.minimumLength),
        Validators.maxLength(event.value?.maximumLength),
      ]);
      this.form.controls['docNumber']?.patchValue('');
    } else {
      this.form.controls['idNumber']?.patchValue('');
      this.unsetFieldRequired('idNumber');
    }

    if(this.requiredFields?.serialNumberIsRequired) {
      this.form.get('serialNumber')?.enable();
      this.form.get('serialNumber')?.setValidators([
        // @ts-ignore
        event.value?.minimumLength ? Validators.required : null,
        Validators.minLength(event.value?.minimumLength),
        Validators.maxLength(event.value?.maximumLength),
      ]);
      this.form.controls['docNumber']?.patchValue('');
    } else {
      this.form.controls['serialNumber']?.patchValue('');
      this.unsetFieldRequired('serialNumber');
    }

    if(!this.requiredFields?.issueDateIsRequired) {
      this.unsetFieldRequired('docIssueDate');
    } else {
      this.setFieldRequired('docIssueDate');
    }
    if(!this.requiredFields?.expiryDateIsRequired) {
      this.unsetFieldRequired('docExpiryDate');
    } else {
      this.setFieldRequired('docExpiryDate')
    }

    if(!this.requiredFields?.documentIssuerIsRequired) {
      this.unsetFieldRequired('docIssuedBy');
    } else {
      this.setFieldRequired('docIssuedBy');
    }

    if(!this.requiredFields?.documentNumberIsRequired) {
      this.unsetFieldRequired('docNumber');
    } else {
      this.form.get('docNumber')?.setValidators([
        // @ts-ignore
        event.value?.minimumLength ? Validators.required : null,
        Validators.minLength(event.value?.minimumLength),
        Validators.maxLength(event.value?.maximumLength),
      ]);
      this.form.get('docNumber')?.updateValueAndValidity();
    }

    if(this.requiredFields?.motherNameIsRequired) {
      this.form.controls['motherName']?.enable();
      this.form.get('motherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('motherName')?.updateValueAndValidity();
    } else {
      this.form.controls['motherName']?.patchValue('');
      this.unsetFieldRequired('motherName');
    }

    if(this.requiredFields?.nationalityIsRequired) {
      this.form.controls['nationality']?.enable();
      this.form.get('nationality')?.setValidators([
        Validators.required
      ]);
      this.form.get('nationality')?.updateValueAndValidity();
    } else {
      this.form.controls['nationality']?.patchValue('');
      this.unsetFieldRequired('nationality');
    }

    if(this.requiredFields?.fatherNameIsRequired) {
      this.form.controls['fatherName']?.enable();
      this.form.get('fatherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('fatherName')?.updateValueAndValidity();
    } else {
      this.form.controls['fatherName']?.patchValue('');
      this.unsetFieldRequired('fatherName');
    }
    this.countDocAttachmentsRequired();
  }

  setFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.setValidators([Validators.required]);
    ctrl.updateValueAndValidity();
  }

  unsetFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
  }

  countDocAttachmentsRequired() {
    this.numberOfAttachmentsRequired = 0;
    if (this.selectedDoctype?.isFirstAttachmentRequired)
      this.numberOfAttachmentsRequired = 1;
    if (this.selectedDoctype?.isSecondAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
    if (this.selectedDoctype?.isThirdAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
  }
}
