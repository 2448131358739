
<app-index-container label="{{ 'BlacklistedSanctionedAuditLogs' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row mb-0">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>{{ 'startDate' | translate }}:</mat-label>
                    <input [(ngModel)]="startDate"  matInput [matDatepicker]="startPicker" formControlName="startDate" />
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
            </div>            
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                    <mat-label>{{ 'endDate' | translate }}:</mat-label>
                    <input [(ngModel)]="endDate" matInput [matDatepicker]="endPicker" formControlName="endDate" />
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-end">
                    <!-- Search button -->
                    <button mat-raised-button type="submit" color="primary">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        {{ 'search' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </form>

    <table table mat-table [dataSource]="dataSource" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.action }}</td>
        </ng-container>

        <!-- name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row?.name }}</td>
        </ng-container>

        <!-- Comment Column -->
        <ng-container matColumnDef="stage">
            <th mat-header-cell *matHeaderCellDef>{{ 'stage' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.stage }}</td>
        </ng-container>

        <!-- Date Created Column -->
        <ng-container matColumnDef="dateCreated">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'dateCreated' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ convertDateToLocaleString(row.dateCreated, 'datetime') }}
            </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <div class="action-button-menu">
                    <button class="btn btn-outline-info btn-sm" (click)="viewChangeLog(row.matchedEntity)">
                        {{ 'viewChangeLog' | translate }}
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>