<h1 mat-dialog-title>{{ 'customerReport' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
    </div>

    <div class="row" style="margin: 10px">
      <table
        id="customerReport"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody *ngIf="!data.length">
          <tr>
            <td class="text-center">{{ 'noRecordsAvailable' | translate }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="data.length">
          <tr>
            <th class="text-nowrap">{{ 'IdNo' | translate }}</th>
            <th class="text-nowrap">{{ 'onboardingDate' | translate }}</th>
            <th class="text-nowrap">{{ 'dateOfBirth' | translate }}</th>
            <th class="text-nowrap">{{ 'name/SurName' | translate }}</th>
            <th class="text-nowrap">{{ 'placeOfBirth' | translate }}</th>
            <th class="text-nowrap">{{ 'gender' | translate }}</th>
            <th class="text-nowrap">{{ 'occupation' | translate }}</th>
            <th class="text-nowrap">{{ 'telephoneNumber' | translate }}</th>
            <th class="text-nowrap">{{ 'idType' | translate }}</th>
            <th class="text-nowrap">{{ 'idNumber' | translate }}</th>
            <th class="text-nowrap">{{ 'address' | translate }}</th>
            <th class="text-nowrap">{{ 'transactionNumbers' | translate }}</th>
            <th class="text-nowrap">{{ 'document' | translate }}</th>
          </tr>
          <tr *ngFor="let customerDetail of data">
            <td class="text-nowrap">{{ customerDetail.id }}</td>
            <td class="text-nowrap">{{ customerDetail.onboardingDate }}</td>
            <td class="text-nowrap">{{ customerDetail.dateOfBirth }}</td>
            <td class="text-nowrap">{{ customerDetail.fullName }}</td>
            <td class="text-nowrap">{{ customerDetail.placeOfBirth }}</td>
            <td class="text-nowrap">{{ customerDetail.gender }}</td>
            <td class="text-nowrap">{{ customerDetail.occupation }}</td>
            <td class="text-nowrap">{{ customerDetail.mobilePhone }}</td>
            <td class="text-nowrap">{{ customerDetail.IdType }}</td>
            <td class="text-nowrap">{{ customerDetail.IdNumber }}</td>
            <td class="text-nowrap">{{ customerDetail.address }}</td>
            <td class="text-nowrap" style="color: #0074d9; cursor: pointer; text-size: 18px" (click)="remittanceReceipts(customerDetail?.remittanceIds)">{{ customerDetail.transactionNumbers }}</td>
            <td class="text-nowrap">
              <a
                mat-menu-item
                *ngIf="customerDetail?.documentPaths"
                color="primary"
                (click)="onViewAttachment(customerDetail?.documentPaths)"
              >
                <mat-icon>attach_file</mat-icon>
                {{ 'viewAttachments' | translate }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
