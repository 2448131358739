import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Utilities } from 'src/app/_common/utils';
import { SnackBarService } from '../../_common/snackBar.service';
import { CommissionConfigurationService } from '../data/commissionConfigurations.service';
@Component({
  selector: 'app-comm-config-request-popup',
  templateUrl: './commConfigRequestPopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class CommissionConfigurationRequestPopupComponent implements OnInit {
  comments: string = '';
  type: string = '';
  form!: UntypedFormGroup;
  constructor(
    public dialogRef: MatDialogRef<CommissionConfigurationRequestPopupComponent>,
    private fb: UntypedFormBuilder,
    private commConfigsService: CommissionConfigurationService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      rateChangeRequestStatus: [
        'approveCommissionConfiguration',
        [Validators.required],
      ],
      statusChangeComments: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.commConfigsService
        .updateCommConfigRequest({
          commConfigRequestId: this.data.id,
          statusType: this.form.value.rateChangeRequestStatus,
          statusChangeComments: this.form.value.statusChangeComments,
        })
        .subscribe(
          () => {
            this.snackBar.open(
              `Commission Configuration Request ${
                this.form.value.rateChangeRequestStatus ===
                'approveCommissionConfiguration'
                  ? 'Approved'
                  : 'Rejected'
              } Successfully...`,
            );
            this.dialogRef.close({
              success: true,
            });
          },
          ({ message }) => {
            this.snackBar.open(message);
            this.dialogRef.close({
              success: false,
            });
          },
        );
    }
  }
  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
