import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Utilities } from '../_common/utils';
import {PartnerSettlementReportDto} from "./data/dto/partnerSettlementReport.dto";

@Component({
  selector: 'app-partner-settlement-report-popup',
  templateUrl: './partnerSettlementReportPopup.component.html',
  styleUrls: ['./reportStyles.css'],
})
export class PartnerSettlementReportPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<PartnerSettlementReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PartnerSettlementReportDto[],
  ) {}

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('partnerSettlements'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      'Partner settlements on ' +
        this.convertDateToLocaleString(new Date(), false) +
        '.xlsx',
    );
  }


}
