<h1 mat-dialog-title>{{ 'confirmSettlement' | translate }}</h1>
<div mat-dialog-content>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'amountInUsd' | translate }}</mat-label>
        <input matInput #usdAmount formControlName="usdAmount" readonly />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'settlerAmount' | translate }}</mat-label>
        <input
          matInput
          #settlerAmount
          formControlName="settlerAmount"
          readonly
        />
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <mat-label
          ><h3>{{ 'settlerMemo' | translate }}</h3></mat-label
        >
        <p class="alert alert-light">{{ settlement?.settlerComments }}</p>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="col" appearance="fill">
        <mat-label>{{ 'account' | translate }}</mat-label>
        <mat-select
          formControlName="account"
          cdkFocusInitial
          (selectionChange)="onAccountChange($event.value.id)"
        >
          <mat-option *ngFor="let account of accounts" [value]="account">
            {{ account.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label
          >{{ 'confirmedAmount' | translate
          }}{{ ' ' + this.beneficiaryCurrency }}</mat-label
        >
        <input
          matInput
          #beneficiaryAmount
          formControlName="beneficiaryAmount"
          readonly
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label
          >{{ 'exchangeRate' | translate
          }}{{ ' ' + this.beneficiaryCurrency }}</mat-label
        >
        <input
          matInput
          #beneficiaryExchangeRate
          formControlName="beneficiaryExchangeRate"
          readonly
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label
          >{{ 'baseCurrencyAmount' | translate
          }}{{ ' ' + this.beneficiaryBaseCurrency }}</mat-label
        >
        <input
          matInput
          #beneficiaryBaseCurrencyAmount
          formControlName="beneficiaryBaseCurrencyAmount"
          readonly
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'usdAmount' | translate }}</mat-label>
        <input
          matInput
          #beneficiaryUsdAmount
          formControlName="beneficiaryUsdAmount"
          readonly
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label
          >{{ 'turkishExchangeRate' | translate
          }}{{ ' TL' }}</mat-label
        >
        <input
          matInput
          #turkishExchangeRate
          formControlName="turkishExchangeRate"
          [readonly]="!isPartnerSettlement"
        />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill">
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'confirm' | translate }}
  </button>
</div>
