import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "../auth/data/auth.service";
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerTypes } from "../_common/enums/systemBranches.enums";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'app-customer-actions',
    templateUrl: './ormcustomeractions.component.html',
    styleUrls: ['./ormcustomeractions.component.css'],

})
export class OrmCustomerActionsComponent implements OnInit {

     permissions = Permissions.customerManagement.customers;
     isSenderPartnerSelected: Boolean = false;
     customerTypes = CustomerTypes

    constructor(
         public authService: AuthService,
         @Inject(MAT_DIALOG_DATA)
         public data: { customerTypeId: number; },
    ) {

    }

    ngOnInit() {
        
    }
}