<h1 mat-dialog-title>{{ 'trialBalanceReport' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'trialBalance' | translate }}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table class="table table-borderless">
        <tr>
          <td>{{ 'branchCode' | translate }}:</td>
          <td>{{ data.data.branchCode }}</td>
          <td>{{ 'branchTitle' | translate }}:</td>
          <td>{{ data.data.branchTitle }}</td>
          <td>{{ 'address' | translate }}:</td>
          <td>{{ data.data.branchAddress }}</td>
        </tr>
        <tr>
          <td>{{ 'mobile' | translate }}:</td>
          <td>{{ data.data.branchMobilePhone }}</td>
          <td>{{ 'requestedDate' | translate }}:</td>
          <td>{{ convertDateToLocaleString(data.date, false) }}</td>
          <td class="onlyPrintable">{{ 'printedDate' | translate }}:</td>
          <td class="onlyPrintable">
            {{ convertDateToLocaleString(data.date, true) }}
          </td>
        </tr>
      </table>
    </div>
    <div class="row" style="margin: 10px">
      <table
        id="accountsData"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody>
          <tr>
            <th>{{ 'account' | translate }}</th>
            <th>{{ 'accountName' | translate }}</th>
            <th>{{ 'debit' | translate }}</th>
            <th>{{ 'credit' | translate }}</th>
          </tr>
          <tr *ngFor="let accountInfo of data.data.accountsInfo">
            <td>{{ accountInfo.accountNumber }}</td>
            <td>{{ accountInfo.accountName }}</td>
            <td>{{ accountInfo.debit === "0.00" ? '' : accountInfo.debit | number:'1.2-2' }}</td>
            <td>{{ accountInfo.credit === "0.00" ? '' : accountInfo.credit | number:'1.2-2' }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <b>{{ 'total' | translate }}</b>
            </td>
            <td>
              <b>{{ debitSum | number:'1.2-2' }}</b>
            </td>
            <td>
              <b>{{ creditSum | number:'1.2-2' }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="report"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
