import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Utilities } from '../_common/utils';
import { BranchStatementDto } from './data/dto/branchStatement.dto';

@Component({
  selector: 'app-branch-statement-popup',
  templateUrl: './branchStatementPopup.component.html',
  styleUrls: ['./reportStyles.css'],
})
export class BranchStatementPopupComponent {
  comments: string = '';
  debitSum!: string;
  creditSum!: string;
  runningTotalSum!: string;

  constructor(
    public dialogRef: MatDialogRef<BranchStatementPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { startDate: Date; endDate: Date; data: BranchStatementDto },
  ) {
    this.debitSum = data.data.statements
      .reduce(
        (sum, a) => (a.debit ? parseFloat(a.debit.toString()) : 0 + sum),
        0,
      )
      .toFixed(2);
    this.creditSum = data.data.statements
      .reduce(
        (sum, a) => (a.credit ? parseFloat(a.credit.toString()) : 0 + sum),
        0,
      )
      .toFixed(2);
    this.runningTotalSum = data.data.statements
      .reduce(
        (sum, a) =>
          a.runningTotal ? parseFloat(a.runningTotal.toString()) : 0 + sum,
        0,
      )
      .toFixed(2);
  }

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('accountsData'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const fileName =
      'Branch Statement on ' +
      this.convertDateToLocaleString(new Date(), false) +
      '.xlsx';
    XLSX.writeFile(wb, fileName);
  }
}
