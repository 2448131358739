import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from './data/customer.service';
import { SnackBarService } from '../_common/snackBar.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-customer-form',
    templateUrl: './addBlacklist.component.html',
    styleUrls: ['./addBlacklist.component.less']
})
export class AddCustomerBlacklistComponent implements OnInit {
    customerBlacklistForm: FormGroup = this.fb.group({});
    customerId: number | null = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private customerService: CustomerService,
        private dialog: MatDialog,
        private snackBar: SnackBarService
    ) {
        this.customerId = data?.customerId || null;
    }

    ngOnInit(): void {
        this.customerBlacklistForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(3)]],
            mobile: ['', [Validators.required, Validators.pattern(/^[0-9]{10,15}$/)]],
            Blocked: [0, Validators.required],
            ApprovalRequired: [0, Validators.required],
            reasons: [''],
            comments: [''] 
            
        });

        if (this.customerId !== null) {
            this.customerBlacklistForm.removeControl('reasons');
            this.customerBlacklistForm.addControl('comments', this.fb.control('', Validators.required));
        } else {
            this.customerBlacklistForm.removeControl('comments');
            this.customerBlacklistForm.addControl('reasons', this.fb.control('', Validators.required));
        }

        if (this.customerId != null) {
            this.searchCustomerBlacklist(this.customerId)
        }
    }

    onSubmit() {
        const formData: CustomerBlacklist = this.customerBlacklistForm.value;
        console.log(formData);
        if (this.customerBlacklistForm.valid && this.customerId == null) {
            this.addCustomerBlacklist(formData);
        }
        if(this.customerBlacklistForm.valid && this.customerId != null) {
            this.updateCustomerBlacklist(this.customerId, formData);
        }
    }

    addCustomerBlacklist(customerBlacklist: CustomerBlacklist) {
        console.log(customerBlacklist);
        this.customerService.addCustomerBlacklist(customerBlacklist).subscribe(
            (resp) => {
                this.snackBar.open(resp?.message);
                this.dialog.closeAll();
            },
            ({ message }) => {
                console.log(message);
                this.snackBar.open(message);
            },
        );
    }

    updateCustomerBlacklist(customerId: number, customerBlacklist: CustomerBlacklist){
        this.customerService.updateCustomerBlacklist(customerId, customerBlacklist).subscribe(
            (resp) => {
                this.snackBar.open(resp?.message);
                this.dialog.closeAll();
            },
            ({ message }) => {
                console.log(message);
                this.snackBar.open(message);
            },
        );
    }
    searchCustomerBlacklist(customerId: number) {
        this.customerService.searchCustomerBlacklisted(customerId).subscribe(
            (resp) => {
                console.log(resp);
                if (resp?.length > 0) {
                    const blacklistData = resp[0];

                    const mappedData = {
                        name: blacklistData.name || '',
                        mobile: blacklistData.mobile || '',
                        Blocked: blacklistData.Blocked || 0,
                        ApprovalRequired: blacklistData.ApprovalRequired || 0,
                        reasons: blacklistData.reasons || '',
                        comments: blacklistData.comments || ''
                    };

                    this.customerBlacklistForm.patchValue(mappedData);
                }
            },
            ({ message }) => {
                console.log(message);
                this.snackBar.open(message);
            },
        );
    }


}


export interface CustomerBlacklist {
    name: string;
    mobile: string;
    Blocked: number;
    ApprovalRequired: number;
    reasons: string;
}
