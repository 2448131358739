import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { BranchesService } from './branches.service';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';
import { GetBranchesDto, GetAccountDto } from './dto/branches.dto';
import { CountriesDto } from 'src/app/remittance/data/dto/countries.dto';

@Injectable()
export class BranchTypesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.branchesService.branchTypes();
  }
}

@Injectable()
export class CountriesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<CountriesDto[]> {
    return this.branchesService.countries();
  }
}

@Injectable()
export class CurrenciesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.branchesService.currencies();
  }
}

@Injectable()
export class BranchParentsResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<GetBranchesDto[]> {
    return this.branchesService.branchParents();
  }
}

@Injectable()
export class BranchByIdResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<GetBranchesDto[]> {
    const { branchId } = route.params;
    return this.branchesService.branchById(branchId);
  }
}

@Injectable()
export class ExchangeRateTypeResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.branchesService.exchangeRateTypes();
  }
}

@Injectable()
export class CitiesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.cities();
  }
}

@Injectable()
export class LocalitiesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.localities();
  }
}

@Injectable()
export class BranchesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<GetBranchesDto[]> {
    return this.branchesService.getBranches();
  }
}

@Injectable()
export class BeneficiaryBranchesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<GetBranchesDto[]> {
    return this.branchesService.beneficiaries();
  }
}

@Injectable()
export class RateChangeStatuses implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.getRateChangeStatuses();
  }
}

@Injectable()
export class BranchCommissionPoliciesResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.getBranchCommissionPolicies();
  }
}

@Injectable()
export class LimitChangeStatuses implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.getLimitChangeStatuses();
  }
}

@Injectable()
export class AccountByIdResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<GetAccountDto> {
    const { accountId } = route.params;
    return this.branchesService.getAccountById(accountId);
  }
}

@Injectable()
export class BranchGroupsResolver implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.accountGroups();
  }
}

@Injectable()
export class CommissionChangeStatuses implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.getCommissionChangeStatuses();
  }
}

@Injectable()
export class ManualJournalStatuses implements Resolve<any> {
  constructor(private branchesService: BranchesService) {}
  resolve(): Observable<LookupDto[]> {
    return this.branchesService.getManualJournalStatuses();
  }
}
