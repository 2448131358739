import { RemittanceType, RemittanceSubType } from './dto/remittances.dto';

export const mapRemittanceSubTypesToRemittanceTypes = (
  remittanceSubTypes: RemittanceSubType[],
): RemittanceType[] => {
  return remittanceSubTypes.reduce(
    (
      remittanceTypes: RemittanceType[],
      remittanceSubType: RemittanceSubType,
    ) => {
      let remittanceType = remittanceTypes.find(
        (rt) => rt.id === remittanceSubType.remittanceTypeId,
      );
      if (!remittanceType) {
        remittanceType = {
          id: remittanceSubType.remittanceTypeId as number,
          name: remittanceSubType.remittanceType as string,
          remittanceSubTypes: [],
        };
        remittanceTypes.push(remittanceType);
      }

      remittanceType.remittanceSubTypes.push(remittanceSubType);

      return remittanceTypes;
    },
    [] as RemittanceType[],
  );
};
