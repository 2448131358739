<app-index-container label="{{ 'monthlyCommissions' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select formControlName="branch">
          <mat-option *ngFor="let branch of branches" [value]="branch"
            >{{ branch.code + ' - ' + branch.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col">
        <input
          matInput
          [matDatepicker]="dp"
          placeholder="{{ 'selectDate' | translate }}"
          [formControl]="date"
        />
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker
          #dp
          startView="year"
          (yearSelected)="chosenYearHandler($event)"
          (monthSelected)="chosenMonthHandler($event, dp)"
          panelClass="example-month-picker"
        >
        </mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>{{ 'status' | translate }}:</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statuses" [value]="status"
            >{{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-10">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
      <div class="col-2">
        <div class="d-flex justify-content-end">
          <button
            *ngIf="dataThere"
            mat-raised-button
            (click)="exporter.exportTable('xlsx')"
            color="warn"
          >
            <mat-icon>file_download</mat-icon>
            {{ 'export' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table
    table
    matTableExporter
    mat-table
    [dataSource]="data"
    #exporter="matTableExporter"
    class="table"
  >
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Branch Code Column -->
    <ng-container matColumnDef="branchCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'branchCode' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.branchCode }}</td>
    </ng-container>
    <!-- Commission Column -->
    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>{{ 'commission' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.commission | number:'1.0-5'  }}</td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'date' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.date) }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
