import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { RemittanceRoutesModule } from './remittance.routes.module';
import { RemittanceService } from './data/remittance.service';
import { RemittancesComponent } from './remittances.component';
import { AddRemittanceComponent } from './addRemittance.component';
import {
  CountriesResolver,
  PayingCountriesResolver,
  PaymentTypesResolver,
  RemittanceForPaybackResolver,
  RemittanceForPaymentResolver,
  RemittancePurposesResolver,
  RemittanceRelationshipsResolver,
  RemittanceStatusesResolver,
  RemittanceSubTypesResolver,
  TellerAccountsResolver,
} from './data/remittance.resolver';
import { PayRemittanceComponent } from './payRemittance.component';

import { CommissionConfigsComponent } from './commissionConfigs/commissionConfigs.component';
import { CommissionConfigurationService } from './data/commissionConfigurations.service';
import { CommissionConfigDetailsComponent } from './commissionConfigs/commissionConfigDetails.component';
import {
  CommissionConfigRequestStatusResolver,
  CommissionConfigurationsResolver,
} from './data/commissionConfigurations.resolver';
import { RemittanceCompliancePopupComponent } from './remittanceCompliancePopup.component';
import { RemittanceApprovalPopupComponent } from './remittanceApprovalPopup.component';
import { RemittanceBlockingPopupComponent } from './remittanceBlockingPopup.component';
import { RemittancePayReceiptPopupComponent } from './remittancePayReceiptPopup.component';
import { RemittanceSendReceiptPopupComponent } from './remittanceSendReceiptPopup.component';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';

import { NgxPrintModule } from 'ngx-print';
import { RemittanceReturnPopupComponent } from './remittanceReturnPopup.component';
import { RemittanceUnblockingPopupComponent } from './remittanceUnblockingPopup.component';
import { RemittanceHistoryPopupComponent } from './remittanceHistoryPopup.component';
import { RemittanceHistoryChangeLogPopupComponent } from './remittanceHistoryChangeLogPopup.component';
import { RemittanceTracePopupComponent } from './remittanceTracePopup.component';
import { PaybackRemittanceComponent } from './paybackRemittance.component';
import { RemittancePaybackReceiptPopupComponent } from './remittancePaybackReceiptPopup.component';
import { CommissionConfigsRequetsComponent } from './commissionConfigs/commissionConfigsRequests.component';
import { CommissionConfigurationRequestPopupComponent } from './commissionConfigs/commConfigRequestPopup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { ValidationErrorModule } from '../_common/components/validation-error/validation-error.module';
import { RemittanceReceiverInformationUpdateComponent } from './remittanceReceiverInformationUpdate.component';
import { AmbiguousRemittancesComponent } from './ambiguousRemittances.component';
import { RemittanceResolvePopupComponent } from './remittanceResolvePopup.component';
import {MobileOwnershipVerifPopupComponent} from "./mobileOwnershipVerifPopup.component";
import {UploadRemittanceReceiptPopupComponent} from "./uploadRemittanceReceiptPopup.component";
import {RemittanceReceiptPopupComponent} from "./remittanceReceiptPopup.component";
import { OrmRemittancesComponent } from './ormremittance.component';
import { FinsurvReportsComponent } from './finsurv-reports.component';
import { FinsurvTransactionsPopupComponent } from './finsurvTransactionsPopup.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FinsurvTransactionsComponent } from './finsurv-transactions.component';
import { FinsurvModificationsPopup } from './finsurvModificationsPopup.component';
import { FinsurvModificationChangeLogPopupComponent } from './finsurvModificationChangeLogPopup.component';
import { AddFinsurvModificationPopup } from './addFinsurvModificationPopup.component';
import { TransfereceiptComponent } from './transfereceipt.component';
@NgModule({
  declarations: [
    RemittancesComponent,
    AddRemittanceComponent,
    PayRemittanceComponent,
    PaybackRemittanceComponent,
    RemittanceCompliancePopupComponent,
    RemittanceApprovalPopupComponent,
    RemittanceBlockingPopupComponent,
    RemittanceUnblockingPopupComponent,
    RemittancePayReceiptPopupComponent,
    RemittancePaybackReceiptPopupComponent,
    RemittanceSendReceiptPopupComponent,

    CommissionConfigsComponent,
    CommissionConfigDetailsComponent,
    AttachmentsPopupComponent,
    RemittanceReturnPopupComponent,
    RemittanceHistoryPopupComponent,
    RemittanceHistoryChangeLogPopupComponent,
    RemittanceTracePopupComponent,
    CommissionConfigsRequetsComponent,
    CommissionConfigurationRequestPopupComponent,
    RemittanceReceiverInformationUpdateComponent,
    AmbiguousRemittancesComponent,
    RemittanceResolvePopupComponent,
    MobileOwnershipVerifPopupComponent,
    UploadRemittanceReceiptPopupComponent,
    RemittanceReceiptPopupComponent,
    FinsurvTransactionsComponent,
    FinsurvReportsComponent,
    FinsurvTransactionsPopupComponent,
    FinsurvModificationsPopup,
    FinsurvModificationChangeLogPopupComponent,
    AddFinsurvModificationPopup,
    TransfereceiptComponent
  ],
  imports: [
    CommonModule,
    RemittanceRoutesModule,
    ContainersModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatIconModule,
    TranslateModule,
    MatDialogModule,
    MatDatepickerModule,
    NgxPrintModule,
    MatTooltipModule,
    NgSelectModule,
    MatMenuModule,
    MatIconModule,
    ValidationErrorModule,
    DragDropModule
  ],
  exports: [],
  providers: [
    RemittanceService,
    CommissionConfigurationService,
    RemittanceForPaybackResolver,
    RemittanceSubTypesResolver,
    TellerAccountsResolver,
    PayingCountriesResolver,
    CountriesResolver,
    PaymentTypesResolver,
    RemittanceForPaymentResolver,
    CommissionConfigurationsResolver,
    RemittanceStatusesResolver,
    RemittancePurposesResolver,
    RemittanceRelationshipsResolver,
    CommissionConfigRequestStatusResolver,
  ],
})
export class RemittanceModule {}
