<h1 mat-dialog-title>{{ 'changeLog' | translate }}</h1>
<div mat-dialog-content>
    <pre>
  {{ data | json }}
  </pre>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="warn" mat-dialog-close="close">
        {{ 'close' | translate }}
    </button>
</div>