import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TrialBalanceReportDto } from './data/dto/trialBalance.dto';
import * as XLSX from 'xlsx';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-trial-balance-popup',
  templateUrl: './trialBalancePopup.component.html',
  styleUrls: ['./reportStyles.css'],
})
export class TrialBalancePopupComponent {
  comments: string = '';
  debitSum!: number;
  creditSum!: number;

  constructor(
    public dialogRef: MatDialogRef<TrialBalancePopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { date: Date; data: TrialBalanceReportDto },
  ) {
    this.debitSum = data.data.sumDebit
    this.creditSum = data.data.sumCredit
  }

  convertDateToLocaleString(date: Date, pritable: boolean) {
    return pritable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('accountsData'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const fileName =
      'Trial Balance on ' +
      this.convertDateToLocaleString(this.data.date, false) +
      '.xlsx';
    XLSX.writeFile(wb, fileName);
  }
}
