import {Component, ElementRef, Inject, OnInit, ViewChild} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {SnackBarService} from "../_common/snackBar.service";
import {AttachmentsPopupComponent} from "./attachmentsPopup.component";
import {RemittanceFilter, RemittanceService} from "./data/remittance.service";

@Component({
  selector: 'upload-remittance-receipt-popup.component',
  templateUrl: './uploadRemittanceReceiptPopup.component.html'
})
export class UploadRemittanceReceiptPopupComponent implements OnInit {

  // @ts-ignore
  @ViewChild('remittanceReceiptFile') remittanceReceiptFileUploadElem: ElementRef;

  form!: UntypedFormGroup;
  title: string = '';
  remittanceReceiptDocFile: File[] = [];
  remittanceReceiptDocFileUrl: any = [];
  remittanceReceiptDocs: any = [];
   isPdfFile: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<UploadRemittanceReceiptPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      remittanceId: number;
      title: string;
      remitDocTypes : any
      receiptTypeId: number;
    },
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private remittanceService: RemittanceService
  ) {
    this.title = this.data.title;
  }

  ngOnInit() {

    this.form = this.fb.group({
      cityName: [Validators.required],
      country: [
        Validators.required,
      ]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(){
    if(this.remittanceReceiptDocFile?.length < 1){
      this.snackBar.open('noFile');
      return
    }

    this.remittanceService
      .uploadRemittanceReceiptAttachment(
        this.data.remittanceId,
        this.data.receiptTypeId,
        this.remittanceReceiptDocFile
      )
      .subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.remittanceService.getRemittances(({remittanceId: this.data.remittanceId}) as RemittanceFilter);
          this.dialogRef.close()
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
  }


  onRemittanceReceiptDocAttachmentSelected(e: Event) {
    const reader = new FileReader();

    if (this.remittanceReceiptDocFile?.length >= 1) {
      this.snackBar.open(
        `onlyOneAttachmentRequired`,
      );
    } else {
      //@ts-ignore
      const file = e.target.files[0];
      this.remittanceReceiptDocFile?.push(file);
      reader.onload = (event: any) => {

        this.remittanceReceiptDocFileUrl.push(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            event.target.result,
          ),
        );
        this.remittanceReceiptDocs?.push({
          type: file.type,
          name: file.name,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(
            event.target.result,
          ),
        });
        if (
          this.remittanceReceiptDocFileUrl &&
          this.remittanceReceiptDocFileUrl.length > 0 &&
          this.remittanceReceiptDocFileUrl[0].changingThisBreaksApplicationSecurity &&
          this.remittanceReceiptDocFileUrl[0].changingThisBreaksApplicationSecurity.indexOf('data:application/pdf') > -1
        ) {
          this.isPdfFile = true;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onViewAttachment(imageURL: string[] | undefined) {
    this.dialog.open(AttachmentsPopupComponent, {
      width: '850px',
      data: { imageURL: imageURL, isPdfFile: this.isPdfFile },
    });
  }

  clearSelectedRemittanceDocAttachment() {
    this.remittanceReceiptDocFile = [];
    this.remittanceReceiptDocs = [];
    this.remittanceReceiptDocFileUrl = [];
    this.remittanceReceiptFileUploadElem.nativeElement.value = null;
  }

}
