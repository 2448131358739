import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReportsService } from './reports.service';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';

@Injectable()
export class CommissionStatusesResolver implements Resolve<any> {
  constructor(private reportService: ReportsService) {}
  resolve(): Observable<LookupDto[]> {
    return this.reportService.getCommissionStatuses();
  }
}
