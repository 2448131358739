import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettlementComponent } from './settlement.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SettlementRoutesModule } from './settlement.routes.module';
import { SettlementService } from './data/settlement.service';
import {
  SettlementStatusResolver,
  SettlementTypesResolver,
} from './data/settlement.resolver';
import { SettlementDetailsComponent } from './settlementDetails.component';
import { SettlementDetailsPopupComponent } from './settlementDetailsPopup.component';
import { NgxPrintModule } from 'ngx-print';
import { SettlementHistoryPopupComponent } from './settlementHistoryPopup.component';
import { SettlementHistoryChangeLogPopupComponent } from './settlementHistoryChangeLogPopup.component';
import { SettlementTransferPopupComponent } from './settlementTransferPopup.component';
import { SettlementRejectPopupComponent } from './settlementRejectPopup.component';
import { SettlementRollbackPopupComponent } from './settlementRollbackPopup.component';
import { SettlementConfirmPopupComponent } from './settlementConfirmPopup.component';
import { SettlementConfirmReceiptPopupComponent } from './settlementConfirmReceiptPopup.component';
import { SettlementAddReceiptPopupComponent } from './settlementAddReceiptPopup.component';
import { MatMenuModule } from '@angular/material/menu';
import { ValidationErrorModule } from '../_common/components/validation-error/validation-error.module';

@NgModule({
  declarations: [
    SettlementComponent,
    SettlementDetailsComponent,
    SettlementDetailsPopupComponent,
    SettlementHistoryPopupComponent,
    SettlementHistoryChangeLogPopupComponent,
    SettlementTransferPopupComponent,
    SettlementRejectPopupComponent,
    SettlementRollbackPopupComponent,
    SettlementConfirmPopupComponent,
    SettlementConfirmReceiptPopupComponent,
    SettlementAddReceiptPopupComponent,
  ],
  imports: [
    CommonModule,
    SettlementRoutesModule,
    ContainersModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    NgxPrintModule,
    MatMenuModule,
    ValidationErrorModule,
  ],
  exports: [],
  providers: [
    SettlementService,
    SettlementTypesResolver,
    SettlementStatusResolver,
  ],
})
export class SettlementModule {}
