<app-form-container [label]="'addJournal' | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branchId' | translate }}:</mat-label>
        <mat-select
          formControlName="branchId"
          [(ngModel)]="branchValue"
          (selectionChange)="getBranchAccounts($event.value)"
        >
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{
              branch.title === 'CPO Branch'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'journalDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="journalDate"
          formControlName="journalDate"
          [disabled]="true"
          [value]="date.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="journalDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #journalDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row">
      <table mat-table [dataSource]="dataSource" formArrayName="data">
        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef>{{ 'account' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field appearance="outline" class="col">
              <mat-select
                formControlName="account"
                (selectionChange)="accountSelectionChange(index, $event.value)"
              >
                <mat-option
                  *ngFor="let account of branchAccounts"
                  [value]="account.id"
                >
                  {{ account.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef>{{ 'total' | translate }}</td>
        </ng-container>

        <ng-container matColumnDef="debitAccountCurrencyAmount">
          <th mat-header-cell *matHeaderCellDef>{{ 'debit' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field appearance="outline" class="col">
              <input
                matInput
                formControlName="debitAccountCurrencyAmount"
                (keyup)="debitChange(index, $event)"
              />
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="debitBranchCurrencyAmount">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'amount' | translate }}{{ ' ' + this.branchCurrencyCode }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-label>{{ row.value.debitBranchCurrencyAmount | number:'1.2-2' }}</mat-label>
          </td>
          <td mat-footer-cell *matFooterCellDef>{{ totalDebit | number:'1.2-2' }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="creditAccountCurrencyAmount">
          <th mat-header-cell *matHeaderCellDef>{{ 'credit' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field appearance="outline" class="col">
              <input
                matInput
                formControlName="creditAccountCurrencyAmount"
                (keyup)="creditChange(index, $event)"
              />
            </mat-form-field>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="creditBranchCurrencyAmount">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'amount' | translate }}{{ ' ' + this.branchCurrencyCode }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-label>{{ row.value.creditBranchCurrencyAmount | number:'1.2-2' }}</mat-label>
          </td>
          <td mat-footer-cell *matFooterCellDef>{{ totalCredit | number:'1.2-2' }}</td>
        </ng-container>

        <ng-container matColumnDef="branchExRate">
          <th mat-header-cell *matHeaderCellDef>
            {{ this.branchCurrencyCode + ' ' }}{{ 'exchangeRate' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-label>{{ row.value.branchExRate | number:'1.0-5' }}</mat-label>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="usdExRate">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'usdExRate' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-label>{{ row.value.usdExRate | number:'1.0-5' }}</mat-label>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="usdAmount">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'usdAmount' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-label>{{ row.value.usdAmount | number:'1.2-2' }}</mat-label>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
          <td mat-cell *matCellDef="let row; let index = index">
            <button
              *ngIf="index === 1"
              mat-raised-button
              type="button"
              (click)="addRow()"
              color="primary"
            >
              {{ 'add' | translate }}
            </button>
            <button
              *ngIf="index > 1"
              mat-raised-button
              type="button"
              (click)="removeRow(index)"
              color="warn"
            >
              {{ 'remove' | translate }}
            </button>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          mat-footer-row
          *matFooterRowDef="displayedColumns; sticky: true"
        ></tr>
      </table>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col my-2">
        <mat-label>{{ 'comments' | translate }}</mat-label>
        <textarea matInput formControlName="comments"></textarea>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end my-2">
      <button
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/branches/journals"
      >
        {{ 'back' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="authService.isUserAuthorized(permissions.CREATE_MANUAL_JOURNAL)"
        type="submit"
        color="primary"
      >
        {{ 'addJournal' | translate }}
      </button>
    </div>
  </form>
</app-form-container>
