<app-index-container>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <mat-card>
      <mat-card-title>{{ title | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{ 'branchId' | translate }}:</mat-label>
            <mat-select
              formControlName="branchId"
              (selectionChange)="onBranchSelected($event.value)"
              [(ngModel)]="branchValue"
              [disabled]="!addMode"
            >
              <mat-option *ngFor="let branch of branches" [value]="branch.id">
                {{
                  branch.title === 'CPO Branch'
                    ? ('hqBranch' | translate)
                    : branch.code + ' - ' + branch.title
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{ 'type' | translate }}:</mat-label>
            <mat-select
              formControlName="typeId"
              (selectionChange)="showHideOptions($event.value)"
              [(ngModel)]="typeValue"
              [disabled]="!addMode"
            >
              <mat-option *ngFor="let type of types" [value]="type.id">
                {{ type.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{ 'group' | translate }}:</mat-label>
            <mat-select
              formControlName="groupId"
              (selectionChange)="getParents()"
              [(ngModel)]="groupValue"
              [disabled]="!addMode"
            >
              <mat-option *ngFor="let group of groups" [value]="group.id">
                {{ group.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{ 'parent' | translate }}:</mat-label>
            <mat-select formControlName="parentId" [disabled]="!addMode">
              <mat-option *ngFor="let parent of parents" [value]="parent.id">
                {{ parent.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{ 'accountName' | translate }}:</mat-label>
            <input matInput #accountName formControlName="accountName" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-2">
            <mat-label>{{ 'currency' | translate }}:</mat-label>
            <mat-select formControlName="currencyId" [disabled]="!addMode">
              <mat-option
                *ngFor="let currency of currencies"
                [value]="currency.id"
              >
                {{ currency.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-2" *ngIf="showUsers">
            <mat-label>{{ 'users' | translate }}:</mat-label>
            <mat-select formControlName="userId" [disabled]="!addMode">
              <mat-option
                *ngFor="let user of branchUsers"
                [value]="user.id"
              >
                {{ user.username }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-radio-group formControlName="isActive" class="col-2 my-3">
            <mat-radio-button value="active">{{
              'active' | translate
            }}</mat-radio-button
            >&nbsp;
            <mat-radio-button value="inactive">{{
              'inactive' | translate
            }}</mat-radio-button>
          </mat-radio-group>
          <mat-checkbox
          *ngIf="showOptions"
          matInput
          #isTeller
          color="primary"
          formControlName="isTeller"
          [disabled]="!addMode"
          (change)="onTellerChecked($event)"
          class="col-2 my-3"
        >
          {{ 'teller' | translate }}
        </mat-checkbox>
          <div class="row">
            <div class="col">
              <div *ngIf="showOptions">
                <label class="label"
                  >{{ 'allowManualEntry' | translate }}:</label
                >&nbsp;
                <mat-radio-group
                  formControlName="isAllowManualEntry"
                  [disabled]="!addMode"
                >
                  <mat-radio-button value="yes">{{
                    'yes' | translate
                  }}</mat-radio-button
                  >&nbsp;
                  <mat-radio-button value="no">{{
                    'no' | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="col">
              <div *ngIf="showOptions">
                <label class="label">{{ 'creditLocked' | translate }}:</label>
                &nbsp;
                <mat-radio-group
                  formControlName="isCreditLocked"
                  [disabled]="!addMode"
                >
                  <mat-radio-button value="yes">{{
                    'yes' | translate
                  }}</mat-radio-button
                  >&nbsp;
                  <mat-radio-button value="no">{{
                    'no' | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
            <div class="col">
              <div *ngIf="showOptions">
                <label class="label">{{ 'debitLocked' | translate }}:</label>
                &nbsp;
                <mat-radio-group
                  formControlName="isDebitLocked"
                  [disabled]="!addMode"
                >
                  <mat-radio-button value="yes">{{
                    'yes' | translate
                  }}</mat-radio-button
                  >&nbsp;
                  <mat-radio-button value="no">{{
                    'no' | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-end my-3">
      <button
        *ngIf="addMode"
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/branches/accounts"
      >
        {{ 'back' | translate }}
      </button>
      <button
        mat-raised-button
        [disabled]="!form.valid"
        type="submit"
        color="primary"
      >
        {{ title | translate }}
      </button>
    </div>
  </form>
</app-index-container>
