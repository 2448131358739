import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SearchBranchesDto } from '../branch/data/dto/branches.dto';
import { ReportsService, SettlementReportFilter } from './data/reports.service';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SettlementReportPopupComponent } from './settlementReportPopup.component';
import {SnackBarService} from "../_common/snackBar.service";

@Component({
  selector: 'app-settlement-report',
  templateUrl: './settlementReport.component.html',
})
export class SettlementReportComponent implements OnInit {
  [x: string]: any;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: FormGroup;
  today = new Date();
  selectedStatuses: string[] = [];
  settlers: SearchBranchesDto[] = [];
  beneficiaries: SearchBranchesDto[] = [];
  statuses: LookupDto[] = [];
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthService,
    private reportService: ReportsService,
    private route: ActivatedRoute,
    private snackbar : SnackBarService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ settlers, beneficiaries, statuses }) => {
      this.settlers = settlers;
      this.beneficiaries = beneficiaries;
      this.statuses = statuses.sort((a: LookupDto, b: LookupDto) =>
        a.name.localeCompare(b.name),
      );
    });

    this.form = this.fb.group({
      direction: [null, [Validators.required]],
      settler: [null, [Validators.required]],
      beneficiary: [null, [Validators.required]],
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onDirectionChange(direction: string) {
    const settler = this.form.get('settler');
    const beneficiary = this.form.get('beneficiary');
    if (direction === '0') {
      settler?.setValidators(Validators.required);
      settler?.enable();
      beneficiary?.setValidators(Validators.required);
      beneficiary?.enable();
    } else if (direction === 'incoming') {
      settler?.removeValidators(Validators.required);
      settler?.setValue(null);
      settler?.disable();
      beneficiary?.setValidators(Validators.required);
      beneficiary?.enable();
    } else if (direction === 'outgoing') {
      settler?.setValidators(Validators.required);
      settler?.enable();
      beneficiary?.removeValidators(Validators.required);
      beneficiary?.disable();
      beneficiary?.setValue(null);
    }
  }

  onSubmit() {
    if (!this.selectedStatuses.length) {
      this.snackbar.open('pleaseSelectStatus');
      // alert('Please select a status');
      return;
    }

    if (this.form.valid) {
      const { settler, beneficiary, startDate, endDate, direction } =
        this.form.value;
      const filter: SettlementReportFilter = {
        statuses: this.selectedStatuses,
        direction,
        startDate: startDate,
        endDate: endDate,
      };
      if (direction !== '0') filter.direction = direction;
      if (direction !== 'incoming') filter.settlerId = settler.id;
      if (direction !== 'outgoing') filter.beneficiaryId = beneficiary.id;

      this.reportService.getSettlementReport(filter).subscribe((data) => {
        const dialogRef = this.dialog.open(SettlementReportPopupComponent, {
          data: { startDate: startDate, endDate: endDate, data: data },
          width: '1200px',
          maxHeight: '90vh',
        });

        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            return;
          }
        });
      }, ({ message }) => {
        this.snackbar.open(message);
      });
    }
  }

  checkStatus(e: any) {
    if (e.checked) {
      this.selectedStatuses.push(e.source.value);
    } else {
      this.selectedStatuses = this.selectedStatuses.filter(
        (f) => f !== e.source.value,
      );
    }
  }

  convertToString(value: number) {
    return value.toString();
  }
}
