<div class="col-md-4 mx-auto pt-5">
  <form
    *ngIf="isLogin"
    name="loginForm"
    id="loginForm"
    [formGroup]="loginForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{ 'login' | translate }}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="px-3">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'username' | translate }}</mat-label>
              <input required matInput #username formControlName="username" autocomplete="off" />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 0px;">
              <mat-label>{{ 'password' | translate }}</mat-label>
              <input
                type="password"
                matInput
                #password
                formControlName="password"
                autocomplete="new-password"
              />
            </mat-form-field>

            <div
              style="text-align: right; color: #0074d9; cursor: pointer; text-decoration: underline; margin-top: 0px; font-size: 14px; transition: color 0.3s ease, text-decoration 0.3s ease;"
              (click)="forgotPassword()"
            >
              <span>{{ 'forgotPasswordLabel' | translate }}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="px-4 mb-3">
        <button mat-flat-button color="primary" (click)="login()">
          {{ 'login' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <form
    *ngIf="isGoogleLoginRequired"
    name="googleAuthenticationForm"
    [formGroup]="googleAuthenticationForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{
          'googleTwoFactorAuthentication' | translate
        }}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="px-3">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'otp' | translate }}</mat-label>
              <input
                type="password"
                required
                matInput
                #otp
                formControlName="otp"
                autocomplete="new-password"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="px-4 mb-3 d-flex justify-content-center">
        <button mat-flat-button color="primary" (click)="loginWithGoogle()">
          {{ 'useGoogleAuthenticator' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <form
    *ngIf="isSmsLoginRequired"
    name="smsAuthenticationForm"
    id="loginForm"
    [formGroup]="smsAuthenticationForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{ 'smsAuthentication' | translate }}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="px-3">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'smsCode' | translate }}</mat-label>
              <input required matInput #smsCode formControlName="smsCode" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="px-4 mb-3 d-flex justify-content-center">
        <button mat-flat-button color="primary" (click)="loginWithSms()">
          {{ 'useYourPhoneNumber' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <form
    *ngIf="isForceResetPassword"
    name="forceResetPwdForm"
    id="forceResetPwdForm"
    [formGroup]="forceResetPwdForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{ pageTitle | translate }}</mat-card-title>
        <mat-card-subtitle
          ><p>{{ message | translate }}</p></mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content class="px-3">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'newPassword' | translate }}</mat-label>
              <input
                type="password"
                matInput
                #newPassword
                formControlName="newPassword"
                [pattern]="passwordPattern"
                autocomplete="new-password"
              />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'confirmPassword' | translate }}</mat-label>
              <input
                type="password"
                matInput
                #confirmPassword
                formControlName="confirmPassword"
                autocomplete="new-password"
              />
              <mat-error *ngIf="forceResetPwdForm.value.confirmPassword">{{
                'passwordMismatch' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="px-4 mb-3">
        <button
          mat-flat-button
          color="primary"
          (click)="resetPassword()"
          class="ms-1"
        >
          {{ 'updatePassword' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <form
    *ngIf="isForceOtpConfiguration"
    name="forceOtpConfigurationForm"
    [formGroup]="forceOtpConfigurationForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{
          '2FactorAuthentication' | translate
        }}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="px-3">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'selectOtpType' | translate }}:</mat-label>
              <mat-select #otpType formControlName="otpType">
                <mat-option value="Google Authentication">
                  {{ 'googleAuthentication' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions class="px-4 mb-3 d-flex justify-content-center">
        <button mat-flat-button color="primary" (click)="configureOTP()">
          {{ 'configureOTP' | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
  <div class="row" *ngIf="optIsselected">
    <h2>{{ 'selectTwoFactorAuthentication' | translate }}</h2>

    <div class="row" *ngIf="googleSteps">
      <hr />
      <div class="row">
        <h2>{{ 'stepsToEnablegoogle2fa' | translate }}</h2>
      </div>
      <div class="row">
        <p>1- {{ 'googleSteps1' | translate }}</p>
        <p>2- {{ 'googleSteps2' | translate }}</p>
        <p>3- {{ 'googleSteps3' | translate }}</p>
      </div>
      <div class="row">
        <div class="col">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            ><img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              height="50px"
              width="100px"
          /></a>
          &nbsp;
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            ><img
              src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
              height="50px"
              width="100px"
          /></a>
        </div>
      </div>
      <hr />
      <div class="row" *ngIf="showQrCodeImg">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <img
              [src]="imgUrl"
              height="140px"
              width="140px"
              style="margin-bottom: 10px"
            />
          </div>
          <div class="col">
            <form
              form
              class="container-fluid flex-column p-0"
              name="form"
              [formGroup]="googleAuthenticationForm"
              novalidate
            >
              <div class="row">
                <mat-form-field appearance="outline" class="col">
                  <mat-label>{{ 'googleOtp' | translate }}</mat-label>
                  <input required matInput #googleOtp formControlName="otp" />
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col d-flex justify-content-center">
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="enableOtp()"
                  >
                    {{ 'submit' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr />
      </div>
      <div class="row" *ngIf="continueButton">
        <div class="col d-flex justify-content-center">
          <button mat-raised-button color="primary" (click)="showQrCode()">
            {{ 'continue' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <form
    *ngIf="isForgotPasswordFormRequired"
    name="isForgotPasswordFormRequired"
    id="isForgotPasswordFormRequired"
    [formGroup]="forgotPasswordForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{ 'forgotPassword' | translate }}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="px-1">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'email' | translate }}</mat-label>
              <input required matInput #email formControlName="email" autocomplete="off" />
            </mat-form-field>
          </div>
        </div>
        <button mat-flat-button color="primary" (click)="sendEmail()">
          {{ 'Continue' | translate }}
        </button>
      </mat-card-content>
    </mat-card>
  </form>
</div>
