<app-form-container [label]="title | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col col-md-4">
        <mat-label>{{ 'remittanceComplianceType' | translate }}:</mat-label>
        <mat-select
          #remittanceComplianceTypeId
          formControlName="remittanceComplianceTypeId"
          (selectionChange)="onComplianceTypeChange($event.value)"
        >
          <mat-option
            *ngFor="let complianceType of complianceTypes"
            [value]="complianceType.id"
          >
            {{ complianceType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field
        *ngIf="showAmount"
        appearance="outline"
        class="col col-md-4"
      >
        <mat-label>{{ 'amountUsd' | translate }}:</mat-label>
        <input matInput #amount formControlName="amount" />
      </mat-form-field>
      <mat-form-field
        *ngIf="showTransactionCount"
        appearance="outline"
        class="col col-md-4"
      >
        <mat-label>{{ 'transactionCount' | translate }}:</mat-label>
        <input matInput #transactionCount formControlName="transactionCount" />
      </mat-form-field>
      <mat-form-field
        *ngIf="showDuration"
        appearance="outline"
        class="col col-md-4"
      >
        <mat-label>{{ 'durationInDays' | translate }}:</mat-label>
        <input matInput #duration formControlName="duration" />
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #primaryDocRequired
          color="primary"
          formControlName="primaryDocRequired"
        >
          {{ 'primaryDocRequired' | translate }}
        </mat-checkbox>
      </div>
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #isAttachmentRequired
          color="primary"
          formControlName="isAttachmentRequired"
        >
          {{ 'isAttachmentRequired' | translate }}
        </mat-checkbox>
      </div>
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #branchApprovalRequired
          color="primary"
          formControlName="branchApprovalRequired"
        >
          {{ 'branchApprovalRequired' | translate }}
        </mat-checkbox>
      </div>
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #parentBranchApprovalRequired
          color="primary"
          formControlName="parentBranchApprovalRequired"
        >
          {{ 'parentBranchApprovalRequired' | translate }}
        </mat-checkbox>
      </div>
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #cpoBranchApprovalRequired
          color="primary"
          formControlName="cpoBranchApprovalRequired"
        >
          {{ 'hqBranchApprovalRequired' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        *ngIf="addMode"
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/compliances/configuration"
      >
        {{ 'back' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="
          authService.isUserAuthorized([
            permissions.CREATE_COMPLIANCE_CONFIG,
            permissions.UPDATE_COMPLIANCE_CONFIG
          ])
        "
        type="submit"
        color="primary"
      >
        {{ title | translate }}
      </button>
    </div>
  </form>
</app-form-container>
