import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/data/auth.service';
import { LookupsService } from '../data/lookups.service';
import {customerDocs, LookupsDto} from '../data/lookups.dto';
import { CustomerDocumentTypesPopupComponent } from './customerDocumentTypesPopup.component';

@Component({
  selector: 'app-customer-document-types',
  templateUrl: './customerDocumentTypes.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class CustomerDocumentTypesComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  displayedColumns: string[] = [
    'number',
    'name',
    'code',
    'minimumLength',
    'maximumLength',
    'regularExpression',
    'type',
    'actions',
  ];
  data = new MatTableDataSource<customerDocs>();
  permissions = Permissions.lookupsManagement.customerDocumentTypes;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public lookupsService: LookupsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.lookupsService.customerDocumentTypes.subscribe((documentTypes) => {
      this.data.data = documentTypes;
      setTimeout(() => {
        this.data.paginator = this.paginator;
      }, 100);
    });
  }

  onSubmit() {
    this.lookupsService.getCustomerDocumentTypes();
  }

  addDocumentTypeDialog(documentTypeId?: number) {
    if (documentTypeId) {
      this.lookupsService
        .getCustomerDocumentType(documentTypeId)
        .subscribe((documentType) => {
          this.dialog.open(CustomerDocumentTypesPopupComponent, {
            data: { ...documentType },
            width: '800px',
          });
        });
    } else {
      this.dialog.open(CustomerDocumentTypesPopupComponent, {
        data: [],
        width: '800px',
      });
    }
  }

  checkDocumentType(isPrimary: boolean | null) {
    if (isPrimary === null) return '';
    return isPrimary ? 'primary' : 'secondary';
  }
}
