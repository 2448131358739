import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsDto } from './dto/role/permissions.dto';
import {
  RolesResponseDto,
  CreateRoleDto,
  UpdateRoleDto,
  SearchRoleRequestsDto,
  UpdateRoleRequestStatusDto,
} from './dto/role/roles.dto';
import { CRUDResponseDto } from '../../_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import { TableTypes } from '../../_common/enums/tableTypes.enums';

interface ModifiedData {
  level: string;
  old_value: any;
  new_value: any;
}

type RoleFilter = {
  userTypeId?: number;
  roleId?: number;
} | null;

export type RoleRequestFilter = {
  userTypeId?: number;
  statusId?: number;
};

type GetRoles = {
  roles: RolesResponseDto[];
  appliedFilters: RoleFilter;
};

@Injectable()
export class RolesService {
  private tablePendingChanges: string = '';
  private _roles: BehaviorSubject<GetRoles> = new BehaviorSubject<GetRoles>({
    roles: [],
    appliedFilters: null,
  });
  private rolesObservable?: Observable<GetRoles>;
  constructor(private httpService: HttpService) {}

  get roles() {
    if (!this.rolesObservable)
      this.rolesObservable = this._roles.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.ROLES) {
            this.triggerGetRoles(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.rolesObservable;
  }

  triggerGetRoles(filter: RoleFilter = null) {
    this.getRoles(filter).subscribe((resp) => {
      this.tablePendingChanges = '';
      this._roles.next({
        roles: resp,
        appliedFilters: filter,
      });
    });
  }

  getRoles(filter: RoleFilter = null) {
    return this.httpService.get<RolesResponseDto[]>('role/list', filter);
  }

  createRole(payload: CreateRoleDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('role/create', payload);
  }

  updateRole(payload: UpdateRoleDto): Observable<CRUDResponseDto> {
    const modifiedData = this.getModifiedData(payload.oldRole, payload.newRole);
    const newPayload = {
      modifiedData: modifiedData,
      ...payload.newRole,
    }
    return this.httpService.post<CRUDResponseDto>('role/update', newPayload).pipe(
      map((resp) => {
        this.tablePendingChanges = TableTypes.ROLES;
        return resp;
      }),
    );
  }

  getModifiedData(obj1: any, obj2: any): ModifiedData[] {
    const modifiedData: ModifiedData[] = [];
  
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          const modifiedItem: ModifiedData = {
            level: key,
            old_value: obj1[key],
            new_value: obj2[key],
          };
          modifiedData.push(modifiedItem);
        }
      }
    }
  
    return modifiedData;
  }

  permissions(): Observable<PermissionsDto[]> {
    return this.httpService.get<PermissionsDto[]>('role/permission/list');
  }

  rolePermissions(roleId: number): Observable<string[]> {
    return this.httpService.get<string[]>(`role/permission/list/${roleId}`);
  }

  getRoleRequests(filter: RoleRequestFilter | null): Observable<SearchRoleRequestsDto[]> {
    return this.httpService.get<SearchRoleRequestsDto[]>('role/request/list', filter);
  }

  getRoleRequest(id: number): Observable<SearchRoleRequestsDto> {
    return this.httpService.get<SearchRoleRequestsDto>('role/request/find', { id });
  }

  updateRoleRequestStatus(dto: UpdateRoleRequestStatusDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'role/request/status/update',
      dto,
    );
  }
}
