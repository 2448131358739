import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { TicketsComponent } from './tickets.component';
import { TicketDetailsComponent } from './ticketDetails.component';
import {
  TicketAssigneeResolver,
  TicketBranchesResolver,
  TicketByIdResolver,
  TicketStatusResolver,
} from './data/support.resolver';
import {BranchesResolver} from "../branch/data/branches.resolver";

const {
  ticketManagement: { tickets },
} = Permissions;

const routes: Routes = [
  {
    path: 'support/tickets',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          permissions: [...Object.values(tickets)],
        },
        resolve: {
          statuses: TicketStatusResolver,
          branches: BranchesResolver,
          assignees: TicketAssigneeResolver,
        },
        component: TicketsComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        data: {
          permissions: [tickets.CREATE_TICKET],
          addMode: true,
        },
        resolve: {
          statuses: TicketStatusResolver,
          branches: TicketBranchesResolver,
          assignees: TicketAssigneeResolver,
        },
        component: TicketDetailsComponent,
      },
      {
        path: 'view/:ticketId',
        canActivate: [AuthGuard],
        data: {
          permissions: [
            tickets.UPDATE_TICKET,
            tickets.VIEW_TICKET,
            tickets.ADD_COMMENT,
          ],
          addMode: false,
        },
        resolve: {
          ticket: TicketByIdResolver,
          statuses: TicketStatusResolver,
          branches: TicketBranchesResolver,
          assignees: TicketAssigneeResolver,
        },
        component: TicketDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SupportRoutesModule {}
