import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CustomerService} from "../customer/data/customer.service";
import { SnackBarService } from '../_common/snackBar.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-mobile-ownership-verif-popup',
  templateUrl: './mobileOwnershipVerifPopup.component.html',

})
export class MobileOwnershipVerifPopupComponent implements OnInit {
  showVerificationSection: boolean = false;
  otp!: number;
  showVerifyMobileBtn: boolean = true;
  smsOtp!: number;
  isVerified: boolean = false;
  resultsLength = 0;
  pageSize = 5;
  currentPage = 1;
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<MobileOwnershipVerifPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    private snackBar: SnackBarService,
    ) { }

    onVerifyMobile(mobilePhone: string) {
      this.customerService
      .sendSmsOnMobileVerification(mobilePhone)
      .subscribe((resp) => {
          this.smsOtp = resp.smsOtp;
          this.showVerifyMobileBtn = false;
          this.showVerificationSection = true;
      },
        ({ message }) => {
          this.snackBar.open(message);
        },
        );
  }

  verifyOTP() {
    const message = 'invalidOtp';
    if(this.otp != this.smsOtp){
      this.snackBar.open(message);
      return;
    }
    this.isVerified = true;
    this.dialogRef.close(this.isVerified);
  }

  getFullName(firstName: string, middleName: string, lastName: string) {
    return middleName
      ? `${firstName} ${middleName} ${lastName}`
      : `${firstName} ${lastName}`;
  }

  get getDuplicateCustomers() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.data.customerWithSameMobile.slice(startIndex, endIndex);
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex + 1;
  }

  ngOnInit(): void {
  }


}
