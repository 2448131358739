<h1 mat-dialog-title>{{ 'remittanceApprovals' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-2 overflow-auto">
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2"
      *ngIf="data && data.branchViolations.length"
    >
      <div
        class="d-flex align-items-center h-8x bg-light px-2 border-bottom border-dark"
      >
        <b>{{ 'branchViolations' | translate }}</b>
      </div>
      <ul class="h-full px-2">
        <li *ngFor="let violation of data && data.branchViolations">
          {{
            violation.configurationType === 'commissionChangeApproval'
              ? '[' +
                violation.approvalType +
                ']' +
                ' Commission charged is outside of what is configured.'
              : violation.configurationType
          }}
        </li>
      </ul>
    </div>
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2"
      *ngIf="data && data.complianceViolations.length"
    >
      <div *ngFor="let compliance of data && data.complianceViolations">
        <div *ngFor="let detail of compliance.complianceDetails; let i = index">
          <div >
            <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <b>{{ detail }}</b>
                <mat-icon aria-hidden="false" aria-label="Icon">play_arrow</mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
              <li *ngIf="compliance.matchedAlieas[i]">Matched Name: <b>{{ compliance.matchedAlieas[i] }}</b></li>
          </mat-expansion-panel>
          </div>               
        <!-- <mat-accordion *ngIf="compliance.complianceData?.length">
          <mat-expansion-panel *ngFor="let cd of compliance.complianceData">
            <mat-expansion-panel-header>
              <mat-panel-title>
                [{{ cd.source }}] - {{ cd.name }}
              </mat-panel-title>
              <mat-panel-description>
                {{ cd.info | slice : 0 : 37 }}...
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div>{{ cd.info }}</div>
              <table class="h-full mx-2">
                <tr class="h-8x bg-light">
                  <th>{{ 'dates of birth' | translate }}</th>
                  <th>{{ 'passports' | translate }}</th>
                  <th>{{ 'countries' | translate }}</th>
                  <th>{{ 'cities' | translate }}</th>
                  <th>{{ 'keywords' | translate }}</th>
                  <th>{{ 'nationalities' | translate }}</th>
                  <th>{{ 'identifiers' | translate }}</th>
                  <th>{{ 'addresses' | translate }}</th>
                  <th>{{ 'aliases' | translate }}</th>
                  <th>{{ 'birth places' | translate }}</th>
                </tr>

                <tr>
                  <td>{{ cd.datesOfBirth.join(', ') }}</td>
                  <td>{{ cd.passports.join(', ') }}</td>
                  <td>{{ cd.countries.join(', ') }}</td>
                  <td>{{ cd.cities.join(', ') }}</td>
                  <td>{{ cd.keywords.join(', ') }}</td>
                  <td>{{ cd.nationalities.join(', ') }}</td>
                  <td>{{ cd.identifiers.join(', ') }}</td>
                  <td>{{ cd.addresses.join(', ') }}</td>
                  <td>{{ cd.aliases.join(', ') }}</td>
                  <td>{{ cd.birthPlaces.join(', ') }}</td>
                </tr>
              </table>
            </div>
          </mat-expansion-panel>
        </mat-accordion> -->
        <!-- <table *ngIf="compliance.remittanceData?.length" class="h-full mx-2">
          <tr class="h-8x bg-light">
            <th>{{ 'code' | translate }}</th>
            <th>{{ 'name' | translate }}</th>
            <th>{{ 'amount' | translate }}</th>
            <th>{{ 'date' | translate }}</th>
          </tr>
          <tr *ngFor="let remittance of compliance.remittanceData">
            <td>{{ remittance.code }}</td>
            <td>{{ remittance.name }}</td>
            <td>{{ remittance.amount | number:'1.2-2' }}</td>
            <td>{{ convertDateToLocaleString(remittance.date) }}</td>
          </tr>
        </table> -->
      </div>
    </div>
    <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
      <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
      <textarea
        matInput
        placeholder="{{ 'approvalComments' | translate }}"
        [(ngModel)]="comments"
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <!-- <button mat-button (click)="onShowDocs()">
    {{ 'SeeAttachment' | translate }}
  </button> -->
  <button mat-button (click)="onApproval()">
    {{ 'approve' | translate }}
  </button>
</div>
