import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../../_common/enums/permissions.enums';
import { RolesService } from '../data/roles.service';
import { PermissionsDto } from '../data/dto/role/permissions.dto';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { RolesResponseDto } from '../data/dto/role/roles.dto';
import { SnackBarService } from '../../_common/snackBar.service';
import { AuthService } from '../../auth/data/auth.service';
import { regExp } from 'src/app/_common/data/validationRules';
import {UserTypes} from "../../_common/enums/userTypes.enums";

interface Permission extends PermissionsDto {
  isChecked: boolean | undefined;
}

interface SubGroup {
  id: number;
  name: string;
  completed: boolean;
  indeterminate: boolean;
  permissions: Permission[];
}

interface Group {
  id: number;
  name: string;
  completed: boolean;
  indeterminate: boolean;
  subGroups: SubGroup[];
}

@Component({
  selector: 'app-role-details',
  templateUrl: './roleDetails.component.html',
})
export class RoleDetailsComponent implements OnInit {
  permissionsForm!: UntypedFormGroup;
  form!: UntypedFormGroup;

  rolesPermissions = Permissions.userManagement.roles;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;

  userTypes: LookupDto[] = [];
  permissions!: Group[];

  role?: RolesResponseDto;
  addMode = true;
  title = '';

  roleStatus: boolean | undefined = false;
  canActivate: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private rolesService: RolesService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ userTypes, permissions, roles, addMode }) => {
      this.addMode = addMode;
      if (roles && roles.length) {
        this.role = roles[0];
      }
      const userPermissions = this.authService.currentUserValue?.permissions;
      let extendablePermissions;
      if (this.authService.currentUserValue?.userTypeName == UserTypes.HQ) {
        extendablePermissions = permissions;
      } else {
        extendablePermissions = permissions.filter(
          (permission: { name: string }) =>
            userPermissions?.includes(permission.name),
        );
      }

      this.permissionsForm = this.getAllPermissionsGroup(
        extendablePermissions,
        this.role?.permissionIds,
      );

      this.roleStatus = this.role?.isActive;

      this.form = this.fb.group({
        name: [
          this.role?.name,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(20),
            Validators.pattern(regExp.alphaSpace),
          ],
        ],
        description: [
          this.role?.description,
          [
            Validators.required,
            Validators.minLength(2),
            Validators.maxLength(200),
            Validators.pattern(regExp.alphaNumericSpecialCharacters),
          ],
        ],
        isActive: [this.roleStatus],
        userTypeId: [this.role?.userTypeId, [Validators.required]],
        permissionIds: this.permissionsForm,
      });

      this.userTypes = userTypes;
      this.permissions = extendablePermissions.reduce(
        (groupedPermissions: Group[], permission: Permission) => {
          let group = groupedPermissions.find(
            (g) => g.id === permission.groupId,
          );
          if (!group) {
            group = {
              id: permission.groupId,
              name: permission.groupName,
              completed: false,
              indeterminate: false,
              subGroups: [],
            };
            groupedPermissions.push(group);
          }

          let subGroup = group.subGroups.find(
            (sg: SubGroup) => sg.id === permission.subGroupId,
          );
          if (!subGroup) {
            subGroup = {
              id: permission.subGroupId,
              name: permission.subGroupName,
              completed: false,
              indeterminate: false,
              permissions: [],
            };
            group.subGroups.push(subGroup);
          }
          permission.isChecked = this.role?.permissionIds?.includes(
            permission.id,
          );
          subGroup.permissions.push(permission);

          return groupedPermissions;
        },
        [] as Group[],
      );

      if (this.addMode) {
        this.title = 'addRole';
      } else if (
        this.authService.isUserAuthorized(this.rolesPermissions.UPDATE_ROLE)
      ) {
        this.title = 'updateRole';
      } else {
        this.title = 'viewRole';
        this.form.disable();
      }
    });

    //checking and unchecking groups and subgroups on init...
    if (!this.addMode) {
      this.setGroupAndSubGroupState();
    }
  }

  getAllPermissionsGroup(
    permissions: PermissionsDto[],
    rolePermissionIds: number[] | undefined,
  ): UntypedFormGroup {
    return this.fb.group(
      permissions.reduce((ps, p) => {
        ps[p.id] = rolePermissionIds?.includes(p.id) || false;
        return ps;
      }, {} as any),
    );
  }

  onSubmit() {
    if (this.form.valid) {
      const { value: formData } = this.form || {};
      formData.permissionIds = Object.keys(formData.permissionIds).filter(
        (id) => formData.permissionIds[id],
      );
      const handler = this.role
        ? this.rolesService.updateRole({
            oldRole: this.role,
            newRole: {
              id: this.role.id,
              ...formData
            },
            id: this.role.id,
            ...formData,
          })
        : this.rolesService.createRole(formData);
      handler.subscribe(
        (resp) => {

          this.snackBar.open(resp?.message);
          if (this.addMode) {
            this.resetBtn?.nativeElement.click();
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  //select all permission if their is selected...
  selectAllPermissionsOfSubgroup(
    checked: boolean,
    subGroupId: any,
    groupId: number,
  ) {
    this.permissions
      .find((g) => g.id === groupId)
      ?.subGroups.find((s) => s.id === subGroupId)
      ?.permissions.forEach((p) => (p.isChecked = checked));

    this.setGroupAndSubGroupState();
  }

  //selecting all subgroups under one group...
  selectAllSubGroups(checked: boolean, id: any) {
    this.permissions
      .find((g) => g.id === id)
      ?.subGroups.forEach((subgroup) =>
        this.selectAllPermissionsOfSubgroup(checked, subgroup.id, id),
      );
  }

  //checking each permission and if all permissions under one subgroup is checked Their subgroup will be checked..
  checkMe(checked: boolean, id: number, subGroupId: number, groupId: number) {
    const permission = this.permissions
      .find((g) => g.id === groupId)
      ?.subGroups.find((s) => s.id === subGroupId)
      ?.permissions.find((p) => p.id === id);

    if (permission) {
      permission.isChecked = checked;
      this.setGroupAndSubGroupState();
    }
  }

  setGroupAndSubGroupState() {
    this.permissions.forEach((g) => {
      g.completed = false;
      g.indeterminate = false;
      g.subGroups.forEach((s) => {
        s.completed = false;
        s.indeterminate = false;
        const checkedPermissions = s.permissions.filter((p) => p.isChecked);
        s.completed = checkedPermissions.length === s.permissions.length;
        if (!s.completed) {
          s.indeterminate = checkedPermissions.length > 0;
        }
      });

      const completedSubGroups = g.subGroups.filter((s) => s.completed);
      g.completed = completedSubGroups.length === g.subGroups.length;
      if (!g.completed) {
        const intermediateSubGroups = g.subGroups.filter(
          (s) => s.indeterminate,
        );
        g.indeterminate =
          !!intermediateSubGroups.length || !!completedSubGroups.length;
      }
    });
  }

  //unchecking module and submodule on form reset...
  uncheckAll() {
    this.permissions.forEach((g) => {
      g.completed = false;
      g.indeterminate = false;
      g.subGroups.forEach((sg) => {
        sg.completed = false;
        sg.indeterminate = false;
      });
    });
  }
}
