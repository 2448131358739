import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import {ReferalDashboardComponent } from './referalDashboard.component';

const {
  REFERAL_MANAGEMENT: { referals },
} = Permissions;

const routes: Routes = [
  {
    path: 'referals',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: Object.values(referals) },
        component: ReferalDashboardComponent,
      },
    ],
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReferalRoutesModule {}
