<app-index-container label="{{ 'accountsManagement' | translate }}">
  <button
    headerActions
    mat-raised-button
    color="primary"
    routerLink="/branches/account/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addAccount' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branchId' | translate }}:</mat-label>
        <mat-select
          formControlName="branchId"
          (selectionChange)="getAccountType($event)"
        >
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{
              branch.title === 'CPO Branch'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'group' | translate }}:</mat-label>
        <mat-select formControlName="groupId">
          <mat-option *ngFor="let group of groups" [value]="group.id">
            {{ group.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'type' | translate }}:</mat-label>
        <mat-select formControlName="typeId">
          <mat-option *ngFor="let type of types" [value]="type.id">
            {{ type.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'accountNumber' | translate }}:</mat-label>
        <input matInput #accountName formControlName="accountNumber" />
      </mat-form-field>
      <mat-radio-group formControlName="isActive">
        <mat-radio-button value="active">{{
          'active' | translate
        }}</mat-radio-button
        >&nbsp;
        <mat-radio-button value="inactive">{{
          'inactive' | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="d-flex justify-content-end">
      <button
        mat-raised-button
        [disabled]="!form.valid"
        type="submit"
        color="primary"
      >
        <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
      </button>
    </div>
  </form>
  <table table mat-table [dataSource]="accounts" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      <!-- {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} -->
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'accountId' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'accountName' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'accountGroup' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.group }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'accountType' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef>{{ 'currency' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.currency }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <button
          mat-raised-button
          *ngIf="authService.isUserAuthorized([permissions.UPDATE_ACCOUNT])"
          color="primary"
          routerLink="/branches/account/update/{{ row.accountId }}"
        >
          {{
            (authService.isUserAuthorized(permissions.UPDATE_ACCOUNT)
              ? 'edit'
              : 'view'
            ) | translate
          }}
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
