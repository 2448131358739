export type CbkReportFilter = {
  startDate: Date;
  endDate: Date;
  direction?: number;
  currencyId?: number;
  type?: string;
};

export type BtransFilter = {
  id?: number;
  date: string;
  type: string;
  recordType: string;
};

export type CbkRemittanceReportDto = {
  date: string,
  totalRemittance: number,
  receivedUsd: number,
  totalTl: number,
  numberOfRemittances: number,
};

export type BsmvResponseDetail = {
  date: string;
  code: string;
  originCurrencyCode: string;
  originalAmount: number;
  originalCommission: number;
  originalBsmv: number;
  turkishExchangeRate: number;
  amountInLira: number;
  commissionInLira: number;
  bsmvInLira: number;
  totalInOriginal: number;
  totalInTL: number;
}

export type BsmvReport = {
  bsmvReportDetails: BsmvResponseDetail[];
  totalOriginalAmount: number;
  totalOriginalCommission: number;
  totalOriginalBsmv: number;
  totalAmountInTL: number;
  totalCommissionInTL: number;
  totalBsmvInTL: number;
  grandOriginalTotal: number;
  grandTLTotal: number;
  reportCurrency: string;
}

export type ClientProtectionDetails = {
  date: string;
  code: string;
  taajSettlement: number;
  paidFromTaajSettlement: number;
  incomingRemittanceAmount: number;
  outgoingRemittanceAmount: number;
  tobeDepositedIntoClientProtectionAccount: number;
}

export type ClientProtectionReport = {
  clientProtectionDetails: ClientProtectionDetails[];
  totalTaajSettlements: number;
  totalIncomingRemittances: number;
  totalOutgoingRemittances: number;
  totalPaidFromTaajSettlements: number;
  totalToBeDepositedIntoClientProtectionAccount: number;
  reportCurrency: string;
}

export type IncomingRemittancesReportDetails = {
  date: string;

  transactionId: string;

  senderName: string;

  senderLastName: string;

  senderTellPhoneNumber: string;

  receiverName: string;

  receiverLastName: string;

  descriptionPurpose: string;

  remittanceReference: string;

  sendingExchangeRate: number;

  payingExchangeRate: number;

  turkishExchangeRate: number;

  receiverIdNumber: number;

  receiverIdType: string;

  senderAddress: string;

  receiverAddress: string;

  senderCountryCode: string;

  receiverCountryCode: string;

  receiverTellPhoneNumber: string;

  transactionTime: string;

  receiverTransactionDate: string;

  transactionDate: string;

  amountInTL: number;

  originalAmount: number;

  originAmountCurrency: string;
}

export type IncomingRemittancesReport = {
  incomingOrOutgoingRemittancesReportDetails: IncomingRemittancesReportDetails[];
  grandTotalInOriginal: number;
  grandTotalInTL: number;
  reportCurrency: string;
}

export type OutgoingRemittancesReportDetails = {
  date: string;

  transactionId: string;

  senderName: string;

  senderLastName: string;

  senderIdType: string;

  senderIdNumber: string;

  senderTellPhoneNumber: string;

  receiverName: string;

  receiverLastName: string;

  descriptionPurpose: string;

  totalInOriginal: number;

  originalAmount: number;

  originalCommission: number;

  originalBsmv: number;

  amountInTL: number;

  commissionInTL: number;

  bsmvInTL: number;

  totalInTL: number;

  turkishExchangeRate: number;

  senderAddress: string;

  senderCountryCode: string;

  receiverCountryCode: string;

  transactionTime: string;

  sendDate: string;

  paymentDate: string;

  originAmountCurrency: string;
}

export type OutgoingRemittancesReport = {
  incomingOrOutgoingRemittancesReportDetails: OutgoingRemittancesReportDetails[];
  totalAmountInOriginal: number;
  totalCommissionInOriginal: number;
  totalBsmvInOriginal: number;
  grandTotalInOriginal: number;
  totalAmountInTL: number;
  totalCommissionInTL: number;
  totalBsmvInTL: number;
  grandTotalInTL: number;
  reportCurrency: string;
}

export type CbkReportDetailFilter = {
  remittanceIds: string;
  direction: number
}

export type ClientProtectionCustomerReport = {
  date: Date;

  receiverName: string;

  mobilePhone: string;

  idType: string;

  idNumber: string;

  numberOfRemittances: number;

  totalOfRemittances: number;
}
