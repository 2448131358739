import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  TicketAssigneeResolver,
  TicketBranchesResolver,
  TicketByIdResolver,
  TicketStatusResolver,
} from './data/support.resolver';
import { SupportService } from './data/support.service';
import { SupportRoutesModule } from './support.routes.module';
import { TicketsComponent } from './tickets.component';
import { TicketDetailsComponent } from './ticketDetails.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {NgSelectModule} from "@ng-select/ng-select";
import {MatMenuModule} from "@angular/material/menu";

@NgModule({
  declarations: [TicketsComponent, TicketDetailsComponent],
  imports: [
    CommonModule,
    SupportRoutesModule,
    ContainersModule,
    TranslateModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatIconModule,
    MatTabsModule,
    MatCheckboxModule,
    NgSelectModule,
    MatMenuModule
  ],
  exports: [],
  providers: [
    SupportService,
    TicketByIdResolver,
    TicketStatusResolver,
    TicketBranchesResolver,
    TicketAssigneeResolver,
  ],
})
export class SupportModule {}
