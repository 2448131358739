import { Component, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective } from '@angular/forms';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.less'],
})
export class ValidationErrorComponent {
  private static readonly errorMessages: any = {
    required: () => 'This field is required',
    minlength: (params: { requiredLength: string }) =>
      'The min number of characters is ' + params.requiredLength,
    maxlength: (params: { requiredLength: string }) =>
      'The max allowed number of characters is ' + params.requiredLength,
    pattern: () => 'Invalid characters',
    uniqueEmail: (params: { message: string }) => params.message,
    notEquivalentPassword: () => 'Password and Confirm Password did not match',
    notEquivalentConfirmMobileNumber: () =>
      'Mobile Number and Confirm Mobile Number did not match',
    notEquivalentAccountNo: () =>
      'Account No and re-entered Account No did not match',
    email: () => 'Invalid email',
    dateOfBirth: (params: { message: string }) => params.message,
    expiryGreaterThanIssue: () => 'Expiry Date should greater than issue date',
    invalidMobileLength: () =>
      'Mobile no should have min of 10 digits and max of 13 digits',
    attachments: (params: { message: string }) => params.message,
    duplicateDocTypes: () =>
      'Primary document and secondary document cannot be same.',
    matDatepickerParse: () => 'Invalid date',
    matDatepickerMin: () => 'Invalid date.Minimum date crossed',
    matDatepickerMax: () => 'Invalid date.Maximum date crossed',
    invalidPwdPattern: (params: { message: string }) => params.message,
    noUppercase: (params: { message: string }) => params.message,
    noLowercase: (params: { message: string }) => params.message,
    noNumberCase: (params: { message: string }) => params.message,
    emailRegexMsg: (params: { message: string }) => params.message,
    POBoxRegexMsg: (params: { message: string }) => params.message,
  };

  @Input()
  control: AbstractControlDirective | AbstractControl | undefined;

  shouldShowErrors(): boolean | undefined | null {
    return (
      this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched)
    );
  }

  listOfErrors(): string[] {
    return Object.keys(this.control?.errors!).map((field) =>
      this.getMessage(field, this.control?.errors![field]),
    );
  }

  private getMessage(type: string, params: any) {
    if (ValidationErrorComponent.errorMessages[type]) {
      return ValidationErrorComponent.errorMessages[type](params);
    } else {
      return 'Invalid input';
    }
  }
}
