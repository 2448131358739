<div mat-dialog-content>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation" *ngIf="authService.isUserAuthorized(permissions.VIEW_CUSTOMER) && !isSenderPartnerSelected">
          <button class="nav-link active" id="document-tab" data-bs-toggle="tab" data-bs-target="#documents" type="button" role="tab" aria-controls="documents" aria-selected="true">{{ 'customerDocuments' | translate }}</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="authService.isUserAuthorized(permissions.VIEW_CUSTOMER)">
          <button class="nav-link" id="modification-tab" data-bs-toggle="tab" data-bs-target="#modifications" type="button" role="tab" aria-controls="modifications" aria-selected="false">{{ 'Customer Modifications' | translate }}</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="data.customerTypeId == customerTypes.Corporate && authService.isUserAuthorized(permissions.VIEW_CUSTOMER)">
          <button class="nav-link" id="conductor-tab" data-bs-toggle="tab" data-bs-target="#conductors" type="button" role="tab" aria-controls="conductors" aria-selected="false">{{ 'conductors' | translate }}</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="authService.isUserAuthorized(permissions.VIEW_CUSTOMER)">
          <button class="nav-link" id="mandate-tab" data-bs-toggle="tab" data-bs-target="#mandate" type="button" role="tab" aria-controls="mandate" aria-selected="true"> {{ 'Onboarding mandate' | translate }}</button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="authService.isUserAuthorized(permissions.VIEW_CUSTOMER)">
          <button class="nav-link" id="mandate-tab" data-bs-toggle="tab" data-bs-target="#declaration" type="button" role="tab" aria-controls="declaration" aria-selected="true"> {{ 'Transaction declaration' | translate }}</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active mt-4" id="documents" role="tabpanel" aria-labelledby="document-tab">
          <app-customerdocs></app-customerdocs> 
        </div>
        <div class="tab-pane fade" id="modifications" role="tabpanel" aria-labelledby="modification-tab">
          <app-customermodifications></app-customermodifications> 
        </div>
        <div class="tab-pane fade" id="conductors" role="tabpanel" aria-labelledby="conductor-tab">
           <app-customerconductors></app-customerconductors>    
        </div>
        <div class="tab-pane fade" id="mandate" role="tabpanel" aria-labelledby="mandate-tab">
          <app-signup-mandate></app-signup-mandate>    
       </div>
       <div class="tab-pane fade" id="declaration" role="tabpanel" aria-labelledby="declaration-tab">
        <app-declaration></app-declaration>    
     </div>
      </div>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button mat-dialog-close="close">{{ 'close' | translate
        }}</button>
</div>
