import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import {
  BranchesService,
  GetManualJournalsSearchFilter,
} from './data/branches.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  BranchLimitApprovalRequestDto,
  SearchBranchesDto,
  GetBranchesDto,
  SearchManualJournalDto,
} from './data/dto/branches.dto';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { ManualJournalReceiptPopupComponent } from './manualJournalReceiptPopup.component';
import { TranslateService } from '@ngx-translate/core';
import { ManualJournalDocPopupComponent } from './manualJournalDocPopup.component';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackBarService } from '../_common/snackBar.service';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-branches-manual-journals',
  templateUrl: './manualJournals.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class ManualJournalsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  isPdfFile: boolean = false;
  permissions = Permissions.branchManagement.manualJournals;
  resultsLength = 0;
  loading = false;
  displayedColumns: string[] = [
    'id',
    'comment',
    'status',
    'date',
    'username',
    'amount',
    'actions',
  ];
  data = new MatTableDataSource<SearchBranchesDto>();
  branchLimitApproval: BranchLimitApprovalRequestDto[] = [];
  isRegional: boolean | undefined;
  isAdmin: boolean | undefined;
  branchesList: GetBranchesDto[] = [];
  branches: GetBranchesDto[] = [];
  statuses: LookupDto[] = [];
  journals = new MatTableDataSource<SearchManualJournalDto>();
  constructor(
    private fb: UntypedFormBuilder,
    private router: ActivatedRoute,
    private branchesService: BranchesService,
    public authService: AuthService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.router.data.subscribe(({ branches, statuses }) => {
      this.statuses = statuses;
      this.branchesList = branches;
      this.branches = branches.map(function (obj: GetBranchesDto) {
        return { id: obj.id, code: obj.code, title: obj.title };
      });
    });

    const userBranchId = this.authService.currentUserValue?.branch.id;

    this.form = this.fb.group({
      branchId: [
        this.branches.find((branch) => branch.id === userBranchId)?.id,
      ],
      manualJournalId: [null],
      statusId: [null],
    });
    this.branchesService.getJournals.subscribe(({ appliedFilters, data }) => {
      this.journals.data = data;
      this.journals.paginator = this.paginator;
      this.resultsLength = data.length;
    });
    this.isAdmin = this.authService.currentUserValue?.isAdmin;
    this.isRegional = this.authService.currentUserValue?.isRegional;
  }

  onSubmit() {
    if (this.form.valid) {
      const { branchId, manualJournalId, statusId } = this.form.value;
      const filters: GetManualJournalsSearchFilter = {};
      filters.branchId = branchId ?? 0;
      filters.manualJournalId = manualJournalId ?? 0;
      filters.statusId = statusId ?? 0;
      this.branchesService.searchManualJournals(filters);
    }
  }

  receipt(id: any) {
    const dialogRef = this.dialog.open(ManualJournalReceiptPopupComponent, {
      data: this.journals.data.find(
        (journal) => journal.manualJournalId === id,
      ),
      width: '850px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      this.translateService.use(localStorage.getItem('lang') || 'en');
    });
  }

  openDocsDialog(journalId: number) {
      const dialogRef = this.dialog.open(ManualJournalDocPopupComponent, {
        width: '1200px',
        disableClose: true,
        data: '',
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (!resp) return;
        if(resp.docFile){
          console.log(typeof(resp.docFile))
          this.branchesService.addJournalDocument(journalId, resp.docFile)
          .subscribe((resp)=>{
            this.snackBar.open(resp.message);
              this.onSubmit();

          },
          ({ message }) => {
            this.snackBar.open(message);
          },
          )

        }
      })
  }

  viewAttachments(manualJournalId: number) {
    this.branchesService
      .getManualJournalDocDetails(manualJournalId)
      .subscribe((rem) => {
        const documentPath = rem.documentPaths;
        const cleanPath = documentPath[Object.keys(documentPath)[0]];

        this.branchesService.getManualJournalAttachment(cleanPath).subscribe(
          (blob) => {
            if (blob) {
              this.isPdfFile = false;
              if (cleanPath.indexOf('.pdf') > -1) {
                blob = new Blob([blob], { type: 'application/pdf' });
                this.isPdfFile = true;
              }
              const objectURL =  this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(blob),
              )
              this.dialog.open(AttachmentsPopupComponent, {
                restoreFocus: true,
                width: '800px',
                data: { imageURL: objectURL, isPdfFile: this.isPdfFile },
              });
            }
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
      });
  }
  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }
}
