<app-form-container label="{{ title | translate }}">
  <form
    #customerForm
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <mat-card>
      <mat-card-title *ngIf="customerTypeId != customerType.Corporate">{{ 'personalInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row" *ngIf="customerTypeId != customerType.Corporate">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'firstName' | translate }}:</mat-label>
                  <input
                    matInput
                    #senderFirstName
                    formControlName="firstName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('firstName'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'middleName' | translate }}:</mat-label>
                  <input
                    matInput
                    #senderMiddleName
                    formControlName="middleName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('middleName'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'lastName' | translate }}:</mat-label>
                  <input matInput #senderLastName formControlName="lastName" />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('lastName'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row" *ngIf="customerTypeId != customerType.Corporate">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'mobile' | translate }}:</mat-label>
                  <input
                    matInput
                    #senderMobilePhone
                    formControlName="mobilePhone"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('mobilePhone'))"
                ></app-validation-error>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label class="mb-1"
                    >{{ 'gender' | translate }}:</mat-label
                  >
                  <mat-select formControlName="gender">
                    <mat-option
                      *ngFor="let gender of customerGenders"
                      [value]="gender"
                      >{{ gender.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="customerTypeId != customerType.Corporate">
                <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                  <mat-label>{{ 'dob' | translate }}</mat-label>
                  <input
                    matInput
                    [max]="maxDOB"
                    [matDatepicker]="dob"
                    formControlName="dateOfBirth"
                  />
                  <mat-hint>DD-MM-YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dob"
                  ></mat-datepicker-toggle>
                  <mat-datepicker startView="month" #dob></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                  <mat-label>{{ 'placeOfBirth' | translate }}:</mat-label>
                  <input
                    matInput
                    #placeOfBirth
                    formControlName="placeOfBirth"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('placeOfBirth'))"
                ></app-validation-error>
                <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                  <mat-label>{{ 'occupation' | translate }}:</mat-label>
                  <mat-select formControlName="occupation">
                    <mat-option
                      *ngFor="let occupation of customerOccupations"
                      [value]="occupation"
                      >{{ occupation.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                  <mat-label>{{ 'sourceOfIncome' | translate }}</mat-label>
                  <mat-select formControlName="sourceOfIncome">
                    <mat-option
                      *ngFor="let source of customerSourceOfIncome"
                      [value]="source"
                      >{{ source.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="row" *ngIf="customerTypeId != customerType.Corporate">
              <p class="h6">{{ 'addressInformation' | translate }}</p>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'country' | translate }}</mat-label>
                <mat-select
                  formControlName="country"
                  (selectionChange)="onCountryChange($event.value.id)"
                >
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country"
                    >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'city' | translate }}:</mat-label>
                <mat-select
                  formControlName="city"
                  (selectionChange)="onCityChange($event.value.id)"
                >
                  <mat-option *ngFor="let city of cities" [value]="city">{{
                    city.name
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'locality' | translate }}</mat-label>
                <mat-select formControlName="locality">
                  <mat-option
                    *ngFor="let locality of localities"
                    [value]="locality"
                    >{{ locality.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row" *ngIf="customerTypeId != customerType.Corporate">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'sublocality' | translate }}</mat-label>
                  <input matInput #sublocality formControlName="sublocality" />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('sublocality'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'street' | translate }}:</mat-label>
                  <input matInput #street formControlName="street" />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('street'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
                  <input
                    matInput
                    #buildingNumber
                    formControlName="buildingNumber"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('buildingNumber'))"
                ></app-validation-error>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'houseNumber' | translate }}:</mat-label>
                  <input matInput #houseNumber formControlName="houseNumber" />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('houseNumber'))"
                ></app-validation-error>
              </div>
            </div>

            <div class="row mt-2 mb-3" *ngIf="!addMode && customerTypeId == customerType.Corporate">
              <p class="h6">{{ 'companyInformation' | translate }}</p>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'companyTitle' | translate }}</mat-label>
                  <input matInput #companyTitle formControlName="companyTitle" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyTitle'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'taxIdentificationNumber' | translate }}</mat-label>
                  <input matInput #taxIdentificationNumber formControlName="taxIdentificationNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('taxIdentificationNumber'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'tradeRegistryNumber' | translate }}</mat-label>
                  <input matInput #tradeRegistryNumber formControlName="tradeRegistryNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('tradeRegistryNumber'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'fieldOfActivity' | translate }}</mat-label>
                  <input matInput #fieldOfActivity formControlName="fieldOfActivity" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('fieldOfActivity'))"></app-validation-error>
              </div>

              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'mobile' | translate }}:</mat-label>
                  <input matInput #companyMobilePhone formControlName="companyMobilePhone" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyMobilePhone'))"></app-validation-error>
              </div>
              <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>{{ 'country' | translate }}</mat-label>
                <mat-select formControlName="companyCountry" (selectionChange)="onCountryChange($event.value.id)">
                  <mat-option
                    *ngFor="let country of countries"
                    [value]="country"
                  >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                <mat-label>{{ 'city' | translate }}:</mat-label>
                <mat-select formControlName="companyCity" (selectionChange)="onCityChange($event.value.id)">
                  <mat-option *ngFor="let city of cities" [value]="city">{{
                    city.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'street' | translate }}:</mat-label>
                  <input matInput #companyStreet formControlName="companyStreet" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyStreet'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
                  <input matInput #companyBuildingNumber formControlName="companyBuildingNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyBuildingNumber'))"></app-validation-error>
              </div>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'companyArea' | translate }}:</mat-label>
                <input matInput #companyArea formControlName="companyArea" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('companyArea'))"></app-validation-error>
            </div>
            <div class="row" *ngIf="!addMode">
              <div class="col-6">
                <mat-checkbox
                  matInput
                  class="mb-3"
                  color="primary"
                  formControlName="isActive"
                >
                  {{ 'active' | translate }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-end my-3">
      <button
        *ngIf="addMode"
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/customers"
      >
        {{ 'back' | translate }}
      </button>
      <button
        *ngIf="
          authService.isUserAuthorized([
            permissions.CREATE_CUSTOMER,
            permissions.UPDATE_CUSTOMER
          ])
        "
        mat-raised-button
        type="submit"
        color="primary"
      >
        {{ title | translate }}
      </button>
    </div>
    <!--[disabled]="!form.valid"-->
  </form>
</app-form-container>
