import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { RemittanceReportsComponent } from './remittanceReports.component';
import { MonthlyCommissionsComponent } from './monthlyCommission.component';
import { BranchesResolver } from '../user/data/users.resolver';
import { CommissionStatusesResolver } from './data/reports.resolver';
import { TrialBalanceReportComponent } from './trialBalance.component';
import { BranchStatementReportComponent } from './branchStatement.component';
import { BeneficiaryBranchesResolver } from '../branch/data/branches.resolver';
import { SettlementStatusResolver } from '../settlement/data/settlement.resolver';
import { SettlementReportComponent } from './settlementReport.component';
import { AccountActivityReportsComponent } from './accountActivityReports.component';
import { UnpaidRemittanceReportComponent } from './unpaidRemittanceReport.component';
import { ComplianceReportComponent } from './complianceReport.component';
import {PartnerSettlementReportComponent} from "./partnerSettlementReport.component";
import {CustomerReportComponent} from "./customerReport.component";
import { FinsurvReconciliationReportComponent } from './finsurvreconciliationreport.component';
import { CustomerRiskRatingReportComponent } from './customer-risk-rating-report.component';
import { KiteworkReportComponent } from './kiteworkReport.component';

const {
  reportManagement: 
  { 
    REMITANCE_ROPORT, ACCOUNT_ACTIVITY_REPORT, MONTHLY_COMMISSIONS_REPORT, TRIAL_BALANCE_REPORT,
    SETTLEMENT_REPORT, UNPAID_REMITTANCE_REPORT, COMPLIANCE_REPORT, CUSTOMER_REPORT, BRANCH_STATEMENT_REPORT,
    PARTNER_SETTLEMENT_REPORT, FINSURV_RECONCILIATION_REPORT, CUSTOMER_RISK_RATING_REPORT, KITEWORK_TRANSACTIONS
  },
} = Permissions;

const routes: Routes = [
  {
    path: 'reports',
    component: OutletComponent,
    children: [
      {
        path: 'view',
        component: OutletComponent,
        children: [
          {
            path: 'remittanceReports',
            canActivate: [AuthGuard],
            data: { permissions: [REMITANCE_ROPORT.VIEW_REMITTANCE_REPORT] },

            component: RemittanceReportsComponent,
          },
          {
            path: 'accountActivityReports',
            canActivate: [AuthGuard],
            data: { permissions: [ACCOUNT_ACTIVITY_REPORT.VIEW_ACCOUNT_ACTIVITY_REPORT] },
            component: AccountActivityReportsComponent,
          },
          {
            path: 'monthlyCommissions',
            canActivate: [AuthGuard],
            data: { permissions: [MONTHLY_COMMISSIONS_REPORT.VIEW_MONTHLY_COMMISSIONS_REPORT] },
            resolve: {
              branches: BranchesResolver,
              statuses: CommissionStatusesResolver,
            },
            component: MonthlyCommissionsComponent,
          },
          {
            path: 'trialbalance',
            canActivate: [AuthGuard],
            data: { permissions: [TRIAL_BALANCE_REPORT.VIEW_TRIAL_BALANCE_REPORT] },
            resolve: { branches: BranchesResolver },
            component: TrialBalanceReportComponent,
          },
          {
            path: 'branchstatement',
            canActivate: [AuthGuard],
            data: { permissions: [BRANCH_STATEMENT_REPORT.VIEW_BRANCH_STATEMENT_REPORT] },
            resolve: { branches: BranchesResolver },
            component: BranchStatementReportComponent,
          },
          {
            path: 'settlementreport',
            canActivate: [AuthGuard],
            data: { permissions: [SETTLEMENT_REPORT.VIEW_SETTLEMENT_REPORT] },
            resolve: {
              settlers: BranchesResolver,
              beneficiaries: BeneficiaryBranchesResolver,
              statuses: SettlementStatusResolver,
            },
            component: SettlementReportComponent,
          },
          {
            path: 'unpaidremittancereport',
            canActivate: [AuthGuard],
            data: { permissions: [UNPAID_REMITTANCE_REPORT.VIEW_UNPAID_REMITTANCE_REPORT] },
            resolve: { branches: BranchesResolver },
            component: UnpaidRemittanceReportComponent,
          },
          {
            path: 'compliancereport',
            canActivate: [AuthGuard],
            data: { permissions: [COMPLIANCE_REPORT.VIEW_COMPLIANCE_REPORT] },
            resolve: { branches: BranchesResolver },
            component: ComplianceReportComponent,
          },
          {
            path: 'partnersettlementreport',
            canActivate: [AuthGuard],
            data: { permissions: [PARTNER_SETTLEMENT_REPORT.VIEW_PARTNER_SETTLEMENT_REPORT] },
            component: PartnerSettlementReportComponent,
          },
          {
            path: 'customerreport',
            canActivate: [AuthGuard],
            data: { permissions: [CUSTOMER_REPORT.VIEW_CUSTOMER_REPORT] },
            component: CustomerReportComponent,
          },
          {
            path: 'finsurvreconciliationreport',
            canActivate: [AuthGuard],
            data: { permissions: [FINSURV_RECONCILIATION_REPORT.VIEW_FINSURV_RECONCILIATION_REPORT] },
            component: FinsurvReconciliationReportComponent,
          },
          {
            path: 'customerriskratingreport',
            canActivate: [AuthGuard],
            data: { permissions: [CUSTOMER_RISK_RATING_REPORT.VIEW_CUSTOMER_RISK_RATING_REPORT] },
            component: CustomerRiskRatingReportComponent,
          },
          {
            path: 'kiteworktransactions',
            canActivate: [AuthGuard],
            data: { permissions: [KITEWORK_TRANSACTIONS.VIEW_KITEWORK_TRANSACTIONS] },
            component: KiteworkReportComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportRoutesModule {}
