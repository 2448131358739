import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { AuthService } from '../../auth/data/auth.service';
import { CommissionConfigurationService } from '../data/commissionConfigurations.service';
import { RemittanceCommissionConfigurationsDto } from '../data/dto/commissionConfigurations.dto';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-remit-commission-configs',
  templateUrl: './commissionConfigs.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class CommissionConfigsComponent implements OnInit {
  form!: UntypedFormGroup;

  permissions = Permissions.remittanceManagement.commissionConfiguration;
  displayedColumns: string[] = [
    '#',
    'senderCountry',
    'receiverCountry',
    'remittanceType',
    'remittanceSubType',
    'minAmount',
    'maxAmount',
    'commission',
    'activeStatus',
    'actions',
  ];
  remitCommConfigs =
    new MatTableDataSource<RemittanceCommissionConfigurationsDto>();

  loading = false;
  resultsLength = 0;

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private fb: UntypedFormBuilder,
    private commConfigsService: CommissionConfigurationService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      isActive: [null],
    });

    this.commConfigsService.commissionConfigs.subscribe(
      ({ commissionConfigs, appliedFilters }) => {
        this.loading = false;
        this.remitCommConfigs.data = commissionConfigs;
        this.remitCommConfigs.paginator = this.paginator;
        this.resultsLength = commissionConfigs.length;
        this.form.controls['isActive'].setValue(appliedFilters?.isActive);
      },
    );
  }

  onSubmit() {
    /*    const q = this.form.value.remittanceCode;
    const filters = q && q.trim() ? {q: q.trim()} : null;*/
    const formValue = this.form.value.isActive;
    const isActive =
      formValue === 'active'
        ? true
        : formValue === 'inactive'
        ? false
        : undefined;
    this.commConfigsService.triggerGetCommissionConfigs({ isActive: isActive });
  }
}
