import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { OutletComponent } from '../_common/components/outlet.component';
import { RolesComponent } from './role/roles.component';
import { RoleDetailsComponent } from './role/roleDetails.component';
import { UsersComponent } from './users.component';
import { UserDetailsComponent } from './userDetails.component';
import { UserRoutesModule } from './user.routes.module';
import { RolesService } from './data/roles.service';
import { UsersService } from './data/users.service';
import { UserTypeResolver } from './data/userTypes.resolver';
import { PermissionsResolver } from './data/permissions.resolver';
import { RoleByIdResolver, RolesResolver } from './data/roles.resolver';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { MatRadioModule } from '@angular/material/radio';
import {
  BranchesResolver,
  LoginHistoryStatusesResolver,
  OtpTypesResolver,
  UserByIdResolver,
} from './data/users.resolver';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { UserProfileComponent } from './userProfile.component';
import { ResetPasswordPopupComponent } from './resetPasswordPopup.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { AuthModule } from '../auth/auth.module';
import { LogsComponent } from './log/logs.component';
import { LogsService } from './data/logs.service';
import { ValidationErrorModule } from '../_common/components/validation-error/validation-error.module';
import { LogDetailPopupComponent } from './log/logDetailPopup.component';
import { CheckGooglePopupComponent } from './checkGoogleOtpPopup.component';
import { RoleRequestsComponent } from './role/roleRequests.component';
import { RoleRequestApprovalPopupComponent } from './role/roleRequestApprovalPopup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginHistoryComponent } from './loginHistory/loginHistory.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserRequestApprovalPopupComponent } from './userRequestApprovalPopup.component';
import { UserRequestsComponent } from './userRequests.component';

@NgModule({
  declarations: [
    OutletComponent,
    RolesComponent,
    RoleDetailsComponent,
    UsersComponent,
    UserDetailsComponent,
    UserProfileComponent,
    ResetPasswordPopupComponent,
    LogsComponent,
    LogDetailPopupComponent,
    CheckGooglePopupComponent,
    RoleRequestsComponent,
    RoleRequestApprovalPopupComponent,
    LoginHistoryComponent,
    UserRequestApprovalPopupComponent,
    UserRequestsComponent,
  ],
  imports: [
    CommonModule,
    UserRoutesModule,

    ContainersModule,
    AuthModule,

    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatExpansionModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSliderModule,
    ValidationErrorModule,
    MatTooltipModule,
    MatDatepickerModule,
  ],
  exports: [],
  providers: [
    RolesService,
    UsersService,
    LogsService,
    UserTypeResolver,
    PermissionsResolver,
    RolesResolver,
    RoleByIdResolver,
    UserByIdResolver,
    BranchesResolver,
    OtpTypesResolver,
    LoginHistoryStatusesResolver,
  ],
})
export class UserModule {}
