<app-index-container label="{{ 'Customer Risk Rating Report' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row">
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" [value]="fromDate.value" />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'endDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" [value]="toDate.value" />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="d-flex justify-content-end">
            <button mat-raised-button type="submit" color="primary">
                <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
            </button>
        </div>
    </form>
    <table table mat-table [dataSource]="remittances" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="#">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        <!-- date Column -->
        <!-- Partner Remittance ID Column -->
        <ng-container matColumnDef="branchCode">
            <th mat-header-cell *matHeaderCellDef>{{ 'Branch Code' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.branchCode }}</td>
        </ng-container>

        <ng-container matColumnDef="lowRiskRatingCustomers">
            <th mat-header-cell *matHeaderCellDef>{{ 'Low Risk Rating Customers' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.lowRiskRatingCustomers > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                    (click)="row.lowRiskRatingCustomers > 0 ? viewCustomerRiskRatingDetails('Low') : null">
                    {{ row.lowRiskRatingCustomers }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="mediumRiskRatingCustomers">
            <th mat-header-cell *matHeaderCellDef> {{ 'Medium Risk Rating Customers' | translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.mediumRiskRatingCustomers > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                    (click)="row.mediumRiskRatingCustomers > 0 ? viewCustomerRiskRatingDetails('Medium') : null">
                    {{ row.mediumRiskRatingCustomers }}
                </span>
            </td>
        </ng-container>

        <ng-container matColumnDef="highRiskRatingCustomers">
            <th mat-header-cell *matHeaderCellDef> {{ 'High Risk Rating Customers' | translate}} </th>
            <td mat-cell *matCellDef="let row">
                <span [ngStyle]="row.highRiskRatingCustomers > 0 ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
                    (click)="row.highRiskRatingCustomers > 0 ? viewCustomerRiskRatingDetails('High') : null">
                    {{ row.highRiskRatingCustomers }}
                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                {{ 'noRecordsAvailable' | translate }}
            </td>
        </tr>
    </table>
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>