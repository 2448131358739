<app-form-container label="{{ title | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <mat-card>
      <mat-card-title>{{ 'addSettlement' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label class="mb-1"
                  >{{ 'settlementType' | translate }}:</mat-label
                >
                <mat-select formControlName="type">
                  <mat-option
                    *ngFor="let type of settlementTypes"
                    [value]="type"
                    >{{ type.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label class="mb-1">{{ 'settler' | translate }}:</mat-label>
                <mat-select
                  formControlName="settler"
                  (selectionChange)="onSettlerBranchChange($event.value.id)"
                >
                  <mat-option
                    *ngFor="let settler of settlers"
                    [value]="settler"
                  >
                    {{
                      settler.code === 'TKCPO'
                        ? ('hqBranch' | translate)
                        : settler.code + ' - ' + settler.title
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label class="mb-1">{{ 'account' | translate }}:</mat-label>
                <mat-select
                  formControlName="account"
                  (selectionChange)="onAccountChange($event.value.id)"
                >
                  <mat-option
                    *ngFor="let account of accounts"
                    [value]="account"
                    >{{ account.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label class="mb-1"
                  >{{ 'beneficiary' | translate }}:</mat-label
                >
                <mat-select formControlName="beneficiary">
                  <mat-option
                    *ngFor="let beneficiary of beneficiaries"
                    [value]="beneficiary"
                  >
                    {{
                      beneficiary.code === 'TKCPO'
                        ? ('hqBranch' | translate)
                        : beneficiary.code + ' - ' + beneficiary.title
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field class="col" appearance="outline">
                <mat-label>{{ 'description' | translate }}</mat-label>
                <textarea
                  matInput
                  #description
                  formControlName="description"
                  placeholder="{{ 'description' | translate }}"
                ></textarea>
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('description'))"
              ></app-validation-error>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label
                  >{{ 'amount' | translate
                  }}{{ ' ' + accountCurrency }}</mat-label
                >
                <input
                  matInput
                  #settlerGlAmount
                  formControlName="settlerGlAmount"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'exchangeRate' | translate }}</mat-label>
                <input
                  matInput
                  #settlerExchangeRate
                  formControlName="settlerExchangeRate"
                  readonly
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'amountUsd' | translate }}</mat-label>
                <input matInput #amount formControlName="amount" readonly />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label
                  >{{ 'branchCurrency' | translate
                  }}{{ ' ' + branchCurrency }}</mat-label
                >
                <input
                  matInput
                  #settlerBaseCurrencyAmount
                  formControlName="settlerBaseCurrencyAmount"
                  readonly
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label
                  >{{ 'turkishExchangeRate' | translate
                  }}</mat-label
                >
                <input
                  matInput
                  #turkishExchangeRate
                  formControlName="turkishExchangeRate"
                />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('amount'))"
              ></app-validation-error>
              <app-validation-error
                [control]="$any(form.get('exchangeRate'))"
              ></app-validation-error>
              <app-validation-error
                [control]="$any(form.get('usdAmount'))"
              ></app-validation-error>
              <app-validation-error
                [control]="$any(form.get('branchCurrencyAmount'))"
              ></app-validation-error>
              <app-validation-error
                [control]="$any(form.get('turkishExchangeRate'))"
              ></app-validation-error>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-end my-3">
      <button
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/settlements"
      >
        {{ 'back' | translate }}
      </button>
      <button
        *ngIf="authService.isUserAuthorized([permissions.CREATE_SETTLEMENT])"
        mat-raised-button
        type="submit"
        color="primary"
      >
        {{ 'addSettlement' | translate }}
      </button>
    </div>
  </form>
</app-form-container>
