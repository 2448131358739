<app-index-container label="{{ 'notifications' | translate }}">
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
        <input matInput #remittanceCode formControlName="remittanceCode" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label class="mb-1">{{ 'status' | translate }}:</mat-label>
        <mat-select formControlName="statusId">
          <mat-option value="">{{ 'all' | translate }}</mat-option>
          <mat-option
            *ngFor="let notificationStatus of notificationStatus"
            [value]="notificationStatus.id"
          >
            {{ notificationStatus.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <button mat-raised-button color="primary" (click)="search()">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Detail Column -->
    <ng-container matColumnDef="detail">
      <th mat-header-cell *matHeaderCellDef>{{ 'detail' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.detail }}</td>
    </ng-container>
    <!-- Remittance Code Column -->
    <ng-container matColumnDef="remittanceCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'remittanceCode' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.remittanceCode }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="lastAttemptMessage">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'lastAttemptMessage' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.lastAttemptMessage }}</td>
    </ng-container>
    <!-- City Column -->
    <ng-container matColumnDef="retryCount">
      <th mat-header-cell *matHeaderCellDef>{{ 'retryCount' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.retryCount }}</td>
    </ng-container>
    <!-- Country Column -->
    <ng-container matColumnDef="lastRetryDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'lastRetryDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.lastRetryDate) }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([permissions.RETRY_NOTIFICATION])
            "
            color="primary"
            (click)="onRetryNotificationClick(row.id)"
          >
            <mat-icon>update</mat-icon>
            {{ 'retry' | translate }}
          </a>
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([permissions.RETRY_NOTIFICATION])
            "
            color="primary"
            (click)="onViewHistoryClick(row.remittanceId)"
          >
            <mat-icon>update</mat-icon>
            {{ 'history' | translate }}
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
