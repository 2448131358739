import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { ReportsService } from './data/reports.service';
import { BranchesService } from '../branch/data/branches.service';
import { SnackBarService } from '../_common/snackBar.service';
import { RemittanceReportFilterDto } from './data/dto/reports.dto';
import { RemittanceReportPopupComponent } from './remittanceReportPopup.component';
import { RemittanceStatus } from '../_common/enums/reports.enums';

@Component({
  selector: 'app-remittance-report',
  templateUrl: './remittanceReports.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class RemittanceReportsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: FormGroup;
  direction?: string;
  isPaid: boolean = false;
  isUnpaid: boolean = false;
  isReturned: boolean = false;
  remittances!: LookupDto[];
  allBranches: any = [];
  allCurrencies: any = [];
  range = new FormGroup({
    start: new FormControl(new Date()),
    end: new FormControl(new Date()),
  });
  isAdmin? = false;
  constructor(
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService,
    private reportsService: ReportsService,
    private snackBar: SnackBarService,
    private authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.direction = 'incoming';
    this.isAdmin = this.authService.currentUserValue?.isAdmin;
    this.branchesService.getBranches().subscribe((b) => {
      b.forEach((d: any) => {
        this.allCurrencies = d.branchCurrencies;
        this.allBranches.push(d);
      });
    });

    this.form = this.fb.group({
      parentBranch: ['', null],
      branch: ['', null],
      paid: [this.isPaid],
      unpaid: [this.isUnpaid],
      returned: [this.isReturned],
      currency: ['', null],
      sender: ['', null],
      receiver: ['', null],
    });
  }

  onStatusSelected(status: string) {
    if (status === RemittanceStatus.PAID) {
      this.form.get('unpaid')?.setValue(false);
      this.form.get('returned')?.setValue(false);
      this.isUnpaid = false;
    } else if (
      status === RemittanceStatus.UNPAID &&
      this.form.get('unpaid')?.value
    ) {
      this.form.get('paid')?.setValue(false);
      this.form.get('returned')?.setValue(false);
      this.isUnpaid = true;
    } else {
      this.form.get('unpaid')?.setValue(false);
      this.form.get('paid')?.setValue(false);
      this.isUnpaid = false;
    }
  }

  onSubmit() {
    const {
      parentBranch,
      branch,
      paid,
      unpaid,
      returned,
      currency,
      sender,
      receiver,
    } = this.form.value;

    const { start, end } = this.range.value;
    const filters: RemittanceReportFilterDto = {
      direction: this.direction as string,
    };

    if (this.direction === 'outgoing') {
      if (parentBranch) filters.senderParentBranch = parentBranch;
      if (branch) filters.senderBranch = branch;
    } else if (this.direction === 'incoming') {
      if (parentBranch) filters.receiverParentBranch = parentBranch;
      if (branch) filters.receiverBranch = branch;
    }

    if (currency) filters.currencyId = currency;
    if (sender && isNaN(parseInt(sender))) {
      filters.senderName = sender;
    } else if (sender) {
      filters.senderMobile = sender;
    }
    if (receiver && isNaN(parseInt(receiver))) {
      filters.receiverName = receiver;
    } else if (receiver) {
      filters.receiverMobile = receiver;
    }
    if (paid) filters.status = RemittanceStatus.PAID;
    if (unpaid) filters.status = RemittanceStatus.UNPAID;
    if (returned) filters.status = RemittanceStatus.RETURNED;
    if (start) filters.startDate = start;
    if (end) filters.endDate = end;
    if (Object.keys(filters).length !== 0) {
      this.reportsService.searchRemittanceReport(filters).subscribe((rem) => {
        const dialogRef = this.dialog.open(RemittanceReportPopupComponent, {
          disableClose: true,
          width: '1000px',
          height: '80%',
          data: rem,
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            return;
          }
        });
      },({message}) =>{
         this.snackBar.open(message);
      });
    } else {
      this.snackBar.open('No filter selected');
    }
  }
}
