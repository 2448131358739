import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation, MatStepper } from '@angular/material/stepper';
import { CdkStepper } from '@angular/cdk/stepper';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Permissions } from '../_common/enums/permissions.enums';
import { BranchesService } from './data/branches.service';
import { LookupDto } from '../_common/data/dto/api.dto';
import {
  BranchConfiguration,
  BranchCurrencyDto,
  GetBranchesDto,
  GetLimitAndCommConfigsDto,
} from './data/dto/branches.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AuthService } from '../auth/data/auth.service';
import { BranchCurrencyPopupComponent } from './branchCurrencyPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { regExp } from '../_common/data/validationRules';
import { CustomValidators } from '../_common/data/customValidators';
import { CountriesDto } from '../remittance/data/dto/countries.dto';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branchDetails.component.html',
  providers: [{ provide: CdkStepper }],
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class BranchDetailsComponent implements OnInit {
  branchInfoForm!: UntypedFormGroup;
  commissionConfigsForm!: UntypedFormGroup;
  branchConfigsForm!: UntypedFormGroup;
  branchLimitForm!: UntypedFormGroup;

  permissions = Permissions.branchManagement.branches;
  //@ts-ignore
  @ViewChild('branchResetBtn', { read: ElementRef }) branchResetBtn: ElementRef;
  //@ts-ignore
  @ViewChild('commissionResetBtn', {read: ElementRef}) commissionResetBtn: ElementRef;
  //@ts-ignore
  @ViewChild('configsResetBtn', { read: ElementRef }) configsResetBtn: ElementRef;
  //@ts-ignore
  @ViewChild('branchesBtn', { read: ElementRef }) branchesBtn: ElementRef;
  //@ts-ignore
  @ViewChild('stepper') stepper: MatStepper;

  branchTypes: LookupDto[] = [];
  branchCities: LookupDto[] = [];
  branchCommissionPolicies: LookupDto[] = [];
  countries: CountriesDto[] = [];
  branchCurrenciesLookup: LookupDto[] = [];
  branchParents: GetBranchesDto[] = [];
  parentBranchLimitAndCommConfigs?: GetLimitAndCommConfigsDto = undefined;

  branch?: GetBranchesDto;
  overallLimit = 0;
  newOverallLimit = 0;
  usersOwnBranch = false;
  addMode = true;
  label = '';
  isRegional: boolean | undefined;
  canActivate: boolean | undefined;
  countryIso2: string = '';

  stepperOrientation: Observable<StepperOrientation>;

  branchConfigurations: BranchConfiguration[] = [
    {
      configurationType: 'commissionChangeApproval',
      configuration: {
        branchApproval: false,
        parentBranchApproval: false,
        cpoBranchApproval: false,
      },
    },
    {
      configurationType: 'workingHours',
      configuration: {
        openingHour: null,
        closingHour: null,
      },
    },
    {
      configurationType: 'language',
      configuration: {
        branchLanguage: null,
      },
    },
  ];

  branchLanguage!: string;

  branchCurrencies: BranchCurrencyDto[] = [];
  branchCurrenciesColumns: string[] = [
    'currency',
    'exchangeRateType',
    'rateVariation',
    'minRate',
    'normRate',
    'maxRate',
    'active',
    'actions',
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private branchesService: BranchesService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
    public dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 768px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  // not being used now since the client side validation is applied
  // validateLimit = (control: AbstractControl) => {
  //   let isValid = null;
  //   let responseMessage = '';
  //   //this.branchLimitForm.valid changes as user types, therefore can not be checked.
  //   if (this.branchLimitForm && control.value != '' && control.value != null) {
  //     this.branchesService
  //       .validateBranchLimit({
  //         id: this.addMode ? 0 : this.branch?.id!,
  //         limit: control.value,
  //         parentId: this.branchInfoForm.get('parentId')?.value,
  //       })
  //       .subscribe(
  //         () => {
  //           isValid = true;
  //           this.branchLimitForm.controls['limit'].setErrors(null);
  //         },
  //         ({ message }) => {
  //           isValid = false;
  //           responseMessage = message;
  //           this.snackBar.open(message);
  //         },
  //       );
  //     if (!isValid) {
  //       return { responseMessage };
  //     }
  //   }
  //   return null;
  // };

  ngOnInit() {
    this.route.data.subscribe(
      ({
        branchTypes,
        countries,
        branchParents,
        branches,
        addMode,
        branchCommissionPolicies,
      }) => {
        this.addMode = addMode;
        this.branchTypes = branchTypes;
        this.countries = countries;
        this.countries = this.countries.filter(
          (country) => country.iso2 === 'ZA',
        );
        this.branchParents = branchParents;
        this.branchCommissionPolicies = branchCommissionPolicies;
        if (branches && branches.length) {
          this.branch = branches[0];
          this.parentBranchLimitAndCommConfigs =
            this.branch?.parentLimitAndCommConfigs;
          this.overallLimit =
            (this.branch?.limit || 0) + (this.branch?.childrenLimit || 0);

          const userBranchId = this.authService.currentUserValue?.branch?.id;
          if (userBranchId)
            this.usersOwnBranch = this.branch?.id === userBranchId;
        }

        this.isRegional = this.branch?.isRegional;

        this.canActivate = this.authService.currentUserValue?.isAdmin
          && this.authService.currentUserValue?.id !== this.branch?.createdBy;

        this.branchInfoForm = this.fb.group({
          code: [
            this.branch?.code,
            [
              Validators.required,
              Validators.minLength(4),
              Validators.maxLength(8),
              //Validators.pattern(regExp.capAlphaNumeric),
            ],
          ],
          typeId: [this.branch?.typeId, [Validators.required]],
          title: [
            this.branch?.title,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(200),
              Validators.pattern(regExp.alphaNumericSpace),
            ],
          ],
          cityId: [this.branch?.cityId, [Validators.required]],
          countryId: [this.branch?.countryId, [Validators.required]],
          baseCurrencyId: [this.branch?.baseCurrencyId, [Validators.required]],
          phone: [
            this.branch?.phone,
            [Validators.required, Validators.pattern(regExp.mobile)],
          ],
          branchIp: [this.branch?.branchIp, Validators.pattern(regExp.ip)],
          parentId: [this.branch?.parentId, [Validators.required]],
          branchStatus: [this.branch?.branchStatus],
        });

        if (!addMode) {
          this.branchInfoForm
            .get('code')
            ?.setValidators([Validators.minLength(6), Validators.maxLength(8)]);
          this.branchInfoForm.updateValueAndValidity();
        }

        this.branchLimitForm = this.fb.group({
          limit: new UntypedFormControl(this.branch?.limit, {
            validators: [
              Validators.required,
              Validators.pattern(regExp.decimalNumber),
              // this.validateLimit,
            ],
            // updateOn: 'blur',
          }),
        });
        this.branchLimitForm.get('limit')?.updateValueAndValidity();

        this.commissionConfigsForm = this.fb.group({
          commissionPolicyId: [
            this.branch?.commissionPolicyId,
            [Validators.required],
          ],
          sendCashCommission: [
            this.branch?.sendCashCommission,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          payCashCommission: [
            this.branch?.payCashCommission,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
        });

        const branchConfigs = this.branch?.branchConfigurations || [];
        this.branchConfigsForm = this.fb.group(
          this.branchConfigurations.reduce((controls, config) => {
            const { configuration } =
              branchConfigs.find(
                (bc) => bc.configurationType === config.configurationType,
              ) || {};
            if (config.configurationType === 'commissionChangeApproval') {
              controls['commissionChangeBranchApproval'] = [
                configuration?.branchApproval,
              ];
              controls['commissionChangeParentApproval'] = [
                configuration?.parentBranchApproval,
              ];
              controls['commissionChangeCpoApproval'] = [
                configuration?.cpoBranchApproval,
              ];
            } else if (config.configurationType === 'workingHours') {
              controls['openingHours'] = [configuration?.openingHours];
              controls['closingHours'] = [configuration?.closingHours];
            } else if (config.configurationType === 'language') {
              controls['branchLang'] = [configuration?.branchLanguage];
            }

            return controls;
          }, {} as { [key: string]: any }),
          {
            validators: CustomValidators.conditionalRequired(
              'openingHours',
              'closingHours',
            ),
          },
        );

        this.branchCurrencies =
          this.branch?.branchCurrencies.sort((a, b) =>
            a.currency.localeCompare(b.currency),
          ) || [];

        if (this.branch?.countryId) {
          this.onCountryChange(this.branch.countryId);
        }

        if (this.branch?.parentId) {
          this.onParentChange(this.branch.parentId);
        }

        if (this.branch) this.setLimitAndCommValidators();

        if (this.addMode) {
          this.label = 'addBranch';
        } else if (
          this.authService.isUserAuthorized(this.permissions.UPDATE_BRANCH)
        ) {
          this.label = 'updateBranch';
        } else {
          this.label = 'viewBranch';
          this.branchInfoForm.disable();
        }

        this.branchLimitForm.controls['limit'].valueChanges.subscribe(
          (value) => {
            this.newOverallLimit = +value + (this.branch?.childrenLimit || 0);
          },
        );
      },
    );
    this.branchLanguage = this.getBranchConfigsData().find(
      (bc) => bc.configurationType === 'language',
    )?.configuration.branchLanguage as string;
  }

  onCountryChange(countryId: number) {
    this.branchesService.branchCities(countryId).subscribe((branchCities) => {
      this.branchCities = branchCities;
    } , ({ message }) => {
      this.snackBar.open(message);
    });
    this.countryIso2 = this.countries.find(
      (country) => country.id === countryId,
    )?.iso2 as string;
  }

  onParentChange(branchId: number) {
    forkJoin([
      this.branchesService
        .branchCurrenciesLookup(branchId)
        .pipe(catchError((error) => of(error))),
      this.branchesService
        .getBranchLimitAndCommConfigs(branchId)
        .pipe(catchError((error) => of(error))),
    ]).subscribe(
      ([branchCurrenciesLookup, parentBranchLimitAndCommConfigs]: [
        Error | LookupDto[],
        Error | GetLimitAndCommConfigsDto,
      ]) => {
        if (
          branchCurrenciesLookup instanceof Error ||
          parentBranchLimitAndCommConfigs instanceof Error
        ) {
          this.branchInfoForm.get('parentId')?.setValue(null, {
            emitEvent: false,
          });
          this.snackBar.open('failedToGetParentBranchData');
          return;
        }

        this.branchCurrenciesLookup = branchCurrenciesLookup;
        this.parentBranchLimitAndCommConfigs = parentBranchLimitAndCommConfigs;
        this.setLimitAndCommValidators();
      },
    );
  }

  private setLimitAndCommValidators() {
    this.branchLimitForm
      .get('limit')
      ?.addValidators(
        Validators.max(
          (this.parentBranchLimitAndCommConfigs?.limit || 0) +
            (this.branch?.limit || 0),
        ),
      );

    this.commissionConfigsForm
      .get('sendCashCommission')
      ?.addValidators(
        Validators.max(
          this.parentBranchLimitAndCommConfigs?.sendCashCommission || 0,
        ),
      );

    this.commissionConfigsForm
      .get('payCashCommission')
      ?.addValidators(
        Validators.max(
          this.parentBranchLimitAndCommConfigs?.payCashCommission || 0,
        ),
      );
  }

  private getBranchConfigsData() {
    const { value: branchConfigsData } = this.branchConfigsForm || {};
    return [
      {
        configurationType: 'commissionChangeApproval',
        configuration: {
          branchApproval:
            branchConfigsData.commissionChangeBranchApproval || false,
          parentBranchApproval:
            branchConfigsData.commissionChangeParentApproval || false,
          cpoBranchApproval:
            branchConfigsData.commissionChangeCpoApproval || false,
        },
      },
      {
        configurationType: 'workingHours',
        configuration: {
          openingHours: branchConfigsData.openingHours || null,
          closingHours: branchConfigsData.closingHours || null,
        },
      },
      {
        configurationType: 'language',
        configuration: {
          branchLanguage: branchConfigsData.branchLang || null,
        },
      },
    ];
  }

  onBranchCurrencyClick(branchCurrencyId?: string) {
    forkJoin([
      this.branchesService.currencies(),
      this.branchesService.exchangeRateTypes(),
    ]).subscribe(([currencies, exchangeRateTypes]) => {
      this.dialog
        .open(BranchCurrencyPopupComponent, {
          width: '450px',
          data: {
            currencies,
            exchangeRateTypes,
            branchId: this.branch?.id,
            branchCurrency: branchCurrencyId
              ? this.branchCurrencies.find((bc) => bc.id === branchCurrencyId)
              : null,
          },
        })
        .afterClosed()
        .subscribe((resp) => {
          if (resp === 'success') {
            this.branchesService
              .branchById(this.branch?.id as number)
              .subscribe(([branch]) => {
                this.branch = branch;
                this.branchCurrencies =
                  this.branch?.branchCurrencies.sort((a, b) =>
                    a.currency.localeCompare(b.currency),
                  ) || [];
              });
          }
        });
    });
  }

  onSubmit(formType: string) {
    const { value: branchInfoData } = this.branchInfoForm || {};
    const { value: branchLimitData } = this.branchLimitForm || {};
    const { value: commissionConfigsData } = this.commissionConfigsForm || {};

    if (this.addMode && this.countryIso2) {
      if (this.branchInfoForm.valid) {
        if (formType === 'branchInfo')
          branchInfoData.code = `${branchInfoData.code}`;
        if (!this.validateLimit(branchLimitData)) return;
        if (!this.validateCommissionConfigs(commissionConfigsData)) return;

        const branchData = {
          ...branchInfoData,
          ...branchLimitData,
          ...commissionConfigsData,
        };
        if (formType === 'branchConfigs') {
          const formData = {
            ...branchData,
            branchConfiguration: this.getBranchConfigsData(),
          };
          this.branchesService.createBranch(formData).subscribe(
            (resp) => {
              this.snackBar.open(resp?.message);
              this.branchResetBtn?.nativeElement.click();
              this.commissionResetBtn?.nativeElement.click();
              this.configsResetBtn?.nativeElement.click();
              this.branchesBtn?.nativeElement.click();
              this.stepper.selectedIndex = 0;
            },
            ({ message }) => {
              this.snackBar.open(message);
            },
          );
        }
      }
      return;
    }

    if (this.branch && formType === 'branchInfo' && this.branchInfoForm.valid) {
      this.apiHandler(
        this.branchesService.updateBranch({
          id: this.branch.id,
          ...branchInfoData,
        }),
        'branchUpdatedSuccess',
      );
      return;
    }

    if (this.branch && formType === 'setLimit' && this.branchLimitForm.valid) {
      this.apiHandler(
        this.branchesService.addBranchLimitApprovalRequest({
          id: this.branch.id,
          ...branchLimitData,
        }),
        'limitUpdateRequestedSuccess',
      );
      return;
    }

    if (
      this.branch &&
      formType === 'commissionConfigs' &&
      this.commissionConfigsForm.valid
    ) {
      this.apiHandler(
        this.branchesService.addBranchCommissionApproval({
          id: this.branch.id,
          ...commissionConfigsData,
        }),
        'commissionUpdateRequestedSuccess',
      );
      return;
    }

    if (
      this.branch &&
      formType === 'branchConfigs' &&
      this.branchConfigsForm.valid
    ) {
      this.apiHandler(
        this.branchesService.updateBranchConfigs({
          branchId: this.branch.id,
          branchConfiguration: this.getBranchConfigsData(),
        }),
        'branchConfigsUpdatedSuccess',
      );
    }
  }

  getParentBranchLimitAndCommConfigs() {
    if (!this.parentBranchLimitAndCommConfigs) {
      this.snackBar.open('parentBranchLimitAndCommissionConfigsMissing');
      return false;
    }

    return this.parentBranchLimitAndCommConfigs;
  }

  validateLimit(branchLimitData: { limit: any }): boolean {
    const parentBranchLimitAndCommConfigs =
      this.getParentBranchLimitAndCommConfigs();

    if (!parentBranchLimitAndCommConfigs) return false;

    if (branchLimitData.limit >= parentBranchLimitAndCommConfigs.limit) {
      this.snackBar.open('branchLimitCannotBeMoreThanItsParent');
      return false;
    }

    return true;
  }

  validateCommissionConfigs(commissionConfigsData: {
    sendCashCommission: number;
    payCashCommission: number;
  }) {
    const parentBranchLimitAndCommConfigs =
      this.getParentBranchLimitAndCommConfigs();

    if (!parentBranchLimitAndCommConfigs) return false;

    if (
      commissionConfigsData.sendCashCommission >
      parentBranchLimitAndCommConfigs.sendCashCommission
    ) {
      this.snackBar.open(
        'BranchSendCommissionCannotBeMoreThanItsParentSendCommission',
      );
      return false;
    }

    if (
      commissionConfigsData.payCashCommission >
      parentBranchLimitAndCommConfigs.payCashCommission
    ) {
      this.snackBar.open(
        'BranchPayCommissionCannotBeMoreThanItsParentPayCommission',
      );
      return false;
    }

    return true;
  }

  apiHandler(handler: Observable<any>, successMsg: string) {
    handler.subscribe(
      () => {
        this.snackBar.open(successMsg);
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }
}
