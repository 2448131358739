import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BranchesService } from '../branch/data/branches.service';
import { ReportsService } from './data/reports.service';
import { AccountReportFilter } from './data/dto/reports.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AccountActivityReportPopupComponent } from './accountActivityReportPopup.component';
@Component({
  selector: 'app-account-activity-report',
  templateUrl: './accountActivityReports.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class AccountActivityReportsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  branches: any[] = [];
  accounts: any[] = [];
  accountDetails: any[] = [];
  accountTransactions: any[] = [];
  startDate = new UntypedFormControl(new Date());
  endDate = new UntypedFormControl(new Date());
  showInAccCurrencyCb = false;
  accountCurrency = '';

  constructor(
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService,
    private reportsService: ReportsService,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.branchesService.getBranches().subscribe((b) => {
      b.forEach((branch) => {
        this.branches.push(branch);
      });
    });
    this.form = this.fb.group({
      branchId: [null],
      accountId: [null, [Validators.required]],
      startDate: this.startDate,
      endDate: this.endDate,
      showInAccCurrency: [true],
    });
  }

  onBranchChange(branchId: number) {
    this.accounts = [];
    this.reportsService.getBranchAccounts(branchId).subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  onAccountChange(accountId: number) {
    const account = this.accounts.find((a) => a.id === accountId);
    this.accountCurrency = account.currencyCode;
    this.showInAccCurrencyCb = this.accountCurrency.toLowerCase() !== 'usd';
    if (this.showInAccCurrencyCb) {
      this.form.get('showInAccCurrency')?.setValue(true);
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }

    const { branchId, accountId, startDate, endDate, showInAccCurrency } =
      this.form.value;
    const filters: AccountReportFilter = {
      showInAccCurrency,
      accountId,
    };
    if (startDate) filters.startDate = startDate;
    if (endDate) filters.endDate = endDate;

    this.accountDetails.length = 0;
    this.accountTransactions.length = 0;
    let dialogData;
    this.reportsService
      .getAccountTransactions(filters)
      .subscribe((transactions) => {
        const account = this.accounts.find((acc) => acc.id == accountId);
        const branch = this.branches.find((b) => b.id == branchId);
        this.accountDetails.push({
          branchCode: branch.code,
          accountName: account.name,
          branchTitle: branch.title,
          currencyCode: showInAccCurrency ? account.currencyCode : 'USD',
          tel: branch.phone,
          requestedDate:
            startDate && endDate
              ? new Date(startDate).toLocaleDateString() +
                ' - ' +
                new Date(endDate).toLocaleDateString()
              : '-',
        });
        dialogData = {
          accountDetails: this.accountDetails,
          accountTransactions: transactions,
        };
        const dialogRef = this.dialog.open(
          AccountActivityReportPopupComponent,
          {
            disableClose: true,
            width: '1000px',
            height: '80%',
            data: dialogData,
          },
        );
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            return;
          }
        });
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }
}
