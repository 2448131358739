import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-transfereceipt',
  templateUrl: './transfereceipt.component.html',
})
export class TransfereceiptComponent {
  constructor(
    public dialogRef: MatDialogRef<TransfereceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { content: string },
    private sanitizer: DomSanitizer
  ) {
  }

  get sanitizedContent(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.data.content);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  printContent() {
    const printWindow = window.open('', '_blank');
    if (printWindow) {
      printWindow.document.write(this.data.content);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    } else {
      console.error('Failed to open print window');
    }
  }
}
