<h1 mat-dialog-title>{{ 'accountActivityReport' | translate }}</h1>
<div mat-dialog-content id="accountActivity">
  <div class="py-2 overflow-auto">
    <div class="row" style="text-align: center">
      <h2>{{ 'accountActivities' | translate }}</h2>
    </div>
    &nbsp;
    <div *ngFor="let details of data.accountDetails">
      <div class="row d-flex align-items-center">
        <span class="col">
          {{ 'branchCode' | translate }}: {{ details.branchCode }}
        </span>
        <span class="col">
          {{ 'branchTitle' | translate }}: {{ details.branchTitle }}
        </span>
        <span class="col">
          {{ 'accountName' | translate }}: {{ details.accountName }}
        </span>
      </div>
      &nbsp;
      <div class="row d-flex align-items-center">
        <span class="col">
          {{ 'currency' | translate }}: {{ details.currencyCode }}
        </span>
        <span class="col"> {{ 'mobile' | translate }}: {{ details.tel }} </span>
        <span class="col">
          {{ 'requestedDate' | translate }}: {{ convertDateToLocaleString(details.requestedDate) }}
        </span>
      </div>
    </div>
    &nbsp;

    <!-- from here -->
    <div class="row" style="margin: 10px">
      <table
        matTableExporter
        #exporter="matTableExporter"
        matSort
        (matSortChange)="sortData($any($event))"
        id="accountsData"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody>
          <tr>
            <th mat-sort-header="date">{{ 'date' | translate }}</th>
            <th mat-sort-header="transactionId">
              {{ 'transactionId' | translate }}
            </th>
            <th>{{ 'description' | translate }}</th>
            <th>{{ 'debit' | translate }}</th>
            <th>{{ 'credit' | translate }}</th>
            <th>{{ 'balance' | translate }}</th>
          </tr>
          <tr *ngFor="let accountInfo of sortedData">
            <td>{{ convertDateToLocaleString(accountInfo.createdDate) }}</td>
            <td>{{ accountInfo.transactionId }}</td>
            <td>{{ accountInfo.description }}</td>
            <td>
              {{ accountInfo.debit === '0.00000' ? 0.0000 :  accountInfo.debit | number:'1.2-2' }}
            </td>
            <td>
              {{ accountInfo.credit === '0.00000' ? 0.0000 :  accountInfo.credit | number:'1.2-2' }}
            </td>
            <td>{{ accountInfo.balance | number:'1.2-2' }}</td>
          </tr>
          <tr>
            <td colspan="3">
              <b>{{ 'total' | translate }}</b>
            </td>
            <td>
              <b>{{ debitSum | number:'1.2-2' }}</b>
            </td>
            <td>
              <b>{{ creditSum | number:'1.2-2' }}</b>
            </td>
            <td>
              <b>{{ balanceSum | number:'1.2-2' }}</b>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="!data.accountTransactions.length" class="row">
      <p align="center">{{ 'noRecordsAvailable' | translate }}.</p>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <div *ngIf="data.accountTransactions">
    <button mat-button (click)="exportToExcel()">{{'export' | translate}}</button>
  </div>
  <button mat-button (click)="print()">{{ 'print' | translate }}</button>
</div>
