import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerFilter, CustomerService } from './data/customer.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { GetCustomerBlacklistDto, GetCustomerDto } from './data/dto/customer.dto';
import { MatDialog } from '@angular/material/dialog';
import { CustomerDocPopupComponent } from './customerDocPopup.component';
import { SnackBarService } from '../_common/snackBar.service';
import {conductorsPopupComponent} from "./conductorsPopup.component";
import {CustomerTypes} from "../_common/enums/systemBranches.enums";
import { AddCustomerBlacklistComponent } from './addBlacklist.component';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-customers',
  templateUrl: './blacklist.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class BlacklistComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.customerManagement.blackList;
  loading = false;
  resultsLength = 0;
  isSenderPartnerSelected: Boolean = false;
  displayedColumns: string[] = [
    'name',
    'mobile',
    'blocked',
    'approvalRequired',
    'requestedBy',
    'modifiedBy',
    'reasons',
    'comments',
    'createdDate',
    'modifiedDate',
    'actions',
  ];
  customerTypes = CustomerTypes
  data = new MatTableDataSource<GetCustomerBlacklistDto>();
  customerStatusId : any = null;
  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    public authService: AuthService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      mobilePhone: [null],
      customerName: [null],
    });

    this.loadData();
  }

  loadData() {
    this.customerService.customersBlacklist.subscribe(
      ({ customersBlacklist, appliedFilters }) => {
        this.loading = false;
        this.data.data = customersBlacklist;
        this.data.paginator = this.paginator;
        this.resultsLength = customersBlacklist.length;
        this.form.controls['mobilePhone'].setValue(appliedFilters?.mobilePhone);
      },
    );
    
  }

  onSubmit() {
    const { mobilePhone, customerName } = this.form.value;
    const filter: CustomerFilter = {};
    if (mobilePhone) filter.mobilePhone = mobilePhone;
    if (customerName) filter.customerName = customerName;
    this.customerService.searchCustomersBlacklist(filter);
  }

  addBlacklist(){
    this.dialog.open(AddCustomerBlacklistComponent, {
      width: '1200px',
      disableClose: false,
    });
  }

  updateBlacklistCustomer(customerId: number){
    this.dialog.open(AddCustomerBlacklistComponent, {
      width: '1200px',
      disableClose: false,
      data: { customerId: customerId },
    });
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
}
}
