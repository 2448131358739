import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ComplianceConfigurationService } from '../data/complianceConfiguration.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-search-sanctions',
  templateUrl: './search-sanctions.component.html',
  styleUrls: ['./search-sanctions.component.less'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class SearchSanctionsComponent implements OnInit {

   // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  resultsLength = 0;
  expandedElement: any;
  loading = false;
  displayedColumns: string[] = [
    '#',
    'number',
    'title',
    'fullName',
    'akaFullName',
    'source',
    'score'
  ];
  sanctions = new MatTableDataSource<any>();
  constructor(
    private fb: UntypedFormBuilder,
    private complianceConfigService: ComplianceConfigurationService,
  ) { }
  ngOnInit(): void {
    this.form = this.fb.group({
        filter: [null, [Validators.required]],
        strength: [null, [Validators.required]],
        filterType: [1]
      });
  }
  onSubmit(){
    const filters:any = {};
    if(this.form.value.filter)
      filters.filter = this.form.value.filter;
    if(this.form.value.strength)
       filters.strength = this.form.value.strength; 
    if(this.form.value.filterType)
      filters.filterType = this.form.value.filterType;

      this.complianceConfigService.searchSanctions(filters).subscribe(response => {
        this.sanctions.data = response;
        this.resultsLength = response.length;
        this.sanctions.paginator = this.paginator;
        this.updatePaginator();
    });   
  }

  toggleRow(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  getIndex(row: any): number {
    return this.sanctions.data.indexOf(row);
  }

    updatePaginator() {
    this.sanctions = new MatTableDataSource(this.sanctions.data);
    this.sanctions.paginator = this.paginator;
  }

}
