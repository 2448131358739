import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { LookupsRoutesModule } from './lookups.routes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RemittancePurposesComponent } from './remittancePurposes/remittancePurposes.component';
import { LookupsService } from './data/lookups.service';
import { RemittancePurposesPopupComponent } from './remittancePurposes/remittancePurposesPopup.component';
import { RemittanceRelationshipsComponent } from './remittanceRelationships/remittanceRelationships.component';
import { RemittanceRelationshipsPopupComponent } from './remittanceRelationships/remittanceRelationshipsPopup.component';
import { OccupationsComponent } from './occupations/occupations.component';
import { OccupationsPopupComponent } from './occupations/occupationsPopup.component';
import { SourceOfFundsComponent } from './sourceOfFunds/sourceOfFunds.component';
import { SourceOfFundsPopupComponent } from './sourceOfFunds/sourceOfFundsPopup.component';
import { CitiesComponent } from './cities/cities.component';
import { CitiesPopupComponent } from './cities/citiesPopup.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalitiesComponent } from './localities/localities.component';
import { LocalityPopupComponent } from './localities/localityPopup.component';
import { CustomerDocumentTypesComponent } from './customerDocumentTypes/customerDocumentTypes.component';
import { CustomerDocumentTypesPopupComponent } from './customerDocumentTypes/customerDocumentTypesPopup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValidationErrorModule } from '../_common/components/validation-error/validation-error.module';

@NgModule({
  declarations: [
    RemittancePurposesComponent,
    RemittancePurposesPopupComponent,
    RemittanceRelationshipsComponent,
    RemittanceRelationshipsPopupComponent,
    OccupationsComponent,
    OccupationsPopupComponent,
    SourceOfFundsComponent,
    SourceOfFundsPopupComponent,
    CitiesComponent,
    CitiesPopupComponent,
    LocalitiesComponent,
    LocalityPopupComponent,
    CustomerDocumentTypesComponent,
    CustomerDocumentTypesPopupComponent,
  ],
  imports: [
    CommonModule,
    LookupsRoutesModule,
    ContainersModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
    NgSelectModule,
    MatTooltipModule,
    ValidationErrorModule,
  ],
  exports: [],
  providers: [LookupsService],
})
export class LookupsModule {}
