<!-- date filter -->
<mat-card class="date-filter-card">
  <div class="date-filters">
    <div class="date-inputs">
      <!-- Branch Picker -->
      <mat-form-field appearance="outline" class="branch-picker">
        <mat-label>Select Branch</mat-label>
        <mat-select [(ngModel)]="selectedBranch" placeholder="Choose a branch">
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{ branch.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Start Date Picker -->
      <mat-form-field appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startPicker" placeholder="Choose a start date" [(ngModel)]="startDate">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>

      <!-- End Date Picker -->
      <mat-form-field appearance="outline">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endPicker" placeholder="Choose an end date" [(ngModel)]="endDate">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" (click)="onSubmit()" class="search-button">Search</button>
  </div>
</mat-card>

<!-- New code for display counts as card start -->
 
<mat-card class="table-card">
  <mat-card-title>Customer</mat-card-title>
  <div class="row cust-count my-2">
    <div class="col-md-3" *ngFor="let custCount of customerDataCount">
      <mat-card>
        <mat-card-title class="count-number-clickable" *ngIf="custCount.statusId !== null && custCount.count > 0 && isOrmDashboard"
          (click)="onCustomerCountClick(custCount.statusId, custCount.count)">
          {{custCount!.count}}
        </mat-card-title>
        <mat-card-title class="count-number" *ngIf="custCount.statusId !== null && custCount.count > 0 && !isOrmDashboard">
          {{custCount!.count}}
        </mat-card-title>
        <mat-card-title class="count-number" *ngIf="custCount.statusId === null || custCount.count === 0">
          {{custCount!.count}}
        </mat-card-title>
        <button class="float-end text-muted" mat-icon-button (click)="onCustomerRefreshClick(custCount)">
          <mat-icon>refresh</mat-icon>
        </button>
        <span class="float-end" *ngIf="custCount.isLoading">
          <mat-spinner diameter="20"></mat-spinner>
        </span>
        <p>{{custCount.status}}</p>

      </mat-card>

    </div>
  </div>
</mat-card>


<mat-card class="table-card">
  <mat-card-title>Remittances</mat-card-title>
  <div class="row remit-count my-2">
    <div class="col-md-3 mb-3" *ngFor="let remitCount of remittanceDataCount">
      <mat-card>
        <mat-card-title class="count-number-clickable" *ngIf="remitCount.statusId !== null && remitCount.count > 0 && isOrmDashboard"
          (click)="onRemittanceCountClick(remitCount.statusId, remitCount.count)">
          {{remitCount!.count}}
        </mat-card-title>
        <mat-card-title class="count-number" *ngIf="remitCount.statusId !== null && remitCount.count > 0 && !isOrmDashboard">
          {{remitCount!.count}}
        </mat-card-title>
        <mat-card-title class="count-number" *ngIf="remitCount.statusId === null || remitCount.count === 0">
          {{remitCount!.count}}
        </mat-card-title>
        <button class="float-end text-muted" mat-icon-button (click)="onRemittanceRefreshClick(remitCount)">
          <mat-icon>refresh</mat-icon>
        </button>
        <span class="float-end" *ngIf="remitCount.isLoading">
          <mat-spinner diameter="20"></mat-spinner>
        </span>
        <p>{{remitCount.status}}</p>

      </mat-card>

    </div>
  </div>
</mat-card>

<!-- New code for display counts as card ends -->