<app-index-container label="{{ 'branchCommissions' | translate }}">
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <mat-card style="margin-bottom: 20px">
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <ng-select
              formControlName="branch"
              appearance="fill"
              placeholder="{{ 'selectBranch' | translate }}"
              [clearable]="false"
            >
              <ng-option *ngFor="let branch of branches" [value]="branch">
                <div title="{{ branch.title }}">
                  {{ branch.code }} - {{ branch.title }}
                </div>
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-end">
              <button mat-raised-button type="submit" color="primary">
                <mat-icon>search</mat-icon> {{ 'search' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  <form
    form
    class="container-fluid p-0 flex-column"
    name="processForm"
    [formGroup]="processForm"
    novalidate
    (submit)="processCommissions()"
  >
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-end">
              <button
                mat-raised-button
                type="submit"
                *ngIf="
                  authService.isUserAuthorized(
                    permissions.PROCESS_BRANCH_COMMISSIONS
                  )
                "
                color="warn"
              >
                <mat-icon>checklist</mat-icon>
                {{ 'processCommissions' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h2>{{ 'pendingBranchCommissions' | translate }}</h2>
          </div>
          <div class="col-md-12">
            <table mat-table [dataSource]="data">
              <!-- Checkbox Column -->
              <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                  <mat-checkbox
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                  >
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox
                    formControlName="selectedCommission"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Branch Code Column -->
              <ng-container matColumnDef="branchCode">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'branchCode' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.branchCode }}
                </td>
              </ng-container>

              <!-- Description Column -->
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'description' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.description }}
                </td>
              </ng-container>

              <!-- Commission Column -->
              <ng-container matColumnDef="commission">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'commission' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.commission | number:'1.0-5' }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                (click)="selection.toggle(row)"
              ></tr>
            </table>
            <mat-paginator
              paginator
              [pageSizeOptions]="[5, 10, 25, 50]"
              [pageSize]="10"
            ></mat-paginator>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</app-index-container>
