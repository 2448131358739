import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from '../../_common/snackBar.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';
import {CountryISO3s} from "../../_common/enums/reports.enums";
import {regExp} from "../../_common/data/validationRules";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-cities-popup',
  templateUrl: './citiesPopup.component.html',
})
export class CitiesPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  city!: LookupsDto;
  countries!: LookupDto[];
  isThisTurkeyCountry : boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupsService: LookupsService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<CitiesPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      city: LookupsDto[];
      countries: LookupDto[];
      countryId: number;
    },
  ) {
    if (data.countryId) {
      this.city = data.city[0];
      if (this.city) {
        this.addMode = false;
      }
    }
    this.countries = data.countries;
    // @ts-ignore
    this.isThisTurkeyCountry = this.countries.find((country) => country.id === this.data.countryId)?.iso3 == CountryISO3s.TURKEY;
  }

  ngOnInit() {
    this.form = this.fb.group({
      cityName: [this.city?.name, [Validators.required]],
      country: [
        this.countries.find((country) => country.id === this.data.countryId),
        [Validators.required],
      ],
      cityCode: [this.city?.code],
      isActive: [this.city?.isActive || this.addMode],
    });
    if(this.isThisTurkeyCountry){
      this.form.get('cityCode')?.setValidators([Validators.required, Validators.pattern(regExp.numeric)]);
      this.form.get('cityCode')?.updateValueAndValidity();
    }

  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const city: LookupsDto = {
        name: formValues.cityName,
        code: formValues.cityCode,
        parentId: formValues.country.id,
        isActive: formValues.isActive || false,
      };

      const handler = this.city
        ? this.lookupsService.updateCity({
            id: this.city.id,
            ...city,
          })
        : this.lookupsService.createCity(city);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(
            resp?.message,
          );
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onCountrySelected(event: any) {
    // @ts-ignore
    this.isThisTurkeyCountry = this.countries.find((country) => country.id === event.id)?.iso3 == CountryISO3s.TURKEY;
    if(this.isThisTurkeyCountry){
      this.form.get('cityCode')?.setValidators([Validators.required, Validators.pattern(regExp.numeric)]);
      this.form.get('cityCode')?.updateValueAndValidity();
    }
  }
}
