import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpService } from '../../_common/data/http.service';
import { GetCustomerDto, SearchCustomerResponseDto } from 'src/app/customer/data/dto/customer.dto';
import { TableTypes } from 'src/app/_common/enums/tableTypes.enums';
import { RemittancesDto } from 'src/app/remittance/data/dto/remittances.dto';


export type RemittanceFilter = {
  recruiterCode?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  limit?: number;
};

export type CustomerFilter = {
  startDate?: Date | null;
  endDate?: Date | null;
  limit?: number | undefined;
  ormRecuirterCode?: string
}

type GetCustomers = {
  appliedFilters: CustomerFilter | null;
  customers: GetCustomerDto[];
};

type GetRemittances = {
  remittances: RemittancesDto[];
  appliedFilters: RemittanceFilter | null;
};

type GetRemittancesBalanceCommission = {
  remittancesBalanceCommission: RemittancesDto[];
  appliedFilters: RemittanceFilter | null;
};

type getCommission = {
  commission: RemittancesDto[];
  appliedFilters: RemittanceFilter | null;
};


@Injectable()
export class ReferalService {
  remittances: Observable<GetRemittances>;
  remittancesBlanceCommission: Observable<GetRemittancesBalanceCommission>;
  commission: Observable<getCommission>;
  constructor(
    private httpService: HttpService,
  ) {
    this.remittances = this._remittances;
    this.remittancesBlanceCommission = this._remittancesBlanaceCommission
    this.commission = this._commission;
  }

  private tablePendingChanges: string = '';
  private customersObservable?: Observable<GetCustomers>;
  private _customer = new BehaviorSubject<GetCustomers>({
    appliedFilters: null,
    customers: [],
  });
  private _remittances = new BehaviorSubject<GetRemittances>({
    remittances: [],
    appliedFilters: null,
  });

  private _remittancesBlanaceCommission = new BehaviorSubject<GetRemittancesBalanceCommission>({
    remittancesBalanceCommission: [],
    appliedFilters: null,
  });

  private _commission = new BehaviorSubject<getCommission>({
    commission: [],
    appliedFilters: null,
  });


  get customers() {
    if (!this.customersObservable)
      this.customersObservable = this._customer.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.CUSTOMERS) {
            this.getCustomers(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.customersObservable;
  }
  getReferalCustomerCount(startDate: Date, endDate: Date): Observable<any> {
    return this.httpService.get<any>('referals/referalcustomercount', { startDate: startDate, endDate: endDate });
  }

  getReferalRemittanceCount(startDate: Date, endDate: Date): Observable<any> {
    return this.httpService.get<any>('referals/referalremittancecount', { startDate: startDate, endDate: endDate });
  }

  getReferalBlanaceCommision(startDate: Date, endDate: Date): Observable<any> {
    return this.httpService.get<any>('referals/referalbalancecommission', { startDate: startDate, endDate: endDate });
  }

  getReferalCommision(startDate: Date, endDate: Date): Observable<any> {
    return this.httpService.get<any>('referals/referalcommission', { startDate: startDate, endDate: endDate });
  }

  getCustomers(filter: CustomerFilter | null) {
    this.httpService
      .get<SearchCustomerResponseDto[]>('referals/searchreferalcustomers', filter)
      .subscribe((customers) => {
        this.tablePendingChanges = '';
        this._customer.next({
          customers: customers,
          appliedFilters: filter,
        });
      });
  }

  getRemittances(filters: RemittanceFilter | null) {
    this.httpService
      .get<RemittancesDto[]>('referals/searchreferalremittances', filters)
      .subscribe((remittances) => {
        this._remittances.next({
          remittances: remittances,
          appliedFilters: filters,
        });
      });
  }

  getRemittancesBlanaceCommission(filters: RemittanceFilter | null) {
    this.httpService
      .get<RemittancesDto[]>('referals/searchreferalbalancecommission', filters)
      .subscribe((remittances) => {
        this._remittancesBlanaceCommission.next({
          remittancesBalanceCommission: remittances,
          appliedFilters: filters,
        });
      });
  }

  getRemittancesCommission(filters: RemittanceFilter | null) {
    this.httpService
      .get<RemittancesDto[]>('referals/searchreferalcommission', filters)
      .subscribe((remittances) => {
        this._commission.next({
          commission: remittances,
          appliedFilters: filters,
        });
      });
  }
}