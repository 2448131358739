<app-index-container [label]="'incomingReport' | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'startDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="startDate"
          formControlName="startDate"
          [value]="fromDate.value"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #startDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'endDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="endDate"
          formControlName="endDate"
          [value]="toDate.value"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #endDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'currency' | translate }}</mat-label>
        <mat-select formControlName="currencyId">
          <mat-option *ngFor="let currency of currencies" [value]="currency.id">
            {{ currency.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button mat-raised-button type="submit" color="primary">
        <i class="fa-solid fa-magnifying-glass"></i>
        {{ 'search' | translate }}
      </button>
    </div>
  </form>
</app-index-container>
