
<app-index-container label="{{ 'ClientScreening' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSearch()">
        <div class="row mb-0">
           
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'name' | translate }}:</mat-label>
                <input matInput #customerName formControlName="name" />
              </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="d-flex justify-content-end">
                    <!-- import button -->
                    <button (click)="onSubmit()" mat-raised-button type="button" color="primary">
                        <i class="fa-solid fa-file-import"></i>
                        {{ 'Import' | translate }}
                    </button>

                     <!-- Search button -->
                     <button mat-raised-button type="submit" color="primary"  style="margin-left: 10px" >
                        <i class="fa-solid fa-search"></i>
                        {{ 'Search' | translate }}
                    </button>

                </div>
            </div>
        </div>        
    </form>

    <table table mat-table [dataSource]="dataSource" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>{{ 'firstName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
        </ng-container>

        <!-- Comment Column -->
        <ng-container matColumnDef="middleName">
            <th mat-header-cell *matHeaderCellDef>{{ 'middleName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.middleName }}</td>
        </ng-container>

         <!-- Comment Column -->
         <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>{{ 'lastName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
        </ng-container>


          <!-- Comment Column -->
          <ng-container matColumnDef="dateOfBirth">
            <th mat-header-cell *matHeaderCellDef>{{ 'dateOfBirth' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.dateOfBirth | date: 'yyyy-MM-dd' }}</td>
        </ng-container>


        <!-- date Imported Column -->
        <ng-container matColumnDef="dateImported">
            <th mat-header-cell *matHeaderCellDef>
                {{ 'dateImported' | translate }}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ convertDateToLocaleString(row.createdDate,  'datetime')}}
            </td>
        </ng-container>

       <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <div class="action-button-menu">
                    <button 
                    *ngIf="row.isSanctioned === 1" 
                    class="btn btn-outline-info btn-sm" 
                    (click)="viewSanctionedCheck(row.id)">
                    {{ 'Sanction Matched' | translate }}
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>