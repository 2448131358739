import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, catchError, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { RemittanceService } from './remittance.service';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { PayeeBranchesDto } from './dto/payeeBranches.dto';
import {
  RemittanceForPaymentResponse,
  RemittanceForPaymentResponseDto,
} from './dto/remittances.dto';
import { SnackBarService } from 'src/app/_common/snackBar.service';

@Injectable()
export class RemittanceSubTypesResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.remittanceService.getRemittanceSubTypes();
  }
}

@Injectable()
export class TellerAccountsResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.remittanceService.getTellerAccounts();
  }
}

@Injectable()
export class PayeeBranchesResolver implements Resolve<PayeeBranchesDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<PayeeBranchesDto[]> {
    return this.remittanceService.getPayeeBranches();
  }
}

@Injectable()
export class CountriesResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.remittanceService.countries();
  }
}

@Injectable()
export class PayingCountriesResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.remittanceService.payingCountries();
  }
}

@Injectable()
export class PaymentTypesResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.remittanceService.getPaymentTypes();
  }
}

@Injectable()
export class RemittancePurposesResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(): Observable<LookupDto[]> {
    return this.remittanceService.getRemittancePurposes();
  }
}

@Injectable()
export class RemittanceRelationshipsResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(): Observable<LookupDto[]> {
    return this.remittanceService.getRemittanceRelationships();
  }
}

@Injectable()
export class RemittanceStatusesResolver implements Resolve<LookupDto[]> {
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.remittanceService.getStatuses();
  }
}

@Injectable()
export class RemittanceForPaymentResolver
  implements Resolve<RemittanceForPaymentResponse | null>
{
  constructor(private remittanceService: RemittanceService, private snackBar: SnackBarService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RemittanceForPaymentResponse | null> {
    const { remittanceId } = route.params;
    return this.remittanceService.getRemittanceForPayment(remittanceId).pipe(
      catchError((error) => {
        this.snackBar.open(error.message)
        return throwError(() => new Error(error.message))
      })
    );
  }
}

@Injectable()
export class RemittanceForPaybackResolver
  implements Resolve<RemittanceForPaymentResponseDto[]>
{
  constructor(private remittanceService: RemittanceService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RemittanceForPaymentResponseDto[]> {
    const { remittanceId } = route.params;
    return this.remittanceService.getRemittanceForPayback(remittanceId);
  }
}
