<h1 mat-dialog-title>{{ 'partnerSettlementReport' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
    </div>

    <div class="row" style="margin: 10px">
      <table
        id="partnerSettlements"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody *ngIf="!data.length">
          <tr>
            <td class="text-center">{{ 'noRecordsAvailable' | translate }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="data.length">
          <tr>
            <th class="text-nowrap">{{ 'date' | translate }}</th>
            <th class="text-nowrap">{{ 'settlementAmount' | translate }}</th>
            <th class="text-nowrap">{{ 'remainingAmountUSD' | translate }}</th>
            <th class="text-nowrap">{{ 'exchangeRateUSD' | translate }}</th>
            <th class="text-nowrap">{{ 'remainingAmountTRY' | translate }}</th>
          </tr>
          <tr *ngFor="let settlementDetail of data">
            <td class="text-nowrap">{{ settlementDetail.date }}</td>
            <td class="text-nowrap">{{ settlementDetail.settlementAmount| number:'1.2-2' }}</td>
            <td class="text-nowrap">
              {{ settlementDetail.remainingAmountUSD| number:'1.2-2' }}
            </td>
            <td class="text-nowrap">
              {{ settlementDetail.exchangeRateUSD | number:'1.0-5' }}
            </td>
            <td class="text-nowrap">{{ settlementDetail.remainingAmountTRY | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="report"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
