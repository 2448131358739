<h1 mat-dialog-title>{{ 'sendReceipt' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="margin-top: 5px" id="receipt">
    <!-- widget content -->
    <div class="widget-body receipt" id="firstOne">
      <div id="addDenomination">
        <div class="row">
          <div class="col-6">
            <img src="../../../../assets/images/logo/logo.svg" width="250px" /><br />
            <strong class="mt-3">Sundus Elektronik Para ve Ödeme Kuruluşu A.Ş</strong>
          </div>
          <div class="col-6">
            <p class="mb-0">
              <b>{{ 'printedDate' | translate }}:</b>
              {{ convertDateToLocaleString(today) }}
            </p>
            <p>
              Esentepe Mahallesi Büyükdere, Caddesi, No: 171,<br />
              A Metrocity D: 176, 34330 Şişli/İstanbul, Turkey.<br />
              <strong>{{ 'contact' | translate }}</strong>
              <span>&nbsp;&nbsp;+90 (212) 631 42 43</span><br />
              <strong>{{ 'email' | translate }}</strong>
              <span>&nbsp;&nbsp;info@tayopay.com</span><br />
              <strong>Mersis No</strong>
              <span>&nbsp;&nbsp;0815080473300001</span>
            </p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <ul class="list-group">
              <!-- <li class="list-group-item bg-light">
                <strong>{{ 'contact' | translate }}</strong><span class="float-end">+90 (212) 631 42 43</span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'email' | translate }}</strong><span class="float-end">info@tayopay.com</span>
              </li> -->
              <!-- <li class="list-group-item bg-light">
                <h5 class="mb-0">
                  <strong>{{ 'RID' | translate }}</strong>
                </h5>
              </li> -->
            </ul>
            <ul class="list-group">
              <li class="list-group-item" style="background-color: #ecbc53">
                <strong>{{ 'senderInformation' | translate }}</strong>
              </li>
              <li class="list-group-item">
                <strong>{{ 'fullname' | translate }}</strong><span class="float-end">{{
                  getFullName(data.sender.firstName, data.sender.middleName, data.sender.lastName)
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'idType' | translate }}</strong><span class="float-end">{{
                  data.senderPrimaryDoc?.documentType
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'idNumber' | translate }}</strong
                ><span class="float-end">{{
                maskNumber(data.senderPrimaryDoc?.documentDetails?.number.toString())
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'mobile' | translate }}</strong><span class="float-end">{{ maskNumber(data.sender.mobilePhone) }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'country' | translate }}</strong><span class="float-end">{{ data.senderAddressCountry
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'address' | translate }}</strong><span class="float-end">
                {{
                data.sender.customerTypeId == customerType.Person ?(
                  data.senderAddressSublocality +
                  ', ' +
                  data.senderAddressStreet +
                  ' No:' +
                  data.senderAddressHouseNumber +
                  data.senderAddressLocality +
                  '/' +
                  data.senderAddressCity +
                  ' ' +
                  data.senderAddressCountry) : data.senderAddressCity + ' ' + data.senderAddressCountry
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'description' | translate }}</strong>
                <span class="float-end">{{data.senderComments}}</span>
              </li>
            </ul>
            <div class="row">
              <div class="col-12">
                <div class="row mt-2">
                  <div class="col">
                    {{ 'customerSignature' | translate }}
                    <div class="p-4" style="border: 1px solid rgb(221, 221, 221)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item" style="background-color: #ecbc53">
                <strong>{{ 'receiverInformation' | translate }}</strong>
              </li>
              <li class="list-group-item">
                <strong>{{ 'receiptNumber' | translate }}</strong> <strong> <span class="float-end">{{receiptNumber}}</span> </strong>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'RID' | translate }}</strong>
                <span class="float-end"><strong>{{ data.code }}</strong></span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'transactionDate' | translate }}</strong>
                <span class="float-end"><strong>{{ convertDateToLocaleString(data.sendDate) }}</strong></span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'fullname' | translate }}</strong><span class="float-end">{{
                  getFullName(data.receiver.firstName, data.receiver.middleName, data.receiver.lastName)
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'mobile' | translate }}</strong><span class="float-end">{{ maskNumber(data.receiver.mobilePhone)
                  }}</span>
              </li>
            </ul>
            <div
              style="background-color: #f8f9fa;color: #202020;font-size: 13px;font-weight: 500;border: 1px solid #dee2e6;;line-height: 28px;"
              class="p-2 mt-3">
              <div>
                {{ 'remittanceTotal' | translate
                }}<span class="float-end">{{ data.amount | number: '1.2-2' }} {{ data.senderAccountCurrency }}</span>
              </div>
              <div>
                {{ 'commission' | translate
                }}<span class="float-end">{{ data.commission | number: '1.2-2' }} {{ data.senderAccountCurrency
                  }}</span>
              </div>
                <strong style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">{{ 'grandTotal' | translate
                  }}</strong><span class="float-end">
                  <p class="mb-0" style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">
                    {{ sumOfAmountAndCommissoin(data.amount, data.commission) | number: '1.2-2' }} {{
                    data.senderAccountCurrency }}
                  </p>
                </span>
              </div>
              <hr class="my-1" />
              <div style="font-size: 13px;font-weight: 500;">
                {{ 'payingAmount' | translate
                }}<span class="float-end">{{ data.receivingAmount | number: '1.2-2' }} {{ data.receiverCountryCurrency
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-1 row" *ngIf="data.senderAccountCurrency !== 'TRY'">
          <div class="col-6"></div>
          <div class="col-6">
            <strong style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">
              {{ 'exchangeRate' | translate}}
            </strong>
            <strong class="float-end">1 {{data.senderAccountCurrency}} = {{data.liraExchangeRate | number: '1.2-2' }} TRY
            </strong>
          </div>
        </div>
        <div class="py-2">
          <small style="font-size: 0.75em; line-height: 11px">
            <p class="mb-1">{{ 'quotedText1' | translate }}</p>
            <!-- <p>{{ 'quotedText2' | translate }}</p> -->
          </small>
        </div>
        <div class="py-0.5 float-end">
          <p class="mb-1">{{ data.sendingBranch }}</p>
        </div>
      </div>
    </div>

    <div class="widget-body receipt onlyPrintable" id="secondOne" style="padding: 0px 5px">
      <div id="addDenomination" style="padding-top: 10px;">
        <div class="row">
          <div class="col-6">
            <img src="../../../../assets/images/logo/logo.svg" width="250px" /><br />
            <strong class="mt-3">Sundus Elektronik Para ve Ödeme Kuruluşu A.Ş</strong>
          </div>
          <div class="col-6">
            <p class="mb-0">
              <b>{{ 'printedDate' | translate }}:</b>
              {{ convertDateToLocaleString(today) }}
            </p>
            <p>
              Esentepe Mahallesi Büyükdere, Caddesi, No: 171,<br />
              A Metrocity D: 176, 34330 Şişli/İstanbul, Turkey.<br />
              <strong>{{ 'contact' | translate }}</strong>
              <span>&nbsp;&nbsp;+90 (212) 631 42 43</span><br />
              <strong>{{ 'email' | translate }}</strong>
              <span>&nbsp;&nbsp;info@tayopay.com</span><br />
              <strong>Mersis No</strong>
              <span>&nbsp;&nbsp;0815080473300001</span>
            </p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-6">
            <ul class="list-group">
              <!-- <li class="list-group-item bg-light">
                <strong>{{ 'contact' | translate }}</strong><span class="float-end">+90 (212) 631 42 43</span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'email' | translate }}</strong><span class="float-end">info@tayopay.com</span>
              </li> -->
              <!-- <li class="list-group-item bg-light">
                <h5 class="mb-0">
                  <strong>{{ 'RID' | translate }}</strong>
                </h5>
              </li> -->
            </ul>
            <ul class="list-group">
              <li class="list-group-item" style="background-color: #ecbc53">
                <strong>{{ 'senderInformation' | translate }}</strong>
              </li>
              <li class="list-group-item">
                <strong>{{ 'fullname' | translate }}</strong><span class="float-end">{{
                  getFullName(data.sender.firstName, data.sender.middleName, data.sender.lastName)
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'idType' | translate }}</strong><span class="float-end">{{
                  data.senderPrimaryDoc?.documentType
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'idNumber' | translate }}</strong
                ><span class="float-end">{{
                maskNumber(data.senderPrimaryDoc?.documentDetails?.number.toString())
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'mobile' | translate }}</strong><span class="float-end">{{ maskNumber(data.sender.mobilePhone) }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'country' | translate }}</strong><span class="float-end">{{ data.senderAddressCountry
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'address' | translate }}</strong><span class="float-end">{{
                data.sender.customerTypeId == customerType.Person ?(
                  data.senderAddressSublocality +
                  ', ' +
                  data.senderAddressStreet +
                  ' No:' +
                  data.senderAddressHouseNumber +
                  data.senderAddressLocality +
                  '/' +
                  data.senderAddressCity +
                  ' ' +
                  data.senderAddressCountry) : data.senderAddressCity + ' ' + data.senderAddressCountry
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'description' | translate }}</strong>
                <span class="float-end">{{data.senderComments}}</span>
              </li>
            </ul>
            <div class="row">
              <div class="col-12">
                <div class="row mt-2">
                  <div class="col">
                    {{ 'customerSignature' | translate }}
                    <div class="p-4" style="border: 1px solid rgb(221, 221, 221)"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item" style="background-color: #ecbc53">
                <strong>{{ 'receiverInformation' | translate }}</strong>
              </li>
              <li class="list-group-item">
                <strong>{{ 'receiptNumber' | translate }}</strong><span class="float-end">{{receiptNumber}}</span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'RID' | translate }}</strong>
                <span class="float-end"><strong>{{ data.code }}</strong></span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'transactionDate' | translate }}</strong>
                <span class="float-end"><strong>{{ convertDateToLocaleString(data.sendDate) }}</strong></span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'fullname' | translate }}</strong><span class="float-end">{{
                  getFullName(data.receiver.firstName, data.receiver.middleName, data.receiver.lastName)
                  }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'mobile' | translate }}</strong><span class="float-end">{{ maskNumber(data.receiver.mobilePhone)
                  }}</span>
              </li>
            </ul>
            <div
              style="background-color: #f8f9fa;color: #202020;font-size: 13px;font-weight: 500;border: 1px solid #dee2e6;;line-height: 28px;"
              class="p-2 mt-3">
              <div>
                {{ 'remittanceTotal' | translate
                }}<span class="float-end">{{ data.amount | number: '1.2-2' }} {{ data.senderAccountCurrency }}</span>
              </div>
              <div>
                {{ 'commission' | translate
                }}<span class="float-end">{{ data.commission | number: '1.2-2' }} {{ data.senderAccountCurrency
                  }}</span>
              <div>
                <strong style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">{{ 'grandTotal' | translate
                  }}</strong><span class="float-end">
                  <p class="mb-0" style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">
                    {{ sumOfAmountAndCommissoin(data.amount, data.commission) | number: '1.2-2' }} {{
                    data.senderAccountCurrency }}
                  </p>
                </span>
              </div>
              <hr class="my-1" />
              <div style="font-size: 13px;font-weight: 500;">
                {{ 'payingAmount' | translate
                }}<span class="float-end">{{ data.receivingAmount | number: '1.2-2' }} {{ data.receiverCountryCurrency
                  }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-1 row" *ngIf="data.senderAccountCurrency !== 'TRY'">
          <div class="col-6"></div>
          <div class="col-6">
            <strong style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">
              {{ 'exchangeRate' | translate}}
            </strong>
            <strong class="float-end">1 {{data.senderAccountCurrency}} = {{data.liraExchangeRate | number: '1.2-2' }} TRY
            </strong>
          </div>
        </div>
        <div class="py-2">
          <small style="font-size: 0.75em; line-height: 11px">
            <p class="mb-1">{{ 'quotedText1' | translate }}</p>
            <!-- <p>{{ 'quotedText2' | translate }}</p> -->
          </small>
        </div>
        <div class="py-0.5 float-end">
          <p class="mb-1">{{ data.sendingBranch }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-button class="btn btn-outline-primary" [useExistingCss]="true" printSectionId="receipt" ngxPrint>
    {{ 'print' | translate }}
  </button>
</div>
