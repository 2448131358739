 import { Component, ViewChild, OnInit, Inject } from '@angular/core';
 import { MatPaginator } from '@angular/material/paginator';
 import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
 import { MatTableDataSource } from '@angular/material/table';
 import { Permissions } from '../_common/enums/permissions.enums';
 import { AuthService } from '../auth/data/auth.service';
 import { LookupDto } from '../_common/data/dto/api.dto';
 import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import { ReferalService, RemittanceFilter } from './data/referl.service';
import { RemittancesDto } from '../remittance/data/dto/remittances.dto';
import { Utilities } from '../_common/utils';
 @Component({
   selector: 'app-remittances',
   templateUrl: './ormRecruiterCommission.component.html',
   styleUrls: ['../_common/styles/table.less'],
 })
 export class OrmRecruiterCommissionComponent implements OnInit {
   startDate: Date | null = null;
   endDate: Date | null = null;
   permissions = Permissions.remittanceManagement.remittances;
   direction: string = '2';
   displayedColumns: string[] = [
     '#',
     'recruiterName',
     'recruiterCode',
     'remittanceId',
     'commission',
     'dateProcessed',
     'status',
   ];
   remittances = new MatTableDataSource<RemittancesDto>();
   statusId: number | null = null;
   limit: number | undefined;
   remittanceStatuses: LookupDto[] = [];
   branches: GetBranchesDto[] = [];
   allbranches: GetBranchesDto[] = [];
   parentBranches: GetBranchesDto[] = [];
   loading = false;
   resultsLength = 0;
   isAdmin? = false;
   isRegional? = false;
   selected: string = '';
   selectedRemittanceStatus: any = '';
   remittanceFilterTypeId: number = 1;
   recruiterCode: string = '';
   // @ts-ignore
   @ViewChild(MatPaginator) paginator: MatPaginator;
   isPdfFile: boolean = false;
 
   constructor(
     private referalService: ReferalService,
     public authService: AuthService,
     public dialog: MatDialog,
     @Inject(MAT_DIALOG_DATA) public data_: any,
   ) {
     this.statusId = data_?.statusId || null;
     this.startDate = data_?.startDate || null;
     this.endDate = data_?.endDate || null;
     this.limit = data_?.limit || null;
     this.recruiterCode = data_?.recruiterCode || null;
   }
 
   ngOnInit() {
     this.loadData();
   }
 
   async loadData() {
     const filter: RemittanceFilter = {
       startDate: this.startDate ? this.startDate: null, 
       endDate: this.endDate ? this.endDate: null, 
       limit: this.limit,
       recruiterCode: this.recruiterCode
     };
 
     this.referalService.getRemittancesCommission(filter);
   
     this.referalService.commission.subscribe(
       ({ commission, appliedFilters }) => {
         this.loading = false;
         this.remittances.data = commission;
         this.remittances.paginator = this.paginator;
         this.resultsLength = commission.length;
       }
     );
   }

   convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

 }
 