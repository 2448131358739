import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { AuthService } from '../../auth/data/auth.service';
import { ComplianceConfigurationService } from '../data/complianceConfiguration.service';
import { ActivatedRoute } from '@angular/router';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { MatTableDataSource } from '@angular/material/table';
import {SnackBarService} from "../../_common/snackBar.service";

type Compliance = {
  id: number;
  complianceType: string;
  config: string;
};

@Component({
  selector: 'app-compliance-configuration',
  templateUrl: './complianceConfigurations.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class ComplianceConfigurationsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  complianceTypes: LookupDto[] = [];

  permissions = Permissions.complianceManagement.complianceConfigurations;
  resultsLength = 0;
  loading = false;
  displayedColumns: string[] = [
    'number',
    'complianceType',
    'config',
    'actions',
  ];
  data = new MatTableDataSource<Compliance>();

  constructor(
    private fb: UntypedFormBuilder,
    private complianceConfigService: ComplianceConfigurationService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private snackBar: SnackBarService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ complianceTypes }) => {
      this.complianceTypes = complianceTypes;
      this.form = this.fb.group({
        complianceType: [null],
      });
      this.complianceConfigService.complianceConfigs.subscribe(
        ({ complianceConfigs, complianceFilters }) => {
          this.loading = false;
          this.data.data = complianceConfigs.map((c) => {
            let config = '';
            switch (c.remittanceComplianceType) {
              case 'Threshold limit':
              case 'Large transaction':
                // @ts-ignore
                config = `Max ${c.value.amount}$`;
                break;
              case 'Threshold structuring':
              case 'Large transaction structuring':
                // @ts-ignore
                config = `Max ${c.value.amount}$ in ${c.value.duration} days`;
                break;
              case 'Recurrent transactions':
                // @ts-ignore
                config = `Max ${c.value.transactionCount} transactions in ${c.value.duration} days`;
                break;
              default:
                break;
            }

            return {
              id: c.id,
              complianceType: c.remittanceComplianceType,
              config,
            };
          });
          this.data.paginator = this.paginator;
          this.resultsLength = complianceConfigs.length;
          this.form.controls['complianceType'].setValue(
            complianceFilters?.remittanceComplianceTypeId,
          );
        },
      );
    });
  }

  onSubmit() {
    const filters = this.form.value.complianceType
      ? { remittanceComplianceTypeId: this.form.value.complianceType }
      : null;
    this.complianceConfigService.triggerSearchComplianceConfigs(filters);
  }
}
