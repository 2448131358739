<app-header
  [loggedIn]="authService.isLoggedIn"
  [currentUser]="authService.currentUserValue"
></app-header>
<div class="flex-equal d-flex overflow-hidden">
  <div class="flex-equal d-flex overflow-hidden">
    <router-outlet *ngIf="isLoginPage || isResetPasswordPage"></router-outlet>
    <app-sidenav
      *ngIf="!isLoginPage && !isResetPasswordPage"
      [userPermissions]="userPermissions"
    ></app-sidenav>
    <main *ngIf="!isLoginPage && !isResetPasswordPage" class="col-12 col-md-9 col-lg-10 overflow-auto">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<app-loader></app-loader>
