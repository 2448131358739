import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from '../../_common/snackBar.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';

@Component({
  selector: 'app-occupations',
  templateUrl: './occupationsPopup.component.html',
})
export class OccupationsPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  occupation: LookupsDto;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupsService: LookupsService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<OccupationsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: LookupsDto[],
  ) {
    this.occupation = data[0];
    if (this.occupation) {
      this.addMode = false;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      occupationName: [this.occupation?.name, [Validators.required]],
      isActive: [this.occupation?.isActive || this.addMode],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const occupation: LookupsDto = {
        name: formValues.occupationName,
        isActive: formValues.isActive || false,
      };

      const handler = this.occupation
        ? this.lookupsService.updateOccupation({
            id: this.occupation.id,
            ...occupation,
          })
        : this.lookupsService.createOccupation(occupation);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
