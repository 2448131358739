<form [formGroup]="commentForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="fill" class="full-width" *ngIf="isReplacementReport">
        <mat-label>Receiver Name</mat-label>
        <input matInput formControlName="receiverName" required />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width" *ngIf="isReplacementReport">
        <mat-label>Receiver Mobile</mat-label>
        <input matInput formControlName="receiverMobile" required />
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
        <mat-label>Comment</mat-label>
        <textarea matInput formControlName="comment" placeholder="Leave a Comment" required></textarea>
    </mat-form-field>

    <button mat-raised-button color="primary" type="submit" [disabled]="!commentForm.valid">
        Submit
    </button>
</form>