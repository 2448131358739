import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";

import {MatDialog} from "@angular/material/dialog";
import { CbkReportFilter} from "./data/Dto/CbkRemittance.dto";
import {CbkReportService} from "./data/cbkReports.service";
import { Utilities } from '../_common/utils';
import {SnackBarService} from "../_common/snackBar.service";
import { LookupDto } from '../_common/data/dto/api.dto';
import { ActivatedRoute } from '@angular/router';
import {ClientProtectionCustomerReportPopupComponent} from "./clientProtection-customer-report-popup.component";

@Component({
  selector: 'app-client-protection-customer-report',
  templateUrl: './clientProtection-customer-report.component.html'
})
export class ClientProtectionCustomerReportComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public form: any;
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);
  currencies!: LookupDto[];
  constructor(private route: ActivatedRoute,private fb : UntypedFormBuilder,private cbkRemittanceService : CbkReportService, private dialog: MatDialog, private snackBar: SnackBarService) { }

  ngOnInit(): void {
    this.route.data.subscribe(({currencies})=>{
      this.currencies = currencies;
    });

    this.form = this.fb.group({
      startDate: this.fromDate,
      endDate: this.toDate,
      currency: [null, [Validators.required]]
    });
  }

  onSubmit(){
    if(this.form.valid){
      const { startDate, endDate } =
        this.form.value;
      const filter: CbkReportFilter = {
        startDate: startDate,
        endDate: endDate,
        direction : 2,
        currencyId: this.form.value.currency?.id,
        type: 'cpaccount',
      };

      this.cbkRemittanceService
        .getClientProtectionCustomerReport(filter)
        .subscribe((resp: any) => {
          const dialogRef = this.dialog.open(
            ClientProtectionCustomerReportPopupComponent,
            {
              data: {
                clientProtectionCustomer: resp,
                reportCurrency: this.form.value.currency.iso3
              },
              width: '1000px',
              maxHeight: '90vh',
              position: { top: '8rem', left : '15rem' },
            },
          );

          dialogRef.afterClosed().subscribe((resp: string) => {
            if (resp === 'close') {
              return;
            }
          });
        }, ({ message }) => {
          this.snackBar.open(message);
        });
    }
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }
}
