import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BranchesService } from '../branch/data/branches.service';
import { BranchConfiguration } from '../branch/data/dto/branches.dto';
import { Utilities } from '../_common/utils';
import { RemittancesDetailsDto } from './data/dto/remittances.dto';
import { maskNumber } from "../_common/utils";
import {CustomerTypes} from "../_common/enums/systemBranches.enums";

@Component({
  selector: 'app-remittance-receipt',
  templateUrl: './remittancePayReceiptPopup.component.html',
  styleUrls: ['./receipt.css'],
})
export class RemittancePayReceiptPopupComponent implements OnInit {
  comments: string = '';
  branchConfigurations: BranchConfiguration[] = [];
  branchLanguage!: string;
  today = new Date();
  receiptNumber: string = '';
  customerType = CustomerTypes
  maskNumber = maskNumber;
  constructor(
    public dialogRef: MatDialogRef<RemittancePayReceiptPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemittancesDetailsDto,
    public branchService: BranchesService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.branchService
      .branchById(this.data.receivingBranch.id)
      .subscribe((data) => {
        this.branchConfigurations = data.find(
          (branch) => branch.id === this.data.receivingBranch.id,
        )?.branchConfigurations as BranchConfiguration[];
        this.branchLanguage = this.branchConfigurations.find(
          (bc) => bc.configurationType === 'language',
        )?.configuration.branchLanguage;
        this.translateService.use(this.branchLanguage);
      });
    this.receiptNumber = this.data.remittanceReceipt.find((rmr: any) => rmr.receiptTypeId == 2)?.receiptNumber;
  }

  sumOfAmountAndCommissoin(amount: any, commission: any) {
    return amount + commission;
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  checkNullValue(value: string | undefined) {
    if (value === null) value = '';
    return value;
  }

  round(amount: number){
    return Math.round(amount)
  }

  getFullName(firstName: string, middleName: string, lastName: string) {
    return middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;
  }
}
