import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from './data/reports.service';
import {SnackBarService} from "../_common/snackBar.service";
import {PartnerSettlementReportPopupComponent} from "./partnerSettlementReportPopup.component";
import moment from "moment";

@Component({
  selector: 'app-partner-settlement',
  templateUrl: './partnerSettlement.component.html',
})
export class PartnerSettlementReportComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: FormGroup;
  today = new Date();
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthService,
    private reportService: ReportsService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const { startDate, endDate } = this.form.value;

      this.reportService
        .getPartnerSettlementReport(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD")
        )
        .subscribe((data) => {
          const dialogRef = this.dialog.open(PartnerSettlementReportPopupComponent, {
            data: data,
            width: '1000px',
            maxHeight: '90vh',
          });

          dialogRef.afterClosed().subscribe((resp) => {
            if (resp === 'close') {
              return;
            }
          });
        }, ({ message }) => {
          this.snackBar.open(message);
        });
    }
  }
}
