import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UsersService, passwordRegex } from './data/users.service';
import { AuthService } from '../auth/data/auth.service';
import { SnackBarService } from '../_common/snackBar.service';
import { CustomValidators } from '../_common/data/customValidators';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetPasswordPopup.component.html',
  styleUrls: ['./resetPasswordPopup.component.less'],
})
export class ResetPasswordPopupComponent implements OnInit {
  resetPassForm!: UntypedFormGroup;
  userId!: number;
  newPassword!: string;
  passwordPattern = passwordRegex;

  constructor(
    public fb: UntypedFormBuilder,
    @Optional() public dailogRef: MatDialogRef<ResetPasswordPopupComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; forceChangePassword: boolean },
    private usersService: UsersService,
    private authService: AuthService,
    private snackBar: SnackBarService,
  ) {}

  closeDialog() {
    this.dailogRef.close();
  }

  ngOnInit() {
    this.resetPassForm = this.fb.group(
      {
        newPassword: [null, [Validators.required]],
        confirmPassword: [null, [Validators.required]],
        otpCode: [null, [Validators.required]],
      },
      {
        validators: CustomValidators.matchPasswords(
          'newPassword',
          'confirmPassword',
        ),
      },
    );
  }

  onSubmit() {
    if (this.resetPassForm.valid) {
      this.newPassword = this.resetPassForm.value.newPassword;
      this.userId = this.data?.id;
      this.usersService
        .resetUserPassword({
          userId: this.userId,
          newPassword: this.newPassword,
          otpCode: this.resetPassForm.value.otpCode
        })
        .subscribe(
          (resp) => {
            // @ts-ignore
            this.snackBar.open(resp?.message);
            this.dailogRef?.close();
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
    }
  }
}
