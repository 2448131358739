<h1 mat-dialog-title>{{ 'resetPassword' | translate }}</h1>
<div mat-dialog-content>
  <form
    name="resetPassForm"
    id="resetPassForm"
    [formGroup]="resetPassForm"
    novalidate
    autocomplete="off"
  >
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'newPassword' | translate }}</mat-label>
          <input
            type="password"
            matInput
            #newPassword
            formControlName="newPassword"
            [pattern]="passwordPattern"
            autocomplete="new-password"
          />
          <mat-error *ngIf="resetPassForm.value.newPassword">{{
            'passwordPattern' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 pt-2">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'confirmPassword' | translate }}</mat-label>
          <input
            type="password"
            matInput
            #confirmPassword
            formControlName="confirmPassword"
            autocomplete="new-password"
          />
          <mat-error *ngIf="resetPassForm.value.confirmPassword">{{
            'passwordMismatch' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12 pt-2">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'otpCode' | translate }}</mat-label>
          <input
            type="password"
            matInput
            #otpCode
            formControlName="otpCode"
            autocomplete="new-password"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end mt-1">
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'reset' | translate }}
  </button>
  <button
    mat-raised-button
    mat-dialog-close="close"
    class="ms-1"
    color="secondary"
    (click)="closeDialog()"
  >
    {{ 'close' | translate }}
  </button>
</div>
