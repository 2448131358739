import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {BtransFilter, CbkRemittanceReportDto, CbkReportDetailFilter, CbkReportFilter} from "./data/Dto/CbkRemittance.dto";
import {CbkReportService} from "./data/cbkReports.service";
import { Utilities } from '../_common/utils';
import { ClientProtectionReportPopupComponent } from './clientProtection-report-popup';
import {SnackBarService} from "../_common/snackBar.service";
import { LookupDto } from '../_common/data/dto/api.dto';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { AuthService } from '../auth/data/auth.service';
import { Permissions } from '../_common/enums/permissions.enums';

@Component({
  selector: 'app-parquet-report',
  templateUrl: './parquet-report.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class ParquetReportComponent implements OnInit {

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public form: any;
  displayedColumns: string[] = ['number', 'name','date', 'reportType', 'actions'];
  data = new MatTableDataSource<{ id: number, name: string, date: Date, path: string, recordType: string, reportType: string, isDeleted: boolean }>();
  date = new UntypedFormControl(new Date(), [Validators.required]);
  currencies!: LookupDto[];
  constructor(private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private cbkRemittanceService: CbkReportService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      date: this.date,
      type: [null, [Validators.required]],
      recordType: [null, [Validators.required]]
    });
  }

  onSubmit(afterDeletion: boolean | null){
    if(this.form.valid){
      const { date } =
        this.form.value;
      const filter: BtransFilter = {
        date: moment(date).format('YYYY-MM-DD'),
        type: this.form.get('type').value,
        recordType: this.form.get('recordType').value,
      };

      const subscriptionHandler = (data: any[]) => {
        this.data.data = data;
        this.data.paginator = this.paginator;
      };
      
      const errorHandler = ({ message }: { message: string }) => {
        this.snackBar.open(message);
      };
      
      if (afterDeletion) {
        this.cbkRemittanceService.getParquetReports(filter.type).subscribe(subscriptionHandler, errorHandler);
      } else {
        this.cbkRemittanceService.getParquetReport(filter).subscribe(
          (resp: any) => this.cbkRemittanceService.getParquetReports(filter.type).subscribe(subscriptionHandler, errorHandler),
          errorHandler
        );
      }
    }
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }

  downloadParquetReport(fileName: string, path: string, fileType: string) {
    this.cbkRemittanceService.getParquetFile(path, fileType).subscribe((data) => {
      const url = window.URL.createObjectURL(data);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName+'.parquet';
      link.click();

      // Clean up the URL and the link
      window.URL.revokeObjectURL(url);
      link.remove();
    })
  }

  deleteTheReport(id: number){
    this.cbkRemittanceService.deleteParquetReport(id).subscribe(( resp ) => {
      this.onSubmit(true);
    }, ({ message })=>{
      this.snackBar.open(message);
    });
  }

  regenerateTheReport(row: any){
    const filter: BtransFilter = {
      id: row.id,
      date: row.reportDate,
      type: row.reportType,
      recordType: row.recordType,
    };
    this.cbkRemittanceService.regenerateParquetReport(filter).subscribe(( resp ) => {
      this.onSubmit(true);
    }, ({ message })=>{
      this.snackBar.open(message);
    });
  }
}
