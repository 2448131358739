import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnpaidRemittanceReportDetailsDto } from './data/dto/reports.dto';
import * as XLSX from 'xlsx';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-unpaidremittancereportpopup',
  templateUrl: './unpaidRemittanceReportPopup.component.html',
})
export class UnpaidRemittanceReportPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<UnpaidRemittanceReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: UnpaidRemittanceReportDetailsDto[] },
  ) {}
  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
      ? Utilities.convertDateToLocaleString(date, '')
      : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('unpaidRemittanceReport'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      'Unpaid Remittance Report on ' +
        this.convertDateToLocaleString(new Date(), false) +
        '.xlsx',
    );
  }
}
