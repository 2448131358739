<app-form-container label="{{ formTitle | translate }}">
  <div form>
    <form
      class="container-fluid flex-column p-0"
      name="form"
      [formGroup]="form"
      novalidate
      (submit)="onSubmit()"
    >
      <mat-card>
        <mat-card-title>{{ 'supportTicket' | translate }}</mat-card-title>
        <mat-card-content>
          <div class="row">
            <div class="col">
              <div class="row">
                  <ng-select  [disabled]="isDisabled" class="col"
                    formControlName="branchId" [clearable]="false"
                    (change)="onBranchSelected($event)" placeholder="{{ 'branch' | translate }}">
                    <ng-option
                      *ngFor="let branch of branches"
                      [value]="branch.id"
                    >
                      {{
                        branch.code === 'TKCPO'
                          ? ('hqBranch' | translate)
                          : branch.code + ' - ' + branch.title
                      }}
                    </ng-option>
                  </ng-select>
                <ng-select class="col"
                 formControlName="assigneeId" [clearable]="false"
                 (change)="onAssigneeSelected($event)"
                 placeholder="{{ 'assignee' | translate }}">
                  <ng-option
                    *ngFor="let assignee of assignees"
                    [value]="assignee.id"
                  >{{ assignee.name }}</ng-option>
                </ng-select>
                <mat-form-field
                  *ngIf="!addMode"
                  appearance="outline"
                  class="col"
                >
                  <mat-label>{{ 'status' | translate }}</mat-label>
                  <mat-select formControlName="statusId">
                    <mat-option
                      *ngFor="let status of statuses"
                      [value]="status.id"
                      >{{ status.name }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
                <div class="col">
                  <p>{{ 'reportedBy' | translate }}</p>
                  <p>{{ reportedBy }}</p>
                </div>
              </div>
              <div class="row">
                <mat-form-field appearance="outline" class="col">
                  <mat-label>{{ 'title' | translate }}:</mat-label>
                  <input matInput #title formControlName="title" />
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field appearance="outline" class="col">
                  <mat-label>{{ 'description' | translate }}:</mat-label>
                  <textarea
                    matInput
                    #description
                    formControlName="description"
                  ></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
      <div class="d-flex justify-content-end my-3">
        <button
          mat-raised-button
          class="me-2"
          color="secondary"
          type="reset"
          routerLink="/support/tickets"
        >
          {{ 'back' | translate }}
        </button>
        <button
          *ngIf="addMode"
          mat-raised-button
          class="me-2"
          color="warn"
          #resetBtn
          type="reset"
        >
          {{ 'reset' | translate }}
        </button>
        <button
          [disabled]="!form.valid"
          *ngIf="
            authService.isUserAuthorized([
              permissions.CREATE_TICKET,
              permissions.UPDATE_TICKET
            ])
          "
          mat-raised-button
          type="submit"
          color="primary"
        >
          {{ formTitle | translate }}
        </button>
      </div>
    </form>
    <mat-tab-group *ngIf="!addMode" class="px-4" (selectedTabChange)="loadHistory()">
      <mat-tab label="{{ 'comments' | translate }}">
        <div *ngFor="let comment of ticket?.comments" class="p-2 border">
          <p>{{ comment.comment }}</p>
          <p>{{ comment.username }} at {{ comment.datetime }}</p>
        </div>
        <form
          name="commentForm"
          [formGroup]="commentForm"
          novalidate
          class="d-flex"
        >
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'comment' | translate }}:</mat-label>
            <textarea matInput #comment formControlName="comment"></textarea>
          </mat-form-field>
        </form>
        <div class="d-flex justify-content-end my-3">
          <button type="reset" #commentResetBtn style="visibility: hidden">
            reset
          </button>
          <button
            [disabled]="!commentForm.valid"
            *ngIf="authService.isUserAuthorized([permissions.ADD_COMMENT])"
            mat-raised-button
            type="submit"
            color="primary"
            (click)="onCommentSubmit()"
          >
            {{ 'addComment' | translate }}
          </button>
        </div>
      </mat-tab>
      <mat-tab label="{{ 'history' | translate }}">
        <div *ngFor="let history of ticket?.history" class="py-2">
          <span>{{ history }}</span>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-form-container>
