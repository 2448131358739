import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Utilities } from '../_common/utils';
import * as XLSX from 'xlsx';
import { RemittanceService } from '../remittance/data/remittance.service';
import { RemittanceHistoryPopupComponent } from '../remittance/remittanceHistoryPopup.component';

@Component({
  selector: 'app-customer-risk-rating-score-details-popup',
  templateUrl: './customer-risk-rating-score-details-popup.component.html',
  styleUrls: ['./customer-risk-rating-score-details-popup.component.less']
})
export class CustomerRiskRatingScoreDetailsPopupComponent {

   constructor(
    public dialogRef: MatDialogRef<CustomerRiskRatingScoreDetailsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: any[any] },
    private remittanceService: RemittanceService,
    private dialog: MatDialog,
  ) {}

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('reconciliationReportDetails'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      'Reconciliation Report Details on ' +
        this.convertDateToLocaleString(new Date(), false) +
        '.xlsx',
    );
  }

  onViewHistoryClick(remittanceId: number) {
    this.remittanceService
      .getRemittanceHistory(remittanceId)
      .subscribe((remittanceHistory) => {
        this.dialog.open(RemittanceHistoryPopupComponent, {
          disableClose: true,
          width: '900px',
          data: remittanceHistory,
        });
      });
  }

}
