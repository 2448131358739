import { Component, Inject, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AuthService } from '../auth/data/auth.service';
import { GetSettlementHistoryDto } from './data/dto/settlement.dto';
import { Permissions } from '../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SettlementHistoryChangeLogPopupComponent } from './settlementHistoryChangeLogPopup.component';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-settlement-history',
  templateUrl: './settlementHistoryPopup.component.html',
})
export class SettlementHistoryPopupComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  permissions = Permissions.settlementManagement.settlements;

  displayedColumns: string[] = [
    'number',
    'user',
    'date',
    'comments',
    'actionType',
    'actions',
  ];
  dataSource = new MatTableDataSource<GetSettlementHistoryDto>(this.dialogData);

  constructor(
    public dialogRef: MatDialogRef<SettlementHistoryPopupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData: GetSettlementHistoryDto[],
    public authService: AuthService,
  ) {
    setTimeout(() => (this.dataSource.paginator = this.paginator));
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  checkNullValue(value: string | undefined) {
    if (value === null) value = '';
    return value;
  }

  viewChangeLog(data: GetSettlementHistoryDto) {
    this.dialog.open(SettlementHistoryChangeLogPopupComponent, {
      disableClose: true,
      width: '1000px',
      data: data.snapshot,
    });
  }
}
