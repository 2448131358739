<h1 mat-dialog-title>{{ 'updateCustomer' | translate }}</h1>
<div mat-dialog-content>
  <form
    form
    class="container-fluid flex-column p-0"
    name="formReceiverUpdate"
    [formGroup]="formReceiverUpdate"
    novalidate
  >
    <div class="row">
      <div *ngIf="data.canUpdateReceiverName">
        <mat-form-field appearance="outline" class="col">
          <mat-label>{{ 'firstName' | translate }}:</mat-label>
          <input matInput #firstName formControlName="firstName" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col">
          <mat-label>{{ 'lastName' | translate }}:</mat-label>
          <input matInput #lastName formControlName="lastName" />
        </mat-form-field>
      </div>
      <div *ngIf="data.canUpdateReceiverPhone">
        <mat-form-field appearance="outline" class="col">
          <mat-label>{{ 'mobile' | translate }}:</mat-label>
          <input matInput #phone formControlName="phone" />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">
    {{ 'close' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'update' | translate }}
  </button>
</div>
