import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Utilities } from "../_common/utils";
import { FinsurvModificationChangeLogPopupComponent } from "./finsurvModificationChangeLogPopup.component";

@Component({
  selector: 'app-finsurv-modification',
  templateUrl: './finsurvModificationsPopup.component.html',
})
export class FinsurvModificationsPopup {
  
    displayedColumns: string[] = [
    'number',
    'action',
    'comments',
    'createdBy',
    'dateCreated',
    // 'actions',
  ];
  customerId!: number;
  dataSource = new MatTableDataSource<any>();

  constructor(
    public dialogRef: MatDialogRef<FinsurvModificationsPopup>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { finsurvModification: any[]; customerId: number },
  ) {
    this.dataSource.data = this.data.finsurvModification;
  }

  viewChangeLog(data: any) {
    this.dialog.open(FinsurvModificationChangeLogPopupComponent, {
      disableClose: true,
      width: '54rem',
      data: data.changeLog,
    });
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
  }

}