import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {RemittanceFilter, RemittanceService} from './data/remittance.service';
import { LookupDto } from '../_common/data/dto/api.dto';
import {
  RemittanceForPaymentResponse,
  RemittanceForPaymentResponseDto,
} from './data/dto/remittances.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { RemittancePaybackReceiptPopupComponent } from './remittancePaybackReceiptPopup.component';
import { GetCustomerDto } from '../customer/data/dto/customer.dto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payback-remittance',
  templateUrl: './paybackRemittance.component.html',
})
export class PaybackRemittanceComponent implements OnInit {
  form!: UntypedFormGroup;
  tellerAccounts: LookupDto[] = [];
  paymentTypes: LookupDto[] = [];
  remittance?: RemittanceForPaymentResponseDto;
  today = new Date();
  senderCustomer: GetCustomerDto = {} as GetCustomerDto;
  receiverCustomer: GetCustomerDto = {} as GetCustomerDto;
  constructor(
    private fb: UntypedFormBuilder,
    private remittanceService: RemittanceService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private tranlateService: TranslateService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({ tellerAccounts, remittances, paymentTypes }) => {
        this.tellerAccounts = tellerAccounts;
        this.paymentTypes = paymentTypes;
        const { remittance } = remittances as RemittanceForPaymentResponse;
        if (!remittance) {
          this.snackBar.open('remittanceNotFound');
          return;
        }

        this.remittance = remittance;
        const { senderCustomers, receiverCustomers } = remittance;
        this.senderCustomer = senderCustomers[0];
        this.receiverCustomer = receiverCustomers[0];
        const amountToReturn =
          remittance.status === 'Return with commissions'
            ? remittance.commission + remittance.amount
            : remittance.amount;

        this.form = this.fb.group({
          remittanceStatus: [remittance.status],
          remittanceCode: [remittance.code],
          senderFirstName: [
            { value: this.senderCustomer.firstName, disabled: true },
          ],
          senderMiddleName: [
            { value: this.senderCustomer.middleName, disabled: true },
          ],
          senderLastName: [
            { value: this.senderCustomer.lastName, disabled: true },
          ],
          senderMobilePhone: [
            { value: this.senderCustomer.mobilePhone, disabled: true },
          ],

          receiverMobilePhone: [
            { value: this.receiverCustomer.mobilePhone, disabled: true },
          ],
          receiverFirstName: [
            { value: this.receiverCustomer.firstName, disabled: true },
          ],
          receiverMiddleName: [
            { value: this.receiverCustomer.middleName, disabled: true },
          ],
          receiverLastName: [
            { value: this.receiverCustomer.lastName, disabled: true },
          ],

          receiverAccountId: [null],
          amount: [
            {
              value: remittance.amount * remittance.exchangeRate,
              disabled: true,
            },
          ],
          currency: [remittance.currency],
          commission: [
            {
              value: remittance.commission * remittance.exchangeRate,
              disabled: true,
            },
          ],
          amountToReturn: [
            { value: amountToReturn * remittance.exchangeRate, disabled: true },
          ],
          remittanceType: [
            { value: remittance.remittanceType, disabled: true },
          ],
          remittanceSubType: [
            { value: remittance.remittanceSubType, disabled: true },
          ],
          paymentType: [paymentTypes[0]],
        });

        this.form.updateValueAndValidity();
      },
    );
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.getRawValue();
      const { receiverAccountId } = formValues;
      this.remittanceService
        .paybackRemittance({
          remittanceId: this.remittance?.id as number,
          receiverAccountId,
        })
        .subscribe(
          (resp) => {
            this.payReceiptPrintDialog(resp.id);
            this.snackBar.open(resp?.message);
          },
          ({ message }) => this.snackBar.open(message),
        );
    }
  }

  payReceiptPrintDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceDetails(remittanceId)
      .subscribe((remittance) => {
        const dialogRef = this.dialog.open(RemittancePaybackReceiptPopupComponent, {
          width: '850px',
          data: remittance,
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.tranlateService.use(localStorage.getItem('lang') || 'en');
            this.router.navigateByUrl('/remittances');
            this.remittanceService.getRemittances(({remittanceId: remittanceId}) as RemittanceFilter);
          }
        });
      });
  }
}
