<app-form-container label="{{ 'remittanceReport' | translate }}">
  <form
    form
    class="container-fluid flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row d-flex align-items-center">
      <span class="colum"> {{ 'direction' | translate }}: </span>
      <div class="col d-flex align-items-center pb-4">
        <mat-radio-group
          aria-label="Direction: "
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="direction"
        >
          <mat-radio-button value="incoming">{{
            'incoming' | translate
          }}</mat-radio-button>
          &nbsp;
          <mat-radio-button value="outgoing">{{
            'outgoing' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
      &nbsp;
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'parentBranch' | translate }}:</mat-label>
        <mat-select #ParentBranch formControlName="parentBranch">
          <mat-option *ngFor="let branch of allBranches" [value]="branch.id">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select #branch formControlName="branch">
          <mat-option *ngFor="let branch of allBranches" [value]="branch.id">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row d-flex align-items-center">
      <span class="colum"> {{ 'status' | translate }}: </span>
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #paid
          color="primary"
          formControlName="paid"
          (change)="onStatusSelected('paid')"
        >
          {{ 'paid' | translate }}
        </mat-checkbox>
        &nbsp;
        <mat-checkbox
          matInput
          #unpaid
          color="primary"
          formControlName="unpaid"
          (change)="onStatusSelected('unpaid')"
        >
          {{ 'unpaid' | translate }}
        </mat-checkbox>
        &nbsp;
        <mat-checkbox
          matInput
          #returned
          color="primary"
          formControlName="returned"
          (change)="onStatusSelected('returned')"
        >
          {{ 'returned' | translate }}
        </mat-checkbox>
      </div>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'filterByCurrency' | translate }}:</mat-label>
        <mat-select #currency formControlName="currency">
          <mat-option
            *ngFor="let currency of allCurrencies"
            [value]="currency.currencyId"
          >
            {{ currency.currency }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'enterDateRange' | translate }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'senderNameOrMobile' | translate }}:</mat-label>
        <input matInput #sender formControlName="sender" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'recieverNameOrMobile' | translate }}:</mat-label>
        <input matInput #receiver formControlName="receiver" />
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button mat-raised-button type="submit" color="primary">
        {{ 'generateReport' | translate }}
      </button>
    </div>
  </form>
</app-form-container>
