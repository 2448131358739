<app-form-container label="{{ 'addRemittance' | translate }}">
  <form
    #remittanceForm
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <input type="hidden" formControlName="complianceComments" />
    <mat-card>
      <mat-card-title>{{ 'senderInformation' | translate }}</mat-card-title>
      <mat-radio-group formControlName="customerTypeId" class="col-3 mb-2 mt-3" (change)="onCustomerTypeChanged($event)">
        <mat-radio-button
            *ngFor="let allCustomerType of allCustomerTypes" [value]="allCustomerType"
            [checked]="allCustomerType.id === customerTypeId"
            style="margin-left: 8px;">
          {{ allCustomerType.name  }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field class="col-md-12">
                <input
                  matInput
                  [(ngModel)]="selectedSender"
                  [matAutocomplete]="auto"
                  [formControl]="searchSenderCtrl"
                  placeholder="{{ 'searchSenderByMobile' | translate }}"
                />
                <button
                  *ngIf="selectedSender"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSelectedSender(true)"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete
                  [panelWidth]="400"
                  #auto="matAutocomplete"
                  (optionSelected)="onSenderSelected()"
                  [displayWith]="displaySenderWith"
                >
                  <mat-option *ngIf="isLoadingSenders" class="is-loading"
                    >Loading...</mat-option
                  >
                  <ng-container *ngIf="!isLoadingSenders">
                    <mat-option
                      *ngFor="let sender of filteredSenders"
                      [value]="sender"
                    >
                      <span
                        ><b>{{
                          getFullName(
                            sender.firstName,
                            sender.middleName,
                            sender.lastName
                          )
                        }}</b>
                        ({{ sender.mobilePhone }})</span
                      >
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="row mt-2 mb-3" *ngIf="customerTypeId == customerTypes.Corporate">
              <p class="h6">{{ 'companyInformation' | translate }}</p>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'companyTitle' | translate }}</mat-label>
                  <input matInput #companyTitle formControlName="companyTitle" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyTitle'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'taxIdentificationNumber' | translate }}</mat-label>
                  <input matInput #taxIdentificationNumber formControlName="taxIdentificationNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('taxIdentificationNumber'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'tradeRegistryNumber' | translate }}</mat-label>
                  <input matInput #tradeRegistryNumber formControlName="tradeRegistryNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('tradeRegistryNumber'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'fieldOfActivity' | translate }}</mat-label>
                  <input matInput #fieldOfActivity formControlName="fieldOfActivity" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('fieldOfActivity'))"></app-validation-error>
              </div>

              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'mobile' | translate }}:</mat-label>
                  <input
                    matInput
                    #companyMobilePhone
                    formControlName="companyMobilePhone"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('companyMobilePhone'))"
                ></app-validation-error>
              </div>
              <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>{{ 'country' | translate }}</mat-label>
                <mat-select
                  formControlName="companyCountry"
                  (selectionChange)="onCountryChange($event.value.id)"
                >
                  <mat-option
                    *ngFor="let country of sendingBranchCountry"
                    [value]="country"
                  >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                <mat-label>{{ 'city' | translate }}:</mat-label>
                <mat-select
                  formControlName="companyCity"
                  (selectionChange)="onCityChange($event.value.id)"
                >
                  <mat-option *ngFor="let city of cities" [value]="city">{{
                    city.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>{{ 'street' | translate }}:</mat-label>
                    <input matInput #companyStreet formControlName="companyStreet" />
                  </mat-form-field>
                  <app-validation-error
                    [control]="$any(form.get('companyStreet'))"
                  ></app-validation-error>
                </div>
                <div class="col-md-3">
                  <mat-form-field appearance="outline" class="col-12">
                    <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
                    <input
                      matInput
                      #companyBuildingNumber
                      formControlName="companyBuildingNumber"
                    />
                  </mat-form-field>
                  <app-validation-error
                    [control]="$any(form.get('companyBuildingNumber'))"
                  ></app-validation-error>
                </div>
                <mat-form-field appearance="outline" class="col">
                  <mat-label>{{ 'companyArea' | translate }}:</mat-label>
                  <input
                    matInput
                    #companyArea
                    formControlName="companyArea"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('companyArea'))"
                ></app-validation-error>
            </div>
            <div class="row" *ngIf="customerTypeId != customerTypes.Corporate">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'firstName' | translate }}:</mat-label>
                  <input
                    matInput
                    #senderFirstName
                    formControlName="senderFirstName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('senderFirstName'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'middleName' | translate }}:</mat-label>
                  <input
                    matInput
                    #senderMiddleName
                    formControlName="senderMiddleName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('senderMiddleName'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'lastName' | translate }}:</mat-label>
                  <input
                    matInput
                    #senderLastName
                    formControlName="senderLastName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('senderLastName'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row" *ngIf="customerTypeId != customerTypes.Corporate">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'mobile' | translate }}:</mat-label>
                  <input matInput #senderMobilePhone formControlName="senderMobilePhone" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('senderMobilePhone'))"></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'placeOfBirth' | translate }}:</mat-label>
                  <input
                    matInput
                    #placeOfBirth
                    formControlName="placeOfBirth"
                  />
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'dob' | translate }}</mat-label>
                  <input
                    matInput
                    [max]="maxDOB"
                    [matDatepicker]="dob"
                    formControlName="dateOfBirth"
                  />
                  <mat-hint>DD-MM-YYYY</mat-hint>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="dob"
                  ></mat-datepicker-toggle>
                  <mat-datepicker startView="month" #dob></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="row" *ngIf="customerTypeId != customerTypes.Corporate">

              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label class="mb-1">{{ 'gender' | translate }}:</mat-label>
                  <mat-select formControlName="gender">
                    <mat-option *ngFor="let gender of customerGenders" [value]="gender">{{ gender.name }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-4">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'occupation' | translate }}:</mat-label>
                <mat-select formControlName="occupation">
                  <mat-option
                    *ngFor="let occupation of customerOccupations"
                    [value]="occupation"
                    >{{ occupation.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'sourceOfIncome' | translate }}</mat-label>
                <mat-select formControlName="sourceOfIncome">
                  <mat-option
                    *ngFor="let source of customerSourceOfIncome"
                    [value]="source"
                    >{{ source.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row mt-2" *ngIf="customerTypeId != customerTypes.Corporate">
              <p class="h6">{{ 'addressInformation' | translate }}</p>
              <!-- <mat-form-field appearance="outline" class="col">
                <mat-label>{{'addressLine1' | translate}}:</mat-label>
                <input matInput #senderAddressLine1 formControlName="senderAddressLine1">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'addressLine2' | translate}}:</mat-label>
                <input matInput #senderAddressLine2 formControlName="senderAddressLine2">
              </mat-form-field> -->
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'country' | translate }}</mat-label>
                <mat-select
                  formControlName="senderCountry"
                  (selectionChange)="onCountryChange($event.value.id)"
                >
                  <mat-option
                    *ngFor="let country of sendingBranchCountry"
                    [value]="country"
                    >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'city' | translate }}:</mat-label>
                <mat-select
                  formControlName="senderCity"
                  (selectionChange)="onCityChange($event.value.id)"
                >
                  <mat-option *ngFor="let city of cities" [value]="city">{{
                    city.name
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row" *ngIf="customerTypeId != customerTypes.Corporate">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'street' | translate }}:</mat-label>
                  <input matInput #street formControlName="street" />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('street'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
                  <input
                    matInput
                    #buildingNumber
                    formControlName="buildingNumber"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('buildingNumber'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'houseNumber' | translate }}:</mat-label>
                  <input matInput #houseNumber formControlName="houseNumber" />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('houseNumber'))"
                ></app-validation-error>
              </div>
            </div>
          </div>
        </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="mt-3" *ngIf="customerTypeId == customerTypes.Corporate">
      <mat-accordion>
        <mat-expansion-panel  [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Conductors
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div appearance="fill" class="col">
              <ng-select
                #conductorSelect
                formControlName="conductorId"
                (change)="onConductorChanged($event)"
                placeholder="{{ 'conductors' | translate }}"
                [clearable]="true"
              (clear)="onConductorCleared()"
              >
                <ng-option *ngFor="let conductor of conductors" [value]="conductor"
                >{{ conductor?.firstName + ' ' + conductor?.lastName }}
                </ng-option>
              </ng-select>
<!--              <span  (click)="conductorSelect.clearModel()"></span>-->
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'firstName' | translate }}:</mat-label>
                <input matInput #conductorFirstName formControlName="conductorFirstName" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('conductorFirstName'))"></app-validation-error>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'lastName' | translate }}:</mat-label>
                <input matInput #conductorLastName formControlName="conductorLastName" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('conductorLastName'))"></app-validation-error>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'mobile' | translate }}:</mat-label>
                <input matInput #conductorMobilePhone formControlName="conductorMobilePhone" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('conductorMobilePhone'))"></app-validation-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'placeOfBirth' | translate }}:</mat-label>
                <input matInput #conductorPlaceOfBirth formControlName="conductorPlaceOfBirth" />
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'dob' | translate }}</mat-label>
                <input matInput [max]="maxDOB" [matDatepicker]="dob" formControlName="conductorDateOfBirth" />
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #dob></mat-datepicker>
              </mat-form-field>
            </div>
            <mat-form-field appearance="outline" class="col-md-3">
              <mat-label>{{ 'position' | translate }}:</mat-label>
              <input matInput #conductorPosition formControlName="conductorPosition" />
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'nationality' | translate }}</mat-label>
                <mat-select formControlName="conductorNationality" (selectionChange)="onConductorCountrySelected($event)">
                  <mat-option
                    *ngFor="let country of allCountries"
                    [value]="country"
                  >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="isThisTrkCountry">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'fatherName' | translate }}:</mat-label>
                <input matInput #conductorFartherName formControlName="conductorFartherName" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('conductorFartherName'))"
              ></app-validation-error>
            </div>
            <div class="col-md-3" *ngIf="isThisTrkCountry">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'motherName' | translate }}:</mat-label>
                <input matInput #conductorMotherName formControlName="conductorMotherName" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('conductorMotherName'))"
              ></app-validation-error>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 mb-4">
              <div appearance="outline" class="col pt-2">
                <mat-label>{{ 'authorizedSignatureUpload' | translate }}</mat-label>
                <input
                  type="file"
                  style="display: none"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (change)="onAuthorizedSignatureDocAttachmentSelected($event)"
                  #authorizedSignatureFile
                />
                <button
                  mat-mini-fab
                  color="primary"
                  class="mx-1"
                  type="button"
                  (click)="authorizedSignatureFileUploadElem.nativeElement.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
                <mat-label *ngFor="let file of authorizedSignatureDocs">
                </mat-label>
              </div>

              <div class="col-md-6">
                <mat-label *ngFor="let file of authorizedSignatureDocs">
                  <div *ngIf="file && file?.type !== 'application/pdf'">
                    <img
                      class="img-thumbnail"
                      [src]="file.url"
                      style="width: auto; height: 100px"
                      (click)="onViewAttachment(file.url)"/>
                    <mat-icon (click)="clearSelectedAuthorizedDocAttachment()">cancel</mat-icon>
                </div>

                  <div *ngIf="file && file?.type === 'application/pdf'">
                    <mat-icon
                      style="font-size: 50px; color: gray; margin-right: 5px; margin-top:10px"
                      (click)="onViewAttachment(file.url)"
                    >picture_as_pdf</mat-icon>
                    <mat-icon (click)="clearSelectedAuthorizedDocAttachment()">cancel</mat-icon>
                </div>

            </mat-label>

              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <mat-card class="mt-3">
      <mat-card-title>{{ 'primaryDocument' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="row">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>{{ 'previousDocuments' | translate }}</mat-label>
                <mat-select
                  formControlName="primaryDocs"
                  [value]="senderPrimaryDoc?.id"
                  (selectionChange)="
                    onCustomerDocSelected($event.value, 'primary')
                  "
                >
                  <mat-option
                    *ngFor="let doc of senderPrimaryDocs"
                    [value]="doc.id"
                    >{{ doc.displayName }}
                  </mat-option>
                </mat-select>
                <mat-icon
                  *ngIf="senderPrimaryDoc"
                  class="clear_document"
                  (click)="resetDoc('primary')"
                  matTooltip="Clear selected document"
                  >cancel</mat-icon
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>{{ 'primaryDocTypes' | translate }}</mat-label>
                <mat-select
                  formControlName="primaryDocType"
                  (selectionChange)="onPrimaryDocChange($event)"
                >
                  <mat-option
                    *ngFor="let primaryDocType of primaryDocTypes"
                    [value]="primaryDocType"
                  >
                    {{ primaryDocType.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col" *ngIf="requiredFields?.documentNumberIsRequired">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ (isPassportDocTypeSelected ? 'passportNumber' : 'docNumber') | translate }}:</mat-label>
                  <input
                    matInput
                    #primaryDocNumber
                    formControlName="primaryDocNumber"
                    (focusout)="onFocusout($event)"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('primaryDocNumber'))"
                ></app-validation-error>
              </div>
              <div class="col" *ngIf="requiredFields?.documentIssuerIsRequired">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'primaryDocIssuedBy' | translate }}:</mat-label>
                  <input
                    matInput
                    #primaryDocIssuedBy
                    formControlName="primaryDocIssuedBy"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('primaryDocIssuedBy'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row">
              <div *ngIf="requiredFields?.idNumberIsRequired" class="col">
                <mat-form-field appearance="outline" class="col-md-12 col-sm-12">
                  <mat-label>{{ 'idNumber' | translate }}:</mat-label>
                  <input
                    matInput
                    #idNumber
                    formControlName="idNumber"
                    (focusout)="onFocusout($event)"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('idNumber'))"
                ></app-validation-error>
              </div>

              <div *ngIf="requiredFields?.serialNumberIsRequired" class="col">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'serialNumber' | translate }}:</mat-label>
                  <input
                    matInput
                    #serialNumber
                    formControlName="serialNumber"
                    (focusout)="onFocusout($event)"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('serialNumber'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.issueDateIsRequired">
                <mat-label>{{ 'primaryDocIssueDate' | translate }}</mat-label>
                <input
                  matInput
                  [max]="today"
                  [matDatepicker]="primaryDocIssueDate"
                  formControlName="primaryDocIssueDate"
                />
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="primaryDocIssueDate"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  startView="month"
                  #primaryDocIssueDate
                ></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.expiryDateIsRequired">
                <mat-label>{{ 'primaryDocExpiryDate' | translate }}</mat-label>
                <input
                  matInput
                  [min]="today"
                  [matDatepicker]="primaryDocExpiryDate"
                  formControlName="primaryDocExpiryDate"
                />
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="primaryDocExpiryDate"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  startView="month"
                  #primaryDocExpiryDate
                ></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="row mt-2">
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.motherNameIsRequired">
                <mat-label>{{ 'motherName' | translate }}:</mat-label>
                <input matInput #motherName formControlName="motherName" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('motherName'))"
              ></app-validation-error>

              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.nationalityIsRequired">
                <mat-label>{{ 'nationality' | translate }}</mat-label>
                <mat-select formControlName="senderNationality">
                  <mat-option
                    *ngFor="let country of allCountries"
                    [value]="country"
                  >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.fatherNameIsRequired">
                <mat-label>{{ 'fatherName' | translate }}:</mat-label>
                <input matInput #fatherName formControlName="fatherName" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('fatherName'))"
              ></app-validation-error>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div appearance="outline" class="col pt-2">
                <input
                  type="file"
                  style="display: none"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (change)="onDocAttachmentSelected($event)"
                  #primaryDocFile
                />
                <button
                  mat-mini-fab
                  color="primary"
                  class="mx-1"
                  type="button"
                  (click)="primaryDocFileElem.nativeElement.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
                <mat-label *ngFor="let file of docFile"
                  >{{ file?.name }}<span *ngIf="file?.name">, </span></mat-label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-2" *ngFor="let file of docFile; let i = index">
                <div *ngIf="file && file?.type !== 'application/pdf'">
                  <img
                    class="img-thumbnail"
                    [src]="file.url"
                    style="width: auto; height: 100px"
                    (click)="onViewAttachment(file.url)"
                  />
                  <div>
                    <mat-icon
                      *ngIf="!senderPrimaryDoc"
                      (click)="clearSelectedDocAttachment(file)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
                <div *ngIf="file && file?.type === 'application/pdf'">
                  <mat-icon
                    style="font-size: 50px; color: gray; margin-right: 50px"
                    (click)="onViewAttachment(file.url)"
                    >picture_as_pdf</mat-icon
                  >
                  <div>
                    <mat-icon
                      *ngIf="!senderPrimaryDoc"
                      (click)="clearSelectedDocAttachment(file)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- <div class="col-md-6">
        <mat-card>
          <mat-card-title>{{'secondaryDocument' | translate}}</mat-card-title>
          <mat-card-content>
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'secondaryDocs' | translate}}</mat-label>
                <mat-select formControlName="secondaryDocs"
                  (selectionChange)="onCustomerDocSelected($event.value, 'secondary')">
                  <mat-option *ngFor="let doc of senderSecondaryDocs" [value]="doc.id">{{doc.displayName}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'secondaryDocTypes' | translate}}</mat-label>
                <mat-select formControlName="secondaryDocType" (selectionChange)="onSecondaryDocChange($event)">
                  <mat-option *ngFor="let secondaryDocType of secondaryDocTypes" [value]="secondaryDocType">
                    {{secondaryDocType.name}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'secondaryDocNumber' | translate}}:</mat-label>
                <input matInput #secondaryDocNumber formControlName="secondaryDocNumber">
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'secondaryDocIssueDate' | translate}}</mat-label>
                <input matInput [max]="today" [matDatepicker]="secondaryDocIssueDate"
                  formControlName="secondaryDocIssueDate">
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="secondaryDocIssueDate"></mat-datepicker-toggle>
                <mat-datepicker startView="multi-year" #secondaryDocIssueDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'secondaryDocExpiryDate' | translate}}</mat-label>
                <input matInput [min]="today" [matDatepicker]="secondaryDocExpiryDate"
                  formControlName="secondaryDocExpiryDate">
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="secondaryDocExpiryDate"></mat-datepicker-toggle>
                <mat-datepicker startView="multi-year" #secondaryDocExpiryDate></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{'secondaryDocIssuedBy' | translate}}:</mat-label>
                <input matInput #secondaryDocIssuedBy formControlName="secondaryDocIssuedBy">
              </mat-form-field>
            </div>
            <div class="row mb-3">
              <div appearance="outline" class="col pt-2">
                <input type="file" style="display: none;" accept=".png, .jpg, .jpeg, .pdf"
                  (change)="onDocAttachmentSelected('secondary', $event)" #secondaryDocFile>
                <button *ngIf="senderSecondaryDoc?.documentPaths?.length" mat-mini-fab color="primary" class="mx-1"
                  type="button" (click)="onViewAttachment(senderSecondaryDoc?.documentPaths)">
                  <mat-icon>search</mat-icon>
                </button>
                <button mat-mini-fab color="primary" class="mx-1" type="button"
                  (click)="secondaryDocFileElem.nativeElement.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
                <mat-label>{{senderSecondaryDocFile?.name || ('uploadAttachment' | translate)}}</mat-label>
              </div>
            </div>
            <div *ngIf="secondaryDocFileUrl">
            <img [src]="secondaryDocFileUrl" style="width:auto;height:100px;">
            </div>
          </mat-card-content>
        </mat-card>
      </div> -->
    <mat-card class="my-3">
      <mat-card-title>{{ 'receiverInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row mb-4">
              <div class="col-4">
                <div class="row">
                  <mat-label class="mb-1"
                    >{{ 'remittanceType' | translate }}:</mat-label
                  >
                  <mat-radio-group formControlName="remittanceType">
                    <mat-radio-button
                      class="ms-2"
                      *ngFor="let remittanceType of remittanceTypes"
                      [value]="remittanceType"
                    >
                      {{ remittanceType.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <mat-label class="mb-1"
                    >{{ 'remittanceSubtype' | translate }}:</mat-label
                  >
                  <mat-radio-group
                    (ngModelChange)="onRemittanceSubtypeChange($event)"
                    formControlName="remittanceSubType"
                  >
                    <mat-radio-button
                      class="ms-2"
                      *ngFor="
                        let remittanceSubType of selectedRemittanceType.remittanceSubTypes
                      "
                      [value]="remittanceSubType"
                      [checked]="
                        remittanceSubType.id === selectedRemittanceSubType.id
                      "
                    >
                      {{ remittanceSubType.name }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="ebirSelected">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'ebirrOptions' | translate }}</mat-label>
                <mat-select
                  formControlName="ebirrOptions"
                  (selectionChange)="onEbirrOptionsChange($event.value)"
                >
                  <mat-option value="Bank">
                    {{ 'EbirrBank' | translate }}
                  </mat-option>
                  <mat-option value="Wallet">
                    {{ 'EbirrWallet' | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'bankOrWallet' | translate }}</mat-label>
                <mat-select formControlName="bankOrWallet">
                  <mat-option
                    *ngFor="let option of partialEbirrOptions"
                    [value]="option"
                  >
                    {{ option.bankName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col">
                <ng-select
                  formControlName="receivingCountry"
                  appearance="fill"
                  placeholder="{{ 'receivingCountry' | translate }}"
                  (change)="onReceivingCountryChange($event.id)"
                  [clearable]="false"
                >
                  <ng-option
                    *ngFor="let country of payingCountries"
                    [value]="country"
                  >
                    <div title="{{ country.name }}">{{ country.name }}</div>
                  </ng-option>
                </ng-select>
              </div>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'receivers' | translate }}</mat-label>
                <mat-select
                  formControlName="existingReceivers"
                  (selectionChange)="onExistingReceiverSelected($event)"
                >
                  <mat-option
                    *ngFor="let receiver of existingReceivers"
                    [value]="receiver"
                  >
                    {{
                      receiver.firstName +
                        ' ' +
                        receiver.lastName +
                        ' - ' +
                        receiver.mobilePhone
                    }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="col">
                <div class="row">
                  <mat-form-field appearance="outline" class="col">
                    <mat-label>{{ 'mobile' | translate }}:</mat-label>
                    <input
                      matInput
                      #receiverMobilePhone
                      formControlName="receiverMobilePhone"
                    />
                  </mat-form-field>
                  <div
                    *ngIf="
                      this.selectedRemittanceSubType.isVerificationRequired
                    "
                    class="col-3 mt-3"
                  >
                    <i
                      *ngIf="isReceiverVerified"
                      class="fa-solid fa-circle-check"
                      style="font-size: 32px; color: green"
                    ></i>
                    <button
                      *ngIf="!isReceiverVerified"
                      mat-raised-button
                      class="me-2 md-accent md-hue-2"
                      color="warn"
                      type="button"
                      (click)="onValidateReceiverInfo()"
                    >
                      {{ 'verify' | translate }}
                    </button>
                  </div>
                  <app-validation-error
                    [control]="$any(form.get('receiverMobilePhone'))"
                  ></app-validation-error>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'firstName' | translate }}:</mat-label>
                  <input
                    matInput
                    #receiverFirstName
                    formControlName="receiverFirstName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('receiverFirstName'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'middleName' | translate }}:</mat-label>
                  <input
                    matInput
                    #receiverMiddleName
                    formControlName="receiverMiddleName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('receiverMiddleName'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'lastName' | translate }}:</mat-label>
                  <input
                    matInput
                    #receiverLastName
                    formControlName="receiverLastName"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('receiverLastName'))"
                ></app-validation-error>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <p class="h6">{{ 'purposeAndRelationship' | translate }}</p>
          <mat-form-field appearance="fill" class="col-md-4">
            <mat-label>{{ 'purpose' | translate }}</mat-label>
            <mat-select formControlName="purpose">
              <mat-option *ngFor="let purpose of purposes" [value]="purpose">{{
                purpose.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-4">
            <mat-label>{{ 'relationship' | translate }}</mat-label>
            <mat-select formControlName="relationship">
              <mat-option
                *ngFor="let relationship of relationships"
                [value]="relationship"
                >{{ relationship.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>{{ 'paymentInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>{{ 'tellerAccount' | translate }}</mat-label>
                <mat-select formControlName="tellerAccount">
                  <mat-option
                    *ngFor="let tellerAccount of tellerAccounts"
                    [value]="tellerAccount"
                  >
                    {{ tellerAccount.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>{{ 'paymentType' | translate }}</mat-label>
                <mat-select formControlName="paymentType">
                  <mat-option
                    *ngFor="let paymentType of paymentTypes"
                    [value]="paymentType"
                  >
                    {{ paymentType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <p class="h6">{{ 'amountInformation' | translate }}</p>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'amount' | translate
                    }}{{ this.sendingAccountCurrency }}:</mat-label
                  >
                  <input
                    matInput
                    #sendingAmount
                    formControlName="sendingAmount"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('sendingAmount'))"
                ></app-validation-error>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'commission' | translate
                    }}{{ this.sendingAccountCurrency }}:</mat-label
                  >
                  <input
                    matInput
                    #sendingCommission
                    formControlName="sendingCommission"
                    (keyup)="onCommissionChange()"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('sendingCommission'))"
                ></app-validation-error>
              </div>
             
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'totalAmount' | translate
                    }}{{ this.sendingAccountCurrency }}:</mat-label
                  >
                  <input
                    matInput
                    #totalSendingAmount
                    formControlName="totalSendingAmount"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('totalSendingAmount'))"
                ></app-validation-error>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'receivingAmount' | translate }}:</mat-label>
                  <input
                    matInput
                    #receivingAmount
                    formControlName="receivingAmount"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('receivingAmount'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="fill" class="col-12">
                  <mat-label>{{ 'receivingCurrency' | translate }}</mat-label>
                  <mat-select formControlName="receivingCurrency">
                    <mat-option
                      *ngFor="let currency of receivingCountryCurrencies"
                      [value]="currency.id"
                    >
                      {{ currency.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>{{ 'exchangeRate' | translate }}:</mat-label>
                <input
                  matInput
                  #sendingExchangeRate
                  readonly
                  formControlName="sendingExchangeRate"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label
                  >{{ 'currencyToCurrencyRate' | translate }}:</mat-label
                >
                <input
                  matInput
                  #currencyToCurrencyRate
                  readonly
                  formControlName="currencyToCurrencyRate"
                />
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'amount' | translate }}{{ ' (USD)' }}:</mat-label
                  >
                  <input matInput #amount formControlName="amount" readonly />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'commission' | translate }}{{ ' (USD)' }}:</mat-label
                  >
                  <input
                    matInput
                    #commission
                    formControlName="commission"
                    readonly
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'amount' | translate
                    }}{{ this.sendingBranchCurrency }}:</mat-label
                  >
                  <input
                    matInput
                    #sendingBranchAmount
                    formControlName="sendingBranchAmount"
                    readonly
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label
                    >{{ 'commission' | translate
                    }}{{ this.sendingBranchCurrency }}:</mat-label
                  >
                  <input
                    matInput
                    #sendingBranchCommission
                    formControlName="sendingBranchCommission"
                    readonly
                  />
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <p class="h6">{{ 'comments' | translate }}</p>
              <mat-form-field class="w-100 mt-2 col" appearance="outline">
                <mat-label>{{ 'comments' | translate }}</mat-label>
                <textarea matInput formControlName="senderComments"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-end my-3">
      <button
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
        (click)="resetForm()"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/remittances"
      >
        {{ 'back' | translate }}
      </button>
      <button mat-raised-button type="submit" color="primary">
        {{ 'addRemittance' | translate }}
      </button>
    </div>
    <!--[disabled]="!form.valid"-->
  </form>
</app-form-container>
