import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Utilities } from '../_common/utils';
import {AttachmentsPopupComponent} from "../customer/attachmentsPopup.component";
import {RemittanceReceiptPopupComponent} from "../remittance/remittanceReceiptPopup.component";
import {RemittanceService} from "../remittance/data/remittance.service";

@Component({
  selector: 'app-customer-report-popup',
  templateUrl: './customerReportPopup.component.html',
  styleUrls: ['./reportStyles.css'],
})
export class CustomerReportPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<CustomerReportPopupComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private remittanceService: RemittanceService
  ) {}

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const table = document.getElementById('customerReport');
    const originalWs: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

    const clonedWs: XLSX.WorkSheet = { ...originalWs };
    const range = XLSX.utils.decode_range(<string>clonedWs['!ref']);
    clonedWs['!ref'] = XLSX.utils.encode_range(range);

    for (let i = 0; i <= range.e.r; i++) {
      const cellRef = XLSX.utils.encode_cell({ r: i, c: range.e.c });
      delete clonedWs[cellRef];
    }

    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, clonedWs, 'Sheet1');

    XLSX.writeFile(
      wb,
      'Customer report on ' +
      this.convertDateToLocaleString(new Date(), false) +
      '.xlsx',
    );
  }

  onViewAttachment(attachments: string[] | undefined) {
    this.dialog.open(AttachmentsPopupComponent, {
      width: '1000px',
      data: attachments,
    });
  }

  remittanceReceipts(remittanceId: number) {
    this.remittanceService.getRemittanceDocuments(remittanceId).subscribe(remitDocs => {

      const dialogRef = this.dialog.open(RemittanceReceiptPopupComponent, {
        disableClose: true,
        width: '850px',
        data: {
          remittanceDocuments: remitDocs,
        }
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') return;

      });

    });
  }

}
