<h1 mat-dialog-title>{{ 'attachments' | translate }}</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-12" *ngFor="let url of imageURL">
      <img
        *ngIf="!isPdfFile"
        [src]="url"
        class="w-auto h-600 shadow-1-strong img-thumbnail rounded mb-4"
      />
      <iframe
        *ngIf="isPdfFile"
        width="100%"
        height="480px"
        [src]="url"
      ></iframe>
    </div>
  </div>
</div>
