import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import {
  BranchesService,
  GetAccountsSearchFilter,
} from './data/branches.service';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { GetBranchesDto, SearchAccountsDto } from './data/dto/branches.dto';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import {SnackBarService} from "../_common/snackBar.service";
@Component({
  selector: 'app-branch-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class AccountsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  form!: FormGroup;
  permissions = Permissions.accountsManagement.accounts;
  branchesList: GetBranchesDto[] = [];
  branches: GetBranchesDto[] = [];
  groups: LookupDto[] = [];
  types: LookupDto[] = [];
  loading = false;

  accounts = new MatTableDataSource<SearchAccountsDto>();
  displayedColumns: string[] = [
    'id',
    'name',
    'group',
    'type',
    'currency',
    'status',
    'actions',
  ];
  resultsLength = 0;
  isRegional: boolean | undefined;
  isAdmin: boolean | undefined;

  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private branchesService: BranchesService,
    public authService: AuthService,
    public dialog: MatDialog,
    public radio: MatRadioModule,
    private snackBar: SnackBarService
  ) {}

  ngOnInit() {
    this.router.data.subscribe(({ branches, groups }) => {
      this.branchesList = branches;
      this.branches = branches.map(function (obj: GetBranchesDto) {
        return { id: obj.id, code: obj.code, title: obj.title };
      });
      this.groups = groups.sort((a: LookupDto, b: LookupDto) =>
        a.name.localeCompare(b.name),
      );
    });

    const userBranchId = this.authService.currentUserValue?.branch.id;

    this.form = this.fb.group({
      branchId: [
        this.branches.find((branch) => branch.id === userBranchId)?.id,
      ],
      groupId: [null],
      typeId: [null],
      accountNumber: [null],
      isActive: ['active'],
    });
    this.branchesService.getAccounts.subscribe(({ appliedFilters, data }) => {
      this.accounts.data = data;
      this.accounts.paginator = this.paginator;
      this.resultsLength = data.length;
    });
    this.isAdmin = this.authService.currentUserValue?.isAdmin;
    this.isRegional = this.authService.currentUserValue?.isRegional;
  }

  getAccountType(branch: any) {
    this.branchesService
      .getAccountLevels(branch.value)
      .subscribe((accountTypes) => {
        this.types = accountTypes;
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }
  onSubmit() {
    const { branchId, groupId, typeId, accountNumber, isActive } =
      this.form.value;
    const filters: GetAccountsSearchFilter = {};
    filters.branchId = branchId ?? '';
    filters.groupId = groupId ?? '';
    filters.typeId = typeId ?? '';
    filters.accountNumber = accountNumber ?? '';
    filters.isActive = isActive == 'active' ? true : false;
    this.branchesService.searchAccounts(filters);
  }
}
