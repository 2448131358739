<app-index-container label="{{ 'Blacklisted Customers' | translate }}">
    <form
      form
      class="container-fluid flex-column p-0"
      name="form"
      [formGroup]="form"
      novalidate
      (submit)="onSubmit()"
    >
      <div class="row mb-0">
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>{{ 'mobile' | translate }}:</mat-label>
            <input matInput #username formControlName="mobilePhone" />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>{{ 'name' | translate }}:</mat-label>
            <input matInput #customerName formControlName="customerName" />
          </mat-form-field>
        </div>
      </div>
       <div class="row mt-0">
        <!-- here -->
      </div> 
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-end">
            <!-- Search button -->
            <button
              mat-raised-button
              type="submit"
              color="primary"
            >
              <i class="fa-solid fa-magnifying-glass"></i>
              {{ 'search' | translate }}
            </button>
            <button
              mat-raised-button
              type="button"
              (click)="addBlacklist()"
              *ngIf="
              authService.isUserAuthorized([
                permissions.CREATE_BLACKLIST,
                permissions.VIEW_BLACKLIST
              ])
            "
              color="primary"
              class="ml-2"
              style="margin-left: 10px;"
            >
              <i class="fa-solid fa-user-slash"></i>
              {{ 'Add Blacklist' | translate }}
            </button>
          </div>
        </div>
      </div>      
    </form>
    <table table mat-table [dataSource]="data" class="table">
      <!-- Number Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
        <td
          [style.color]="row.localBlacklistGuid ? 'red' : 'null'"
          mat-cell
          *matCellDef="let row"
        >
          {{ row.name }}
        </td>
      </ng-container>
      <!-- Last Name Column -->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef>{{ 'mobile' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.mobile }}</td>
      </ng-container>
      <!-- blocked Column -->
      <ng-container matColumnDef="blocked">
        <th mat-header-cell *matHeaderCellDef>{{ 'blocked' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.Blocked }}</td>
      </ng-container>
      <!-- approvalRequired Column -->
      <ng-container matColumnDef="approvalRequired">
        <th mat-header-cell *matHeaderCellDef>{{ 'approvalRequired' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.ApprovalRequired }}</td>
      </ng-container>
      <!-- requestedBy Column -->
      <ng-container matColumnDef="requestedBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'requestedBy' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.requestedBy }}</td>
      </ng-container>
      <!-- modifiedBy Column -->
      <ng-container matColumnDef="modifiedBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'modifiedBy' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.modifiedBy }}</td>
      </ng-container>

       <!-- reasons Column -->
       <ng-container matColumnDef="reasons">
        <th mat-header-cell *matHeaderCellDef>{{ 'reasons' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.reasons }}</td>
      </ng-container>

         <!-- comments Column -->
         <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef>{{ 'comments' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.comments || 'ok' }}</td>
        </ng-container>

          <!-- created date Column -->
          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Createdd Date' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.createdDate,  'datetime') }}</td>
          </ng-container>

          <!-- modified date Column -->
          <ng-container matColumnDef="modifiedDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Modified Date' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.modifiedDate,  'datetime')}}</td>
          </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <div class="action-button-menu">
            <a
              mat-menu-item
              *ngIf="
                authService.isUserAuthorized([
                  permissions.UPDATE_BLACKLIST,
                  permissions.VIEW_BLACKLIST
                ])
              "
              color="primary"
              (click)="updateBlacklistCustomer(row.id)"
            >
              <mat-icon>{{
                authService.isUserAuthorized(permissions.UPDATE_BLACKLIST)
                  ? 'edit'
                  : 'visibility'
              }}</mat-icon>
              {{
                (authService.isUserAuthorized(permissions.UPDATE_BLACKLIST)
                  ? 'edit'
                  : 'view'
                ) | translate
              }}
            </a>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      paginator
      [pageSizeOptions]="[10, 25, resultsLength]"
      [pageSize]="10"
    ></mat-paginator>
  </app-index-container>
  