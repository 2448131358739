import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RolesService } from './roles.service';
import { PermissionsDto } from './dto/role/permissions.dto';

@Injectable()
export class PermissionsResolver implements Resolve<any> {
  constructor(private rolesService: RolesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<PermissionsDto[]> {
    return this.rolesService.permissions();
  }
}
