import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SearchTicketDto } from './data/dto/support.dto';
import { Permissions } from '../_common/enums/permissions.enums';
import { SupportService } from './data/support.service';
import { AuthService } from '../auth/data/auth.service';
import { LookupDto } from '../_common/data/dto/api.dto';
import { ActivatedRoute } from '@angular/router';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import {MatSelect} from "@angular/material/select";
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class TicketsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.ticketManagement.tickets;
  loading = false;
  resultsLength = 0;
  displayedColumns: string[] = [
    'number',
    'title',
    'status',
    'branch',
    'assignee',
    'actions',
  ];
  data = new MatTableDataSource<SearchTicketDto>();

  branches: GetBranchesDto[] = [];
  assignees: LookupDto[] = [];
  allAssignees: LookupDto[] = [];
  statuses: LookupDto[] = [];
  private selected: string = '';
  @ViewChild(MatSelect) select: MatSelect | undefined;
  selectedStatuses: number[] = [23,24];
  constructor(
    private fb: UntypedFormBuilder,
    private supportService: SupportService,
    public authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ branches, assignees, statuses }) => {
      this.branches = branches;
      // @ts-ignore
      this.selected = this.authService.currentUserValue.branch?.id;
      this.assignees = assignees.filter((assigne : any) => assigne.parentId == this.selected);
      this.allAssignees = assignees;
      this.statuses = statuses;


      this.form = this.fb.group({
        branchId: [null],
        assigneeId: [null],
        statusId: [[statuses[0].id]],
        onlyMyTickets:[ false ]
      });
      this.loadData();
      const currentUser = this.authService.currentUserValue;
      const me = this.assignees.find((f) => f.id == currentUser?.id);
      this.form.get('assigneeId')?.setValue(me?.id);
      this.form.get('branchId')?.setValue(this.selected);
      this.form.get('statusId')?.setValue([statuses[0].id,statuses[1].id])
    });
  }

  onBranchSelected(branchId: number) {
    this.assignees = this.allAssignees.filter((f) => f.parentId == branchId);
  }

  onAssigneeSelected(assigneeId: number) {
    const assignee = this.assignees.find((f) => f.id == assigneeId);
    this.form.get('branchId')?.setValue(assignee?.parentId);
  }

  loadData() {
    this.supportService.tickets.subscribe(({ tickets, appliedFilters }) => {
      this.loading = false;
      this.data.data = tickets;
      this.data.paginator = this.paginator;
      this.resultsLength = tickets.length;

      this.form.controls['branchId'].setValue(appliedFilters?.branchId);
      this.form.controls['assigneeId'].setValue(appliedFilters?.assigneeId);
      this.form.controls['statusId'].setValue(appliedFilters?.statusId);
    });
  }

  onSubmit() {
    const filters = {...this.form.value, onlyMyTickets: this.form.value.onlyMyTickets ? 1 : 0};
    this.supportService.searchTickets(filters);
  }

  onChangeMyTickets(event: MatCheckboxChange) {
    if(event.checked) {
      this.form.controls['branchId'].disable();
      this.form.controls['assigneeId'].disable();
    } else {
      this.form.controls['branchId'].enable();
      this.form.controls['assigneeId'].enable();

      this.form.controls['assigneeId'].setValidators([Validators.required]);
      this.form.controls['assigneeId'].updateValueAndValidity();

      this.form.controls['branchId'].setValidators([Validators.required]);
      this.form.controls['branchId'].updateValueAndValidity();
    }
  }
}
