<app-form-container [label]="label | translate">
  <div form class="container-fluid p-0">
    <mat-stepper
      #stepper
      [linear]="addMode"
      [orientation]="(stepperOrientation | async)!"
    >
      <mat-step
        [stepControl]="branchInfoForm"
        label="{{ 'branchInfo' | translate }}"
      >
        <form
          class="container-fluid flex-column p-0"
          name="branchInfoForm"
          [formGroup]="branchInfoForm"
          novalidate
          (submit)="onSubmit('branchInfo')"
        >
          <div class="row">
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'country' | translate }}:</mat-label>
              <mat-select
                #countryId
                [disabled]="!addMode"
                formControlName="countryId"
                (selectionChange)="onCountryChange($event.value)"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.id"
                >
                  {{ country.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'city' | translate }}:</mat-label>
              <mat-select #cityId formControlName="cityId">
                <mat-option *ngFor="let city of branchCities" [value]="city.id">
                  {{ city.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'parent' | translate }}:</mat-label>
              <mat-select
                #parentId
                [disabled]="!addMode"
                formControlName="parentId"
                (selectionChange)="onParentChange($event.value)"
              >
                <mat-option
                  *ngFor="let parent of branchParents"
                  [value]="parent.id"
                >
                  {{
                    parent.title === 'CPO Branch'
                      ? ('hqBranch' | translate)
                      : parent.code + ' - ' + parent.title
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'type' | translate }}:</mat-label>
              <mat-select
                #typeId
                [disabled]="!addMode"
                formControlName="typeId"
              >
                <mat-option
                  *ngFor="let branchType of branchTypes"
                  [value]="branchType.id"
                >
                  {{ branchType.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="fill" class="col-12">
                <mat-label>{{ 'code' | translate }}:</mat-label>
                <input
                  matInput
                  #code
                  formControlName="code"
                  [readonly]="!addMode"
                />
                <!-- <span *ngIf="addMode" matPrefix>{{ countryIso2 }}&nbsp;</span> -->
              </mat-form-field>
              <app-validation-error
                [control]="$any(branchInfoForm.get('code'))"
              ></app-validation-error>
            </div>
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'baseCurrency' | translate }}:</mat-label>
              <mat-select
                #baseCurrencyId
                [disabled]="!addMode"
                formControlName="baseCurrencyId"
              >
                <mat-option
                  *ngFor="let currency of branchCurrenciesLookup"
                  [value]="currency.id"
                >
                  {{ currency.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'title' | translate }}:</mat-label>
                <input matInput #title formControlName="title" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(branchInfoForm.get('title'))"
              ></app-validation-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'phone' | translate }}:</mat-label>
                <input matInput #phone formControlName="phone" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(branchInfoForm.get('phone'))"
              ></app-validation-error>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'branchIp' | translate }}:</mat-label>
                <input matInput #branchIp formControlName="branchIp" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(branchInfoForm.get('branchIp'))"
              ></app-validation-error>
            </div>
            <div class="col-md-4 mt-3" *ngIf="!addMode && canActivate">
              <mat-label>{{ 'status' | translate }}:</mat-label>
              <mat-radio-group formControlName="branchStatus">
                <mat-radio-button value="active">{{
                  'active' | translate
                }}</mat-radio-button
                >&nbsp;
                <mat-radio-button value="inactive">{{
                  'inactive' | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              mat-raised-button
              class="me-2"
              color="secondary"
              type="reset"
              #branchesBtn
              routerLink="/branches"
            >
              {{ 'branches' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="warn"
              #branchResetBtn
              type="reset"
            >
              {{ 'reset' | translate }}
            </button>
            <button
              mat-raised-button
              *ngIf="
                !addMode &&
                authService.isUserAuthorized(permissions.UPDATE_BRANCH)
              "
              type="submit"
              color="primary"
            >
              {{ 'updateBranchInfo' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              color="primary"
              matStepperNext
            >
              {{ 'next' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="branchLimitForm"
        label="{{ 'Branch Limit' | translate }}"
      >
        <form
          class="container-fluid flex-column p-0"
          name="branchLimitForm"
          [formGroup]="branchLimitForm"
          novalidate
          (submit)="onSubmit('setLimit')"
        >
          <div *ngIf="!addMode" class="row">
            <mat-label
              >{{ 'childrenLimit' | translate }}:
              {{ branch?.childrenLimit || 0 }}</mat-label
            >
          </div>
          <div *ngIf="!addMode" class="row">
            <mat-label
              >{{ 'overallLimit' | translate }}: {{ overallLimit }}</mat-label
            >
          </div>
          <div class="row">
            <mat-form-field appearance="outline" class="col col-md-4">
              <mat-label>{{ 'branchLimit' | translate }}:</mat-label>
              <input
                matInput
                #setBranchLimit
                formControlName="limit"
                ng-model-options="{ debounce: 1000 }"
                [readonly]="usersOwnBranch"
              />
            </mat-form-field>
          </div>
          <div *ngIf="!addMode" class="row">
            <mat-label
              >{{ 'newOverallLimit' | translate }}:
              {{ newOverallLimit }}</mat-label
            >
          </div>
          <div class="row">
            <mat-label
              >{{ 'parentBranchLimit' | translate }}:
              {{ parentBranchLimitAndCommConfigs?.limit }}</mat-label
            >
          </div>
          <div class="d-flex justify-content-end">
            <button
              *ngIf="!addMode"
              mat-raised-button
              class="me-2"
              color="secondary"
              type="reset"
              routerLink="/branches"
            >
              {{ 'branches' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="warn"
              #limitResetBtn
              type="reset"
            >
              {{ 'reset' | translate }}
            </button>
            <button
              mat-raised-button
              *ngIf="
                !addMode &&
                authService.isUserAuthorized(permissions.UPDATE_BRANCH) &&
                !usersOwnBranch
              "
              [disabled]="
                (branchLimitForm.get('limit')?.dirty ||
                  branchLimitForm.get('limit')?.touched) &&
                branchLimitForm.get('limit')?.invalid
              "
              type="submit"
              color="primary"
            >
              {{ 'updateLimit' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="secondary"
              matStepperPrevious
            >
              {{ 'back' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              color="primary"
              matStepperNext
              [disabled]="
                (branchLimitForm.get('limit')?.dirty ||
                  branchLimitForm.get('limit')?.touched) &&
                branchLimitForm.get('limit')?.invalid
              "
            >
              {{ 'next' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="commissionConfigsForm"
        label="{{ 'commissionConfigs' | translate }}"
      >
        <form
          class="container-fluid flex-column p-0"
          name="commissionConfigsForm"
          [formGroup]="commissionConfigsForm"
          novalidate
          (submit)="onSubmit('commissionConfigs')"
        >
          <div class="row">
            <mat-form-field appearance="outline" class="col">
              <mat-label>{{ 'commissionPolicy' | translate }}:</mat-label>
              <mat-select
                #commissionPolicy
                formControlName="commissionPolicyId"
                [disabled]="usersOwnBranch"
              >
                <mat-option
                  *ngFor="let policy of branchCommissionPolicies"
                  [value]="policy.id"
                >
                  {{ policy.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'sendCashCommission' | translate }}:</mat-label>
                <input
                  matInput
                  #sendCashCommission
                  formControlName="sendCashCommission"
                  [readonly]="usersOwnBranch"
                />
              </mat-form-field>
              <app-validation-error
                [control]="
                  $any(commissionConfigsForm.get('sendCashCommission'))
                "
              ></app-validation-error>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'payCashCommission' | translate }}:</mat-label>
                <input
                  matInput
                  #payCashCommission
                  formControlName="payCashCommission"
                  [readonly]="usersOwnBranch"
                />
              </mat-form-field>
              <app-validation-error
                [control]="$any(commissionConfigsForm.get('payCashCommission'))"
              ></app-validation-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <mat-label
                >{{ 'parentSendCashCommission' | translate }}:</mat-label
              >
              <mat-label>{{
                parentBranchLimitAndCommConfigs?.sendCashCommission
              }}</mat-label>
            </div>
            <div class="col-md-6">
              <mat-label
                >{{ 'parentPayCashCommission' | translate }}:</mat-label
              >
              <mat-label>{{
                parentBranchLimitAndCommConfigs?.payCashCommission
              }}</mat-label>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              *ngIf="!addMode"
              mat-raised-button
              class="me-2"
              color="secondary"
              type="reset"
              routerLink="/branches"
            >
              {{ 'branches' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="warn"
              #commissionResetBtn
              type="reset"
            >
              {{ 'reset' | translate }}
            </button>
            <button
              mat-raised-button
              *ngIf="
                !addMode &&
                authService.isUserAuthorized(permissions.UPDATE_BRANCH) &&
                !usersOwnBranch
              "
              [disabled]="!commissionConfigsForm.valid"
              type="submit"
              color="primary"
            >
              {{ 'updateBranchCommissions' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="secondary"
              matStepperPrevious
            >
              {{ 'back' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              color="primary"
              matStepperNext
            >
              {{ 'next' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        [stepControl]="branchConfigsForm"
        label="{{ 'branchConfigs' | translate }}"
      >
        <form
          class="container-fluid flex-column p-0"
          name="branchConfigsForm"
          [formGroup]="branchConfigsForm"
          novalidate
          (submit)="onSubmit('branchConfigs')"
        >
          <div class="row">
            <p class="mb-1">
              <strong>{{ 'commDiscountApproval' | translate }}</strong>
            </p>
            <div class="d-block ps-3">
              <mat-checkbox
                matInput
                class="mx-1"
                color="primary"
                formControlName="commissionChangeBranchApproval"
              >
                {{ 'branchApproval' | translate }}
              </mat-checkbox>
              <mat-checkbox
                matInput
                class="mx-1"
                color="primary"
                formControlName="commissionChangeParentApproval"
              >
                {{ 'parentApproval' | translate }}
              </mat-checkbox>
              <mat-checkbox
                matInput
                class="mx-1"
                color="primary"
                formControlName="commissionChangeCpoApproval"
              >
                {{ 'hqApproval' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="row mt-2">
            <p class="mb-1">
              <strong>{{ 'workingHours' | translate }}</strong>
            </p>
            <div class="d-block ps-3 row">
              <mat-form-field appearance="outline" class="col col-md-2">
                <mat-label>{{ 'openingHours' | translate }}:</mat-label>
                <input
                  matInput
                  #openingHours
                  formControlName="openingHours"
                  placeholder="09:00"
                  pattern="^([01]\d|2[0123]):(?:[012345]\d)"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col col-md-2">
                <mat-label>{{ 'closingHours' | translate }}:</mat-label>
                <input
                  matInput
                  #closingHours
                  formControlName="closingHours"
                  placeholder="18:00"
                  pattern="^([01]\d|2[0123]):(?:[012345]\d)"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="row mt-2">
            <p class="mb-1">
              <strong>{{ 'selectLanguage' | translate }}</strong>
            </p>
            <div class="d-block ps-3 row">
              <mat-radio-group formControlName="branchLang">
                <mat-radio-button value="en">{{
                  'english' | translate
                }}</mat-radio-button>
                <!-- <mat-radio-button value="tr">{{
                  'turkish' | translate
                }}</mat-radio-button> -->
              </mat-radio-group>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <button
              *ngIf="!addMode"
              mat-raised-button
              class="me-2"
              color="secondary"
              type="reset"
              routerLink="/branches"
            >
              {{ 'branches' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="warn"
              #configsResetBtn
              type="reset"
            >
              {{ 'reset' | translate }}
            </button>
            <button
              mat-raised-button
              *ngIf="
                !addMode &&
                authService.isUserAuthorized(permissions.UPDATE_BRANCH)
              "
              [disabled]="!branchConfigsForm.valid"
              type="submit"
              color="primary"
            >
              {{ 'updateBranchConfigs' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              class="me-2"
              color="secondary"
              matStepperPrevious
            >
              {{ 'back' | translate }}
            </button>
            <button
              *ngIf="addMode"
              mat-raised-button
              color="primary"
              [disabled]="!branchConfigsForm.valid"
              matStepperNext
            >
              {{ 'next' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step
        *ngIf="!addMode"
        label="{{ 'branchCurrencies' | translate }}"
        style="margin-top: 5px"
      >
        <app-index-container label="{{ 'branchCurrencies' | translate }}">
          <button
            headerActions
            *ngIf="
              authService.isUserAuthorized(permissions.UPDATE_BRANCH) &&
              isRegional
            "
            mat-raised-button
            color="primary"
            (click)="onBranchCurrencyClick()"
          >
            {{ 'addCurrencyRate' | translate }}
          </button>
          <table table mat-table [dataSource]="branchCurrencies" class="table">
            <!-- currency Column -->
            <ng-container matColumnDef="currency">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'currency' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.currency }}</td>
            </ng-container>
            <!-- exchangeRateType Column -->
            <ng-container matColumnDef="exchangeRateType">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'exchangeRateType' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.exchangeRateType }}</td>
            </ng-container>
            <!-- rateVariation Column -->
            <ng-container matColumnDef="rateVariation">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'rateVariation' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.rateVariation }}</td>
            </ng-container>
            <!-- minRate Column -->
            <ng-container matColumnDef="minRate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'minRate' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.minRate }}</td>
            </ng-container>
            <!-- normRate Column -->
            <ng-container matColumnDef="normRate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'normRate' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.normRate }}</td>
            </ng-container>
            <!-- maxRate Column -->
            <ng-container matColumnDef="maxRate">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'maxRate' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">{{ row.maxRate }}</td>
            </ng-container>
            <!-- active Column -->
            <ng-container matColumnDef="active">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'active' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                {{ (row.isActive ? 'active' : 'inactive') | translate }}
              </td>
            </ng-container>
            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'actions' | translate }}
              </th>
              <td mat-cell *matCellDef="let row">
                <button
                  mat-raised-button
                  *ngIf="
                    authService.isUserAuthorized([
                      permissions.UPDATE_BRANCH,
                      permissions.VIEW_BRANCH
                    ]) && isRegional
                  "
                  color="primary"
                  (click)="onBranchCurrencyClick(row.id)"
                >
                  {{
                    (authService.isUserAuthorized(permissions.UPDATE_BRANCH)
                      ? 'update'
                      : 'view'
                    ) | translate
                  }}
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="branchCurrenciesColumns"></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: branchCurrenciesColumns"
            ></tr>
          </table>
          <mat-paginator
            paginator
            [pageSizeOptions]="[10, 25, branchCurrencies.length]"
            [pageSize]="10"
          ></mat-paginator>
        </app-index-container>
      </mat-step>
    </mat-stepper>
  </div>
</app-form-container>
