<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'BsmvTaxRemittanceDetail' | translate }}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table id="bsmvReport" class="table table-bordered table-hover">
        <tbody *ngIf="data.bsmvReportDetails.length">
        <tr>
          <th>{{ '#' | translate }}</th>
          <th>{{ 'date' | translate }}</th>
          <th>{{ 'transactionId' | translate }}</th>
          <th>{{ 'remittanceAmount' | translate }}{{ '('+data.reportCurrency+')' }}</th>
          <th>{{ 'remittanceCommission' | translate }}{{ '('+data.reportCurrency+')' }}</th>
          <th>{{ 'bsmvAmount' | translate }}{{ '('+data.reportCurrency+')' }}</th>
          <ng-container *ngIf="data.reportCurrency !== 'TRY'">
            <th>{{ 'exchangeRate' | translate }}</th>
            <th>{{ 'remittanceAmount' | translate }}{{ '(TRY)' }}</th>
            <th>{{ 'remittanceCommission' | translate }}{{ '(TRY)' }}</th>
            <th>{{ 'bsmvAmount' | translate }}{{ '(TRY)' }}</th>
          </ng-container>
          <th>{{ 'totalAmount' | translate }}{{ '('+data.reportCurrency+')' }}</th>
          <th *ngIf="data.reportCurrency !== 'TRY'">{{ 'totalAmount' | translate }}{{ '(TRY)' }}</th>
          
        </tr>
        <tr *ngFor="let remittance of data.bsmvReportDetails; let i = index">
          <!-- Number Column -->
          <td>{{ i + 1 }}</td>
          <td class="text-nowrap">{{ remittance.date }}</td>
          <td class="text-nowrap">{{ remittance.code }}</td>
          <td class="text-nowrap">{{ remittance.originalAmount | number:'1.2-2' }}</td>
          <td class="text-nowrap">{{ remittance.originalCommission | number:'1.2-2' }}</td>
          <td class="text-nowrap">{{ remittance.originalBsmv | number:'1.2-2' }}</td>
          <ng-container *ngIf="data.reportCurrency !== 'TRY'">
            <td class="text-nowrap">{{ remittance.turkishExchangeRate | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ remittance.amountInLira | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ remittance.commissionInLira | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ remittance.bsmvInLira | number:'1.2-2' }}</td>
          </ng-container>
          <td class="text-nowrap">{{ remittance.totalInOriginal | number:'1.2-2' }}</td>
          <td class="text-nowrap" *ngIf="data.reportCurrency !== 'TRY'">{{ remittance.totalInTL | number:'1.2-2' }}</td>
        </tr>
        </tbody>
        <tbody>
          <tr class="table-borderless">
            <td colspan="3" class="text-center"><strong>{{'grandTotal' | translate}}</strong></td>
            <td class="text-nowrap">{{ data.totalOriginalAmount | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ data.totalOriginalCommission | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ data.totalOriginalBsmv | number:'1.2-2' }}</td>
            <ng-container *ngIf="data.reportCurrency !== 'TRY'">
              <td class="text-nowrap">{{ '' }}</td>
              <td class="text-nowrap">{{ data.totalAmountInTL | number:'1.2-2' }}</td>
              <td class="text-nowrap">{{ data.totalCommissionInTL | number:'1.2-2' }}</td>
              <td class="text-nowrap">{{ data.totalBsmvInTL | number:'1.2-2' }}</td>
            </ng-container>
            <td class="text-nowrap">{{ data.grandOriginalTotal | number:'1.2-2' }}</td>
            <td class="text-nowrap" *ngIf="data.reportCurrency !== 'TRY'">{{ data.grandTLTotal | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-right">
    <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
    <button
      mat-button
      class="btn btn-outline-primary"
      [useExistingCss]="true"
      printSectionId="report"
      ngxPrint
    >
      {{ 'print' | translate }}
    </button>
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
      {{ 'export' | translate }}
    </button>
  </div>
</div>

