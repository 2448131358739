import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CRUDResponseDto, LookupDto } from 'src/app/_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import {
  CreateComplianceConfigDto,
  GetComplianceConfigDto,
  UpdateComplianceConfigDto,
} from './dto/complianceConfiguration.dto';
import { TableTypes } from 'src/app/_common/enums/tableTypes.enums';
import { map } from 'rxjs/operators';

type ComplianceListFilter = {
  remittanceComplianceTypeId?: string;
  rcConfigId?: string;
} | null;

type GetCompliance = {
  complianceFilters: ComplianceListFilter;
  complianceConfigs: GetComplianceConfigDto[];
};

@Injectable()
export class ComplianceConfigurationService {
  private tablePendingChanges: string = '';
  private _complianceConfig = new BehaviorSubject<GetCompliance>({
    complianceFilters: null,
    complianceConfigs: [],
  });
  private compliancesObservable?: Observable<GetCompliance>;
  constructor(private httpService: HttpService) {}

  get complianceConfigs() {
    if (!this.compliancesObservable)
      this.compliancesObservable = this._complianceConfig.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.COMPLIANCE) {
            this.triggerSearchComplianceConfigs(resp.complianceFilters);
          }
          return resp;
        }),
      );

    return this.compliancesObservable;
  }

  createComplianceConfig(
    payload: CreateComplianceConfigDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/compliance/config/create',
      payload,
    );
  }

  updateComplianceConfig(
    payload: UpdateComplianceConfigDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('remittance/compliance/config/update', payload)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.COMPLIANCE;
          return resp;
        }),
      );
  }

  searchComplianceConfigs(filter: ComplianceListFilter = null) {
    return this.httpService.get<GetComplianceConfigDto[]>(
      'remittance/compliance/config/list',
      filter,
    );
  }

  triggerSearchComplianceConfigs(filter: ComplianceListFilter = null) {
    this.searchComplianceConfigs(filter).subscribe((configs) => {
      this.tablePendingChanges = '';
      this._complianceConfig.next({
        complianceFilters: filter,
        complianceConfigs: configs,
      });
    });
  }

  getComplianceConfigById(
    complianceConfigId: string,
  ): Observable<GetComplianceConfigDto[]> {
    return this.searchComplianceConfigs({
      rcConfigId: complianceConfigId,
    });
  }

  getComplianceTypes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('remittance/compliance/type/list');
  }

  searchSanctions(
    payload: any,
  ): Observable<any[any]> {
    return this.httpService.post<any>(
      'customer/searchsanctions',
      payload
    );
  }
}
