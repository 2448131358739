import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-attachments',
  templateUrl: './attachmentsPopup.component.html',
})
export class AttachmentsPopupComponent {
  imageURL: any[] = [];
  isPdfFile: Boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AttachmentsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      imageURL: [];
      isPdfFile: Boolean;
    },
    private sanitizer: DomSanitizer,
  ) {
    this.isPdfFile = data.isPdfFile;
    this.imageURL.push(data.imageURL);
  }
}
