<app-index-container label="{{ 'cities' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.ADD_CITY_LOOKUP)"
    mat-raised-button
    color="primary"
    (click)="addCityDialog()"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addCity' | translate }}
  </button>
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-6">
        <ng-select
          formControlName="countryId"
          appearance="fill"
          placeholder="{{ 'receivingCountry' | translate }}"
          [clearable]="false"
        >
          <ng-option *ngFor="let country of countries" [value]="country">
            <div title="{{ country.name }}">{{ country.name }}</div>
          </ng-option>
        </ng-select>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <button mat-raised-button type="submit" color="primary">
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>

    <!-- code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>{{ 'code' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>
    <!-- Country Column -->
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>{{ 'country' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.parentName }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_CITY_LOOKUP,
                permissions.VIEW_CITY_LOOKUP
              ])
            "
            color="primary"
            (click)="addCityDialog(row.parentId, row.id)"
          >
            <mat-icon>edit</mat-icon>{{ 'edit' | translate }}
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
