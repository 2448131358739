import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { AddRemittanceComponent } from './addRemittance.component';
import { RemittancesComponent } from './remittances.component';
import { PayRemittanceComponent } from './payRemittance.component';
import {
  CountriesResolver,
  PayingCountriesResolver,
  PaymentTypesResolver,
  RemittanceForPaymentResolver,
  RemittanceStatusesResolver,
  RemittanceSubTypesResolver,
  TellerAccountsResolver,
  RemittancePurposesResolver,
  RemittanceRelationshipsResolver,
  RemittanceForPaybackResolver,
} from './data/remittance.resolver';
import { CommissionConfigsComponent } from './commissionConfigs/commissionConfigs.component';
import { CommissionConfigDetailsComponent } from './commissionConfigs/commissionConfigDetails.component';
import {
  CommissionConfigRequestStatusResolver,
  CommissionConfigurationsResolver,
} from './data/commissionConfigurations.resolver';
import {
  CustomerDocumentTypesResolver,
  CustomerGenderResolver,
  CustomerOccupationsResolver,
  CustomerSourceOfIncomeResolver, CustomerTypesResolver,
} from '../customer/data/customer.resolver';
import {
  BranchesResolver,
  CitiesResolver,
  LocalitiesResolver,
} from '../branch/data/branches.resolver';
import { PaybackRemittanceComponent } from './paybackRemittance.component';
import { CommissionConfigsRequetsComponent } from './commissionConfigs/commissionConfigsRequests.component';
import { AmbiguousRemittancesComponent } from './ambiguousRemittances.component';
import { OrmComponent } from './orm.component';
import { FinsurvReportsComponent } from './finsurv-reports.component';
import { FinsurvTransactionsPopupComponent } from './finsurvTransactionsPopup.component';
import { FinsurvTransactionsComponent } from './finsurv-transactions.component';

const {
  remittanceManagement: {
    remittances,
    ambiguousRemittances,
    commissionConfiguration,
    commissionConfigurationRequests,
    finsurv, finsurvTransactions
  },
} = Permissions;

const routes: Routes = [
  {
    path: 'remittances',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(remittances)] },
        component: RemittancesComponent,
        resolve: {
          remittanceStatuses: RemittanceStatusesResolver,
          branches: BranchesResolver,
        },
      },
      {
        path: 'ormdashboard',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(remittances)] },
        component: OrmComponent,
        resolve: {
          ormStatus: RemittanceStatusesResolver,
        },
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        data: {
          permissions: [remittances.CREATE_REMITTANCE],
          accessibleToAdmin: false,
        },
        resolve: {
          remittanceSubTypes: RemittanceSubTypesResolver,
          tellerAccounts: TellerAccountsResolver,
          countries: CountriesResolver,
          payingCountries: PayingCountriesResolver,
          paymentTypes: PaymentTypesResolver,
          customerDocTypes: CustomerDocumentTypesResolver,
          customerGenders: CustomerGenderResolver,
          customerOccupations: CustomerOccupationsResolver,
          customerSourceOfIncome: CustomerSourceOfIncomeResolver,
          cities: CitiesResolver,
          localities: LocalitiesResolver,
          remittancePurposes: RemittancePurposesResolver,
          remittanceRelationships: RemittanceRelationshipsResolver,
          customerTypesData: CustomerTypesResolver
        },
        component: AddRemittanceComponent,
      },
      {
        path: 'pay/:remittanceId',
        canActivate: [AuthGuard],
        data: {
          permissions: [remittances.PAY_REMITTANCE],
          accessibleToAdmin: false,
        },
        resolve: {
          tellerAccounts: TellerAccountsResolver,
          countries: CountriesResolver,
          paymentTypes: PaymentTypesResolver,
          customerDocTypes: CustomerDocumentTypesResolver,
          remittanceForPaymentResp: RemittanceForPaymentResolver,
          customerGenders: CustomerGenderResolver,
          customerOccupations: CustomerOccupationsResolver,
          customerSourceOfIncome: CustomerSourceOfIncomeResolver,
          cities: CitiesResolver,
          localities: LocalitiesResolver,
          remittancePurposes: RemittancePurposesResolver,
          remittanceRelationships: RemittanceRelationshipsResolver,
          customerTypesData: CustomerTypesResolver
        },
        component: PayRemittanceComponent,
      },
      {
        path: 'payback/:remittanceId',
        canActivate: [AuthGuard],
        data: {
          permissions: [remittances.PAY_REMITTANCE],
          accessibleToAdmin: false,
        },
        resolve: {
          tellerAccounts: TellerAccountsResolver,
          paymentTypes: PaymentTypesResolver,
          remittances: RemittanceForPaybackResolver,
        },
        component: PaybackRemittanceComponent,
      },
      {
        path: 'commissionconfigs',
        component: OutletComponent,
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            data: { permissions: [...Object.values(commissionConfiguration)] },
            component: CommissionConfigsComponent,
          },
          {
            path: 'add',
            canActivate: [AuthGuard],
            data: {
              permissions: [commissionConfiguration.CREATE_REMIT_COMM_CONFIG],
              addMode: true,
            },
            resolve: {
              countries: CountriesResolver,
              remittanceSubTypes: RemittanceSubTypesResolver,
            },
            component: CommissionConfigDetailsComponent,
          },
          {
            path: 'view/:commissionConfigId',
            canActivate: [AuthGuard],
            data: {
              permissions: [
                commissionConfiguration.VIEW_REMIT_COMM_CONFIG,
                commissionConfiguration.UPDATE_REMIT_COMM_CONFIG,
              ],
              addMode: false,
            },
            resolve: {
              countries: CountriesResolver,
              remittanceSubTypes: RemittanceSubTypesResolver,
              commissionConfigs: CommissionConfigurationsResolver,
            },
            component: CommissionConfigDetailsComponent,
          },
          {
            path: 'commissionConfigurationRequests',
            canActivate: [AuthGuard],
            data: {
              permissions: [
                commissionConfigurationRequests.UPDATE_REMITTANCE_COMMISSION_STRUCTURE_REQUEST,
                commissionConfigurationRequests.VIEW_REMITTANCE_COMMISSION_STRUCTURE_REQUEST,
              ],
            },
            resolve: { statuses: CommissionConfigRequestStatusResolver },
            component: CommissionConfigsRequetsComponent,
          },
        ],
      },
      {
        path: 'ambiguousremittances',
        component: AmbiguousRemittancesComponent,
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(ambiguousRemittances)] },
      },
      {
        path: 'finsurvreports',
        component: FinsurvReportsComponent,
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(finsurv)] },
      },
      {
        path: 'finsurvtransactions',
        component: FinsurvTransactionsComponent,
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(finsurvTransactions)] },
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RemittanceRoutesModule {}
