import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from '../_common/snackBar.service';
import { SettlementService } from './data/settlement.service';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-settlement-rollback',
  templateUrl: './settlementRollbackPopup.component.html',
})
export class SettlementRollbackPopupComponent implements OnInit {
  settlerComments: string;

  form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SettlementRollbackPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { settlementId: number; settlerComments: string, partnerSettlementId: number, isPartnerSettlement: boolean },
    private fb: FormBuilder,
    private settlementService: SettlementService,
    public snackBar: SnackBarService,
  ) {
    this.settlerComments = this.data.settlerComments;
  }

  ngOnInit() {
    this.form = this.fb.group({
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.settlementService
        .rollbackOrRejectSettlement(
          this.data.settlementId,
          this.form.value.comments,
          'Rollback Settlement',
          this.data.partnerSettlementId,
          this.data.isPartnerSettlement
        )
        .subscribe(
          (resp) => {
            this.snackBar.open(resp?.message);
            this.dialogRef.close();
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
    }
  }
}
