<app-index-container [label]="'unPaidRemittanceReport' | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col-4">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select #branchId formControlName="branchId">
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{ branch.code === 'TKCPO' ? ('hqBranch' | translate) : branch.code + ' - ' + branch.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'requestedDate' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="requestedDateStart"
          formControlName="requestedDate"
          [value]="date.value"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="requestedDateStart"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #requestedDateStart></mat-datepicker>
      </mat-form-field>
      <div class="col d-flex align-items-center pb-3">
        <mat-checkbox
          matInput
          #isSubBranchesIncluded
          color="primary"
          formControlName="isSubBranchesIncluded"
        >
          {{ 'includesSubBranch' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button mat-raised-button type="submit" color="primary">
        <i class="fa-solid fa-magnifying-glass"></i>
        {{ 'search' | translate }}
      </button>
    </div>
  </form>

  <table
    table
    matTableExporter
    mat-table
    [dataSource]="data"
    #exporter="matTableExporter"
    class="table"
  >
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- unpaidAmount Column -->
    <ng-container matColumnDef="unpaidAmount">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'unpaidAmount' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.unpaidAmount | number:'1.2-2' }}</td>
    </ng-container>
    <!-- unpaidCommission Column -->
    <ng-container matColumnDef="unpaidCommission">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'unpaidCommission' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.unpaidCommission | number:'1.0-5' }}</td>
    </ng-container>
    <!-- totalAmountWithComm Column -->
    <ng-container matColumnDef="totalAmountWithComm">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'totalAmountWithComm' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.totalAmountWithComm | number:'1.2-2' }}</td>
    </ng-container>
    <!-- numberOfRemittances Column -->
    <ng-container matColumnDef="numberOfRemittances">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'numberOfRemittances' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        <span
          style="color: #0074d9; cursor: pointer"
          (click)="disPlayRemitDetails(row?.id)"
        >
          {{ row.numberOfRemittances }}
        </span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
