import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoginComponent } from './login.component';
import { LogoutComponent } from './logout.component';
import { AuthRoutesModule } from './auth.routes.module';
import { AuthGuard } from './data/auth.guard';
import { AuthInterceptor } from './data/http.interceptor';
import { AuthService } from './data/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ResetPasswordComponent } from "./reset-password.component";
@NgModule({
  declarations: [LoginComponent, LogoutComponent, ResetPasswordComponent],
  imports: [
    AuthRoutesModule,
    MatRadioModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TranslateModule,
    BrowserModule,
    MatSelectModule,
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      deps: [AuthService],
      multi: true,
    }
  ],
})
export class AuthModule {}
