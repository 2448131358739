import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  CreateBranchDto,
  UpdateBranchDto,
  SearchBranchesDto,
  GetBranchesDto,
  SearchAccountsDto,
  CreateAccountDto,
  GetAccountDto,
  UpdateAccountDto,
  UpdateBranchConfigsDto,
  BranchCurrencyDto,
  SearchBranchCurrencyDto,
  UpdateBranchCurrencyStatus,
  ValidateBranchLimitDto,
  SearchBranchLimitRequestsResponseDto,
  BranchLimitUpdateApprovalRequestDto,
  BranchCommissionUpdateApprovalRequestDto,
  SearchBranchCommissionRequestsResponseDto,
  GetBranchCommissionsDto,
  CreateManualJournalDto,
  SearchManualJournalDto,
  UpdateManualJournalDto,
  GetBranchCurrencyDto,
  GetLimitAndCommConfigsDto,
  AddBranchLimitApprovalRequestDto,
  AddBranchCommissionApprovalRequestDto,
} from './dto/branches.dto';
import { CRUDResponseDto, LookupDto } from 'src/app/_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import { TableTypes } from 'src/app/_common/enums/tableTypes.enums';
import { map } from 'rxjs/operators';
import { CountriesDto } from 'src/app/remittance/data/dto/countries.dto';

type BranchFilter = {
  branchCode: string;
  branchStatus: string;
} | null;

export type BranchCurrenciesFilter = {
  branchId?: number;
  statusId?: number;
};

export type BranchLimitRequestsFilter = {
  branchId?: number;
  statusId?: number;
};
export type GetAccountsSearchFilter = {
  branchId?: string;
  groupId?: string;
  typeId?: string;
  accountNumber?: string;
  isActive?: boolean;
};
export type GetManualJournalsSearchFilter = {
  branchId?: number;
  manualJournalId?: number;
  statusId?: number;
};

export type BranchCommissionRequestsFilter = {
  branchId?: number;
  statusId?: number;
};

type BranchListFilter = {
  branchId: number;
} | null;

type GetBranchesResponse = {
  appliedFilters: BranchFilter;
  data: SearchBranchesDto[];
};
type GetAccountsResponse = {
  appliedFilters: GetAccountsSearchFilter;
  data: SearchAccountsDto[];
};
type GetJournalsResponse = {
  appliedFilters: GetManualJournalsSearchFilter;
  data: SearchManualJournalDto[];
};

type GetBranchCurrenciesResponse = {
  appliedFilters: BranchCurrenciesFilter | null;
  data: SearchBranchCurrencyDto[];
};

type GetBranchLimitRequestsResponse = {
  appliedFilters: BranchLimitRequestsFilter | null;
  data: SearchBranchLimitRequestsResponseDto[];
};

type GetBranchCommissionRequestsResponse = {
  appliedFilters: BranchCommissionRequestsFilter | null;
  data: SearchBranchCommissionRequestsResponseDto[];
};

@Injectable()
export class BranchesService {
  private tablePendingChanges: string = '';
  private _branches = new BehaviorSubject<GetBranchesResponse>({
    appliedFilters: null,
    data: [],
  });
  //getBranches: Observable<GetBranchesResponse>;
  private _accounts = new BehaviorSubject<GetAccountsResponse>({
    appliedFilters: {},
    data: [],
  });
  getAccounts: Observable<GetAccountsResponse>;
  private _journals = new BehaviorSubject<GetJournalsResponse>({
    appliedFilters: {},
    data: [],
  });
  getJournals: Observable<GetJournalsResponse>;
  private _branchLimitRquests =
    new BehaviorSubject<GetBranchLimitRequestsResponse>({
      appliedFilters: null,
      data: [],
    });
  private branchesObservable?: Observable<GetBranchesResponse>;
  private _branchLimitRequests =
    new BehaviorSubject<GetBranchLimitRequestsResponse>({
      appliedFilters: null,
      data: [],
    });
  getLimitchangeRequests: Observable<GetBranchLimitRequestsResponse>;
  private _rateChangeRequests =
    new BehaviorSubject<GetBranchCurrenciesResponse>({
      appliedFilters: null,
      data: [],
    });
  getRateChangeRequests: Observable<GetBranchCurrenciesResponse>;
  private _branchCommissionRequests =
    new BehaviorSubject<GetBranchCommissionRequestsResponse>({
      appliedFilters: null,
      data: [],
    });
  getCommissionchangeRequests: Observable<GetBranchCommissionRequestsResponse>;
  constructor(private httpService: HttpService) {
    this.getRateChangeRequests = this._rateChangeRequests.asObservable();
    this.getLimitchangeRequests = this._branchLimitRquests.asObservable();
    this.getAccounts = this._accounts.asObservable();
    this.getLimitchangeRequests = this._branchLimitRequests.asObservable();
    this.getCommissionchangeRequests =
      this._branchCommissionRequests.asObservable();
    this.getJournals = this._journals.asObservable();
  }

  getbranches() {
    if (!this.branchesObservable)
      this.branchesObservable = this._branches.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.BRANCHES) {
            this.searchBranches(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.branchesObservable;
  }

  searchBranches(filter: BranchFilter = null) {
    this.httpService
      .get<SearchBranchesDto[]>('branch/search', filter)
      .subscribe((branches) => {
        this.tablePendingChanges = '';
        this._branches.next({
          appliedFilters: filter,
          data: branches,
        });
      });
  }

  searchBranchCurrencies(filter: BranchCurrenciesFilter) {
    this.httpService
      .get<SearchBranchCurrencyDto[]>('branch/currencies/search', filter)
      .subscribe((branchCurrencies) => {
        this._rateChangeRequests.next({
          appliedFilters: filter,
          data: branchCurrencies,
        });
      });
  }

  createBranch(payload: CreateBranchDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('branch/create', payload);
  }

  updateBranch(payload: UpdateBranchDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('branch/update', payload)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.BRANCHES;
          return resp;
        }),
      );
  }

  addBranchLimitApprovalRequest(
    payload: AddBranchLimitApprovalRequestDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('branch/branchLimitApproval/add', payload)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.BRANCHES;
          return resp;
        }),
      );
  }

  addBranchCommissionApproval(
    payload: AddBranchCommissionApprovalRequestDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('branch/branchCommissionApproval/add', payload)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.BRANCHES;
          return resp;
        }),
      );
  }

  validateBranchLimit(
    payload: ValidateBranchLimitDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'branch/validateLimit',
      payload,
    );
  }

  updateBranchConfigs(
    payload: UpdateBranchConfigsDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'branch/configuration/update',
      payload,
    );
  }

  updateBranchCurrencyRequestStatus(
    payload: UpdateBranchCurrencyStatus,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'branch/currencies/status/update',
      payload,
    );
  }

  getBranchCommissions(
    branchId: number,
  ): Observable<GetBranchCommissionsDto[]> {
    return this.httpService.get<GetBranchCommissionsDto[]>(
      'remittance/branch/commissions',
      { branchId },
    );
  }

  processPendingBranchCommissions(
    dto: GetBranchCommissionsDto[],
  ): Observable<GetBranchCommissionsDto[]> {
    return this.httpService.post<GetBranchCommissionsDto[]>(
      'remittance/branch/commissions/process',
      dto,
    );
  }

  getBranches(filter: BranchListFilter = null): Observable<GetBranchesDto[]> {
    return this.httpService.get<GetBranchesDto[]>('branch/list', filter);
  }

  beneficiaries(branchId?: number): Observable<GetBranchesDto[]> {
    return this.httpService.get<GetBranchesDto[]>(
      'branch/beneficiary',
      branchId ? { branchId } : null,
    );
  }

  getRateChangeStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/currencies/status/list');
  }

  getManualJournalStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/journal/status/list');
  }

  getBranchCommissionPolicies(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/commission/policies');
  }

  branchById(branchId: number): Observable<GetBranchesDto[]> {
    return this.getBranches({ branchId });
  }

  branchTypes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/branchtype/list');
  }

  branchCities(countryId: number | null): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      `branch/city/list?countryId=${countryId}`,
    );
  }

  getLocalities(cityId: number): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      `branch/locality/list?cityId=${cityId}`,
    );
  }

  countries(): Observable<CountriesDto[]> {
    return this.httpService.get<CountriesDto[]>('branch/country/list');
  }

  cities(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/city/list');
  }

  localities(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/locality/list');
  }

  exchangeRateTypes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/exchangeratetypes/list');
  }

  accountGroups(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('account/group/list');
  }

  currencies(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/currency/list');
  }

  createBranchCurrencies(
    dto: BranchCurrencyDto,
  ): Observable<CRUDResponseDto[]> {
    return this.httpService.post<CRUDResponseDto[]>(
      'branch/currencies/create',
      dto,
    );
  }

  branchCurrenciesLookup(branchId: number): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/currencies/list', {
      branchId,
    });
  }

  getBranchLimitAndCommConfigs(
    branchId: number,
  ): Observable<GetLimitAndCommConfigsDto> {
    return this.httpService.get<GetLimitAndCommConfigsDto>(
      'branch/limitandcommconfigs',
      {
        branchId,
      },
    );
  }

  updatetBranchLimitApproval(
    dto: BranchLimitUpdateApprovalRequestDto,
  ): Observable<CRUDResponseDto[]> {
    return this.httpService.post<CRUDResponseDto[]>(
      'branch/branchLimitApproval/update',
      dto,
    );
  }

  getBranchLimitApprovals(): Observable<CRUDResponseDto[]> {
    return this.httpService.get<CRUDResponseDto[]>(
      'branch/branchLimitApprovals/list',
    );
  }

  branchParents(): Observable<GetBranchesDto[]> {
    return this.httpService.get<GetBranchesDto[]>('branch/parent/list');
  }

  searchBranchLimitsRequests(filter: BranchLimitRequestsFilter) {
    this.httpService
      .get<SearchBranchLimitRequestsResponseDto[]>(
        'branch/branchLimitApprovals/search',
        filter,
      )
      .subscribe((branchLimitRequests) => {
        this._branchLimitRequests.next({
          appliedFilters: filter,
          data: branchLimitRequests,
        });
      });
  }

  getLimitChangeStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      'branch/branchLimitApprovals/status/list',
    );
  }
  getAccountLevels(branchId: any): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      `account/level/list?branchId=${branchId}`,
    );
  }
  searchAccounts(filter: GetAccountsSearchFilter) {
    this.httpService
      .get<SearchAccountsDto[]>('account/search', filter)
      .subscribe((accounts) => {
        this._accounts.next({
          appliedFilters: filter,
          data: accounts,
        });
      });
  }
  getParents(
    branchId: any,
    groupId: any,
    typeId: any,
  ): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(`account/parent/list`, {
      branchId,
      groupId,
      typeId,
    });
  }

  createAccount(dto: CreateAccountDto): Observable<CRUDResponseDto[]> {
    return this.httpService.post<CRUDResponseDto[]>('account/create', dto);
  }
  getAccountById(id: string): Observable<GetAccountDto> {
    return this.httpService.get<GetAccountDto>(
      `account/details?accountId=${id}`,
    );
  }
  updateAccount(dto: UpdateAccountDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('account/update', dto);
  }

  updatetBranchCommissionApproval(
    dto: BranchCommissionUpdateApprovalRequestDto,
  ): Observable<CRUDResponseDto[]> {
    return this.httpService.post<CRUDResponseDto[]>(
      'branch/branchCommissionApproval/update',
      dto,
    );
  }

  searchBranchCommissionsRequests(filter: BranchCommissionRequestsFilter) {
    this.httpService
      .get<SearchBranchCommissionRequestsResponseDto[]>(
        'branch/branchCommissionApprovals/search',
        filter,
      )
      .subscribe((branchCommissionRequests) => {
        this._branchCommissionRequests.next({
          appliedFilters: filter,
          data: branchCommissionRequests,
        });
      });
  }

  getCommissionChangeStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      'branch/branchCommissionApprovals/status/list',
    );
  }

  getBranchAccountsById(branchId: any) {
    return this.httpService.get<LookupDto[]>(
      `account/branch/list?branchId=${branchId}`,
    );
  }

  getBranchAccounts(branchId: number): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('account/entry/list', {
      branchId,
    });
  }

  getBranchExchangeRates(branchId: any, exchangeRateType: string) {
    return this.httpService.get<GetBranchCurrencyDto[]>(
      `branch/exchangerates`,
      {
        branchId,
        exchangeRateType,
      },
    );
  }

  createManualJournal(dto: CreateManualJournalDto) {
    return this.httpService.post<CRUDResponseDto>('branch/journal/create', dto);
  }

  searchManualJournals(filters: GetManualJournalsSearchFilter) {
    return this.httpService
      .get<SearchManualJournalDto[]>('branch/journal/search', filters)
      .subscribe((journals) => {
        this._journals.next({
          appliedFilters: filters,
          data: journals,
        });
      });
  }

  approveManualJournal(dto: UpdateManualJournalDto) {
    return this.httpService.post<CRUDResponseDto>(
      'branch/journal/approve',
      dto,
    );
  }

  rejectManualJournal(dto: UpdateManualJournalDto) {
    return this.httpService.post<CRUDResponseDto>('branch/journal/reject', dto);
  }

  getManualJournalDetails(id: number): Observable<SearchManualJournalDto> {
    return this.httpService.get<SearchManualJournalDto>(
      'branch/journal/detail',
      { id },
    );
  }

  addJournalDocument(journalId: number, file: File): Observable<CRUDResponseDto> {
    const reqData = new FormData();
    reqData.append('journalId', journalId.toString());
    reqData.append('file', file);
    return this.httpService.post<CRUDResponseDto>(
      'branch/document/upload',
      reqData,
    );
  }

  getManualJournalDocDetails(manualJournalId: number): Observable<any> {
    return this.httpService.get<any>('branch/document/detail', {
      manualJournalId,
    });
  }
  getManualJournalAttachment(path: string) {
    return this.httpService.getAttachment('branch/attachment', { path });
  }
  getBranchUsers(branchId: number | undefined): Observable<any> {
    return this.httpService.get('user/branchusers', { branchId });
  }
}
