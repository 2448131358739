import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import * as XLSX from "xlsx";
import { Utilities } from '../_common/utils';
import { BsmvReport } from "./data/Dto/CbkRemittance.dto";



@Component({
  selector: 'app-bsmv-report-popup',
  templateUrl: './bsmv-report-popup.html',
})
export class BsmvReportPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<BsmvReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: BsmvReport,
  ) { }

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
      ? Utilities.convertDateToLocaleString(date, '')
      : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('bsmvReport'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'bsmvReport');
    XLSX.writeFile(
      wb,
      'bsmvReport' +
      this.convertDateToLocaleString(new Date(), false) +
      '.xlsx',
    );
  }
}
