import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-signup-mandate',
  templateUrl: './signup-mandate.component.html',
  styleUrls: ['./signup-mandate.component.css'],
})
export class SignupMandateComponent {

}
