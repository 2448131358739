import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  GetUserDto,
  GetUsersDto,
  UpdateUserDto,
  UserDto,
  CreateUserDto,
  GetOtpTypes,
  OtpDto,
  ResetUserPasswordDto,
  UpdateUserRequestStatusDto,
  SearchUserRequests,
} from './dto/user/users.dto';
import { CRUDResponseDto, LookupDto } from '../../_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import { TableTypes } from '../../_common/enums/tableTypes.enums';
import { GetLoginHistoryDto } from './dto/loginHistory/loginHistory.dto';


interface ModifiedData {
  level: string;
  old_value: any;
  new_value: any;
}

type UserFilter = {
  userName: string;
  branchCode: string;
  statusId?: number;
  userTypeId?: number;
} | null;

type GetUsers = {
  appliedFilters: UserFilter;
  users: GetUsersDto[];
};

export type UserRequestFilter = {
  userTypeId?: number;
  statusId?: number;
};

export type LoginHistoryFilter = {
  date?: Date;
  statusId?: number;
} | null;

export const passwordRegex =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;

@Injectable()
export class UsersService {
  private tablePendingChanges: string = '';
  private _user = new BehaviorSubject<GetUsers>({
    appliedFilters: null,
    users: [],
  });
  private usersObservable?: Observable<GetUsers>;
  constructor(private httpService: HttpService) {}

  get users() {
    if (!this.usersObservable)
      this.usersObservable = this._user.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.USERS) {
            this.getUsers(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.usersObservable;
  }

  getUsers(payload: UserFilter = null) {
    this.httpService
      .get<GetUsersDto[]>('user/search', payload)
      .subscribe((users) => {
        this.tablePendingChanges = '';
        this._user.next({
          users: users,
          appliedFilters: payload,
        });
      });
  }

  getUserById(userId: number): Observable<UserDto> {
    return this.httpService.get<UserDto>(`user/list`, { userId });
  }

  createUser(payload: CreateUserDto): Observable<CreateUserDto> {
    return this.httpService.post<CreateUserDto>('user/create', payload);
  }

  generateQrCode(): Observable<OtpDto> {
    return this.httpService.get<OtpDto>('user/2fa/generate');
  }

  generateSmsCode(): Observable<OtpDto> {
    return this.httpService.get<OtpDto>('user/2fa/generateSms');
  }

  checkSmsCode(smsCode: string): Observable<OtpDto> {
    return this.httpService.get<OtpDto>('user/2fa/checkSms', { smsCode });
  }

  setOtpEnabled(selectedOtp: string, googleOtp?: string): Observable<OtpDto> {
    return this.httpService.post<OtpDto>('user/2fa/setOtpEnabled', {
      selectedOtp,
      googleOtp,
    });
  }

  updateUser(payload: UpdateUserDto): Observable<CreateUserDto> {
    const modifiedData = this.getModifiedData(payload.oldUser, payload.newUser);
    const newPayload = {
      modifiedData: modifiedData,
      ...payload.newUser,
    }
    return this.httpService.post<CreateUserDto>('user/update', newPayload).pipe(
      map((resp) => {
        this.tablePendingChanges = TableTypes.USERS;
        return resp;
      }),
    );
  }
  
  getModifiedData(obj1: any, obj2: any): ModifiedData[] {
    const modifiedData: ModifiedData[] = [];
  
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          const modifiedItem: ModifiedData = {
            level: key,
            old_value: obj1[key],
            new_value: obj2[key],
          };
          modifiedData.push(modifiedItem);
        }
      }
    }
  
    return modifiedData;
  }
  

  updateUserRequestStatus(dto: UpdateUserRequestStatusDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('user/request/status/update', dto);
  }

  getUserRequests(filter: UserRequestFilter | null): Observable<SearchUserRequests[]> {
    return this.httpService.get<SearchUserRequests[]>('user/request/list', filter);
  }

  userTypes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('role/usertype/list');
  }

  userDetails(): Observable<GetUserDto> {
    return this.httpService.get<GetUserDto>('user/detail');
  }

  getOtpTypes(): Observable<GetOtpTypes[]> {
    return this.httpService.get<GetOtpTypes[]>('user/otpTypes/list');
  }
  resetUserPassword(payload: ResetUserPasswordDto): Observable<CreateUserDto> {
    return this.httpService.post<CreateUserDto>('user/resetpassword', payload);
  }
  changeUserPassword(
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string,
    otpCode: string,
  ) {
    return this.httpService.post<CreateUserDto>('user/changePassword', {
      currentPassword,
      newPassword,
      confirmNewPassword,
      otpCode,
    });
  }

  getLoginHistoryStatus(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('user/loginhistory/status/list');
  }

  searchLoginHistories(filter: LoginHistoryFilter | null): Observable<GetLoginHistoryDto[]> {
    return this.httpService.get<GetLoginHistoryDto[]>('user/loginhistory/search', filter);
  }
}
