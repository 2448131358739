<h1 mat-dialog-title>{{ 'updateStatus' | translate }}</h1>
<div mat-dialog-content>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-label class="mb-4">{{ 'status' | translate }}:</mat-label>
      <mat-radio-group formControlName="status" class="mb-4">
        <mat-radio-button value="returnWithCommission">
          {{ 'returnWithCommission' | translate }}
        </mat-radio-button>
        <mat-radio-button value="returnWithoutCommission">
          {{ 'returnWithoutCommission' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">
    {{ 'close' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'update' | translate }}
  </button>
</div>
