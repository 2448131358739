import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../_common/snackBar.service';
import { BranchesService } from './data/branches.service';
import {regExp} from "../_common/data/validationRules";
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-branch-currency-approval',
  templateUrl: './branchCurrencyApprovalPopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class BranchCurrencyApprovalPopupComponent implements OnInit {
  comments: string = '';
  type: string = '';

  form!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<BranchCurrencyApprovalPopupComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private branchesService: BranchesService,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group({
      rateChangeRequestStatus: ['approveBranchCurrency', [Validators.required]],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.branchesService
        .updateBranchCurrencyRequestStatus({
          branchCurrencyId: this.data.id,
          statusType: this.form.value.rateChangeRequestStatus,
          comments: this.form.value.comments,
        })
        .subscribe(
          (resp) => {
            this.snackBar.open(resp?.message);
            this.dialogRef.close({
              success: true,
            });
          },
          ({ message }) => {
            this.snackBar.open(message);
            this.dialogRef.close({
              success: false,
            });
          },
        );
    }
  }
  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
