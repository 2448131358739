import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event,
} from '@angular/router';
import { AuthService } from './auth/data/auth.service';
import { AutoLogoutService } from './auth/data/autoLogout.service';
import { filter } from 'rxjs/operators'
export let pageRefreshed = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'tayopay';
  route: any;
  isLoginPage: boolean = false;
  isResetPasswordPage: boolean = false;
  cssPalette!: string;
  userPermissions: string[] = [];
  pageRefreshed: boolean = false;

  constructor(
    private router: Router,
    location: Location,
    public authService: AuthService,
    private autoLogout: AutoLogoutService,
  ) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          break;
        }
        case event instanceof NavigationEnd: {
          if (this.cssPalette && Object.keys(this.cssPalette).length) {
            this.setDynamicStyling(this.cssPalette);
          } else {
            this.setDynamicStyling('theme-blue-palette');
          }
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          break;
        }
        default: {
          break;
        }
      }
    });
    router.events.subscribe((val) => {
      this.route = location.path();
      this.isLoginPage = this.route === '/login';
      this.isResetPasswordPage = this.route?.split('?')[0] === '/resetpassword';
      if (!this.isLoginPage && !this.isResetPasswordPage && !this.authService.isLoggedIn) {
        window.location.href = '/login';
      }
    });

    this.router.events
    .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
    .subscribe(event => {
      pageRefreshed = false;
      if (event.id === 1 && event.url === event.urlAfterRedirects) {
          pageRefreshed = true;
          window.addEventListener("beforeunload", function (e) {
            let currentUser = localStorage.getItem('currentUser');
            if(currentUser && pageRefreshed){
              localStorage.removeItem('currentUser');
              localStorage.removeItem('authToken');
            }
        });
      }
    })
  }

  setDynamicStyling(cssURL: string) {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'sundus-styling';
    style.rel = 'stylesheet';
    style.href = `assets/css/${cssURL}.css`;
    head.appendChild(style);
  }

  ngOnInit() {
    this.cssPalette = JSON.parse(localStorage.getItem('sundus_theme') || '{}');
    this.authService.currentUser.subscribe((user) => {
      this.userPermissions = user?.permissions || [];
    });

    // const sw = this.autoLogout.startWatching(300).subscribe((isTimedOut: boolean) => {
    //   console.log(isTimedOut);
    //   if (isTimedOut) {
    //     this.autoLogout.stopTimer();
    //     if (this.authService.isLoggedIn) {
    //       this.authService.logout();
    //     }

    //     sw.unsubscribe();
    //   }
    // });
  }
}
