<div class="customer-form-container">
  <form [formGroup]="customerBlacklistForm" (ngSubmit)="onSubmit()">
    <div class="form-grid">
      <!-- Full Name -->
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
      </mat-form-field>
  
      <!-- Mobile -->
      <mat-form-field appearance="outline">
        <mat-label>Mobile</mat-label>
        <input matInput formControlName="mobile" required type="tel" />
      </mat-form-field>

      <!-- Is customer blocked? -->
      <mat-form-field appearance="outline">
        <mat-label>Is customer blocked?</mat-label>
        <mat-select formControlName="Blocked" required>
          <mat-option [value]="1">Yes</mat-option>
          <mat-option [value]="0">No</mat-option>
        </mat-select>
      </mat-form-field>
  
      <!-- Is approval required? -->
      <mat-form-field appearance="outline">
        <mat-label>Is approval required?</mat-label>
        <mat-select formControlName="ApprovalRequired" required>
          <mat-option [value]="1">Yes</mat-option>
          <mat-option [value]="0">No</mat-option>
        </mat-select>
      </mat-form-field>
  
      <!-- comment -->
      <mat-form-field *ngIf="customerId !== null" appearance="outline">
        <mat-label>Comment</mat-label>
        <textarea matInput formControlName="comments" required></textarea>
      </mat-form-field>

      <!-- Reasons -->
      <mat-form-field *ngIf="customerId === null" appearance="outline">
        <mat-label>Reason</mat-label>
        <textarea matInput formControlName="reasons" required></textarea>
      </mat-form-field>

    </div>

      <!-- Submit Button -->
    <button mat-raised-button color="primary" type="submit" [disabled]="customerBlacklistForm.invalid">
      {{ customerId != null ? 'Update Blacklist' : 'Add Blacklist' }}
    </button>

  </form>
</div>
