import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-remittance-blocking',
  templateUrl: './remittanceBlockingPopup.component.html',
})
export class RemittanceBlockingPopupComponent implements OnInit {
  @ViewChild('docFile') docFileElem!: ElementRef;
  comments: string = '';
  form!: UntypedFormGroup;
  docFile?: File;
  docFileUrl: any;
  isFileSelected: boolean = false;
  isPdfFile: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<RemittanceBlockingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onDocAttachmentSelected(e: Event) {
    //@ts-ignore
    const file = e.target.files[0];
    const reader = new FileReader();
    this.docFile = file;
    reader.onload = (event: any) => {
      this.isFileSelected = true;
      this.docFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        event.target.result,
      );
      if (
        this.docFileUrl.changingThisBreaksApplicationSecurity.indexOf(
          'data:application/pdf',
        ) > -1
      ) {
        this.isPdfFile = true;
      }
    };
    reader.readAsDataURL(file);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close({
        comments: this.form.value.comments,
        // docFile: this.docFile,
      });
    }
  }

  onViewAttachment(imageURL: string[] | undefined) {
    this.dialog.open(AttachmentsPopupComponent, {
      width: '850px',
      data: { imageURL: [imageURL], isPdfFile: this.isPdfFile },
    });
  }
}
