import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RolesService } from './roles.service';
import { RolesResponseDto } from './dto/role/roles.dto';

@Injectable()
export class RolesResolver implements Resolve<any> {
  constructor(private rolesService: RolesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RolesResponseDto[]> {
    return this.rolesService.getRoles();
  }
}

@Injectable()
export class RoleByIdResolver implements Resolve<any> {
  constructor(private rolesService: RolesService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RolesResponseDto[]> {
    const { roleId } = route.params;
    return this.rolesService.getRoles({ roleId });
  }
}
