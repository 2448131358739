<app-form-container label="{{ 'payRemittance' | translate }}">
  <form
    form
    #payRemittanceForm
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
          <input
            [readonly]="true"
            matInput
            #remittanceCode
            formControlName="remittanceCode"
          />
        </mat-form-field>
      </div>
      <div class="col col-md-6" *ngIf="remittance?.verificationCode">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'remittancePin' | translate }}:</mat-label>
          <input matInput #remittancePin formControlName="remittancePin" />
        </mat-form-field>
        &nbsp;
        <button
          mat-raised-button
          class="me-2"
          color="secondary"
          type="button"
          (click)="generateRemittancePin()"
        >
          {{ 'resendVerificationPin' | translate }}
        </button>
      </div>
    </div>
    <mat-card *ngIf="multipleSenders && !senderSelected">
      <mat-card-title>{{ 'multipleSendersFound' | translate }}</mat-card-title>
      <h4>{{ 'pleaseSelectSenderFromList' | translate }}</h4>
      <table table mat-table [dataSource]="senderCustomers" class="table">
        <ng-container matColumnDef="#">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'firstName' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'lastName' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="mobilePhone">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'mobilePhone' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.mobilePhone }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <a (click)="onSelectSenderCustomer(row.id)">
              <mat-icon>check_circle</mat-icon>
            </a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        #scPaginator
        [pageSizeOptions]="[10, 25, senderResultsLength]"
        [pageSize]="10"
      ></mat-paginator>
    </mat-card>
    <mat-card *ngIf="senderSelected">
      <mat-card-title>{{ 'senderInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row" *ngIf="customerTypeId == customerType.Person">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'firstName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderFirstName
                  formControlName="senderFirstName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'middleName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderMiddleName
                  formControlName="senderMiddleName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'lastName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderLastName
                  formControlName="senderLastName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'mobile' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderMobilePhone
                  formControlName="senderMobilePhone"
                />
              </mat-form-field>
            </div>

            <div class="row mt-2 mb-3" *ngIf="customerTypeId == customerType.Corporate">
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'companyTitle' | translate }}</mat-label>
                  <input matInput #companyTitle formControlName="companyTitle" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyTitle'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'taxIdentificationNumber' | translate }}</mat-label>
                  <input matInput #taxIdentificationNumber formControlName="taxIdentificationNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('taxIdentificationNumber'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'tradeRegistryNumber' | translate }}</mat-label>
                  <input matInput #tradeRegistryNumber formControlName="tradeRegistryNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('tradeRegistryNumber'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'fieldOfActivity' | translate }}</mat-label>
                  <input matInput #fieldOfActivity formControlName="fieldOfActivity" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('fieldOfActivity'))"></app-validation-error>
              </div>

              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'mobile' | translate }}:</mat-label>
                  <input matInput #companyMobilePhone formControlName="companyMobilePhone" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyMobilePhone'))"></app-validation-error>
              </div>
              <mat-form-field appearance="outline" class="col-md-3">
                <mat-label>{{ 'country' | translate }}</mat-label>
                <mat-select formControlName="companyCountry" (selectionChange)="onCountryChange($event.value.id)">
                  <mat-option *ngFor="let country of allCountries" [value]="country">{{ country.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
                <mat-label>{{ 'city' | translate }}:</mat-label>
                <mat-select formControlName="companyCity" (selectionChange)="onCityChange($event.value.id)">
                  <mat-option *ngFor="let city of cities" [value]="city">{{
                    city.name
                    }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'street' | translate }}:</mat-label>
                  <input matInput #companyStreet formControlName="companyStreet" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyStreet'))"></app-validation-error>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
                  <input matInput #companyBuildingNumber formControlName="companyBuildingNumber" />
                </mat-form-field>
                <app-validation-error [control]="$any(form.get('companyBuildingNumber'))"></app-validation-error>
              </div>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'companyArea' | translate }}:</mat-label>
                <input matInput #companyArea formControlName="companyArea" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('companyArea'))"></app-validation-error>
            </div>

          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="multipleReceivers && !receiverSelected">
      <mat-card-title>{{
        'multipleReceiversFound' | translate
      }}</mat-card-title>
      <h4>{{ 'pleaseSelectReceiverFromList' | translate }}</h4>
      <table table mat-table [dataSource]="receiverCustomers" class="table">
        <ng-container matColumnDef="#">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'firstName' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.firstName }}</td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'lastName' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
        </ng-container>
        <ng-container matColumnDef="mobilePhone">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'mobilePhone' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.mobilePhone }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <a (click)="onSelectReceiverCustomer(row.id)">
              <mat-icon>check_circle</mat-icon>
            </a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        #rcPaginator
        [pageSizeOptions]="[10, 25, receiverResultsLength]"
        [pageSize]="10"
      ></mat-paginator>
    </mat-card>
    <mat-card *ngIf="senderSelected && receiverSelected" class="mt-3">
      <mat-card-title>{{ 'receiverInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row mb-2">
          <mat-radio-group formControlName="receiverCustomerTypeId" class="col-3 mb-2 mt-3" (change)="onCustomerTypeChanged($event)">
            <mat-radio-button
              *ngFor="let allCustomerType of allCustomerTypes" [value]="allCustomerType"
              [checked]="allCustomerType.id === receiverCustomerTypeId"
              style="margin-left: 8px;">
              {{ allCustomerType.name  }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="row" *ngIf="receiverCustomerTypeId != customerType.Corporate">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'firstName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverFirstName
                  formControlName="receiverFirstName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'middleName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverMiddleName
                  formControlName="receiverMiddleName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'lastName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverLastName
                  formControlName="receiverLastName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'mobile' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverMobilePhone
                  formControlName="receiverMobilePhone"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="receiverCustomerTypeId != customerType.Corporate">
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label class="mb-1">{{ 'gender' | translate }}:</mat-label>
              <mat-select formControlName="gender">
                <mat-option
                  *ngFor="let gender of customerGenders"
                  [value]="gender"
                  >{{ gender.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'placeOfBirth' | translate }}:</mat-label>
              <input matInput #placeOfBirth formControlName="placeOfBirth" />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('placeOfBirth'))"
            ></app-validation-error>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'dob' | translate }}</mat-label>
              <input
                matInput
                [max]="maxDOB"
                [matDatepicker]="dob"
                formControlName="dateOfBirth"
              />
              <mat-hint>DD-MM-YYYY</mat-hint>
              <mat-datepicker-toggle
                matSuffix
                [for]="dob"
              ></mat-datepicker-toggle>
              <mat-datepicker startView="month" #dob></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="receiverCustomerTypeId != customerType.Corporate">
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'occupation' | translate }}:</mat-label>
            <mat-select formControlName="occupation">
              <mat-option
                *ngFor="let occupation of customerOccupations"
                [value]="occupation"
              >
                {{ occupation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'sourceOfIncome' | translate }}</mat-label>
            <mat-select formControlName="sourceOfIncome">
              <mat-option
                *ngFor="let source of customerSourceOfIncome"
                [value]="source"
                >{{ source.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div class="col"></div>
        </div>
        <div class="row" *ngIf="receiverCustomerTypeId != customerType.Corporate">
          <p class="h6">{{ 'addressInformation' | translate }}</p>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'country' | translate }}</mat-label>
            <mat-select
              formControlName="receiverCountry"
              (selectionChange)="onCountryChange($event.value.id)"
            >
              <mat-option *ngFor="let country of countries" [value]="country">{{
                country.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'city' | translate }}:</mat-label>
            <mat-select
              formControlName="receiverCity"
              (selectionChange)="onCityChange($event.value.id)"
            >
              <mat-option *ngFor="let city of cities" [value]="city">{{
                city.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'locality' | translate }}</mat-label>
            <mat-select formControlName="locality">
              <mat-option
                *ngFor="let locality of localities"
                [value]="locality"
                >{{ locality.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'sublocality' | translate }}</mat-label>
              <input matInput #sublocality formControlName="sublocality" />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('sublocality'))"
            ></app-validation-error>
          </div>
        </div>
        <div class="row" *ngIf="receiverCustomerTypeId != customerType.Corporate">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'street' | translate }}:</mat-label>
              <input matInput #street formControlName="street" />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('street'))"
            ></app-validation-error>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
              <input
                matInput
                #buildingNumber
                formControlName="buildingNumber"
              />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('buildingNumber'))"
            ></app-validation-error>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'houseNumber' | translate }}:</mat-label>
              <input matInput #houseNumber formControlName="houseNumber" />
            </mat-form-field>
            <app-validation-error
              [control]="$any(form.get('houseNumber'))"
            ></app-validation-error>
          </div>
        </div>


        <div class="row mt-2 mb-3" *ngIf="receiverCustomerTypeId == customerType.Corporate">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'companyTitle' | translate }}</mat-label>
              <input matInput #receiverCompanyTitle formControlName="receiverCompanyTitle" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverCompanyTitle'))"></app-validation-error>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'taxIdentificationNumber' | translate }}</mat-label>
              <input matInput #receiverTaxIdentificationNumber formControlName="receiverTaxIdentificationNumber" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverTaxIdentificationNumber'))"></app-validation-error>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'tradeRegistryNumber' | translate }}</mat-label>
              <input matInput #receiverTradeRegistryNumber formControlName="receiverTradeRegistryNumber" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverTradeRegistryNumber'))"></app-validation-error>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'fieldOfActivity' | translate }}</mat-label>
              <input matInput #receiverFieldOfActivity formControlName="receiverFieldOfActivity" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverFieldOfActivity'))"></app-validation-error>
          </div>

          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'mobile' | translate }}:</mat-label>
              <input matInput #receiverCompanyMobilePhone formControlName="receiverCompanyMobilePhone" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverCompanyMobilePhone'))"></app-validation-error>
          </div>
          <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>{{ 'country' | translate }}</mat-label>
            <mat-select formControlName="receiverCompanyCountry" (selectionChange)="onCountryChange($event.value.id)">
              <mat-option *ngFor="let country of countries" [value]="country">{{ country.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-md-6 col-sm-12">
            <mat-label>{{ 'city' | translate }}:</mat-label>
            <mat-select formControlName="receiverCompanyCity" (selectionChange)="onCityChange($event.value.id)">
              <mat-option *ngFor="let city of cities" [value]="city">{{
                city.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'street' | translate }}:</mat-label>
              <input matInput #receiverCompanyStreet formControlName="receiverCompanyStreet" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverCompanyStreet'))"></app-validation-error>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="col-12">
              <mat-label>{{ 'buildingNumber' | translate }}:</mat-label>
              <input matInput #receiverCompanyBuildingNumber formControlName="receiverCompanyBuildingNumber" />
            </mat-form-field>
            <app-validation-error [control]="$any(form.get('receiverCompanyBuildingNumber'))"></app-validation-error>
          </div>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{ 'companyArea' | translate }}:</mat-label>
            <input matInput #receiverCompanyArea formControlName="receiverCompanyArea" />
          </mat-form-field>
          <app-validation-error [control]="$any(form.get('receiverCompanyArea'))"></app-validation-error>
        </div>

      </mat-card-content>
    </mat-card>

    <div class="mt-3" *ngIf="receiverCustomerTypeId == customerType.Corporate">
      <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Conductors
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div appearance="fill" class="col">
              <ng-select #receiverConductorSelect formControlName="conductorId" (change)="onConductorChanged($event)"
                         placeholder="{{ 'conductors' | translate }}" [clearable]="true" (clear)="onConductorCleared()">
                <ng-option *ngFor="let receiverConductor of receiverConductors" [value]="receiverConductor.id">{{ receiverConductor?.firstName + ' ' +
                receiverConductor?.lastName }}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'firstName' | translate }}:</mat-label>
                <input matInput #receiverConductorFirstName formControlName="receiverConductorFirstName" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorFirstName'))"></app-validation-error>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'lastName' | translate }}:</mat-label>
                <input matInput #receiverConductorLastName formControlName="receiverConductorLastName" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorLastName'))"></app-validation-error>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'mobile' | translate }}:</mat-label>
                <input matInput #receiverConductorMobilePhone formControlName="receiverConductorMobilePhone" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorMobilePhone'))"></app-validation-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'placeOfBirth' | translate }}:</mat-label>
                <input matInput #receiverConductorPlaceOfBirth formControlName="receiverConductorPlaceOfBirth" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorPlaceOfBirth'))"></app-validation-error>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'dob' | translate }}</mat-label>
                <input matInput [max]="maxDOB" [matDatepicker]="dob" formControlName="receiverConductorDateOfBirth" />
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #dob></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'position' | translate }}:</mat-label>
                <input matInput #receiverConductorPosition formControlName="receiverConductorPosition" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorPosition'))"></app-validation-error>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'nationality' | translate }}</mat-label>
                <mat-select formControlName="receiverConductorNationality" (selectionChange)="onConductorCountrySelected($event)">
                  <mat-option *ngFor="let country of allCountries" [value]="country">{{ country.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-3" *ngIf="isThisTrkCountry">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'fatherName' | translate }}:</mat-label>
                <input matInput #receiverConductorFartherName formControlName="receiverConductorFartherName" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorFartherName'))"></app-validation-error>
            </div>
            <div class="col-md-3" *ngIf="isThisTrkCountry">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>{{ 'motherName' | translate }}:</mat-label>
                <input matInput #receiverConductorMotherName formControlName="receiverConductorMotherName" />
              </mat-form-field>
              <app-validation-error [control]="$any(form.get('receiverConductorMotherName'))"></app-validation-error>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 mb-4">
              <div appearance="outline" class="col pt-2">
                <mat-label>{{ 'authorizedSignatureUpload' | translate }}</mat-label>
                <input type="file" style="display: none" accept=".png, .jpg, .jpeg, .pdf"
                       (change)="onAuthorizedSignatureDocAttachmentSelected($event)" #authorizedSignatureFile />
                <button mat-mini-fab color="primary" class="mx-1" type="button"
                        (click)="authorizedSignatureFileUploadElem.nativeElement.click()">
                  <mat-icon>attach_file</mat-icon>
                </button>
                <mat-label *ngFor="let file of authorizedSignatureDocs">
                </mat-label>
              </div>

              <div class="col-md-6">
                <mat-label *ngFor="let file of authorizedSignatureDocs">
                  <div *ngIf="file && file?.type !== 'application/pdf'">
                    <img class="img-thumbnail" [src]="file.url" style="width: auto; height: 100px"
                         (click)="onViewAttachment(file.url, file?.type)" />
                    <mat-icon (click)="clearSelectedAuthorizedDocAttachment()">cancel</mat-icon>
                  </div>

                  <div *ngIf="file && file?.type === 'application/pdf'">
                    <mat-icon style="font-size: 50px; color: gray; margin-right: 5px; margin-top:10px"
                              (click)="onViewAttachment(file.url, file?.type)">picture_as_pdf</mat-icon>
                    <mat-icon (click)="clearSelectedAuthorizedDocAttachment()">cancel</mat-icon>
                  </div>

                </mat-label>

              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <mat-card *ngIf="senderSelected && receiverSelected" class="mt-3">
      <mat-card-title>{{ 'primaryDocument' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>{{ 'previousDocuments' | translate }}</mat-label>
                <mat-select
                  formControlName="primaryDocs"
                  [value]="receiverPrimaryDoc?.id"
                  (selectionChange)="
                    onCustomerDocSelected($event.value, 'primary')
                  "
                >
                  <mat-option
                    *ngFor="let doc of receiverPrimaryDocs"
                    [value]="doc.id"
                    >{{ doc.displayName }}</mat-option
                  >
                </mat-select>
                <mat-icon
                  *ngIf="receiverPrimaryDoc"
                  class="clear_document"
                  (click)="resetDoc('primary')"
                  matTooltip="Clear selected document"
                  >cancel</mat-icon
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>{{ 'primaryDocTypes' | translate }}</mat-label>
                <mat-select
                  formControlName="primaryDocType"
                  (selectionChange)="onPrimaryDocChange($event)"
                >
                  <mat-option
                    *ngFor="let primaryDocType of primaryDocTypes"
                    [value]="primaryDocType"
                  >
                    {{ primaryDocType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col" *ngIf="requiredFields?.documentNumberIsRequired">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ (isPassportDocTypeSelected ? 'passportNumber' : 'docNumber') | translate }}:</mat-label>
                  <input
                    matInput
                    #primaryDocNumber
                    formControlName="primaryDocNumber"
                    (focusout)="onFocusout($event)"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('primaryDocNumber'))"
                ></app-validation-error>
              </div>
              <div class="col" *ngIf="requiredFields?.documentIssuerIsRequired">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'primaryDocIssuedBy' | translate }}:</mat-label>
                  <input
                    matInput
                    #primaryDocIssuedBy
                    formControlName="primaryDocIssuedBy"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('primaryDocIssuedBy'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row">
              <div *ngIf="requiredFields?.idNumberIsRequired" class="col">
                <mat-form-field appearance="outline" class="col-md-12 col-sm-12">
                  <mat-label>{{ 'idNumber' | translate }}:</mat-label>
                  <input
                    matInput
                    #idNumber
                    formControlName="idNumber"
                    (focusout)="onFocusout($event)"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('idNumber'))"
                ></app-validation-error>
              </div>

              <div *ngIf="requiredFields?.serialNumberIsRequired" class="col">
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>{{ 'serialNumber' | translate }}:</mat-label>
                  <input
                    matInput
                    #serialNumber
                    formControlName="serialNumber"
                    (focusout)="onFocusout($event)"
                  />
                </mat-form-field>
                <app-validation-error
                  [control]="$any(form.get('serialNumber'))"
                ></app-validation-error>
              </div>
            </div>
            <div class="row">
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.issueDateIsRequired">
                <mat-label>{{ 'primaryDocIssueDate' | translate }}</mat-label>
                <input
                  matInput
                  [max]="today"
                  [matDatepicker]="primaryDocIssueDate"
                  formControlName="primaryDocIssueDate"
                />
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="primaryDocIssueDate"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  startView="month"
                  #primaryDocIssueDate
                ></mat-datepicker>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.expiryDateIsRequired">
                <mat-label>{{ 'primaryDocExpiryDate' | translate }}</mat-label>
                <input
                  matInput
                  [min]="today"
                  [matDatepicker]="primaryDocExpiryDate"
                  formControlName="primaryDocExpiryDate"
                />
                <mat-hint>DD-MM-YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="primaryDocExpiryDate"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  startView="month"
                  #primaryDocExpiryDate
                ></mat-datepicker>
              </mat-form-field>
            </div>

            <div class="row mt-2">
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.motherNameIsRequired">
                <mat-label>{{ 'motherName' | translate }}:</mat-label>
                <input matInput #motherName formControlName="motherName" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('motherName'))"
              ></app-validation-error>

              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.nationalityIsRequired">
                <mat-label>{{ 'nationality' | translate }}</mat-label>
                <mat-select formControlName="receiverNationality">
                  <mat-option
                    *ngFor="let country of allCountries"
                    [value]="country"
                  >{{ country.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div class="row">
              <mat-form-field appearance="outline" class="col" *ngIf="requiredFields?.fatherNameIsRequired">
                <mat-label>{{ 'fatherName' | translate }}:</mat-label>
                <input matInput #fatherName formControlName="fatherName" />
              </mat-form-field>
              <app-validation-error
                [control]="$any(form.get('fatherName'))"
              ></app-validation-error>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row mb-3">
              <div appearance="outline" class="col pt-2">
                <input
                  type="file"
                  style="display: none"
                  accept=".png, .jpg, .jpeg, .pdf"
                  (change)="onDocAttachmentSelected($event)"
                  #primaryDocFile
                />
                <button
                  mat-mini-fab
                  color="primary"
                  class="mx-1"
                  type="button"
                  (click)="primaryDocFileElem.nativeElement.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
                <mat-label *ngFor="let file of docFile"
                  >{{ file?.name }}<span *ngIf="file?.name">, </span></mat-label
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-2" *ngFor="let file of docFile; let i = index">
                <div *ngIf="file && file?.type !== 'application/pdf'">
                  <img
                    class="img-thumbnail"
                    [src]="file.url"
                    style="width: auto; height: 100px"
                    (click)="onViewAttachment(file.url, file?.type)"
                  />
                  <div>
                    <mat-icon
                      *ngIf="!receiverPrimaryDoc"
                      (click)="clearSelectedDocAttachment(file)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
                <div *ngIf="file && file?.type === 'application/pdf'">
                  <mat-icon
                    style="font-size: 50px; color: gray; margin-right: 50px"
                    (click)="onViewAttachment(file.url, file?.type)"
                    >picture_as_pdf</mat-icon
                  >
                  <div>
                    <mat-icon
                      *ngIf="!receiverPrimaryDoc"
                      (click)="clearSelectedDocAttachment(file)"
                      >cancel</mat-icon
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{'secondaryDocs' | translate}}</mat-label>
            <mat-select formControlName="secondaryDocs" [value]="receiverSecondaryDoc?.id"
              (selectionChange)="onCustomerDocSelected($event.value, 'secondary')">
              <mat-option *ngFor="let doc of receiverSecondaryDocs" [value]="doc.id">{{doc.displayName}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-3">
            <mat-label>{{'secondaryDocTypes' | translate}}</mat-label>
            <mat-select formControlName="secondaryDocType" (selectionChange)="onSecondaryDocChange($event)">
              <mat-option *ngFor="let secondaryDocType of secondaryDocTypes" [value]="secondaryDocType">
                {{secondaryDocType.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div appearance="outline" class="col-3 pt-2">
            <input type="file" style="display: none;" accept=".png, .jpg, .jpeg, .pdf"
              (change)="onDocAttachmentSelected('secondary', $event)" #secondaryDocFile>
            <button *ngIf="receiverSecondaryDoc?.documentPaths?.length" mat-mini-fab color="primary" class="mx-1"
              type="button" (click)="onViewAttachment(receiverSecondaryDoc?.documentPaths)">
              <mat-icon>search</mat-icon>
            </button>
            <button mat-mini-fab color="primary" class="mx-1" type="button"
              (click)="secondaryDocFileElem.nativeElement.click()">
              <mat-icon>attach_file</mat-icon>
            </button>
            <mat-label>{{receiverSecondaryDocFile?.name || ('uploadAttachment' | translate)}}</mat-label>
          </div>
        </div>
        <div class="row">
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{'secondaryDocNumber' | translate}}:</mat-label>
            <input matInput #secondaryDocNumber formControlName="secondaryDocNumber">
          </mat-form-field>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{'secondaryDocIssueDate' | translate}}</mat-label>
            <input matInput [min]="today" [matDatepicker]="secondaryDocIssueDate"
              formControlName="secondaryDocIssueDate">
            <mat-hint>DD-MM-YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="secondaryDocIssueDate"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #secondaryDocIssueDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{'secondaryDocExpiryDate' | translate}}</mat-label>
            <input matInput [matDatepicker]="secondaryDocExpiryDate" formControlName="secondaryDocExpiryDate">
            <mat-hint>DD-MM-YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="secondaryDocExpiryDate"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #secondaryDocExpiryDate></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col">
            <mat-label>{{'secondaryDocIssuedBy' | translate}}:</mat-label>
            <input matInput #secondaryDocIssuedBy formControlName="secondaryDocIssuedBy">
          </mat-form-field>
        </div> -->
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="isTaajRemittance" class="mt-3">
      <mat-card-title>{{ 'purposeAndRelationship' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <mat-form-field appearance="fill" class="col-md-4">
            <mat-label>{{ 'purpose' | translate }}</mat-label>
            <mat-select formControlName="purpose">
              <mat-option *ngFor="let purpose of purposes" [value]="purpose">{{
                purpose.name
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="col-md-4">
            <mat-label>{{ 'relationship' | translate }}</mat-label>
            <mat-select formControlName="relationship">
              <mat-option
                *ngFor="let relationship of relationships"
                [value]="relationship"
                >{{ relationship.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="senderSelected && receiverSelected" class="mt-3">
      <mat-card-title>{{ 'paymentInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>{{ 'remittanceType' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #remittanceType
                  formControlName="remittanceType"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>{{ 'remittanceSubtype' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #remittanceSubType
                  formControlName="remittanceSubType"
                />
              </mat-form-field>
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>{{ 'paymentType' | translate }}</mat-label>
                <mat-select formControlName="paymentType">
                  <mat-option
                    *ngFor="let paymentType of paymentTypes"
                    [value]="paymentType"
                  >
                    {{ paymentType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>{{ 'tellerAccount' | translate }}</mat-label>
                <mat-select
                  formControlName="receiverAccount"
                  (selectionChange)="onAccountChange()"
                >
                  <mat-option
                    *ngFor="let tellerAccount of tellerAccounts"
                    [value]="tellerAccount"
                  >
                    {{ tellerAccount.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>{{ 'amount' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #payingAmount
                  formControlName="payingAmount"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label
                  >{{ 'amount' | translate
                  }}{{ this.payingBranchCurrency }}:</mat-label
                >
                <input
                  [readonly]="true"
                  matInput
                  #payingBranchAmount
                  formControlName="payingBranchAmount"
                />
              </mat-form-field>
            </div>
            <div class="row">
              <p class="h6">{{ 'comments' | translate }}</p>
              <mat-form-field class="w-100 mt-2 col" appearance="outline">
                <mat-label>{{ 'comments' | translate }}</mat-label>
                <textarea
                  matInput
                  formControlName="receiverComments"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-end my-3">
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/remittances"
      >
        {{ 'back' | translate }}
      </button>
      <button
        *ngIf="senderSelected && receiverSelected"
        mat-raised-button
        type="submit"
        color="primary"
      >
        {{ 'payRemittance' | translate }}
      </button>
      <!--      [disabled]="!form.valid"-->
    </div>
  </form>
</app-form-container>
