<h1 mat-dialog-title>{{ 'remittanceHistory' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 11px; margin-top: 18px">
    <!-- widget content -->
    <div class="widget-body" style="padding: 0px 10px">
      <table class="table table-bordered table-striped">
        <tbody>
          <tr style="background-color: #c3c4be; font-weight: bold">
            <td colspan="4">{{ 'remittanceInformation' | translate }}</td>
          </tr>
          <tr>
            <td>{{ 'remittanceId' | translate }}</td>
            <td>{{ data.code }}</td>
            <td>{{ 'status' | translate }}</td>
            <td>{{ data.status }}</td>
          </tr>
          <tr>
            <td width="20%">{{ 'senderName' | translate }}</td>
            <td width="30%">
              <a>{{ data.senderFullName }}</a>
            </td>
            <td width="20%">{{ 'receiverName' | translate }}</td>
            <td width="30%">
              <a>{{ data.receiverFullName }}</a>
            </td>
          </tr>
          <tr>
            <td>{{ (data.isTaajRemittance ? 'sendingCountry' : 'sendingBranch') | translate }}</td>
            <td>{{ data.isTaajRemittance ? data.sendingCountry : data.sendingBranch }}</td>
            <td *ngIf="data.status === 'Paid'">
              {{ 'payingBranch' | translate }}
            </td>
            <td *ngIf="data.status === 'Paid'">{{ data.receivingBranch }}</td>
            <td *ngIf="data.status !== 'Paid'">
              {{ 'payingCountry' | translate }}
            </td>
            <td *ngIf="data.status !== 'Paid'">{{ data.receivingCountry }}</td>
          </tr>
          <tr>
            <td>{{ 'dateSend' | translate }}</td>
            <td>
              <span
                style="border-bottom: 1px dotted"
                data-toggle="tooltip"
                title="{{ convertDateToLocaleString(data.sendDate) }}"
                >{{ convertDateToLocaleString(data.sendDate) }}</span
              >
            </td>
            <td>{{ 'actionDate' | translate }}</td>
            <td>
              <span
                style="border-bottom: 1px dotted"
                data-toggle="tooltip"
                title=""
              >
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ 'approvalStatus' | translate }}</td>
            <td>
              <span *ngFor="let approvalStatus of data.remittanceApprovals">
                {{ approvalStatus.approvalType | translate }} :
                {{ setIsApproved(approvalStatus.isApproved) | translate }} ,
              </span>
            </td>
            <td>{{ 'tracingStatus' | translate }}</td>
            <td>
              {{ (data.isTraced ? 'traced' : 'notTraced') | translate }}
            </td>
          </tr>
          <tr
            *ngIf="
              authService.isUserAuthorized(permissions.VIEW_REMITTANCE_CODE)
            "
          >
            <td>{{ 'verificationCode' | translate }}</td>
            <td colspan="3">{{ data.verificationPin }}</td>
          </tr>
          <tr>
            <td>{{ 'paymentAmount' | translate }}</td>
            <td>{{ round(data.amount) }} ( USD )</td>
            <td>{{ 'serviceChanges' | translate }}</td>
            <td>{{ round(data.commission) }} ( USD )</td>
          </tr>
          <tr>
            <td>{{ 'totalAmount' | translate }}</td>
            <td>{{ round(data.totalAmount) }} ( USD )</td>
            <td>{{ 'remittanceType' | translate }}</td>
            <td>{{ data.remittanceSubType }}</td>
          </tr>

          <tr>
            <td>{{ 'sendingLocalAmount' | translate }}</td>
            <td>{{ round(data.sendingLocalAmount) }} ( {{ data.sendingCurrencyCode }} )</td>
            <td>{{ 'Local Commission Amount' | translate }}</td>
            <td>{{ round(data.localCommissionAmount) }} ( {{ data.sendingCurrencyCode }} )</td>
          </tr>
          <tr>
            <td>{{ 'Receiving Local Amount' | translate }}</td>
            <td>{{ round(data.receivingAmount) }} ( {{ data.receivingCurrencyCode }} )</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>{{ 'sendingPaymentMethod' | translate }}</td>
            <td>{{ data.paymentType }}</td>
            <td>{{ 'payingPaymentMethod' | translate }}</td>
            <td>{{ checkNullValue(data.paymentType) }}</td>
          </tr>
          <tr>
            <td>{{ 'remittancePurpose' | translate }}</td>
            <td>{{ data.remittancePurpose }}</td>
            <td>{{ 'remittanceRelationship' | translate }}</td>
            <td>{{ data.remittanceRelationship }}</td>
          </tr>
          <tr>
            <td *ngIf="data.destinationScore">Destination Country Score</td>
            <td *ngIf="data.destinationScore">{{ data.destinationScore }}</td>

            <td *ngIf="data.remittancePurposeScore">Remittance Purpose Score</td>
            <td *ngIf="data.remittancePurposeScore">{{ data.remittancePurposeScore }}</td>
          </tr>

          <tr>
            <td *ngIf="data.remittanceVolumeScore">Remittance Volume Score</td>
            <td *ngIf="data.remittanceVolumeScore">{{ data.remittanceVolumeScore }}</td>
          
            <td *ngIf="data.occupationScore">Occupation Score</td>
            <td *ngIf="data.occupationScore">{{ data.occupationScore }}</td>
          </tr>

          <tr *ngIf="data.overallCustomerRiskRatingScore">
            <td>Overall Customer Risk Rating Score</td>
            <td> {{data.overallCustomerRiskRatingScore}}</td>
          </tr>
        </tbody>
      </table>
      <table
        *ngIf="data.partnerRemittanceId"
        class="table table-bordered table-striped"
      >
        <tbody>
          <tr style="background-color: #c0bbb7; font-weight: bold">
            <td colspan="4">
              {{ 'partnerRemittanceInformation' | translate }}
            </td>
          </tr>
          <tr>
            <td>{{ 'partnerRemittanceId' | translate }}</td>
            <td>{{ data.partnerRemittanceId }}</td>
          </tr>
          <tr>
            <td width="20%">{{ 'partnerState' | translate }}</td>
            <td width="30%">{{ data.partnerStatus }}</td>
            <td width="20%">{{ 'localState' | translate }}</td>
            <td width="30%">
              <a>{{ data.status }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered table-striped">
        <tbody>
          <tr style="background-color: #c0bbb7; font-weight: bold">
            <td colspan="5">{{ 'history' | translate }}</td>
          </tr>
          <tr>
            <th>{{ 'action' | translate }}</th>
            <th>{{ 'comment' | translate }}</th>
            <th>{{ 'date' | translate }}</th>
            <th>{{ 'user' | translate }}</th>
            <th>{{ 'actions' | translate }}</th>
          </tr>
          <tr *ngFor="let row of data.remittanceHistories">
            <td>{{ row.action }}</td>
            <td>{{ row.comments }}</td>
            <td>
              <span
                style="border-bottom: 1px dotted"
                data-toggle="tooltip"
                title="{{ convertDateToLocaleString(row.date) }}"
                >{{ convertDateToLocaleString(row.date) }}</span
              >
            </td>
            <td>{{ row.user }}</td>
            <td>
              <button
                class="btn btn-outline-info btn-sm"
                *ngIf="
                  authService.isUserAuthorized(permissions.VIEW_CHANGE_LOG)
                "
                (click)="viewChangeLog(row)"
              >
                {{ 'viewChangeLog' | translate }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-stroked-button color="warn" mat-dialog-close="close">
    {{ 'close' | translate }}
  </button>
</div>
