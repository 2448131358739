import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CRUDResponseDto } from 'src/app/_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import { map } from 'rxjs/operators';
import { customerDocs, LookupsDto } from './lookups.dto';

@Injectable()
export class LookupsService {
  private _remittancePurposes = new BehaviorSubject<LookupsDto[]>([]);
  private remittancePurposesObservable?: Observable<LookupsDto[]>;
  private _remittanceRelationships = new BehaviorSubject<LookupsDto[]>([]);
  private remittanceRelationshipsObservable?: Observable<LookupsDto[]>;
  private _occupations = new BehaviorSubject<LookupsDto[]>([]);
  private occupationsObservable?: Observable<LookupsDto[]>;
  private _customerDocumentTypes = new BehaviorSubject<customerDocs[]>([]);
  private customerDocumentTypesObservable?: Observable<customerDocs[]>;
  private _sourceOfFund = new BehaviorSubject<LookupsDto[]>([]);
  private sourceOfFundObservable?: Observable<LookupsDto[]>;
  private _cities = new BehaviorSubject<LookupsDto[]>([]);
  private citiesObservable?: Observable<LookupsDto[]>;
  private _localities = new BehaviorSubject<LookupsDto[]>([]);
  private localitiesObservable?: Observable<LookupsDto[]>;
  constructor(private httpService: HttpService) {}

  get remittancePurposes() {
    if (!this.remittancePurposesObservable)
      this.remittancePurposesObservable = this._remittancePurposes;
    return this.remittancePurposesObservable;
  }

  get remittanceRelationships() {
    if (!this.remittanceRelationshipsObservable)
      this.remittanceRelationshipsObservable = this._remittanceRelationships;
    return this.remittanceRelationshipsObservable;
  }

  get occupations() {
    if (!this.occupationsObservable)
      this.occupationsObservable = this._occupations;
    return this.occupationsObservable;
  }

  get customerDocumentTypes() {
    if (!this.customerDocumentTypesObservable)
      this.customerDocumentTypesObservable = this._customerDocumentTypes;
    return this.customerDocumentTypesObservable;
  }

  get sourceOfFunds() {
    if (!this.sourceOfFundObservable)
      this.sourceOfFundObservable = this._sourceOfFund;
    return this.sourceOfFundObservable;
  }

  get cities() {
    if (!this.citiesObservable) this.citiesObservable = this._cities;
    return this.citiesObservable;
  }

  get localities() {
    if (!this.localitiesObservable)
      this.localitiesObservable = this._localities;
    return this.localitiesObservable;
  }

  getRemittancePurposes() {
    this.httpService
      .get<LookupsDto[]>('lookups/remittance/purposes')
      .subscribe((purposes) => {
        this._remittancePurposes.next(purposes);
      });
  }

  getRemittancePurpose(id: number | undefined) {
    return this.httpService.get<LookupsDto>('lookups/remittance/purposes', {
      id,
    });
  }

  getRemittanceRelationship(id: number | undefined) {
    return this.httpService.get<LookupsDto>(
      'lookups/remittance/relationships',
      { id },
    );
  }

  getOccupation(id: number | undefined) {
    return this.httpService.get<LookupsDto>('lookups/occupations', { id });
  }

  getCustomerDocumentType(id: number | undefined) {
    return this.httpService.get<LookupsDto>('lookups/customerdocumenttypes', {
      id,
    });
  }

  getSourceOfFund(id: number | undefined) {
    return this.httpService.get<LookupsDto>('lookups/sourceoffunds', { id });
  }

  getCity(countryId: number | undefined, id: number | undefined) {
    return this.httpService.get<LookupsDto>('lookups/cities', {
      id,
      countryId,
    });
  }

  getLocality(cityId: number | undefined, id: number | undefined) {
    return this.httpService.get<LookupsDto>('lookups/localities', {
      id,
      cityId,
    });
  }

  getRemittanceRelationships() {
    this.httpService
      .get<LookupsDto[]>('lookups/remittance/relationships')
      .subscribe((relationships) => {
        this._remittanceRelationships.next(relationships);
      });
  }

  getCities(countryId: number) {
    this.httpService
      .get<LookupsDto[]>('lookups/cities', { countryId })
      .subscribe((cities) => {
        this._cities.next(cities);
      });
  }

  getLocalities(cityId: number) {
    this.httpService
      .get<LookupsDto[]>('lookups/localities', { cityId })
      .subscribe((localities) => {
        this._localities.next(localities);
      });
  }

  getOccupations() {
    this.httpService
      .get<LookupsDto[]>('lookups/occupations')
      .subscribe((occupations) => {
        this._occupations.next(occupations);
      });
  }

  getCustomerDocumentTypes() {
    this.httpService
      .get<customerDocs[]>('lookups/customerdocumenttypes')
      .subscribe((documentTypes) => {
        this._customerDocumentTypes.next(documentTypes);
      });
  }

  getSourceOfFunds() {
    this.httpService
      .get<LookupsDto[]>('lookups/sourceoffunds')
      .subscribe((sourceOfFunds) => {
        this._sourceOfFund.next(sourceOfFunds);
      });
  }

  createRemittancePurpose(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/remittance/purposes/upsert', dto)
      .pipe(
        map((resp) => {
          this.getRemittancePurposes();
          return resp;
        }),
      );
  }

  updateRemittancePurpose(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/remittance/purposes/upsert', dto)
      .pipe(
        map((resp) => {
          this.getRemittancePurposes();
          return resp;
        }),
      );
  }

  createRemittanceRelationship(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/remittance/relationships/upsert', dto)
      .pipe(
        map((resp) => {
          this.getRemittanceRelationships();
          return resp;
        }),
      );
  }

  updateRemittanceRelationship(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/remittance/relationships/upsert', dto)
      .pipe(
        map((resp) => {
          this.getRemittanceRelationships();
          return resp;
        }),
      );
  }

  createOccupation(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/occupations/upsert', dto)
      .pipe(
        map((resp) => {
          this.getOccupations();
          return resp;
        }),
      );
  }

  updateOccupation(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/occupations/upsert', dto)
      .pipe(
        map((resp) => {
          this.getOccupations();
          return resp;
        }),
      );
  }

  createCustomerDocumentType(dto: customerDocs): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/customerdocumenttypes/upsert', dto)
      .pipe(
        map((resp) => {
          this.getCustomerDocumentTypes();
          return resp;
        }),
      );
  }

  updateCustomerDocumentType(dto: customerDocs): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/customerdocumenttypes/upsert', dto)
      .pipe(
        map((resp) => {
          this.getCustomerDocumentTypes();
          return resp;
        }),
      );
  }

  createSourceOfFund(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/sourceoffunds/upsert', dto)
      .pipe(
        map((resp) => {
          this.getSourceOfFunds();
          return resp;
        }),
      );
  }

  updateSourceOfFund(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/sourceoffunds/upsert', dto)
      .pipe(
        map((resp) => {
          this.getSourceOfFunds();
          return resp;
        }),
      );
  }

  createCity(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/cities/upsert', dto)
      .pipe(
        map((resp) => {
          this.getCities(dto.parentId as number);
          return resp;
        }),
      );
  }

  updateCity(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/cities/upsert', dto)
      .pipe(
        map((resp) => {
          this.getCities(dto.parentId as number);
          return resp;
        }),
      );
  }

  createLocality(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/localities/upsert', dto)
      .pipe(
        map((resp) => {
          this.getLocalities(dto.parentId as number);
          return resp;
        }),
      );
  }

  updateLocality(dto: LookupsDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('lookups/localities/upsert', dto)
      .pipe(
        map((resp) => {
          this.getLocalities(dto.parentId as number);
          return resp;
        }),
      );
  }
}
