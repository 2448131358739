import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomerService } from './data/customer.service';
import { CustomerStatusResponseDto } from './data/dto/customerStatusResponse.dto';

@Component({
  selector: 'app-comment',
  templateUrl: './commentPopup.component.html',
  styleUrls: ['./commentPopup.component.css']
})
export class CommentPopupComponent {
  customerId!: number;
  statusId!: number;
  customerStatus: any;
  comment: string = '';

  constructor(
    private dialogRef: MatDialogRef<CommentPopupComponent>,  
    private customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) public data: { customerId: number, statusId: number}
  ) {
    this.customerId = data.customerId;
    this.statusId = data.statusId;
  }

  onSubmit(commentForm: any) {
    const comment = commentForm.value.comment;
    this.customerService.updateCustomerStatus(this.customerId, this.statusId, comment).subscribe(
      (response: CustomerStatusResponseDto) => {
        this.customerStatus = response.customerStatus;
        this.dialogRef.close(this.customerStatus);
      },
      (error) => {
        console.error('Error updating customer status:', error);
      }
    );
  }
}
