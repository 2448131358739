import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {RemittanceFilter, RemittanceService} from './data/remittance.service';
import { CRUDResponseDto, LookupDto } from '../_common/data/dto/api.dto';
import {
  RemittanceForPaymentResponse,
  RemittanceForPaymentResponseDto,
} from './data/dto/remittances.dto';
import {
  CustomerDocTypesResponseDto,
  GetCustomerDto, upsertConductor,
  UpsertCustomerDto,
} from '../customer/data/dto/customer.dto';
import {CreateConductorDocumentDto, CustomerDocumentDto} from '../customer/data/dto/customerDocument.dto';
import { CustomerService } from '../customer/data/customer.service';
import { SnackBarService } from '../_common/snackBar.service';
import {forkJoin, Observable, ObservableInput, of, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {RemittanceCompliancePopupComponent} from './remittanceCompliancePopup.component';
import {RemittancePayReceiptPopupComponent} from './remittancePayReceiptPopup.component';
import {AttachmentsPopupComponent} from './attachmentsPopup.component';
import {BranchesService} from '../branch/data/branches.service';
import {DomSanitizer} from '@angular/platform-browser';
import {regExp} from '../_common/data/validationRules';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {GetBranchCurrencyDto} from '../branch/data/dto/branches.dto';
import moment from 'moment';
import {CountryISO2s, CustomerTypes} from "../_common/enums/systemBranches.enums";

@Component({
  selector: 'app-pay-remittance',
  templateUrl: './payRemittance.component.html',
})
export class PayRemittanceComponent implements OnInit {
  form!: UntypedFormGroup;
  // @ts-ignore
  @ViewChild('primaryDocFile') primaryDocFileElem: ElementRef;
  // @ts-ignore
  @ViewChild('secondaryDocFile') secondaryDocFileElem: ElementRef;

  // @ts-ignore
  @ViewChild('authorizedSignatureFile') authorizedSignatureFileUploadElem: ElementRef;

  // @ts-ignore
  @ViewChild('payRemittanceForm') el: ElementRef;

  tellerAccounts: LookupDto[] = [];
  paymentTypes: LookupDto[] = [];
  remittance?: RemittanceForPaymentResponseDto;
  countries: LookupDto[] = [];
  allCountries: LookupDto[] = [];
  customerDocTypes: CustomerDocTypesResponseDto[] = [];
  primaryDocTypes: LookupDto[] = [];
  secondaryDocTypes: LookupDto[] = [];
  receiverDocs: CustomerDocumentDto[] = [];
  receiverPrimaryDocs: CustomerDocumentDto[] = [];
  receiverPrimaryDocFile?: File[] = [];
  receiverPrimaryDoc?: CustomerDocumentDto;
  receiverSecondaryDocs: CustomerDocumentDto[] = [];
  maxDOB = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate(),
  );
  today = new Date();
  receiverSecondaryDocFile?: File;
  receiverSecondaryDoc?: CustomerDocumentDto;
  customerGenders: LookupDto[] = [];
  customerOccupations: LookupDto[] = [];
  customerSourceOfIncome: LookupDto[] = [];
  localities: LookupDto[] = [];
  cities: LookupDto[] = [];
  primaryDocFileUrl: any[] = [];
  documentPathArray: string[] | undefined;
  imageURL: any;
  docTypeSelected!: CustomerDocTypesResponseDto;

  senderCustomers: GetCustomerDto[] = [];
  receiverCustomers: GetCustomerDto[] = [];
  senderCustomer: GetCustomerDto = {} as GetCustomerDto;
  receiverCustomer: GetCustomerDto = {} as GetCustomerDto;
  multipleSenders: boolean = true;
  multipleReceivers: boolean = true;
  senderSelected: boolean = false;
  receiverSelected: boolean = false;
  numberOfAttachmentsRequired: number = 0;
  displayedColumns: string[] = [
    '#',
    'firstName',
    'lastName',
    'mobilePhone',
    'actions',
  ];
  senderCustomersTable = new MatTableDataSource<GetCustomerDto>();
  receiverCustomersTable = new MatTableDataSource<GetCustomerDto>();
  senderResultsLength = 0;
  receiverResultsLength = 0;
  payingExchangeRate? = 0;
  payingAmount? = 0;
  payingBranchExchangeRate = 0;
  payingBranchAmount = 0;
  payingBranchCurrency = '';
  branchExchangeRates: GetBranchCurrencyDto[] = [];
  selectedDoctype: any;
  panelOpenState: boolean = false;
  selectedConductor: any = null;
  // @ts-ignore
  @ViewChild('scPaginator') scPaginator: MatPaginator;
  @ViewChild('rcPaginator')
  rcPaginator!: MatPaginator;
  docFile: any[] = [];
  isPdfFile: boolean = false;
  requiredFields: any;
  isPassportDocTypeSelected: boolean = false;
  customerTypeId: any = CustomerTypes.Person;
  receiverCustomerTypeId: any = CustomerTypes.Person;
  receiverConductors: any;
  receiverConductor: any;
  isThisTrkCountry: boolean = false;
  authorizedSignatureDocFileUrl: any[] = [];
  authorizedSignatureDocs: any[] = [];
  authorizedSignatureDocFile: any[] = [];
  conductors: any;
  customerType = CustomerTypes
  allCustomerTypes: any;
  purposes: LookupDto[] = [];
  relationships: LookupDto[] = [];
  isTaajRemittance: boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private remittanceService: RemittanceService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private branchService: BranchesService,
    private sanitizer: DomSanitizer,
    private tranlateService: TranslateService,
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe(
      ({
         tellerAccounts,
         remittanceForPaymentResp,
         paymentTypes,
         countries,
         customerDocTypes,
         customerGenders,
         customerOccupations,
         customerSourceOfIncome,
         cities,
         customerTypesData,
	  remittancePurposes,
        remittanceRelationships,
       }) => {
        this.tellerAccounts = tellerAccounts;
        this.countries = countries;
        this.allCountries = countries;
        this.paymentTypes = paymentTypes;
        this.customerDocTypes = [...customerDocTypes].filter((f) => f.isActive);
        this.customerGenders = customerGenders;
        this.allCustomerTypes = customerTypesData;
        this.customerOccupations = [...customerOccupations].filter(
          (f) => f.isActive != 0,
        );
        this.customerSourceOfIncome = [...customerSourceOfIncome].filter(
          (f) => f.isActive != 0,
        );
        this.cities = [...cities].filter((f) => f.isActive);
        this.primaryDocTypes = this.filterDocTypes(true, null);
        this.secondaryDocTypes = this.filterDocTypes(false, null);
        this.purposes = [...remittancePurposes].filter((f) => f.isActive);
        this.relationships = [...remittanceRelationships].filter(
          (f) => f.isActive,
        );

        const {
          remittance,
          payingBranchExchangeRate,
          payingBranchAmount,
          payingBranchCurrency,
          branchExchangeRates,
        } = remittanceForPaymentResp as RemittanceForPaymentResponse;
        if (!remittance) {
          this.snackBar.open('remittanceNotFound');
          return;
        }

        this.payingBranchExchangeRate = payingBranchExchangeRate;
        this.payingBranchAmount = payingBranchAmount;
        this.payingBranchCurrency = payingBranchCurrency;
        this.branchExchangeRates = branchExchangeRates;

        this.remittance = remittance;


        this.isTaajRemittance = remittance?.isTaajRemittance;
        const { senderCustomers, receiverCustomers, compliances } = remittance;

        let receiverDocs: CustomerDocumentDto[] = [];
        if (receiverCustomers.length == 1) {
          this.multipleReceivers = false;
          this.receiverSelected = true;
          this.receiverCustomer = receiverCustomers[0];
          receiverDocs =
            this.receiverCustomer.docs
              ?.map((d) => ({
                ...d,
                displayName: `${
                  this.customerDocTypes.find((t) => t.id === d.documentTypeId)
                    ?.name
                } - ${d.documentDetails.number}`,
              }))
              .filter((doc) => doc.isActive) || [];
        }
        if (senderCustomers.length == 1) {
          this.multipleSenders = false;
          this.senderSelected = true;
          this.senderCustomer = senderCustomers[0];
        }
        this.customerTypeId = this.senderCustomer.customerTypeId || CustomerTypes.Person;
        this.receiverDocs = receiverDocs;
        this.receiverPrimaryDocs = this.filterReceiverDocs(
          null,
          this.primaryDocTypes,
        );
        this.receiverSecondaryDocs = this.filterReceiverDocs(
          null,
          this.secondaryDocTypes,
        );
        const additionalInfoRequired = !!compliances?.find(
          (c) => c.additionalInfoRequired,
        );
        const primaryDocRequired = !!compliances?.find(
          (c) => c.primaryDocRequired,
        );
        const secondaryDocRequired = !!compliances?.find(
          (c) => c.secondaryDocRequired,
        );
        this.senderCustomersTable.data = senderCustomers;
        this.senderCustomersTable.paginator = this.scPaginator;
        this.senderResultsLength = senderCustomers.length;
        this.receiverCustomersTable.data = receiverCustomers;
        this.receiverCustomersTable.paginator = this.rcPaginator;
        this.receiverResultsLength = receiverCustomers.length;
        this.senderCustomers = senderCustomers;
        this.receiverCustomers = receiverCustomers;
        this.receiverConductor = this.receiverCustomer?.conductors?.length ? this.receiverCustomer.conductors?.find(c => c.id == remittance?.conductorId) : {};
        this.receiverCustomerTypeId = this.receiverCustomer?.customerTypeId || CustomerTypes.Person
        this.receiverConductors = this.receiverCustomer?.conductors;
        this.panelOpenState = this.receiverCustomerTypeId == CustomerTypes.Corporate;


        this.form = this.fb.group({
          remittancePin: [null],
          remittanceCode: [remittance.code],
          senderFirstName: [
            {value: this.senderCustomer.firstName, disabled: true},
          ],
          senderMiddleName: [
            {value: this.senderCustomer.middleName, disabled: true},
          ],
          senderLastName: [
            {value: this.senderCustomer.lastName, disabled: true},
          ],
          senderMobilePhone: [
            {value: this.senderCustomer.mobilePhone, disabled: true},
          ],

          receiverMobilePhone: [
            {value: this.receiverCustomer.mobilePhone, disabled: true},
          ],
          receiverFirstName: [
            {value: this.receiverCustomer.firstName, disabled: true},
          ],
          receiverMiddleName: [
            {value: this.receiverCustomer.middleName, disabled: true},
          ],
          receiverLastName: [
            {value: this.receiverCustomer.lastName, disabled: true},
          ],
          receiverAddressLine1: [
            this.receiverCustomer.addressLine1,
            additionalInfoRequired ? [Validators.required] : undefined,
          ],
          receiverAddressLine2: [
            this.receiverCustomer.addressLine2,
            additionalInfoRequired ? [Validators.required] : undefined,
          ],
          receiverCity: [
            this.receiverCustomer.cityId,
            additionalInfoRequired ? [Validators.required] : undefined,
          ],
          receiverCountry: [
            this.countries.find(
              (c) => c.id === this.receiverCustomer.countryId,
            ),
            additionalInfoRequired ? [Validators.required] : undefined,
          ],
          receiverNationality: [],
          primaryDocs: [null],
          primaryDocType: [
            null,
            primaryDocRequired ? [Validators.required] : undefined,
          ],
          primaryDocNumber: [
            null,
            primaryDocRequired
              ? [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(20),
                Validators.pattern(regExp.alphaNumericSpace),
              ]
              : undefined,
          ],
          primaryDocIssueDate: [
            null,
            primaryDocRequired ? [Validators.required] : undefined,
          ],
          primaryDocExpiryDate: [
            null,
            primaryDocRequired ? [Validators.required] : undefined,
          ],
          primaryDocIssuedBy: [
            null,
            primaryDocRequired
              ? [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(20),
                Validators.pattern(regExp.alphaNumericSpace),
              ]
              : undefined,
          ],
          secondaryDocs: [null],
          secondaryDocType: [
            null,
            secondaryDocRequired ? [Validators.required] : undefined,
          ],
          secondaryDocNumber: [
            null,
            secondaryDocRequired ? [Validators.required] : undefined,
          ],
          secondaryDocIssueDate: [
            null,
            secondaryDocRequired ? [Validators.required] : undefined,
          ],
          secondaryDocExpiryDate: [
            null,
            secondaryDocRequired ? [Validators.required] : undefined,
          ],
          secondaryDocIssuedBy: [
            null,
            secondaryDocRequired ? [Validators.required] : undefined,
          ],
          receiverAccount: [null, [Validators.required]],
          complianceComments: [null],
          payingAmount: [null, [Validators.required]],
          payingBranchAmount: [
            {value: this.payingBranchAmount, disabled: true},
            [Validators.required],
          ],
          remittanceType: [
            {value: remittance.remittanceType, disabled: true},
          ],
          remittanceSubType: [
            {value: remittance.remittanceSubType, disabled: true},
          ],
          receivingCountry: [remittance.receiverCountry],
          paymentType: [paymentTypes[0]],
          motherName: [],
          fatherName: [null],
          gender: [
            this.customerGenders.find(
              (g) => g.id === this.receiverCustomer.genderId,
            ),
            [Validators.required],
          ],
          dateOfBirth: [
            this.receiverCustomer.dateOfBirth,
            [Validators.required],
          ],
          sourceOfIncome: [
            this.customerSourceOfIncome.find(
              (s) => s.id === this.receiverCustomer.sourceOfIncomeId,
            ),
            [Validators.required],
          ],
          street: [
            this.receiverCustomer.street,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          buildingNumber: [
            this.receiverCustomer.buildingNumber,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          houseNumber: [
            this.receiverCustomer.houseNumber,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          placeOfBirth: [
            this.receiverCustomer.placeOfBirth,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          occupation: [
            this.customerOccupations.find(
              (o) => o.id === this.receiverCustomer.occupationId,
            ),
            [Validators.required],
          ],
          multipleSenders: this.multipleSenders,
          multipleReceivers: this.multipleReceivers,
          receiverComments: [null, [Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
          idNumber: [null],
          serialNumber: [null],
          companyTitle: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: (this.senderCustomer?.firstName + ' ' + this.senderCustomer.middleName + ' ' + this.senderCustomer?.lastName),
            disabled: true
          } : ''],
          tradeRegistryNumber: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.tradeRegistryNumber,
            disabled: true
          } : ''],
          taxIdentificationNumber: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.taxIdentificationNumber,
            disabled: true
          } : ''],
          fieldOfActivity: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.fieldOfActivity,
            disabled: true
          } : ''],
          companyMobilePhone: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.mobilePhone,
            disabled: true
          } : ''],
          companyCountry: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.countries.find((f) => f.id === this.senderCustomer?.countryId),
            disabled: true
          } : ''],
          companyCity: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.cities.find((f) => f.id == this.senderCustomer?.cityId),
            disabled: true
          } : null],
          companyStreet: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.street,
            disabled: true
          } : ''],
          companyBuildingNumber: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.buildingNumber,
            disabled: true
          } : ''],
          companyArea: [this.senderCustomer.customerTypeId == CustomerTypes.Corporate ? {
            value: this.senderCustomer?.companyArea,
            disabled: true
          } : ''],
          receiverCustomerTypeId: [this.receiverCustomer?.customerTypeId],

          receiverCompanyTitle: [this.receiverCustomerTypeId == CustomerTypes.Corporate ? {
            value: (this.receiverCustomer?.firstName + ' ' + this.receiverCustomer?.middleName || '' + ' ' + this.receiverCustomer?.lastName),
            disabled: true
          } : ''],
          receiverTradeRegistryNumber: [this.receiverCustomer?.tradeRegistryNumber ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverTaxIdentificationNumber: [this.receiverCustomer?.taxIdentificationNumber ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverFieldOfActivity: [this.receiverCustomer?.fieldOfActivity,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverCompanyMobilePhone: [this.receiverCustomerTypeId == CustomerTypes.Corporate ? {
            value: this.receiverCustomer.mobilePhone,
            disabled: true
          } : null],
          receiverCompanyCountry: [this.receiverCustomerTypeId == CustomerTypes.Corporate ? this.allCountries.find(country => country.id == this.receiverCustomer.countryId) : '' ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverCompanyCity: [this.receiverCustomerTypeId == CustomerTypes.Corporate ? this.cities.find(cities => cities.id == this.receiverCustomer.cityId): '', this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverCompanyStreet: [this.receiverCustomer?.street,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverCompanyBuildingNumber: [this.receiverCustomer?.buildingNumber ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverCompanyArea: [this.receiverCustomer?.companyArea,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],

          receiverConductorFirstName: [this.receiverConductor?.firstName ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorLastName: [this.receiverConductor.lastName ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorMobilePhone: [this.receiverConductor.mobile, this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorPlaceOfBirth: [this.receiverConductor.placeOfBirth,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorDateOfBirth: [this.receiverConductor.dateOfBirth ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorPosition: [this.receiverConductor.position ,this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorNationality: [this.countries.find((f) => f.id === this.receiverConductor.nationalityId),this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorFartherName: [this.receiverConductor.fartherName , this.isThisTrkCountry && this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          receiverConductorMotherName: [this.receiverConductor.motherName , this.isThisTrkCountry && this.receiverCustomerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          conductorId: [this.receiverConductor?.id],
        });

        if(this.receiverCustomerTypeId == CustomerTypes.Corporate) {
          this.receiverDocs = this.receiverConductor?.conductorDocument
            ?.map((d: any) => ({
              ...d,
              displayName: `${
                this.customerDocTypes.find((t) => t.id === d.documentTypeId)
                  ?.name
              } - ${d.documentDetails.number}`,
              ...d.documentDetails
            }))
            .filter((doc: any) => doc.isActive) || [];

          this.receiverPrimaryDocs = this.filterReceiverDocs(
            null,
            this.primaryDocTypes,
          );
          if(this.receiverConductor?.conductorDocumentAttachmentPath) {
            this.authorizedSignatureDocFile = [];
            this.authorizedSignatureDocs = [];
            var url = this.receiverConductor?.conductorDocumentAttachmentPath?.split("/")?.slice(2)?.join("/")
            this.remittanceService.getRemittanceDocumentAttachments(url).subscribe(
              (blob) => {
                const isPdfFile = false;
                if (blob) {
                  if (url?.indexOf('.pdf') > -1) {
                    blob = new Blob([blob], { type: 'application/pdf' });
                    this.isPdfFile = true;
                  }
                  this.authorizedSignatureDocs?.push({
                    type: url?.indexOf('.pdf') > -1 ? 'application/pdf' : '',
                    name: '',
                    url: this.sanitizer?.bypassSecurityTrustResourceUrl(
                      URL.createObjectURL(blob),
                    ),
                  });

                  this.authorizedSignatureDocFile?.push(blob);
                }
              },
              (err) => {
                this.snackBar.open(err.message);
              },
            );
          }

          this.setFieldsRequiredByCustomerTypeId();
        }

        if(this.isTaajRemittance){
          this.form.addControl('purpose', this.fb.control(
            this.purposes.find((s) => s.id === this.remittance?.purposeId),
            [Validators.required]
          ));

          this.form.addControl('relationship', this.fb.control(
            this.relationships.find((s) => s.id === this.remittance?.relationshipId),
            [Validators.required]
          ));
        }

        this.form.updateValueAndValidity();
        if (compliances?.length) {
          const dialogRef = this.dialog.open(
            RemittanceCompliancePopupComponent,
            {
              width: '850px',
              data: {
                remittanceCompliances: compliances,
              },
            },
          );

          dialogRef.afterClosed().subscribe((resp) => {
            if (resp === 'close') return;

            this.form.get('complianceComments')?.setValue(resp);
          });
        }
      },
    );
    this.getBranchCountryCity(this.countries);
  }

  generateRemittancePin() {
    this.remittanceService.generateRemittancePin(this.remittance?.id).subscribe(
      (resp) => {
        this.snackBar.open(
          resp?.message,
        );
      },
      (err) => {
        this.snackBar.open(err.message);
      },
    );
  }

  resetDoc(docType: string) {
    this.form.controls[`${docType}DocType`].setValue(null);
    this.form.controls[`${docType}DocNumber`].setValue(null);
    this.form.controls[`${docType}DocIssueDate`].setValue(null);
    this.form.controls[`${docType}DocExpiryDate`].setValue(null);
    this.form.controls[`${docType}DocIssuedBy`].setValue(null);

    this.form.controls[`${docType}Docs`].enable();
    this.form.controls[`${docType}DocType`].enable();
    this.form.controls[`${docType}DocNumber`].enable();
    this.form.controls[`${docType}DocIssueDate`].enable();
    this.form.controls[`${docType}DocExpiryDate`].enable();
    this.form.controls[`${docType}DocIssuedBy`].enable();

    if (docType === 'primary') {
      this.primaryDocFileElem.nativeElement.value = '';
      this.receiverPrimaryDoc = undefined;
      this.receiverPrimaryDocFile = undefined;
      this.docFile = [];
    } else {
      this.secondaryDocFileElem.nativeElement.value = '';
      this.receiverSecondaryDoc = undefined;
      this.receiverSecondaryDocFile = undefined;
    }
  }

  onCustomerDocSelected(id: number, docType: string) {
    const doc = (
      docType === 'primary'
        ? this.receiverPrimaryDocs
        : this.receiverSecondaryDocs
    )?.find((d) => d.id === id);
    if (!doc) {
      return;
    }
    this.selectedDoctype = this.customerDocTypes.find(dt => dt.id === doc.documentTypeId);
    this.requiredFields = JSON.parse(this.selectedDoctype?.requiredFields || '{}');
    this.resetDoc(docType);

    docType === 'primary'
      ? (this.receiverSecondaryDocs = this.filterReceiverDocs(
        doc.documentTypeId,
        this.secondaryDocTypes,
      ))
      : (this.receiverPrimaryDocs = this.filterReceiverDocs(
        doc.documentTypeId,
        this.primaryDocTypes,
      ));

    this.form.controls[`${docType}DocType`].setValue(
      this.customerDocTypes.find((dt) => dt.id === doc.documentTypeId),
    );
    this.form.controls[`${docType}DocNumber`].setValue(doc.number);
    this.form.controls[`${docType}DocIssueDate`].setValue(doc.issueDate);
    this.form.controls[`${docType}DocExpiryDate`].setValue(doc.expiryDate);
    this.form.controls[`${docType}DocIssuedBy`].setValue(doc.issuedBy);

    this.form.controls[`idNumber`].setValue(doc?.documentDetails?.idNumber);
    this.form.controls[`serialNumber`].setValue(doc?.documentDetails?.serialNumber);
    this.form.controls[`motherName`].setValue(doc?.documentDetails?.motherName);
    this.form.controls[`fatherName`].setValue(doc?.documentDetails?.fatherName);
    this.form.controls[`receiverNationality`].setValue(this.allCountries.find(
      (f) => f.id === doc?.documentDetails?.nationality,
    ));
    this.form.controls[`primaryDocs`].disable();
    this.form.controls[`${docType}DocType`].disable();
    this.form.controls[`${docType}DocNumber`].disable();
    this.form.controls[`${docType}DocIssueDate`].disable();
    this.form.controls[`${docType}DocExpiryDate`].disable();
    this.form.controls[`${docType}DocIssuedBy`].disable();
    this.form.controls[`idNumber`].disable();
    this.form.controls[`serialNumber`].disable();

    this.form.controls[`motherName`].disable();
    this.form.controls[`fatherName`].disable();
    this.form.controls[`receiverNationality`].disable();

    this.documentPathArray = doc.documentPaths?.map((o) => Object.values(o)[0]);
    if (this.documentPathArray && this.documentPathArray!.length) {
      this.documentPathArray?.forEach((url) => {
        this.customerService.getCustomerDocument(url).subscribe(
          (blob) => {
            if (blob) {
              this.isPdfFile = false;
              if (url.indexOf('.pdf') > -1) {
                blob = new Blob([blob], {type: 'application/pdf'});
                this.isPdfFile = true;
              }
              this.docFile?.push({
                type: this.isPdfFile ? 'application/pdf' : '',
                name: '',
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  URL.createObjectURL(blob),
                ),
              });
            }
          },
          (err) => {
            this.snackBar.open(err.message);
          },
        );
      });
    }

    if (docType === 'primary') {
      this.receiverPrimaryDoc = doc;
    } else {
      this.receiverSecondaryDoc = doc;
    }
  }

  // onDocAttachmentSelected(type: string, e: Event) {
  //   //@ts-ignore
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   if (type === 'primary') {
  //     this.receiverPrimaryDocFile = file;
  //     reader.onload = (event: any) => {
  //       this.primaryDocFileUrl = event.target.result;
  //     }
  //   } else {
  //     this.receiverSecondaryDocFile = file;
  //   }
  //   reader.readAsDataURL(file);
  // }


  onDocAttachmentSelected(e: Event) {
    if (this.form.controls['primaryDocType'].value) {
      this.docTypeSelected = this.form.controls['primaryDocType'].value;
      const reader = new FileReader();
      if (this.docTypeSelected.isTwoPageAttachmentRequired) {
        if (this.receiverPrimaryDocFile!.length > 1) {
          this.snackBar.open(
            `${this.docTypeSelected.name} requires only two attachments.`,
          );
        } else {
          //@ts-ignore
          const file: File[] = e.target.files;
          for (var i = 0; i < file.length; i++) {
            const index = i;
            if (this.docFile.length && this.docFile[i].name === file[i].name) {
              this.snackBar.open(`File already selected with this name`);
              return;
            }
            this.receiverPrimaryDocFile?.push(file[i]);
            reader.onload = (event: any) => {
              this.primaryDocFileUrl.push(
                this.sanitizer.bypassSecurityTrustResourceUrl(
                  event.target.result,
                ),
              );
              this.docFile?.push({
                type: file[index].type,
                name: file[index].name,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  event.target.result,
                ),
              });
              if (
                this.primaryDocFileUrl[0].changingThisBreaksApplicationSecurity.indexOf(
                  'data:application/pdf',
                ) > -1
              ) {
                this.isPdfFile = true;
              }
            };
            reader.readAsDataURL(file[i]);
          }
        }
      } else {
        if (
          this.receiverPrimaryDocFile!.length > 0 &&
          this.docFile.length >= this.numberOfAttachmentsRequired
        ) {
          this.snackBar.open(
            `${this.docTypeSelected.name} requires only ${
              !this.numberOfAttachmentsRequired
                ? this.numberOfAttachmentsRequired + 1
                : this.numberOfAttachmentsRequired
            } file attachment.`,
          );
        } else {
          //@ts-ignore
          const file = e.target.files[0];
          this.receiverPrimaryDocFile?.push(file);
          reader.onload = (event: any) => {
            this.primaryDocFileUrl.push(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                event.target.result,
              ),
            );
            this.docFile?.push({
              type: file.type,
              name: file.name,
              url: this.sanitizer.bypassSecurityTrustResourceUrl(
                event.target.result,
              ),
            });
            if (
              this.primaryDocFileUrl[0].changingThisBreaksApplicationSecurity.indexOf(
                'data:application/pdf',
              ) > -1
            ) {
              this.isPdfFile = true;
            }
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }

  onViewAttachment(attachments: string[] | undefined, type: string = '') {
   this.isPdfFile = type == 'application/pdf';
    this.dialog.open(AttachmentsPopupComponent, {
      width: '850px',
      data: {imageURL: attachments, isPdfFile: this.isPdfFile},
    });
  }

  prepareDocumentPayload(formValues: any, type: string, conductorId: number = 0): CreateConductorDocumentDto {
    return {
      id: formValues[`${type}Docs`] || null,
      customerId: conductorId ? conductorId : this.receiverCustomer.id as number,
      documentTypeId: formValues[`${type}DocType`].id,
      documentDetails: {
        number: formValues[`${type}DocNumber`],
        issueDate: formValues[`${type}DocIssueDate`] || null,
        expiryDate: formValues[`${type}DocExpiryDate`] || null,
        issuedBy: formValues[`${type}DocIssuedBy`] || null,
        idNumber: formValues[`idNumber`] || null,
        serialNumber: formValues[`serialNumber`] || null,
        requiredFields: this.selectedDoctype?.requiredFields,
        motherName: formValues[`motherName`] || null,
        fatherName: formValues[`fatherName`] || null,
        nationality: formValues[`receiverNationality`] || null
      },
      conductorId: this.receiverCustomerTypeId == CustomerTypes.Corporate ? conductorId : null
    };
  }

  onAccountChange() {
    const selectedAccount = this.form.get('receiverAccount')?.value;
    this.payingExchangeRate = this.branchExchangeRates.find(
      (e) => e.currencyId === selectedAccount.currencyId,
    )?.normRate;
    if (!this.payingExchangeRate) {
      this.snackBar.open('exchangeRateNotFoundForSelectedAccount');
      return;
    }

    this.payingAmount =
      (this.remittance?.amount || 0) * this.payingExchangeRate;
    this.form.get('payingAmount')?.setValue(Math.round(this.payingAmount));
  }

  onSubmit() {
    if (!this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]',
          );
          invalidControl.focus();
          break;
        }
      }
      return;
    }

    if (this.docFile?.length < this.numberOfAttachmentsRequired) {
      this.snackBar.open(
        `${this.numberOfAttachmentsRequired} attachments required.`,
      );
      return;
    }
    if (!this.docFile.length) {
      this.snackBar.open('pleaseSelectDocumentAttachment');
      return;
    }

    if(this.receiverCustomerTypeId == CustomerTypes.Corporate && this.authorizedSignatureDocFile?.length < 1){
      this.snackBar.open(`authorizedSignatureAttachmentsRequired`);
      return;
    }

    const receiverCustomer = this.receiverCustomer;
    const formValues = this.form.getRawValue();
    const receiverDto: UpsertCustomerDto = {
      id: this.receiverCustomer.id,
      firstName: this.form.controls['receiverFirstName'].disabled
        ? receiverCustomer?.firstName
        : formValues.receiverFirstName,
      middleName: this.form.controls['receiverMiddleName'].disabled
        ? receiverCustomer?.middleName
        : formValues.receiverMiddleName,
      lastName: this.form.controls['receiverLastName'].disabled
        ? receiverCustomer?.lastName
        : formValues.receiverLastName,
      mobilePhone: this.form.controls['receiverMobilePhone'].disabled
        ? receiverCustomer?.mobilePhone
        : formValues.receiverMobilePhone,
      addressLine1: formValues.receiverAddressLine1,
      addressLine2: formValues.receiverAddressLine2,
      genderId: formValues.gender?.id,
      dateOfBirth: formValues.dateOfBirth ? moment(formValues.dateOfBirth).format('YYYY-MM-DD'): null,
      placeOfBirth: formValues.placeOfBirth,
      houseNumber: formValues.houseNumber,
      buildingNumber: formValues.buildingNumber,
      street: formValues.street,
      occupationId: formValues.occupation?.id,
      sourceOfIncomeId: formValues.sourceOfIncome?.id,
      cityId: this.form.controls['receiverCity'].disabled
        ? this.cities[0].id
        : formValues.receiverCity.id,
      countryId: this.form.controls['receiverCountry'].disabled
        ? this.countries[0].id
        : formValues.receiverCountry?.id,
      customerTypeId: this.receiverCustomerTypeId
    };

    if (this.receiverCustomerTypeId == CustomerTypes.Corporate) {
      receiverDto.taxIdentificationNumber = this.form.controls['receiverTaxIdentificationNumber'].disabled ?
        receiverCustomer?.taxIdentificationNumber : formValues?.receiverTaxIdentificationNumber;
      receiverDto.tradeRegistryNumber = this.form.controls['receiverTradeRegistryNumber'].disabled ?
        receiverCustomer?.tradeRegistryNumber : formValues?.receiverTradeRegistryNumber;
      receiverDto.fieldOfActivity = this.form.controls['receiverFieldOfActivity'].disabled ? receiverCustomer?.fieldOfActivity :
        formValues?.receiverFieldOfActivity;
      receiverDto.mobilePhone = this.form.controls['receiverCompanyMobilePhone'].disabled ? receiverCustomer?.mobilePhone :
        formValues?.receiverCompanyMobilePhone;
      receiverDto.street = this.form.controls['receiverCompanyStreet'].disabled ? receiverCustomer?.companyStreet :
        formValues?.receiverCompanyStreet;
      receiverDto.companyArea = this.form.controls['receiverCompanyArea'].disabled ? receiverCustomer?.companyArea :
        formValues?.receiverCompanyArea;
      receiverDto.buildingNumber = this.form.controls['buildingNumber'].disabled ? receiverCustomer?.buildingNumber :
        formValues.receiverCompanyBuildingNumber;
    }


    const conductorDto: upsertConductor = {
      id: this.selectedConductor?.id,
      firstName: this.form.controls['receiverConductorFirstName'].disabled ? this.selectedConductor?.firstName : formValues.receiverConductorFirstName,
      lastName: this.form.controls['receiverConductorLastName'].disabled ? this.selectedConductor?.lastName : formValues.receiverConductorLastName,
      mobile: this.form.controls['receiverConductorMobilePhone'].disabled ? this.selectedConductor?.mobile : formValues.receiverConductorMobilePhone,
      dateOfBirth: this.form.controls['receiverConductorDateOfBirth'].disabled ? this.selectedConductor?.dateOfBirth : moment(formValues.receiverConductorDateOfBirth).format('YYYY-MM-DD'),
      placeOfBirth: this.form.controls['receiverConductorPlaceOfBirth'].disabled ? this.selectedConductor?.placeOfBirth : formValues.receiverConductorPlaceOfBirth,
      position: this.form.controls['receiverConductorPosition'].disabled ? this.selectedConductor?.position : formValues.receiverConductorPosition,
      fartherName: this.form.controls['receiverConductorFartherName']?.disabled ? this.selectedConductor?.fartherName : formValues.receiverConductorFartherName,
      motherName: this.form.controls['receiverConductorMotherName']?.disabled ? this.selectedConductor?.motherName : formValues.receiverConductorMotherName,
      nationalityId: this.form.controls['receiverConductorNationality']?.disabled ? this.selectedConductor?.nationalityId : formValues.receiverConductorNationality?.id
    };



    forkJoin([
      this.customerService
        .upsertCustomer(receiverDto)
        .pipe(catchError((error) => of(error))),
    ])
      .pipe(
        switchMap(
          ([receiverResp]: (CRUDResponseDto | Error)[]) => {
            if (
              receiverResp instanceof Error
            ) {
              return throwError(() =>
                receiverResp
              );
            }

            const {id: receiverId } = receiverResp;
            const conductorObservables: Observable<any>[] = [];

            if(this.receiverCustomerTypeId == CustomerTypes.Corporate){
              conductorDto.customerId = receiverId;
              conductorObservables.push(this.customerService.upsertConductor(conductorDto));
            } else {
              conductorObservables.push(of({id: null}));
            }

            return forkJoin(conductorObservables).pipe(
              map(([conductorResp]) => {

                if (conductorResp instanceof Error) {
                  return throwError(() =>
                    conductorResp,
                  );
                }

                const { id: conductorId } = conductorResp;

                return [receiverId, conductorId];
              })
            );
          },
        ),
        switchMap((value: any) => {
          const [receiverId, conductorId] = value;
          const docsObservables: ObservableInput<any>[] = [];

          if (formValues.primaryDocType) {
            if(this.receiverCustomerTypeId == CustomerTypes.Corporate){
              docsObservables.push(
                this.customerService
                  .upsertConductorDocument(
                    this.prepareDocumentPayload(formValues, 'primary', conductorId),
                  )
                  .pipe(catchError((error) => of(error))),
              );
            } else {
              docsObservables.push(
                this.customerService
                  .upsertCustomerDocument(
                    this.prepareDocumentPayload(formValues, 'primary'),
                  )
                  .pipe(catchError((error) => of(error))),
              );
            }
          } else {
            docsObservables.push(of({ id: null }));
          }
          if (formValues.secondaryDocType) {
            if (this.customerTypeId == CustomerTypes.Corporate){
              docsObservables.push(
                this.customerService
                  .upsertConductorDocument(
                    this.prepareDocumentPayload(
                      formValues,
                      'secondary',
                      conductorId,
                    ),
                  )
                  .pipe(catchError((error) => of(error))),
              );
            } else {
              docsObservables.push(
                this.customerService
                  .upsertCustomerDocument(
                    this.prepareDocumentPayload(formValues, 'secondary'),
                  )
                  .pipe(catchError((error) => of(error))),
              );
            }
          } else {
            docsObservables.push(of({ id: null }));
          }

          return forkJoin([...docsObservables]).pipe(
            switchMap(
              ([primaryDocResp, secondaryDocResp]: (CRUDResponseDto | Error)[]) => {
                if (
                  primaryDocResp instanceof Error ||
                  secondaryDocResp instanceof Error
                ) {
                  return throwError(() =>
                    primaryDocResp instanceof Error
                      ? primaryDocResp
                      : secondaryDocResp,
                  );
                }
                const { id: primaryDocId } = primaryDocResp;
                const { id: secondaryDocId } = secondaryDocResp;

                const docsAttachmentObservables = [];
                if (this.receiverPrimaryDocFile && primaryDocId) {
                  docsAttachmentObservables.push(
                    this.customerService
                      .uploadDocumentAttachment(
                        primaryDocId,
                        this.receiverPrimaryDocFile,
                        this.receiverCustomerTypeId == CustomerTypes.Corporate ? 1 : 0
                      )
                      .pipe(catchError((error) => of(error))),
                  );
                } else {
                  docsAttachmentObservables.push(of(null));
                }
                if (this.receiverSecondaryDocFile && secondaryDocId) {
                  docsAttachmentObservables.push(
                    this.customerService
                      .uploadDocumentAttachment(secondaryDocId, [
                        this.receiverSecondaryDocFile,
                      ], this.receiverCustomerTypeId == CustomerTypes.Corporate ? 1 : 0)
                      .pipe(catchError((error) => of(error))),
                  );
                } else {
                  docsAttachmentObservables.push(of(null));
                }

                return forkJoin(docsAttachmentObservables).pipe(
                  map(([primaryDocResp, secondaryDocResp]) => {
                    if (
                      primaryDocResp instanceof Error ||
                      secondaryDocResp instanceof Error
                    ) {
                      return throwError(() =>
                        primaryDocResp instanceof Error
                          ? primaryDocResp
                          : secondaryDocResp,
                      );
                    }
                    const processResp = (
                      docId: number,
                      resp: CRUDResponseDto | null,
                      type: string,
                    ) => {
                      let docs =
                        type === 'primary'
                          ? this.receiverPrimaryDocs
                          : this.receiverSecondaryDocs;
                      let docInfo = this.prepareDocumentPayload(formValues, type);
                      docInfo.documentPaths = resp?.data?.attachments || null;
                      if (docInfo.id) {
                        docs = docs.filter((d) => d.id !== docInfo.id);
                      } else {
                        docInfo.id = docId;
                      }
                      docInfo.displayName = `${
                        this.customerDocTypes.find(
                          (t) => t.id === docInfo.documentTypeId,
                        )?.name
                      } - ${docInfo.documentDetails.number}`;

                      docInfo = {
                        ...docInfo,
                        ...docInfo.documentDetails,
                      };
                      docs.push(docInfo);
                      if (type === 'primary') {
                        this.receiverPrimaryDocs = docs;
                      } else {
                        this.receiverSecondaryDocs = docs;
                      }
                      // this.onCustomerDocSelected(docInfo.id, type);
                    };

                    if (primaryDocId) {
                      processResp(primaryDocId, primaryDocResp, 'primary');
                    }
                    if (secondaryDocId) {
                      processResp(secondaryDocId, secondaryDocResp, 'secondary');
                    }

                    return [primaryDocId, secondaryDocId, receiverId, conductorId];
                  }),
                );
            })
          );
        }),
        switchMap((value: any) => {
          const [primaryDocId, secondaryDocId, receiverId, conductorId] = value;
          const authorizedDocAttachmentObservables = [];

          if(this.receiverCustomerTypeId == CustomerTypes.Corporate && conductorId) {
            authorizedDocAttachmentObservables.push(
              this.customerService
                .uploadAuthorizedSignatureCustomerDocument(
                  conductorId,
                  this.authorizedSignatureDocFile
                )
                .pipe(catchError((error) => of(error))),
            );
          } else {
            authorizedDocAttachmentObservables.push(of({id: null}));
          }

          return  forkJoin(authorizedDocAttachmentObservables).pipe(
            map(([authorizedDocAttachmentResp]) => {

              if(authorizedDocAttachmentResp instanceof Error){
                return throwError(() => authorizedDocAttachmentResp);
              }

              return [primaryDocId, secondaryDocId, receiverId, conductorId, authorizedDocAttachmentResp];
            })
          );
        }),
        switchMap((value: any) => {
          const [primaryDocId, secondaryDocId, receiverId, conductorId, authorizedDocAttachmentResp] = value;
          const { receiverAccount } = formValues;
          return this.remittanceService.payRemittance({
            remittanceId: this.remittance?.id as number,
            primaryDocId: primaryDocId as number,
            secondaryDocId: secondaryDocId as number,
            receiverComments: formValues.receiverComments,
            receiverAccountId: receiverAccount.id,
            payingExchangeRate: +(this.payingExchangeRate || 0),
            payingAmount: +(this.payingAmount || 0),
            payingBranchExchangeRate: +this.payingBranchExchangeRate,
            payingBranchAmount: +this.payingBranchAmount,
            receiverCustomerId: this.receiverCustomer.id,
            senderCustomerId: this.senderCustomer.id,
            remittancePin: formValues.remittancePin,
            authorizedSignatureFilePath: this.receiverCustomerTypeId == CustomerTypes.Corporate ? authorizedDocAttachmentResp?.authorizedSignatureFilePath : null,
            conductorId: this.receiverCustomerTypeId == CustomerTypes.Corporate ? conductorId : null,
            purposeId:
              formValues.purpose && formValues.purpose.id
                ? formValues.purpose.id
                : null,
            relationshipId:
              formValues.relationship && formValues.relationship.id
                ? formValues.relationship.id
                : null,
          });
        }),
      )
      .subscribe(
        (resp) => {
          this.payReceiptPrintDialog(resp.id);
          this.snackBar.open(resp?.message);
        },
        ({ message }) => this.snackBar.open(message),
      );
  }

  filterReceiverDocs(excludedId: number | null, doctypes: LookupDto[]) {
    return this.receiverDocs.filter(({ documentTypeId }) =>
      doctypes.find((t) => t.id === documentTypeId && t.id !== excludedId),
    );
  }

  getBranchCountryCity(countries: LookupDto[]) {
    this.branchService
      .branchById(
        this.tellerAccounts[0].parentId ? this.tellerAccounts[0].parentId : 0,
      )
      .subscribe((s) => {
        this.countries = countries.filter((f) => f.id === s[0].countryId);
        this.cities = this.cities.filter(
          (f) => f.parentId === this.countries[0].id,
        );
        this.onCityChange(this.cities[0].id);
        this.form.controls['receiverCountry'].setValue(this.countries[0]);
        this.form.controls['receiverCountry'].disable();
        this.form.controls['receiverCity'].setValue(this.cities[0]);
        this.form.controls['receiverCity'].disable();
      });
  }

  onCityChange(cityId: number) {
    // this.branchService.getLocalities(cityId).subscribe((localities) => {
    // });
  }

  onCountryChange(countryId: number) {
    this.branchService.branchCities(countryId).subscribe((cities) => {
      this.cities = cities;
    });
  }

  filterDocTypes(isPrimary: boolean, excludedId: number | null) {
    return this.customerDocTypes.filter(
      (cdt: CustomerDocTypesResponseDto) =>
        (cdt.isPrimary === null || cdt.isPrimary === isPrimary) &&
        cdt.id !== excludedId,
    );
  }

  onPrimaryDocChange(event: any) {
    this.selectedDoctype = event.value;
    this.requiredFields = JSON.parse(event.value?.requiredFields || '{}');
    this.isPassportDocTypeSelected = this.selectedDoctype?.name?.trim()?.toLowerCase() == 'passport';

    this.docFile = [];
    this.receiverPrimaryDocFile = [];
    this.numberOfAttachmentsRequired = 0;
    this.secondaryDocTypes = this.filterDocTypes(false, event.value.id);

    if(this.requiredFields?.idNumberIsRequired) {
      this.form.controls['idNumber']?.enable();
      this.setFieldRequired('idNumber');

      this.form.get('idNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);
      this.form.get('idNumber')?.updateValueAndValidity();

      this.form.controls['primaryDocNumber']?.patchValue('');
    } else {
      this.form.controls['idNumber']?.patchValue('');
      this.unsetFieldRequired('idNumber');
    }

    if(this.requiredFields?.serialNumberIsRequired) {
      this.form.get('serialNumber')?.enable();

      this.form.get('serialNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);
      this.form.get('serialNumber')?.updateValueAndValidity();

      this.form.controls['primaryDocNumber']?.patchValue('');
    } else {
      this.form.controls['serialNumber']?.patchValue('');
      this.unsetFieldRequired('serialNumber');
    }

    if(!this.requiredFields?.issueDateIsRequired) {
      this.unsetFieldRequired('primaryDocIssueDate');
    } else {
      this.setFieldRequired('primaryDocIssueDate');
    }
    if(!this.requiredFields?.expiryDateIsRequired) {
      this.unsetFieldRequired('primaryDocExpiryDate');
    } else {
      this.setFieldRequired('primaryDocExpiryDate')
    }

    if(!this.requiredFields?.documentIssuerIsRequired) {
      this.unsetFieldRequired('primaryDocIssuedBy');
    } else {
      this.setFieldRequired('primaryDocIssuedBy');
    }

    if(!this.requiredFields?.documentNumberIsRequired) {
      this.unsetFieldRequired('primaryDocNumber');
    } else {
      this.form.get('primaryDocNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);
      this.form.get('primaryDocNumber')?.updateValueAndValidity();
    }

    if(this.requiredFields?.motherNameIsRequired) {
      this.form.controls['motherName']?.enable();
      this.form.get('motherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('motherName')?.updateValueAndValidity();
    } else {
      this.form.controls['motherName']?.patchValue('');
      this.unsetFieldRequired('motherName');
    }

    if(this.requiredFields?.nationalityIsRequired) {
      this.form.controls['receiverNationality']?.enable();
      this.form.get('receiverNationality')?.setValidators([
        Validators.required
      ]);
      this.form.get('receiverNationality')?.updateValueAndValidity();
    } else {
      this.form.controls['receiverNationality']?.patchValue('');
      this.unsetFieldRequired('receiverNationality');
    }

    if(this.requiredFields?.fatherNameIsRequired) {
      this.form.controls['fatherName']?.enable();
      this.form.get('fatherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('fatherName')?.updateValueAndValidity();
    } else {
      this.form.controls['fatherName']?.patchValue('');
      this.unsetFieldRequired('fatherName');
    }

    if (event.value.isFirstAttachmentRequired)
      this.numberOfAttachmentsRequired = 1;
    if (event.value.isSecondAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
    if (event.value.isThirdAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
  }

  setFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.setValidators([Validators.required]);
    ctrl.updateValueAndValidity();
  }

  unsetFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
  }

  onSecondaryDocChange(event: any) {
    this.primaryDocTypes = this.filterDocTypes(true, event.value.id);
  }

  payReceiptPrintDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceDetails(remittanceId)
      .subscribe((remittance) => {
        const dialogRef = this.dialog.open(RemittancePayReceiptPopupComponent, {
          width: '850px',
          data: remittance,
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.tranlateService.use(localStorage.getItem('lang') || 'en');
            this.router.navigateByUrl('/remittances');
            this.remittanceService.getRemittances(({remittanceId: remittanceId}) as RemittanceFilter);
          }
        });
      });
  }

  onSelectSenderCustomer(customerId: number) {
    const sc = this.senderCustomers.find((f) => f.id === customerId);
    if (sc) {
      this.senderCustomer = sc;
      this.form.controls['senderFirstName'].setValue(`${sc?.firstName}`);
      this.form.controls['senderMiddleName'].setValue(`${sc?.middleName}`);
      this.form.controls['senderLastName'].setValue(`${sc?.lastName}`);
      this.form.controls['senderMobilePhone'].setValue(`${sc?.mobilePhone}`);

      this.senderSelected = true;
    }
  }

  onSelectReceiverCustomer(customerId: number) {
    const rc = this.receiverCustomers.find((f) => f.id === customerId);
    if (rc) {
      this.receiverCustomer = rc;
      this.form.controls['receiverFirstName'].setValue(`${rc?.firstName}`);
      this.form.controls['receiverMiddleName'].setValue(`${rc?.middleName}`);
      this.form.controls['receiverLastName'].setValue(`${rc?.lastName}`);
      this.form.controls['receiverMobilePhone'].setValue(`${rc?.mobilePhone}`);
      this.form.controls['receiverAddressLine1'].setValue(
        `${rc?.addressLine1}`,
      );
      this.form.controls['receiverAddressLine2'].setValue(
        `${rc?.addressLine2}`,
      );
      this.form.controls['gender'].setValue(
        this.customerGenders.find((g) => g.id === rc.genderId),
      );
      this.form.controls['dateOfBirth'].setValue(rc.dateOfBirth);
      this.form.controls['sourceOfIncome'].setValue(
        this.customerSourceOfIncome.find((s) => s.id === rc.sourceOfIncomeId),
      );
      this.form.controls['street'].setValue(rc.street);
      this.form.controls['houseNumber'].setValue(rc.houseNumber);
      this.form.controls['buildingNumber'].setValue(rc.buildingNumber);
      this.form.controls['placeOfBirth'].setValue(rc.placeOfBirth);
      this.form.controls['occupation'].setValue(
        this.customerOccupations.find((o) => o.id === rc.occupationId),
      );

      this.receiverSelected = true;
    }
  }

  clearSelectedDocAttachment(file: any) {
    this.receiverPrimaryDocFile = this.receiverPrimaryDocFile?.filter(
      (list) => list.name !== file.name,
    );
    this.docFile = this.docFile?.filter((list) => list.name !== file.name);
    this.primaryDocFileUrl = [];
    this.primaryDocFileElem.nativeElement.value = null;
  }

  onFocusout(event: any) {
    const regexLiteral = this.selectedDoctype?.regularExpression;
    if(regexLiteral) {
      const [, pattern, flags] = regexLiteral?.match(/\/(.*)\/(.*)/);
      const regex = new RegExp(pattern, flags);
      if (!regex.test(event.target.value)) {
        this.form.get('primaryDocNumber')?.setErrors({invalid: true});
      } else {
        this.form.get('primaryDocNumber')?.setErrors(null);
      }
    }
  }

  onCustomerTypeChanged(event: any) {
    this.receiverCustomerTypeId = event.value.id;
    this.requiredFields = {};
    if(this.receiverCustomerTypeId == CustomerTypes.Corporate){
      this.panelOpenState = true;
      this.primaryDocTypes = this.filterDocTypes(true, null);
      this.setFieldRequired('receiverCompanyTitle');
      this.setFieldRequired('receiverTradeRegistryNumber');
      this.setFieldRequired('receiverTaxIdentificationNumber');
      this.setFieldRequired('receiverFieldOfActivity');
      this.setFieldRequired('receiverCompanyMobilePhone');
      this.setFieldRequired('receiverCompanyCountry');
      this.setFieldRequired('receiverCompanyCity');
      this.setFieldRequired('receiverCompanyStreet');
      this.setFieldRequired('receiverCompanyBuildingNumber');
      this.setFieldRequired('receiverCompanyArea');

      this.setFieldRequired('receiverConductorFartherName');
      this.setFieldRequired('receiverConductorMotherName');

      this.setFieldRequired('receiverConductorFirstName');
      this.setFieldRequired('receiverConductorLastName');
      this.setFieldRequired('receiverConductorMobilePhone');
      this.setFieldRequired('receiverConductorDateOfBirth');
      this.setFieldRequired('receiverConductorPlaceOfBirth');
      this.setFieldRequired('receiverConductorPosition');

      this.unsetFieldRequired('buildingNumber');
      this.unsetFieldRequired('gender');
      this.unsetFieldRequired('occupation');
      this.unsetFieldRequired('sourceOfIncome');
      this.unsetFieldRequired('street');
      this.unsetFieldRequired('houseNumber');
      this.unsetFieldRequired('receiverFirstName');
      this.unsetFieldRequired('receiverLastName');
      this.unsetFieldRequired('receiverMobilePhone');
      this.unsetFieldRequired('dateOfBirth');
      this.unsetFieldRequired('placeOfBirth');

      this.form.controls['receiverCompanyTitle'].setValue(this.receiverCustomer.firstName + ' ' + this.receiverCustomer.lastName);
      this.form.controls['receiverCompanyTitle'].disable();

      this.form.controls['receiverCompanyMobilePhone'].setValue(this.receiverCustomer.mobilePhone);
      this.form.controls['receiverCompanyMobilePhone'].disable();

      if(this.receiverCustomer.tradeRegistryNumber) {
        this.form.controls['receiverTradeRegistryNumber'].setValue(this.receiverCustomer.tradeRegistryNumber);
        this.form.controls['receiverTradeRegistryNumber'].disable();
      }

      if(this.receiverCustomer.taxIdentificationNumber) {
        this.form.controls['receiverTaxIdentificationNumber'].setValue(this.receiverCustomer.taxIdentificationNumber);
        this.form.controls['receiverTaxIdentificationNumber'].disable();
      }

      if(this.receiverCustomer.fieldOfActivity) {
        this.form.controls['receiverFieldOfActivity'].setValue(this.receiverCustomer.fieldOfActivity);
        this.form.controls['receiverFieldOfActivity'].disable();
      }

      if(this.receiverCustomer.countryId) {
        this.form.controls['receiverCompanyCountry'].setValue(this.allCountries.find(country => country.id == this.receiverCustomer.countryId));
        this.form.controls['receiverCompanyCountry'].disable();
      }

      if(this.receiverCustomer.cityId) {
        this.form.controls['receiverCompanyCity'].setValue(this.cities.find(city => city.id == this.receiverCustomer.cityId));
        this.form.controls['receiverCompanyCity'].disable();
      }

      if(this.receiverCustomer.street) {
        this.form.controls['receiverCompanyStreet'].setValue(this.receiverCustomer.street);
        this.form.controls['receiverCompanyStreet'].disable();
      }

      if(this.receiverCustomer.buildingNumber) {
        this.form.controls['receiverCompanyBuildingNumber'].setValue(this.receiverCustomer.buildingNumber);
        this.form.controls['receiverCompanyBuildingNumber'].disable();
      }

      if(this.receiverCustomer.companyArea) {
        this.form.controls['receiverCompanyArea'].setValue(this.receiverCustomer.companyArea);
        this.form.controls['receiverCompanyArea'].disable();
      }

    } else {
      this.panelOpenState = false;
      this.primaryDocTypes = this.filterDocTypes(true, null);
      this.unsetFieldRequired('receiverCompanyTitle');
      this.unsetFieldRequired('receiverTradeRegistryNumber');
      this.unsetFieldRequired('receiverTaxIdentificationNumber');
      this.unsetFieldRequired('receiverFieldOfActivity');
      this.unsetFieldRequired('receiverCompanyMobilePhone');
      this.unsetFieldRequired('receiverCompanyCountry');
      this.unsetFieldRequired('receiverCompanyCity');
      this.unsetFieldRequired('receiverCompanyStreet');
      this.unsetFieldRequired('receiverCompanyBuildingNumber');
      this.unsetFieldRequired('receiverCompanyArea');



      this.unsetFieldRequired('receiverConductorFartherName');
      this.unsetFieldRequired('receiverConductorMotherName');
      this.unsetFieldRequired('receiverConductorFirstName');
      this.unsetFieldRequired('receiverConductorLastName');
      this.unsetFieldRequired('receiverConductorMobilePhone');
      this.unsetFieldRequired('receiverConductorDateOfBirth');
      this.unsetFieldRequired('receiverConductorPlaceOfBirth');
      this.unsetFieldRequired('receiverConductorPosition');

      this.setFieldRequired('gender');
      this.setFieldRequired('occupation');
      this.setFieldRequired('sourceOfIncome');
      this.setFieldRequired('street');
      this.setFieldRequired('buildingNumber');
      this.setFieldRequired('houseNumber');
      this.setFieldRequired('senderFirstName');
      this.setFieldRequired('senderLastName');
      this.setFieldRequired('senderMobilePhone');
      this.setFieldRequired('dateOfBirth');
      this.setFieldRequired('placeOfBirth');
    }
  }

  setFieldsRequiredByCustomerTypeId(){
    if(this.receiverCustomerTypeId == CustomerTypes.Corporate){
        this.unsetFieldRequired('gender');
        this.unsetFieldRequired('occupation');
        this.unsetFieldRequired('sourceOfIncome');
        this.unsetFieldRequired('street');
        this.unsetFieldRequired('houseNumber');
        this.unsetFieldRequired('receiverFirstName');
        this.unsetFieldRequired('receiverLastName');
        this.unsetFieldRequired('receiverMobilePhone');
        this.unsetFieldRequired('dateOfBirth');
        this.unsetFieldRequired('placeOfBirth');
    }
  }


  onConductorChanged(event: any){
    this.selectedConductor = this.receiverConductors.find((c: any) => c.id = event);
    this.authorizedSignatureDocs = [];
    this.receiverDocs = this.selectedConductor?.conductorDocument
      ?.map((d: any) => ({
        ...d,
        displayName: `${
          this.customerDocTypes.find((t) => t.id === d.documentTypeId)
            ?.name
        } - ${d.documentDetails.number}`,
        ...d.documentDetails
      }))
      .filter((doc: any) => doc.isActive) || [];

    this.receiverPrimaryDocs = this.filterReceiverDocs(
      null,
      this.primaryDocTypes,
    );

    if(this.selectedConductor?.conductorDocumentAttachmentPath) {
      var url = this.selectedConductor?.conductorDocumentAttachmentPath?.split("/")?.slice(2)?.join("/")
      this.remittanceService.getRemittanceDocumentAttachments(url).subscribe(
        (blob) => {
          const isPdfFile = false;
          if (blob) {
            if (url?.indexOf('.pdf') > -1) {
              blob = new Blob([blob], { type: 'application/pdf' });
              this.isPdfFile = true;
            }
            this.authorizedSignatureDocs?.push({
              type: url?.indexOf('.pdf') > -1 ? 'application/pdf' : '',
              name: '',
              url: this.sanitizer?.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(blob),
              ),
            });
          }
        },
        (err) => {
          this.snackBar.open(err.message);
        },
      );
    }

    this.form.controls['receiverConductorFirstName'].setValue(this.selectedConductor?.firstName);
    this.form.controls['receiverConductorFirstName'].disable();

    this.form.controls['receiverConductorLastName'].setValue(this.selectedConductor?.lastName);
    this.form.controls['receiverConductorLastName'].disable();

    this.form.controls['receiverConductorMobilePhone'].setValue(this.selectedConductor?.mobile);
    this.form.controls['receiverConductorMobilePhone'].disable();

    this.form.controls['receiverConductorDateOfBirth'].setValue(this.selectedConductor?.dateOfBirth);
    this.form.controls['receiverConductorDateOfBirth'].disable();

    this.form.controls['receiverConductorPlaceOfBirth'].setValue(this.selectedConductor?.placeOfBirth);
    this.form.controls['receiverConductorPlaceOfBirth'].disable();

    this.form.controls['receiverConductorPosition'].setValue(this.selectedConductor?.position);
    this.form.controls['receiverConductorPosition'].disable();

    this.form.controls['receiverConductorFartherName'].setValue(this.selectedConductor?.fartherName);
    this.form.controls['receiverConductorFartherName'].disable();

    this.form.controls['receiverConductorMotherName'].setValue(this.selectedConductor?.motherName);
    this.form.controls['receiverConductorMotherName'].disable();

    const conductorCountry = this.allCountries.find((f) => f.id === this.selectedConductor?.nationalityId);
    this.form.controls[`receiverConductorNationality`].setValue(conductorCountry);
    this.form.controls['receiverConductorNationality'].disable();
    //@ts-ignore
    this.isThisTrkCountry = conductorCountry?.iso2 == CountryISO2s.TURKEY;
  }

  onConductorCleared(){
    this.form.controls['receiverConductorFirstName']?.setValue('');
    this.form.controls['receiverConductorLastName']?.setValue('');
    this.form.controls['receiverConductorMobilePhone']?.setValue('');
    this.form.controls['receiverConductorDateOfBirth']?.setValue('');
    this.form.controls['receiverConductorPlaceOfBirth']?.setValue('');
    this.form.controls['receiverConductorFartherName']?.setValue('');
    this.form.controls['receiverConductorMotherName']?.setValue('');
    this.form.controls['receiverConductorNationality']?.setValue('');
    this.form.controls['receiverConductorPosition']?.setValue('');

    this.form.controls['receiverConductorFirstName']?.enable();
    this.form.controls['receiverConductorLastName']?.enable();
    this.form.controls['receiverConductorMobilePhone']?.enable();
    this.form.controls['receiverConductorDateOfBirth']?.enable();
    this.form.controls['receiverConductorPlaceOfBirth']?.enable();
    this.form.controls['receiverConductorFartherName']?.enable();
    this.form.controls['receiverConductorMotherName']?.enable();
    this.form.controls['receiverConductorNationality']?.enable();
    this.form.controls['receiverConductorPosition']?.enable();

    this.selectedConductor = null;
    this.authorizedSignatureDocs = [];
  }

  onConductorCountrySelected(event: any){
    this.isThisTrkCountry = event.value?.iso2 == CountryISO2s.TURKEY;

    if(!this.isThisTrkCountry){
      this.unsetFieldRequired('receiverConductorFartherName');
      this.unsetFieldRequired('receiverConductorMotherName');
    } else {
      this.setFieldRequired('receiverConductorFartherName');
      this.setFieldRequired('receiverConductorMotherName');
    }
  }

  onAuthorizedSignatureDocAttachmentSelected(e: Event) {
    const reader = new FileReader();

    if (this.authorizedSignatureDocFile?.length >= 1) {
      this.snackBar.open(
        `onlyOneAttachmentRequired`,
      );
    } else {
      //@ts-ignore
      const file = e.target.files[0];
      this.authorizedSignatureDocFile?.push(file);
      reader.onload = (event: any) => {

        this.authorizedSignatureDocFileUrl.push(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            event.target.result,
          ),
        );
        this.authorizedSignatureDocs?.push({
          type: file.type,
          name: file.name,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(
            event.target.result,
          ),
        });
        if (
          this.authorizedSignatureDocFileUrl &&
          this.authorizedSignatureDocFileUrl.length > 0 &&
          this.authorizedSignatureDocFileUrl[0].changingThisBreaksApplicationSecurity &&
          this.authorizedSignatureDocFileUrl[0].changingThisBreaksApplicationSecurity.indexOf('data:application/pdf') > -1
        ) {
          this.isPdfFile = true;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  clearSelectedAuthorizedDocAttachment() {
    this.authorizedSignatureDocFile = [];
    this.authorizedSignatureDocs = [];
    this.authorizedSignatureDocFileUrl = [];
    this.authorizedSignatureFileUploadElem.nativeElement.value = null;
  }

}
