import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { NotificationsComponent } from './notifications.component';

const {
  remittanceManagement: { notifications },
} = Permissions;

const routes: Routes = [
  {
    path: 'notifications',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(notifications)] },
        component: NotificationsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotificationRoutesModule {}
