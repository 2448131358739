import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { ComplianceConfigurationsComponent } from './complianceConfiguration/complianceConfigurations.component';
import {
  ComplianceTypesResolver,
  ComplianceConfigByIdResolver,
} from './data/complianceConfiguration.resolver';
import { ComplianceConfigurationDetailsComponent } from './complianceConfiguration/complianceConfigurationDetails.component';
import { SearchSanctionsComponent } from './search-sanctions/search-sanctions.component';

const {
  complianceManagement: { complianceConfigurations, searchSanctions },
} = Permissions;

const routes: Routes = [
  {
    path: 'compliances',
    component: OutletComponent,
    children: [
      {
        path: 'configuration',
        component: OutletComponent,
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            data: { permissions: [...Object.values(complianceConfigurations)] },
            resolve: {
              complianceTypes: ComplianceTypesResolver,
            },
            component: ComplianceConfigurationsComponent,
          },
          {
            path: 'add',
            canActivate: [AuthGuard],
            data: {
              permissions: [complianceConfigurations.CREATE_COMPLIANCE_CONFIG],
              addMode: true,
            },
            resolve: {
              complianceTypes: ComplianceTypesResolver,
              complianceConfigs: ComplianceConfigByIdResolver,
            },
            component: ComplianceConfigurationDetailsComponent,
          },
          {
            path: 'view/:complianceConfigId',
            canActivate: [AuthGuard],
            data: {
              permissions: [
                complianceConfigurations.VIEW_COMPLIANCE_CONFIG,
                complianceConfigurations.UPDATE_COMPLIANCE_CONFIG,
              ],
              addMode: false,
            },
            resolve: {
              complianceTypes: ComplianceTypesResolver,
              complianceConfigs: ComplianceConfigByIdResolver,
            },
            component: ComplianceConfigurationDetailsComponent,
          }
        ]
      },
      {
        path:'searchSanctions',
        canActivate: [AuthGuard],
        data: {
          permissions: [...Object.values(searchSanctions)],
        },
        component: SearchSanctionsComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ComplianceRoutesModule {}
