<app-index-container label="{{ 'roles' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_ROLE)"
    mat-raised-button
    color="primary"
    routerLink="/users/roles/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addRole' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>{{ 'userTypes' | translate }}:</mat-label>
          <mat-select formControlName="userTypeId">
            <mat-option *ngFor="let userType of userTypes" [value]="userType">{{
              userType.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'userType' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.userTypeName }}</td>
    </ng-container>
    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>{{ 'description' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.description }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ (row.isActive ? 'active' : 'inactive') | translate }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_ROLE,
                permissions.VIEW_ROLE
              ])
            "
            color="primary"
            routerLink="/users/roles/view/{{ row.id }}"
          >
            <mat-icon>{{
              authService.isUserAuthorized(permissions.UPDATE_ROLE)
                ? 'edit'
                : 'visibility'
            }}</mat-icon>
            {{
              (authService.isUserAuthorized(permissions.UPDATE_ROLE)
                ? 'edit'
                : 'view'
              ) | translate
            }}</a
          >
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
