import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { ComplianceConfigurationsComponent } from './complianceConfiguration/complianceConfigurations.component';
import { ComplianceConfigurationService } from './data/complianceConfiguration.service';
import {
  ComplianceConfigByIdResolver,
  ComplianceTypesResolver,
} from './data/complianceConfiguration.resolver';
import { ComplianceConfigurationDetailsComponent } from './complianceConfiguration/complianceConfigurationDetails.component';
import { ComplianceRoutesModule } from './compliance.routes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SearchSanctionsComponent } from './search-sanctions/search-sanctions.component';

@NgModule({
  declarations: [
    ComplianceConfigurationsComponent,
    ComplianceConfigurationDetailsComponent,
    SearchSanctionsComponent
  ],
  imports: [
    CommonModule,
    ComplianceRoutesModule,

    ContainersModule,

    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
  ],
  exports: [],
  providers: [
    ComplianceConfigurationService,
    ComplianceConfigByIdResolver,
    ComplianceTypesResolver,
  ],
})
export class ComplianceModule {}
