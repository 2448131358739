<h1 mat-dialog-title>{{ 'settlementHistory' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 11px; margin-top: 18px">
    <!-- widget content -->
    <div class="widget-body" style="padding: 0px 10px">
      <table table mat-table [dataSource]="dataSource" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        <!-- User Column -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>{{ 'user' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.user }}</td>
        </ng-container>
        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>{{ 'date' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ convertDateToLocaleString(row.createdDate) }}
          </td>
        </ng-container>
        <!-- Comments Column -->
        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'comments' | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.comments }}</td>
        </ng-container>
        <!-- Action Column -->
        <ng-container matColumnDef="actionType">
          <th mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{ row.actionType }}</td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <span>
              &nbsp;
              <button
                mat-raised-button
                class="btn btn-outline-danger"
                (click)="viewChangeLog(row)"
              >
                {{ 'viewChangeLog' | translate }}
              </button>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        paginator
        [pageSizeOptions]="[10, 25]"
        [pageSize]="10"
      ></mat-paginator>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-stroked-button color="warn" mat-dialog-close="close">
    {{ 'close' | translate }}
  </button>
</div>
