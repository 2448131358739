import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import {SnackBarService} from "../_common/snackBar.service";
import {RemittanceService} from "./data/remittance.service";
import {extractDocumentPath, remittanceDocPath} from "../_common/utils";
@Component({
  selector: 'app-attachments',
  templateUrl: './attachmentsPopup.component.html',
})
export class AttachmentsPopupComponent {
  imageURL: any[] = [];
  isPdfFile: Boolean = false;
  attachmentData: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<AttachmentsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      imageURL: [];
      isPdfFile: Boolean;
      attachments?: any
      isReceiptAttachment: boolean
    },
    private sanitizer: DomSanitizer,
    private snackBar: SnackBarService,
    private remittanceService: RemittanceService
  ) {
    if(!data.imageURL && data.isReceiptAttachment) {
      this.attachmentData = data.attachments.map((o: any) => Object.values(o)[0]);
      this.attachmentData.forEach((attachUrl) => {
        const remainingPath = extractDocumentPath(attachUrl, remittanceDocPath);
        const url: string = remainingPath ? remainingPath : attachUrl;
        this.remittanceService.getRemittanceDocumentAttachments(url).subscribe(
          (blob) => {
            this.isPdfFile = false;
            if (blob) {
              if (attachUrl.indexOf('.pdf') > -1) {
                blob = new Blob([blob], { type: 'application/pdf' });
                this.isPdfFile = true;
              }
              const objectURL = URL.createObjectURL(blob);
              this.imageURL.push(
                this.sanitizer.bypassSecurityTrustResourceUrl(objectURL),
              );
            }
          },
          (err) => {
            this.snackBar.open(err.message);
          },
        );
      });
      return;
    }

    this.isPdfFile = data.isPdfFile;
    this.imageURL.push(data.imageURL);
  }
}
