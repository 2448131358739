import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | UrlTree {
    const url: string = state.url;

    const loggedIn = this.checkAuthentication(url);
    if (loggedIn !== true) {
      return loggedIn;
    }

    return this.checkAuthorization(route);
  }

  checkAuthentication(url: string): boolean | UrlTree {
    const loggedIn = this.authService.isLoggedIn;
    if (!loggedIn) {
      return this.router.parseUrl('/login');
    }

    if (url === '/login') {
      return this.router.parseUrl('/dashboard');
    }

    return true;
  }

  checkAuthorization(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const { permissions: requiredPermissions = [], accessibleToAdmin = true } =
      route.data || {};
    const isAdmin = this.authService.currentUserValue?.isAdmin;
    const authorized =
      this.authService.isUserAuthorized(requiredPermissions) &&
      (!isAdmin || accessibleToAdmin);
    if (authorized) {
      return true;
    }

    return this.router.parseUrl('/login');
  }
}
