import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { HttpService } from '../../_common/data/http.service';
import { LogDto } from './dto/log/log.dto';

type LogFilter = {
  since: string;
  reqId: string;
} | null;

type GetLogs = {
  logs: LogDto[];
  appliedFilters: LogFilter;
};

@Injectable()
export class LogsService {
  private _logs = new BehaviorSubject<GetLogs>({
    appliedFilters: { since: '12', reqId: '' },
    logs: [],
  });
  private logsObservable?: Observable<GetLogs>;

  constructor(private httpService: HttpService) {}

  get logs() {
    if (!this.logsObservable)
      this.logsObservable = this._logs.pipe(
        map((resp) => {
          return resp;
        }),
      );

    return this.logsObservable;
  }

  getLogs(qs: LogFilter) {
    this.httpService.get<LogDto[]>('log/list', qs).subscribe((logs) => {
      this._logs.next({
        logs: logs,
        appliedFilters: qs,
      });
    });
  }
}
