import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UsersService, passwordRegex } from './data/users.service';
import { GetOtpTypes, GetUserDto } from './data/dto/user/users.dto';
import { AuthService } from '../auth/data/auth.service';
import { ActivatedRoute } from '@angular/router';
import { SnackBarService } from '../_common/snackBar.service';
import { AllOtpTypes } from '../_common/enums/allOtpTypes.enums';
import { CustomValidators } from '../_common/data/customValidators';
import { CheckGooglePopupComponent } from './checkGoogleOtpPopup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-profile',
  templateUrl: './userProfile.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class UserProfileComponent implements OnInit {
  form!: UntypedFormGroup;
  smsForm!: UntypedFormGroup;
  googleOtpForm!: UntypedFormGroup;
  otpTypes!: GetOtpTypes[];
  imgUrl!: string;
  user!: GetUserDto;
  googleSteps!: boolean;
  showQrCodeImg!: boolean;
  submitBtn!: boolean;
  smsCodeField!: boolean;
  smsSteps: boolean = false;
  showSmsFieldBtn: boolean = true;
  enableForm: boolean = false;
  enabledOtpName!: string;
  passWordChangeform!: UntypedFormGroup;
  passwordPattern = passwordRegex;

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ otpTypes }) => {
      this.otpTypes = otpTypes;
    });

    this.user = this.authService.currentUserValue as GetUserDto;
    this.enabledOtpName = this.otpTypes.find(
      (otpType) => otpType.id === this.user.otpTypeId,
    )?.name as string;

    this.form = this.fb.group({
      otpType: [
        this.user.otpTypeId
          ? this.otpTypes.find((f) => f.id === this.user.otpTypeId)
          : null,
        [Validators.required],
      ],
    });

    this.smsForm = this.fb.group({
      smsCode: [null, [Validators.required]],
    });

    this.googleOtpForm = this.fb.group({
      googleOtp: [null, [Validators.required]],
    });

    this.passWordChangeform = this.fb.group(
      {
        currentPassword: [null, [Validators.required]],
        newPassword: [null, [Validators.required]],
        confirmNewPassword: [null, [Validators.required]],
      },
      {
        validators: CustomValidators.matchPasswords(
          'newPassword',
          'confirmNewPassword',
        ),
      },
    );
  }

  onSubmit() {
    this.usersService.generateQrCode().subscribe(({ qrCodeImg }) => {
      this.imgUrl = qrCodeImg;
      this.showQrCodeImg = true;
      this.submitBtn = true;
    });
  }

  setEnabled() {
    if (this.googleOtpForm.valid) {
      const selectedOtp = this.form.value.otpType.id;
      const googleOtp = this.googleOtpForm.value.googleOtp;
      this.usersService.setOtpEnabled(selectedOtp, googleOtp).subscribe(
        (resp) => {
          // @ts-ignore
          this.snackBar.open(resp?.message);
          this.enableForm = true;
        },
        (err) => {
          this.snackBar.open(err.message);
        },
      );
    }
  }

  showSteps(otpName: string) {
    this.showQrCodeImg = false;
    if (otpName === AllOtpTypes.GOOGLE_AUTHENTICATION) {
      this.googleSteps = true;
      this.smsSteps = false;
    } else if (otpName === AllOtpTypes.SMS_OTP) {
      this.googleSteps = false;
      this.smsSteps = true;
      this.showSmsFieldBtn = true;
      this.smsCodeField = false;
    }
  }

  showQrCode() {
    this.usersService.generateQrCode().subscribe(({ qrCodeImg }) => {
      this.imgUrl = qrCodeImg;
      this.showQrCodeImg = true;
    });
  }

  showSmsCodeField() {
    this.usersService.generateSmsCode().subscribe(() => {
      this.showSmsFieldBtn = false;
    });
    this.smsCodeField = true;
  }

  checkSmsCode() {
    if (this.smsForm.valid) {
      const selectedOtp = this.form.value.otpType.id;
      this.usersService.checkSmsCode(this.smsForm.value.smsCode).subscribe(
        (isSmsCodeValid) => {
          if (isSmsCodeValid) {
            this.usersService.setOtpEnabled(selectedOtp).subscribe(
              (resp) => {
                // @ts-ignore
                this.snackBar.open(resp?.message);
                this.smsCodeField = false;
                this.enableForm = true;
              },
              ({ message }) => {
                this.snackBar.open(message);
              },
            );
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  onPasswordChange() {
    if (this.passWordChangeform.valid) {
      const { currentPassword, newPassword, confirmNewPassword } =
        this.passWordChangeform.value;

      this.dialog.open(CheckGooglePopupComponent, {
        disableClose: true,
        autoFocus: false,
        width: '500px',
        data: { id: this.authService.currentUserValue?.id, passwords: {
            newPassword: newPassword,
            currentPassword: currentPassword,
            confirmNewPassword: confirmNewPassword,
          }
        },
      });
    }
  }
}
