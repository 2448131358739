<app-index-container label="{{ 'remitCommConfigsRequests' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'commConfigRequestStatus' | translate }}:</mat-label>
          <mat-select #status formControlName="statusId">
            <mat-option
              *ngFor="let status of remitCommConfigRequestStatus"
              [value]="status"
            >
              {{ status.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="remitCommConfigRequests" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      <!-- {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} -->
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="senderCountry">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'senderCountry' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.senderCountry || ('all' | translate)"
        matTooltipPosition="after"
      >
        {{ row.senderCountry || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Sender Name Column -->
    <ng-container matColumnDef="receiverCountry">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'receiverCountry' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.receiverCountry || ('all' | translate)"
        matTooltipPosition="after"
      >
        {{ row.receiverCountry || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Sender Mobile Column -->
    <ng-container matColumnDef="remittanceType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'remittanceType' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.remittanceType || ('all' | translate)"
        matTooltipPosition="after"
      >
        {{ row.remittanceType || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Receiver Name Column -->
    <ng-container matColumnDef="remittanceSubType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'remittanceSubtype' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.remittanceSubType || ('all' | translate)"
        matTooltipPosition="after"
      >
        {{ row.remittanceSubType || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Receiver Mobile Column -->
    <ng-container matColumnDef="minAmount">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('minAmount' | translate) + ' $' }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.minAmount | number:'1.2-2'  }}</td>
    </ng-container>
    <!-- Amount Mobile Column -->
    <ng-container matColumnDef="maxAmount">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('maxAmount' | translate) + ' $' }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.maxAmount | number:'1.2-2'  }}</td>
    </ng-container>
    <!-- Commission Column -->
    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('commission' | translate) + ' %' }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.commission | number:'1.0-5'  }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.status"
        matTooltipPosition="after"
      >
        {{ row.status }}
      </td>
    </ng-container>
    <!-- Request Date Column -->
    <ng-container matColumnDef="requestDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('requestDate' | translate) + ' %' }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="convertDateToLocaleString(row.createdDate)"
        matTooltipPosition="after"
      >
        {{ convertDateToLocaleString(row.createdDate) }}
      </td>
    </ng-container>
    <!-- Requester Comments Column -->
    <ng-container matColumnDef="requesterComments">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('requesterComments' | translate) + ' %' }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.requesterComments"
        matTooltipPosition="after"
      >
        {{ row.requesterComments }}
      </td>
    </ng-container>
    <!-- Status Change Date Column -->
    <ng-container matColumnDef="statusChangeDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('statusChangeDate' | translate) + ' %' }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="convertDateToLocaleString(row.statusChangeDate)"
        matTooltipPosition="after"
      >
        {{ convertDateToLocaleString(row.statusChangeDate) }}
      </td>
    </ng-container>
    <!-- Status Change Comments Column -->
    <ng-container matColumnDef="statusChangeComments">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('statusChangeComments' | translate) + ' %' }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.statusChangeComments"
        matTooltipPosition="after"
      >
        {{ row.statusChangeComments }}
      </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized(
                permissions.UPDATE_REMITTANCE_COMMISSION_STRUCTURE_REQUEST
              ) &&
              !authService.isCurrentUser(row.requestedById) &&
              row.status === 'Pending'
            "
            color="primary"
            (click)="onCommConfigApproval(row.id)"
          >
            <mat-icon>edit</mat-icon> {{ 'actions' | translate }}</a
          >
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
