<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'incomingRemittanceDetail' | translate }}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table id="incomingReport" class="table table-bordered table-hover">
        <tbody *ngIf="data.incomingOrOutgoingRemittancesReportDetails.length">
          <tr>
            <th>{{ '#' | translate }}</th>
            <th>{{ 'date' | translate }}</th>
            <th>{{ 'transactionId' | translate }} </th>
            <th>{{ 'senderName' | translate }}</th>
            <th>{{ 'senderSurName' | translate }}</th>
            <th>{{ 'senderCountryCode' | translate }}</th>
            <th>{{ 'receiverName' | translate }}</th>
            <th>{{ 'receiverSurName' | translate }}</th>
            <th>{{ 'receiverIdType' | translate }}</th>
            <th>{{ 'receiverAddress' | translate }}</th>
            <th>{{ 'receiverCountryCode' | translate }}</th>
            <th>{{ 'receiverTellPhone' | translate }}</th>
            <th>{{ 'transactionDate' | translate }}</th>
            <th>{{ 'transactionTime' | translate }}</th>
            <th>{{ 'reasonOfTransfer' | translate }}</th>
            <th>{{ 'originAmountCurrency' | translate }}</th>
            <th>{{ 'remittanceAmount' | translate }}{{'('+data.reportCurrency+')'}}</th>
            <ng-container *ngIf="data.reportCurrency !== 'TRY'">
              <th>{{ 'exchangeRate' | translate }}</th>
              <th>{{ 'remittanceAmount' | translate }}{{'(TRY)'}}</th>
            </ng-container>
          </tr>
        <tr *ngFor="let remittance of data.incomingOrOutgoingRemittancesReportDetails; let i = index">
          <!-- Number Column -->
          <td>{{ i + 1 }}</td>
          <td class="text-nowrap">{{ remittance.date }}</td>
          <td class="text-nowrap">{{ remittance.transactionId }}</td>
          <td class="text-nowrap">{{ remittance.senderName }}</td>
          <td class="text-nowrap">{{ remittance.senderLastName }}</td>
          <td class="text-nowrap">{{ remittance.senderCountryCode }}</td>
          <td class="text-nowrap">{{ remittance.receiverName }}</td>
          <td class="text-nowrap">{{ remittance.receiverLastName }}</td>
          <td class="text-nowrap">{{ remittance.receiverIdType }}</td>
          <td class="text-nowrap">{{ remittance.receiverAddress }}</td>
          <td class="text-nowrap">{{ remittance.receiverCountryCode }}</td>
          <td class="text-nowrap">{{ remittance.receiverTellPhoneNumber }}</td>
          <td class="text-nowrap">{{ remittance.date }}</td>
          <td class="text-nowrap">{{ remittance.transactionTime }}</td>
          <td class="text-nowrap">{{ remittance.descriptionPurpose }}</td>
          <td class="text-nowrap">{{ remittance.originAmountCurrency }}</td>
          <td class="text-nowrap">{{ remittance.originalAmount| number:'1.2-2' }}</td>
          <ng-container *ngIf="data.reportCurrency !== 'TRY'">
            <td class="text-nowrap">{{ remittance.payingExchangeRate| number:'1.2-5' }}</td>
            <td class="text-nowrap">{{ remittance.amountInTL| number:'1.2-2' }}</td>
          </ng-container>
        </tr>
        </tbody>
        <tbody>
          <tr class="table-borderless">
            <td colspan="16" class="text-center"><strong>{{'grandTotal' | translate}}</strong></td>
            <td class="text-nowrap">{{ data.grandTotalInOriginal | number:'1.2-2' }}</td>
            <ng-container *ngIf="data.reportCurrency !== 'TRY'">
              <td class="text-nowrap">{{ '' }}</td>
              <td class="text-nowrap">{{ data.grandTotalInTL | number:'1.2-2' }}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-right">
    <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
    <button
      mat-button
      class="btn btn-outline-primary"
      [useExistingCss]="true"
      printSectionId="report"
      ngxPrint
    >
      {{ 'print' | translate }}
    </button>
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
      {{ 'export' | translate }}
    </button>
  </div>
</div>

