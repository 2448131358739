<app-index-container label="{{ 'Finsurv Reports' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row">
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
                <input matInput #remittanceCode formControlName="remittanceCode" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'Report Reference' | translate }}:</mat-label>
                <input matInput #ReportReference formControlName="ReportReference" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'startDate' | translate }}</mat-label>
                <input matInput [matDatepicker]="startDate" formControlName="startDate" [value]="fromDate.value" />
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #startDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'End Date' | translate }}</mat-label>
                <input matInput [matDatepicker]="endDate" formControlName="endDate" [value]="toDate.value" />
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker startView="month" #endDate></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-3">
                <mat-label>{{ 'Flow' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="flow">
                    <mat-radio-button class="material-radio" checked value="Out" style="margin-left: 5px">{{ 'OUT' | translate
                        }}</mat-radio-button>

                        <mat-radio-button class="material-radio" checked value="In" style="margin-left: 5px">{{ 'In' |
                            translate
                            }}</mat-radio-button>

                        <mat-radio-button class="material-radio" value="" style="margin-left: 10px">
                            {{ 'All' | translate }}
                        </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-sm-6 col-md-6 col-lg-3 mt-2">
                <mat-label>{{ 'BOP Category' | translate }}</mat-label>
                <mat-radio-group aria-label="Select an option" class="mt-2" matInput formControlName="bopCategory">
                    <div class="radio-group-row">
                        <mat-radio-button class="material-radio" value="401" style="margin-left: 5px">{{ '401 Gifts' | translate
                            }}</mat-radio-button>
                        <mat-radio-button class="material-radio" value="416" style="margin-left: 5px">{{ '416 Migrant Worker
                            Remittances' | translate }}</mat-radio-button>
                    </div>
                    <div class="radio-group-row" style="margin-top: 10px;">
                        <mat-radio-button class="material-radio" value="417" style="margin-left: 5px">{{ '417 Foreign national
                            contract Worker Remittances' | translate }}</mat-radio-button>
                        <mat-radio-button class="material-radio" value="400" style="margin-left: 5px">{{ '400 Reversals' |
                            translate }}</mat-radio-button>
                        <mat-radio-button class="material-radio" checked value="" style="margin-left: 5px">{{ 'All' | translate
                            }}</mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>

        <div class="d-flex justify-content-end">
            <button mat-raised-button type="submit" color="primary">
                <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
            </button>
        </div>
    </form>
    <table table mat-table [dataSource]="remittances" class="table">
        <!-- Number Column -->
        <ng-container matColumnDef="#">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>
        <!-- Code Column -->
        <ng-container matColumnDef="reportReference">
            <th mat-header-cell *matHeaderCellDef>{{ 'Report Reference' | translate }}</th>
            <td mat-cell *matCellDef="let row" [ngStyle]="isReportingManual ? {'color': '#0074d9', 'cursor': 'pointer'} : {}"
            (click)="isReportingManual ? viewFinsurvTransactionsDetails(row.transactions) : null">{{ row.reportReference }}</td>
        </ng-container>

        <!-- Partner Remittance ID Column -->
        <ng-container matColumnDef="reportDate">
            <th mat-header-cell *matHeaderCellDef>{{ 'Report Date' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.reportDate) }}</td>
        </ng-container>

        <ng-container matColumnDef="reportState">
            <th mat-header-cell *matHeaderCellDef> {{ 'Report State' | translate}} </th>
            <td mat-cell *matCellDef="let row"  >
                {{ row.reportState }}
            </td>
        </ng-container>

        <ng-container matColumnDef="reportTranType">
            <th mat-header-cell *matHeaderCellDef> {{ 'Report Transaction Type' | translate}} </th>
            <td mat-cell *matCellDef="let row" >
                {{ row.transactionType }}
            </td>
        </ng-container>

        <ng-container matColumnDef="reportFlow">
            <th mat-header-cell *matHeaderCellDef>{{ 'Report Flow' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.flow }}</td>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="reportBoPCategory">
            <th mat-header-cell *matHeaderCellDef>{{ 'Report BoP Category' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.bopCategory }}</td>
        </ng-container>
        <!-- Actions Column -->
        <ng-container matColumnDef="actions"  *ngIf="!isReportingManual">
            <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
            <td mat-cell *matCellDef="let row">
                <div class="action-button-menu">
                    <a (click)="viewTransactionDetails(row.reportDate, 1, row.reportReference)" *ngIf="row.canViewHistory" mat-menu-item>
                        <mat-icon>history</mat-icon>{{ 'View Transaction Details' | translate }}
                    </a>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
                {{ 'noRecordsAvailable' | translate }}
            </td>
        </tr>
    </table>
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>