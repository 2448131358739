<div class="col-md-4 mx-auto pt-5">
 <form
    *ngIf="isForceResetPassword"
    name="forceResetPwdForm"
    id="forceResetPwdForm"
    [formGroup]="forceResetPwdForm"
    novalidate
    autocomplete="off"
  >
    <mat-card class="example-card">
      <mat-card-header class="my-4">
        <mat-card-title>{{ 'resetPassword' | translate }}</mat-card-title>
        <mat-card-subtitle
          ><p>{{ message | translate }}</p></mat-card-subtitle
        >
      </mat-card-header>
      <mat-card-content class="px-3">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'newPassword' | translate }}</mat-label>
              <input
                type="password"
                matInput
                #newPassword
                formControlName="newPassword"
                [pattern]="passwordPattern"
                autocomplete="new-password"
              />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'confirmPassword' | translate }}</mat-label>
              <input
                type="password"
                matInput
                #confirmPassword
                formControlName="confirmPassword"
                autocomplete="new-password"
              />
              <mat-error *ngIf="forceResetPwdForm.value.confirmPassword">{{
                'passwordMismatch' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'otp' | translate }}</mat-label>
              <input
                type="password"
                matInput
                #code
                formControlName="code"
                autocomplete="code"
              />
            </mat-form-field>
          </div>
        </div>
        <button
          mat-flat-button
          color="primary"
          (click)="resetPassword()"
          class="px-4"
        >
          {{ 'updatePassword' | translate }}
        </button>
      </mat-card-content>
    </mat-card>
  </form>
</div>
