<table table mat-table [dataSource]="remittances" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="remittanceId">
        <th mat-header-cell *matHeaderCellDef>{{ 'remittanceId' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>

    <ng-container matColumnDef="senderName">
        <th mat-header-cell *matHeaderCellDef> {{ 'senderFullName' | translate}} </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.senderName" matTooltipPosition="after">
            {{ row.senderName }}<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="receiverName">
        <th mat-header-cell *matHeaderCellDef> {{ 'receiverFullName' | translate}} </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.receiverName" matTooltipPosition="after">
            {{ row.receiverName }} &nbsp;<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.amount | number:'1.2-2' }}{{' '+row.currency}}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- External Status Column -->
    <ng-container matColumnDef="complianceStatus">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'complianceStatus' | translate }}
        </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.complianceStatus" matTooltipPosition="after">
            {{ row.complianceStatus }}
        </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
        <td mat-cell *matCellDef="let row">
            <div class="action-button-menu">
                <a *ngIf="row.canPay" mat-menu-item routerLink="/remittances/pay/{{ row.id }}">
                    <mat-icon>send</mat-icon>{{ 'pay' | translate }}
                </a>
                <a *ngIf="row.canApprove" mat-menu-item (click)="onApproval(row.id)">
                    <mat-icon>check_circle</mat-icon>{{ 'approve' | translate }}
                </a>
                <a *ngIf="row.canBlock" mat-menu-item (click)="onBlockUnblock(row.id, 'yes')">
                    <mat-icon>block</mat-icon>{{ 'block' | translate }}
                </a>
                <a *ngIf="row.canViewHistory && row.id.toString().startsWith('tj')" mat-menu-item
                    (click)="viewHistoryDialog(row.id)">
                    <mat-icon>history</mat-icon>{{ 'history' | translate }}
                </a>
                <a *ngIf="row.canUnfreeze && row.id.toString().startsWith('tj')" mat-menu-item
                    (click)="unfreezeRemittance(row.id)">
                    <i class="fa fa-lock-open align-item-center ms-auto p-1"></i>
                    {{ 'unfreeze' | translate }}
                </a>
            </div>
            <div class="action-menu" *ngIf="!row.id.toString().startsWith('tj')">
                <a [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </a>
                <mat-menu #menu="matMenu" class="action-menu-item">
                    <!-- <a *ngIf="row.canPayback" mat-menu-item routerLink="/remittances/payback/{{ row.id }}">
                        <mat-icon>reply_all</mat-icon>{{ 'payback' | translate }}
                    </a> -->
                    <!-- <a *ngIf="row.canUnblock" mat-menu-item (click)="onBlockUnblock(row.id, 'no')">
                        <mat-icon>recommend</mat-icon>{{ 'Unblock' | translate }}
                    </a> -->
                    <a *ngIf="row.canTrace" mat-menu-item (click)="traceRemittanceDialog(row.id)">
                        <mat-icon>{{
                            row.isTraced ? 'find_replace' : 'youtube_searched_for'
                            }}</mat-icon>{{ (row.isTraced ? 'retrace' : 'trace') | translate }}
                    </a>
                    <!-- <a *ngIf="row.canViewSendReceipt" mat-menu-item (click)="sendReceiptPrintDialog(row.id)">
                        <mat-icon>receipt_long</mat-icon>{{ 'sendReceipt' | translate }}
                    </a> -->
                    <!-- <a *ngIf="row.canViewPayReceipt" mat-menu-item (click)="payReceiptPrintDialog(row.id)">
                        <mat-icon>receipt</mat-icon>{{ 'payReceipt' | translate }}
                    </a> -->
                    <!-- <a *ngIf="row.canViewPayBackReceipt" mat-menu-item (click)="payBackReceiptPrintDialog(row.id)">
                        <mat-icon>receipt</mat-icon>{{ 'payBackReceipt' | translate }}
                    </a> -->
                    <!-- <a *ngIf="row.canReturn" mat-menu-item (click)="returnRemittanceDialog(row.id)">
                        <mat-icon>undo</mat-icon>{{ 'return' | translate }}
                    </a> -->
                    <a *ngIf="row.canViewHistory" mat-menu-item (click)="viewHistoryDialog(row.id)">
                        <mat-icon>history</mat-icon>{{ 'history' | translate }}
                    </a>
                    <!-- <a *ngIf="row.canUpdateReceiverName || row.canUpdateReceiverPhone" mat-menu-item (click)="
                            viewReceiverUpdateDialog(
                              row.id,
                              row.receiverName,
                              row.receiverMobile,
                              row.canUpdateReceiverName,
                              row.canUpdateReceiverPhone
                            )
                          ">
                        <mat-icon>update</mat-icon>{{ 'update' | translate }}
                    </a> -->
                    <!-- <a *ngIf="row.canViewAttachment" mat-menu-item (click)="viewAttachments(row.id)">
                        <mat-icon>attachment</mat-icon>
                        {{ 'cancellationForm' | translate }}
                    </a> -->
                    <!-- <a *ngIf="row.canUploadSendReceipt" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 1)">
                        <mat-icon>attach_file</mat-icon>{{ 'uploadSendReceipt' | translate }}
                    </a> -->

                    <!-- <a *ngIf="row.canUploadPayReceipt" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 2)">
                        <mat-icon>attach_file</mat-icon>{{ 'uploadPayReceipt' | translate }}
                    </a> -->

                    <!-- <a *ngIf="row.canUploadSenderSignedForm" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 3)">
                        <mat-icon>attach_file</mat-icon>{{ 'uploadSenderSignedForm' | translate }}
                    </a> -->

                    <!-- <a *ngIf="row.canUploadReceiverSignedForm" mat-menu-item
                        (click)="uploadRemittanceReceipt(row.id, 4)">
                        <mat-icon>attach_file</mat-icon>{{ 'uploadReceiverSignedForm' | translate }}
                    </a> -->

                    <!-- <a *ngIf="row.canViewRemittanceReceiptDocument" mat-menu-item (click)="remittanceReceipts(row.id)">
                        <mat-icon>attach_file</mat-icon>{{ 'remittanceAttachments' | translate }}
                    </a> -->
                </mat-menu>
            </div>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
            {{ 'noRecordsAvailable' | translate }}
        </td>
    </tr>
</table>
<mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>