<h1 mat-dialog-title>{{ 'settlementDetails' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 11px; margin-top: 18px" id="settlement">
    <!-- widget content -->
    <div class="widget-body" id="firstOne" style="padding: 0px 10px">
      <div class="row">
        <div class="col-6" style="border-right: 2px dotted #000">
          <div class="printheading capitalize">SUNDUS PAY</div>
          <b>T:</b> +90212XXXXXXXX <b>F:</b> +90555XXXXXXXX
        </div>
        <div class="col-3 dottedHeading">
          <div class="printheading normal">
            {{ 'confirmSettlement' | translate }}
          </div>
          <div class="normal"></div>
        </div>
        <div class="col-3">
          <div class="normal printheading">
            {{ 'denomination' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td style="font-weight: bold" width="25%">
              {{ 'settlementType' | translate }}
            </td>
            <td colspan="3">{{ data.settlementType }}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-weight: bold">
              {{ 'settler' | translate }}
            </td>
            <td colspan="2" style="font-weight: bold">
              {{ 'beneficiary' | translate }}
            </td>
          </tr>
          <tr>
            <td>{{ 'code' | translate }}</td>
            <td width="25%">{{ data.settlerCode }}</td>
            <td width="25%">{{ 'code' | translate }}</td>
            <td width="25%">{{ data.beneficiaryCode }}</td>
          </tr>
          <tr>
            <td>{{ 'account' | translate }}</td>
            <td width="25%">
              <a>{{ data.settlerAccount }}</a>
            </td>
            <td width="25%">{{ 'account' | translate }}</td>
            <td width="25%">
              <a>{{ data.beneficiaryAccount }}</a>
            </td>
          </tr>
          <tr>
            <td>{{ 'description' | translate }}</td>
            <td width="25%">
              <a>{{ data.settlerComments }}</a>
            </td>
            <td width="25%">{{ 'description' | translate }}</td>
            <td width="25%">
              <a>{{ data.beneficiaryComments }}</a>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold" colspan="4">{{ 'settlement' | translate }}</td>
          </tr>
          <tr>
            <td>{{ 'amount' | translate }}</td>
            <td colspan="3">
              <a>{{ data.confirmedAmount | number:'1.2-2'  }}</a>
            </td>
            <td>{{ 'status' | translate }}</td>
            <td>
              <a>{{ data.status }}</a>
            </td>
          </tr>
          <tr>
            <td>{{ 'dateConfirmed' | translate }}</td>
            <td>
              <a>{{ convertDateToLocaleString(data.lastModifiedDate) }}</a>
            </td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="row" style="margin-top: 20px">
        <div class="col-6" style="text-align: left">
          {{ 'customerSignature' | translate }}: _________________________
        </div>
        <div class="col-6" style="text-align: right">
          {{ 'tellerSignature' | translate }}: __________________________
        </div>
      </div>
    </div>

    <div
      class="widget-body onlyPrintable"
      id="secondOne"
      style="padding: 0px 10px"
    >
      <div class="row">
        <div class="col-5" style="border-right: 2px dotted #000">
          <div class="printheading capitalize">SUNDUS PAY</div>
          <b>T:</b> +90212XXXXXXXX <b>F:</b> +90555XXXXXXXX <br />
          <br />
        </div>
        <div class="col-5 dottedHeading">
          <div class="printheading normal">
            {{ 'confirmSettlement' | translate }}
          </div>
          <div class="normal"></div>
        </div>
        <div class="col-2">
          <div class="normal printheading">
            {{ 'denomination' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
        <div class="col-5">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
      </div>
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td style="font-weight: bold" width="25%">
              {{ 'settlementType' | translate }}
            </td>
            <td colspan="3">{{ data.settlementType }}</td>
          </tr>
          <tr>
            <td colspan="2" style="font-weight: bold">
              {{ 'settler' | translate }}
            </td>
            <td colspan="2" style="font-weight: bold">
              {{ 'beneficiary' | translate }}
            </td>
          </tr>
          <tr>
            <td>{{ 'code' | translate }}</td>
            <td width="25%">{{ data.settlerCode }}</td>
            <td width="25%">{{ 'code' | translate }}</td>
            <td width="25%">{{ data.beneficiaryCode }}</td>
          </tr>
          <tr>
            <td>{{ 'account' | translate }}</td>
            <td width="25%">
              <a>{{ data.settlerAccount }}</a>
            </td>
            <td width="25%">{{ 'account' | translate }}</td>
            <td width="25%">
              <a>{{ data.beneficiaryAccount }}</a>
            </td>
          </tr>
          <tr>
            <td>{{ 'description' | translate }}</td>
            <td width="25%">
              <a>{{ data.settlerComments }}</a>
            </td>
            <td width="25%">{{ 'description' | translate }}</td>
            <td width="25%">
              <a>{{ data.beneficiaryComments }}</a>
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold">{{ 'settlement' | translate }}</td>
            <td colspan="3"></td>
          </tr>
          <tr>
            <td>{{ 'amount' | translate }}</td>
            <td colspan="3">
              <a>{{ data.confirmedAmount | number:'1.2-2'  }}</a>
            </td>
          </tr>
          <tr>
            <td>{{ 'status' | translate }}</td>
            <td colspan="3">
              <a>{{ data.status }}</a>
            </td>
          </tr>
          <tr>
            <td>{{ 'dateConfirmed' | translate }}</td>
            <td colspan="3">
              <a>{{ convertDateToLocaleString(data.lastModifiedDate) }}</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row" style="margin-top: 20px">
        <div class="col-6" style="text-align: left">
          {{ 'customerSignature' | translate }}: _________________________
        </div>
        <div class="col-6" style="text-align: right">
          {{ 'tellerSignature' | translate }}: __________________________
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button
    mat-raised-button
    color="primary"
    [useExistingCss]="true"
    printSectionId="settlement"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-raised-button color="warn" mat-dialog-close="close">
    {{ 'close' | translate }}
  </button>
</div>
