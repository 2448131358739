import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../_common/enums/permissions.enums';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AuthService } from '../auth/data/auth.service';
import { SupportService } from './data/support.service';
import { TicketDto } from './data/dto/support.dto';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticketDetails.component.html',
})
export class TicketDetailsComponent implements OnInit {
  form!: UntypedFormGroup;
  commentForm!: UntypedFormGroup;
  permissions = Permissions.ticketManagement.tickets;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;
  @ViewChild('commentResetBtn', { read: ElementRef })
  //@ts-ignore
  commentResetBtn: ElementRef;
  ticket?: TicketDto;
  addMode = true;
  formTitle = '';
  reportedBy: string = '';
  branches: GetBranchesDto[] = [];
  assignees: LookupDto[] = [];
  allAssignees: LookupDto[] = [];
  statuses: LookupDto[] = [];
  private selected: string | undefined;
  isDisabled: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private ticketService: SupportService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
    private supportService : SupportService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({ addMode, ticket, branches, assignees, statuses }) => {
        this.addMode = addMode;
        this.branches = branches;
        // @ts-ignore
        this.selected = this.authService.currentUserValue.branch?.id;
         this.assignees =  assignees.filter((assigne : any) => assigne.parentId == this.selected || ticket?.assigneeId == assigne.id);
        this.allAssignees = assignees;
        this.statuses = statuses;
        if (ticket) {
          this.ticket = ticket;
        }

        const formFields: any = {
          title: [this.ticket?.title, [Validators.maxLength(500)]],
          description: [
            this.ticket?.description,
            [Validators.required, Validators.maxLength(1000), Validators.pattern(regExp.alphaNumericSpecialCharacters)],
          ],
          branchId: [this.ticket?.branchId],
          assigneeId: [this.ticket?.assigneeId],
          statusId: [this.ticket?.statusId],
        };

        this.form = this.fb.group(formFields);
        this.commentForm = this.fb.group({
          comment: [null, [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
        });
        if (this.addMode) {
          this.formTitle = 'addTicket';
          const currentUser = this.authService.currentUserValue;
          this.reportedBy =
            currentUser?.firstName + ' ' + currentUser?.lastName;
          this.form.get('branchId')?.setValue(this.selected);
          this.form.get('assigneeId')?.setValue(currentUser?.id);
        } else if (
          this.authService.isUserAuthorized(this.permissions.UPDATE_TICKET)
        ) {
          this.formTitle = 'updateTicket';
          this.reportedBy = this.ticket?.reportedBy ?? '';
          this.isDisabled = true;
        } else {
          this.formTitle = 'viewTicket';
          this.reportedBy = this.ticket?.reportedBy ?? '';
          this.form.disable();
          this.commentForm.disable();
        }
      },
    );
  }

  onBranchSelected(branchId: number) {
    this.assignees = this.allAssignees.filter((f) => f.parentId == branchId);
    this.form.get('assigneeId')?.setValue(this.assignees[0]?.id);
  }

  onAssigneeSelected(assigneeId: number) {
    const assignee = this.assignees.find((f) => f.id == assigneeId);
    this.form.get('branchId')?.setValue(assignee?.parentId);
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.getRawValue() || {};

      // any of these two (assigneeId or branchId) should be provided always
      if (!formValues.branchId && !formValues.assigneeId) {
        alert('Please select branch or assignee.');
        return;
      }

      const ticketDto: TicketDto = {
        title: formValues.title,
        description: formValues.description,
        branchId: formValues.branchId,
        assigneeId: formValues.assigneeId,
        statusId: formValues.statusId,
      };

      const handler = this.ticket
        ? this.ticketService.updateTicket({
            id: this.ticket?.id,
            ...ticketDto,
          })
        : this.ticketService.createTicket(ticketDto);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          if (this.addMode) {
            this.resetBtn?.nativeElement.click();
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  onCommentSubmit() {
    if (this.commentForm.valid && this.ticket?.id) {
      const comment = this.commentForm.value.comment;
      this.ticketService.addComment(comment, this.ticket.id).subscribe(
        (resp) => {
          this.ticket?.comments?.unshift({
            comment,
            username: this.authService.currentUserValue?.username || '',
            datetime: Date().toString(),
          });
          this.commentForm.get('comment')?.setValue('');
          this.commentResetBtn?.nativeElement.click();
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  loadHistory() {
    // @ts-ignore
    this.supportService.getTicketById(this.ticket.id).subscribe(resp =>{
      this.ticket = resp
    });
  }
}
