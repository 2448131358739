import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/data/auth.service';
import { LookupsService } from './../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import { RemittancePurposesPopupComponent } from './remittancePurposesPopup.component';

@Component({
  selector: 'app-remittance-purposes',
  templateUrl: './remittancePurposes.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class RemittancePurposesComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  displayedColumns: string[] = ['number', 'name', 'code', 'actions'];
  data = new MatTableDataSource<LookupsDto>();
  permissions = Permissions.branchManagement.branches;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public lookupsService: LookupsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.lookupsService.remittancePurposes.subscribe((purposes) => {
      this.data.data = purposes;
      setTimeout(() => {
        this.data.paginator = this.paginator;
      }, 100);
    });
  }

  onSubmit() {
    this.lookupsService.getRemittancePurposes();
  }

  addRemittancePurposeDialog(purposeId?: number) {
    if (purposeId) {
      this.lookupsService
        .getRemittancePurpose(purposeId)
        .subscribe((purpose) => {
          this.dialog.open(RemittancePurposesPopupComponent, {
            data: { ...purpose },
            width: '400px',
          });
        });
    } else {
      this.dialog.open(RemittancePurposesPopupComponent, {
        data: [],
        width: '400px',
      });
    }
  }
}
