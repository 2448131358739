export enum SystemBranchCodes {
  TKCPO = 'TKCPO', // eslint-disable-line no-unused-vars
  PTTAAJ = 'PTTAAJ', // eslint-disable-line no-unused-vars
}

export enum CountryISO2s {
  TURKEY = 'TR',
}

export enum CustomerTypes {
  Person = 1,
  Corporate = 2
}

export enum RemittanceDocumentTypes {
  CANCELLATION_FORM = 'Cancellation Form',
  AUTHORIZED_SIGNATURE_DOCUMENT = 'Authorized signature Document',
  SEND_REMITTANCE_RECEIPT = 'Send Remittance Receipt',
  PAY_REMITTANCE_RECEIPT = 'Pay Remittance Receipt',
  SENDER_CUSTOMER_SIGNED_FORM = 'Sender Customer Signed Form',
  RECEIVER_CUSTOMER_SIGNED_FORM = 'Receiver Customer Signed Form'
}
