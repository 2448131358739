<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
>
  <h1 class="h2">Layout Editor</h1>
</div>
<div class="col-md-4 pt-2">
  <form
    form
    name="colorThemeForm"
    id="colorThemeForm"
    [formGroup]="colorThemeForm"
    *ngIf="colorThemeForm"
    novalidate
    autocomplete="off"
  >
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="fill">
          <mat-label>Select layout color</mat-label>
          <mat-select #colorPalette formControlName="colorPalette">
            <mat-option
              value="theme-gray-palette"
              style="background-color: #90a4ae"
              >Gray
            </mat-option>
            <mat-option
              value="theme-blue-palette"
              style="background-color: #64b5f6"
              >Blue
            </mat-option>
            <mat-option
              value="theme-yellow-palette"
              style="background-color: #dbd24d"
              >Yellow
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <button mat-flat-button color="primary" (click)="saveChanges()">
          Save changes
        </button>
      </div>
    </div>
  </form>
</div>
