import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SupportService } from './support.service';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { TicketDto } from './dto/support.dto';

@Injectable()
export class TicketByIdResolver implements Resolve<any> {
  constructor(private supportService: SupportService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<TicketDto> {
    const { ticketId } = route.params;
    return this.supportService.getTicketById(ticketId);
  }
}

@Injectable()
export class TicketStatusResolver implements Resolve<any> {
  constructor(private supportService: SupportService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<LookupDto[]> {
    return this.supportService.getStatus();
  }
}

@Injectable()
export class TicketBranchesResolver implements Resolve<any> {
  constructor(private supportService: SupportService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<LookupDto[]> {
    return this.supportService.getBranches();
  }
}

@Injectable()
export class TicketAssigneeResolver implements Resolve<any> {
  constructor(private supportService: SupportService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<LookupDto[]> {
    return this.supportService.getAssignees();
  }
}
