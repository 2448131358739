import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  RemittanceCommissionConfigurationsDto,
  RemittanceCommissionConfigurationsRequestsDto,
  UpdateCommConfigRequestStatus,
} from './dto/commissionConfigurations.dto';
import { HttpService } from '../../_common/data/http.service';
import { CRUDResponseDto, LookupDto } from '../../_common/data/dto/api.dto';

type CommissionConfigFilter = {
  id?: number;
  isActive?: boolean;
} | null;

export type CommissionConfigRequestFilter = {
  statusId?: number;
} | null;

type GetCommissions = {
  appliedFilters: CommissionConfigFilter;
  commissionConfigs: RemittanceCommissionConfigurationsDto[];
};

type GetCommissionConfigRequests = {
  appliedFilters: CommissionConfigRequestFilter;
  commissionConfigRequests: RemittanceCommissionConfigurationsRequestsDto[];
};

@Injectable()
export class CommissionConfigurationService {
  private _commissionConfigs = new BehaviorSubject<GetCommissions>({
    appliedFilters: null,
    commissionConfigs: [],
  });
  commissionConfigs: Observable<GetCommissions>;
  private _commissionConfigsRequests =
    new BehaviorSubject<GetCommissionConfigRequests>({
      appliedFilters: null,
      commissionConfigRequests: [],
    });
  commissionConfigsRequest: Observable<GetCommissionConfigRequests>;
  constructor(private httpService: HttpService) {
    this.commissionConfigs = this._commissionConfigs;
    this.commissionConfigsRequest = this._commissionConfigsRequests;
  }

  getCommissionConfigs(filters: CommissionConfigFilter = null) {
    const filter = filters?.id
      ? { id: filters.id, isActive: undefined }
      : filters?.isActive != undefined
      ? { id: null, isActive: filters.isActive }
      : undefined;
    return this.httpService.get<RemittanceCommissionConfigurationsDto[]>(
      'remittance/commissionconfiguration/list',
      filter,
    );
  }

  getCommissionConfigRequest(filters: CommissionConfigRequestFilter = null) {
    return this.httpService.get<
      RemittanceCommissionConfigurationsRequestsDto[]
    >('remittance/commissionconfiguration/request/list', filters);
  }

  triggerGetCommissionConfigs(filters: CommissionConfigFilter = null) {
    this.getCommissionConfigs(filters).subscribe((commissionConfigs) => {
      this._commissionConfigs.next({
        commissionConfigs: commissionConfigs,
        appliedFilters: filters,
      });
    });
  }

  triggerGetCommissionConfigRequests(
    filters: CommissionConfigRequestFilter = null,
  ) {
    this.getCommissionConfigRequest(filters).subscribe(
      (commissionConfigRequests) => {
        this._commissionConfigsRequests.next({
          commissionConfigRequests: commissionConfigRequests,
          appliedFilters: filters,
        });
      },
    );
  }

  getCommissionConfigById(
    id: number,
  ): Observable<RemittanceCommissionConfigurationsDto[]> {
    return this.getCommissionConfigs({
      id,
    });
  }

  createCommissionConfig(
    commissionConfig: RemittanceCommissionConfigurationsDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/commissionconfiguration/create',
      commissionConfig,
    );
  }

  updateCommissionConfig(
    commissionConfig: RemittanceCommissionConfigurationsDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/commissionconfiguration/update',
      commissionConfig,
    );
  }

  updateCommConfigRequest(
    payload: UpdateCommConfigRequestStatus,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/commissionconfiguration/request/update',
      payload,
    );
  }

  getCommConfigRequestStatus(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      'remittance/commissionconfiguration/status/list',
    );
  }
}
