<app-form-container label="{{ label }}">
  <ng-content headerActions select="[headerActions]"></ng-content>
  <ng-content
    class="overflow-auto mat-elevation-z8 m-3 px-3"
    form
    select="[form]"
  ></ng-content>
</app-form-container>
<div class="overflow-auto mat-elevation-z2 m-3 px-3">
  <ng-content select="[table]"></ng-content>
  <ng-content select="[paginator]"></ng-content>
</div>

<!--<div class="loading-shade"
     *ngIf="isLoadingResults || isRateLimitReached">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
  <div class="rate-limit-reached" *ngIf="isRateLimitReached">
    GitHub's API rate limit has been reached. It will be reset in one minute.
  </div>
</div> -->
