export enum LoginTypes {
  GOOGLE = 'google', // eslint-disable-line no-unused-vars
  SMS = 'sms', // eslint-disable-line no-unused-vars
  FORCERESETPASSWORD = 'forceResetPassword', // eslint-disable-line no-unused-vars
  FORCEOTPCONFIGURATION = 'forceOTPConfiguration', // eslint-disable-line no-unused-vars
  CONFIGOTP = 'configOtp', // eslint-disable-line no-unused-vars
  ENABLE2FA = 'enableOtp', // eslint-disable-line no-unused-vars
  SENDFORGOTPASSWORDEMAIL = 'sendForgotPasswordEmail',
  FORGOTPASSWORD = 'forgotPassword'
}
