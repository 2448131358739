<h1 mat-dialog-title>{{ 'remittanceReport' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-2 overflow-auto">
    <table
      mat-table
      [dataSource]="data"
      matTableExporter
      #exporter="matTableExporter"
      class="mat-elevation-z8 remitaanceReportTable"
    >
      <!-- Remittance Column -->
      <ng-container matColumnDef="remittanceDetails">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'remittanceDetails' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div>
            <em class="reportTitles">{{ 'remittanceId' | translate }}: </em>
            {{ row.id }}
          </div>
          <div>
            <em class="reportTitles">{{ 'remittanceAmount' | translate }}: </em>
            {{ row.amount | number:'1.2-2'}}
          </div>
          <div>
            <em class="reportTitles"
              >{{ 'remittanceCommission' | translate }}:
            </em>
            {{ row.commission | number:'1.0-5'}}
          </div>
          <div>
            <em class="reportTitles">{{ 'remittanceStatus' | translate }}: </em>
            {{ row.status }}
          </div>
        </td>
      </ng-container>
      <!-- Sender Column -->
      <ng-container matColumnDef="senderDetails">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'senderDetails' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div>
            <em class="reportTitles">{{ 'senderName' | translate }}: </em>
            {{ row.senderFullName }}
          </div>
          <div>
            <em class="reportTitles">{{ 'senderMobile' | translate }}: </em>
            {{ row.senderMobile }}
          </div>
          <div>
            <em class="reportTitles">{{ 'senderCountry' | translate }}: </em>
            {{ row.senderCountry }}
          </div>
          <div *ngIf="row.senderCity">
            <em class="reportTitles">{{ 'senderCity' | translate }}: </em>
            {{ row.senderCity }}
          </div>
          <div>
            <em class="reportTitles">{{ 'senderBranch' | translate }}: </em>
            {{ row.sendingBranchName }}
          </div>
          <div>
            <em class="reportTitles">{{ 'sentDate' | translate }}: </em>
            {{ convertDateToLocaleString(row.sentDate) }}
          </div>
        </td>
      </ng-container>
      <!-- Reciever Column -->
      <ng-container matColumnDef="recieverDetails">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'recieverDetails' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          <div>
            <em class="reportTitles">{{ 'receiverName' | translate }}: </em>
            {{ row.receiverFullName }}
          </div>
          <div>
            <em class="reportTitles">{{ 'receiverMobile' | translate }}: </em>
            {{ row.receiverMobile }}
          </div>
          <div>
            <em class="reportTitles">{{ 'receiverCountry' | translate }}: </em>
            {{ row.receiverCountry }}
          </div>
          <div *ngIf="row.receiverCity">
            <em class="reportTitles">{{ 'receiverCity' | translate }}: </em>
            {{ row.receiverCity }}
          </div>
          <div *ngIf="row.receiverBranchName">
            <em class="reportTitles">{{ 'receiverBranch' | translate }}: </em>
            {{ row.receiverBranchName }}
          </div>
          <div *ngIf="row.paidDate">
            <em class="reportTitles">{{ 'paidDate' | translate }}: </em>
            {{ convertDateToLocaleString(row.paidDate) }}
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-button (click)="exporter.exportTable('csv')">
    {{ 'exportCSV' | translate }}
  </button>
</div>
