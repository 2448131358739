import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import {
  BranchCurrenciesFilter,
  BranchesService,
} from './data/branches.service';
import {
  GetBranchesDto,
  SearchBranchCurrencyDto,
} from './data/dto/branches.dto';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { BranchCurrencyApprovalPopupComponent } from './branchCurrencyApprovalPopup.component';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SystemBranchCodes } from '../_common/enums/systemBranches.enums';
import { Utilities } from '../_common/utils';
import {SnackBarService} from "../_common/snackBar.service";

@Component({
  selector: 'app-rate-change-requests',
  templateUrl: './rateChangeRequests.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class RateChangeRequestsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.branchManagement.rateChangeRequests;
  resultsLength = 0;
  loading = false;
  branches: GetBranchesDto[] = [];
  branchCurrenciesColumns: string[] = [
    'number',
    'branchCode',
    'currencyCode',
    'exchangeRateTypeName',
    'newRate',
    'oldRate',
    'status',
    'requestDate',
    'requesterComments',
    'statusChangeDate',
    'statusChangeComments',
    'actions',
  ];
  data = new MatTableDataSource<SearchBranchCurrencyDto>();
  rateChangeStatuses: LookupDto[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private branchesService: BranchesService,
    public authService: AuthService,
    public dialog: MatDialog,
    private snackBar: SnackBarService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ branches, rateChangeStatuses }) => {
      this.branches = [...branches].filter(
        (filter) => filter.code !== SystemBranchCodes.TKCPO,
      );
      this.rateChangeStatuses = rateChangeStatuses;
    }, ({ message }) => {
      this.snackBar.open(message);
    });

    this.form = this.fb.group({
      branchId: [null],
      statusId: [null],
    });

    this.branchesService.getRateChangeRequests.subscribe(
      ({ appliedFilters, data }) => {
        this.loading = false;
        this.data.data = data;
        this.data.paginator = this.paginator;
        this.resultsLength = data.length;
        this.form.controls['branchId'].setValue(
          this.branches.find(
            (branch) => branch.id === appliedFilters?.branchId,
          ),
        );
        this.form.controls['statusId'].setValue(
          this.rateChangeStatuses.find(
            (status) => status.id === appliedFilters?.statusId,
          ),
        );
      },
      ({ message }) => {
        this.snackBar.open(message);
      }
    );
  }

  onSubmit() {
    const { branchId, statusId } = this.form.value;
    const filters: BranchCurrenciesFilter = {};

    if (branchId) filters.branchId = branchId.id;
    if (statusId) filters.statusId = statusId.id;

    this.branchesService.searchBranchCurrencies(filters);
  }

  onBranchCurrency(id: number) {
    const dialogRef = this.dialog.open(BranchCurrencyApprovalPopupComponent, {
      width: '1000px',
      disableClose: true,
      data: this.data.data.find((r) => r.id == id),
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      if (resp.success) this.onSubmit();
    });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
