import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { RemittanceCommissionConfigurationsDto } from './dto/commissionConfigurations.dto';
import { CommissionConfigurationService } from './commissionConfigurations.service';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';

@Injectable()
export class CommissionConfigurationsResolver
  implements Resolve<RemittanceCommissionConfigurationsDto[]>
{
  constructor(
    private commissionConfigurationService: CommissionConfigurationService,
  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<RemittanceCommissionConfigurationsDto[]> {
    const { commissionConfigId } = route.params;
    return this.commissionConfigurationService.getCommissionConfigById(
      commissionConfigId,
    );
  }
}

@Injectable()
export class CommissionConfigRequestStatusResolver implements Resolve<any> {
  constructor(
    private commissionConfigurationService: CommissionConfigurationService,
  ) {}
  resolve(): Observable<LookupDto[]> {
    return this.commissionConfigurationService.getCommConfigRequestStatus();
  }
}
