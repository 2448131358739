<h1 mat-dialog-title>{{ 'manualJournalReceipt' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 11px; margin-top: 18px" id="journal">
    <!-- widget content -->
    <div class="widget-body" id="firstOne" style="padding: 0px 10px">
      <div class="row">
        <div class="col-5" style="border-right: 2px dotted #000">
          <div class="printheading capitalize">SUNDUS PAY</div>
          <b>T:</b> +90212XXXXXXXX <b><br>F:</b> +90555XXXXXXXX <br />
        </div>
        <div class="col-5 dottedHeading">
          <div class="printheading normal">
            {{ 'manualJournal' | translate }}
          </div>
          <div class="normal"></div>
        </div>
        <div class="col-2">
          <div class="normal printheading">
            {{ 'denomination' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
        <div class="col-5">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div><b>{{ 'teller' | translate }}:</b> {{ data.username }}</div>
        </div>
        <div class="col-6">
          <div><b>{{ 'date' | translate }}:</b> {{ convertDateToLocaleString(data.journalDate) }}</div>
        </div>
      </div>
      <div class="row" style="margin-top: 20px">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>
                <b>{{ 'manualJournalId' | translate }}</b>
              </th>
              <th>
                <b>{{ 'accountName' | translate }}</b>
              </th>
              <th>
                <b>{{ 'debit' | translate }}</b>
              </th>
              <th>
                <b>{{ 'credit' | translate }}</b>
              </th>
              <th>
                <b>{{ 'currency' | translate }}</b>
              </th>
              <th>
                <b>{{ 'amount' | translate }}</b>
              </th>
            </tr>
            <tr *ngFor="let detail of data.manualJournalDetails">
              <td>{{ detail.manualJournalDetailId }}</td>
              <td>{{ detail.account }}</td>
              <td>{{ detail.debitAccountCurrencyAmount | number:'1.2-2' }}</td>
              <td>{{ detail.creditAccountCurrencyAmount | number:'1.2-2' }}</td>
              <td>{{ detail.currency }}</td>
              <td>{{ detail.usdAmount | number:'1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" style="margin-top: 10px">
        <p>{{ 'comments' | translate }}: {{ data.comments }}</p>
      </div>
      <div class="row" style="margin-top: 20px">
        <div class="col-6" style="text-align: left">
          {{ 'customerSignature' | translate }}: _________________________
        </div>
        <div class="col-6" style="text-align: right">
          {{ 'tellerSignature' | translate }}: __________________________
        </div>
      </div>
    </div>

    <div
      class="widget-body onlyPrintable"
      id="secondOne"
      style="padding: 0px 10px"
    >
      <div class="row">
        <div class="col-5" style="border-right: 2px dotted #000">
          <div class="printheading capitalize">SUNDUS PAY</div>
          <b>T:</b> +90212XXXXXXXX <b>F:</b> +90555XXXXXXXX <br />
          <br />
        </div>
        <div class="col-5 dottedHeading">
          <div class="printheading normal">
            {{ 'manualJournal' | translate }}
          </div>
          <div class="normal"></div>
        </div>
        <div class="col-2">
          <div class="normal printheading">
            {{ 'denomination' | translate }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
        <div class="col-5">
          <div class="bottomdotted bottomdottedStyle"></div>
        </div>
      </div>
      <div class="row">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td>
                <i>{{ 'date' | translate }}:</i>
              </td>
              <td width="25%">
                {{ convertDateToLocaleString(data.journalDate) }}
              </td>
              <td width="75%"></td>
            </tr>
            <tr>
              <td>
                <i>{{ 'teller' | translate }}</i
                >:
              </td>
              <td width="25%">
                <a>{{ data.username }}</a>
              </td>
              <td width="75%"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" style="margin-top: 20px">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>
                <b>{{ 'manualJournalId' | translate }}</b>
              </th>
              <th>
                <b>{{ 'accountName' | translate }}</b>
              </th>
              <th>
                <b>{{ 'debit' | translate }}</b>
              </th>
              <th>
                <b>{{ 'credit' | translate }}</b>
              </th>
              <th>
                <b>{{ 'currency' | translate }}</b>
              </th>
              <th>
                <b>{{ 'amount' | translate }}</b>
              </th>
            </tr>
            <tr *ngFor="let detail of data.manualJournalDetails">
              <td>{{ detail.manualJournalDetailId }}</td>
              <td>{{ detail.account }}</td>
              <td>{{ detail.debitAccountCurrencyAmount | number:'1.2-2' }}</td>
              <td>{{ detail.creditAccountCurrencyAmount | number:'1.2-2' }}</td>
              <td>{{ detail.currency }}</td>
              <td>{{ detail.usdAmount | number:'1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" style="margin-top: 10px">
        <p>{{ 'comments' | translate }}: {{ data.comments }}</p>
      </div>
      <div class="row" style="margin-top: 20px">
        <div class="col-6" style="text-align: left">
          {{ 'customerSignature' | translate }}: _________________________
        </div>
        <div class="col-6" style="text-align: right">
          {{ 'tellerSignature' | translate }}: __________________________
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-end">
    <button
      mat-raised-button
      *ngIf="canApprove"
      color="primary"
      (click)="updateJournal(data.manualJournalId, 'approve')"
    >
      {{ 'approve' | translate }}
    </button>
    &nbsp;
    <button
      mat-raised-button
      *ngIf="canReject"
      color="warn"
      (click)="updateJournal(data.manualJournalId, 'reject')"
    >
      {{ 'reject' | translate }}
    </button>
    &nbsp;
    <button
      mat-raised-button
      color="primary"
      [useExistingCss]="true"
      printSectionId="journal"
      ngxPrint
    >
      {{ 'print' | translate }}
    </button>
    &nbsp;
    <button mat-raised-button mat-dialog-close="close">
      {{ 'close' | translate }}
    </button>
  </div>
</div>
<div mat-dialog-actions></div>
