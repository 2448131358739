import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CRUDResponseDto, LookupDto } from 'src/app/_common/data/dto/api.dto';
import { PayeeBranchesDto } from './dto/payeeBranches.dto';
import {
  RemittancesDto,
  CreateRemittanceDto,
  PayRemittanceDto,
  RemittanceForPaymentResponseDto,
  RemittanceViolationsDto,
  ApproveRemittanceDto,
  RemittancesDetailsDto,
  CalculateRemittanceAmountsReqDto,
  CalculateRemittanceAmountsResDto,
  ReturnRemittanceDto,
  RemittancesHistoryDto,
  BlockUnblockRemittanceDto,
  TraceRemittanceDto,
  RemittanceSubType,
  PaybackRemittanceDto,
  RemittanceReceiverUpdateDto,
  RemittanceForPaymentResponse,
} from './dto/remittances.dto';
import { HttpService } from '../../_common/data/http.service';
import { CustomerService } from '../../customer/data/customer.service';
import { CountriesDto } from './dto/countries.dto';
import { EbirrOptionsDto } from './dto/ebirrOptions.dto';

export type RemittanceFilter = {
  remittanceCode?: string;
  senderBranchId?: string;
  statusId?: number;
  senderMobile?: string;
  receiverMobile?: string;
  receiverName?: string;
  remittanceId?: number;
  partnerRemittanceId?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  direction?: string;
  limit?: number;
};

export type AmbiguousRemittanceFilter = {
  remittanceCode?: string;
  remittanceId?: number;
  partnerRemittanceId?: number;
};


export type FinsurvReportFilter = {
  remittanceCode?: string;
  ReportReference?: string;
  flow?: string;
  bopCategory?: string;
  startDate?: string;
  endDate?: string;
};
type GetRemittances = {
  remittances: RemittancesDto[];
  appliedFilters: RemittanceFilter | null;
};

@Injectable()
export class RemittanceService {
  private _remittances = new BehaviorSubject<GetRemittances>({
    remittances: [],
    appliedFilters: null,
  });
  remittances: Observable<GetRemittances>;
  constructor(
    private httpService: HttpService,
    private customerService: CustomerService,
  ) {
    this.remittances = this._remittances;
  }

  getRemittances(filters: RemittanceFilter | null) {
    this.httpService
      .get<RemittancesDto[]>('remittance/search', filters)
      .subscribe((remittances) => {
        this._remittances.next({
          remittances: remittances,
          appliedFilters: filters,
        });
      });
  }


  getAmbiguousRemittances(filters: AmbiguousRemittanceFilter | null) {
    return this.httpService.get<RemittancesDto[]>('remittance/ambiguousremittances', filters);
  }

  createRemittance(
    remittanceDto: CreateRemittanceDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/create',
      remittanceDto,
    );
  }

  getRemittanceForPayment(
    remittanceId: number,
  ): Observable<RemittanceForPaymentResponse> {
    return this.httpService
      .get<RemittanceForPaymentResponse>('remittance/list/forpayment', {
        remittanceId,
      })
      .pipe(
        map((resp) => {
          const { remittance } = resp;
          resp.remittance = {
            ...remittance,
            receiverCustomers: remittance.receiverCustomers.map((m) => ({
              ...m,
              docs: m.docs?.map(this.customerService.prepareDocument),
            })),
          };
          return resp;
        }),
      );
  }

  getRemittanceForPayback(
    remittanceId: number,
  ): Observable<RemittanceForPaymentResponseDto[]> {
    return this.httpService.get<RemittanceForPaymentResponseDto[]>(
      'remittance/list/forpayment',
      { remittanceId },
    );
  }

  getRemittanceDetails(
    remittanceId: number,
  ): Observable<RemittancesDetailsDto> {
    return this.httpService.get<RemittancesDetailsDto>('remittance/details', {
      remittanceId,
    });
  }

  getRemittanceDocDetails(remittanceId: number): Observable<any> {
    return this.httpService.get<any>('remittance/document/detail', {
      remittanceId,
    });
  }

  getRemittanceDocuments(remittanceId: number): Observable<any> {
    return this.httpService.get<any>('remittance/document/remittancedocuments', {
      remittanceId,
    });
  }

  generatetTransferReceipt(remittanceCode: string): Observable<any> {
    return this.httpService.post<any>('user/generatetransferreceiptforportal', {
      remittanceCode: remittanceCode
    })
  }  

  getRemittanceHistory(
    remittanceId: number,
  ): Observable<RemittancesHistoryDto[]> {
    return this.httpService.get<RemittancesHistoryDto[]>('remittance/history', {
      remittanceId,
    });
  }

  payRemittance(remittanceDto: PayRemittanceDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/pay',
      remittanceDto,
    );
  }
  updateRemittanceReceiverName(
    dto: RemittanceReceiverUpdateDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/receiver/info/update',
      dto,
    );
  }
  paybackRemittance(
    remittanceDto: PaybackRemittanceDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/payback',
      remittanceDto,
    );
  }

  getRemittanceSubTypes(): Observable<RemittanceSubType[]> {
    return this.httpService.get<RemittanceSubType[]>('remittance/subtype/list');
  }

  getTellerAccounts(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('account/teller/list');
  }

  getPayeeBranches(): Observable<PayeeBranchesDto[]> {
    return this.httpService.get<PayeeBranchesDto[]>('branch/payee');
  }

  getEbirrOptions(): Observable<EbirrOptionsDto[]> {
    return this.httpService.get<EbirrOptionsDto[]>('remittance/ebirroptions');
  }

  getPaymentTypes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('remittance/paymenttype/list');
  }

  getRemittancePurposes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('remittance/purposes/list');
  }

  getRemittanceRelationships(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('remittance/relationships/list');
  }

  getRemittanceTracingOptions(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('remittance/tracingOptions/list');
  }

  getStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('remittance/status/list');
  }

  calculateRemittanceAmounts(
    req: CalculateRemittanceAmountsReqDto,
  ): Observable<CalculateRemittanceAmountsResDto> {
    return this.httpService.get<CalculateRemittanceAmountsResDto>(
      'remittance/calculateremittanceamounts',
      req,
    );
  }

  getRemittanceViolations(
    remittanceId: number,
  ): Observable<RemittanceViolationsDto> {
    return this.httpService.get<RemittanceViolationsDto>(
      'remittance/list/violation',
      { remittanceId },
    );
  }

  approveRemittance(dto: ApproveRemittanceDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('remittance/approve', dto);
  }

  getReceivingCountryCurrencies(
    countryId: number,
    remittanceSubTypeId: number,
  ): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      'remittance/payingcountry/currencies/list',
      { countryId, remittanceSubTypeId },
    );
  }

  returnRemittance(dto: ReturnRemittanceDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('remittance/return', dto);
  }

  traceRemittance(dto: TraceRemittanceDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('remittance/trace', dto);
  }

  unfreezeRemittance(remittanceId: string): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('remittance/unfreeze', {
      remittanceId,
    });
  }

  unfreezeAndReverseRemittance(remittanceId: string, comments: string): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('remittance/unfreezeandreverse', { remittanceId, comments } );
  }

  blockunblockkRemittance(
    dto: BlockUnblockRemittanceDto,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'remittance/blockunblock',
      dto,
    );
  }

  uploadDocument(docId: number, file: File): Observable<CRUDResponseDto> {
    const reqData = new FormData();
    reqData.append('docId', docId.toString());
    reqData.append('file', file);
    return this.httpService.post<CRUDResponseDto>(
      'remittance/document/upload',
      reqData,
    );
  }

  getAttachment(path: string) {
    return this.httpService.getAttachment('customer/attachment', { path });
  }

  getRemittanceAttachment(path: string) {
    return this.httpService.getAttachment('remittance/attachment', { path });
  }

  countries(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('branch/country/list');
  }

  payingCountries(): Observable<CountriesDto[]> {
    return this.httpService.get<CountriesDto[]>('remittance/country/list');
  }

  generateRemittancePin(remittanceId: any): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('remittance/pin/generate', {
      remittanceId,
    });
  }

  uploadRemittanceReceiptAttachment(
    remittanceId: number,
    documentTypeId: number,
    file: File[],
  ): Observable<CRUDResponseDto> {
    const reqData = new FormData();
    reqData.append('remittanceId', remittanceId?.toString());
    reqData.append('documentTypeId', documentTypeId.toString());
    if (Array.isArray(file)) {
      file.forEach((file) => {
        reqData.append('file', file);
      });
    } else {
      reqData.append('file', file);
    }
    return this.httpService.post<CRUDResponseDto>(
      `remittance/document/uploadremittancereceipt`,
      reqData,
    );
  }

  getRemittanceDocTypes() {
    return this.httpService.get<LookupDto[]>('remittance/document/remittancedocumenttypes', {});
  }

  getRemittanceDocumentAttachments(path: string): Observable<Blob> {
    return this.httpService.getAttachment('remittance/remittancedocument/attachment', { path });
  }

   getFinsurvReports(filters: FinsurvReportFilter | null) {
    return this.httpService.post<any[]>('remittance/finsurvreports', filters);
  }

  getFinsurvTransactions(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<any[]>(
      'remittance/finsurvtransactions',
      filter,
    );
  }

  cancelFinsurvTransactions(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<any[]>(
      'finsurv/canceltransaction',
      filter,
    );
  }

  reverseFinsurvTransactions(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<any[]>(
      'finsurv/reversetransaction',
      filter,
    );
  }

  replaceFinsurvTransactions(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<any[]>(
      'finsurv/replacetransaction',
      filter,
    );
  }

  resubmitFinsurvTransactions(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<any[]>(
      'finsurv/resubmittransaction',
      filter,
    );
  }

  getUnverifiedCustomersSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('customer/unverifiedsummary',  { startDate: startDate, endDate: endDate, branchId: branchId});
  }

  getVerifiedCustomersSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('customer/verifiedsummary',  { startDate: startDate, endDate: endDate, branchId: branchId});
  }

  getRejectedCustomersSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('customer/rejectedsummary',  { startDate: startDate, endDate: endDate, branchId: branchId});
  }

  getTotalCustomersSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('customer/totalsummary',  { startDate: startDate, endDate: endDate, branchId: branchId});
  }


  // Remittances

  getSendingRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/sendingsummary', { startDate: startDate, endDate: endDate, branchId: branchId});
  }
  getCancelledRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/cancelledsummary', { startDate: startDate, endDate: endDate, branchId: branchId});
  }
  getBlockedRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/blockedsummary', { startDate: startDate, endDate: endDate, branchId: branchId});
  }
  getPendingRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/pendingsummary', { startDate: startDate, endDate: endDate, branchId: branchId });
  }
  getPedningAprovalRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/pendingapprovalsummary', { startDate: startDate, endDate: endDate, branchId: branchId});
  }
  getReturnedRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/returnedsummary', { startDate: startDate, endDate: endDate, branchId: branchId});
  }
  getPaidRemittanceSummary(startDate: Date, endDate: Date, branchId: number): Observable<any[]> {
    return this.httpService.get<any[]>('remittance/paidsummary', { startDate: startDate, endDate: endDate, branchId: branchId});
  }

  getFinsurvModifications(remittanceCode: string): Observable<any[]> {
    return this.httpService
      .get<any[]>('finsurv/getfinsurvmodifications', { remittanceCode });
  }
}
