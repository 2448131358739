import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { Permissions } from '../../enums/permissions.enums';
import { AuthService } from 'src/app/auth/data/auth.service';
import { PoolingService, Notification } from '../../data/pooling.service';
import { BranchCommissionApprovalPopupComponent } from '../../../branch/branchCommissionApprovalPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { BranchCurrencyApprovalPopupComponent } from '../../../branch/branchCurrencyApprovalPopup.component';
import { BranchLimitApprovalPopupComponent } from '../../../branch/branchLimitApprovalPopup.component';
import { ManualJournalReceiptPopupComponent } from '../../../branch/manualJournalReceiptPopup.component';
import { CommissionConfigurationRequestPopupComponent } from '../../../remittance/commissionConfigs/commConfigRequestPopup.component';
import { RemittanceApprovalPopupComponent } from '../../../remittance/remittanceApprovalPopup.component';
import { environment } from '../../../../environments/environment';
import { RemittancesDto } from '../../../remittance/data/dto/remittances.dto';
import { Utilities } from '../../utils';
import {RoleRequestApprovalPopupComponent} from "../../../user/role/roleRequestApprovalPopup.component";
import {UserRequestApprovalPopupComponent} from "../../../user/userRequestApprovalPopup.component";
import {RolesService} from "../../../user/data/roles.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() loggedIn: boolean = false;
  @Input() currentUser: any;
  lang: any;
  permissions = Permissions.userManagement.users;
  notifications: Notification[] = [];
  dataLastRefreshed?: number;

  constructor(
    public translate: TranslateService,
    private dateAdaptor: DateAdapter<any>,
    public authService: AuthService,
    public poolingService: PoolingService,
    public dialog: MatDialog,
    public roleService: RolesService,
  ) {}

  ngOnInit() {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    const lang = localStorage.getItem('lang') || 'en';
    this.translate.use(lang);
    this.dateAdaptor.setLocale(lang);

    this.poolingService.notifications.subscribe((notifications) => {
      this.notifications = notifications;
    });
    this.authService.currentUser.subscribe((user) => {
      if (user) this.poolingService.start();
      else this.poolingService.stop();
    });
  }

  ngOnDestroy() {
    this.poolingService.stop();
  }

  switchLang(lang: string) {
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.dateAdaptor.setLocale(lang);
  }

  onNotificationMenuOpen() {
    if (
      !this.dataLastRefreshed ||
      Date.now() - this.dataLastRefreshed > 60 * 1000
    ) {
      this.onRefresh();
    }
  }

  onRefresh(e: MouseEvent | undefined = undefined) {
    e?.stopPropagation();

    this.poolingService.refreshData();
    this.dataLastRefreshed = Date.now();
  }

  onNotificationItemClick(notification: Notification) {
    let popupComponent;
    if (notification.type === 'commissionRequest')
      popupComponent = BranchCommissionApprovalPopupComponent;
    else if (notification.type === 'currencyRequest')
      popupComponent = BranchCurrencyApprovalPopupComponent;
    else if (notification.type === 'limitRequest')
      popupComponent = BranchLimitApprovalPopupComponent;
    else if (notification.type === 'mJRequest')
      popupComponent = ManualJournalReceiptPopupComponent;
    else if (notification.type === 'commissionConfigRequest')
      popupComponent = CommissionConfigurationRequestPopupComponent;
    else if (notification.type === 'roleRequest')
      popupComponent = RoleRequestApprovalPopupComponent;
    else if (notification.type === 'userRequest')
      popupComponent = UserRequestApprovalPopupComponent;

    if (popupComponent) this.showDialog(notification, popupComponent);
  }

  onRemittanceApprove(notification: Notification) {
    this.showDialog(notification, RemittanceApprovalPopupComponent);
  }

  onRemittanceComplianceApprove(notification: Notification) {
    const { ssTransactionUrl } = environment;
    window.open(
      `${ssTransactionUrl}${
        (notification.itemData as RemittancesDto).ssTransactionId
      }`,
      '_blank',
    );
  }

  showDialog(notification: Notification, popupComponent: any) {
    const data = notification.itemData;
    if(notification.type === 'roleRequest') {
      this.roleService.getRoleRequest(notification.id).subscribe((roleRequest)=>{
        const dialogRef = this.dialog.open(popupComponent, {
          width: '1000px',
          disableClose: true,
          data: roleRequest,
          panelClass: 'dialog-content',
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp.success) this.poolingService.refreshData();
        });
      });
    } else {
      const dialogRef = this.dialog.open(popupComponent, {
        width: '1000px',
        disableClose: true,
        data,
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp.success) this.poolingService.refreshData();
      });
    }
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
