import { Component, HostBinding, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './data/auth.service';
import { passwordRegex } from '../user/data/users.service';
import { SnackBarService } from '../_common/snackBar.service';
import { LoginRequest } from './data/loginRequest.dto';
import { of } from 'rxjs';
import { LoginTypes } from '../_common/enums/loginTypes.enums';
import { CustomValidators } from '../_common/data/customValidators';
import { AllOtpTypes } from '../_common/enums/allOtpTypes.enums';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
  // host: {
  //   class: 'w-100'
  // }
})
export class LoginComponent implements OnInit {
  @HostBinding('class') class: string = 'w-100';
  loginForm!: UntypedFormGroup;
  googleAuthenticationForm!: UntypedFormGroup;
  smsAuthenticationForm!: UntypedFormGroup;
  forceResetPwdForm!: UntypedFormGroup;
  forceOtpConfigurationForm!: UntypedFormGroup;
  form!: UntypedFormGroup;
  forgotPasswordForm!: UntypedFormGroup;

  isLogin = true;
  isGoogleLoginRequired = false;
  isSmsLoginRequired = false;
  isForceResetPassword = false;
  isForceOtpConfiguration = false;
  pageTitle!: string;
  message!: string;
  optIsselected = false;
  isForgotPasswordFormRequired = false;
  temporaryToken!: string;
  loginRequest: LoginRequest = {
    username: '',
    password: '',
    otpCode: '',
    loginType: '',
    newPassword: '',
    forceResetPassword: false,
    forceOtpConfiguration: '',
    email: ''
  };

  username!: string;
  password!: string;
  passwordPattern = passwordRegex;
  showQrCodeImg!: boolean;
  googleSteps!: boolean;
  imgUrl!: string;
  continueButton = false;
  forcedOtptype = '';

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private snackBar: SnackBarService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if(this.authService.isLoggedIn) {
      this.router.navigateByUrl('/dashboard');
    }
    this.form = this.fb.group({
      otpType: [null],
    });

    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });

    this.googleAuthenticationForm = this.fb.group({
      otp: [null, [Validators.required]],
    });

    this.smsAuthenticationForm = this.fb.group({
      smsCode: [null, [Validators.required]],
    });

    this.forceResetPwdForm = this.fb.group(
      {
        newPassword: [null, [Validators.required]],
        confirmPassword: [null, [Validators.required]],
      },
      {
        validators: CustomValidators.matchPasswords(
          'newPassword',
          'confirmPassword',
        ),
      },
    );

    this.forceOtpConfigurationForm = this.fb.group({
      otpType: [null],
    });

    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required]]
    });
  }

  login() {
    this.username = this.loginForm?.get('username')?.value;
    this.password = this.loginForm?.get('password')?.value;
    this.loginRequest.username = this.username;
    this.loginRequest.password = this.password;
    this.authService.login(this.loginRequest).subscribe(
      (data) => {
        if (data.responseType == LoginTypes.FORCEOTPCONFIGURATION) {
          this.isLogin = false;
          this.isForceOtpConfiguration = true;
        } else if (data.responseType == LoginTypes.FORCERESETPASSWORD) {
          this.isLogin = false;
          this.pageTitle = data.pageTitle;
          this.message = data.message;
          this.isForceResetPassword = true;
        } else if (data.responseType == LoginTypes.GOOGLE) {
          this.isLogin = false;
          this.isGoogleLoginRequired = true;
        } else if (data.responseType == LoginTypes.SMS) {
          this.isLogin = false;
          this.isSmsLoginRequired = true;
        } else {
          this.authService.setAuthToken(of(data)).subscribe(
            () => {
              this.router.navigateByUrl('/dashboard');
            },
            ({ message }) => {
              this.snackBar.open(message);
            },
          );
        }
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  loginWithGoogle() {
    this.loginRequest.otpCode =
      this.googleAuthenticationForm?.get('otp')?.value;
    this.loginRequest.loginType = LoginTypes.GOOGLE;
    this.authService.login(this.loginRequest).subscribe(
      (data) => {
        this.authService.setAuthToken(of(data)).subscribe(
          () => {
            this.router.navigateByUrl('/dashboard');
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  loginWithSms() {
    this.loginRequest.otpCode =
      this.smsAuthenticationForm?.get('smsCode')?.value;
    this.loginRequest.loginType = LoginTypes.SMS;
    this.authService.login(this.loginRequest).subscribe(
      (data) => {
        this.authService.setAuthToken(of(data)).subscribe(
          () => {
            this.router.navigateByUrl('/dashboard');
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  resetPassword() {
    if (this.forceResetPwdForm.valid) {
      this.loginRequest.newPassword =
        this.forceResetPwdForm?.value?.newPassword;
      this.loginRequest.forceResetPassword = true;
      this.authService.login(this.loginRequest).subscribe(
        (data) => {
          this.snackBar.open('Password has been updated successfully.');
          localStorage.clear();
          window.location.href = '/login';
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  enableOtp() {
    if (this.googleAuthenticationForm.valid) {
      this.loginRequest.forceOtpConfiguration = this.forcedOtptype;
      this.loginRequest.otpCode = this.googleAuthenticationForm.value.otp;
      this.loginRequest.loginType = LoginTypes.ENABLE2FA;
      this.authService.login(this.loginRequest).subscribe(
        () => {
          this.snackBar.open('Google two factor authentication is enabled...');
          localStorage.clear();
          window.location.href = '/login';
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  showQrCode() {
    this.authService.login(this.loginRequest).subscribe((data) => {
      this.continueButton = false;
      this.imgUrl = Object(data).qrCodeImg;
      this.showQrCodeImg = true;
    });
  }

  showSteps(otpName: string) {
    this.showQrCodeImg = false;
    if (otpName === AllOtpTypes.GOOGLE_AUTHENTICATION) {
      this.googleSteps = true;
    }
  }

  configureOTP() {
    if (this.forceOtpConfigurationForm.valid) {
      this.optIsselected = true;
      this.isForceOtpConfiguration = false;
      this.googleSteps = true;
      this.loginRequest.loginType = LoginTypes.CONFIGOTP;
      this.continueButton = true;
      this.forcedOtptype = this.forceOtpConfigurationForm.value.otpType;
    }
  }

  forgotPassword(){
    this.isForgotPasswordFormRequired = true;
    this.isLogin = false;
  }

  sendEmail() {

    const email = this.forgotPasswordForm?.get('email')?.value;
    this.loginRequest.email = email;
    this.loginRequest.loginType = LoginTypes.SENDFORGOTPASSWORDEMAIL;

    if (this.forgotPasswordForm.valid) {
    this.authService.login(this.loginRequest).subscribe(
      ({ message}) => {
        this.snackBar.open(message);
        this.forgotPasswordForm?.get('email')?.setValue('')
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
    }
  }
}
