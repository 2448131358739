<app-index-container label="{{ 'roleRequests' | translate }}">
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'userTypes' | translate }}:</mat-label>
        <mat-select #branch formControlName="userType">
          <mat-option *ngFor="let userType of userTypes" [value]="userType">
            {{ userType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'statuses' | translate }}:</mat-label>
        <mat-select #status formControlName="status">
          <mat-option
            *ngFor="let status of statuses"
            [value]="status"
          >
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <button
          class="float-end"
          mat-raised-button
          [disabled]="!form.valid"
          type="submit"
          color="primary"
        >
          <i class="fa-solid fa-magnifying-glass"></i>
          {{ 'search' | translate }}
        </button>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Old Usertype Column -->
    <ng-container matColumnDef="oldUserType">
      <th mat-header-cell *matHeaderCellDef>{{ 'oldUserType' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.oldUserType }}</td>
    </ng-container>
    <!-- Usertype Column -->
    <ng-container matColumnDef="newUserType">
      <th mat-header-cell *matHeaderCellDef>{{ 'newUserType' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.newUserType }}</td>
    </ng-container>
    <!-- Old Name Column -->
    <ng-container matColumnDef="oldName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'oldName' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.oldName }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="newName">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'newName' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.newName }}</td>
    </ng-container>
    <!-- Old Description Column -->
    <ng-container matColumnDef="oldDescription">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'oldDescription' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.oldDescription }}</td>
    </ng-container>
    <!-- Description Column -->
    <ng-container matColumnDef="newDescription">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'newDescription' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.newDescription }}</td>
    </ng-container>
    <!-- Request Date Column -->
    <ng-container matColumnDef="requestDate">
      <th mat-header-cell *matHeaderCellDef>{{ 'requestDate' | translate }}</th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="convertDateToLocaleString(row.createdDate)"
        matTooltipPosition="after"
      >
        {{ convertDateToLocaleString(row.createdDate) }}
      </td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- Status Change Date Column -->
    <ng-container matColumnDef="statusChangeDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'statusChangeDate' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="convertDateToLocaleString(row.statusChangeDate)"
        matTooltipPosition="after"
      >
        {{ convertDateToLocaleString(row.statusChangeDate) }}
      </td>
    </ng-container>
    <!-- Status Change Comments Column -->
    <ng-container matColumnDef="statusChangeComments">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'statusChangeComments' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.statusChangeComments }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="row.canApprove"
            color="primary"
            (click)="onRoleRequestAction(row.id)"
          >
            <mat-icon>edit</mat-icon>{{ 'action' | translate }}
          </a>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
