<div mat-dialog-title>
  <h1>{{ 'branchCurrency' | translate }}</h1>
</div>
<form
  class="container-fluid flex-column p-0"
  id="form"
  name="form"
  [formGroup]="form"
  novalidate
  (submit)="onSubmit()"
>
  <div mat-dialog-content>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'currency' | translate }}:</mat-label>
        <mat-select
          #currencyId
          [disabled]="!addMode"
          formControlName="currencyId"
        >
          <mat-option *ngFor="let currency of currencies" [value]="currency.id">
            {{ currency.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'exchangeRateType' | translate }}:</mat-label>
        <mat-select
          #exchangeRateTypeId
          [disabled]="!addMode"
          formControlName="exchangeRateTypeId"
        >
          <mat-option
            *ngFor="let exchangeRateType of exchangeRateTypes"
            [value]="exchangeRateType.id"
          >
            {{ exchangeRateType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'rateVariation' | translate }}:</mat-label>
        <input matInput #rateVariation formControlName="rateVariation" />
      </mat-form-field>
      <app-validation-error
        [control]="$any(form.get('rateVariation'))"
      ></app-validation-error>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'normRate' | translate }}:</mat-label>
        <input matInput #normRate formControlName="normRate" />
      </mat-form-field>
      <app-validation-error
        [control]="$any(form.get('normRate'))"
      ></app-validation-error>
    </div>
    <div class="row">
      <mat-form-field class="col" appearance="outline">
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
      <app-validation-error
        [control]="$any(form.get('comments'))"
      ></app-validation-error>
    </div>
    <div class="row">
      <mat-checkbox
        matInput
        class="mx-1"
        color="primary"
        formControlName="isActive"
        checked
        value="true"
      >
        {{ 'isActive' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button
      mat-dialog-close="close"
      mat-raised-button
      class="me-2"
      color="secondary"
    >
      {{ 'back' | translate }}
    </button>
    <button mat-raised-button type="submit" color="primary">
      {{ 'submit' | translate }}
    </button>
  </div>
</form>
