import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import { MonthlyCommissionsDto } from './dto/monthlyCommission.dto';
import { TrialBalanceReportDto } from './dto/trialBalance.dto';
import { BranchStatementDto } from './dto/branchStatement.dto';
import { SettlementReportDto } from './dto/settlementReport.dto';
import {
  RemittanceReportFilterDto,
  AccountActivityReportDto,
  AccountReportFilter,
  UnpaidRemittanceReportDto,
  UnpaidRemittanceReportDetailsDto,
  ComplianceReportDto,
  ComplianceReportDetailsDto,
} from './dto/reports.dto';
import {PartnerSettlementReportDto} from "./dto/partnerSettlementReport.dto";

export type MonthlyCommissionFilter = {
  statusId?: number;
  branchId?: number;
  date?: string;
};

export type SettlementReportFilter = {
  direction?: string;
  settlerId?: number;
  beneficiaryId?: string;
  startDate: Date;
  endDate: Date;
  statuses: string[];
};
export type UnpaidRemittanceReportFilter = {
  branchId: number;
  requestedDate: Date;
  isSubBranchesIncluded: boolean;
};
export type ComplianceReportFilter = {
  // branchId: number | null;
  startDate: string,
  endDate: string
};
export type UnpaidRemittanceReportDetailFilter = {
  remittanceIds: string;
};
export type ComplianceReportDetailFilter = {
  transactionIds: [];
};
export type CbkReportFilter = {
  startDate: Date;
  endDate: Date;
};

export type FinsurvReconciliationReportFilter = {
  startDate?: Date;
  endDate?: Date;
  transactionCategory?: string;
}

@Injectable()
export class ReportsService {
  constructor(private httpService: HttpService) {}

  getCommissionStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>(
      'remittance/commissions/status/list',
    );
  }

  getMonthlyCommissions(
    filter: MonthlyCommissionFilter,
  ): Observable<MonthlyCommissionsDto[]> {
    return this.httpService.get<MonthlyCommissionsDto[]>(
      'remittance/commissions/monthly',
      Object.values(filter) ? filter : null,
    );
  }

  searchRemittanceReport(payload: RemittanceReportFilterDto) {
    return this.httpService.get(`remittance/searchRemittanceReport`, payload);
  }

  getTrialBalanceReport(
    branchId: number,
    endDate: string,
    showInBranchCurrency: boolean,
  ): Observable<TrialBalanceReportDto> {
    return this.httpService.get<TrialBalanceReportDto>('account/report/trial', {
      branchId,
      endDate,
      showInBranchCurrency,
    });
  }

  getBranchStatementReport(
    branchId: number,
    startDate: string,
    endDate: string,
  ): Observable<BranchStatementDto> {
    return this.httpService.get<BranchStatementDto>(
      'account/report/branchstatement',
      { branchId, startDate, endDate },
    );
  }
  getBranchAccounts(branchId: number) {
    return this.httpService.get<LookupDto[]>('account/branchAccounts/list', {
      branchId,
    });
  }

  getSettlementReport(
    filter: SettlementReportFilter,
  ): Observable<SettlementReportDto> {
    return this.httpService.get<SettlementReportDto>(
      'settlement/report',
      filter,
    );
  }
  getAccountTransactions(payload: AccountReportFilter) {
    return this.httpService.get<AccountActivityReportDto[]>(
      'account/accountTransactions/list',
      payload,
    );
  }
  getUnpaidRemittanceReport(
    filter: UnpaidRemittanceReportFilter,
  ): Observable<UnpaidRemittanceReportDto[]> {
    return this.httpService.post<UnpaidRemittanceReportDto[]>(
      'remittance/unpaidremittancereport',
      filter,
    );
  }
  getUnpaidRemittanceReportDetail(
    filter: UnpaidRemittanceReportDetailFilter,
  ): Observable<UnpaidRemittanceReportDetailsDto[]> {
    return this.httpService.get<UnpaidRemittanceReportDetailsDto[]>(
      'remittance/unpaidremittancereportdetail',
      filter,
    );
  }
  getComplianceReport(
    filter: ComplianceReportFilter,
  ): Observable<ComplianceReportDto> {
    return this.httpService.get<ComplianceReportDto>(
      'remittance/compliance/report',
      filter,
    );
  }
  getComplianceReportDetail(
    filter: ComplianceReportDetailFilter,
  ): Observable<ComplianceReportDetailsDto[]> {
    return this.httpService.post<ComplianceReportDetailsDto[]>(
      'remittance/compliance/report/detail',
      filter,
    );
  }
  getPartnerSettlementReport(
    startDate: string,
    endDate: string,
  ): Observable<PartnerSettlementReportDto[]> {
    return this.httpService.get<PartnerSettlementReportDto[]>(
      'settlement/partnersettlementreport',
      { startDate, endDate },
    );
  }

  getCustomersReport(
    startDate: string,
    endDate: string,
  ): Observable<any> {
    return this.httpService.get<any>(
      'customer/customerreport',
      { startDate, endDate },
    );
  }

  getFinsurvReconciliationReport(
    filter: FinsurvReconciliationReportFilter,
  ): Observable<any[]> {
    return this.httpService.post<FinsurvReconciliationReportFilter[]>(
      'remittance/finsurvreconciliationreport',
      filter,
    );
  }

  getFinsurvReconciliationReportDetail(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<FinsurvReconciliationReportFilter[]>(
      'remittance/finsurvreconciliationreportdetails',
      filter,
    );
  }


  getCustomerRiskratingReport(
    filter: FinsurvReconciliationReportFilter,
  ): Observable<any[]> {
    return this.httpService.post<FinsurvReconciliationReportFilter[]>(
      'customer/customerriskratingreport',
      filter,
    );
  }

  getCustomerRiskratingReportDetail(
    filter: any
  ): Observable<any[]> {
    return this.httpService.post<FinsurvReconciliationReportFilter[]>(
      'customer/customerriskratingreportdetails',
      filter,
    );
  }

  getKiteworkTransactions(
    filter: any,
  ): Observable<any[]> {
    return this.httpService.post<any[]>(
      'remittance/getkiteworktransactions',
      filter,
    );
  }

}
