import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Permissions } from '../_common/enums/permissions.enums';
import {
  RemittancesDto,
  RemittanceReceiverUpdateDto,
} from './data/dto/remittances.dto';
import { RemittanceFilter, RemittanceService } from './data/remittance.service';
import { AuthService } from '../auth/data/auth.service';
import { SnackBarService } from '../_common/snackBar.service';
import { RemittanceApprovalPopupComponent } from './remittanceApprovalPopup.component';
import { RemittanceBlockingPopupComponent } from './remittanceBlockingPopup.component';
import { RemittancePayReceiptPopupComponent } from './remittancePayReceiptPopup.component';
import { RemittanceSendReceiptPopupComponent } from './remittanceSendReceiptPopup.component';
import { ActivatedRoute } from '@angular/router';
import { LookupDto } from '../_common/data/dto/api.dto';
import { RemittanceReturnPopupComponent } from './remittanceReturnPopup.component';
import { RemittanceUnblockingPopupComponent } from './remittanceUnblockingPopup.component';
import { RemittanceHistoryPopupComponent } from './remittanceHistoryPopup.component';
import { RemittanceTracePopupComponent } from './remittanceTracePopup.component';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RemittanceReceiverInformationUpdateComponent } from './remittanceReceiverInformationUpdate.component';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import { RemittancePaybackReceiptPopupComponent } from './remittancePaybackReceiptPopup.component';
import { UploadRemittanceReceiptPopupComponent } from './uploadRemittanceReceiptPopup.component';
import { RemittanceDocumentTypes } from '../_common/enums/systemBranches.enums';
import { RemittanceReceiptPopupComponent } from './remittanceReceiptPopup.component';
@Component({
  selector: 'app-remittances',
  templateUrl: './ormremittance.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class OrmRemittancesComponent implements OnInit {
  startDate: Date | null = null;
  endDate: Date | null = null;
  permissions = Permissions.remittanceManagement.remittances;
  direction: string = '2';
  displayedColumns: string[] = [
    '#',
    'remittanceId',
    'senderName',
    'receiverName',
    'amount',
    'status',
    'complianceStatus',
    'actions',
  ];
  remittances = new MatTableDataSource<RemittancesDto>();
  statusId: number | null = null;
  limit: number | undefined;
  remittanceStatuses: LookupDto[] = [];
  branches: GetBranchesDto[] = [];
  allbranches: GetBranchesDto[] = [];
  parentBranches: GetBranchesDto[] = [];
  loading = false;
  resultsLength = 0;
  isAdmin? = false;
  isRegional? = false;
  selected: string = '';
  selectedRemittanceStatus: any = '';
  remittanceFilterTypeId: number = 1;
  remittanceCode: string = '';
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isPdfFile: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private remittanceService: RemittanceService,
    public authService: AuthService,
    public dialog: MatDialog,
    private tranlateService: TranslateService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data_: any,
  ) {
    this.statusId = data_?.statusId || null;
    this.startDate = data_?.startDate || null;
    this.endDate = data_?.endDate || null;
    this.limit = data_?.limit || null;
    this.remittanceCode = data_?.remittanceCode || null;
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    const filter: RemittanceFilter = {
      statusId: this.statusId !== null ? this.statusId : undefined,
      startDate: this.startDate ? this.startDate: null, 
      endDate: this.endDate ? this.endDate: null, 
      direction: this.direction,
      limit: this.limit,
      remittanceCode: this.remittanceCode
    };

    this.remittanceService.getRemittances(filter);
  
    this.remittanceService.remittances.subscribe(
      ({ remittances, appliedFilters }) => {
        this.loading = false;
        this.remittances.data = remittances;
        this.remittances.paginator = this.paginator;
        this.resultsLength = remittances.length;
      }
    );
  }
   

  onApproval(remittanceId: number) {
    this.dialog
      .open(RemittanceApprovalPopupComponent, {
        disableClose: true,
        width: '850px',
        data: {
          id: remittanceId,
        },
      })
      .afterClosed()
      .subscribe((resp) => {
        if (resp === 'close') return;

        if (resp.success) this.loadData();
      });
  }

  onBlockUnblock(remittanceId: number, block: string) {
    if (block == 'yes') {
      const dialogRef = this.dialog.open(RemittanceBlockingPopupComponent, {
        width: '850px',
        data: '',
      });

      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') return;

        this.remittanceService
          .blockunblockkRemittance({
            remittanceId,
            block: true,
            comments: resp.comments,
          })
          .subscribe(
            (response) => {
              console.log(response);
              this.remittanceService
                .uploadDocument(response.data.doc.id, resp.docFile)
                .subscribe(
                  (resp) => {
                    this.loadData();
                    this.snackBar.open(resp?.message);
                  },
                  ({ message }) => {
                    this.snackBar.open(message);
                  },
                );
            },
            ({ message }) => {
              this.snackBar.open(message);
            },
          );
      });
    } else if (block == 'no') {
      const dialogRef = this.dialog.open(RemittanceUnblockingPopupComponent, {
        width: '850px',
        data: '',
      });
      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') return;
        this.remittanceService
          .blockunblockkRemittance({
            remittanceId,
            block: false,
            comments: resp,
          })
          .subscribe(
            (resp) => {
              this.loadData();
              this.snackBar.open(resp?.message);
            },
            ({ message }) => {
              this.snackBar.open(message);
            },
          );
      });
    }
  }

  traceRemittanceDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceTracingOptions()
      .subscribe((tracingOptions) => {
        const dialogRef = this.dialog.open(RemittanceTracePopupComponent, {
          width: '850px',
          data: tracingOptions,
          disableClose: true,
        });

        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') return;

          this.remittanceService
            .traceRemittance({
              remittanceId,
              tracingOptionId: resp.tracingOption.id,
              comments: resp.comments,
            })
            .subscribe(
              (resp) => {
                this.loadData();
                this.snackBar.open(resp?.message);
              },
              ({ message }) => {
                this.snackBar.open(message);
              },
            );
        });
      });
  }

  sendReceiptPrintDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceDetails(remittanceId)
      .subscribe((remittance) => {
        const dialogRef = this.dialog.open(
          RemittanceSendReceiptPopupComponent,
          {
            width: '850px',
            disableClose: true,
            data: remittance,
          },
        );
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.tranlateService.use(localStorage.getItem('lang') || 'en');
          }
        });
      });
  }

  payReceiptPrintDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceDetails(remittanceId)
      .subscribe((remittance) => {
        const dialogRef = this.dialog.open(RemittancePayReceiptPopupComponent, {
          width: '850px',
          data: remittance,
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.tranlateService.use(localStorage.getItem('lang') || 'en');
          }
        });
      });
  }

  payBackReceiptPrintDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceDetails(remittanceId)
      .subscribe((remittance) => {
        const dialogRef = this.dialog.open(
          RemittancePaybackReceiptPopupComponent,
          {
            width: '850px',
            data: remittance,
            disableClose: true,
          },
        );
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.tranlateService.use(localStorage.getItem('lang') || 'en');
          }
        });
      });
  }

  viewHistoryDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceHistory(remittanceId)
      .subscribe((remittanceHistory) => {
        this.dialog.open(RemittanceHistoryPopupComponent, {
          disableClose: true,
          width: '900px',
          data: remittanceHistory,
        });
      });
  }

  viewAttachments(remittanceId: number) {
    this.remittanceService
      .getRemittanceDocDetails(remittanceId)
      .subscribe((rem) => {
        const documentPath = rem.documentPaths[0];
        const cleanPath = documentPath[Object.keys(documentPath)[0]];
        this.remittanceService.getRemittanceAttachment(cleanPath).subscribe(
          (blob) => {
            if (blob) {
              this.isPdfFile = false;
              if (cleanPath.indexOf('.pdf') > -1) {
                blob = new Blob([blob], { type: 'application/pdf' });
                this.isPdfFile = true;
              }
              const objectURL = this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL(blob),
              );
              this.dialog.open(AttachmentsPopupComponent, {
                restoreFocus: true,
                width: '800px',
                data: { imageURL: objectURL, isPdfFile: this.isPdfFile },
              });
            }
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
      });
  }
  viewReceiverUpdateDialog(
    remittanceId: number,
    name: string,
    phone: string,
    canUpdateName: boolean,
    canUpdatePhone: boolean,
  ) {
    const nameSplit = name.split(' ');
    const remittanceReceiverData: RemittanceReceiverUpdateDto = {
      firstName: nameSplit[0],
      lastName: nameSplit[1],
      mobilePhone: phone,
      remittanceId,
      canUpdateReceiverName: canUpdateName,
      canUpdateReceiverPhone: canUpdatePhone,
    };
    const dialogRef = this.dialog.open(
      RemittanceReceiverInformationUpdateComponent,
      {
        width: '900px',
        data: remittanceReceiverData,
      },
    );
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      const rData: RemittanceReceiverUpdateDto = {
        remittanceId: remittanceId,
        firstName: resp.firstName,
        lastName: resp.lastName,
        mobilePhone: resp.phone,
        canUpdateReceiverName: false,
        canUpdateReceiverPhone: false,
      };
      this.remittanceService.updateRemittanceReceiverName(rData).subscribe(
        (resp) => {
          this.loadData();
          this.snackBar.open(resp?.message);
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    });
  }
  returnRemittanceDialog(remittanceId: number) {
    const dialogRef = this.dialog.open(RemittanceReturnPopupComponent, {
      disableClose: true,
      width: '850px',
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      this.remittanceService
        .returnRemittance({
          remittanceId,
          type: resp.status,
          comments: resp.comments,
        })
        .subscribe(
          (resp) => {
            this.loadData();
            this.snackBar.open(resp?.message);
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
    });
  }
  onParentBranchSelected(parentId: number) {
    this.branches = this.allbranches.some((pr) => pr.parentId == parentId)
      ? this.allbranches.filter(
          (pr) => pr.parentId == parentId || pr.id == parentId,
        )
      : this.allbranches.filter((pr) => pr.id == parentId); //  parent branch and his childs
  }
//   onChangeDirection(direction: any) {
//     this.direction = direction;
//     this.searchWithRemittanceCode();
//   }

  unfreezeRemittance(remittanceId: string) {
    if (!confirm('Are you sure to unfreeze remittance?')) {
      return;
    }

    this.remittanceService.unfreezeRemittance(remittanceId).subscribe(
      (resp) => {
        this.snackBar.open(resp?.message);
        this.loadData();
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  uploadRemittanceReceipt(remittanceId: number, receiptTypeId: any) {
    this.remittanceService
      .getRemittanceDocTypes()
      .subscribe((remitDocTypes) => {
        const remittanceSendReceipt = remitDocTypes.find(
          (recType) =>
            recType.name == RemittanceDocumentTypes.SEND_REMITTANCE_RECEIPT,
        );
        const remittancePayReceipt = remitDocTypes.find(
          (recType) =>
            recType.name == RemittanceDocumentTypes.PAY_REMITTANCE_RECEIPT,
        );
        const senderSignedForm = remitDocTypes.find(
          (recType) =>
            recType.name == RemittanceDocumentTypes.SENDER_CUSTOMER_SIGNED_FORM,
        );
        const receiverSignedForm = remitDocTypes.find(
          (recType) =>
            recType.name ==
            RemittanceDocumentTypes.RECEIVER_CUSTOMER_SIGNED_FORM,
        );

        var title = '';
        switch (receiptTypeId) {
          case 1:
            title = 'uploadSendReceipt';
            receiptTypeId = remittanceSendReceipt?.id;
            break;
          case 2:
            title = 'uploadPayReceipt';
            receiptTypeId = remittancePayReceipt?.id;
            break;
          case 3:
            title = 'senderSignedForm';
            receiptTypeId = senderSignedForm?.id;
            break;
          case 4:
            title = 'receiverSignedForm';
            receiptTypeId = receiverSignedForm?.id;
            break;
          default:
            console.log('Invalid type');
        }

        const dialogRef = this.dialog.open(
          UploadRemittanceReceiptPopupComponent,
          {
            disableClose: true,
            width: '850px',
            data: {
              remittanceId,
              title: title,
              remitDocTypes,
              receiptTypeId: receiptTypeId,
            },
          },
        );
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') return;
        });
      });
  }

  remittanceReceipts(remittanceId: number) {
    this.remittanceService
      .getRemittanceDocuments(remittanceId)
      .subscribe((remitDocs) => {
        const dialogRef = this.dialog.open(RemittanceReceiptPopupComponent, {
          disableClose: true,
          width: '850px',
          data: {
            remittanceDocuments: remitDocs,
          },
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') return;
        });
      });
  }

  onRemittanceFilterTypeChanged(event: any) {
    this.remittanceFilterTypeId = event.value;
  }
}
