<app-form-container [label]="title | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'firstName' | translate }}:</mat-label>
          <input matInput #firstName formControlName="firstName" type="text"/>
        </mat-form-field>
        <app-validation-error
          [control]="$any(form.get('firstName'))"
        ></app-validation-error>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'lastName' | translate }}:</mat-label>
          <input matInput #lastName formControlName="lastName" type="text"/>
        </mat-form-field>
        <app-validation-error
          [control]="$any(form.get('lastName'))"
        ></app-validation-error>
      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'username' | translate }}:</mat-label>
        <input
          matInput
          #username
          formControlName="username"
          [readonly]="!addMode"
          type="text"
        />
      </mat-form-field>
      <mat-form-field *ngIf="addMode" appearance="outline" class="col">
        <mat-label>{{ 'password' | translate }}:</mat-label>
        <input
          matInput
          type="password"
          #password
          formControlName="password"
          [pattern]="passwordPattern"
          autocomplete="off"
        />
        <mat-error *ngIf="form.value.password">{{
          'passwordPattern' | translate
        }}</mat-error>
      </mat-form-field>
    </div>
    <div class="row pt-2">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'email' | translate }}:</mat-label>
          <input matInput type="email" #email formControlName="email"/>
        </mat-form-field>
        <app-validation-error
          [control]="$any(form.get('email'))"
        ></app-validation-error>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'mobile' | translate }}:</mat-label>
          <input matInput #mobilePhone formControlName="mobilePhone"/>
        </mat-form-field>
        <app-validation-error
          [control]="$any(form.get('mobilePhone'))"
        ></app-validation-error>
      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'userType' | translate }}:</mat-label>
        <mat-select
          #userTypeId
          formControlName="userTypeId"
          (selectionChange)="onUserTypeChange($event.value)"
          [disabled]="!addMode"
        >
          <mat-option *ngFor="let userType of userTypes" [value]="userType.id">
            {{ userType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'role' | translate }}:</mat-label>
        <mat-select #roleId formControlName="roleId">
          <mat-option *ngFor="let role of userTypeRoles" [value]="role.id">
            {{ role.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-checkbox
        matInput
        #isActive
        color="primary"
        formControlName="isActive"
        class="col d-flex align-items-center pb-3"
      >
        {{ 'isActive' | translate }}
      </mat-checkbox>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select
          #branchId
          formControlName="branchId"
          [disabled]="!addMode"
          (selectionChange)="onBranchChange($event.value)"
        >
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <div class="col d-flex align-items-center pb-3">
        <mat-checkbox matInput #isActive color="primary" formControlName="isActive">
          {{'isActive' | translate}}
        </mat-checkbox>
      </div> -->
      <div class="col d-flex align-items-center pb-3" *ngIf="isTeller">
        <mat-checkbox
          matInput
          #isTeller
          color="primary"
          formControlName="isTeller"
          (change)="onTellerChecked($event)"
        >
          {{ 'teller' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="row" *ngIf="isTellerChecked && !hasAlreadyTellerAccount">
      <mat-form-field appearance="fill" class="col-md-6 col-lg-6 col-sm-6">
        <mat-label>{{ 'branchCurrencies' | translate }}:</mat-label>
        <mat-select
          #branchCurrencyId
          formControlName="branchCurrencyId"
          (selectionChange)="onBranchCurrencyChange($event.value)"
        >
          <mat-option
            *ngFor="let branchCurrency of branchCurrencies"
            [value]="branchCurrency.id"
          >
            {{ branchCurrency.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'tellerAccount' | translate }}:</mat-label>
        <input
          matInput
          #tellerAccountName
          formControlName="tellerAccountName"
          value="teller {{ username.value }} ({{ currencyCode }})"
          type="text"
        />
      </mat-form-field>
    </div>
    <div class="row" *ngIf="isReferralUserType">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'commissionPercent' | translate }}:</mat-label>
          <input matInput #commissionPercent formControlName="commissionPercent"/>
        </mat-form-field>
        <app-validation-error
          [control]="$any(form.get('commissionPercent'))"
        ></app-validation-error>
      </div>
    </div>
    <div class="row">
      <p class="mb-1">
        <strong>{{ 'workingHours' | translate }}</strong>
      </p>
      <div class="d-block ps-3 row">
        <mat-form-field appearance="outline" class="col col-md-2">
          <mat-label>{{ 'openingHours' | translate }}:</mat-label>
          <input
            matInput
            #openingHours
            formControlName="openingHours"
            placeholder="09:00"
            pattern="^([01]\d|2[0123]):(?:[012345]\d)"
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="col col-md-2">
          <mat-label>{{ 'closingHours' | translate }}:</mat-label>
          <input
            matInput
            #closingHours
            formControlName="closingHours"
            placeholder="18:00"
            pattern="^([01]\d|2[0123]):(?:[012345]\d)"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        *ngIf="addMode"
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/users"
      >
        {{ 'back' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="
          authService.isUserAuthorized([
            permissions.CREATE_USER,
            permissions.UPDATE_USER
          ])
        "
        type="submit"
        color="primary"
      >
        {{ title | translate }}
      </button>
    </div>
  </form>
</app-form-container>
