import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { UsersService, passwordRegex } from './data/users.service';
import { AuthService } from '../auth/data/auth.service';
import { SnackBarService } from '../_common/snackBar.service';
import { CustomValidators } from '../_common/data/customValidators';

@Component({
  selector: 'app-check-google-otp',
  templateUrl: './checkGoogleOtpPopup.component.html',
})
export class CheckGooglePopupComponent implements OnInit {
  form!: UntypedFormGroup;
  userId!: number;
  otpCode!: string;

  constructor(
    public fb: UntypedFormBuilder,
    @Optional() public dailogRef: MatDialogRef<CheckGooglePopupComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number; passwords: { newPassword: string, currentPassword: string, confirmNewPassword: string } },
    private usersService: UsersService,
    private authService: AuthService,
    private snackBar: SnackBarService,
  ) {}

  closeDialog() {
    this.dailogRef.close();
  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        googleOtpCode: [null, [Validators.required]],
      },
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.otpCode = this.form.value.googleOtpCode;
      this.userId = this.data?.id;

      this.usersService
        .changeUserPassword(
          this.data.passwords.currentPassword, this.data.passwords.newPassword,
          this.data.passwords.confirmNewPassword, this.otpCode)
        .subscribe({
          next: (resp) => {
            // @ts-ignore
            this.snackBar.open(resp?.message),
            localStorage.clear();
            window.location.href = '/login';
          },
          error: (err) => this.snackBar.open(err.message),
        });
    }
  }
}
