import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { BranchRoutesModule } from './branch.routes.module';
import { BranchesService } from './data/branches.service';
import { BranchesComponent } from './branches.component';
import { BranchDetailsComponent } from './branchDetails.component';
import {
  BranchByIdResolver,
  CountriesResolver,
  CurrenciesResolver,
  BranchParentsResolver,
  BranchTypesResolver,
  ExchangeRateTypeResolver,
  LocalitiesResolver,
  CitiesResolver,
  BranchesResolver,
  RateChangeStatuses,
  LimitChangeStatuses,
  CommissionChangeStatuses,
  BeneficiaryBranchesResolver,
  BranchCommissionPoliciesResolver,
  BranchGroupsResolver,
  AccountByIdResolver,
  ManualJournalStatuses,
} from './data/branches.resolver';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { TranslateModule } from '@ngx-translate/core';
import { BranchCurrencyPopupComponent } from './branchCurrencyPopup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { BranchCurrencyApprovalPopupComponent } from './branchCurrencyApprovalPopup.component';
import { RateChangeRequestsComponent } from './rateChangeRequests.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { BranchLimitApprovalPopupComponent } from './branchLimitApprovalPopup.component';
import { LimitChangeRequestsComponent } from './limitChangeRequests.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BranchCommissionApprovalPopupComponent } from './branchCommissionApprovalPopup.component';
import { CommissionChangeRequestsComponent } from './commissionChangeRequests.component';
import { BrancheCommissionsComponent } from './branchCommissions.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManualJournalsComponent } from './manualJournals.component';
import { ManualJournalDetailsComponent } from './manualJournalDetails.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AccountsComponent } from './accounts.component';
import { AccountDetailsComponent } from './accountDetails.component';
import { ValidationErrorModule } from '../_common/components/validation-error/validation-error.module';
import { ManualJournalReceiptPopupComponent } from './manualJournalReceiptPopup.component';
import { NgxPrintModule } from 'ngx-print';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ManualJournalDocPopupComponent } from './manualJournalDocPopup.component';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
@NgModule({
  declarations: [
    BranchesComponent,
    BranchDetailsComponent,
    BranchCurrencyPopupComponent,
    BranchCurrencyApprovalPopupComponent,
    RateChangeRequestsComponent,
    BranchLimitApprovalPopupComponent,
    LimitChangeRequestsComponent,
    BranchCommissionApprovalPopupComponent,
    CommissionChangeRequestsComponent,
    BrancheCommissionsComponent,
    ManualJournalsComponent,
    ManualJournalDetailsComponent,
    AccountsComponent,
    AccountDetailsComponent,
    ManualJournalReceiptPopupComponent,
    ManualJournalDocPopupComponent,
    AttachmentsPopupComponent,
  ],
  imports: [
    CommonModule,
    BranchRoutesModule,

    ContainersModule,

    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatStepperModule,
    TranslateModule,
    MatDialogModule,
    MatRadioModule,
    MatExpansionModule,
    MatRadioModule,
    MatMenuModule,
    MatIconModule,
    NgSelectModule,
    ValidationErrorModule,
    MatDatepickerModule,
    NgxPrintModule,
    MatTooltipModule,
  ],
  exports: [],
  providers: [
    BranchesService,
    BranchTypesResolver,
    CurrenciesResolver,
    CountriesResolver,
    BranchParentsResolver,
    BranchByIdResolver,
    CommissionChangeStatuses,
    BranchCommissionPoliciesResolver,
    BeneficiaryBranchesResolver,
    ExchangeRateTypeResolver,
    LocalitiesResolver,
    CitiesResolver,
    BranchesResolver,
    RateChangeStatuses,
    BranchGroupsResolver,
    AccountByIdResolver,
    LimitChangeStatuses,
    ManualJournalStatuses,
  ],
})
export class BranchModule {}
