import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';



@Component({
  selector: 'app-finaurv-modification',
  templateUrl: './addFinsurvModificationPopup.component.html',
  styleUrls: ['./addFinsurvModificationPopup.component.css']
})
export class AddFinsurvModificationPopup {
  comment: string = '';
  isReplacementReport: boolean = false;
  receiverFullName: string = '';
  receiverMobile: string = '';
  commentForm!: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<AddFinsurvModificationPopup>, 
    @Inject(MAT_DIALOG_DATA) public data: { 
      isReplacementReport: boolean, 
      receiverFullName:string,
      receiverMobile: string
    },
    private fb: FormBuilder
  ) {
    this.isReplacementReport = data.isReplacementReport;
    this.receiverFullName = data.receiverFullName;
    this.receiverMobile = data.receiverMobile;
  }


  ngOnInit(): void {
    this.commentForm = this.fb.group({
      receiverName: [this.isReplacementReport ? this.receiverFullName : '', this.isReplacementReport ? Validators.required : null],
      receiverMobile: [this.isReplacementReport ? this.receiverMobile : '', this.isReplacementReport ? Validators.required : null],
      comment: ['', Validators.required],
    });
  }

  onSubmit() {
    const comment = this.commentForm.value;
    if (this.commentForm.valid){
      this.dialogRef.close(this.commentForm.value);
    }
  }
}
