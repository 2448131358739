import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackBarService } from '../_common/snackBar.service';
import { CustomerService } from './data/customer.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachmentsPopup.component.html',
})
export class AttachmentsPopupComponent {
  data: string[] = [];
  attachUrl: string[] = [];
  imageURL: any[] = [];
  isPdfFile: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AttachmentsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private attachments: {
      [key: string]: string;
    }[],
    private customerService: CustomerService,
    private snackBar: SnackBarService,
    private sanitizer: DomSanitizer,
  ) {
    this.data = attachments.map((o) => Object.values(o)[0]);
    this.data.forEach((attachUrl) => {
      this.customerService.getCustomerDocument(attachUrl).subscribe(
        (blob) => {
          this.isPdfFile = false;
          if (blob) {
            if (attachUrl.indexOf('.pdf') > -1) {
              blob = new Blob([blob], { type: 'application/pdf' });
              this.isPdfFile = true;
            }
            const objectURL = URL.createObjectURL(blob);
            this.imageURL.push(
              this.sanitizer.bypassSecurityTrustResourceUrl(objectURL),
            );
          }
        },
        (err) => {
          this.snackBar.open(err.message);
        },
      );
    });
  }
}
