<app-index-container label="{{ 'ambiguousRemittances' | translate }}">
  <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
        <input matInput #remittanceCode formControlName="remittanceCode" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceId' | translate }}:</mat-label>
        <input matInput #remittanceId formControlName="remittanceId" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'partnerRemittanceId' | translate }}:</mat-label>
        <input matInput #partnerRemittanceId formControlName="partnerRemittanceId" />
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end">
      <button mat-raised-button type="submit" color="primary">
        <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
      </button>
    </div>
  </form>
  <table table mat-table [dataSource]="remittances" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>{{ 'code' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>

    <!-- Partner Remittance ID Column -->
    <ng-container matColumnDef="partnerRemittanceId">
      <th mat-header-cell *matHeaderCellDef>{{ 'partnerRemittanceId' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.partnerRemittanceId }}</td>
    </ng-container>

    <ng-container matColumnDef="senderName">
      <th mat-header-cell *matHeaderCellDef> {{ 'senderFullName' | translate}} </th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.senderName" matTooltipPosition="after">
        {{ row.senderName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="receiverName">
      <th mat-header-cell *matHeaderCellDef> {{ 'receiverFullName' | translate}} </th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.receiverName" matTooltipPosition="after">
        {{ row.receiverName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.amount | number:'1.2-2' }}{{' '+row.currency}}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a (click)="unfreezeAndReverseRemittance(row.id)" *ngIf="row.canResolveAmbiguousRemittance && row.canUnfreeze" mat-menu-item>
            <mat-icon>undo</mat-icon>{{ 'unfreezeAndReverse' | translate }}
          </a>
          <a (click)="viewHistoryDialog(row.id)" *ngIf="row.canViewHistory" mat-menu-item>
            <mat-icon>history</mat-icon>{{ 'history' | translate }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
        {{ 'noRecordsAvailable' | translate }}
      </td>
    </tr>
  </table>
  <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>
</app-index-container>
