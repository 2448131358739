<app-form-container [label]="title | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'senderCountry' | translate }}:</mat-label>
        <mat-select #senderCountryId formControlName="senderCountryId" [disabled]="!addMode">
          <mat-option *ngFor="let country of countries" [value]="country.id">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'receiverCountry' | translate }}:</mat-label>
        <mat-select #receiverCountryId formControlName="receiverCountryId" [disabled]="!addMode">
          <mat-option *ngFor="let country of countries" [value]="country.id">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceType' | translate }}:</mat-label>
        <mat-select #remittanceTypeId formControlName="remittanceTypeId" [disabled]="!addMode">
          <mat-option *ngFor="let rt of remittanceTypes" [value]="rt.id">
            {{ rt.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceSubtype' | translate }}:</mat-label>
        <mat-select #remittanceSubTypeId formControlName="remittanceSubTypeId" [disabled]="!addMode">
          <mat-option *ngFor="let rst of remittanceSubTypes" [value]="rst.id">
            {{ rst.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'minAmount' | translate }}:</mat-label>
        <input matInput #minAmount formControlName="minAmount" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'maxAmount' | translate }}:</mat-label>
        <input matInput #maxAmount formControlName="maxAmount" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ ('minCommission' | translate) + "%"}}:</mat-label>
        <input matInput #minCommission formControlName="minCommission" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ ('commission' | translate) + "%" }}:</mat-label>
        <input matInput #commission formControlName="commission" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ ('maxCommission' | translate) + "%" }}:</mat-label>
        <input matInput #maxCommission formControlName="maxCommission" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col" appearance="outline">
        <mat-label>{{ 'requestComments' | translate }}</mat-label>
        <textarea
          matInput
          #comments
          formControlName="requesterComments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>

      <mat-radio-group
        formControlName="isActive"
        class="col-3 my-3"
        *ngIf="!addMode"
      >
        <mat-radio-button value="active">{{
          'active' | translate
        }}</mat-radio-button
        >&nbsp;
        <mat-radio-button value="inactive">{{
          'inactive' | translate
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="d-flex justify-content-end">
      <button
        *ngIf="addMode"
        mat-raised-button
        class="me-2"
        color="warn"
        #resetBtn
        type="reset"
      >
        {{ 'reset' | translate }}
      </button>
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/remittances/commissionconfigs"
      >
        {{ 'back' | translate }}
      </button>
      <button
        mat-raised-button
        *ngIf="
          authService.isUserAuthorized([
            permissions.CREATE_REMIT_COMM_CONFIG,
            permissions.UPDATE_REMIT_COMM_CONFIG
          ])
        "
        type="submit"
        color="primary"
      >
        {{ title | translate }}
      </button>
    </div>
  </form>
</app-form-container>
