import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../../_common/data/http.service';
import { CRUDResponseDto, LookupDto } from '../../_common/data/dto/api.dto';
import {
  ConfirmSettlementDto,
  CreateSettlementDto,
  GetSettlementDetailsDto,
  GetSettlementDto,
  GetSettlementHistoryDto,
} from './dto/settlement.dto';

export type SettlementFilter = {
  settlerId?: number;
  statusId?: number;
  beneficiaryId?: number;
  direction?: string;
  settlementTypeId?: number;
  settlementId?: number;
  partnerSettlement?: boolean;
};

type GetSettlements = {
  settlements: GetSettlementDto[];
  appliedFilters: SettlementFilter | null;
};

@Injectable()
export class SettlementService {
  private _settlements = new BehaviorSubject<GetSettlements>({
    appliedFilters: null,
    settlements: [],
  });
  public settlements = this._settlements.asObservable();
  constructor(private httpService: HttpService) {}

  getSettlements(filters: SettlementFilter) {
    this.httpService
      .get<GetSettlementDto[]>(
        'settlement/list',
        Object.values(filters) ? filters : null,
      )
      .subscribe((settlements) => {
        this._settlements.next({
          settlements: settlements,
          appliedFilters: filters,
        });
      });
  }

  createSettlement(dto: CreateSettlementDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('settlement/create', dto);
  }

  getSettlementTypes(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('settlement/types');
  }

  getSettlementStatus(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('settlement/status');
  }

  getSettlementDetails(
    settlementId: number,
  ): Observable<GetSettlementDetailsDto> {
    return this.httpService.get<GetSettlementDetailsDto>('settlement/details', {
      settlementId,
    });
  }

  getSettlementHistory(
    settlementId: number,
  ): Observable<GetSettlementHistoryDto[]> {
    return this.httpService.get<GetSettlementHistoryDto[]>(
      'settlement/history',
      { settlementId },
    );
  }

  checkAccountCurrency(
    accountId: string,
  ): Observable<{ currency: any; exchangeRate: number }> {
    return this.httpService.get<{ currency: any; exchangeRate: number }>(
      'settlement/account/currency',
      { accountId },
    );
  }

  transferSettlement(
    settlementId: number,
    beneficiaryId: string,
    comments: string,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('settlement/transfer', {
      settlementId,
      beneficiaryId,
      comments,
    });
  }

  confirmSettlement(dto: ConfirmSettlementDto): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>('settlement/confirm', dto);
  }

  rollbackOrRejectSettlement(
    settlementId: number,
    comments: string,
    actionType: string,
    partnerSettlementId: number,
    isPartnerSettlement: boolean,
  ): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'settlement/rollbackOrReject',
      { settlementId, comments, actionType, partnerSettlementId, isPartnerSettlement },
    );
  }
}
