<h1 mat-dialog-title>{{ 'transferSettlement' | translate }}</h1>
<div mat-dialog-content>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-form-field class="col" appearance="fill">
        <mat-label>{{ 'beneficiary' | translate }}</mat-label>
        <mat-select autoActiveFirstOption formControlName="beneficiary">
          <mat-option *ngFor="let branch of branches" [value]="branch">
            {{ branch.code + ' - ' + branch.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <mat-label
          ><h3>{{ 'settlerMemo' | translate }}</h3></mat-label
        >
        <p class="alert alert-light">{{ settlerComments }}</p>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'transfer' | translate }}
  </button>
</div>
