import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';
import { ComplianceConfigurationService } from './complianceConfiguration.service';
import { GetComplianceConfigDto } from './dto/complianceConfiguration.dto';

@Injectable()
export class ComplianceTypesResolver implements Resolve<any> {
  constructor(private complianceService: ComplianceConfigurationService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return this.complianceService.getComplianceTypes();
  }
}

@Injectable()
export class ComplianceConfigByIdResolver implements Resolve<any> {
  constructor(private complianceService: ComplianceConfigurationService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<GetComplianceConfigDto[]> {
    const { complianceConfigId } = route.params;
    return this.complianceService.getComplianceConfigById(complianceConfigId);
  }
}
