import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackBarService } from '../_common/snackBar.service';
import { MatTableDataSource } from '@angular/material/table';
import { RemittancesDto } from './data/dto/remittances.dto';
import { FinsurvReportFilter, RemittanceService } from './data/remittance.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RemittanceHistoryChangeLogPopupComponent } from './remittanceHistoryChangeLogPopup.component';
import { FinsurvTransactionsPopupComponent } from './finsurvTransactionsPopup.component';
import { Utilities } from '../_common/utils';
import { ReportsService } from '../report/data/reports.service';
import { FinsurvReconciliationDetailComponent } from '../report/finsurvreconciliationdetail.component';

@Component({
  selector: 'app-finsurv-reports',
  templateUrl: './finsurv-reports.component.html',
  styleUrls: ['./finsurv-reports.component.less']
})
export class FinsurvReportsComponent implements OnInit {

   remittances = new MatTableDataSource<RemittancesDto>();
   form!: UntypedFormGroup;
   resultsLength = 0;
   isReportingManual: any =false;
   displayedColumns: string[] = [
    '#',
    'reportReference', 'reportDate', 'reportState', 'reportTranType', 'reportFlow', 'reportBoPCategory'
  ];
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);
  constructor(
      private fb: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private remittanceService: RemittanceService,
    public dialog: MatDialog,
    private reportService: ReportsService,
  ) { }

  ngOnInit(): void {
    const filter: FinsurvReportFilter = {};

    this.remittanceService.getFinsurvReports(filter)
      .subscribe((data)=>{
        this.isReportingManual = data.some(d => d.isReportingManual);
      
        if (!this.isReportingManual) {
            this.displayedColumns.push('actions');
          }
        this.remittances.paginator = this.paginator;
      },({ message }) => {
        // this.snackBar.open(message)
        this.remittances.data = [];
       }
      );

    this.remittances.data = [];
    this.form = this.fb.group({
      remittanceCode: [null],
      ReportReference: [null],
      flow: [''],
      bopCategory: [''],
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onSubmit() {
    const { remittanceCode, ReportReference, flow, bopCategory,startDate, endDate } = this.form.value;
    const filter: FinsurvReportFilter = {};
    if(remittanceCode) filter.remittanceCode = remittanceCode;
    if(ReportReference) filter.ReportReference = ReportReference;
    if(flow) filter.flow = flow;
    if(bopCategory) filter.bopCategory = bopCategory;
    if(startDate) filter.startDate = startDate;
    if(endDate) filter.endDate = endDate;

    this.remittanceService.getFinsurvReports(filter)
      .subscribe((data)=>{
        this.isReportingManual = data.some(d => d.isReportingManual);
        if (!this.isReportingManual && !this.displayedColumns.includes('actions')){ 
          this.displayedColumns.push('actions');
        }

        this.remittances.data = data;
        this.remittances.paginator = this.paginator;
      },({ message }) => {
        // this.snackBar.open(message)
        this.remittances.data = [];
       }
      );
  }

  viewChangeLog(snapshot: any) {
    this.dialog.open(RemittanceHistoryChangeLogPopupComponent, {
      disableClose: true,
      width: '54rem',
      data: snapshot,
    });
  }

   viewFinsurvTransactionsDetails( data: any ) {
     const { startDate, endDate } = this.form.value;
      const dialogRef = this.dialog.open(FinsurvTransactionsPopupComponent, {
      data: { data: data, startDate: new Date(startDate), endDate: endDate },
      maxWidth: 'fit-content',
      maxHeight: 'fit-content',
    });


      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') {
          return;
        }
      });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }

  viewTransactionDetails( startDate: string ,tranState: number, batchNo: string) {

    let tranStates = '';

    if(tranState == 1)
       tranStates = '7,8,9,10,11,12,13,14,15';
      
    let filter = {
      startDate: startDate,
      endDate: this.form.value?.endDate,
      tranState: tranStates,
      batchNo: batchNo,
    }
    this.reportService
        .getFinsurvReconciliationReportDetail(
          filter,
        )
        .subscribe((data) => {
          const dialogRef = this.dialog.open(FinsurvReconciliationDetailComponent, {
            data: {data: data, title: 'Finsurv TransactionDetails' },
            maxWidth: 'fit-content',
            maxHeight: 'fit-content',
          });

          dialogRef.afterClosed().subscribe((resp) => {
            if (resp === 'close') {
              return;
            }
          });
        }, ({ message }) => {
          this.snackBar.open(message);
        });
  }

}
