<h1 mat-dialog-title>{{ 'updateStatus' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-1 overflow-y-auto">
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2 overflow-hidden"
      *ngIf="data"
    >
      <div
        class="d-flex align-items-center h-9x bg-light px-2 border-bottom border-dark"
      >
        <b>{{ 'remittanceCommissionRequestDetails' | translate }}</b>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'senderCountry' | translate }}:
          <p>{{ data.senderCountry }}</p>
        </div>
        <div class="col">
          {{ 'receiverCountry' | translate }}:
          <p>{{ data.receiverCountry }}</p>
        </div>
        <div class="col">
          {{ 'remittanceType' | translate }}:
          <p>{{ data.remittanceType }}</p>
        </div>
        <div class="col">
          {{ 'remittanceSubType' | translate }}:
          <p>{{ data.remittanceSubType }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'minAmount' | translate }}:
          <p>{{ data.minAmount | number:'1.2-2'  }}</p>
        </div>
        <div class="col">
          {{ 'maxAmount' | translate }}:
          <p>{{ data.maxAmount | number:'1.2-2'  }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'minCommission' | translate }}:
          <p>{{ data.minCommission | number:'1.0-5'  }}</p>
        </div>
        <div class="col">
          {{ 'commission' | translate }}:
          <p>{{ data.commission | number:'1.0-5'  }}</p>
        </div>
        <div class="col">
          {{ 'maxCommission' | translate }}:
          <p>{{ data.maxCommission | number:'1.0-5'  }}</p>
        </div>
        <div class="col"></div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'requestingUser' | translate }}:
          <p>{{ data.requestedBy }}</p>
        </div>
        <div class="col">
          {{ 'requesterComments' | translate }}:
          <p>{{ data.requesterComments }}</p>
        </div>
        <div class="col">
          {{ 'requestDate' | translate }}:
          <p>{{ convertDateToLocaleString(data.createdDate) }}</p>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>

  <!-- ... -->
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-label class="mb-4">{{ 'status' | translate }}:</mat-label>
      <mat-radio-group formControlName="rateChangeRequestStatus" class="mb-4">
        <mat-radio-button value="approveCommissionConfiguration">
          {{ 'approveCommissionConfiguration' | translate }}
        </mat-radio-button>
        <mat-radio-button value="rejectCommissionConfiguration">
          {{ 'rejectCommissionConfiguration' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="statusChangeComments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'update' | translate }}
  </button>
</div>
