<h1 mat-dialog-title>{{ 'payReceipt' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="margin-top: 5px" id="receipt">
    <!-- widget content -->
    <div class="widget-body receipt" id="firstOne">
      <div id="addDenomination">
        <div class="row">
          <div class="col-6">
            <img src="../../../../assets/images/logo/logo.svg" width="250px" /><br />
            <strong class="mt-3">Sundus Elektronik Para ve Ödeme Kuruluşu A.Ş</strong>
          </div>
          <div class="col-6">
            <p class="mb-0">
              <b>{{ 'printedDate' | translate }}:</b>
              {{ convertDateToLocaleString(today) }}
            </p>
            <p>
              Esentepe Mahallesi Büyükdere, Caddesi, No: 171,<br />
              A Metrocity D: 176, 34330 Şişli/İstanbul, Turkey.<br />
              <strong>{{ 'contact' | translate }}:</strong>
              <span>&nbsp;&nbsp;+90 (212) 631 42 43</span><br />
              <strong>{{ 'email' | translate }}:</strong>
              <span>&nbsp;&nbsp;info@tayopay.com</span><br />
              <strong>Mersis No:</strong>
              <span>&nbsp;&nbsp;0815080473300001</span>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item" style="background-color: #ecbc53">
                <strong>{{ 'receiverInformation' | translate }}</strong>
              </li>
              <li class="list-group-item">
                <strong>{{ 'fullname' | translate }}</strong
                ><span class="float-end">{{
                  getFullName(data.receiver.firstName, data.receiver.middleName,data.receiver.lastName)
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'purpose' | translate }}</strong
                ><span class="float-end">{{ data.purpose }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'idType' | translate }}</strong
                ><span class="float-end">{{
                  data.receiverPrimaryDoc?.documentType
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'idNumber' | translate }}</strong
                ><span class="float-end">{{
                maskNumber(data.receiverPrimaryDoc?.documentDetails?.number)
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'mobile' | translate }}</strong
                ><span class="float-end">{{ maskNumber(data.receiver.mobilePhone) }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'country' | translate }}</strong
                ><span class="float-end">{{ data.receiverCountry }}</span>
              </li>
              <li class="list-group-item" style="height: 100%">
                <strong>{{ 'address' | translate }}</strong
                ><span class="float-end">{{
                data.sender.customerTypeId == customerType.Person ?(
                  data.receiverAddressSublocality +
                  ', ' +
                  data.receiverAddressStreet +
                  ' No:' +
                  data.receiverAddressHouseNumber +
                  data.receiverAddressLocality +
                  '/' +
                  data.receiverAddressCity +
                  ' ' +
                  data.receiverAddressCountry) : data.receiverAddressCity + ' ' + data.receiverAddressCountry
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'description' | translate }}</strong>
                <span class="float-end">{{data.receiverComments}}</span>
              </li>
            </ul>
            <div class="row mt-2">
              <div class="col">
                {{ 'customerSignature' | translate }}
                <div
                  class="p-4"
                  style="border: 1px solid rgb(221, 221, 221)"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="list-group-item" style="background-color: #ecbc53">
                <strong>{{ 'senderInformation' | translate }}</strong>
              </li>
              <li class="list-group-item">
                <strong>{{ 'receiptNumber' | translate }}</strong><span class="float-end">{{receiptNumber}}</span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'RID' | translate }}</strong>
                <span class="float-end"><strong>{{ data.code }}</strong></span>
              </li>
              <li class="list-group-item bg-light">
                <strong>{{ 'transactionDate' | translate }}</strong>
                <span class="float-end"><strong>{{ convertDateToLocaleString(data.payDate) }}</strong></span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'fullname' | translate }}</strong
                ><span class="float-end">{{
                  getFullName(data.sender.firstName, data.sender.middleName,data.sender.lastName)
                }}</span>
              </li>
              <li class="list-group-item">
                <strong>{{ 'mobile' | translate }}</strong
                ><span class="float-end">{{ maskNumber(data.sender.mobilePhone) }}</span>
              </li>
            </ul>
            <div style="background-color: #f8f9fa;color: #202020;font-size: 15px;font-weight: 600;border: 1px solid #dee2e6;" class="p-2 mt-3">
              <div>
                {{ 'total' | translate
                }}<span class="float-end">{{ round(data.payingAmount) +' '+data.receiverAccountCurrency }}</span>
              </div>
            </div>
            <div style="color: #202020;font-size: 15px;font-weight: 600;border: 1px solid #dee2e6;" class="p-2 mt-3"
                 *ngIf="data.receiverAccountCurrency !== 'TRY'">
              <div>
                <strong style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">
                  {{ 'payingExchangeRate' | translate}}
                </strong>
                <strong class="float-end">1 {{data.receiverAccountCurrency}} = {{data.payingExchangeRate | number: '1.2-2' }} TRY
                </strong>
              </div>
            </div>

          </div>
        </div>
        <div class="py-3">
          <small style="font-size: 0.75em;line-height: 11px;">
            <p class="mb-1">{{ 'quotedText1' | translate }}</p>
            <!-- <p>{{ 'quotedText2' | translate }}</p> -->
          </small>
        </div>
        <div class="py-1 float-end">
          <p class="mb-1">{{ data.receivingBranch }}</p>
        </div>
      </div>
    </div>

    <div
      class="widget-body receipt onlyPrintable"
      id="secondOne"
      style="padding: 0px 5px"
    >
    <div id="addDenomination">
      <div class="row">
        <div class="col-6">
          <img src="../../../../assets/images/logo/logo.svg" width="250px" /><br />
          <strong class="mt-3">Sundus Elektronik Para ve Ödeme Kuruluşu A.Ş</strong>
        </div>
        <div class="col-6">
          <p class="mb-0">
            <b>{{ 'printedDate' | translate }}:</b>
            {{ convertDateToLocaleString(today) }}
          </p>
          <p>
            Esentepe Mahallesi Büyükdere, Caddesi, No: 171,<br />
            A Metrocity D: 176, 34330 Şişli/İstanbul, Turkey.<br />
            <strong>{{ 'contact' | translate }}:</strong>
            <span>&nbsp;&nbsp;+90 (212) 631 42 43</span><br />
            <strong>{{ 'email' | translate }}:</strong>
            <span>&nbsp;&nbsp;info@tayopay.com</span><br />
            <strong>Mersis No:</strong>
            <span>&nbsp;&nbsp;0815080473300001</span>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <ul class="list-group">
            <li class="list-group-item" style="background-color: #ecbc53">
              <strong>{{ 'receiverInformation' | translate }}</strong>
            </li>
            <li class="list-group-item">
              <strong>{{ 'fullname' | translate }}</strong
              ><span class="float-end">{{
                getFullName(data.receiver.firstName, data.receiver.middleName,data.receiver.lastName)
              }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'purpose' | translate }}</strong
              ><span class="float-end">{{ data.purpose }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'idType' | translate }}</strong
              ><span class="float-end">{{
                data.receiverPrimaryDoc?.documentType
              }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'idNumber' | translate }}</strong
              ><span class="float-end">{{
              maskNumber(data.receiverPrimaryDoc?.documentDetails?.number)
              }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'mobile' | translate }}</strong
              ><span class="float-end">{{ maskNumber(data.receiver.mobilePhone) }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'country' | translate }}</strong
              ><span class="float-end">{{ data.receiverCountry }}</span>
            </li>
            <li class="list-group-item" style="height: 100%">
              <strong>{{ 'address' | translate }}</strong
              ><span class="float-end">{{
              data.sender.customerTypeId == customerType.Person ?(
                data.receiverAddressSublocality +
                ', ' +
                data.receiverAddressStreet +
                ' No:' +
                data.receiverAddressHouseNumber +
                data.receiverAddressLocality +
                '/' +
                data.receiverAddressCity +
                ' ' +
                data.receiverAddressCountry) : data.receiverAddressCity + ' ' + data.receiverAddressCountry
              }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'description' | translate }}</strong>
              <span class="float-end">{{data.receiverComments}}</span>
            </li>
          </ul>
          <div class="row mt-2">
            <div class="col">
              {{ 'customerSignature' | translate }}
              <div
                class="p-4"
                style="border: 1px solid rgb(221, 221, 221)"
              ></div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <ul class="list-group">
            <li class="list-group-item" style="background-color: #ecbc53">
              <strong>{{ 'senderInformation' | translate }}</strong>
            </li>
            <li class="list-group-item">
              <strong>{{ 'receiptNumber' | translate }}</strong><span class="float-end">{{receiptNumber}}</span>
            </li>
            <li class="list-group-item bg-light">
              <strong>{{ 'RID' | translate }}</strong>
              <span class="float-end"><strong>{{ data.code }}</strong></span>
            </li>
            <li class="list-group-item bg-light">
              <strong>{{ 'transactionDate' | translate }}</strong>
              <span class="float-end"><strong>{{ convertDateToLocaleString(data.payDate) }}</strong></span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'fullname' | translate }}</strong
              ><span class="float-end">{{
                getFullName(data.sender.firstName, data.sender.middleName,data.sender.lastName)
              }}</span>
            </li>
            <li class="list-group-item">
              <strong>{{ 'mobile' | translate }}</strong
              ><span class="float-end">{{ maskNumber(data.sender.mobilePhone) }}</span>
            </li>
          </ul>
          <div style="background-color: #f8f9fa;color: #202020;font-size: 15px;font-weight: 600;border: 1px solid #dee2e6;" class="p-2 mt-3">
            <div>
              {{ 'total' | translate
              }}<span class="float-end">{{ round(data.payingAmount) +' '+data.receiverAccountCurrency }}</span>
            </div>
          </div>
          <div style="color: #202020;font-size: 15px;font-weight: 600;border: 1px solid #dee2e6;" class="p-2 mt-3"
               *ngIf="data.receiverAccountCurrency !== 'TRY'">
            <div>
              <strong style="font-size: 15px;letter-spacing: 1px;font-weight: 600;">
                {{ 'payingExchangeRate' | translate}}
              </strong>
              <strong class="float-end">1 {{data.receiverAccountCurrency}} = {{data.payingExchangeRate | number: '1.2-2' }} TRY
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="py-3">
        <small style="font-size: 0.75em;line-height: 11px;">
          <p class="mb-1">{{ 'quotedText1' | translate }}</p>
          <!-- <p>{{ 'quotedText2' | translate }}</p> -->
        </small>
      </div>
      <div class="py-1 float-end">
        <p class="mb-1">{{ data.receivingBranch }}</p>
      </div>
    </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="receipt"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
</div>
