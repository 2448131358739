import { NgModule } from "@angular/core";
import {ReferalDashboardComponent } from "./referalDashboard.component";
import { CommonModule } from "@angular/common";
import { ReferalRoutesModule } from "./referal.routes.module";
import { MatProgressSpinnerModule, MatSpinner } from "@angular/material/progress-spinner";
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatInputModule } from "@angular/material/input";
import { MatNativeDateModule, MatOptionModule } from "@angular/material/core";
import { FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { ReferalService } from "./data/referl.service";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTableModule } from "@angular/material/table";
import { ormRecuirterCustomersComponent } from "./ormRecruiterCustomers.component";
import { OrmRecruiterRemittancesComponent } from "./ormRecruiterRemittance.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { OrmRecruiterBalanceCommissionComponent } from "./ormRecruiterBalanceCommission.component";
import { OrmRecruiterCommissionComponent } from "./ormRecruiterCommission.component";


@NgModule({
  declarations: [ReferalDashboardComponent, OrmRecruiterCommissionComponent, OrmRecruiterBalanceCommissionComponent, ormRecuirterCustomersComponent, OrmRecruiterRemittancesComponent],
  imports: [
     CommonModule,
     ReferalRoutesModule,
     MatIconModule,
     MatProgressSpinnerModule,
     MatCardModule,
     MatDatepickerModule,
     MatInputModule,
     MatNativeDateModule,
     FormsModule,
     MatSelectModule,  
     MatOptionModule,
     MatButtonModule,
     TranslateModule,
     MatPaginatorModule,
     MatTableModule,
     MatTooltipModule
  ],
  exports: [],
  providers: [ReferalDashboardComponent, ReferalService],
})
export class ReferalModule {}
