<h1 mat-dialog-title>{{ 'branchStatementReport' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'branchStatement' | translate }}</h1>
      </div>
    </div>
    <hr />
    <div class="row" style="margin: 10px">
      <table class="table table-borderless">
        <tr>
          <td>{{ 'branchCode' | translate }}:</td>
          <td>{{ data.data.branchCode }}</td>
          <td>{{ 'branchName' | translate }}:</td>
          <td>{{ data.data.branchTitle }}</td>
        </tr>
        <tr>
          <td>{{ 'address' | translate }}:</td>
          <td>{{ data.data.branchAddress }}</td>
          <td>{{ 'requestedDate' | translate }}:</td>
          <td>
            {{ convertDateToLocaleString(data.startDate, false) }}
            {{ 'to' | translate }}
            {{ convertDateToLocaleString(data.endDate, false) }}
          </td>
        </tr>
        <tr>
          <td>{{ 'mobile' | translate }}:</td>
          <td>{{ data.data.branchMobilePhone }}</td>
          <td class="onlyPrintable">{{ 'printedDate' | translate }}:</td>
          <td class="onlyPrintable">
            {{ convertDateToLocaleString(data.startDate, true) }}
          </td>
        </tr>
      </table>
    </div>
    <div class="row" style="margin: 10px">
      <table
        id="accountsData"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody>
          <tr>
            <th>{{ 'description' | translate }}</th>
            <th>{{ 'debit' | translate }}</th>
            <th>{{ 'credit' | translate }}</th>
            <th>{{ 'runningTotal' | translate }}</th>
          </tr>
          <tr *ngFor="let statement of data.data.statements">
            <td>{{ statement.description }}</td>
            <td>{{ statement.debit === 0 ? '' : statement.debit | number:'1.2-2' }}</td>
            <td>{{ statement.credit === 0 ? '' : statement.credit | number:'1.2-2' }}</td>
            <td>
              {{ statement.runningTotal === 0 ? '' : statement.runningTotal | number:'1.2-2' }}
            </td>
          </tr>
          <tr>
            <td>
              <b>{{ 'total' | translate }}</b>
            </td>
            <td>
              <b>{{ debitSum | number:'1.2-2' }}</b>
            </td>
            <td>
              <b>{{ creditSum | number:'1.2-2' }}</b>
            </td>
            <td>
              <b>{{ runningTotalSum | number:'1.2-2' }}</b>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-borderless">
        <tr>
          <td>{{ 'manager' | translate }}:</td>
          <td>_________________________</td>
          <td>{{ 'signature' | translate }}:</td>
          <td>__________________________</td>
          <td>{{ 'date' | translate }}:</td>
          <td>____._____.____________</td>
        </tr>
        <tr>
          <td>{{ 'hqSignature' | translate }}:</td>
          <td>__________________________</td>
          <td>{{ 'date' | translate }}:</td>
          <td>____._____.____________</td>
          <td colspan="2"></td>
        </tr>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="report"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
