import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/data/auth.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import { RemittanceRelationshipsPopupComponent } from './remittanceRelationshipsPopup.component';

@Component({
  selector: 'app-remittance-relationships',
  templateUrl: './remittanceRelationships.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class RemittanceRelationshipsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  displayedColumns: string[] = ['number', 'name', 'actions'];
  data = new MatTableDataSource<LookupsDto>();
  permissions = Permissions.lookupsManagement.remittanceRelationships;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService,
    public lookupsService: LookupsService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.lookupsService.remittanceRelationships.subscribe((relationships) => {
      this.data.data = relationships;
      setTimeout(() => {
        this.data.paginator = this.paginator;
      }, 100);
    });
  }

  onSubmit() {
    this.lookupsService.getRemittanceRelationships();
  }

  addRemittanceRelationshipDialog(relationshipId?: number) {
    if (relationshipId) {
      this.lookupsService
        .getRemittanceRelationship(relationshipId)
        .subscribe((relationship) => {
          this.dialog.open(RemittanceRelationshipsPopupComponent, {
            data: { ...relationship },
            width: '400px',
          });
        });
    } else {
      this.dialog.open(RemittanceRelationshipsPopupComponent, {
        data: [],
        width: '400px',
      });
    }
  }
}
