import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BranchesService } from '../branch/data/branches.service';
import { BranchConfiguration } from '../branch/data/dto/branches.dto';
import { Utilities } from '../_common/utils';
import { RemittancesDetailsDto } from './data/dto/remittances.dto';
import {SnackBarService} from "../_common/snackBar.service";
import { maskNumber } from "../_common/utils";
@Component({
  selector: 'app-remittance-payback-receipt',
  templateUrl: './remittancePaybackReceiptPopup.component.html',
  styleUrls: ['./receipt.css'],
})
export class RemittancePaybackReceiptPopupComponent implements OnInit {
  comments: string = '';
  branchConfigurations: BranchConfiguration[] = [];
  branchLanguage!: string;
  today = new Date();
  receiptNumber: string = '';
  maskNumber = maskNumber;
  constructor(
    public dialogRef: MatDialogRef<RemittancePaybackReceiptPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemittancesDetailsDto,
    public branchService: BranchesService,
    private translateService: TranslateService,
    private snackBar: SnackBarService
  ) {}

  ngOnInit() {
    this.branchService
      .branchById(this.data.sendingBranch.id)
      .subscribe((data) => {
        this.branchConfigurations = data.find(
          (branch) => branch.id === this.data.sendingBranch.id,
        )?.branchConfigurations as BranchConfiguration[];
        this.branchLanguage = this.branchConfigurations.find(
          (bc) => bc.configurationType === 'language',
        )?.configuration.branchLanguage;
        this.translateService.use(this.branchLanguage);
      }, ({ message }) => {
        this.snackBar.open(message);
      });

    this.receiptNumber = this.data.remittanceReceipt.find((rmr: any) => rmr.receiptTypeId == 2)?.receiptNumber;
  }

  sumOfAmountAndCommissoin(amount: any, commission: any) {
    return amount + commission;
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  checkNullValue(value: string | undefined) {
    if (value === null) value = '';
    return value;
  }

  round(amount: number){
    return amount.toFixed(2);
  }

  getFullName(firstName: string, middleName: string, lastName: string) {
    return middleName ? `${firstName} ${middleName} ${lastName}` : `${firstName} ${lastName}`;
  }
}
