import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  Validators,
  FormGroup,
  FormBuilder,
  FormArray,
  UntypedFormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Permissions } from '../_common/enums/permissions.enums';
import { BranchesService } from './data/branches.service';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, forkJoin } from 'rxjs';
import {
  BranchCurrencyDto,
  CreateManualJournalDto,
  CreateManualJournalDetailDto,
  GetBranchesDto,
} from './data/dto/branches.dto';
import { ManualJournalReceiptPopupComponent } from './manualJournalReceiptPopup.component';
import { TranslateService } from '@ngx-translate/core';
import { setDecimals } from '../_common/utils';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-branch-manual-journal-details',
  templateUrl: './manualJournalDetails.component.html',
  styleUrls: [
    '../_common/styles/table.less',
    './manualJournalDetails.component.less',
  ],
})
export class ManualJournalDetailsComponent implements OnInit {
  form!: FormGroup;
  branches: GetBranchesDto[] = [];
  permissions = Permissions.manualJournals.journals;
  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  branchValue = '';
  totalDebit = 0;
  totalCredit = 0;
  branchCurrency: BranchCurrencyDto[] = [];
  branchExRate = 0;
  branchCurrencyCode = 'USD';
  date = new UntypedFormControl(new Date(), [Validators.required]);
  //@ts-ignore
  @ViewChild('manualJournalsResetBtn') manualJournalsResetBtn: ElementRef;
  displayedColumns: string[] = [
    'account',
    'debitAccountCurrencyAmount',
    'debitBranchCurrencyAmount',
    'creditAccountCurrencyAmount',
    'creditBranchCurrencyAmount',
    'branchExRate',
    'usdExRate',
    'usdAmount',
    'actions',
  ];
  data: FormArray = this.fb.array([]);
  branchAccounts: LookupDto[] = [];
  accountsExRate: { [id: string]: number } = {};
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private branchesService: BranchesService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
    public dialog: MatDialog,
    public translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ branches }) => {
      this.branches = branches;

      this.form = this.fb.group({
        branchId: [Validators.required],
        journalDate: this.date,
        data: this.data,
        comments: ['', [Validators.pattern(regExp.alphaNumericSpecialCharacters), Validators.required]],
      });
      this.addRow();
      this.addRow();
    });
  }

  getBranchAccounts(branchId: any) {
    this.dataSource.next(this.data.controls);
    forkJoin([
      this.branchesService.getBranchAccountsById(branchId),
      this.branchesService.getBranchExchangeRates(
        branchId,
        'Exchange Rate for Manual Journals',
      ),
      this.branchesService.branchById(branchId),
    ]).subscribe(
      ([accounts, currency, branches]) => {
        if (!branches || !branches.length) {
          this.snackBar.open('selectedBranchNotFound');
          return;
        }
        if (!accounts || !accounts.length) {
          this.snackBar.open('selectedBranchAccountNotFound');
          return;
        }
        if (!currency || !currency.length) {
          this.snackBar.open('selectedBranCurrencyRateNotFound');
          return;
        }

        this.branchAccounts = accounts;
        this.branchCurrency = currency;
        if (!this.branchCurrency) {
          this.branchExRate = 0;
          this.branchCurrencyCode = 'USD';
          this.snackBar.open("exchangeRateForThisBranchNotExist");
          return;
        }

        const bData = this.branchCurrency.filter(
          (obj) => obj.currencyId == branches[0].baseCurrencyId,
        );
        if (bData && bData.length > 0) {
          this.branchExRate = bData[0].normRate;
          // @ts-ignore
          this.branchCurrencyCode = bData[0].currency
            .split('(')
            .pop()
            ?.replace('(', '')
            .replace(')', '');
        } else {
          this.branchExRate = 0;
          this.branchCurrencyCode = 'USD';
          this.snackBar.open("exchangeRateForThisBranchNotExist");
        }
      },
      (error) => {
        console.error(error);
        this.branchExRate = 0;
        this.branchCurrencyCode = 'USD';
        this.snackBar.open("exchangeRateForThisBranchNotExist");
      },
    );
  }

  accountSelectionChange(index: number, accountId: any) {
    const row = this.data.at(index);
    const accountIds = this.data.value.map((r: any) => r.account);
    const duplicates = accountIds.filter(
      (val: number, i: number, iteratee: number[]) =>
        iteratee.includes(val, i + 1),
    );
    if (duplicates.length) {
      // @ts-ignore
      row.controls.account.setValue(null, {
        emitEvent: false,
      });
      this.snackBar.open('youCannotSelectAnAccountMoreThanOnce');
      return;
    }

    row.patchValue({
      debitAccountCurrencyAmount: '',
      creditAccountCurrencyAmount: '',
      debitBranchCurrencyAmount: '',
      creditBranchCurrencyAmount: '',
      branchExRate: '',
      usdExRate: '',
      usdAmount: '',
    });
    const accCurrencyId = this.branchAccounts.filter(
      (obj) => obj.id == accountId,
    )[0].currencyId;
    const accExRate = this.branchCurrency.filter(
      (obj) => obj.currencyId == accCurrencyId,
    );
    if (accExRate && accExRate.length > 0) {
      this.accountsExRate[index] = accExRate[0].normRate;
    } else {
      this.snackBar.open("exchangeRateForThisAccountNotExist");
    }
    this.calculateTotals();
  }

  addRow() {
    const row = this.fb.group({
      account: ['', [Validators.required]],
      debitAccountCurrencyAmount: ['', []],
      debitBranchCurrencyAmount: ['', []],
      creditAccountCurrencyAmount: ['', []],
      creditBranchCurrencyAmount: ['', []],
      branchExRate: ['', []],
      usdExRate: ['', []],
      usdAmount: ['', []],
    });
    this.data.push(row);
    this.dataSource.next(this.data.controls);
  }

  removeRow(index: any) {
    this.data.removeAt(index);
    this.calculateTotals();
    this.dataSource.next(this.data.controls);
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.getRawValue() || {};
      const mjDetails: CreateManualJournalDetailDto[] = [];
      formValues.data.forEach((row: any) => {
        const mjd: CreateManualJournalDetailDto = {
          accountId: row.account,
          creditAccountCurrencyAmount: row.creditAccountCurrencyAmount,
          creditBranchCurrencyAmount: row.creditBranchCurrencyAmount,
          debitAccountCurrencyAmount: row.debitAccountCurrencyAmount,
          debitBranchCurrencyAmount: row.debitBranchCurrencyAmount,
          branchExRate: row.branchExRate,
          usdExRate: row.usdExRate,
          usdAmount: row.usdAmount,
        };
        mjDetails.push(mjd);
      });
      const mjDto: CreateManualJournalDto = {
        branchId: formValues.branchId,
        comments: formValues.comments,
        journalDate: formValues.journalDate,
        totalCreditBranchCurrencyAmount: this.totalCredit,
        totalDebitBranchCurrencyAmount: this.totalDebit,
        manualJournalDetails: mjDetails,
      };

      this.branchesService.createManualJournal(mjDto).subscribe(
        (resp) => {
          const message = resp?.message;
          this.snackBar.open(message);
          this.form.reset();
          this.receipt(resp.id);
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  debitChange(index: number, event: any) {
    const accountExRate = this.accountsExRate[index];
    const value = event.target.value;
    if (value > 0) {
      const row = this.data.at(index);
      const usdAmount = value / accountExRate;
      row.patchValue({
        creditAccountCurrencyAmount: 0,
        creditBranchCurrencyAmount: 0,
        debitBranchCurrencyAmount: setDecimals(usdAmount * this.branchExRate),
        branchExRate: this.branchExRate,
        usdExRate: accountExRate,
        usdAmount: setDecimals(usdAmount),
      });
      this.calculateTotals();
    }
  }

  creditChange(index: number, event: any) {
    const accountExRate = this.accountsExRate[index];
    const value = event.target.value;
    const row = this.data.at(index);
    if (value > 0) {
      const usdAmount = value / accountExRate;
      row.patchValue({
        debitAccountCurrencyAmount: 0,
        debitBranchCurrencyAmount: 0,
        creditBranchCurrencyAmount: setDecimals(usdAmount * this.branchExRate),
        branchExRate: this.branchExRate,
        usdExRate: accountExRate,
        usdAmount: setDecimals(usdAmount),
      });
      this.calculateTotals();
    }
  }
  calculateTotals() {
    this.totalCredit = 0;
    this.totalDebit = 0;
    this.data.controls.forEach((row: any) => {
      this.totalCredit += +row.value.creditBranchCurrencyAmount;
      this.totalDebit += +row.value.debitBranchCurrencyAmount;
    });
  }
  receipt(id: any) {
    this.branchesService.getManualJournalDetails(id).subscribe(
      (data) => {
        const dialogRef = this.dialog.open(ManualJournalReceiptPopupComponent, {
          data: data,
          width: '850px',
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close')
            this.translateService.use(localStorage.getItem('lang') || 'en');
        });
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }
}
