import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackBarService } from '../_common/snackBar.service';
import {regExp} from "../_common/data/validationRules";
import { Utilities } from '../_common/utils';
import { UsersService } from './data/users.service';

@Component({
  selector: 'app-user-request-approval',
  templateUrl: './userRequestApprovalPopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})

export class UserRequestApprovalPopupComponent implements OnInit {
  comments: string = '';
  type: string = '';

  form!: UntypedFormGroup;
  permissions!: any[];

  constructor(
    public dialogRef: MatDialogRef<UserRequestApprovalPopupComponent>,
    private fb: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) {}

  closeDialog() {
    this.dialogRef.close({
      success: false
    });
  }

  ngOnInit() {
    this.form = this.fb.group({
      statusType: ['approveUserRequest', [Validators.required]],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.userService
        .updateUserRequestStatus({
          id: this.data.id,
          statusType: this.form.value.statusType,
          comments: this.form.value.comments,
          userData: this.data
        })
        .subscribe(
          (resp) => {
            this.snackBar.open(resp?.message);
            this.dialogRef.close({
              success: true,
            });
          },
          ({ message }) => {
            this.snackBar.open(message);
            this.dialogRef.close({
              success: false,
            });
          },
        );
    }
  }
  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
