import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from '../../_common/snackBar.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';

@Component({
  selector: 'app-remittance-relationships',
  templateUrl: './remittanceRelationshipsPopup.component.html',
})
export class RemittanceRelationshipsPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  remittanceRelationship: LookupsDto;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupsService: LookupsService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<RemittanceRelationshipsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: LookupsDto[],
  ) {
    this.remittanceRelationship = data[0];
    if (this.remittanceRelationship) {
      this.addMode = false;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      relationshipName: [
        this.remittanceRelationship?.name,
        [Validators.required],
      ],
      isActive: [this.remittanceRelationship?.isActive || this.addMode],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const remittancePurpose: LookupsDto = {
        name: formValues.relationshipName,
        isActive: formValues.isActive || false,
      };

      const handler = this.remittanceRelationship
        ? this.lookupsService.updateRemittanceRelationship({
            id: this.remittanceRelationship.id,
            ...remittancePurpose,
          })
        : this.lookupsService.createRemittanceRelationship(remittancePurpose);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
