import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { BranchesService } from './data/branches.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  GetBranchesDto,
  GetBranchCommissionsDto,
} from './data/dto/branches.dto';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { SystemBranchCodes } from '../_common/enums/systemBranches.enums';
import { SnackBarService } from '../_common/snackBar.service';

@Component({
  selector: 'app-branch-commissions',
  templateUrl: './branchCommissions.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class BrancheCommissionsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  processForm!: UntypedFormGroup;

  permissions = Permissions.branchManagement.branchCommissions;
  resultsLength = 0;
  loading = false;
  branches: GetBranchesDto[] = [];
  displayedColumns: string[] = [
    'select',
    'branchCode',
    'description',
    'commission',
  ];
  data = new MatTableDataSource<GetBranchCommissionsDto>();
  selection = new SelectionModel<GetBranchCommissionsDto>(true, []);

  constructor(
    private fb: UntypedFormBuilder,
    private router: ActivatedRoute,
    private branchesService: BranchesService,
    public authService: AuthService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      branch: [null, [Validators.required]],
    });

    this.processForm = this.fb.group({
      selectedCommission: [null],
    });

    this.router.data.subscribe(({ branches }) => {
      this.branches = [...branches].filter(
        (f) => f.code !== SystemBranchCodes.TKCPO,
      );
    });

    setTimeout(() => {
      this.data.paginator = this.paginator;
    }, 1000);
  }

  onSubmit() {
    if (this.form.valid) {
      this.selection.clear();
      const branchId = this.form.value.branch.id;
      this.branchesService.getBranchCommissions(branchId).subscribe((data) => {
        this.loading = false;
        this.data.data = data;
        this.data.paginator = this.paginator;
        this.resultsLength = data.length;
      } , ({ message }) => {
        this.snackBar.open(message);
      });
    }
  }

  processCommissions() {
    if (this.selection.selected.length) {
      this.branchesService
        .processPendingBranchCommissions(this.selection.selected)
        .subscribe(
          (resp) => {
            // @ts-ignore
            this.snackBar.open(resp?.message);
            this.selection.clear();
            location.reload();
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.data.data.forEach((row) => this.selection.select(row));
  }
}
