import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CRUDResponseDto, LookupDto } from 'src/app/_common/data/dto/api.dto';
import { HttpService } from '../../_common/data/http.service';
import {
  GetNotificationsDto,
  RetryNotificationDto,
} from './dto/notifications.dto';

export type NotificationFilter = {
  remittanceCode?: string;
  statusId?: string;
} | null;

type NotificationListFilter = {
  notificationId: string;
} | null;

type GetNotificationsResponse = {
  appliedFilters: NotificationFilter;
  data: GetNotificationsDto[];
};

@Injectable()
export class NotificationsService {
  private _notifications = new BehaviorSubject<GetNotificationsResponse>({
    appliedFilters: null,
    data: [],
  });
  getNotifications: Observable<GetNotificationsResponse>;
  constructor(private httpService: HttpService) {
    this.getNotifications = this._notifications.asObservable();
  }

  searchNotifications(filter: NotificationFilter | null) {
    this.httpService
      .get<GetNotificationsDto[]>('notification/list', filter)
      .subscribe((notifs) => {
        this._notifications.next({
          appliedFilters: filter,
          data: notifs,
        });
      });
  }

  notifications(
    filter: NotificationListFilter = null,
  ): Observable<GetNotificationsDto[]> {
    return this.httpService.get<GetNotificationsDto[]>(
      'notification/list',
      filter,
    );
  }

  retry(dto: RetryNotificationDto): Observable<CRUDResponseDto[]> {
    console.log(dto.id);
    return this.httpService.post<CRUDResponseDto[]>('notification/retry', dto);
  }

  getStatuses(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('notification/status/list');
  }
}
