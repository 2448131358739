import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RemittanceComplianceDto } from './data/dto/remittances.dto';

export interface RemittanceCompliancesPopupData {
  remittanceCompliances: RemittanceComplianceDto[];
}

@Component({
  selector: 'app-remittance-compliances',
  templateUrl: './remittanceCompliancePopup.component.html',
})
export class RemittanceCompliancePopupComponent {
  comments: string = '';

  constructor(
    public dialogRef: MatDialogRef<RemittanceCompliancePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemittanceCompliancesPopupData,
  ) {}
}
