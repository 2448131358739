import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
@Injectable({ providedIn: 'root' })
export class SnackBarService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  public open(msgKey: string, action: string = 'Ok') {
    const translatedMessage = this.translate.instant(msgKey);
    return this.snackBar.open(translatedMessage, action, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 60000,
    });
  }
}
