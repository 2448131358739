<div>
  <h2>Matched Sanction Entity</h2>
  <table mat-table [dataSource]="dataSource" class="table bordered-table">
    <!-- sanction Screen ImportId Column -->
    <ng-container matColumnDef="sanctionScreenImportId">
      <th mat-header-cell *matHeaderCellDef>id</th>
      <td mat-cell *matCellDef="let row">{{ row.sanctionScreenImportId }}</td>
    </ng-container>

    <!-- FullName Column -->
    <ng-container matColumnDef="FullName">
      <th mat-header-cell *matHeaderCellDef>{{ 'fullname' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.matchedEntity?.req?.name }}</td>
    </ng-container>

        <!-- Titel Column -->
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef>{{ 'title' | translate }}</th>
          <td mat-cell *matCellDef="let row">{{  row.matchedEntity?.res?.matchedResults[0]?.title }}</td>
        </ng-container>

    <!-- AkaFullName Column -->
    <ng-container matColumnDef="AkaFullName">
      <th mat-header-cell *matHeaderCellDef>{{ 'Aka Full Name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.matchedEntity?.res?.matchedResults[0]?.name }}</td>
    </ng-container>

    <!-- Source Column -->
    <ng-container matColumnDef="Source">
      <th mat-header-cell *matHeaderCellDef>{{ 'Source' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.matchedEntity?.res?.matchedResults[0]?.source }}</td>
    </ng-container>

    <!-- Score Column -->
    <ng-container matColumnDef="Score">
      <th mat-header-cell *matHeaderCellDef>{{ 'Score' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.matchedEntity?.res?.matchedResults[0]?.score }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
