<app-form-container label="{{ 'paybackRemittance' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>{{ 'remittanceStatus' | translate }}:</mat-label>
        <input
          [readonly]="true"
          matInput
          #remittanceStatus
          formControlName="remittanceStatus"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-md-3">
        <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
        <input
          [readonly]="true"
          matInput
          #remittanceCode
          formControlName="remittanceCode"
        />
      </mat-form-field>
    </div>
    <mat-card>
      <mat-card-title>{{ 'senderInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'firstName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderFirstName
                  formControlName="senderFirstName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'middleName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderMiddleName
                  formControlName="senderMiddleName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'lastName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderLastName
                  formControlName="senderLastName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'mobile' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #senderMobilePhone
                  formControlName="senderMobilePhone"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-3">
      <mat-card-title>{{ 'receiverInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'firstName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverFirstName
                  formControlName="receiverFirstName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'middleName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverMiddleName
                  formControlName="receiverMiddleName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'lastName' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverLastName
                  formControlName="receiverLastName"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col">
                <mat-label>{{ 'mobile' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #receiverMobilePhone
                  formControlName="receiverMobilePhone"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-3">
      <mat-card-title>{{ 'paymentInformation' | translate }}</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <div class="row">
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>{{ 'remittanceType' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #remittanceType
                  formControlName="remittanceType"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label>{{ 'remittanceSubtype' | translate }}:</mat-label>
                <input
                  [readonly]="true"
                  matInput
                  #remittanceSubType
                  formControlName="remittanceSubType"
                />
              </mat-form-field>
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>{{ 'paymentType' | translate }}</mat-label>
                <mat-select formControlName="paymentType">
                  <mat-option
                    *ngFor="let paymentType of paymentTypes"
                    [value]="paymentType"
                  >
                    {{ paymentType.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field appearance="fill" class="col-4">
                <mat-label>{{ 'tellerAccount' | translate }}</mat-label>
                <mat-select formControlName="receiverAccountId">
                  <mat-option
                    *ngFor="let tellerAccount of tellerAccounts"
                    [value]="tellerAccount.id"
                  >
                    {{ tellerAccount.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label
                  >{{ 'amount' | translate }} [{{
                    this.form.controls.currency.value
                  }}]:</mat-label
                >
                <input
                  [readonly]="true"
                  matInput
                  #amount
                  formControlName="amount"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label
                  >{{ 'commission' | translate }} [{{
                    this.form.controls.currency.value
                  }}]:</mat-label
                >
                <input
                  [readonly]="true"
                  matInput
                  #commission
                  formControlName="commission"
                />
              </mat-form-field>
              <mat-form-field appearance="outline" class="col-4">
                <mat-label
                  >{{ 'amountToReturn' | translate }} [{{
                    this.form.controls.currency.value
                  }}]:</mat-label
                >
                <input
                  [readonly]="true"
                  matInput
                  #amountToReturn
                  formControlName="amountToReturn"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-end my-3">
      <button
        mat-raised-button
        class="me-2"
        color="secondary"
        type="reset"
        routerLink="/remittances"
      >
        {{ 'back' | translate }}
      </button>
      <button mat-raised-button type="submit" color="primary">
        {{ 'paybackRemittance' | translate }}
      </button>
      <!--      [disabled]="!form.valid"-->
    </div>
  </form>
</app-form-container>
