import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {OutletComponent} from "../_common/components/outlet.component";
import {IncomingRemittanceComponent} from "./incoming-remittance.component";
import {AuthGuard} from "../auth/data/auth.guard";
import {Permissions} from "../_common/enums/permissions.enums";
import {OutgoingRemittanceComponent} from "./outgoingremittance.component";
import {BsmvTaxComponent} from "./bsmvtax.component";
import {ClientProtectionAccountComponent} from "./client-protection-account.component";
import { CurrenciesResolver } from '../branch/data/branches.resolver';
import { ParquetReportComponent } from './parquet-report.component';
import {ClientProtectionCustomerReportComponent} from "./clientProtection-customer-report.component";

const {
  CBK_REPORT,
} = Permissions;
const routes : Routes = [
  {
    path: 'cbkreports',
    component : OutletComponent,
    children : [
      {
        path : 'view',
        component: OutletComponent,
        children : [
          {
            path : 'incomingremittance',
            canActivate : [AuthGuard],
            data: { permissions: [CBK_REPORT.incomingRemittance.VIEW_INCOMING_REMITTANCE_REPORT] },
            resolve: { currencies: CurrenciesResolver },
            component : IncomingRemittanceComponent
          },
          {
            path : 'outgoingremittance',
            canActivate : [AuthGuard],
            data: { permissions: [CBK_REPORT.outgoingRemittance.VIEW_OUTGOING_REMITTANCE_REPORT] },
            resolve: { currencies: CurrenciesResolver },
            component : OutgoingRemittanceComponent
          },
          {
            path : 'bsmvtx',
            canActivate : [AuthGuard],
            data: { permissions: [CBK_REPORT.bsmvTax.VIEW_BSMV_TAX_REMITTANCE_REPORT] },
            resolve: { currencies: CurrenciesResolver },
            component : BsmvTaxComponent
          },
          {
            path : 'clientprotectionaccount',
            canActivate : [AuthGuard],
            data: { permissions: [CBK_REPORT.clientProtection.VIEW_CLIENT_PROTECTION_REMITTANCE_REPORT] },
            resolve: { currencies: CurrenciesResolver },
            component : ClientProtectionAccountComponent
          },
          {
            path : 'clientprotectioncustomer',
            canActivate : [AuthGuard],
            data: { permissions: [CBK_REPORT.clientProtection.VIEW_CLIENT_PROTECTION_REMITTANCE_REPORT] },
            resolve: { currencies: CurrenciesResolver },
            component : ClientProtectionCustomerReportComponent
          },
          {
            path : 'generateparquetreports',
            canActivate : [AuthGuard],
            data: { permissions: [CBK_REPORT.btransReport.VIEW_BTRANS_PARQUET_REPORT] },
            component : ParquetReportComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CbkReportsRoutesModule {}
