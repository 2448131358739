import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../../_common/enums/permissions.enums';
import { RolesService } from '../data/roles.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RolesResponseDto } from '../data/dto/role/roles.dto';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { AuthService } from '../../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import {UserTypes} from "../../_common/enums/userTypes.enums";

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class RolesComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.userManagement.roles;
  allOption = {
    id: 'all',
    name: 'All',
  };
  userTypes: LookupDto[] = [];
  resultsLength = 0;

  displayedColumns: string[] = [
    'number',
    'name',
    'type',
    'description',
    'status',
    'actions',
  ];
  data = new MatTableDataSource<RolesResponseDto>();

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ userTypes: userTypesResolved }) => {
      this.userTypes = [this.allOption, ...userTypesResolved];

      this.form = this.fb.group({
        userTypeId: [this.userTypes[0]],
      });

      this.rolesService.roles.subscribe(({ roles, appliedFilters }) => {
        const currentUserPermissions =
          this.authService.currentUserValue?.permissions;

        if (this.authService.currentUserValue?.userTypeName == UserTypes.HQ) {
          this.data.data = roles;
        } else {
          this.data.data = roles.filter((r) =>
            r.permissionNames?.every((p) => currentUserPermissions?.includes(p)),
          );
        }

        this.data.paginator = this.paginator;
        this.resultsLength = roles.length;

        if (appliedFilters?.userTypeId) {
          this.form.controls['userTypeId'].setValue(
            this.userTypes.find((f) => f.id === appliedFilters.userTypeId),
          );
        }
      });
    });
  }

  async onSubmit() {
    const userTypeId = await this.form.value.userTypeId.id;
    const filters = userTypeId === this.allOption.id ? null : { userTypeId };
    this.rolesService.triggerGetRoles(filters);
  }
}
