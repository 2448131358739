import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { FinsurvReconciliationReportFilter, ReportsService } from './data/reports.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { FinsurvReconciliationDetailComponent } from './finsurvreconciliationdetail.component';

@Component({
  selector: 'app-finsurvreconciliationreport',
  templateUrl: './finsurvreconciliationreport.component.html',
  styleUrls: ['./finsurvreconciliationreport.component.less']
})
export class FinsurvReconciliationReportComponent implements OnInit {
  form!: UntypedFormGroup;
  resultsLength = 0;
   displayedColumns: string[] = [
    '#', 'date', 'batchNo', 'totalTransactions', 'waitingTobeSubmitted', 'submittedToSARBAndWaitingFinalResponse','submittedToSARBAndFailed', 'fullyReportedToSARB'
  ];

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  remittances = new MatTableDataSource<any>();
  constructor(
    private fb: UntypedFormBuilder,
    private reportsService: ReportsService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private reportService: ReportsService,
  ) { }
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);

  ngOnInit(): void {
    this.form = this.fb.group({
      transactionCategory: ['1'],
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onSubmit() {
    const { transactionCategory, startDate, endDate } = this.form.value;
    const filter: FinsurvReconciliationReportFilter = {};

    if(transactionCategory) filter.transactionCategory = transactionCategory;
    if(startDate) filter.startDate = startDate;
    if(endDate) filter.endDate = endDate;

    this.reportsService.getFinsurvReconciliationReport(filter)
      .subscribe((data)=>{
        this.remittances.data = data;
        console.log(data);
        this.remittances.paginator = this.paginator;
      },({ message }) => {
        // this.snackBar.open(message)
        this.remittances.data = [];
       }
      );
  }

   viewReconciliationDetails( startDate: string ,tranState: number, batchNo: string) {

    let tranStates = '';

    if(tranState == 1)
       tranStates = '7,8,9,10,11,12,13,14,15';
    
    if(tranState == 2)
       tranStates = '7,9';

    if(tranState == 3)
       tranStates = '8';

    if(tranState == 4)
       tranStates = '11, 12';

    if(tranState == 5)
       tranStates = '10,13,14,15';
      
    let filter = {
      startDate: startDate,
      endDate: this.form.value?.endDate,
      tranState: tranStates,
      batchNo: batchNo,
    }
    this.reportService
        .getFinsurvReconciliationReportDetail(
          filter,
        )
        .subscribe((data) => {
          const dialogRef = this.dialog.open(FinsurvReconciliationDetailComponent, {
            data: {data: data },
            maxWidth: 'fit-content',
            maxHeight: 'fit-content',
          });

          dialogRef.afterClosed().subscribe((resp) => {
            if (resp === 'close') {
              return;
            }
          });
        }, ({ message }) => {
          this.snackBar.open(message);
        });
  }

  

}
