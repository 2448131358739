import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { RemittancePurposesComponent } from './remittancePurposes/remittancePurposes.component';
import { RemittanceRelationshipsComponent } from './remittanceRelationships/remittanceRelationships.component';
import { OccupationsComponent } from './occupations/occupations.component';
import { SourceOfFundsComponent } from './sourceOfFunds/sourceOfFunds.component';
import {
  CitiesResolver,
  CountriesResolver,
} from '../branch/data/branches.resolver';
import { CitiesComponent } from './cities/cities.component';
import { LocalitiesComponent } from './localities/localities.component';
import { CustomerDocumentTypesComponent } from './customerDocumentTypes/customerDocumentTypes.component';

const {
  lookupsManagement: {
    remittancePurposes,
    remittanceRelationships,
    occupations,
    sourceOfFunds,
    cities,
    localities,
    customerDocumentTypes,
  },
} = Permissions;

const routes: Routes = [
  {
    path: 'lookups',
    component: OutletComponent,
    children: [
      {
        path: 'view',
        component: OutletComponent,
        children: [
          {
            path: 'remittancePurposes',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(remittancePurposes) },

            component: RemittancePurposesComponent,
          },
          {
            path: 'remittanceRelationships',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(remittanceRelationships) },

            component: RemittanceRelationshipsComponent,
          },
          {
            path: 'occupations',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(occupations) },

            component: OccupationsComponent,
          },
          {
            path: 'sourceOfFunds',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(sourceOfFunds) },

            component: SourceOfFundsComponent,
          },
          {
            path: 'cities',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(cities) },
            resolve: { countries: CountriesResolver },
            component: CitiesComponent,
          },
          {
            path: 'localities',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(localities) },
            resolve: { cities: CitiesResolver, countries: CountriesResolver },
            component: LocalitiesComponent,
          },
          {
            path: 'customerdocumenttypes',
            canActivate: [AuthGuard],
            data: { permissions: Object.values(customerDocumentTypes) },
            resolve: { cities: CitiesResolver, countries: CountriesResolver },
            component: CustomerDocumentTypesComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LookupsRoutesModule {}
