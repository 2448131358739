import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Utilities } from "../_common/utils";
import { CustomerModificationChangeLogPopupComponent } from "./customerModificationChangeLogPopup.component";
import { CustomerService } from "./data/customer.service";
import { SnackBarService } from "../_common/snackBar.service";

@Component({
  selector: 'app-customermodifications',
  templateUrl: './customerModificationsPopup.component.html',
})
export class CustomerModificationsPopup {
  
    displayedColumns: string[] = [
    'number',
    'action',
    'comments',
    'createdBy',
    'dateCreated',
    'actions',
  ];
  customerId!: number;
  dataSource = new MatTableDataSource<any>();

  constructor(
    public dialogRef: MatDialogRef<CustomerModificationsPopup>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { customerModification: any[]; customerId: number },
    private customerService: CustomerService,
    private snackBar: SnackBarService,

  ) {
  }

  ngOnInit() {
    this.customerService.getCustomerModifications(this.data.customerId).subscribe(
      (resp) => {
        this.data.customerModification = resp;
        this.dataSource.data = this.data.customerModification;
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }

  viewChangeLog(data: any) {
    this.dialog.open(CustomerModificationChangeLogPopupComponent, {
      disableClose: true,
      width: '54rem',
      data: data.changeLog,
    });
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
  }

}