<app-index-container label="{{ 'remitCommConfigs' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_REMIT_COMM_CONFIG)"
    mat-raised-button
    color="primary"
    routerLink="/remittances/commissionconfigs/add"
  >
    <i class="fa-solid fa-circle-plus"></i>
    {{ 'addCommissionConfig' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-6">
        <mat-radio-group formControlName="isActive" class="col-3 my-3">
          <mat-radio-button value="active">{{
            'active' | translate
          }}</mat-radio-button
          >&nbsp;
          <mat-radio-button value="inactive">{{
            'inactive' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="remitCommConfigs" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      <!-- {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} -->
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="senderCountry">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'senderCountry' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.senderCountry || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Sender Name Column -->
    <ng-container matColumnDef="receiverCountry">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'receiverCountry' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.receiverCountry || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Sender Mobile Column -->
    <ng-container matColumnDef="remittanceType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'remittanceType' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.remittanceType || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Receiver Name Column -->
    <ng-container matColumnDef="remittanceSubType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'remittanceSubtype' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row.remittanceSubType || ('all' | translate) }}
      </td>
    </ng-container>
    <!-- Receiver Mobile Column -->
    <ng-container matColumnDef="minAmount">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('minAmount' | translate) + ' $' }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.minAmount | number:'1.2-2'  }}</td>
    </ng-container>
    <!-- Amount Mobile Column -->
    <ng-container matColumnDef="maxAmount">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('maxAmount' | translate) + ' $' }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.maxAmount  | number:'1.2-2'  }}</td>
    </ng-container>
    <!-- Commission Column -->
    <ng-container matColumnDef="activeStatus">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.isActive ? ('active' | translate) : ('inactive' | translate) }}
      </td>
    </ng-container>
    <!--Active Status Column -->
    <ng-container matColumnDef="commission">
      <th mat-header-cell *matHeaderCellDef>
        {{ ('commission' | translate) + ' %' }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.commission | number:'1.0-5'  }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized(
                permissions.UPDATE_REMIT_COMM_CONFIG
              ) ||
              authService.isUserAuthorized(permissions.VIEW_REMIT_COMM_CONFIG)
            "
            color="primary"
            routerLink="/remittances/commissionconfigs/view/{{ row.id }}"
          >
            <mat-icon>{{
              authService.isUserAuthorized(permissions.UPDATE_REMIT_COMM_CONFIG)
                ? 'edit'
                : 'visibility'
            }}</mat-icon>
            {{
              (authService.isUserAuthorized(
                permissions.UPDATE_REMIT_COMM_CONFIG
              )
                ? 'update'
                : 'view'
              ) | translate
            }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
