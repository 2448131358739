import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { SettlementService } from './data/settlement.service';
import { SettlementConfirmReceiptPopupComponent } from './settlementConfirmReceiptPopup.component';
import {
  GetBranchCurrencyDto,
  GetBranchesDto,
} from '../branch/data/dto/branches.dto';
import {
  ConfirmSettlementDto,
  GetSettlementDetailsDto,
} from './data/dto/settlement.dto';
import { regExp } from '../_common/data/validationRules';
import { setDecimals } from '../_common/utils';

@Component({
  selector: 'app-settlement-confirm',
  templateUrl: './settlementConfirmPopup.component.html',
})
export class SettlementConfirmPopupComponent implements OnInit {
  accounts: LookupDto[] = [];
  exchangeRates: GetBranchCurrencyDto[] = [];
  branch?: GetBranchesDto = undefined;
  settlement?: GetSettlementDetailsDto = undefined;
  beneficiaryBaseCurrency: string = '';
  beneficiaryBaseCurrencyRate?: number = undefined;
  beneficiaryBaseCurrencyAmount?: number = undefined;
  beneficiaryCurrency: string = '';
  beneficiaryExchangeRate?: number = undefined;
  beneficiaryAmount?: number = undefined;
  beneficiaryUsdAmount?: number = undefined;
  isPartnerSettlement?: boolean = false;

  form!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SettlementConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      accounts: LookupDto[];
      exchangeRates: GetBranchCurrencyDto[];
      branch: GetBranchesDto;
      settlement: GetSettlementDetailsDto;
    },
    private fb: FormBuilder,
    private dialog: MatDialog,
    private settlementService: SettlementService,
    public snackBar: SnackBarService,
  ) {
    this.exchangeRates = data.exchangeRates;
    this.accounts = data.accounts;
    this.branch = data.branch;
    this.settlement = data.settlement;

    this.isPartnerSettlement = this.settlement?.isPartnerSettlement;
    this.beneficiaryBaseCurrency =
      this.branch?.baseCurrency.split(' ').pop() || '';
    const baseExRate = this.exchangeRates.find(
      (e) => e.currencyId === this.branch?.baseCurrencyId,
    );
    if (!baseExRate) {
      this.snackBar.open('Branch base currency exchange rate not found.');
      return;
    }

    this.beneficiaryBaseCurrencyRate = baseExRate.normRate;
  }

  ngOnInit() {
    this.form = this.fb.group({
      usdAmount: [this.settlement?.settlementAmount],
      settlerAmount: [
        `${this.settlement?.localAmount} ${this.settlement?.settlerAccountCurrency}`,
      ],
      account: [null, [Validators.required]],
      beneficiaryAmount: [
        null,
        [Validators.required, Validators.pattern(regExp.decimalNumber)],
      ],
      beneficiaryExchangeRate: [null],
      beneficiaryBaseCurrencyAmount: [null],
      beneficiaryUsdAmount: [null],
      turkishExchangeRate: ['', this.isPartnerSettlement ? Validators.required : null],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });

    this.form.controls['beneficiaryAmount'].valueChanges.subscribe((value) => {
      this.beneficiaryUsdAmount = value / (this.beneficiaryExchangeRate || 0);
      this.beneficiaryBaseCurrencyAmount =
        this.beneficiaryUsdAmount * (this.beneficiaryBaseCurrencyRate || 0);
      this.form
        .get('beneficiaryBaseCurrencyAmount')
        ?.setValue(this.beneficiaryBaseCurrencyAmount);
      this.form
        .get('beneficiaryUsdAmount')
        ?.setValue(this.beneficiaryUsdAmount);
    });
  }

  onAccountChange(accountId: number) {
    this.resetAmounts();

    const selectedAccount = this.accounts.find((a) => a.id === accountId);
    const exchangeRate = this.exchangeRates.find(
      (e) => e.currencyId === selectedAccount?.currencyId,
    );
    if (!exchangeRate) {
      this.resetAmounts();
      this.snackBar.open('Settlement exchange rate not found for this account');
      return;
    }

    this.beneficiaryCurrency = exchangeRate.currency.split(' ').pop() || '';
    this.beneficiaryExchangeRate = exchangeRate.normRate;
    this.form
      .get('beneficiaryExchangeRate')
      ?.setValue(this.beneficiaryExchangeRate);
    this.beneficiaryAmount = setDecimals(
      (this.settlement?.settlementAmount || 0) * this.beneficiaryExchangeRate,
    );
    this.form.get('beneficiaryAmount')?.setValue(this.beneficiaryAmount);
    this.beneficiaryBaseCurrencyAmount =
      (this.settlement?.settlementAmount || 0) *
      (this.beneficiaryBaseCurrencyRate || 0);
    this.form
      .get('beneficiaryBaseCurrencyAmount')
      ?.setValue(this.beneficiaryBaseCurrencyAmount);
    this.beneficiaryUsdAmount = this.settlement?.settlementAmount;
    this.form.get('beneficiaryUsdAmount')?.setValue(this.beneficiaryUsdAmount);
    if (!this.isPartnerSettlement) {
      this.form.get('turkishExchangeRate')?.setValue(this.settlement?.turkishExchangeRate);
    }
  }

  onSubmit() {
    if (this.form.valid && this.settlement) {
      const dto: ConfirmSettlementDto = {
        settlementId: this.settlement.id,
        beneficiaryAccountId: this.form.value.account.id,
        beneficiaryGlAmount: this.beneficiaryAmount || 0,
        beneficiaryExchangeRate: this.beneficiaryExchangeRate || 0,
        beneficiaryBaseCurrencyRate: this.beneficiaryBaseCurrencyRate || 0,
        beneficiaryBaseCurrencyAmount: this.beneficiaryBaseCurrencyAmount || 0,
        confirmedAmount: this.beneficiaryUsdAmount || 0,
        turkishExchangeRate: this.isPartnerSettlement ? this.form.value.turkishExchangeRate : (this.settlement.turkishExchangeRate || 0),
        comments: this.form.value.comments,
        isPartnerSettlement: this.isPartnerSettlement,
        partnerSettlementId: this.settlement?.partnerSettlementId,
      };
      this.settlementService.confirmSettlement(dto).subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close();
          this.openConfirmReceiptDialog(this.settlement?.id);
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  resetAmounts() {
    this.beneficiaryCurrency = '';
    this.beneficiaryExchangeRate = 0;
    this.form.get('beneficiaryExchangeRate')?.setValue('');
    this.beneficiaryAmount = 0;
    this.form.get('beneficiaryAmount')?.setValue('');
    this.beneficiaryBaseCurrencyAmount = 0;
    this.form.get('beneficiaryBaseCurrencyAmount')?.setValue('');
    this.beneficiaryUsdAmount = 0;
    this.form.get('beneficiaryUsdAmount')?.setValue('');
  }

  openConfirmReceiptDialog(settlementId: number = 0) {
    this.settlementService
      .getSettlementDetails(settlementId)
      .subscribe((settlementDetails) => {
        this.dialog.open(SettlementConfirmReceiptPopupComponent, {
          disableClose: true,
          width: '900px',
          data: settlementDetails,
        });
      },({ message }) => {
        this.snackBar.open(message);
      });
  }
}
