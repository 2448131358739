import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
  private pendingRequests = 0;
  private loaderSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public show = this.loaderSubject.asObservable();

  public showLoader() {
    this.pendingRequests++;
    this.loaderSubject.next(true);
  }

  public hideLoader() {
    if (--this.pendingRequests === 0) {
      this.loaderSubject.next(false);
    }
  }
}
