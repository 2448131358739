<nav class="navbar bg-sp-header navbar-expand-lg navbar-dark py-0" style="height: 74px">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img height="40" src="../../../../assets/images/logo/logo.svg">
    </a>
    <button class="navbar-toggler d-md-none me-3 collapsed" type="button" data-bs-toggle="collapse"
    data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fas fa-ellipsis-v"></i>
  </button>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fas fa-bars"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0"></ul>
      <div class="d-flex bg-sp-header">
        <ul class="navbar-nav">
          <li class="nav-item me-3">
            <div class="input-group">
              <span class="input-group-text" id="basic-addon1">
                <img *ngIf="translate.currentLang == 'en'" height="26" src="https://res.cloudinary.com/paysii/image/upload/v1586237050/paysii-orm/flags-iso/GB.png">
                <img *ngIf="translate.currentLang == 'tr'" height="26" src="https://res.cloudinary.com/paysii/image/upload/v1586237050/paysii-orm/flags-iso/TR.png">
              </span>
              <select class="form-control me-2" #selectedLang (change)="switchLang(selectedLang.value)" style="width: 50px; text-transform: capitalize;">
                <option *ngFor="let language of translate.getLangs()" [value]="language"
                  [selected]="language === translate.currentLang">
                  {{ language }}
                </option>
              </select>
            </div>
          </li>
          <li class="nav-item me-3" *ngIf="loggedIn">
            <div class="notifications nav-item"
            style="color: white">
            <i *ngIf="poolingService.canApproveRequests" class="text-gray fa-solid fa-bell notification-icon fa-2x"
              [matMenuTriggerFor]="notificationMenu" (click)="onNotificationMenuOpen()">
              <span style="font-size: 9px;" *ngIf="notifications.length"
                class="position-absolute text-small top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{notifications.length}}</span>
            </i>
            <mat-menu #notificationMenu>
              <div class="list-group" style="width:279px !important;">
                <h3 class="fw-bold mb-0 list-group-item bg-info list-group-item-action active" aria-current="true">
                  {{ 'notifications' | translate }}
                  <div *ngIf="!poolingService.pooling" class="float-end">
                    <div class="btn btn-outline-primary btn-sm" (click)="onRefresh($event)">
                      <i class="fa-solid fa-arrows-rotate me-2"></i>{{ 'refresh' | translate }}
                    </div>
                  </div>
                </h3>
                <div *ngIf="!poolingService.pooling && notifications.length">
                  <div *ngFor="let notification of notifications" class="list-group-item list-group-item-action">
                    <div (click)="onNotificationItemClick(notification)">
                      <h5 class="mb-1">{{ 'branchCode' | translate }}: {{ notification.branchCode }}</h5>
                      <div class="my-1" style="font-weight:500">{{ notification.type | translate }}</div>
                      <div><small class="text-muted" style="text-transform:capitalize"><i
                            class="fa-solid fa-user fa-sm"></i> {{notification.requestedBy}}</small> <small
                          class="text-muted float-end"><i class="fa-solid fa-clock fa-sm"></i> {{ convertDateToLocaleString(notification.date) }}</small></div>
                    </div>
                    <div *ngIf="notification.type === 'remittanceApproval'" class="row px-2 mt-1">
                      <div *ngIf="notification.primaryApprovalRequired" class="col p-0 btn btn-outline-secondary btn-sm"
                        (click)="onRemittanceApprove(notification)"><small>{{ 'branchApproval' | translate }}</small>
                      </div>
                      <div *ngIf="notification.secondaryApprovalRequired" class="col p-0 btn btn-outline-secondary btn-sm"
                        (click)="onRemittanceComplianceApprove(notification)">
                        <small>{{ 'complianceApproval' | translate }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="poolingService.pooling || !notifications.length" class="no-notification-container">
                <div *ngIf="poolingService.pooling" style="height: 100px; width: 100px">
                  <svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
                    <path fill="#000" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3
          c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z">
                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50"
                        to="360 50 50" repeatCount="indefinite" />
                    </path>
                    <path fill="#000" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7
          c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z">
                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50"
                        to="-360 50 50" repeatCount="indefinite" />
                    </path>
                    <path fill="#000" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5
          L82,35.7z">
                      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50"
                        to="360 50 50" repeatCount="indefinite" />
                    </path>
                  </svg>
                </div>
                <p style="margin-top: 10px">
                  {{
                  (poolingService.pooling ? 'loading' : 'noPendingRequests') | translate
                  }}
                </p>
              </div>
            </mat-menu>
            
          </div>
          </li>
          <li class="nav-item" *ngIf="loggedIn">
            <div class="row text-gray" [matMenuTriggerFor]="menu">
              <div class="col-3"><i class="fas fa-user-circle fa-2x"></i></div>
              <div class="col-9">
                <span>{{currentUser?.branch?.code === 'TKCPO' ? currentUser?.firstName + ' ' + currentUser?.lastName : currentUser?.firstName + ' ' + currentUser?.lastName}}</span><br>
                <small>{{currentUser?.branch?.code === 'TKCPO' ? '(' + ('hq' | translate) + '- ' + currentUser?.username + ')' : ' (' + currentUser?.branch?.code + '- ' + currentUser?.username + ')'}}</small>
              </div>
            </div>
            <mat-menu #menu="matMenu">
              <a *ngIf="authService.isUserAuthorized(permissions.VIEW_USER_PROFILE)" mat-menu-item
                routerLink="/users/profile"><mat-icon>person</mat-icon> {{ 'profile' | translate }}</a>
              <a mat-menu-item routerLink="/logout"><mat-icon>logout</mat-icon>&nbsp;{{ 'logout' | translate }}</a>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>