<app-index-container label="{{ 'complianceConfigurations' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_COMPLIANCE_CONFIG)"
    mat-raised-button
    color="primary"
    routerLink="/compliances/configuration/add"
  >
    <i class="fa-solid fa-circle-plus"></i>
    {{ 'addComplianceConfig' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>{{ 'complianceType' | translate }}:</mat-label>
          <mat-select #complianceType formControlName="complianceType">
            <mat-option
              *ngFor="let complianceType of complianceTypes"
              [value]="complianceType.id"
            >
              {{ complianceType.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- remittanceComplianceType Column -->
    <ng-container matColumnDef="complianceType">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'complianceType' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.complianceType }}</td>
    </ng-container>
    <!-- config Column -->
    <ng-container matColumnDef="config">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'configuration' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.config }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_COMPLIANCE_CONFIG,
                permissions.VIEW_COMPLIANCE_CONFIG
              ])
            "
            color="primary"
            routerLink="/compliances/configuration/view/{{ row.id }}"
          >
            <mat-icon>{{
              authService.isUserAuthorized(permissions.UPDATE_COMPLIANCE_CONFIG)
                ? 'edit'
                : 'visibility'
            }}</mat-icon>
            {{
              (authService.isUserAuthorized(
                permissions.UPDATE_COMPLIANCE_CONFIG
              )
                ? 'edit'
                : 'view'
              ) | translate
            }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
