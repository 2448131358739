<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'clientProtectionCustomerReport' | translate }}{{' ('+data.reportCurrency+')'}}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table id="clientProtectionReport" class="table table-bordered table-hover">
        <tbody *ngIf="data.clientProtectionCustomer.length">
        <tr>
          <th>{{ '#' | translate }}</th>
          <th>{{ 'date' | translate }}</th>
          <th>{{ 'customerName' | translate }}</th>
          <th>{{ 'mobile' | translate }}</th>
          <th>{{ 'idType' | translate }}</th>
          <th>{{ 'idNumber' | translate }}</th>
          <th>{{ 'numberOfRemittances' | translate }}</th>
          <th>{{ 'totalOfRemittances' | translate }}</th>

        </tr>
        <tr *ngFor="let clientProtectionCustomer of data.clientProtectionCustomer; let i = index">
          <!-- Number Column -->
          <td>{{ i + 1 }}</td>
          <td class="text-nowrap">{{ convertDateToLocaleString(clientProtectionCustomer.date, false) }}</td>
          <td class="text-nowrap">{{ clientProtectionCustomer.receiverName }}</td>
          <td class="text-nowrap">{{ clientProtectionCustomer.mobilePhone }}</td>
          <td class="text-nowrap">{{ clientProtectionCustomer.idType }}</td>
          <td class="text-nowrap">{{ clientProtectionCustomer.idNumber }}</td>
          <td class="text-nowrap">{{ clientProtectionCustomer.numberOfRemittances }}</td>
          <td class="text-nowrap">{{ clientProtectionCustomer.totalOfRemittances | number:'1.2-2' }}</td>
        </tr>
        </tbody>
        <tbody>
          <tr class="table-borderless">
            <td colspan="3" class="text-center"><strong>{{'grandTotal' | translate}}</strong></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-nowrap">{{ numberOfRemittancesGrandTotal}}</td>
            <td class="text-nowrap">{{ sumOfRemittancesGrandTotal | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div mat-dialog-actions class="justify-content-right">
    <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
    <button
      mat-button
      class="btn btn-outline-primary"
      [useExistingCss]="true"
      printSectionId="report"
      ngxPrint
    >
      {{ 'print' | translate }}
    </button>
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
      {{ 'export' | translate }}
    </button>
  </div>
</div>

