import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-settlement-history-change-log',
  templateUrl: './settlementHistoryChangeLogPopup.component.html',
})
export class SettlementHistoryChangeLogPopupComponent {
  Approved = '';

  constructor(
    public dialogRef: MatDialogRef<SettlementHistoryChangeLogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.data = JSON.parse(this.data);
  }
}
