import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { TranslateModule } from '@ngx-translate/core';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { ReportRoutesModule } from './report.routes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RemittanceReportsComponent } from './remittanceReports.component';
import { ReportsService } from './data/reports.service';
import { MonthlyCommissionsComponent } from './monthlyCommission.component';
import { CommissionStatusesResolver } from './data/reports.resolver';
import { BranchesResolver } from '../user/data/users.resolver';
import { TrialBalanceReportComponent } from './trialBalance.component';
import { TrialBalancePopupComponent } from './trialBalancePopup.component';
import { NgxPrintModule } from 'ngx-print';
import { BranchStatementReportComponent } from './branchStatement.component';
import { BranchStatementPopupComponent } from './branchStatementPopup.component';
import { SettlementReportComponent } from './settlementReport.component';
import { SettlementReportPopupComponent } from './settlementReportPopup.component';
import { RemittanceReportPopupComponent } from './remittanceReportPopup.component';
import { AccountActivityReportsComponent } from './accountActivityReports.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { AccountActivityReportPopupComponent } from './accountActivityReportPopup.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { UnpaidRemittanceReportComponent } from './unpaidRemittanceReport.component';
import { UnpaidRemittanceReportPopupComponent } from './unpaidRemittanceReportPopup.component';
import { ComplianceReportPopupComponent } from './complianceReportPopup.component';
import { ComplianceReportComponent } from './complianceReport.component';
import {PartnerSettlementReportComponent} from "./partnerSettlementReport.component";
import {PartnerSettlementReportPopupComponent} from "./partnerSettlementReportPopup.component";
import {CustomerReportComponent} from "./customerReport.component";
import {CustomerReportPopupComponent} from "./customerReportPopup.component";
import { FinsurvReconciliationReportComponent } from './finsurvreconciliationreport.component';
import { FinsurvReconciliationDetailComponent } from './finsurvreconciliationdetail.component';
import { CustomerRiskRatingReportComponent } from './customer-risk-rating-report.component';
import { CustomerRiskRatingScoreDetailsPopupComponent } from './customer-risk-rating-score-details-popup.component';
import { KiteworkReportComponent } from './kiteworkReport.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    RemittanceReportsComponent,
    MonthlyCommissionsComponent,
    TrialBalanceReportComponent,
    TrialBalancePopupComponent,
    BranchStatementReportComponent,
    BranchStatementPopupComponent,
    SettlementReportComponent,
    SettlementReportPopupComponent,
    RemittanceReportPopupComponent,
    AccountActivityReportsComponent,
    AccountActivityReportPopupComponent,
    UnpaidRemittanceReportComponent,
    UnpaidRemittanceReportPopupComponent,
    ComplianceReportComponent,
    ComplianceReportPopupComponent,
    PartnerSettlementReportComponent,
    PartnerSettlementReportPopupComponent,
    CustomerReportComponent,
    CustomerReportPopupComponent,
    FinsurvReconciliationReportComponent,
    FinsurvReconciliationDetailComponent,
    CustomerRiskRatingReportComponent,
    CustomerRiskRatingScoreDetailsPopupComponent,
    KiteworkReportComponent
  ],
  imports: [
    CommonModule,
    ReportRoutesModule,
    ContainersModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
    MatDatepickerModule,
    MatTableExporterModule,
    NgxPrintModule,
    MatDatepickerModule,
    MatTableExporterModule,
    MatDialogModule,
    MatDatepickerModule,
    MatDialogModule,
    MatTableExporterModule,
    MatTooltipModule
  ],
  exports: [],
  providers: [ReportsService, CommissionStatusesResolver, BranchesResolver],
})
export class ReportModule {}
