import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { RolesComponent } from './role/roles.component';
import { RoleDetailsComponent } from './role/roleDetails.component';
import { UsersComponent } from './users.component';
import { UserDetailsComponent } from './userDetails.component';
import { UserTypeResolver } from './data/userTypes.resolver';
import { PermissionsResolver } from './data/permissions.resolver';
import { RoleByIdResolver, RolesResolver } from './data/roles.resolver';
import {
  BranchesResolver,
  LoginHistoryStatusesResolver,
  OtpTypesResolver,
  UserByIdResolver,
} from './data/users.resolver';
import { UserProfileComponent } from './userProfile.component';
import { LogsComponent } from './log/logs.component';
import { RateChangeStatuses } from '../branch/data/branches.resolver';
import { RoleRequestsComponent } from './role/roleRequests.component';
import { LoginHistoryComponent } from './loginHistory/loginHistory.component';
import { TicketStatusResolver } from '../support/data/support.resolver';
import { UserRequestsComponent } from './userRequests.component';

const {
  userManagement: { users, roles, logs, roleRequests, userRequests, loginHistories },
} = Permissions;

const routes: Routes = [
  {
    path: 'users',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(users)] },
        component: UsersComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        data: { permissions: [users.CREATE_USER], addMode: true },
        resolve: {
          userTypes: UserTypeResolver,
          roles: RolesResolver,
          branches: BranchesResolver,
        },
        component: UserDetailsComponent,
      },
      {
        path: 'view/:userId',
        canActivate: [AuthGuard],
        data: {
          permissions: [users.UPDATE_USER, users.VIEW_USER],
          addMode: false,
        },
        resolve: {
          userTypes: UserTypeResolver,
          roles: RolesResolver,
          users: UserByIdResolver,
          branches: BranchesResolver,
        },
        component: UserDetailsComponent,
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        data: { permissions: [users.VIEW_USER_PROFILE] },
        resolve: { otpTypes: OtpTypesResolver },
        component: UserProfileComponent,
      },
      {
        path: 'logs',
        canActivate: [AuthGuard],
        data: { permissions: [logs.VIEW_LOG] },
        resolve: {},
        component: LogsComponent,
      },
      {
        path: 'loginhistories',
        canActivate: [AuthGuard],
        data: { permissions: [loginHistories.VIEW_LOGIN_HISTORY] },
        resolve: { statuses: LoginHistoryStatusesResolver},
        component: LoginHistoryComponent,
      },
      {
        path: 'requests',
        canActivate: [AuthGuard],
        data: {
          permissions: [userRequests.VIEW_USER_REQUESTS, userRequests.UPDATE_USER_REQUESTS],
        },
        resolve: {
          userTypes: UserTypeResolver,
          statuses: RateChangeStatuses,
        },
        component: UserRequestsComponent,
      },
      {
        path: 'roles',
        component: OutletComponent,
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            data: { permissions: [...Object.values(roles)] },
            resolve: { userTypes: UserTypeResolver },
            component: RolesComponent,
          },
          {
            path: 'add',
            canActivate: [AuthGuard],
            data: { permissions: [roles.CREATE_ROLE], addMode: true },
            resolve: {
              userTypes: UserTypeResolver,
              permissions: PermissionsResolver,
            },
            component: RoleDetailsComponent,
          },
          {
            path: 'view/:roleId',
            canActivate: [AuthGuard],
            data: {
              permissions: [roles.VIEW_ROLE, roles.UPDATE_ROLE],
              addMode: false,
            },
            resolve: {
              userTypes: UserTypeResolver,
              permissions: PermissionsResolver,
              roles: RoleByIdResolver,
            },
            component: RoleDetailsComponent,
          },
          {
            path: 'requests',
            canActivate: [AuthGuard],
            data: {
              permissions: [roleRequests.VIEW_ROLE_REQUESTS, roleRequests.UPDATE_ROLE_REQUESTS],
            },
            resolve: {
              userTypes: UserTypeResolver,
              statuses: RateChangeStatuses,
            },
            component: RoleRequestsComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutesModule {}
