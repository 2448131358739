import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { RemittanceReceiverUpdateDto } from './data/dto/remittances.dto';

@Component({
  selector: 'app-remittance-receiver-information',
  templateUrl: './remittanceReceiverInformationUpdate.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class RemittanceReceiverInformationUpdateComponent implements OnInit {
  comments: string = '';
  type: string = '';
  tracingOptions: LookupDto[] = [];

  formReceiverUpdate!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemittanceReceiverInformationUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RemittanceReceiverUpdateDto,
    private fb: FormBuilder,
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.formReceiverUpdate = this.fb.group({
      firstName: [
        this.data.firstName,
        this.data.canUpdateReceiverName ? [Validators.required] : [],
      ],
      lastName: [
        this.data.lastName,
        this.data.canUpdateReceiverName ? [Validators.required] : [],
      ],
      phone: [
        this.data.mobilePhone,
        this.data.canUpdateReceiverPhone ? Validators.required : [],
      ],
      remittanceId: [],
    });
  }

  onSubmit() {
    if (this.formReceiverUpdate.valid) {
      this.formReceiverUpdate.value.remittanceId = this.data.remittanceId;
      this.dialogRef.close(this.formReceiverUpdate.value);
    }
  }
}
