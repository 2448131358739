<app-index-container label="{{ 'customers' | translate }}">
  <!-- <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_CUSTOMER)"
    mat-raised-button
    color="primary"
    routerLink="/customers/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addCustomer' | translate }}
  </button> -->
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row mb-0">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'mobile' | translate }}:</mat-label>
          <input matInput #username formControlName="mobilePhone" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'name' | translate }}:</mat-label>
          <input matInput #customerName formControlName="customerName" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'documentNumber' | translate }}:</mat-label>
          <input matInput #documentNumber formControlName="documentNumber" />
        </mat-form-field>
      </div>
    </div>
     <div class="row mt-0">
      <div class="col-4 mx-1">
        <label for="Customer Status">Customer Status</label>
        <mat-radio-group formControlName="customerStatusId" class="col-3 mb-2 mt-3" (change)="onCustomerStatusChanged($event)">
          <mat-radio-button [value]="null" checked style="margin-left: 8px;">
            All
          </mat-radio-button>

          <mat-radio-button [value]="1" style="margin-left: 8px;">
            Unverified
          </mat-radio-button>
        
          <mat-radio-button [value]="2" style="margin-left: 8px;">
            Verified
          </mat-radio-button>
        
          <mat-radio-button [value]="3" style="margin-left: 8px;">
            Rejected
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div> 
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>{{ 'firstName' | translate }}</th>
      <td
        [style.color]="row.localBlacklistGuid ? 'red' : 'null'"
        mat-cell
        *matCellDef="let row"
      >
        {{ row.firstName }}
      </td>
    </ng-container>

    <!-- middle Name Column -->
    <ng-container matColumnDef="middleName">
      <th mat-header-cell *matHeaderCellDef>{{ 'middleName' | translate }}</th>
      <td [style.color]="row.localBlacklistGuid ? 'red' : 'null'" mat-cell *matCellDef="let row">
        {{ row.middleName }}
      </td>
    </ng-container>
    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>{{ 'lastName' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
    </ng-container>
    <!-- Mobile Column -->
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef>{{ 'mobile' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.mobilePhone }}</td>
    </ng-container>
    <!-- Country Column
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>{{ 'country' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.country }}</td>
    </ng-container> -->
    <!-- City Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef>{{ 'city' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.city }}</td>
    </ng-container>
    <!-- Customer age Column -->
    <!-- <ng-container matColumnDef="customerAge">
      <th mat-header-cell *matHeaderCellDef>{{ 'customerAge' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.customerAge }}</td>
    </ng-container> -->

    <!-- Customer registeration date Column -->
    <ng-container matColumnDef="customerCreatedDate">
      <th mat-header-cell *matHeaderCellDef>{{ 'createdDate' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.createdDate,  'datetime') }}</td>
    </ng-container>

    <!-- Customer onboard date Column -->
    <ng-container matColumnDef="customerOnboardDate">
      <th mat-header-cell *matHeaderCellDef>{{ 'onboardDate' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.onboardDate,  'datetime') }}</td> 
    </ng-container>

    <ng-container matColumnDef="customerStatus">
      <th mat-header-cell *matHeaderCellDef>Customer Status</th>
      <td mat-cell *matCellDef="let row">{{ row.customerStatus }}</td>
    </ng-container>
     <!-- Actions Column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
    <td mat-cell *matCellDef="let row">
      <div class="action-button-menu">
        <a mat-menu-item class="primary" (click)="openActionsDialog(row.id, row.customerTypeId)">
          <mat-icon>visibility</mat-icon>
          {{ 'view actions' | translate }}
        </a>
      </div>
    </td>
  </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
