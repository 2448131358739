import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import { ReportsService } from './data/reports.service';
import { TrialBalancePopupComponent } from './trialBalancePopup.component';
import {SnackBarService} from "../_common/snackBar.service";

@Component({
  selector: 'app-trial-balance',
  templateUrl: './trialBalance.component.html',
})
export class TrialBalanceReportComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: FormGroup;
  today = new Date();
  endDate = new UntypedFormControl(this.today, [Validators.required]);
  branches: GetBranchesDto[] = [];
  showInBranchCurrencyCb = false;
  branchCurrency = '';

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public authService: AuthService,
    private reportService: ReportsService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ branches }) => {
      this.branches = branches;
    });

    this.form = this.fb.group({
      branch: [null, [Validators.required]],
      endDate: this.endDate,
      showInBranchCurrency: [true],
    });
  }

  onBranchChange(branch: GetBranchesDto) {
    this.branchCurrency =
      branch.baseCurrency.split(' ').pop()?.replace('(', '').replace(')', '') ||
      '';
    this.showInBranchCurrencyCb = this.branchCurrency.toLowerCase() !== 'usd';
    if (this.showInBranchCurrencyCb) {
      this.form.get('showInBranchCurrencyCb')?.setValue(true);
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const { branch, endDate, showInBranchCurrency } = this.form.value;

      this.reportService
        .getTrialBalanceReport(branch.id, endDate, showInBranchCurrency)
        .subscribe((data) => {
          const dialogRef = this.dialog.open(TrialBalancePopupComponent, {
            data: { date: endDate, data: data },
            width: '1000px',
            maxHeight: '90vh',
          });

          dialogRef.afterClosed().subscribe((resp) => {
            if (resp === 'close') {
              return;
            }
          });
        }, ({message}) =>{
          this.snackBar.open(message);
        });
    }
  }
}
