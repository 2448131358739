import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as XLSX from 'xlsx';
import { Utilities } from '../_common/utils';
import { SettlementReportDto } from './data/dto/settlementReport.dto';

@Component({
  selector: 'app-settlement-report-popup',
  templateUrl: './settlementReportPopup.component.html',
  styleUrls: ['./reportStyles.css'],
})
export class SettlementReportPopupComponent {
  constructor(
    public dialogRef: MatDialogRef<SettlementReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { startDate: Date; endDate: Date; data: SettlementReportDto },
  ) {}

  convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('settlements'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(
      wb,
      'Settlements on ' +
        this.convertDateToLocaleString(new Date(), false) +
        '.xlsx',
    );
  }

  
}
