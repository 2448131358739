import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackBarService } from '../_common/snackBar.service';
import { BranchesService } from './data/branches.service';
import { LookupDto } from '../_common/data/dto/api.dto';
import {regExp} from "../_common/data/validationRules";
@Component({
  selector: 'app-commission-approval-request',
  templateUrl: './branchCommissionApprovalPopup.component.html',
  styles: [
    '.mat-radio-button ~ .mat-radio-button { margin-left: 16px; } .mat-radio-group { margin-left: 16px; }',
  ],
})
export class BranchCommissionApprovalPopupComponent implements OnInit {
  comments: string = '';
  selectedRadio: string = '';

  form!: UntypedFormGroup;
  commissionChangeStatuses: LookupDto[] = [];

  constructor(
    public dialogRef: MatDialogRef<BranchCommissionApprovalPopupComponent>,
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService,
    private snackBar: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) {}

  ngOnInit() {
    this.branchesService.getCommissionChangeStatuses().subscribe((statuses) => {
      this.commissionChangeStatuses = statuses;
    }, ({ message }) => {
      this.snackBar.open(message);
    });
    this.form = this.fb.group({
      commissionChangeRequestStatus: [
        'approveBranchCommission',
        [Validators.required],
      ],
      comments: ['', [Validators.required, Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const statusId = this.commissionChangeStatuses.find(
        (status) =>
          status.name == this.form.value.commissionChangeRequestStatus,
      )?.id;
      if (!statusId) {
        this.snackBar.open('statusIdNotFound');
        this.dialogRef.close({ success: false });
        return;
      }

      this.branchesService
        .updatetBranchCommissionApproval({
          id: this.data.id,
          statusId: statusId,
          statusChangeComments: this.form.value.comments,
        })
        .subscribe(
          (resp) => {
            // @ts-ignore
            this.snackBar.open(resp?.message);
            this.dialogRef.close({ success: true });
          },
          ({ message }) => {
            this.snackBar.open(message);
            this.dialogRef.close({ success: false });
          },
        );
    }
  }
}
