<app-index-container label="{{ 'settlements' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_SETTLEMENT)"
    mat-raised-button
    color="primary"
    routerLink="/settlements/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addSettlement' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label class="mb-1">{{ 'direction' | translate }}:</mat-label>
        <mat-select formControlName="direction">
          <mat-option value="incoming">{{ 'incoming' | translate }}</mat-option>
          <mat-option value="outgoing">{{ 'outgoing' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label class="mb-1">{{ 'settlementId' | translate }}:</mat-label>
        <input matInput #settlementId formControlName="settlementId" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label class="mb-1">{{ 'settlementType' | translate }}:</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of settlementTypes" [value]="type">{{
            type.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'settler' | translate }}:</mat-label>
        <mat-select formControlName="settler">
          <mat-option *ngFor="let settler of settlers" [value]="settler">
            {{
              settler.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : settler.code + ' - ' + settler.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'beneficiary' | translate }}:</mat-label>
        <mat-select formControlName="beneficiary">
          <mat-option
            *ngFor="let beneficiary of beneficiaries"
            [value]="beneficiary"
          >
            {{
              beneficiary.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : beneficiary.code + ' - ' + beneficiary.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-6">
        <mat-label>{{ 'status' | translate }}:</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statuses" [value]="status"
            >{{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-sm-6 col-md-3 col-lg-3 pt-3">
        <mat-checkbox
          matInput
          class="mt-1"
          color="primary"
          formControlName="partnerSettlement"
        >
          {{ 'partnerSettlement' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'date' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ convertDateToLocaleString(row.createdDate) }}
      </td>
    </ng-container>
    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.amount | number:'1.2-2'  }}</td>
    </ng-container>
    <!-- Confirmed Amount Column -->
    <ng-container matColumnDef="confirmedAmount">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'confirmedAmount' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.confirmedAmount | number:'1.2-2'  }}</td>
    </ng-container>
    <!-- Settler Column -->
    <ng-container matColumnDef="settler">
      <th mat-header-cell *matHeaderCellDef>{{ 'settler' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{
          row.settler === 'CPO Branch' ? ('hqBranch' | translate) : row.settler
        }}
      </td>
    </ng-container>
    <!-- Beneficiary Column -->
    <ng-container matColumnDef="beneficiary">
      <th mat-header-cell *matHeaderCellDef>{{ 'beneficiary' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{
          row.beneficiary === 'CPO Branch'
            ? ('hqBranch' | translate)
            : row.beneficiary
        }}
      </td>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            *ngIf="row.canConfirm"
            mat-menu-item
            (click)="
              confirmSettlementDialog(
                row.id,
                row.settlerComments,
                row.amount,
                row.beneficiaryId
              )
            "
          >
            <mat-icon>check_circle</mat-icon>{{ 'confirm' | translate }}
          </a>
          <a mat-menu-item (click)="viewSettlementDialog(row.id)">
            <mat-icon>visibility</mat-icon>{{ 'view' | translate }}
          </a>
          <a
            *ngIf="row.canReject"
            mat-menu-item
            (click)="
              rejectSettlementDialog(
                row.id,
                row.settlerComments,
                row.partnerSettlementId,
                row.isPartnerSettlement
              )
            "
          >
            <mat-icon>block</mat-icon>{{ 'reject' | translate }}
          </a>
        </div>
        <div class="action-menu">
          <a [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </a>
          <mat-menu #menu="matMenu" class="action-menu-item">
            <a
              *ngIf="row.canRollback"
              mat-menu-item
              (click)="
                rollbackSettlementDialog(
                  row.id,
                  row.settlerComments,
                  row.partnerSettlementId,
                  row.isPartnerSettlement
                )
              "
            >
              <mat-icon>undo</mat-icon>{{ 'rollback' | translate }}
            </a>
            <a
              *ngIf="row.canViewHistory"
              mat-menu-item
              (click)="viewHistoryDialog(row.id)"
            >
              <mat-icon>history</mat-icon>{{ 'history' | translate }}
            </a>
            <a
              *ngIf="row.canTransfer"
              mat-menu-item
              (click)="
                transferSettlementDialog(
                  row.id,
                  row.settlerId,
                  row.settlerComments
                )
              "
            >
              <mat-icon>move_up</mat-icon>{{ 'transfer' | translate }}
            </a>
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
