<app-index-container label="{{ 'journals' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_MANUAL_JOURNAL)"
    mat-raised-button
    color="primary"
    routerLink="/branches/journals/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addJournal' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branchId' | translate }}:</mat-label>
        <mat-select formControlName="branchId">
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'manualJournalId' | translate }}:</mat-label>
        <input matInput #accountName formControlName="manualJournalId" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'status' | translate }}:</mat-label>
        <mat-select formControlName="statusId">
          <mat-option *ngFor="let status of statuses" [value]="status.id">
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end">
      <button
        mat-raised-button
        [disabled]="!form.valid"
        type="submit"
        color="primary"
      >
        <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
      </button>
    </div>
  </form>
  <table table mat-table [dataSource]="journals" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">
        *m
        {{ i + 1 }}
        +
      </td>

      <!-- {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}} -->
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>{{ 'id' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.manualJournalId }}</td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'requesterComment' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.comments }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'journalDate' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ convertDateToLocaleString(row.journalDate) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>{{ 'username' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.username }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{ row.totalDebitBranchCurrencyAmount | number:'1.2-2'}}
      </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.CREATE_MANUAL_JOURNAL,
                permissions.VIEW_MANUAL_JOURNAL,
                permissions.APPROVE_MANUAL_JOURNAL,
                permissions.REJECT_MANUAL_JOURNAL
              ])
            "
            (click)="receipt(row.manualJournalId)"
          >
            <mat-icon>receipt</mat-icon
            >{{
              (row.capApprove &&
              authService.isUserAuthorized([
                permissions.APPROVE_MANUAL_JOURNAL,
                permissions.REJECT_MANUAL_JOURNAL
              ])
                ? 'approve'
                : 'details'
              ) | translate
            }}
          </a>
          <a
          mat-menu-item
          *ngIf="authService.isUserAuthorized(permissions.UPDATE_MANUAL_JOURNAL) && !row.hasAttachment && row.status =='Pending'"
          class="primary"
          (click)="openDocsDialog(row.manualJournalId)"
        >
          <mat-icon>badge</mat-icon>
          {{ 'uploadDoc' | translate }}
        </a>
        <a
              *ngIf="authService.isUserAuthorized(permissions.VIEW_MANUAL_JOURNAL) && row.canViewAttachment && row.hasAttachment"
              mat-menu-item
              (click)="viewAttachments(row.manualJournalId)"
            >
              <mat-icon>attachment</mat-icon
              >{{ 'viewDoc' | translate }}
            </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
