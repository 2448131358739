import { NgModule } from '@angular/core';
import { CustomerService } from './data/customer.service';
import {
  CustomerByIdResolver,
  CustomerDocumentTypesResolver,
  CustomerGenderResolver,
  CustomerOccupationsResolver,
  CustomerSourceOfIncomeResolver, CustomerTypesResolver,
} from './data/customer.resolver';
import { CustomersComponent } from './customers.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContainersModule } from '../_common/components/containers/containers.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { CustomerRoutesModule } from './customer.routes.module';
import { CustomerDetailsComponent } from './customerDetails.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CustomerDocPopupComponent } from './customerDocPopup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomerAddDocPopupComponent } from './customerAddDocPopup.component';
import { MatIconModule } from '@angular/material/icon';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { CustomerDocEditPopupComponent } from './customerDocEditPopup.component';
import { MatMenuModule } from '@angular/material/menu';
import { ValidationErrorModule } from '../_common/components/validation-error/validation-error.module';
import {conductorsPopupComponent} from "./conductorsPopup.component";
import { EditConductorPopupComponent } from "./editConductorPopup.component";
import { BlacklistComponent } from './blacklist.component';
import { AddCustomerBlacklistComponent } from './addBlacklist.component';
import { CustomerModificationsPopup } from './customerModificationsPopup.component';
import { CustomerModificationChangeLogPopupComponent } from './customerModificationChangeLogPopup.component';
import { BlacklistSactionedAuditLogsComponent } from './blacklistSanctionedAuditLogs.component';
import { ClientScreeningConponent } from './clientScreening.component';
import { UploadDialogComponent } from './uploadExcelFile.component';
import { ClientScreeningCheckConponent } from './clientScreeningCheck.component';
import { OrmCustomerActionsComponent } from './ormcustomeractions.component';
import { SignupMandateComponent } from './signup-mandate.component';
import { SendRemittanceDeclarationComponent } from './sendRemittanceDeclaration.component';
import { DocumentImagesComponent } from './documentImages.component';

@NgModule({
  declarations: [
    CustomersComponent,
    BlacklistComponent,
    AddCustomerBlacklistComponent,
    CustomerDetailsComponent,
    CustomerDocPopupComponent,
    CustomerAddDocPopupComponent,
    AttachmentsPopupComponent,
    CustomerDocEditPopupComponent,
    conductorsPopupComponent,
    EditConductorPopupComponent,
    CustomerModificationsPopup,
    CustomerModificationChangeLogPopupComponent,
    BlacklistSactionedAuditLogsComponent,
    ClientScreeningConponent,
    UploadDialogComponent,
    ClientScreeningCheckConponent,
    OrmCustomerActionsComponent,
    SignupMandateComponent,
    SendRemittanceDeclarationComponent,
    DocumentImagesComponent
  ],
  imports: [
    CommonModule,
    CustomerRoutesModule,
    ContainersModule,
    MatNativeDateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatDialogModule,
    MatIconModule,
    MatIconModule,
    MatMenuModule,
    ValidationErrorModule,
  ],
  exports: [CustomerDocPopupComponent, CustomerModificationsPopup, conductorsPopupComponent],
  providers: [
    CustomerService,
    CustomerDocumentTypesResolver,
    CustomerGenderResolver,
    CustomerOccupationsResolver,
    CustomerSourceOfIncomeResolver,
    CustomerByIdResolver,
    CustomerTypesResolver
  ],
})
export class CustomerModule {}
