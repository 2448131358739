<h2>{{ 'Customers' | translate }}</h2>
<table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- First Name Column -->
    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef>{{ 'fullName' | translate }}</th>
      <td [style.color]="row.localBlacklistGuid ? 'red' : 'null'" mat-cell *matCellDef="let row">
        {{ row.fullName }}
      </td>
    </ng-container>
    <!-- Middle Name Column -->
    <ng-container matColumnDef="customerStatus">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.customerStatus }}</td>
    </ng-container>
    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef>{{ 'lastName' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.lastName }}</td>
    </ng-container>
    <!-- Mobile Column -->
    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef>{{ 'mobile' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.mobilePhone }}</td>
    </ng-container>

    <ng-container matColumnDef="recruiterCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'recruiterCode' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.recruiterCode }}</td>
    </ng-container>


    <!-- City Column -->
    <ng-container matColumnDef="dateRecruited">
      <th mat-header-cell *matHeaderCellDef>{{ 'dateRecruited' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.createdDate) }}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>