<app-index-container label="{{ 'loginHistory' | translate }}">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label class="mb-1">{{ 'status' | translate }}:</mat-label>
        <mat-select formControlName="status">
          <mat-option
            *ngFor="let status of statuses"
            [value]="status"
          >
            {{ status.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'date' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="requestedDateStart"
          formControlName="date"
          [value]="date.value"
        />
        <mat-hint>DD-MM-YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="requestedDateStart"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #requestedDateStart></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-end">
        <button
          mat-raised-button
          [disabled]="!form.valid"
          type="submit"
          color="primary"
        >
          <i class="fa-solid fa-magnifying-glass"></i>
          {{ 'search' | translate }}
        </button>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>{{ 'username' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.username }}</td>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'date' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.date }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
