import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-remittance-report',
  templateUrl: './remittanceReportPopup.component.html',
})
export class RemittanceReportPopupComponent {
  displayedColumns: string[] = [
    'remittanceDetails',
    'senderDetails',
    'recieverDetails',
  ];
  // ['#', 'senderBranch','senderFullName','senderMobile','senderCity', 'senderCountry', 'sentDate', 'payingBranch',
  // 'recieverFullName', 'receiverMobile', 'recieverCity', 'recieverCountry', 'paidDate', 'remittanceId', 'amount', 'commission', 'status'];
  // remittances = new MatTableDataSource();

  constructor(
    public dialogRef: MatDialogRef<RemittanceReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
