import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {
  colorThemeForm: UntypedFormGroup | undefined;

  constructor(private fb: UntypedFormBuilder, private router: Router) {}

  ngOnInit(): void {
    this.colorThemeForm = this.fb.group({
      colorPalette: [null],
    });
  }

  saveChanges() {
    if (this.colorThemeForm?.value.colorPalette) {
      localStorage.setItem(
        'tayopay_theme',
        JSON.stringify(this.colorThemeForm?.value.colorPalette),
      );
      window.location.reload();
    }
  }
}
