import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-logdetailpopup',
  templateUrl: './logDetailPopup.component.html',
})
export class LogDetailPopupComponent {
  constructor(
    @Optional() public dialogRef: MatDialogRef<LogDetailPopupComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  get json() {
    return JSON.stringify(this.data, null, 4);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
