<app-index-container label="{{ 'Search Sanctions' | translate }}">
    <form form class="container-fluid flex-column p-0" name="form" [formGroup]="form" novalidate (submit)="onSubmit()">
        <div class="row mt-1 mb-2">
            <div class="col-4 mx-1">
                <label for="Customer Status">Filter Type</label>
                <mat-radio-group formControlName="filterType" class="col-3 mb-2 mt-3">
                    <mat-radio-button [value]="1" checked style="margin-left: 8px;">
                        Sanctioned
                    </mat-radio-button>
        
                    <mat-radio-button [value]="2" style="margin-left: 8px;">
                        PEP
                    </mat-radio-button>
        
                    <mat-radio-button [value]="3" style="margin-left: 8px;">
                        Both
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="row ml-2">
            <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{ 'filter' | translate }}:</mat-label>
                <input matInput  formControlName="filter" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="col-6">
                <mat-label>{{ 'Strength' | translate }}:</mat-label>
                <input matInput formControlName="strength" type="number" min="1"/>
            </mat-form-field>
        </div>

        <div class="row">
            <!-- <div class="col-md-6"></div> -->
                <div class="d-flex justify-content-end">
                    <button mat-raised-button [disabled]="!form.valid" type="submit" color="primary">
                        <i class="fa-solid fa-magnifying-glass"></i>
                        {{ 'search' | translate }}
                    </button>
                </div>
        </div>
    </form>


    <table table mat-table [dataSource]="sanctions" multiTemplateDataRows class="table">
        <!-- Icon Column for Row Expansion -->
        <ng-container matColumnDef="#">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row;">
                <mat-icon (click)="toggleRow(row);">
                    {{ expandedElement === row ? 'remove_circle_outline' : 'add_circle_outline' }}
                </mat-icon>
            </td>
        </ng-container>
    
        <!-- Number Column -->
        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let row; let i = index">{{ getIndex(row) + 1 }}</td>
        </ng-container>
    
        <!-- Title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{ 'title' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.title }}</td>
        </ng-container>
    
        <!-- Full Name Column -->
        <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef>{{ 'fullName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.fullName }}</td>
        </ng-container>
    
        <!-- Aka Full Name Column -->
        <ng-container matColumnDef="akaFullName">
            <th mat-header-cell *matHeaderCellDef>{{ 'Aka FullName' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.matchedAlias }}</td>
        </ng-container>
    
        <!-- Source Column -->
        <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef>{{ 'Source' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.source }}</td>
        </ng-container>
    
        <!-- Score Column -->
        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef>{{ 'Score' | translate }}</th>
            <td mat-cell *matCellDef="let row">{{ row.score }}</td>
        </ng-container>
    
        <!-- Table Header and Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.expanded]="expandedElement === row"></tr>
    
        <!-- Expanded Detail Row -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div *ngIf="expandedElement === element" class="detail-panel">
                    <table cellpadding="5" class="table table-striped table-bordered table-hover" cellspacing="0" border="0" style="padding-left:50px;">
                        <ng-container *ngIf="element.aliases.length">
                            <tr>
                                <td colspan="6"><b>Aliases:</b></td>
                            </tr>
                            <tr *ngFor="let alias of element.aliases">
                                <td></td>
                                <td colspan="5">{{ alias }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Keywords -->
                        <ng-container *ngIf="element.keywords.length">
                            <tr>
                                <td colspan="6"><b>Keywords:</b></td>
                            </tr>
                            <tr *ngFor="let keyword of element.keywords">
                                <td></td>
                                <td colspan="5">{{ keyword }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Dates of Birth -->
                        <ng-container *ngIf="element.datesOfBirth.length">
                            <tr>
                                <td colspan="6"><b>Dates of Birth:</b></td>
                            </tr>
                            <tr *ngFor="let dob of element.datesOfBirth">
                                <td></td>
                                <td colspan="5">{{ dob }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Places of Birth -->
                        <ng-container *ngIf="element.placesOfBirth.length">
                            <tr>
                                <td colspan="6"><b>Places of Birth:</b></td>
                            </tr>
                            <tr *ngFor="let place of element.placesOfBirth">
                                <td></td>
                                <td colspan="5">{{ place }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Addresses -->
                        <ng-container *ngIf="element.addresses.length">
                            <tr>
                                <td colspan="6"><b>Addresses:</b></td>
                            </tr>
                            <tr *ngFor="let address of element.addresses">
                                <td></td>
                                <td colspan="5">{{ address }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Cities -->
                        <ng-container *ngIf="element.cities.length">
                            <tr>
                                <td colspan="6"><b>Cities:</b></td>
                            </tr>
                            <tr *ngFor="let city of element.cities">
                                <td></td>
                                <td colspan="5">{{ city }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Countries -->
                        <ng-container *ngIf="element.countries.length">
                            <tr>
                                <td colspan="6"><b>Countries:</b></td>
                            </tr>
                            <tr *ngFor="let country of element.countries">
                                <td></td>
                                <td colspan="5">{{ country }}</td>
                            </tr>
                        </ng-container>
                        
                        <!-- Passports -->
                        <ng-container *ngIf="element.passports.length">
                            <tr>
                                <td colspan="6"><b>Passports:</b></td>
                            </tr>
                            <tr *ngFor="let passport of element.passports">
                                <td></td>
                                <td colspan="5">{{ passport }}</td>
                            </tr>
                        </ng-container>
                        
                    </table>
                </div>
            </td>
        </ng-container>
    
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="example-detail-row"></tr>
    </table>
    
    <mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10" (page)="updatePaginator()"></mat-paginator>
</app-index-container>