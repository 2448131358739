<h1 mat-dialog-title style="text-align: center">{{ 'Customer Risk Rating Report Details' | translate }}</h1>
<div mat-dialog-content>
    <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
        <div class="row" style="margin: 10px">
            <table id="reconciliationReportDetails" class="table table-borderless">
                <tbody>
                    <tr>
                        
                        <th class="text-nowrap">{{ 'CustomerId' | translate }}</th>
                        <th class="text-nowrap">{{ 'Full Name' | translate }}</th>
                        <th class="text-nowrap">{{ 'Mobile' | translate }}</th>
                        <th class="text-nowrap">{{ 'Country' | translate }}</th>
                        <th class="text-nowrap">{{ 'Branch Code' | translate }}</th>
                        <th class="text-nowrap">{{ 'Customer Risk Rating' | translate }}</th>
                        <th class="text-nowrap">{{ 'Actions' | translate }}</th>
                    </tr>
                    <tr *ngFor="let riskRating of data.data">
                        <td class="text-nowrap">{{ riskRating.customerId }}</td>
                        <td class="text-nowrap">{{ riskRating.customerFullName }}</td>
                        <td class="text-nowrap">{{ riskRating.customerMobile }}</td>
                        <td class="text-nowrap">{{ riskRating.countryName }}</td>
                        <td class="text-nowrap">{{ riskRating.branchCode }}</td>
                        <td class="text-nowrap">{{ riskRating.customerRiskRating }}</td>
                        <div class="action-button-menu">
                            <a mat-menu-item (click)="onViewHistoryClick(riskRating.remittanceId)">
                                <mat-icon>history</mat-icon>{{ 'history' | translate }}
                            </a>
                        </div>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div mat-dialog-actions class="justify-content-right">
    <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
    <button mat-button class="btn btn-outline-primary" [useExistingCss]="true" printSectionId="report" ngxPrint>
        {{ 'print' | translate }}
    </button>
    <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
        {{ 'export' | translate }}
    </button>
</div>