<h1 mat-dialog-title>{{ 'Finsurv Modifications' | translate }}</h1>
<div mat-dialog-content>
    <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
        <div class="row" style="margin: 10px; text-align: center">
            <div class="col justify-content-center">
            </div>
        </div>

       <div class="py-2 overflow-auto">
    <table table mat-table [dataSource]="dataSource" class="table">
      <!-- Number Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      </ng-container>

       <!-- action Column -->
       <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.modificationAction }}</td>
      </ng-container>

    <!-- comment Column -->
    <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef>{{ 'comment' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.comments }}</td>
    </ng-container>

      <!-- Issued By Column -->
      <ng-container matColumnDef="createdBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'created By' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.createdBy }}</td>
      </ng-container>
   
      <!-- Date Created Column -->
      <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'dateCreated' | translate }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ convertDateToLocaleString(row.dateCreated, 'datetime') }}
        </td>
      </ng-container>
     
      <!-- Actions Column -->
      <!-- <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
        <td mat-cell *matCellDef="let row">
          <div class="action-button-menu">
            <button class="btn btn-outline-info btn-sm"  (click)="viewChangeLog(row)">
                {{ 'viewChangeLog' | translate }}
            </button>
          </div>
        </td>
      </ng-container> -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
    </div>
</div>

        <div mat-dialog-actions class="justify-content-right">
            <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
</div>