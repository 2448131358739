import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-customer-modification-change-log',
  templateUrl: './customerModificationChangeLogPopup.component.html',
  styleUrls: [],
})
export class CustomerModificationChangeLogPopupComponent {
  Approved = '';

  constructor(
    public dialogRef: MatDialogRef<CustomerModificationChangeLogPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    this.data = JSON.parse(this.data);
  }
}
