<div mat-dialog-content>
    <div [innerHTML]="sanitizedContent"></div>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button mat-stroked-button color="primary" (click)="printContent()">
        {{ 'print' | translate }}
    </button>
    <button mat-stroked-button color="warn" mat-dialog-close="close">
        {{ 'close' | translate }}
    </button>
</div>