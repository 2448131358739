<h1 mat-dialog-title>{{ 'remittanceCompliances' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-2 overflow-auto">
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2"
      *ngFor="let compliance of data.remittanceCompliances"
    >
      <div
        class="d-flex align-items-center h-8x bg-light px-2 border-bottom border-dark"
      >
        <b>{{ compliance.complianceDetails }}</b>
      </div>
      <mat-accordion *ngIf="compliance.complianceData?.length">
        <mat-expansion-panel *ngFor="let cd of compliance.complianceData">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ cd.name }}
            </mat-panel-title>
            <mat-panel-description>
              {{ cd.source }}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
            <div>{{ cd.info }}</div>
            <table class="h-full mx-2">
              <tr class="h-8x bg-light">
                <th>{{ 'dates of birth' | translate }}</th>
                <th>{{ 'birth places' | translate }}</th>
                <th>{{ 'addresses' | translate }}</th>
                <th>{{ 'aliases' | translate }}</th>
              </tr>

              <tr>
                <td>{{ cd.datesOfBirth.join(', ') }}</td>
                <td>{{ cd.birthPlaces.join(', ') }}</td>
                <td>{{ cd.addresses.join(', ') }}</td>
                <td>{{ cd.aliases.join(', ') }}</td>
              </tr>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <table *ngIf="compliance.remittanceData?.length" class="h-full mx-2">
        <tr class="h-8x bg-light">
          <th>{{ 'code' | translate }}</th>
          <th>{{ 'name' | translate }}</th>
          <th>{{ 'amount' | translate }}</th>
          <th>{{ 'date' | translate }}</th>
        </tr>
        <tr *ngFor="let remittance of compliance.remittanceData">
          <td>{{ remittance.code }}</td>
          <td>{{ remittance.name }}</td>
          <td>{{ remittance.amount | number:'1.2-2' }}</td>
          <td>{{ remittance.date }}</td>
        </tr>
      </table>
    </div>
    <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
      <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
      <textarea
        matInput
        placeholder="{{ 'complianceComments' | translate }}"
        [(ngModel)]="comments"
      ></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-button [mat-dialog-close]="comments">
    {{ 'continue' | translate }}
  </button>
</div>
