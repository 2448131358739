import { Component, Inject } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {GetConductor} from "./data/dto/customer.dto";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CustomerService} from "./data/customer.service";
import {BranchesService} from "../branch/data/branches.service";
import {EditConductorPopupComponent} from "./editConductorPopup.component";
import {SnackBarService} from "../_common/snackBar.service";


@Component({
  selector: 'app-customerconductors',
  templateUrl: './conductorsPopup.component.html',
})
export class  conductorsPopupComponent {

  displayedColumns: string[] = [
    'id',
    'firstName',
    'lastName',
    'mobile',
    'placeOfBirth',
    'dateOfBirth',
    'position',
    'fartherName',
    'motherName'
  ];
  customerId!: number;
  dataSource = new MatTableDataSource<GetConductor>();
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { conductors: GetConductor[]; customerId: number },
    private customerService: CustomerService,
    private branchesService: BranchesService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<conductorsPopupComponent>,
    private snackBar: SnackBarService
  ) {
    this.data.conductors = [];
  }

  ngOnInit() {
    this.customerService.getConductorByCustomerId(this.data.customerId).subscribe(
      (resp) => {
        this.data.conductors = resp;
        this.dataSource.data = this.data.conductors;
      },
      ({ message }) => {
        this.snackBar.open(message);
      },
    );
  }



  editConductor(customerId: any) {
    this.customerService.getConductorById(customerId).subscribe((conductor) => {

      this.branchesService.countries().subscribe(countries => {
        const dialogRef = this.dialog.open(EditConductorPopupComponent, {
          width: '850px',
          disableClose: true,
          data: {
            conductor: conductor,
            countries : countries
          },
        });

        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') return;

          this.dialogRef.close();
        });
      }, ({ message }) => {
        this.snackBar.open(message);
      });
    });
  }

}
