import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerService } from './data/customer.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CustomerModificationChangeLogPopupComponent } from './customerModificationChangeLogPopup.component';
import { Utilities } from '../_common/utils';


@Component({
  selector: 'app-customers',
  templateUrl: './clientScreeningCheck.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class ClientScreeningCheckConponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.customerManagement.blackListSanctionAuditLogs;
  loading = false;
  resultsLength = 0;
  displayedColumns: string[] = [
    'sanctionScreenImportId',
    'Title',
    'FullName',
    'AkaFullName',
    'Source',
    'Score',
   
  ];
  dataSource = new MatTableDataSource<any>();
  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    public authService: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public id: number,
  ) {}


  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.customerService.sanctionedList.subscribe(
      ({ sanctionedList }) => {
        this.loading = false;
        this.dataSource.data = [sanctionedList];
        this.dataSource.paginator = this.paginator;
        this.resultsLength = sanctionedList.length;
      },
    );

    this.customerService.getSanctionedList(this.id);

  }


  viewChangeLog(matchedEntity: any) {
    console.log(matchedEntity);
    this.dialog.open(CustomerModificationChangeLogPopupComponent, {
      disableClose: true,
      width: '54rem',
      data: matchedEntity,
    });
  }

  convertDateToLocaleString(date: Date, type: string) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
