<app-index-container label="{{ 'branches' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_BRANCH)"
    mat-raised-button
    color="primary"
    routerLink="/branches/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addBranch' | translate }}
  </button>
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-md-6">
          <mat-label>{{ 'branchCode' | translate }}:</mat-label>
          <input matInput #branchCode formControlName="branchCode" />
        </mat-form-field>
      </div>
      <div class="col-md-4 mt-4">
        <mat-label>{{ 'status' | translate }}:</mat-label> &nbsp;
        <mat-radio-group formControlName="branchStatus">
          <mat-radio-button value="active">{{
            'active' | translate
          }}</mat-radio-button
          >&nbsp;
          <mat-radio-button value="inactive">{{
            'inactive' | translate
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-md-4">
        <div class="d-flex justify-content-end">
          <button
            mat-raised-button
            [disabled]="!form.valid"
            type="submit"
            color="primary"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
            {{ 'search' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>{{ 'code' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>
    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>{{ 'title' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.title }}</td>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.type }}</td>
    </ng-container>
    <!-- City Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef>{{ 'city' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.city }}</td>
    </ng-container>
    <!-- Country Column -->
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>{{ 'country' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.country }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.branchStatus }}</td>
    </ng-container>
    <!-- Total Limit Column -->
    <ng-container matColumnDef="totalLimit">
      <th mat-header-cell *matHeaderCellDef>{{ 'totalLimit' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.totalLimit | number:'1.2-2' }}</td>
    </ng-container>
    <!-- Used Limit Column -->
    <ng-container matColumnDef="usedLimit">
      <th mat-header-cell *matHeaderCellDef>{{ 'usedLimit' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.usedLimit | number:'1.2-2' }}</td>
    </ng-container>
    <!-- Balance Column -->
    <ng-container matColumnDef="balance">
      <th mat-header-cell *matHeaderCellDef>{{ 'balance' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.balance | number:'1.2-2' }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_BRANCH,
                permissions.VIEW_BRANCH
              ])
            "
            color="primary"
            routerLink="/branches/view/{{ row.id }}"
          >
            <mat-icon>{{
              authService.isUserAuthorized(permissions.UPDATE_BRANCH)
                ? 'edit'
                : 'visibility'
            }}</mat-icon>
            {{
              (authService.isUserAuthorized(permissions.UPDATE_BRANCH)
                ? 'edit'
                : 'view'
              ) | translate
            }}</a
          >
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
