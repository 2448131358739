<div mat-dialog-title>
  <h1>{{ 'locality' | translate }}</h1>
</div>
<form
  class="container-fluid flex-column p-0"
  id="form"
  name="form"
  [formGroup]="form"
  novalidate
  (submit)="onSubmit()"
>
  <div mat-dialog-content>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'localityName' | translate }}:</mat-label>
        <input matInput #localityName formControlName="localityName" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'country' | translate }}:</mat-label>
        <mat-select
          #country
          [disabled]="!addMode"
          formControlName="country"
          (selectionChange)="onCountryChange($event.value.id)"
        >
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'city' | translate }}:</mat-label>
        <mat-select #city [disabled]="!addMode" formControlName="city">
          <mat-option *ngFor="let city of localityCities" [value]="city">
            {{ city.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-checkbox
        matInput
        class="mx-1"
        color="primary"
        formControlName="isActive"
        checked
        value="true"
      >
        {{ 'isActive' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button type="submit" color="primary">
      {{ 'submit' | translate }}
    </button>
  </div>
</form>
