import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import DisableDevtool from 'disable-devtool';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.disableDevTool) {
  let currentURL = (window.location.href.indexOf('login') > 0) ? 'about:blank' : '/logout';
  DisableDevtool({
    url: currentURL
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
