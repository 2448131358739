<h1 mat-dialog-title>{{ 'remittanceAttachments' | translate }}</h1>
<div mat-dialog-content>

  <div class="row" style="margin: 10px">
    <table
      id="conductors"
      class="table table-bordered table-hover"
      width="100%"
    >

      <tbody *ngIf="data.remittanceDocuments.length">
      <tr>
        <th class="text-nowrap">{{ 'id' | translate }}</th>
        <th class="text-nowrap">{{ 'remittanceId' | translate }}</th>
        <th class="text-nowrap">{{ 'documentType' | translate }}</th>
        <th class="text-nowrap">{{ 'actions' | translate }}</th>
      </tr>
      <tr *ngFor="let remitDoc of data.remittanceDocuments">
        <td class="text-nowrap">{{ remitDoc.id }}</td>
        <td class="text-nowrap">{{ remitDoc.remittanceId }}</td>
        <td class="text-nowrap">{{ remitDoc.documentType }}</td>

        <a
          class="text-nowrap"
          mat-menu-item
          *ngIf="remitDoc?.documentPath"
          color="primary"
          (click)="onViewAttachment(remitDoc?.documentPath)"
        >
          <mat-icon>attach_file</mat-icon>
          {{ 'viewAttachments' | translate }}
        </a>
      </tr>
      </tbody>
    </table>
  </div>

</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">
    {{ 'close' | translate }}
  </button>
</div>
