import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { CustomerDocTypesResponseDto } from './data/dto/customer.dto';
import { CustomerDocumentDto } from './data/dto/customerDocument.dto';
import {regExp} from "../_common/data/validationRules";

@Component({
  selector: 'app-add-customer-doc',
  templateUrl: './customerAddDocPopup.component.html',
})
export class CustomerAddDocPopupComponent implements OnInit {
  // @ts-ignore
  @ViewChild('customerDocFile') docFileElem: ElementRef;

  // @ts-ignore
  @ViewChild('documentForm') documentForm: ElementRef;

  form!: UntypedFormGroup;
  today = new Date();
  customerDocFile?: File[] = [];
  docTypes: LookupDto[] = [];
  customerId!: number;
  isFileSelected = false;
  docTypeSelected!: CustomerDocTypesResponseDto;
  docFile: any[] = [];
  selectedDoctype: any;
  private numberOfAttachmentsRequired: number = 0;
  public requiredFields: any;
  public isPassportDocTypeSelected: boolean = false;
  public allCountries: any;
  constructor(
    public dialogRef: MatDialogRef<CustomerAddDocPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { types: LookupDto[]; customerId: number, countries : any },
    private fb: UntypedFormBuilder,
    private snackbar: SnackBarService,
  ) {
    this.docTypes = this.data.types;
    this.docTypes = [...this.docTypes].filter((f) => f.isActive);
    this.customerId = this.data.customerId;
    this.allCountries = this.data?.countries;
  }

  ngOnInit() {
    this.form = this.fb.group({
      docType: [null, [Validators.required]],
      docNumber: [null, [Validators.required]],
      docIssueDate: [null, [Validators.required]],
      docExpiryDate: [null, [Validators.required]],
      docIssuedBy: [null, [Validators.required]],
      idNumber: [null],
      serialNumber: [null],
      motherName: [],
      fatherName: [],
      nationality: [],
    });
  }

  onDocAttachmentSelected(e: Event) {
    if (this.form.controls['docType'].value) {
      this.docTypeSelected = this.form.controls['docType'].value;
      const reader = new FileReader();
      if (this.docTypeSelected.isTwoPageAttachmentRequired) {
        if (this.customerDocFile!.length > 1) {
          this.snackbar.open(
            `${this.docTypeSelected.name} requires only two attachments.`,
          );
        } else {
          //@ts-ignore
          const file: File[] = e.target.files;
          for (let i = 0; i < file.length; i++) {
            const index = i;
            if (this.docFile.length && this.docFile[i].name === file[i].name) {
              this.snackbar.open(`File already selected with this name`);
              return;
            }
            this.customerDocFile?.push(file[i]);
            reader.onload = (event: any) => {
              this.isFileSelected = true;
              this.docFile?.push({
                type: file[index].type,
                name: file[index].name,
                url: event.target.result,
              });
            };
            reader.readAsDataURL(file[i]);
          }
        }
      } else {
        if (
          this.customerDocFile!.length > 0 &&
          this.docFile.length >= this.numberOfAttachmentsRequired
        ) {
          this.snackbar.open(
            `${this.docTypeSelected.name} requires only ${
              !this.numberOfAttachmentsRequired
                ? this.numberOfAttachmentsRequired + 1
                : this.numberOfAttachmentsRequired
            } file attachment.`,
          );
        } else {
          //@ts-ignore
          const file = e.target.files[0];
          this.customerDocFile?.push(file);
          reader.onload = (event: any) => {
            this.isFileSelected = true;
            this.docFile?.push({
              type: file.type,
              name: file.name,
              url: event.target.result,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }

  onSubmit() {

    if (!this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.documentForm.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]',
          );
          invalidControl.focus();
          break;
        }
      }
      return;
    }

    if (this.form.valid) {
      // @ts-ignore
      if (this.customerDocFile?.length < this.numberOfAttachmentsRequired) {
        this.snackbar.open(
          `${this.numberOfAttachmentsRequired} attachments required.`,
        );
        return;
      }
      if (!this.customerDocFile) {
        this.isFileSelected = false;
      } else {
        this.dialogRef.close({
          docData: this.prepareDocumentPayload(
            this.form.value,
            this.customerId,
          ),
          docAttachments: this.customerDocFile,
        });
      }
    }
  }

  checkDocumentAttachment(event: any) {
    this.docFile = [];
    this.customerDocFile = [];
    this.selectedDoctype = event.value;

    this.requiredFields = JSON.parse(this.selectedDoctype?.requiredFields || '{}');
    this.isPassportDocTypeSelected = this.selectedDoctype?.name?.trim()?.toLowerCase() == 'passport';

    if(this.requiredFields?.idNumberIsRequired) {
      this.form.controls['idNumber']?.enable();
      this.form.get('idNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);
      this.form.get('primaryDocNumber')?.updateValueAndValidity();
      this.form.controls['primaryDocNumber']?.patchValue('');
    } else {
      this.form.controls['idNumber']?.patchValue('');
      this.unsetFieldRequired('idNumber');
    }

    if(this.requiredFields?.serialNumberIsRequired) {
      this.form.get('serialNumber')?.enable();
      this.setFieldRequired('serialNumber');
      this.form.get('serialNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);

      this.form.controls['primaryDocNumber']?.patchValue('');
    } else {
      this.form.controls['serialNumber']?.patchValue('');
      this.unsetFieldRequired('serialNumber');
    }

    if(!this.requiredFields?.issueDateIsRequired) {
      this.unsetFieldRequired('docIssueDate');
    } else {
      this.setFieldRequired('docIssueDate');
    }
    if(!this.requiredFields?.expiryDateIsRequired) {
      this.unsetFieldRequired('docExpiryDate');
    } else {
      this.setFieldRequired('docExpiryDate')
    }

    if(!this.requiredFields?.documentIssuerIsRequired) {
      this.unsetFieldRequired('docIssuedBy');
    } else {
      this.setFieldRequired('docIssuedBy');
    }

    if(!this.requiredFields?.documentNumberIsRequired) {
      this.unsetFieldRequired('docNumber');
    } else {
      this.form.get('docNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength),
      ]);
      this.form.get('docNumber')?.updateValueAndValidity();
    }
    if(this.requiredFields?.motherNameIsRequired) {
      this.form.controls['motherName']?.enable();
      this.form.get('motherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('motherName')?.updateValueAndValidity();
    } else {
      this.form.controls['motherName']?.patchValue('');
      this.unsetFieldRequired('motherName');
    }

    if(this.requiredFields?.nationalityIsRequired) {
      this.form.controls['nationality']?.enable();
      this.form.get('nationality')?.setValidators([
        Validators.required
      ]);
      this.form.get('nationality')?.updateValueAndValidity();
    } else {
      this.form.controls['nationality']?.patchValue('');
      this.unsetFieldRequired('nationality');
    }

    if(this.requiredFields?.fatherNameIsRequired) {
      this.form.controls['fatherName']?.enable();
      this.form.get('fatherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('fatherName')?.updateValueAndValidity();
    } else {
      this.form.controls['fatherName']?.patchValue('');
      this.unsetFieldRequired('fatherName');
    }
       this.numberOfAttachmentsRequired = 0;
    if (this.selectedDoctype?.isFirstAttachmentRequired)
      this.numberOfAttachmentsRequired = 1;
    if (this.selectedDoctype?.isSecondAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
    if (this.selectedDoctype?.isThirdAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
  }

  prepareDocumentPayload(
    formValues: any,
    customerId: number,
  ): CustomerDocumentDto {
    return {
      id: 0,
      customerId,
      documentTypeId: formValues['docType'].id,
      documentDetails: {
        number: formValues['docNumber'],
        issueDate: formValues['docIssueDate'] || null,
        expiryDate: formValues['docExpiryDate'] || null,
        issuedBy: formValues['docIssuedBy'] || null,
        idNumber: formValues[`idNumber`] || null,
        serialNumber: formValues[`serialNumber`] || null,
        requiredFields: this.selectedDoctype?.requiredFields,
        motherName : formValues[`motherName`] || null,
        fatherName : formValues[`fatherName`] || null,
        nationality : formValues[`receiverNationality`]?.id || null
      },
    };
  }

  clearSelectedDocAttachment(file: any) {
    this.customerDocFile = this.customerDocFile?.filter(
      (list) => list.name !== file.name,
    );
    this.docFile = this.docFile?.filter((list) => list.name !== file.name);
    this.docFileElem.nativeElement.value = null;
  }

  setFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.setValidators([Validators.required]);
    ctrl.updateValueAndValidity();
  }

  unsetFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
  }

  onFocusOut(event: any) {
    const regexLiteral = this.selectedDoctype.regularExpression;

    if (regexLiteral) {
      const [, pattern, flags] = regexLiteral?.match(/\/(.*)\/(.*)/);
      const regex = new RegExp(pattern, flags);
      if (!regex.test(event?.target?.value)) {
        this.form.get('docNumber')?.setErrors({invalid: true});
      } else {
        this.form.get('docNumber')?.setErrors(null);
      }
    }
  }
}
