import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BranchesService } from './data/branches.service';
import { BranchCurrencyDto } from './data/dto/branches.dto';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { regExp } from '../_common/data/validationRules';

type PopupData = {
  branchId: number;
  branchCurrency?: BranchCurrencyDto;
  currencies: LookupDto[];
  exchangeRateTypes: LookupDto[];
};

@Component({
  selector: 'app-branch-currency',
  templateUrl: './branchCurrencyPopup.component.html',
})
export class BranchCurrencyPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  currencies: LookupDto[] = [];
  exchangeRateTypes: LookupDto[] = [];

  branchCurrency?: BranchCurrencyDto;
  branchId: number = 0;

  constructor(
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<BranchCurrencyPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: PopupData,
  ) {
    this.branchId = data.branchId;
    this.branchCurrency = data.branchCurrency;
    this.currencies = data.currencies;
    this.exchangeRateTypes = data.exchangeRateTypes;
    if (this.branchCurrency) {
      this.addMode = false;
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      currencyId: [this.branchCurrency?.currencyId, [Validators.required]],
      exchangeRateTypeId: [
        this.branchCurrency?.exchangeRateTypeId,
        [Validators.required],
      ],
      rateVariation: [
        this.branchCurrency?.rateVariation,
        [Validators.required, Validators.pattern(regExp.decimalNumber)],
      ],
      normRate: [
        this.branchCurrency?.normRate,
        [Validators.required, Validators.pattern(regExp.decimalNumber)],
      ],
      isActive: [this.branchCurrency?.isActive],
      comments: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(200),
          Validators.pattern(regExp.alphaNumericSpecialCharacters),
        ],
      ],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const branchCurrency: BranchCurrencyDto = {
        branchId: this.branchId,
        currencyId: formValues.currencyId,
        exchangeRateTypeId: formValues.exchangeRateTypeId,
        rateVariation: +formValues.rateVariation,
        normRate: +formValues.normRate,
        isActive: formValues.isActive || false,
        comments: formValues.comments,
      };

      this.branchesService.createBranchCurrencies(branchCurrency).subscribe(
        (resp) => {
          //@ts-ignore
          this.snackBar.open(resp?.message);
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }
}
