import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { AuthService } from '../../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { RoleRequestApprovalPopupComponent } from './roleRequestApprovalPopup.component';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { Utilities } from '../../_common/utils';
import { RoleRequestFilter, RolesService } from '../data/roles.service';
import { SearchRoleRequestsDto } from '../data/dto/role/roles.dto';
import {SnackBarService} from "../../_common/snackBar.service";

@Component({
  selector: 'app-role-requests',
  templateUrl: './roleRequests.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class RoleRequestsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.userManagement.roleRequests;
  resultsLength = 0;
  loading = false;
  userTypes: LookupDto[] = [];
  displayColumns: string[] = [
    'number',
    'oldUserType',
    'newUserType',
    'oldName',
    'newName',
    'oldDescription',
    'newDescription',
    'status',
    'requestDate',
    'statusChangeDate',
    'statusChangeComments',
    'actions',
  ];
  data = new MatTableDataSource<SearchRoleRequestsDto>();
  statuses: LookupDto[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private roleService: RolesService,
    public authService: AuthService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ userTypes, statuses }) => {
      this.userTypes = userTypes;
      this.statuses = statuses;
    });

    this.form = this.fb.group({
      userType: [null],
      status: [null],
    });
  }

  onSubmit() {
    const { userType, status } = this.form.value;
    const filters: RoleRequestFilter = {};

    if (userType) filters.userTypeId = userType.id;
    if (status) filters.statusId = status.id;

    this.roleService.getRoleRequests(filters).subscribe((data)=>{
      this.data.data = data;
      this.data.paginator = this.paginator;
      this.resultsLength = data.length;
    }, error => {
      this.snackBar.open(error.message);
    })
  }

  onRoleRequestAction(id: number) {
    this.roleService.getRoleRequest(id).subscribe((roleRequest)=>{
      const dialogRef = this.dialog.open(RoleRequestApprovalPopupComponent, {
        width: '1000px',
        disableClose: true,
        data: roleRequest,
        panelClass: 'dialog-content',
      });

      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') return;

        if (resp.success) this.onSubmit();
      });
    })
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
