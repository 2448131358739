import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remittance-blocking',
  templateUrl: './remittanceUnblockingPopup.component.html',
})
export class RemittanceUnblockingPopupComponent {
  comments: string = '';

  constructor(
    public dialogRef: MatDialogRef<RemittanceUnblockingPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: '',
  ) {}
}
