import { Component, OnInit, ViewChild } from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { BranchesService } from '../branch/data/branches.service';
import {
  ComplianceReportDetailFilter,
  ComplianceReportFilter,
  ReportsService,
} from './data/reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { ComplianceReportDto } from './data/dto/reports.dto';
import { MatDialog } from '@angular/material/dialog';
import { ComplianceReportPopupComponent } from './complianceReportPopup.component';
import { SnackBarService } from '../_common/snackBar.service';
import { ActivatedRoute } from '@angular/router';
import { Utilities } from '../_common/utils';
import moment from "moment";

@Component({
  selector: 'app-compliancereport',
  templateUrl: './complianceReport.component.html',
})
export class ComplianceReportComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  branches: any[] = [];
  dataThere: boolean = false;
  selected: any;
  displayedColumns: string[] = ['number', 'date', 'numberOfRemittances'];
  data = new MatTableDataSource<ComplianceReportDto>();
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);
  allOption = {
    id: 'all',
    code: 'All',
    title: 'Branches',
  };

  constructor(
    private fb: UntypedFormBuilder,
    private branchesService: BranchesService,
    private reportService: ReportsService,
    private dialog: MatDialog,
    private snackBar: SnackBarService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(({ branches }) => {
      this.branches = branches;
    });

    this.form = this.fb.group({
      // branch: [this.branches.length === 1 ? this.branches[0] : this.allOption],
      startDate: this.fromDate,
      endDate: this.toDate,
    });
  }

  onSubmit() {
    if (this.form.valid) {
      // const branchId = this.form.value.branch.id;
      const { startDate, endDate } = this.form.value;
      const filter: ComplianceReportFilter = {
        // branchId: branchId === this.allOption.id ? null : branchId,
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate:   moment(endDate).format("YYYY-MM-DD")
      };

      this.reportService.getComplianceReport(filter).subscribe(
        (resp) => {
          if (resp) {
            this.dataThere = true;
          }
          this.data.data = [resp];
          this.data.paginator = this.paginator;
        },
        ({ message }) => {
          this.snackBar.open(message);
          this.data.data = [];
        },
      );
    }
  }

  displayComplianceReportDetail(id: []) {
    const filter: ComplianceReportDetailFilter = {
      transactionIds: id,
    };
    this.reportService.getComplianceReportDetail(filter).subscribe((resp) => {
      const dialogRef = this.dialog.open(ComplianceReportPopupComponent, {
        data: { data: resp },
        width: '1000px',
        maxHeight: '90vh',
      });

      dialogRef.afterClosed().subscribe((resp) => {
        if (resp === 'close') {
          return;
        }
      });
    });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
