import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ApiResponseDto } from './dto/api.dto';
import { LoaderService } from '../components/loader/loader.service';

type Request = {
  method: 'post' | 'get';
  url: string;
  body?: any;
  params?: any;
  headers?: any;
};

@Injectable({ providedIn: 'root' })
export class HttpService {
  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  private handleError =
    (backgroundRequest: boolean) => (error: HttpErrorResponse) => {
      if (!backgroundRequest) this.loaderService.hideLoader();

      //handle 400, 403, 500
      if (error.status === 401) {
        if (
          error.url?.includes('auth/agent/login') ||
          error.url?.includes('auth/forgotpassword') ||
          error.url?.includes('auth/login')
        ) {
          console.error(error);
          return throwError(
            new Error(error.error?.message || 'invalidCredentials'),
          );
        }
        window.location.href = '/login';
      }
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, body was: `,
          error.error,
        );
      }

      // Return an observable with a user-facing error message.
      // @ts-ignore
      return throwError(
        new Error(
          error.error?.message || 'somethingWentWrong',
          // @ts-ignore
          { cause: error },
        ),
      );
    };

  private processRequest<T>({ method, url, body, params, headers }: Request) {
    const backgroundRequest = !!params?.__backgroundRequest ||
    url.includes('customer/unverifiedsummary') ||
    url.includes('customer/verifiedsummary')  || 
    url.includes('customer/rejectedsummary') ||
    url.includes('customer/totalsummary') ||
    url.includes('remittance/sendingsummary') ||
    url.includes('remittance/cancelledsummary') ||
    url.includes('remittance/pendingsummary') ||
    url.includes('remittance/pendingapprovalsummary') ||
    url.includes('remittance/returnedsummary') ||
    url.includes('remittance/paidsummary') ||
    url.includes('referals/referalcustomercount') ||
    url.includes('referals/referalremittancecount') ||
    url.includes('referals/referalcommission') ||
    url.includes('referals/referalbalancecommission');


    delete params?.__backgroundRequest;

    const options = {
      params,
    } as { [key: string]: any };
    if (!(body instanceof FormData)) {
      options['headers'] = new HttpHeaders({
        'Content-Type': 'application/json',
        ...headers,
      });
    }

    const uri = `${environment.apiBaseUrl}${url}`;
    const processor =
      method === 'get'
        ? this.http.get<ApiResponseDto<T>>(uri, options)
        : this.http.post<ApiResponseDto<T>>(uri, body, options);

    if (!backgroundRequest) this.loaderService.showLoader();
    return processor.pipe(
      catchError(this.handleError(backgroundRequest)),
      map((resp) => {
        if (!backgroundRequest) this.loaderService.hideLoader();

        const { data } = resp;

        if(data) {
          // @ts-ignore
          data.message = resp?.message;
        }

        return data;
      }),
    );
  }

  private processAttachmentRequest(url: string, params: any) {
    const uri = `${environment.apiBaseUrl}${url}`;

    const options = {
      params,
    } as { [key: string]: any };
    options['responseType'] = 'blob';

    const processor = this.http.get(uri, options);
    this.loaderService.showLoader();
    return processor.pipe(
      catchError(this.handleError(true)),
      map((resp) => {
        this.loaderService.hideLoader();
        return resp as Blob;
      }),
    );
  }

  public get<T>(url: string, params: any = null): Observable<T> {
    return this.processRequest({
      method: 'get',
      url,
      params,
    });
  }

  public getAttachment(url: string, params: any = null) {
    return this.processAttachmentRequest(url, params);
  }

  public post<T>(
    url: string,
    body: any,
    params: any = null,
    headers: any = null,
  ): Observable<T> {
    return this.processRequest({
      method: 'post',
      url,
      body,
      params,
      headers,
    });
  }
}
