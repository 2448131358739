import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerFilter, ReferalService } from './data/referl.service';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-customers',
  templateUrl: './ormrecuirtercustomer.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class ormRecuirterCustomersComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ormRecuirterCode: string | null = null;
  startDate: Date | null = null;
  endDate: Date | null = null;
  limit: number | undefined;
  permissions = Permissions.REFERAL_MANAGEMENT.referals;
  loading = false;
  resultsLength = 0;
  displayedColumns: string[] = [
    'number',
    'fullName',
    'mobile',
    'customerStatus',
    'recruiterCode',
    'dateRecruited',
  ];
  data: any = new MatTableDataSource<any>();
  constructor(
    private fb: UntypedFormBuilder,
    private referlService: ReferalService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data_: any,
  ) {
    this.ormRecuirterCode = data_?.ormRecuirterCode || null;
    this.startDate = data_?.startDate || null;
    this.endDate = data_?.endDate || null;
    this.limit = data_?.limit || null;
  }

  ngOnInit() {
    this.loadData();
  }
  loadData() {
    const filter: CustomerFilter = {
      ormRecuirterCode: this.ormRecuirterCode || undefined,
      startDate: this.startDate ? this.startDate : null,
      endDate: this.endDate ? this.endDate : null,
      limit: this.limit
    };

    this.referlService.customers.subscribe(
      ({ customers, appliedFilters }) => {
        this.loading = false;
        this.data.data = customers;
        this.data.paginator = this.paginator;
        this.resultsLength = customers.length;
      },
    );

    this.referlService.getCustomers(filter);
  }

    convertDateToLocaleString(date: Date) {
      return Utilities.convertDateToLocaleString(date, '');
    }
}
