import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { ComplianceConfigurationService } from '../data/complianceConfiguration.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/data/auth.service';
import {
  CreateComplianceConfigDto,
  GetComplianceConfigDto,
} from '../data/dto/complianceConfiguration.dto';
import { SnackBarService } from '../../_common/snackBar.service';

@Component({
  selector: 'app-compliance-configuration-details',
  templateUrl: './complianceConfigurationDetails.component.html',
})
export class ComplianceConfigurationDetailsComponent implements OnInit {
  form!: UntypedFormGroup;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;

  complianceTypes: LookupDto[] = [];

  permissions = Permissions.complianceManagement.complianceConfigurations;

  complianceConfig?: GetComplianceConfigDto;
  addMode = true;
  title = '';

  showAmount = false;
  showDuration = false;
  showTransactionCount = false;

  constructor(
    private fb: UntypedFormBuilder,
    private complianceConfigService: ComplianceConfigurationService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({ complianceTypes, complianceConfigs, addMode }) => {
        this.addMode = addMode;
        this.complianceTypes = complianceTypes;
        if (complianceConfigs?.length) {
          this.complianceConfig = complianceConfigs[0];
        }

        this.form = this.fb.group({
          remittanceComplianceTypeId: [
            this.complianceConfig?.remittanceComplianceTypeId,
            [Validators.required],
          ],
          // @ts-ignore
          amount: [this.complianceConfig?.value?.amount],
          // @ts-ignore
          duration: [this.complianceConfig?.value?.duration],
          // @ts-ignore
          transactionCount: [this.complianceConfig?.value?.transactionCount],
          primaryDocRequired: [this.complianceConfig?.primaryDocRequired],
          secondaryDocRequired: [this.complianceConfig?.secondaryDocRequired],
          isAttachmentRequired: [this.complianceConfig?.isAttachmentRequired],
          additionalInfoRequired: [
            this.complianceConfig?.additionalInfoRequired,
          ],
          branchApprovalRequired: [
            this.complianceConfig?.branchApprovalRequired,
          ],
          parentBranchApprovalRequired: [
            this.complianceConfig?.parentBranchApprovalRequired,
          ],
          cpoBranchApprovalRequired: [
            this.complianceConfig?.cpoBranchApprovalRequired,
          ],
        });

        if (this.addMode) {
          this.title = 'addComplianceConfig';
        } else if (
          this.authService.isUserAuthorized(
            this.permissions.UPDATE_COMPLIANCE_CONFIG,
          )
        ) {
          this.title = 'updateComplianceConfig';
        } else {
          this.title = 'viewComplianceConfig';
          this.form.disable();
        }

        if (!this.addMode) {
          this.onComplianceTypeChange(
            this.complianceConfig?.remittanceComplianceTypeId as number,
          );
        }
      },
    );
  }

  onComplianceTypeChange(complianceTypeId: number, resetValues = false) {
    const complianceType = this.complianceTypes.find(
      (ct) => ct.id === complianceTypeId,
    );
    const amountCtrl = this.form.get('amount');
    const durationCtrl = this.form.get('duration');
    const transactionCountCtrl = this.form.get('transactionCount');

    amountCtrl?.clearValidators();
    durationCtrl?.clearValidators();
    transactionCountCtrl?.clearValidators();

    if (resetValues) {
      amountCtrl?.setValue(null);
      durationCtrl?.setValue(null);
      transactionCountCtrl?.setValue(null);
    }

    this.showAmount = false;
    this.showDuration = false;
    this.showTransactionCount = false;

    switch (complianceType?.name) {
      case 'Threshold limit':
      case 'Large transaction':
        amountCtrl?.setValidators([Validators.required]);
        amountCtrl?.updateValueAndValidity();
        this.showAmount = true;
        break;
      case 'Threshold structuring':
      case 'Large transaction structuring':
        amountCtrl?.setValidators([Validators.required]);
        durationCtrl?.setValidators([Validators.required]);
        amountCtrl?.updateValueAndValidity();
        durationCtrl?.updateValueAndValidity();
        this.showAmount = true;
        this.showDuration = true;
        break;
      case 'Recurrent transactions':
        transactionCountCtrl?.setValidators([Validators.required]);
        durationCtrl?.setValidators([Validators.required]);
        transactionCountCtrl?.updateValueAndValidity();
        durationCtrl?.updateValueAndValidity();
        this.showTransactionCount = true;
        this.showDuration = true;
        break;
      default:
        break;
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const { value: formData } = this.form || {};
      const payload: CreateComplianceConfigDto = {
        remittanceComplianceTypeId: formData.remittanceComplianceTypeId,
        primaryDocRequired: formData.primaryDocRequired || false,
        secondaryDocRequired: formData.secondaryDocRequired || false,
        isAttachmentRequired: formData.isAttachmentRequired || false,
        additionalInfoRequired: formData.additionalInfoRequired || false,
        branchApprovalRequired: formData.branchApprovalRequired || false,
        parentBranchApprovalRequired:
          formData.parentBranchApprovalRequired || false,
        cpoBranchApprovalRequired: formData.cpoBranchApprovalRequired || false,
        isApplicableOnSender: true,
        isApplicableOnReceiver: true,
      };

      const complianceType = this.complianceTypes.find(
        (ct) => ct.id === payload.remittanceComplianceTypeId,
      );
      switch (complianceType?.name) {
        case 'Threshold limit':
        case 'Large transaction':
          payload.value = {
            amount: formData.amount,
          };
          break;
        case 'Threshold structuring':
        case 'Large transaction structuring':
          payload.value = {
            amount: formData.amount,
            duration: formData.duration,
          };
          break;
        case 'Recurrent transactions':
          payload.value = {
            transactionCount: formData.transactionCount as number,
            duration: formData.duration as number,
          };
          break;
        case 'Sanctioned entities match':
          payload.value = {
            checkSanctionedEntities: true,
          };
          break;
        default:
          break;
      }

      const handler = this.complianceConfig
        ? this.complianceConfigService.updateComplianceConfig({
            id: this.complianceConfig.id,
            ...payload,
          })
        : this.complianceConfigService.createComplianceConfig(payload);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          if (this.addMode) {
            this.resetBtn?.nativeElement.click();
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }
}
