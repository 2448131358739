export const regExp = {
  alpha: /^[A-Za-z\u00C0-\u00FF]+$/,
  alphaSpace: /^[a-zA-ZçÇğĞıİöÖşŞüÜ\u00C0-\u00FF ]+$/,
  numeric: /^[0-9]+$/,
  numericSpace: /^[0-9 ]+$/,
  alphaNumeric: /^[A-Z\u00C0-\u00FF]+$/,
  capAlphaNumeric: /^[A-Z][0-9]+$/,
  alphaNumericSpace: /^[A-Za-z\u00C0-\u00FF0-9 ]+$/,
  alphaNumericMinuSpace: /^[A-Za-z\u00C0-\u00FF0-9_-]+$/,
  alphaNumericSymbols: /^[A-Za-z\u00C0-\u00FF0-9 !@#$^*{}\[\],\-=.\/\\?_+|]+$/,
  // alphaUnicodeSpace: /^[A-Za-z\u00C0-\u00FF ]+$/, // accept all these latin characters = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏàáâãäåæçèéêëìíîïÐÑÒÓÔÕÖØÙÚÛÜÝÞßðñòóôõöøùúûüýþÿ',
  // alphaNumericUnicodeSpace: /^[A-Za-z0-9\u00C0-\u00FF ]+$/,
  // alphaNumericUnicodeSymbols: /^[A-Za-z0-9\u00C0-\u00FF !@#$^*{}\[\],\-=.\/\\?_+|]+$/,
  ip: /^\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b$/,
  emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  decimalNumber: /^\d*\.?\d*$/,
  mobile: /^[1-9][0-9]{9,12}$/,
  alphaNumericSpecialCharacters: /^[a-zA-ZçÇğĞıİöÖşŞüÜ\u00C0-\u00FF0-9.,$()%@! ]+$/
};
