<h1 mat-dialog-title>{{ title | translate }}</h1>
<div mat-dialog-content>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <div class="col-md-3 mb-4">
        <div appearance="outline" class="col pt-2">
          <mat-label>{{ 'uploadAttachment' | translate }}</mat-label>
          <br>
          <input type="file" style="display: none" accept=".png, .jpg, .jpeg, .pdf"
                 (change)="onRemittanceReceiptDocAttachmentSelected($event)" #remittanceReceiptFile />
          <button mat-mini-fab color="primary" class="mx-1" type="button" class="mt-3"
                  (click)="remittanceReceiptFileUploadElem.nativeElement.click()">
            <mat-icon>attach_file</mat-icon>
          </button>
          <mat-label *ngFor="let file of remittanceReceiptDocs">
          </mat-label>
        </div>

        <div class="col-md-6">
          <mat-label *ngFor="let file of remittanceReceiptDocs">
            <div *ngIf="file && file?.type !== 'application/pdf'">
              <img class="img-thumbnail" [src]="file.url" style="width: auto; height: 100px"
                   (click)="onViewAttachment(file.url)" />
              <mat-icon (click)="clearSelectedRemittanceDocAttachment()">cancel</mat-icon>
            </div>

            <div *ngIf="file && file?.type === 'application/pdf'">
              <mat-icon style="font-size: 50px; color: gray; margin-right: 5px; margin-top:10px"
                        (click)="onViewAttachment(file.url)">picture_as_pdf</mat-icon>
              <mat-icon (click)="clearSelectedRemittanceDocAttachment()">cancel</mat-icon>
            </div>

          </mat-label>
        </div>

      </div>
    </div>

  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">
    {{ 'close' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'update' | translate }}
  </button>
</div>
