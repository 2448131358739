import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';
import { AuthService } from 'src/app/auth/data/auth.service';
import { GetLoginHistoryDto } from '../data/dto/loginHistory/loginHistory.dto';
import { LoginHistoryFilter, UsersService } from '../data/users.service';
import {SnackBarService} from "../../_common/snackBar.service";

@Component({
  selector: 'app-login-histories',
  templateUrl: './loginHistory.component.html',
})
export class LoginHistoryComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  username = '';

  permissions = Permissions.userManagement.loginHistories;
  resultsLength = 0;
  displayedColumns: string[] = [
    'username',
    'date',
    'status',
  ];
  data = new MatTableDataSource<GetLoginHistoryDto>();
  statuses!: LookupDto[];
  date = new UntypedFormControl(new Date());

  constructor(
    private fb: UntypedFormBuilder,
    private usersService: UsersService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private snackBar: SnackBarService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ statuses }) => {

      this.statuses = statuses;

      this.form = this.fb.group({
        date: this.date,
        status: [null],
      });
    });
  }

  onSubmit() {
    const { status, date } = this.form.value;

    const filters: LoginHistoryFilter = {};
    if (status) filters.statusId = status.id;
    if (date) filters.date = date;

    this.usersService.searchLoginHistories(filters).subscribe((data)=>{
      this.data.data = data;
      this.data.paginator = this.paginator;
      this.resultsLength = data.length;
    }, ({ message }) => {
      this.snackBar.open(message);
    });
  }
}
