import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
})
export class LoaderComponent implements OnInit {
  public showLoader: boolean = false;

  constructor(private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.loaderService.show.subscribe((showLoader) => {
      this.showLoader = showLoader;
      this.cdRef.detectChanges();
    });
  }
}
