import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-declaration',
  templateUrl: './sendRemittanceDeclaration.component.html',
  styleUrls: ['./SendRemittanceDeclaration.component.css'],
})
export class SendRemittanceDeclarationComponent {
  name: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string }) {
    this.name = data.name;
  }

  ngOnInit() {
    console.log(`Received name: ${this.name}`);
  }
}
