<table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>

    <ng-container matColumnDef="documentNumber">
        <th mat-header-cell *matHeaderCellDef>{{ 'documentNumber' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.documentDetails.number}}</td>
    </ng-container>

    <ng-container matColumnDef="partnerNumber">
        <th mat-header-cell *matHeaderCellDef>{{ 'partnerNumber' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.partnerNumber}}</td>
    </ng-container>

    <ng-container matColumnDef="partnerName">
        <th mat-header-cell *matHeaderCellDef>{{ 'partnerName' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
    </ng-container>

    <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef>{{ 'documentType' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
    </ng-container>

    <ng-container matColumnDef="issuedBy">
        <th mat-header-cell *matHeaderCellDef>{{ 'issuedBy' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.documentDetails.issuedBy}} </td>
    </ng-container>


    <ng-container matColumnDef="issuedDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'issuedDate' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.issueDate}} </td>
    </ng-container>


    <ng-container matColumnDef="expiryDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'expiryDate' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.expiryDate}} </td>
    </ng-container>


    <ng-container matColumnDef="dateCreated">
        <th mat-header-cell *matHeaderCellDef>{{ 'dateCreated' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
    </ng-container>


    <ng-container matColumnDef="idVerificationStatus">
        <th mat-header-cell *matHeaderCellDef>{{ 'idVerificationStatus' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.ormIdVerificationState}}</td>
    </ng-container>


    <ng-container matColumnDef="partnerStatus">
        <th mat-header-cell *matHeaderCellDef>{{ 'partnerStatus' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.ormIdVerificationPartnerState}}</td>
    </ng-container>


    <ng-container matColumnDef="Customerstatus">
        <th mat-header-cell *matHeaderCellDef>{{ 'Customer status' | translate }}</th>
        <td mat-cell *matCellDef="let element"> {{element.customerStatus}} </td>
    </ng-container>


    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <div class="action-menu">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="action-menu-item">
                    <a  
                        (click)="element.customerStatus !== 'verified' && openPopupCommentDialog(element.customerId, 2)"
                        [disabled]="element.customerStatus === 'verified'" 
                        mat-menu-item class="primary">
                        <mat-icon>verified</mat-icon>
                        {{ 'verify' | translate }}
                    </a>
                    <a  
                        (click)="element.customerStatus !== 'rejected' && openPopupCommentDialog(element.customerId, 3)"
                        [disabled]="element.customerStatus === 'rejected'" 
                        mat-menu-item class="primary">
                        <mat-icon>cancel</mat-icon>
                        {{ 'reject' | translate }}
                    </a>
                    <a mat-menu-item class="primary" (click)="onDocumentViewAttachment(element.customerSelfieImage ,element.documentBase64, element.ormIdVerificationResultImage)">
                        <mat-icon>attach_file</mat-icon>
                        {{ 'view attachment' | translate }}
                    </a>
                </mat-menu>
            </div>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>