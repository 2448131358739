import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '../../_common/data/http.service';
import { CRUDResponseDto, LookupDto } from '../../_common/data/dto/api.dto';
import { TicketDto, SearchTicketDto } from './dto/support.dto';
import { TableTypes } from 'src/app/_common/enums/tableTypes.enums';

type TicketFilter = {
  branchId?: string;
  assigneeId?: string;
  statusId?: [];
  onlyMyTickets?: boolean
} | null;

type GetTickets = {
  appliedFilters: TicketFilter;
  tickets: SearchTicketDto[];
};

@Injectable()
export class SupportService {
  private tablePendingChanges: string = '';
  private _tickets = new BehaviorSubject<GetTickets>({
    appliedFilters: null,
    tickets: [],
  });
  private ticketsObservable?: Observable<GetTickets>;
  constructor(private httpService: HttpService) {}

  get tickets() {
    if (!this.ticketsObservable)
      this.ticketsObservable = this._tickets.pipe(
        map((resp) => {
          if (this.tablePendingChanges === TableTypes.TICKETS) {
            this.searchTickets(resp.appliedFilters);
          }
          return resp;
        }),
      );

    return this.ticketsObservable;
  }

  searchTickets(appliedFilters: TicketFilter) {
    return this.httpService
      .get<SearchTicketDto[]>('support/ticket/list', appliedFilters)
      .subscribe((tickets) => {
        this.tablePendingChanges = '';
        this._tickets.next({
          tickets,
          appliedFilters,
        });
      });
  }

  // the default status should be 'open'
  createTicket(dto: TicketDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('support/ticket/create', dto)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.TICKETS;
          return resp;
        }),
      );
  }

  updateTicket(dto: TicketDto): Observable<CRUDResponseDto> {
    return this.httpService
      .post<CRUDResponseDto>('support/ticket/update', dto)
      .pipe(
        map((resp) => {
          this.tablePendingChanges = TableTypes.TICKETS;
          return resp;
        }),
      );
  }

  getTicketById(id: number): Observable<TicketDto> {
    return this.httpService.get<TicketDto>('support/ticket', { id });
    // return of({
    //   id: 1,
    //   title: "This is test",
    //   description: "Please fix this",
    //   branchId: 2,
    //   assigneeId: 2,
    //   assigneeName: "Muneeb",
    //   reportedBy: 'Rizwan',
    //   statusId: 1,
    //   history: [
    //     'rizwan created ticket at Sun 10, November, 2022 11:00AM',
    //     'muneeb assigned ticket to muneeb at Sun 10, November, 2022 11:00AM',
    //     "muneeb changed the status to 'in progress' from resolved at Sun 10, November, 2022 11:00AM"
    //   ],
    //   comments: [
    //     {
    //       comment: "Why do we need this ticket",
    //       username: "Muneeb",
    //       datetime: "Sun 10, November, 2022 11:00AM"
    //     },
    //     {
    //       comment: "Because we need to fix this",
    //       username: "Rizwan",
    //       datetime: "Sun 10, November, 2022 11:00AM"
    //     }
    //   ]
    // } as TicketDto)
  }

  addComment(comment: string, ticketId: number): Observable<CRUDResponseDto> {
    return this.httpService.post<CRUDResponseDto>(
      'support/ticket/comment/add',
      { ticketId, comment },
    );
  }

  getStatus(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('support/ticket/status/list');
  }

  // this method should return all the branches in users top and bottom hierarchy
  getBranches(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('support/ticket/branch/list');
  }

  // this method should return all the users from users top and bottom hierarchy
  // the name should be user's fullname and branch code ordered by branch code
  getAssignees(): Observable<LookupDto[]> {
    return this.httpService.get<LookupDto[]>('support/ticket/assignee/list');
  }
}
