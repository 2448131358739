import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { BranchDetailsComponent } from './branchDetails.component';
import { BranchesComponent } from './branches.component';

import {
  BranchByIdResolver,
  CountriesResolver,
  BranchParentsResolver,
  BranchTypesResolver,
  BranchesResolver,
  RateChangeStatuses,
  BranchGroupsResolver,
  AccountByIdResolver,
  LimitChangeStatuses,
  CommissionChangeStatuses,
  BranchCommissionPoliciesResolver,
  ManualJournalStatuses,
} from './data/branches.resolver';
import { RateChangeRequestsComponent } from './rateChangeRequests.component';
import { LimitChangeRequestsComponent } from './limitChangeRequests.component';
import { AccountsComponent } from './accounts.component';
import { AccountDetailsComponent } from './accountDetails.component';
import { CommissionChangeRequestsComponent } from './commissionChangeRequests.component';
import { BrancheCommissionsComponent } from './branchCommissions.component';
import { ManualJournalsComponent } from './manualJournals.component';
import { ManualJournalDetailsComponent } from './manualJournalDetails.component';

const {
  branchManagement: {
    branches,
    rateChangeRequests,
    limitChangeRequests,
    commissionChangeRequests,
    branchCommissions,
  },
  accountsManagement: { accounts },
  manualJournals: { journals },
} = Permissions;

const routes: Routes = [
  {
    path: 'branches',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(branches)] },

        component: BranchesComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        data: { permissions: [branches.CREATE_BRANCH], addMode: true },
        resolve: {
          branchTypes: BranchTypesResolver,
          countries: CountriesResolver,
          branchParents: BranchParentsResolver,
          branchCommissionPolicies: BranchCommissionPoliciesResolver,
        },
        component: BranchDetailsComponent,
      },
      {
        path: 'view/:branchId',
        canActivate: [AuthGuard],
        data: {
          permissions: [branches.VIEW_BRANCH, branches.UPDATE_BRANCH],
          addMode: false,
        },
        resolve: {
          branchTypes: BranchTypesResolver,
          countries: CountriesResolver,
          branchParents: BranchParentsResolver,
          branches: BranchByIdResolver,
          branchCommissionPolicies: BranchCommissionPoliciesResolver,
        },
        component: BranchDetailsComponent,
      },
      {
        path: 'branchCommissions',
        canActivate: [AuthGuard],
        data: { permissions: [branchCommissions.VIEW_BRANCH_COMMISSIONS] },
        resolve: { branches: BranchesResolver },
        component: BrancheCommissionsComponent,
      },
      {
        path: 'rateChangeRequests',
        canActivate: [AuthGuard],
        data: {
          permissions: [rateChangeRequests.VIEW_BRANCH_RATE_CHANGE_REQUESTS],
        },
        resolve: {
          branches: BranchesResolver,
          rateChangeStatuses: RateChangeStatuses,
        },
        component: RateChangeRequestsComponent,
      },
      {
        path: 'limitChangeRequests',
        canActivate: [AuthGuard],
        data: {
          permissions: [limitChangeRequests.VIEW_BRANCH_LIMIT_CHANGE_REQUESTS],
        },
        resolve: {
          branches: BranchesResolver,
          LimitChangeStatuses: LimitChangeStatuses,
        },
        component: LimitChangeRequestsComponent,
      },
      {
        path: 'accounts',
        canActivate: [AuthGuard],
        data: { permissions: [accounts.CREATE_ACCOUNT] },
        resolve: { branches: BranchesResolver, groups: BranchGroupsResolver },
        component: AccountsComponent,
      },
      {
        path: 'account/add',
        canActivate: [AuthGuard],
        data: {
          permissions: [accounts.CREATE_ACCOUNT],
          addMode: true,
        },
        resolve: { branches: BranchesResolver, groups: BranchGroupsResolver },
        component: AccountDetailsComponent,
      },

      {
        path: 'account/update/:accountId',
        canActivate: [AuthGuard],
        data: {
          permissions: [accounts.UPDATE_ACCOUNT],
          addMode: false,
        },
        resolve: {
          branches: BranchesResolver,
          groups: BranchGroupsResolver,
          account: AccountByIdResolver,
        },
        component: AccountDetailsComponent,
      },
      {
        path: 'commissionChangeRequests',
        canActivate: [AuthGuard],
        data: {
          permissions: [
            commissionChangeRequests.VIEW_BRANCH_COMMISSION_CHANGE_REQUESTS,
          ],
        },
        resolve: {
          branches: BranchesResolver,
          CommissionChangeStatuses: CommissionChangeStatuses,
        },
        component: CommissionChangeRequestsComponent,
      },
      {
        path: 'journals',
        canActivate: [AuthGuard],
        data: {
          permissions: [
            journals.VIEW_MANUAL_JOURNAL,
            journals.UPDATE_MANUAL_JOURNAL,
          ],
        },
        resolve: {
          branches: BranchesResolver,
          statuses: ManualJournalStatuses,
        },
        component: ManualJournalsComponent,
      },
      {
        path: 'journals/add',
        canActivate: [AuthGuard],
        data: { permissions: [journals.CREATE_MANUAL_JOURNAL] },
        resolve: { branches: BranchesResolver },
        component: ManualJournalDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BranchRoutesModule {}
