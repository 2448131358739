import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {EMPTY, forkJoin, merge, Observable, of, throwError} from 'rxjs';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import {
  debounceTime,
  tap,
  switchMap,
  finalize,
  distinctUntilChanged,
  filter,
  catchError,
  repeat,
  map,
} from 'rxjs/operators';
import { RemittanceService } from './data/remittance.service';
import { CRUDResponseDto, LookupDto } from '../_common/data/dto/api.dto';
import { CustomerService } from '../customer/data/customer.service';
import {
  CustomerDocTypesResponseDto,
  UpsertCustomerDto,
  SearchCustomerResponseDto,
  GetCustomerDto,
  ReceiverDto, upsertConductor, GetCustomerTypesDto,
} from '../customer/data/dto/customer.dto';
import {
  CalculateRemittanceAmountsReqDto,
  CreateRemittanceDto,
  RemittanceComplianceDto,
  RemittanceSubType,
  RemittanceType,
} from './data/dto/remittances.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { mapRemittanceSubTypesToRemittanceTypes } from './data/remittance.helper';
import {CreateConductorDocumentDto, CustomerDocumentDto} from '../customer/data/dto/customerDocument.dto';
import { RemittanceSendReceiptPopupComponent } from './remittanceSendReceiptPopup.component';
import { RemittanceCompliancePopupComponent } from './remittanceCompliancePopup.component';
import { BranchesService } from '../branch/data/branches.service';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { CountriesDto } from './data/dto/countries.dto';
import { DomSanitizer } from '@angular/platform-browser';
import { EbirrOptionsDto } from './data/dto/ebirrOptions.dto';
import { regExp } from '../_common/data/validationRules';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import {setDecimals, splitFullName} from '../_common/utils';
import { CountryISO2s, CustomerTypes} from "../_common/enums/systemBranches.enums";
import {MobileOwnershipVerifPopupComponent} from "./mobileOwnershipVerifPopup.component";

interface BankDetails {
  bankCode: string;
  bankAccount: string;
  bankName: string;
}

interface RemittanceTypeDetails {
  bankDetails: BankDetails;
  accountNumber: string;
}

@Component({
  selector: 'app-add-remittance',
  templateUrl: './addRemittance.component.html',
})
export class AddRemittanceComponent implements OnInit {
  form!: UntypedFormGroup;

  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;
  // @ts-ignore
  @ViewChild('primaryDocFile') primaryDocFileElem: ElementRef;

  // @ts-ignore
  @ViewChild('authorizedSignatureFile') authorizedSignatureFileUploadElem: ElementRef;

  // @ts-ignore
  @ViewChild('secondaryDocFile') secondaryDocFileElem: ElementRef;
  // @ts-ignore
  @ViewChild('remittanceForm') el: ElementRef;

  searchSenderCtrl = new UntypedFormControl();
  isLoadingSenders = false;
  errorMsgForLoadingSenders!: string;
  minLengthForLoadingSenders = 3;
  maxDOB = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate(),
  );
  today = new Date();
  isCommissionIncludedInAmount: boolean = false;

  // form models
  filteredSenders: SearchCustomerResponseDto[] = [];
  selectedSender: GetCustomerDto | null = null;
  selectedConductor: any = null;
  conductors: any;
  remittanceTypes: RemittanceType[] = [];
  tellerAccounts: LookupDto[] = [];
  paymentTypes: LookupDto[] = [];
  allPayingCountries: CountriesDto[] = [];
  sendingBranchCountry: LookupDto[] = [];
  allCountries: LookupDto[] = [];
  purposes: LookupDto[] = [];
  relationships: LookupDto[] = [];
  customerDocTypes: CustomerDocTypesResponseDto[] = [];
  primaryDocTypes: LookupDto[] = [];
  secondaryDocTypes: LookupDto[] = [];
  senderPrimaryDocs: CustomerDocumentDto[] = [];
  senderPrimaryDocFile?: File[] = [];
  senderPrimaryDoc?: CustomerDocumentDto;
  senderSecondaryDocs: CustomerDocumentDto[] = [];
  senderSecondaryDocFile?: File;
  senderSecondaryDoc?: CustomerDocumentDto;
  senderDocs: CustomerDocumentDto[] = [];
  senderCities: LookupDto[] = [];
  senderLocalities: LookupDto[] = [];
  customerGenders: LookupDto[] = [];
  allCustomerTypes: GetCustomerTypesDto[] = [];
  customerOccupations: LookupDto[] = [];
  customerSourceOfIncome: LookupDto[] = [];
  receivingCountryCurrencies: LookupDto[] = [];
  cities: LookupDto[] = [];
  localities: LookupDto[] = [];
  existingReceivers: ReceiverDto[] | undefined;
  primaryDocFileUrl: any[] = [];
  secondaryDocFileUrl: string | undefined;
  isReceiverVerified: boolean = false;
  receiverAccountNumber: string = '';
  receiverBankAccount: string = '';
  sendingBranchAmount: number = 0;
  sendingBranchCommission: number = 0;
  amount: number = 0;
  commission: number = 0;
  sendingAmount: number = 0;
  sendingCommission: number = 0;
  totalSendingAmount: number = 0;
  receivingAmount: number = 0;
  sendingExchangeRate: number = 0;
  receiverExchangeRate: number = 0;
  sendingBranchExchangeRate: number = 0;
  currencyToCurrencyExchangeRate: number = 0;
  minSendingCommission: number = 0;
  maxSendingCommission: number = 0;
  sendingAccountCurrency: string = '';
  sendingBranchCurrency: string = '';
  documentPathArray: string[] | undefined;
  docTypeSelected!: CustomerDocTypesResponseDto;
  ebirrOptions!: EbirrOptionsDto[];
  partialEbirrOptions!: EbirrOptionsDto[];
  ebirSelected: boolean = false;
  receiverBankDetails!: EbirrOptionsDto;
  remittanceTypeDetails: RemittanceTypeDetails = {
    bankDetails: {
      bankAccount: '',
      bankCode: '',
      bankName: '',
    },
    accountNumber: '',
  };
  docFile: any[] = [];
  isPdfFile: Boolean = false;
  numberOfAttachmentsRequired: number = 0;
  selectedDoctype: any;
  requiredFields: any;
  isPassportDocTypeSelected: boolean = false;
  allLocalities: LookupDto[] = [];
  allOccupations: LookupDto[] = [];
  allSourceOfIncome: LookupDto[] = [];
  customerTypeId: any = CustomerTypes.Person;
  panelOpenState: boolean = false;
  isThisTrkCountry: boolean = false;
  authorizedSignatureDocFile: File[] = [];
  authorizedSignatureDocFileUrl: any = [];
  authorizedSignatureDocs: any = [];
  customerTypes = CustomerTypes;
  duplicateCustomers = [];
  isMobileOwnershipVerified: Boolean = false;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private remittanceService: RemittanceService,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private snackBar: SnackBarService,
    public dialog: MatDialog,
    private branchService: BranchesService,
    private sanitizer: DomSanitizer,
    private tranlateService: TranslateService,
  ) {}

  mapOnValueChange(fieldName: string) {
    return this.form.controls[fieldName].valueChanges.pipe(
      map((value) => ({ value, fieldName })),
    );
  }

  setInputValueSilent(field: string, value: number | null) {
    this.form.controls[field]?.setValue(value, {
      emitEvent: false,
    });
  }

  ngOnInit() {
    this.route.data.subscribe(
      ({
        remittanceSubTypes,
        tellerAccounts,
        payingCountries,
        countries,
        paymentTypes,
        customerDocTypes,
        customerGenders,
        customerOccupations,
        customerSourceOfIncome,
        cities,
        localities,
        remittancePurposes,
        remittanceRelationships,
         customerTypesData
      }) => {
        this.remittanceTypes =
          mapRemittanceSubTypesToRemittanceTypes(remittanceSubTypes);
        this.allCustomerTypes = customerTypesData;

        this.tellerAccounts = tellerAccounts;
        this.allCountries = countries;
        this.allPayingCountries = payingCountries;
        this.paymentTypes = paymentTypes;
        this.purposes = [...remittancePurposes].filter((f) => f.isActive);
        this.relationships = [...remittanceRelationships].filter(
          (f) => f.isActive,
        );
        this.customerDocTypes = [...customerDocTypes].filter((f) => f.isActive);
        this.customerGenders = customerGenders;
        this.allOccupations = customerOccupations;
        this.customerOccupations = [...customerOccupations].filter(
          (f) => f.isActive != 0,
        );
        this.allSourceOfIncome = customerSourceOfIncome;
        this.customerSourceOfIncome = [...customerSourceOfIncome].filter(
          (f) => f.isActive != 0,
        );
        this.senderCities = [...cities].filter((f) => f.isActive);
        this.senderLocalities = localities;
        this.primaryDocTypes = this.filterDocTypes(true, null);
        this.secondaryDocTypes = this.filterDocTypes(false, null);

        this.form = this.fb.group({
          senderFirstName: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(40),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          senderMiddleName: [
            null,
            [
              Validators.minLength(2),
              Validators.maxLength(10),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          senderLastName: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(50),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          senderMobilePhone: [
            null,
            [Validators.required,Validators.maxLength(15), Validators.pattern(regExp.mobile)],
          ],
          motherName: [
            null,
          ],
          gender: [null, [Validators.required]],
          dateOfBirth: [null, [Validators.required]],
          sourceOfIncome: [null, [Validators.required]],
          street: [
            null,
            [
              Validators.minLength(2),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          buildingNumber: [
            null,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          houseNumber: [
            null,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpecialCharacters),
            ],
          ],
          placeOfBirth: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          occupation: [null, [Validators.required]],
          senderAddressLine1: [null],
          senderAddressLine2: [null],
          senderCity: [null, [Validators.required]],
          senderCountry: [null, [Validators.required]],
          senderNationality: [null],
          companyTitle: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          customerTypeId: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          tradeRegistryNumber: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          taxIdentificationNumber: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          fieldOfActivity: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyMobilePhone: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyCountry: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyCity: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyStreet: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyBuildingNumber: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyArea: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorFirstName: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorLastName: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorMobilePhone: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorDateOfBirth: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorPlaceOfBirth: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorPosition: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorFartherName: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorMotherName: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorNationality: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          conductorId: [this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          primaryDocs: [null],
          primaryDocType: [null, [Validators.required]],
          primaryDocNumber: [null, [Validators.required]],
          primaryDocIssueDate: [null, [Validators.required]],
          primaryDocExpiryDate: [null, [Validators.required]],
          primaryDocIssuedBy: [null, [Validators.required]],
          idNumber: [null],
          serialNumber: [null],
          /*           secondaryDocs: [null],
                    secondaryDocType: [null],
                    secondaryDocNumber: [null],
                    secondaryDocIssueDate: [null],
                    secondaryDocExpiryDate: [null],
                    secondaryDocIssuedBy: [null], */
          remittanceType: [this.selectedRemittanceType, [Validators.required]],
          remittanceSubType: [
            this.selectedRemittanceSubType,
            [Validators.required],
          ],
          receivingCountry: [null, [Validators.required]],
          receiverMobilePhone: [
            null,
            [Validators.required,Validators.maxLength(15) , Validators.pattern(regExp.mobile)],
          ],
          receiverFirstName: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(40),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          receiverMiddleName: [
            null,
            [
              Validators.minLength(2),
              Validators.maxLength(10),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          receiverLastName: [
            null,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(50),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          tellerAccount: [
            this.tellerAccounts.length === 1 ? this.tellerAccounts[0] : null,
            [Validators.required],
          ],
          sendingAmount: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          sendingCommission: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          totalSendingAmount: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          receivingAmount: [
            null,
            [Validators.required, Validators.pattern(regExp.decimalNumber)],
          ],
          receivingCurrency: [null, [Validators.required]],
          sendingExchangeRate: [
            { value: null, disabled: true },
            [Validators.required],
          ],
          currencyToCurrencyRate: [
            { value: null, disabled: true },
            [Validators.required],
          ],
          amount: [{ value: null, disabled: true }, [Validators.required]],
          sendingBranchAmount: [
            { value: null, disabled: true },
            [Validators.required],
          ],
          commission: [{ value: null, disabled: true }, [Validators.required]],
          sendingBranchCommission: [
            { value: null, disabled: true },
            [Validators.required],
          ],
          paymentType: [paymentTypes[0], [Validators.required]],
          complianceComments: [null],
          purpose: [null, [Validators.required]],
          relationship: [null, [Validators.required]],
          existingReceivers: [null],
          ebirrOptions: [null],
          bankOrWallet: [null],
          senderComments: [null, [Validators.pattern(regExp.alphaNumericSpecialCharacters)]],
          fatherName: [null],
        });
      },
    );
    this.getBranchCountryCity(false);
    this.setReceivingCountry();

    this.searchSenderCtrl.valueChanges
      .pipe(
        filter(
          (res) =>
            res !== null && res.length >= this.minLengthForLoadingSenders,
        ),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsgForLoadingSenders = '';
          this.filteredSenders = [];
          this.isLoadingSenders = true;
        }),
        switchMap((value) =>
          this.customerService.searchCustomer(value).pipe(
            finalize(() => {
              this.isLoadingSenders = false;
            }),
          ),
        ),
      )
      .subscribe((data: SearchCustomerResponseDto[]) => {
        this.errorMsgForLoadingSenders = '';
        this.filteredSenders = data.filter(cust => cust.customerTypeId == this.customerTypeId);
      }, ({ message }) => {
        this.snackBar.open(message);
      });

    merge(
      this.mapOnValueChange('remittanceSubType'),
      this.mapOnValueChange('tellerAccount'),
      this.mapOnValueChange('receivingCurrency'),
      this.mapOnValueChange('sendingAmount'),
      this.mapOnValueChange('totalSendingAmount'),
      this.mapOnValueChange('receivingAmount'),
    )
      .pipe(
        /*        tap(() => {
                  this.form.controls['sendingCommission'].setValue(null);
                }),*/
        filter(() => {
          return (
            this.form.controls['remittanceSubType'].value &&
            this.form.controls['tellerAccount'].value &&
            this.form.controls['receivingCountry'].value &&
            this.form.controls['receivingCurrency'].value &&
            (this.form.controls['sendingAmount'].value ||
              this.form.controls['totalSendingAmount'].value ||
              this.form.controls['receivingAmount'].value)
          );
        }),
        debounceTime(500),
        switchMap(({ fieldName }) => {
          this.isCommissionIncludedInAmount =
            fieldName === 'totalSendingAmount';
          this.receiverExchangeRate = 0;
          const payload: CalculateRemittanceAmountsReqDto = {
            changedField: fieldName,
            remittanceSubTypeId:
              this.form.controls['remittanceSubType'].value?.id,
            receiverCountryId: this.form.controls['receivingCountry'].value?.id,
            tellerAccountId: this.form.controls['tellerAccount'].value?.id,
            sendingAmount: this.form.controls['sendingAmount'].value,
            totalSendingAmount: this.form.controls['totalSendingAmount'].value,
            receivingAmount: this.form.controls['receivingAmount'].value,
            receivingCurrencyId: this.form.controls['receivingCurrency'].value,
          };
          const isValid =
            (payload.sendingAmount ||
              payload.totalSendingAmount ||
              payload.receivingAmount) &&
            Object.keys(payload).reduce(
              (isValid, key) =>
                isValid &&
                ([
                  'sendingAmount',
                  'totalSendingAmount',
                  'receivingAmount',
                ].includes(key) ||
                  !!payload[key]),
              true,
            );
          if (!isValid) return EMPTY;

          return this.remittanceService.calculateRemittanceAmounts(payload);
        }),
        catchError(({ message }) => {
          this.snackBar.open(message);
          return EMPTY;
        }),
        repeat(),
      )
      .subscribe(
        ({
          sendingCommission,
          minSendingCommission,
          maxSendingCommission,
          receivingAmount,
          sendingExchangeRate,
          currencyToCurrencyExchangeRate,
          receiverExchangeRate,
          sendingAmount,
          sendingBranchExchangeRate,
          sendingBranchAmount,
          sendingBranchCommission,
          amount,
          commission,
          sendingAccountCurrency,
          sendingBranchCurrency
        }) => {
          this.setInputValueSilent('sendingAmount', +sendingAmount.toFixed(2));
          this.setInputValueSilent(
            'sendingCommission',
            +sendingCommission.toFixed(2),
          );
          this.setInputValueSilent(
            'totalSendingAmount',
            +(+sendingAmount + sendingCommission).toFixed(
              2,
            ),
          );
          this.setInputValueSilent(
            'receivingAmount',
            +receivingAmount.toFixed(2),
          );
          this.setInputValueSilent(
            'sendingExchangeRate',
            +sendingExchangeRate.toFixed(5),
          );
          this.setInputValueSilent(
            'currencyToCurrencyRate',
            +currencyToCurrencyExchangeRate.toFixed(5),
          );
          this.setInputValueSilent(
            'sendingBranchAmount',
            +sendingBranchAmount.toFixed(2),
          );
          this.setInputValueSilent(
            'sendingBranchCommission',
            +sendingBranchCommission.toFixed(2),
          );
          this.setInputValueSilent('amount', +amount.toFixed(2));
          this.setInputValueSilent('commission', +commission.toFixed(2));
          this.sendingAmount = +sendingAmount.toFixed(2);
          this.sendingCommission = +sendingCommission.toFixed(2);
          this.receivingAmount = +receivingAmount.toFixed(2);
          this.sendingExchangeRate = +sendingExchangeRate.toFixed(5);
          this.sendingBranchAmount = +sendingBranchAmount.toFixed(2);
          this.sendingBranchCommission = +sendingBranchCommission.toFixed(2);
          this.amount = +amount.toFixed(2);
          this.commission = +commission.toFixed(2);
          this.receiverExchangeRate = +receiverExchangeRate.toFixed(2);
          this.currencyToCurrencyExchangeRate =
            +currencyToCurrencyExchangeRate.toFixed(5);
          this.minSendingCommission = +minSendingCommission.toFixed(2);
          this.maxSendingCommission = +maxSendingCommission.toFixed(2);
          this.sendingBranchExchangeRate =
            +sendingBranchExchangeRate.toFixed(5);
          this.sendingAccountCurrency = ` (${sendingAccountCurrency})`;
          this.sendingBranchCurrency = ` (${sendingBranchCurrency})`;
        },
        () => this.clearPayment(),
      );

    merge(
      this.mapOnValueChange('receiverMobilePhone'),
      this.mapOnValueChange('remittanceSubType'),
    ).subscribe(({ fieldName }) => {
      if (fieldName === 'remittanceSubType') {
        this.setReceivingCountry();
      }

      this.isReceiverVerified = false;
      this.receiverAccountNumber = '';
      this.receiverBankAccount = '';
    });
  }

  onCommissionChange() {
    const sendingAmount = +(this.form.get('sendingAmount')?.value || 0);
    const sendingCommission = +(this.form.get('sendingCommission')?.value || 0);
    this.sendingCommission = +sendingCommission.toFixed(2);
    const minSendingCommission = +(
      sendingAmount *
      (this.minSendingCommission / 100)
    ).toFixed(2);
    const maxSendingCommission = +(
      sendingAmount *
      (this.maxSendingCommission / 100)
    ).toFixed(2);
    if (
      sendingCommission &&
      (minSendingCommission > sendingCommission ||
        sendingCommission > maxSendingCommission)
    ) {
      alert(
        `Commission cannot be less than ${minSendingCommission.toFixed(
          2,
        )} and cannot be more than ${maxSendingCommission.toFixed(2)}`,
      );

      this.setInputValueSilent('totalSendingAmount', 0);
      this.setInputValueSilent('commission', 0);
      this.setInputValueSilent('sendingBranchCommission', 0);
      this.totalSendingAmount = 0;
      this.commission = 0;
      this.sendingBranchCommission = 0;
    } else {
      this.totalSendingAmount = +(
        sendingAmount +
        sendingCommission
      ).toFixed(2);
      this.commission = +setDecimals(
        sendingCommission / this.sendingExchangeRate,
      ).toFixed(2);
      this.sendingBranchCommission = setDecimals(
        this.commission * this.sendingBranchExchangeRate,
      );

      this.setInputValueSilent('totalSendingAmount', this.totalSendingAmount);
      this.setInputValueSilent('commission', this.commission);
      this.setInputValueSilent(
        'sendingBranchCommission',
        this.sendingBranchCommission,
      );
    }
  }

  setReceivingCountry() {
    const receivingCountry = this.form.controls['receivingCountry'];
    const payingCountry =
      this.payingCountries.length === 1 ? this.payingCountries[0] : null;
    receivingCountry.setValue(payingCountry);
    this.onReceivingCountryChange(payingCountry?.id);
  }

  get selectedRemittanceType(): RemittanceType {
    return (
      this.form?.controls['remittanceType'].value || this.remittanceTypes[0]
    );
  }

  get selectedRemittanceSubType(): RemittanceSubType {
    const remittanceType = this.selectedRemittanceType;
    const formRemittanceSubType =
      this.form?.controls['remittanceSubType'].value;
    const remittanceSubTypeExists =
      formRemittanceSubType &&
      remittanceType.remittanceSubTypes.some(
        (rst) => rst.id === formRemittanceSubType.id,
      );
    if (remittanceSubTypeExists) {
      return formRemittanceSubType;
    }

    this.form?.controls['remittanceSubType'].setValue(
      remittanceType.remittanceSubTypes[0],
    );
    return remittanceType.remittanceSubTypes[0];
  }

  get payingCountries() {
    const subTypeCountries = this.selectedRemittanceSubType.countries;
    let payingCountries = this.allPayingCountries;
    if (subTypeCountries?.length)
      payingCountries = this.allPayingCountries.filter((c: CountriesDto) =>
        subTypeCountries.includes(c.iso3),
      );

    return payingCountries;
  }

  onValidateReceiverInfo() {
    this.form.controls['receiverFirstName'].enable();
    this.form.controls['receiverMiddleName'].enable();
    this.form.controls['receiverLastName'].enable();

    this.customerService
      .validateCustomerInfo(
        this.form.controls['receiverMobilePhone'].value,
        this.form.controls['remittanceSubType'].value.id,
        (this.form.controls['remittanceSubType'].value.name === 'Ebirr' ||
          this.form.controls['remittanceSubType'].value.name ===
            'Ebirr Bank') &&
          this.form.controls['bankOrWallet'].value
          ? this.form.controls['bankOrWallet'].value.bankCode
          : null,
      )
      .subscribe(
        ({ data: validationInfo }) => {
          const nameParts = validationInfo.name.split(' ');
          this.form.controls['receiverFirstName'].setValue(nameParts.shift());
          this.form.controls['receiverMiddleName'].setValue(null);
          this.form.controls['receiverLastName'].setValue(nameParts.join(' '));
          this.remittanceTypeDetails.accountNumber =
            validationInfo.accountNumber;
          this.remittanceTypeDetails.bankDetails.bankAccount =
            validationInfo.bankAccount;
          if (
            this.form.controls['remittanceSubType'].value.name === 'Ebirr' ||
            (this.form.controls['remittanceSubType'].value.name ===
              'Ebirr Bank' &&
              this.form.controls['receivingCountry'].value.iso3 === 'ETH')
          ) {
            this.remittanceTypeDetails.bankDetails.bankName =
              this.form.controls['bankOrWallet'].value.bankName;
            this.remittanceTypeDetails.bankDetails.bankCode =
              this.form.controls['bankOrWallet'].value.bankCode;
          }
          this.isReceiverVerified = true;
          if (!this.selectedRemittanceSubType.canReceiverNameChanged) {
            this.form.controls['receiverFirstName'].disable();
            this.form.controls['receiverMiddleName'].disable();
            this.form.controls['receiverLastName'].disable();
          }
        },
        (err) => {
          this.isReceiverVerified = false;
          this.receiverAccountNumber = '';
          this.receiverBankAccount = '';
          this.snackBar.open(err.message);
        },
      );
  }

  onSenderSelected() {
    this.clearSelectedSender(false);

    this.customerService
      .getCustomerWithDocs(this.searchSenderCtrl.value.id)
      .subscribe((customer) => {
        this.selectedSender = customer;
        this.conductors = this.selectedSender.conductors;
        this.customerTypeId = this.selectedSender.customerTypeId;

        if(this.customerTypeId == CustomerTypes.Corporate ){
          this.panelOpenState = true;
        } else {
          this.panelOpenState = false;
        }

        this.onCustomerTypeChanged({value: this.customerTypeId, isCustomerSelected : 1})
        this.localities = this.allLocalities;
        this.customerOccupations = this.allOccupations;
        this.customerSourceOfIncome = this.allSourceOfIncome;

        this.getBranchCountryCity(true);

        this.form.controls['senderFirstName'].setValue(
          this.selectedSender?.firstName,
        );
        this.form.controls['senderFirstName'].disable();
        this.form.controls['senderMiddleName'].setValue(
          this.selectedSender?.middleName,
        );
        this.form.controls['senderMiddleName'].disable();
        this.form.controls['senderLastName'].setValue(
          this.selectedSender?.lastName,
        );
        this.form.controls['senderLastName'].disable();
        this.form.controls['senderMobilePhone'].setValue(
          this.selectedSender?.mobilePhone,
        );
        this.form.controls['senderMobilePhone'].disable();

        this.form.controls['gender'].setValue(
          this.customerGenders.find(
            (f) => f.id === this.selectedSender?.genderId,
          ),
        );
        this.form.controls['gender'].disable();
        this.form.controls['occupation'].setValue(
          this.customerOccupations.find(
            (f) => f.id === this.selectedSender?.occupationId,
          ),
        );
        this.form.controls['occupation'].disable();
        this.form.controls['sourceOfIncome'].setValue(
          this.customerSourceOfIncome.find(
            (f) => f.id === this.selectedSender?.sourceOfIncomeId,
          ),
        );
        this.form.controls['sourceOfIncome'].disable();
        if (
          this.localities.find(
            (f) => f.parentId === this.selectedSender?.cityId,
          )
        ) {

          this.form.controls['street'].setValue(this.selectedSender.street);
          this.form.controls['street'].disable();
          this.form.controls['houseNumber'].setValue(
            this.selectedSender.houseNumber,
          );
          this.form.controls['buildingNumber'].setValue(
            this.selectedSender.buildingNumber,
          );
          this.form.controls['buildingNumber'].disable();
        }
        this.form.controls['senderAddressLine1'].setValue(
          this.selectedSender?.addressLine1,
        );
        this.form.controls['senderAddressLine2'].setValue(
          this.selectedSender?.addressLine2,
        );
        this.form.controls['dateOfBirth'].setValue(
          this.selectedSender.dateOfBirth,
        );
        this.form.controls['dateOfBirth'].disable();
        this.form.controls['placeOfBirth'].setValue(
          this.selectedSender.placeOfBirth,
        );
        this.form.controls['placeOfBirth'].disable();
        //this.existingReceivers = customer.receivers;
        this.customerService
          .getSenderReceivers(customer.id)
          .subscribe((custom) => {
            this.existingReceivers = custom;
          });
        customer.docs =
          customer.docs
            ?.map((d) => ({
              ...d,
              displayName: `${
                this.customerDocTypes.find((t) => t.id === d.documentTypeId)
                  ?.name
              } - ${d.documentDetails.number}`,
            }))
            .filter((doc) => doc.isActive) || [];
        this.senderDocs = customer.docs;
        this.senderPrimaryDocs = this.filterSenderDocs(
          null,
          this.primaryDocTypes,
        );
        this.senderSecondaryDocs = this.filterSenderDocs(
          null,
          this.secondaryDocTypes,
        );
        if (this.senderPrimaryDocs.length == 1) {
          this.form.controls['primaryDocs'].setValue(
            this.senderPrimaryDocs[0]?.id,
          );
          this.onCustomerDocSelected(
            customer.docs.find((d) => d.displayName != '')?.id!,
            'primary',
          );
        }

        if (this.selectedSender.customerTypeId == CustomerTypes.Corporate){
          this.form.controls['companyTitle'].setValue(
            this.selectedSender?.firstName + ' ' + this.selectedSender?.lastName,
          );
          this.form.controls['companyTitle'].disable();
          this.form.controls['taxIdentificationNumber'].setValue(
            this.selectedSender?.taxIdentificationNumber,
          );
          this.form.controls['taxIdentificationNumber'].disable();

          this.form.controls['fieldOfActivity'].setValue(
            this.selectedSender?.fieldOfActivity,
          );
          this.form.controls['fieldOfActivity'].disable();

          this.form.controls['companyMobilePhone'].setValue(
            this.selectedSender?.mobilePhone,
          );
          this.form.controls['companyMobilePhone'].disable();

          this.form.controls['tradeRegistryNumber'].setValue(
            this.selectedSender?.tradeRegistryNumber,
          );
          this.form.controls['tradeRegistryNumber'].disable();

          this.form.controls['companyArea'].setValue(
            this.selectedSender?.companyArea,
          );
          this.form.controls['companyArea'].disable();

          this.form.controls['companyStreet'].setValue(
            this.selectedSender?.street,
          );
          this.form.controls['companyStreet'].disable();

          this.form.controls['companyBuildingNumber'].setValue(
            this.selectedSender?.buildingNumber,
          );
          this.form.controls['companyBuildingNumber'].disable();

          this.form.controls['companyCountry'].setValue(
            this.allCountries.find(
              (f) => f.id === this.selectedSender?.countryId
            )
          );
          this.form.controls['companyCountry'].disable();

          this.form.controls['companyCity'].setValue(
            this.cities.find(
              (f) => f.id === this.selectedSender?.cityId
            )
          );
          this.form.controls['companyCity'].disable();

          this.form.controls['customerTypeId'].setValue(this.allCustomerTypes.find(ctype => ctype.id == this.selectedSender?.customerTypeId))
        }
      });
  }

  onExistingReceiverSelected(event: any) {
    this.clearSelectedReceiver();

    const selectedExistingReceiver = event.value;
    this.form.controls['receiverMobilePhone'].setValue(
      selectedExistingReceiver.mobilePhone,
    );
    this.form.controls['receiverFirstName'].setValue(
      selectedExistingReceiver.firstName,
    );
    this.form.controls['receiverMiddleName'].setValue(
      selectedExistingReceiver.middleName,
    );
    this.form.controls['receiverLastName'].setValue(
      selectedExistingReceiver.lastName,
    );
    this.form.controls['purpose'].setValue(this.purposes.find(p=>p.id === selectedExistingReceiver.purpose.id));
    this.form.controls['relationship'].setValue(this.relationships.find(r=>r.id === selectedExistingReceiver.relationship.id));
  }

  clearSelectedReceiver() {
    this.form.controls['receiverFirstName'].setValue(null);
    this.form.controls['receiverMiddleName'].setValue(null);
    this.form.controls['receiverLastName'].setValue(null);
    this.form.controls['receiverMobilePhone'].setValue(null);
    this.form.controls['purpose'].setValue(null);
    this.form.controls['relationship'].setValue(null);
    this.isReceiverVerified = false;
    this.form.controls['receiverFirstName'].enable();
    this.form.controls['receiverMiddleName'].enable();
    this.form.controls['receiverLastName'].enable();

    this.receiverBankAccount = '';
    this.receiverAccountNumber = '';
    this.localities = [...this.localities].filter((f) => f.isActive);
    this.customerOccupations = [...this.customerOccupations].filter((f) => f.isActive);
    this.customerSourceOfIncome = [...this.customerSourceOfIncome].filter((f) => f.isActive);
  }

  displaySenderWith(value: SearchCustomerResponseDto) {
    return !!value ? `${value.firstName} - ${value.mobilePhone}` : '';
  }

  clearSelectedSender(resetFilteredSenders: boolean) {
    if (resetFilteredSenders) this.filteredSenders = [];

    this.selectedSender = null;
    this.existingReceivers = [];
    this.form.controls['senderFirstName'].setValue(null);
    this.form.controls['senderMiddleName'].setValue(null);
    this.form.controls['senderLastName'].setValue(null);
    this.form.controls['senderMobilePhone'].setValue(null);
    this.form.controls['senderAddressLine1'].setValue(null);
    this.form.controls['senderAddressLine2'].setValue(null);
    this.form.controls['houseNumber'].enable();
    this.form.controls['houseNumber'].setValue(null);
    this.form.controls['buildingNumber'].enable();
    this.form.controls['buildingNumber'].setValue(null);
    this.form.controls['street'].enable();
    this.form.controls['street'].setValue(null);
    this.form.controls['dateOfBirth'].setValue(null);
    this.form.controls['occupation'].setValue(null);
    this.form.controls['sourceOfIncome'].setValue(null);
    this.form.controls['senderNationality'].setValue(null);
    this.form.controls['placeOfBirth'].setValue(null);
    this.form.controls['motherName'].setValue(null);
    this.form.controls['gender'].setValue(null);
    this.form.controls['existingReceivers'].setValue(null);

    if(this.customerTypeId == CustomerTypes.Corporate){
      this.unsetFieldRequired('companyTitle');
      this.unsetFieldRequired('taxIdentificationNumber');
      this.unsetFieldRequired('tradeRegistryNumber');
      this.unsetFieldRequired('fieldOfActivity');
      this.unsetFieldRequired('companyMobilePhone');
      this.unsetFieldRequired('companyCountry');
      this.unsetFieldRequired('companyCity');
      this.unsetFieldRequired('companyStreet');
      this.unsetFieldRequired('companyBuildingNumber');
      this.unsetFieldRequired('companyArea');

      this.unsetFieldRequired('conductorFartherName');
      this.unsetFieldRequired('conductorNationality');
      this.unsetFieldRequired('conductorMotherName');
      this.unsetFieldRequired('conductorFirstName');
      this.unsetFieldRequired('conductorLastName');
      this.unsetFieldRequired('conductorMobilePhone');
      this.unsetFieldRequired('conductorDateOfBirth');
      this.unsetFieldRequired('conductorPlaceOfBirth');
      this.unsetFieldRequired('conductorPosition');

      this.form.controls['companyTitle'].enable();
      this.form.controls['taxIdentificationNumber'].enable();
      this.form.controls['tradeRegistryNumber'].enable();
      this.form.controls['fieldOfActivity'].enable();
      this.form.controls['companyMobilePhone'].enable();
      this.form.controls['companyCountry'].enable();
      this.form.controls['companyCity'].enable();
      this.form.controls['companyStreet'].enable();
      this.form.controls['companyBuildingNumber'].enable();
      this.form.controls['companyArea'].enable();

      this.form.controls['conductorFirstName'].enable();
      this.form.controls['conductorLastName'].enable();
      this.form.controls['conductorMobilePhone'].enable();
      this.form.controls['conductorDateOfBirth'].enable();
      this.form.controls['conductorPlaceOfBirth'].enable();
      this.form.controls['conductorFartherName'].enable();
      this.form.controls['conductorMotherName'].enable();
      this.form.controls['conductorNationality'].enable();
      this.form.controls['conductorPosition'].enable();

      this.conductors = [];

      this.clearSelectedAuthorizedDocAttachment();
    }
    this.customerTypeId = this.allCustomerTypes.find(ctype => ctype.id == CustomerTypes.Person);


    this.senderPrimaryDocs = [];
    this.form.controls['primaryDocs'].setValue(null);
    this.clearSelectedReceiver();
    this.resetDoc('primary');

    this.form.controls['senderFirstName'].enable();
    this.form.controls['senderMiddleName'].enable();
    this.form.controls['senderLastName'].enable();
    this.form.controls['senderMobilePhone'].enable();
    this.form.controls['motherName'].enable();
    this.form.controls['gender'].enable();
    this.form.controls['occupation'].enable();
    this.form.controls['sourceOfIncome'].enable();
    this.form.controls['senderNationality'].enable();
    this.form.controls['dateOfBirth'].enable();
    this.form.controls['placeOfBirth'].enable();
    this.docFile = [];
    this.clearPayment();

    /*    this.senderSecondaryDocs = [];
        this.form.controls['secondaryDocs'].setValue(null);
        this.resetDoc('secondary');*/
  }

  clearPayment() {
    this.setInputValueSilent('sendingAmount', null);
    this.setInputValueSilent('sendingCommission', null);
    this.setInputValueSilent('totalSendingAmount', null);
    this.setInputValueSilent('receivingAmount', null);
    this.setInputValueSilent('sendingExchangeRate', null);
    this.setInputValueSilent('sendingBranchAmount', null);
    this.setInputValueSilent('sendingBranchCommission', null);
    this.setInputValueSilent('amount', null);
    this.setInputValueSilent('commission', null);
    this.receiverExchangeRate = 0;
    this.currencyToCurrencyExchangeRate = 0;
    this.minSendingCommission = 0;
    this.maxSendingCommission = 0;

    this.sendingAmount = 0;
    this.sendingCommission = 0;
    this.totalSendingAmount = 0;
    this.receivingAmount = 0;
    this.sendingExchangeRate = 0;
    this.sendingBranchAmount = 0;
    this.sendingBranchCommission = 0;
    this.amount = 0;
    this.commission = 0;
  }

  showEbirrOptions(countryId?: number) {
    if (
      this.form.controls['remittanceSubType'].value &&
      (this.form.controls['remittanceSubType'].value.name === 'Ebirr Bank' ||
        this.form.controls['remittanceSubType'].value.name === 'Ebirr')
    ) {
      this.ebirSelected = true;
      this.setFieldRequired('bankOrWallet');
      this.setFieldRequired('ebirrOptions');
      this.remittanceService.getEbirrOptions().subscribe((options) => {
        this.ebirrOptions = options;
      });
    } else {
      this.ebirSelected = false;
      this.unsetFieldRequired('bankOrWallet');
      this.unsetFieldRequired('ebirrOptions');
    }
  }

  onReceivingCountryChange(countryId?: number) {
    this.showEbirrOptions(countryId);
    if (!countryId) {
      this.receivingCountryCurrencies = [];
      this.form.controls['receivingCurrency'].setValue(null);
      return;
    }

    this.form.controls['receivingCountry'].setValue(
      this.payingCountries.find((country) => country.id === countryId),
    );
    this.setReceivingCurrencies(countryId);
  }

  setReceivingCurrencies(countryId: number) {
    this.remittanceService
      .getReceivingCountryCurrencies(
        countryId,
        this.selectedRemittanceSubType.id,
      )
      .subscribe(
        (currencies) => {
          this.receivingCountryCurrencies = currencies;
          this.form.controls['receivingCurrency'].setValue(currencies[0].id);
        },
        (err) => {
          this.snackBar.open(err?.message);
          this.receivingCountryCurrencies = [];
          this.form.controls['receivingCurrency'].setValue(null);
        },
      );
  }

  resetForm() {
    this.resetBtn?.nativeElement.click();
    this.selectedSender = null;
    this.filteredSenders = [];
    this.receivingCountryCurrencies = [];
    this.existingReceivers = [];
    this.form.controls['street'].enable();
    this.form.controls['houseNumber'].enable();
    this.form.controls['buildingNumber'].enable();
    this.getBranchCountryCity(false);
    this.clearSelectedSender(false);
    this.isMobileOwnershipVerified = false;
    setTimeout(() => {
      this.form?.controls['remittanceType'].setValue(this.remittanceTypes[0]);
      this.form?.controls['tellerAccount'].setValue(this.tellerAccounts[0]);
      this.form?.controls['paymentType'].setValue(this.paymentTypes[0]);
    }, 100);
  }

  resetDoc(docType: string) {
    this.form.controls[`${docType}DocType`].setValue(null);
    this.form.controls[`${docType}DocNumber`].setValue(null);
    this.form.controls[`${docType}DocIssueDate`].setValue(null);
    this.form.controls[`${docType}DocExpiryDate`].setValue(null);
    this.form.controls[`${docType}DocIssuedBy`].setValue(null);
    this.form.controls[`idNumber`]?.setValue(null);
    this.form.controls[`serialNumber`]?.setValue(null);
    this.form.controls[`motherName`]?.setValue(null);
    this.form.controls[`fatherName`]?.setValue(null);
    this.form.controls[`senderNationality`]?.setValue(null);


    this.form.controls[`${docType}Docs`].enable();
    this.form.controls[`${docType}DocType`].enable();
    this.form.controls[`${docType}DocNumber`].enable();
    this.form.controls[`${docType}DocIssueDate`].enable();
    this.form.controls[`${docType}DocExpiryDate`].enable();
    this.form.controls[`${docType}DocIssuedBy`].enable();
    this.form.controls[`idNumber`]?.enable();
    this.form.controls[`serialNumber`]?.enable();
    this.form.controls[`motherName`]?.enable();
    this.form.controls[`fatherName`]?.enable();
    this.form.controls[`senderNationality`]?.enable();

    if (docType === 'primary') {
      this.primaryDocFileElem.nativeElement.value = '';
      this.senderPrimaryDoc = undefined;
      this.senderPrimaryDocFile = undefined;
      this.docFile = [];
    } else {
      this.secondaryDocFileElem.nativeElement.value = '';
      this.senderSecondaryDoc = undefined;
      this.senderSecondaryDocFile = undefined;
    }
  }

  onCustomerDocSelected(id: number, docType: string) {
    const doc = (
      docType === 'primary' ? this.senderPrimaryDocs : this.senderSecondaryDocs
    )?.find((d) => d.id === id);
    if (!doc) {
      return;
    }
     this.selectedDoctype = this.customerDocTypes.find(dt => dt.id === doc.documentTypeId);
    this.requiredFields = this.selectedDoctype?.requiredFields;
    this.resetDoc(docType);
    docType === 'primary'
      ? (this.senderSecondaryDocs = this.filterSenderDocs(
          doc.documentTypeId,
          this.secondaryDocTypes,
        ))
      : (this.senderPrimaryDocs = this.filterSenderDocs(
          doc.documentTypeId,
          this.primaryDocTypes,
        ));

    this.form.controls[`${docType}DocType`].setValue(
      this.customerDocTypes.find((dt) => dt.id === doc.documentTypeId),
    );
    this.form.controls[`${docType}DocNumber`].setValue(doc.number);
    this.form.controls[`${docType}DocIssueDate`].setValue(doc.issueDate);
    this.form.controls[`${docType}DocExpiryDate`].setValue(doc.expiryDate);
    this.form.controls[`${docType}DocIssuedBy`].setValue(doc.issuedBy);

    this.form.controls[`idNumber`].setValue(doc?.documentDetails?.idNumber);
    this.form.controls[`serialNumber`].setValue(doc?.documentDetails?.serialNumber);
    this.form.controls[`motherName`].setValue(doc?.documentDetails?.motherName);
    this.form.controls[`fatherName`].setValue(doc?.documentDetails?.fatherName);
    this.form.controls[`senderNationality`].setValue(this.allCountries.find(
      (f) => f.id === doc.documentDetails?.nationality,
    ));

    this.form.controls[`primaryDocs`].disable();
    this.form.controls[`${docType}DocType`].disable();
    this.form.controls[`${docType}DocNumber`].disable();
    this.form.controls[`${docType}DocIssueDate`].disable();
    this.form.controls[`${docType}DocExpiryDate`].disable();
    this.form.controls[`${docType}DocIssuedBy`].disable();

    this.form.controls[`idNumber`].disable();
    this.form.controls[`serialNumber`].disable();

    this.form.controls[`motherName`].disable();
    this.form.controls[`fatherName`].disable();
    this.form.controls[`senderNationality`].disable();

    if (docType === 'primary') {
      this.senderPrimaryDoc = doc;
    } else {
      this.senderSecondaryDoc = doc;
    }

    this.documentPathArray = doc.documentPaths?.map((o) => Object.values(o)[0]);

    if (this.documentPathArray && this.documentPathArray!.length) {
      this.documentPathArray?.forEach((url) => {
        this.customerService.getCustomerDocument(url).subscribe(
          (blob) => {
            if (blob) {
              this.isPdfFile = false;
              if (url.indexOf('.pdf') > -1) {
                blob = new Blob([blob], { type: 'application/pdf' });
                this.isPdfFile = true;
              }
              this.docFile?.push({
                type: this.isPdfFile ? 'application/pdf' : '',
                name: '',
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  URL.createObjectURL(blob),
                ),
              });
            }
          },
          (err) => {
            this.snackBar.open(err.message);
          },
        );
      });
    }
  }

  // onDocAttachmentSelected(type: string, e: Event) {
  //   //@ts-ignore
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   if (type === 'primary') {
  //     this.senderPrimaryDocFile = file;
  //     reader.onload = (event: any) => {
  //       this.primaryDocFileUrl = event.target.result;
  //     }
  //   } else {
  //     this.senderSecondaryDocFile = file;
  //     reader.onload = (event: any) => {
  //       this.secondaryDocFileUrl = event.target.result;
  //     }
  //   }
  //   reader.readAsDataURL(file);
  // }

  onDocAttachmentSelected(e: Event) {
    if (this.form.controls['primaryDocType'].value) {
      this.docTypeSelected = this.form.controls['primaryDocType'].value;
      const reader = new FileReader();
      if (this.docTypeSelected.isTwoPageAttachmentRequired) {
        if (this.senderPrimaryDocFile!.length > 1) {
          this.snackBar.open(
            `${this.docTypeSelected.name} requires only two attachments.`,
          );
        } else {
          //@ts-ignore
          const file: File[] = e.target.files;
          for (var i = 0; i < file.length; i++) {
            const index = i;
            if (this.docFile.length && this.docFile[i].name === file[i].name) {
              this.snackBar.open(`File already selected with this name`);
              return;
            }
            this.senderPrimaryDocFile?.push(file[i]);
            reader.onload = (event: any) => {
              this.primaryDocFileUrl.push(
                this.sanitizer.bypassSecurityTrustResourceUrl(
                  event.target.result,
                ),
              );
              this.docFile?.push({
                type: file[index].type,
                name: file[index].name,
                url: this.sanitizer.bypassSecurityTrustResourceUrl(
                  event.target.result,
                ),
              });
              if (
                this.primaryDocFileUrl[
                  index
                ].changingThisBreaksApplicationSecurity.indexOf(
                  'data:application/pdf',
                ) > -1
              ) {
                this.isPdfFile = true;
              }
            };
            reader.readAsDataURL(file[i]);
          }
        }
      } else {
        if (
          this.senderPrimaryDocFile &&  this.senderPrimaryDocFile.length > 0 &&
          this.docFile.length >= this.numberOfAttachmentsRequired
        ) {
          this.snackBar.open(
            `${this.docTypeSelected.name} requires only ${
              !this.numberOfAttachmentsRequired
                ? this.numberOfAttachmentsRequired + 1
                : this.numberOfAttachmentsRequired
            } file attachment.`,
          );
        } else {
          //@ts-ignore
          const file = e.target.files[0];
          this.senderPrimaryDocFile?.push(file);
          reader.onload = (event: any) => {
            this.primaryDocFileUrl.push(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                event.target.result,
              ),
            );
            this.docFile?.push({
              type: file.type,
              name: file.name,
              url: this.sanitizer.bypassSecurityTrustResourceUrl(
                event.target.result,
              ),
            });
            if (
              this.primaryDocFileUrl[0].changingThisBreaksApplicationSecurity.indexOf(
                'data:application/pdf',
              ) > -1
            ) {
              this.isPdfFile = true;
            }
          };
          reader.readAsDataURL(file);
        }
      }
    }
  }

  onAuthorizedSignatureDocAttachmentSelected(e: Event) {
    const reader = new FileReader();

    if (this.authorizedSignatureDocFile?.length >= 1) {
      this.snackBar.open(
        `onlyOneAttachmentRequired`,
      );
    } else {
      //@ts-ignore
      const file = e.target.files[0];
      this.authorizedSignatureDocFile?.push(file);
      reader.onload = (event: any) => {

        this.authorizedSignatureDocFileUrl.push(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            event.target.result,
          ),
        );
        this.authorizedSignatureDocs?.push({
          type: file.type,
          name: file.name,
          url: this.sanitizer.bypassSecurityTrustResourceUrl(
            event.target.result,
          ),
        });
        if (
          this.authorizedSignatureDocFileUrl &&
          this.authorizedSignatureDocFileUrl.length > 0 &&
          this.authorizedSignatureDocFileUrl[0].changingThisBreaksApplicationSecurity &&
          this.authorizedSignatureDocFileUrl[0].changingThisBreaksApplicationSecurity.indexOf('data:application/pdf') > -1
        ) {
          this.isPdfFile = true;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onViewAttachment(imageURL: string[] | undefined) {
    this.dialog.open(AttachmentsPopupComponent, {
      width: '850px',
      data: { imageURL: imageURL, isPdfFile: this.isPdfFile },
    });
  }

  prepareDocumentPayload(
    formValues: any,
    type: string,
    customerId: number,
  ): CreateConductorDocumentDto {
    return {
      id: this.form.controls[`${type}Docs`].disabled
        ? this.senderPrimaryDoc?.id
        : formValues[`${type}Docs`] || null,
      customerId,
      documentTypeId: this.form.controls[`${type}DocType`].disabled
        ? this.senderPrimaryDoc?.documentTypeId
        : formValues[`${type}DocType`].id,
      documentDetails: {
        number: this.form.controls[`${type}DocNumber`].disabled
          ? this.senderPrimaryDoc?.number
          : formValues[`${type}DocNumber`],
        issueDate: this.form.controls[`${type}DocIssueDate`].disabled
          ? this.senderPrimaryDoc?.issueDate
          : formValues[`${type}DocIssueDate`] || null,
        expiryDate: this.form.controls[`${type}DocExpiryDate`].disabled
          ? this.senderPrimaryDoc?.expiryDate
          : formValues[`${type}DocExpiryDate`] || null,
        issuedBy: this.form.controls[`${type}DocIssuedBy`].disabled
          ? this.senderPrimaryDoc?.issuedBy
          : formValues[`${type}DocIssuedBy`] || null,
        idNumber : this.form.controls['idNumber'].disabled ? this.senderPrimaryDoc?.documentDetails.idNumber : formValues[`idNumber`] || null,
        serialNumber : this.form.controls['serialNumber'].disabled ? this.senderPrimaryDoc?.documentDetails.serialNumber : formValues[`serialNumber`] || null,
        motherName : this.form.controls['motherName'].disabled ? this.senderPrimaryDoc?.documentDetails.motherName : formValues[`motherName`] || null,
        fatherName : this.form.controls['fatherName'].disabled ? this.senderPrimaryDoc?.documentDetails.fatherName : formValues[`fatherName`] || null,
        nationality : this.form.controls['senderNationality'].disabled ? this.senderPrimaryDoc?.documentDetails.nationality : formValues[`senderNationality`]?.id || null,
        requiredFields: this.selectedDoctype?.requiredFields,
      },
      conductorId: this.customerTypeId == CustomerTypes.Corporate ? customerId : null
    };
  }

  onSubmit() {
    if (!this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]',
          );
          invalidControl.focus();
          break;
        }
      }
      return;
    }

    // @ts-ignore
    if (this.docFile?.length < this.numberOfAttachmentsRequired) {
      this.snackBar.open(
        `${this.numberOfAttachmentsRequired} attachments required.`,
      );
      return;
    }

    if(this.customerTypeId == CustomerTypes.Corporate && this.authorizedSignatureDocFile?.length < 1){
      this.snackBar.open(`authorizedSignatureAttachmentsRequired`);
      return;
    }

    if (!this.docFile.length && this.numberOfAttachmentsRequired > 0) {
      this.snackBar.open('pleaseSelectDocumentAttachment');
      return;
    }

    const formValues = this.form.getRawValue();
    if (
      formValues.remittanceSubType.isVerificationRequired &&
      !this.isReceiverVerified
    ) {
      this.snackBar.open('receiverIsNotVerified');
      return;
    }
    var name: any;
    var firstName: any;
    var lastName: any;
    if(this.customerTypeId == CustomerTypes.Corporate) {
      name = splitFullName(formValues?.companyTitle, true);
      firstName =  this.form.controls['senderFirstName'].disabled ? this.selectedSender?.firstName : name.firstName
      lastName =  this.form.controls['senderLastName'].disabled ? this.selectedSender?.lastName : name.lastName
    } else {
      firstName = this.form.controls['senderFirstName'].disabled ? this.selectedSender?.firstName : formValues.senderFirstName;
      lastName = this.form.controls['senderLastName'].disabled ? this.selectedSender?.lastName : formValues.senderLastName;
    }

    const senderDto: UpsertCustomerDto = {
      id: this.selectedSender?.id,
      firstName: firstName,
      middleName: this.form.controls['senderMiddleName'].disabled
        ? this.selectedSender?.middleName
        : formValues.senderMiddleName,
      lastName: lastName,
      mobilePhone: this.form.controls['senderMobilePhone'].disabled
        ? this.selectedSender?.mobilePhone
        : formValues.senderMobilePhone,
      addressLine1: formValues.senderAddressLine1,
      addressLine2: formValues.senderAddressLine2,
      genderId: this.form.controls['gender'].disabled
        ? this.selectedSender?.genderId
        : formValues.gender?.id,
      dateOfBirth: this.form.controls['dateOfBirth'].disabled
        ? this.selectedSender?.dateOfBirth
        : formValues.dateOfBirth ? moment(formValues.dateOfBirth).format('YYYY-MM-DD') : null,
      placeOfBirth: this.form.controls['placeOfBirth'].disabled
        ? this.selectedSender?.placeOfBirth
        : formValues.placeOfBirth,
      houseNumber: this.form.controls['houseNumber'].disabled
        ? this.selectedSender?.houseNumber
        : formValues.houseNumber,
      buildingNumber: this.form.controls['buildingNumber'].disabled
        ? this.selectedSender?.buildingNumber
        : formValues.buildingNumber,
      street: this.form.controls['street'].disabled
        ? this.selectedSender?.street
        : formValues.street,
      occupationId: this.form.controls['occupation'].disabled
        ? this.selectedSender?.occupationId
        : formValues.occupation?.id,
      sourceOfIncomeId: this.form.controls['sourceOfIncome'].disabled
        ? this.selectedSender?.sourceOfIncomeId
        : formValues.sourceOfIncome?.id,
      cityId: this.form.controls['senderCity'].disabled
        ? this.cities[0].id
        : formValues.senderCity.id,
      countryId: this.form.controls['senderCountry'].disabled
        ? this.sendingBranchCountry[0].id
        : formValues.senderCountry?.id,
      isSender: true,
      customerTypeId: this.customerTypeId
    };

    if (this.customerTypeId == CustomerTypes.Corporate) {
      senderDto.taxIdentificationNumber = this.form.controls['taxIdentificationNumber'].disabled ? this.selectedSender?.taxIdentificationNumber : formValues?.taxIdentificationNumber;
      senderDto.tradeRegistryNumber = this.form.controls['tradeRegistryNumber'].disabled ? this.selectedSender?.tradeRegistryNumber : formValues?.tradeRegistryNumber;
      senderDto.fieldOfActivity = this.form.controls['fieldOfActivity'].disabled ? this.selectedSender?.fieldOfActivity : formValues?.fieldOfActivity;
      senderDto.mobilePhone = this.form.controls['companyMobilePhone'].disabled ? this.selectedSender?.mobilePhone : formValues?.companyMobilePhone;
      senderDto.street = this.form.controls['companyStreet'].disabled ? this.selectedSender?.companyStreet : formValues?.companyStreet;
      senderDto.companyArea = this.form.controls['companyArea'].disabled ? this.selectedSender?.companyArea : formValues?.companyArea;
      senderDto.buildingNumber = this.form.controls['buildingNumber'].disabled ? this.selectedSender?.buildingNumber: formValues.companyBuildingNumber;
    }

    const receiverDto: UpsertCustomerDto = {
      id: formValues.existingReceivers?.id,
      firstName: formValues.receiverFirstName,
      middleName: formValues.receiverMiddleName,
      lastName: formValues.receiverLastName,
      mobilePhone: formValues.receiverMobilePhone,
      countryId: formValues.receivingCountry.id
    };

    const conductorDto: upsertConductor = {
      id: this.selectedConductor?.id,
      firstName: this.form.controls['conductorFirstName'].disabled ? this.selectedConductor?.firstName : formValues.conductorFirstName,
      lastName: this.form.controls['conductorLastName'].disabled ? this.selectedConductor?.lastName : formValues.conductorLastName,
      mobile: this.form.controls['conductorMobilePhone'].disabled ? this.selectedConductor?.mobile : formValues.conductorMobilePhone,
      dateOfBirth: this.form.controls['conductorDateOfBirth'].disabled ? this.selectedConductor?.dateOfBirth : moment(formValues.conductorDateOfBirth).format('YYYY-MM-DD'),
      placeOfBirth: this.form.controls['conductorPlaceOfBirth'].disabled ? this.selectedConductor?.placeOfBirth : formValues.conductorPlaceOfBirth,
      position: this.form.controls['conductorPosition'].disabled ? this.selectedConductor?.position : formValues.conductorPosition,
      fartherName: this.form.controls['conductorFartherName']?.disabled ? this.selectedConductor?.fartherName : formValues.conductorFartherName,
      motherName: this.form.controls['conductorMotherName']?.disabled ? this.selectedConductor?.motherName : formValues.conductorMotherName,
      nationalityId: this.form.controls['conductorNationality']?.disabled ? this.selectedConductor?.nationalityId : formValues.conductorNationality?.id
    };

    if (!this.isMobileOwnershipVerified) {
      this.customerService.checkCustomersWithSameMobile(senderDto).subscribe((response) => {
        const customerWithSameMobile = response;
        const mobilePhone = senderDto.mobilePhone;

        if(response){
          this.duplicateCustomers = customerWithSameMobile;
          return this.handleMobileOwnershipVerification(customerWithSameMobile, mobilePhone);
        }

        forkJoin([
          this.customerService.upsertCustomer(senderDto).pipe(catchError((error) => of(error))),
          this.customerService.upsertCustomer(receiverDto).pipe(catchError((error) => of(error)))
        ])
          .pipe(
            switchMap(([senderResp, receiverResp]: (CRUDResponseDto | Error)[]) => {
              if (senderResp instanceof Error || receiverResp instanceof Error) {
                return throwError(() =>
                  senderResp instanceof Error ? senderResp : receiverResp,
                );
              }
              const { id: senderId } = senderResp;
              const { id: receiverId } = receiverResp;
              const customerObservables: Observable<any>[] = [];

              if(this.customerTypeId == CustomerTypes.Corporate){
                conductorDto.customerId = senderId;
                customerObservables.push(this.customerService.upsertConductor(conductorDto));
              } else {
                customerObservables.push(of({id: null}));
              }

              return forkJoin(customerObservables).pipe(
                map(([conductorResp]) => {

                  if (conductorResp instanceof Error) {
                    return throwError(() =>
                      conductorResp,
                    );
                  }

                  const { id: conductorId } = conductorResp;

                  return [senderId, receiverId, conductorId];
                })
              );

            }),
            switchMap((value: any) => {
              const [senderId, receiverId, conductorId] = value;

              const docsObservables = [];


              if (formValues.primaryDocType) {

                if(this.customerTypeId == CustomerTypes.Corporate){
                  docsObservables.push(
                    this.customerService
                      .upsertConductorDocument(
                        this.prepareDocumentPayload(formValues, 'primary', conductorId),
                      )
                      .pipe(catchError((error) => of(error))),
                  );
                } else {
                  docsObservables.push(
                    this.customerService
                      .upsertCustomerDocument(
                        this.prepareDocumentPayload(formValues, 'primary', senderId),
                      )
                      .pipe(catchError((error) => of(error))),
                  );

                }
              } else {
                docsObservables.push(of({ id: null }));
              }
              if (formValues.secondaryDocType) {

                if (this.customerTypeId == CustomerTypes.Corporate){
                  docsObservables.push(
                    this.customerService
                      .upsertConductorDocument(
                        this.prepareDocumentPayload(
                          formValues,
                          'secondary',
                          conductorId,
                        ),
                      )
                      .pipe(catchError((error) => of(error))),
                  );
                } else {
                  docsObservables.push(
                    this.customerService
                      .upsertCustomerDocument(
                        this.prepareDocumentPayload(
                          formValues,
                          'secondary',
                          senderId,
                        ),
                      )
                      .pipe(catchError((error) => of(error))),
                  );
                }
              } else {
                docsObservables.push(of({ id: null }));
              }

              return forkJoin(docsObservables).pipe(
                map(
                  ([primaryDocResp, secondaryDocResp]: (
                    | CRUDResponseDto
                    | Error
                    )[]) => {
                    if (
                      primaryDocResp instanceof Error ||
                      secondaryDocResp instanceof Error
                    ) {
                      return throwError(() =>
                        primaryDocResp instanceof Error
                          ? primaryDocResp
                          : secondaryDocResp,
                      );
                    }
                    const { id: primaryDocId } = primaryDocResp;
                    const { id: secondaryDocId } = secondaryDocResp;
                    return [senderId, receiverId, conductorId,  primaryDocId, secondaryDocId];
                  },
                ),
              );
            }),
            switchMap((value: any) => {
              const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId] = value;
              const docsAttachmentObservables = [];
              if (this.senderPrimaryDocFile && primaryDocId) {
                docsAttachmentObservables.push(
                  this.customerService
                    .uploadDocumentAttachment(
                      primaryDocId,
                      this.senderPrimaryDocFile,
                      this.customerTypeId == CustomerTypes.Corporate ? 1 : 0
                    )
                    .pipe(catchError((error) => of(error))),
                );
              } else {
                docsAttachmentObservables.push(of(null));
              }
              if (this.senderSecondaryDocFile && secondaryDocId) {
                docsAttachmentObservables.push(
                  this.customerService
                    .uploadDocumentAttachment(secondaryDocId, [
                      this.senderSecondaryDocFile,
                    ], this.customerTypeId)
                    .pipe(catchError((error) => of(error))),
                );
              } else {
                docsAttachmentObservables.push(of(null));
              }

              return forkJoin(docsAttachmentObservables).pipe(
                map(([primaryDocResp, secondaryDocResp]) => {
                  if (
                    primaryDocResp instanceof Error ||
                    secondaryDocResp instanceof Error
                  ) {
                    return throwError(() =>
                      primaryDocResp instanceof Error
                        ? primaryDocResp
                        : secondaryDocResp,
                    );
                  }
                  const processResp = (
                    docId: number,
                    resp: CRUDResponseDto | null,
                    type: string,
                  ) => {
                    let docs =
                      type === 'primary'
                        ? this.senderPrimaryDocs
                        : this.senderSecondaryDocs;
                    let docInfo = this.prepareDocumentPayload(
                      formValues,
                      type,
                      senderId as number,
                    );
                    docInfo.documentPaths = resp?.data?.attachments || null;
                    if (docInfo.id) {
                      docs = docs.filter((d) => d.id !== docInfo.id);
                    } else {
                      docInfo.id = docId;
                    }
                    docInfo.displayName = `${
                      this.customerDocTypes.find(
                        (t) => t.id === docInfo.documentTypeId,
                      )?.name
                    } - ${docInfo.documentDetails.number}`;

                    docInfo = {
                      ...docInfo,
                      ...docInfo.documentDetails,
                    };
                    docs.push(docInfo);
                    if (type === 'primary') {
                      this.senderPrimaryDocs = docs;
                    } else {
                      this.senderSecondaryDocs = docs;
                    }
                    // this.onCustomerDocSelected(docInfo.id, type);
                  };

                  if (primaryDocId) {
                    processResp(primaryDocId, primaryDocResp, 'primary');
                  }
                  if (secondaryDocId) {
                    processResp(secondaryDocId, secondaryDocResp, 'secondary');
                  }

                  return [senderId, receiverId, conductorId, primaryDocId, secondaryDocId];
                }),
              );
            }),
            switchMap((value: any) => {
              const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId] = value;
              const authorizedDocAttachmentObservables = [];

              if(this.customerTypeId == CustomerTypes.Corporate && conductorId) {
                authorizedDocAttachmentObservables.push(
                  this.customerService
                    .uploadAuthorizedSignatureCustomerDocument(
                      conductorId,
                      this.authorizedSignatureDocFile
                    )
                    .pipe(catchError((error) => of(error))),
                );
              } else {
                authorizedDocAttachmentObservables.push(of({id: null}));
              }

              return  forkJoin(authorizedDocAttachmentObservables).pipe(
                map(([authorizedDocAttachmentResp]) => {

                  if(authorizedDocAttachmentResp instanceof Error){
                    return throwError(() => authorizedDocAttachmentResp);
                  }

                  return [senderId, receiverId, conductorId, primaryDocId, secondaryDocId, authorizedDocAttachmentResp];
                })
              );
            }),
            switchMap((value: any) => {
              const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId, authorizedDocAttachmentResp] = value;

              const remittanceDto: CreateRemittanceDto = {
                senderCustomerId: senderId as number,
                receiverCustomerId: receiverId as number,
                remittanceSubTypeId: formValues.remittanceSubType.id,
                remittanceTypeDetails: this.remittanceTypeDetails,
                primaryDocId: primaryDocId as number,
                secondaryDocId: secondaryDocId as number,
                receiverCityId: undefined,
                receiverCountryId: formValues.receivingCountry.id,
                paymentTypeId: formValues.paymentType.id,
                purposeId: formValues.purpose.id,
                relationshipId: formValues.relationship.id,
                amount: this.amount,
                senderAccountId: formValues.tellerAccount.id,
                sendingExchangeRate: +this.sendingExchangeRate,
                sendingAmount: +this.sendingAmount,
                sendingBranchExchangeRate: +this.sendingBranchExchangeRate,
                sendingBranchAmount: +formValues.sendingBranchAmount,
                commission: +this.commission,
                sendingCommission: +this.sendingCommission,
                sendingBranchCommission: +this.sendingBranchCommission,
                isCommissionIncludedInAmount: this.isCommissionIncludedInAmount,
                payingExchangeRate: +this.receiverExchangeRate,
                payingAmount: +formValues.receivingAmount,
                receivingAmount: +formValues.receivingAmount,
                receivingCountryCurrencyId: +formValues.receivingCurrency,
                receivingExchangeRate: +this.receiverExchangeRate,
                senderComments: formValues.senderComments,
                complianceComments: formValues.complianceComments,
                senderCustomer: {
                  firstName: senderDto.firstName,
                  middleName: senderDto.middleName,
                  lastName: senderDto.lastName,
                  mobilePhone: senderDto.mobilePhone,
                },
                receiverCustomer: {
                  firstName: receiverDto.firstName,
                  middleName: receiverDto.middleName,
                  lastName: receiverDto.lastName,
                  mobilePhone: receiverDto.mobilePhone,
                },
                authorizedSignatureFilePath: this.customerTypeId == CustomerTypes.Corporate ? authorizedDocAttachmentResp?.authorizedSignatureFilePath : null,
                conductorId: this.customerTypeId == CustomerTypes.Corporate ? conductorId : null,
              };
              return this.remittanceService.createRemittance(remittanceDto);
            }),
          )
          .subscribe(
            (resp) => {
              this.snackBar.open(resp?.message);
              this.sendReceiptPrintDialog(resp.id);
              this.resetForm();
            },
            (err) => {
              const errData = err?.cause?.error?.data;
              if (!errData) {
                this.snackBar.open(err.message);
                return;
              }

              this.handleComplianceResponse(errData as RemittanceComplianceDto[]);
            },
          );

    },
      ({ message }) => {
        this.snackBar.open(message);
      },);
    } else {
      forkJoin([
        this.customerService.upsertCustomer(senderDto).pipe(catchError((error) => of(error))),
        this.customerService.upsertCustomer(receiverDto).pipe(catchError((error) => of(error)))
      ])
        .pipe(
          switchMap(([senderResp, receiverResp]: (CRUDResponseDto | Error)[]) => {
            if (senderResp instanceof Error || receiverResp instanceof Error) {
              return throwError(() =>
                senderResp instanceof Error ? senderResp : receiverResp,
              );
            }
            const { id: senderId } = senderResp;
            const { id: receiverId } = receiverResp;
            const customerObservables: Observable<any>[] = [];

            if(this.customerTypeId == CustomerTypes.Corporate){
              conductorDto.customerId = senderId;
              customerObservables.push(this.customerService.upsertConductor(conductorDto));
            } else {
              customerObservables.push(of({id: null}));
            }

            return forkJoin(customerObservables).pipe(
              map(([conductorResp]) => {

                if (conductorResp instanceof Error) {
                  return throwError(() =>
                    conductorResp,
                  );
                }

                const { id: conductorId } = conductorResp;

                return [senderId, receiverId, conductorId];
              })
            );

          }),
          switchMap((value: any) => {
            const [senderId, receiverId, conductorId] = value;

            const docsObservables = [];


            if (formValues.primaryDocType) {

              if(this.customerTypeId == CustomerTypes.Corporate){
                docsObservables.push(
                  this.customerService
                    .upsertConductorDocument(
                      this.prepareDocumentPayload(formValues, 'primary', conductorId),
                    )
                    .pipe(catchError((error) => of(error))),
                );
              } else {
                docsObservables.push(
                  this.customerService
                    .upsertCustomerDocument(
                      this.prepareDocumentPayload(formValues, 'primary', senderId),
                    )
                    .pipe(catchError((error) => of(error))),
                );

              }
            } else {
              docsObservables.push(of({ id: null }));
            }
            if (formValues.secondaryDocType) {

              if (this.customerTypeId == CustomerTypes.Corporate){
                docsObservables.push(
                  this.customerService
                    .upsertConductorDocument(
                      this.prepareDocumentPayload(
                        formValues,
                        'secondary',
                        conductorId,
                      ),
                    )
                    .pipe(catchError((error) => of(error))),
                );
              } else {
                docsObservables.push(
                  this.customerService
                    .upsertCustomerDocument(
                      this.prepareDocumentPayload(
                        formValues,
                        'secondary',
                        senderId,
                      ),
                    )
                    .pipe(catchError((error) => of(error))),
                );
              }
            } else {
              docsObservables.push(of({ id: null }));
            }

            return forkJoin(docsObservables).pipe(
              map(
                ([primaryDocResp, secondaryDocResp]: (
                  | CRUDResponseDto
                  | Error
                  )[]) => {
                  if (
                    primaryDocResp instanceof Error ||
                    secondaryDocResp instanceof Error
                  ) {
                    return throwError(() =>
                      primaryDocResp instanceof Error
                        ? primaryDocResp
                        : secondaryDocResp,
                    );
                  }
                  const { id: primaryDocId } = primaryDocResp;
                  const { id: secondaryDocId } = secondaryDocResp;
                  return [senderId, receiverId, conductorId,  primaryDocId, secondaryDocId];
                },
              ),
            );
          }),
          switchMap((value: any) => {
            const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId] = value;
            const docsAttachmentObservables = [];
            if (this.senderPrimaryDocFile && primaryDocId) {
              docsAttachmentObservables.push(
                this.customerService
                  .uploadDocumentAttachment(
                    primaryDocId,
                    this.senderPrimaryDocFile,
                    this.customerTypeId == CustomerTypes.Corporate ? 1 : 0
                  )
                  .pipe(catchError((error) => of(error))),
              );
            } else {
              docsAttachmentObservables.push(of(null));
            }
            if (this.senderSecondaryDocFile && secondaryDocId) {
              docsAttachmentObservables.push(
                this.customerService
                  .uploadDocumentAttachment(secondaryDocId, [
                    this.senderSecondaryDocFile,
                  ], this.customerTypeId)
                  .pipe(catchError((error) => of(error))),
              );
            } else {
              docsAttachmentObservables.push(of(null));
            }

            return forkJoin(docsAttachmentObservables).pipe(
              map(([primaryDocResp, secondaryDocResp]) => {
                if (
                  primaryDocResp instanceof Error ||
                  secondaryDocResp instanceof Error
                ) {
                  return throwError(() =>
                    primaryDocResp instanceof Error
                      ? primaryDocResp
                      : secondaryDocResp,
                  );
                }
                const processResp = (
                  docId: number,
                  resp: CRUDResponseDto | null,
                  type: string,
                ) => {
                  let docs =
                    type === 'primary'
                      ? this.senderPrimaryDocs
                      : this.senderSecondaryDocs;
                  let docInfo = this.prepareDocumentPayload(
                    formValues,
                    type,
                    senderId as number,
                  );
                  docInfo.documentPaths = resp?.data?.attachments || null;
                  if (docInfo.id) {
                    docs = docs.filter((d) => d.id !== docInfo.id);
                  } else {
                    docInfo.id = docId;
                  }
                  docInfo.displayName = `${
                    this.customerDocTypes.find(
                      (t) => t.id === docInfo.documentTypeId,
                    )?.name
                  } - ${docInfo.documentDetails.number}`;

                  docInfo = {
                    ...docInfo,
                    ...docInfo.documentDetails,
                  };
                  docs.push(docInfo);
                  if (type === 'primary') {
                    this.senderPrimaryDocs = docs;
                  } else {
                    this.senderSecondaryDocs = docs;
                  }
                  // this.onCustomerDocSelected(docInfo.id, type);
                };

                if (primaryDocId) {
                  processResp(primaryDocId, primaryDocResp, 'primary');
                }
                if (secondaryDocId) {
                  processResp(secondaryDocId, secondaryDocResp, 'secondary');
                }

                return [senderId, receiverId, primaryDocId, secondaryDocId, conductorId];
              }),
            );
          }),
          switchMap((value: any) => {
            const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId] = value;
            const authorizedDocAttachmentObservables = [];

            if(this.customerTypeId == CustomerTypes.Corporate && conductorId) {
              authorizedDocAttachmentObservables.push(
                this.customerService
                  .uploadAuthorizedSignatureCustomerDocument(
                    conductorId,
                    this.authorizedSignatureDocFile
                  )
                  .pipe(catchError((error) => of(error))),
              );
            } else {
              authorizedDocAttachmentObservables.push(of({id: null}));
            }

            return  forkJoin(authorizedDocAttachmentObservables).pipe(
              map(([authorizedDocAttachmentResp]) => {

                if(authorizedDocAttachmentResp instanceof Error){
                  return throwError(() => authorizedDocAttachmentResp);
                }

                return [senderId, receiverId, conductorId, primaryDocId, secondaryDocId, authorizedDocAttachmentResp];
              })
            );
          }),
          switchMap((value: any) => {
            const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId] = value;
            const authorizedDocAttachmentObservables = [];

            if(this.customerTypeId == CustomerTypes.Corporate && conductorId) {
              authorizedDocAttachmentObservables.push(
                this.customerService
                  .uploadAuthorizedSignatureCustomerDocument(
                    conductorId,
                    this.authorizedSignatureDocFile
                  )
                  .pipe(catchError((error) => of(error))),
              );
            } else {
              authorizedDocAttachmentObservables.push(of({id: null}));
            }

            return  forkJoin(authorizedDocAttachmentObservables).pipe(
              map(([authorizedDocAttachmentResp]) => {

                if(authorizedDocAttachmentResp instanceof Error){
                  return throwError(() => authorizedDocAttachmentResp);
                }

                return [senderId, receiverId, conductorId, primaryDocId, secondaryDocId, authorizedDocAttachmentResp];
              })
            );
          }),
          switchMap((value: any) => {
            const [senderId, receiverId, conductorId, primaryDocId, secondaryDocId, authorizedDocAttachmentResp] = value;

            const remittanceDto: CreateRemittanceDto = {
              senderCustomerId: senderId as number,
              receiverCustomerId: receiverId as number,
              remittanceSubTypeId: formValues.remittanceSubType.id,
              remittanceTypeDetails: this.remittanceTypeDetails,
              primaryDocId: primaryDocId as number,
              secondaryDocId: secondaryDocId as number,
              receiverCityId: undefined,
              receiverCountryId: formValues.receivingCountry.id,
              paymentTypeId: formValues.paymentType.id,
              purposeId: formValues.purpose.id,
              relationshipId: formValues.relationship.id,
              amount: this.amount,
              senderAccountId: formValues.tellerAccount.id,
              sendingExchangeRate: +this.sendingExchangeRate,
              sendingAmount: +this.sendingAmount,
              sendingBranchExchangeRate: +this.sendingBranchExchangeRate,
              sendingBranchAmount: +formValues.sendingBranchAmount,
              commission: +this.commission,
              sendingCommission: +this.sendingCommission,
              sendingBranchCommission: +this.sendingBranchCommission,
              isCommissionIncludedInAmount: this.isCommissionIncludedInAmount,
              payingExchangeRate: +this.receiverExchangeRate,
              payingAmount: +formValues.receivingAmount,
              receivingAmount: +formValues.receivingAmount,
              receivingCountryCurrencyId: +formValues.receivingCurrency,
              receivingExchangeRate: +this.receiverExchangeRate,
              senderComments: formValues.senderComments,
              complianceComments: formValues.complianceComments,
              senderCustomer: {
                firstName: senderDto.firstName,
                middleName: senderDto.middleName,
                lastName: senderDto.lastName,
                mobilePhone: senderDto.mobilePhone,
              },
              receiverCustomer: {
                firstName: receiverDto.firstName,
                middleName: receiverDto.middleName,
                lastName: receiverDto.lastName,
                mobilePhone: receiverDto.mobilePhone,
              },
              authorizedSignatureFilePath: this.customerTypeId == CustomerTypes.Corporate ? authorizedDocAttachmentResp?.authorizedSignatureFilePath : null,
              conductorId: this.customerTypeId == CustomerTypes.Corporate ? conductorId : null,
            };
            return this.remittanceService.createRemittance(remittanceDto);
          }),
        )
        .subscribe(
          (resp) => {
            this.snackBar.open(resp?.message);
            this.sendReceiptPrintDialog(resp.id);
            this.resetForm();
          },
          (err) => {
            const errData = err?.cause?.error?.data;
            if (!errData) {
              this.snackBar.open(err.message);
              return;
            }

            this.handleComplianceResponse(errData as RemittanceComplianceDto[]);
          },
        );
      }

  }

  onRemittanceSubtypeChange(e: any) {
    this.showEbirrOptions();
  }

  onEbirrOptionsChange(type: string) {
    this.partialEbirrOptions = this.ebirrOptions;
  }

  onCityChange(cityId: number) {
    this.branchService.getLocalities(cityId).subscribe((localities) => {
      this.allLocalities = localities;
      this.localities = [...localities].filter((f) => f.isActive);
    });
  }

  onCountryChange(countryId: number) {
    this.branchService.branchCities(countryId).subscribe((cities) => {
      this.cities = cities;
    });
  }

  handleComplianceResponse(remittanceCompliances: RemittanceComplianceDto[]) {
    if (remittanceCompliances.find((rc) => rc.additionalInfoRequired)) {
      this.setFieldRequired('senderAddressLine1');
      this.setFieldRequired('senderAddressLine2');
      this.setFieldRequired('senderCity');
      this.setFieldRequired('senderCountry');
    }

    if (remittanceCompliances.find((rc) => rc.primaryDocRequired)) {
      this.setFieldRequired('primaryDocType');
      this.setFieldRequired('primaryDocNumber');
      this.setFieldRequired('primaryDocIssueDate');
      this.setFieldRequired('primaryDocExpiryDate');
      this.setFieldRequired('primaryDocIssuedBy');
    }

    /* if (remittanceCompliances.find(rc => rc.secondaryDocRequired)) {
      this.setFieldRequired('secondaryDocType');
      this.setFieldRequired('secondaryDocNumber');
      this.setFieldRequired('secondaryDocIssueDate');
      this.setFieldRequired('secondaryDocExpiryDate');
      this.setFieldRequired('secondaryDocIssuedBy');
    } */

    const dialogRef = this.dialog.open(RemittanceCompliancePopupComponent, {
      width: '850px',
      data: {
        remittanceCompliances,
      },
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      this.form.get('complianceComments')?.setValue(resp);
      this.form.updateValueAndValidity();
    });
  }

  setFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.setValidators([Validators.required]);
    ctrl.updateValueAndValidity();
  }

  unsetFieldRequired(ctrlName: string) {
    const ctrl = this.form.get(ctrlName);
    if (!ctrl) return;

    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
  }

  filterSenderDocs(excludedId: number | null, doctypes: LookupDto[]) {
    return this.senderDocs.filter(({ documentTypeId }) =>
      doctypes.find((t) => t.id === documentTypeId && t.id !== excludedId),
    );
  }

  filterDocTypes(isPrimary: boolean, excludedId: number | null) {
    return this.customerDocTypes.filter(
      (cdt: CustomerDocTypesResponseDto) =>
        (cdt.isPrimary === null || cdt.isPrimary === isPrimary) &&
        cdt.id !== excludedId,
    );
  }

  onPrimaryDocChange(event: any) {
    this.selectedDoctype = event.value;
    this.requiredFields = event.value?.requiredFields;
    this.isPassportDocTypeSelected = this.selectedDoctype?.name?.trim()?.toLowerCase() == 'passport';

    this.docFile = [];
    this.senderPrimaryDocFile = [];
    this.numberOfAttachmentsRequired = 0;
    this.secondaryDocTypes = this.filterDocTypes(false, event.value.id);

    if(this.requiredFields?.idNumberIsRequired) {
      this.form.controls['idNumber']?.enable();
      this.form.get('idNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);
      this.form.get('primaryDocNumber')?.updateValueAndValidity();
      this.form.controls['primaryDocNumber']?.patchValue('');
    } else {
      this.form.controls['idNumber']?.patchValue('');
      this.unsetFieldRequired('idNumber');
    }

    if(this.requiredFields?.motherNameIsRequired) {
      this.form.controls['motherName']?.enable();
      this.form.controls['motherName']?.patchValue('');
      this.form.get('motherName')?.setValidators([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(100),
          Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('motherName')?.updateValueAndValidity();
    } else {
      this.form.controls['motherName']?.patchValue('');
      this.unsetFieldRequired('motherName');
    }

    if(this.requiredFields?.nationalityIsRequired) {
      this.form.controls['senderNationality']?.enable();
      this.form.controls['senderNationality']?.patchValue('');
      this.form.get('senderNationality')?.setValidators([
        Validators.required
      ]);
      this.form.get('senderNationality')?.updateValueAndValidity();
    } else {
      this.form.controls['senderNationality']?.patchValue('');
      this.unsetFieldRequired('senderNationality');
    }

    if(this.requiredFields?.fatherNameIsRequired) {
      this.form.controls['fatherName']?.enable();
      this.form.controls['fatherName']?.patchValue('');
      this.form.get('fatherName')?.setValidators([
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern(regExp.alphaSpace),
      ]);
      this.form.get('fatherName')?.updateValueAndValidity();
    } else {
      this.form.controls['fatherName']?.patchValue('');
      this.unsetFieldRequired('fatherName');
    }


    if(this.requiredFields?.serialNumberIsRequired) {
      this.form.get('serialNumber')?.enable();
      this.form.get('serialNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
      ]);
      this.form.get('primaryDocNumber')?.updateValueAndValidity();
      this.form.controls['primaryDocNumber']?.patchValue('');
    } else {
      this.form.controls['serialNumber']?.patchValue('');
      this.unsetFieldRequired('serialNumber');
    }

    if(!this.requiredFields?.issueDateIsRequired) {
      this.unsetFieldRequired('primaryDocIssueDate');
    } else {
      this.setFieldRequired('primaryDocIssueDate');
    }
    if(!this.requiredFields?.expiryDateIsRequired) {
      this.unsetFieldRequired('primaryDocExpiryDate');
    } else {
      this.setFieldRequired('primaryDocExpiryDate')
    }

    if(!this.requiredFields?.documentIssuerIsRequired) {
      this.unsetFieldRequired('primaryDocIssuedBy');
    } else {
      this.setFieldRequired('primaryDocIssuedBy');
    }

    if(!this.requiredFields?.documentNumberIsRequired) {
      this.unsetFieldRequired('primaryDocNumber');
    } else {
      this.form.get('primaryDocNumber')?.setValidators([
        // @ts-ignore
        event.value.minimumLength ? Validators.required : null,
        Validators.minLength(event.value.minimumLength),
        Validators.maxLength(event.value.maximumLength || 50),
      ]);
      this.form.get('primaryDocNumber')?.updateValueAndValidity();
    }

    this.numberOfAttachmentsRequired = 0;
    if (event.value.isFirstAttachmentRequired)
      this.numberOfAttachmentsRequired = 1;
    if (event.value.isSecondAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
    if (event.value.isThirdAttachmentRequired)
      this.numberOfAttachmentsRequired += 1;
  }

  onSecondaryDocChange(event: any) {
    this.primaryDocTypes = this.filterDocTypes(true, event.value.id);
  }

  sendReceiptPrintDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceDetails(remittanceId)
      .subscribe((remittance) => {
        const dialogRef = this.dialog.open(
          RemittanceSendReceiptPopupComponent,
          {
            width: '850px',
            data: remittance,
          },
        );
        dialogRef.afterClosed().subscribe((resp) => {
          if (resp === 'close') {
            this.tranlateService.use(localStorage.getItem('lang') || 'en');
            if (this.duplicateCustomers.length) {
              this.customerService.deactivateDuplicateCustomers(this.duplicateCustomers).subscribe((response) => {
                this.duplicateCustomers = [];
              })
            }
          }
        });
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }

  getBranchCountryCity(selectedSender: boolean) {
    //todo: get branch country info on startup instead of getting it from teller account
    this.branchService
      .branchById(
        this.tellerAccounts[0].parentId ? this.tellerAccounts[0].parentId : 0,
      )
      .subscribe(([sendingBranch]) => {
        this.sendingBranchCountry = [
          this.allCountries.find(
            (f) => f.id === sendingBranch.countryId,
          ) as LookupDto,
        ];
        this.cities = this.senderCities.filter(
          (f) => f.parentId === this.sendingBranchCountry[0].id,
        );

        if (!selectedSender) this.onCityChange(this.cities[0].id);

        this.form.controls['senderCountry'].setValue(
          this.sendingBranchCountry[0],
        );
        // this.form.controls['senderCountry'].disable();
        this.form.controls['senderCity'].setValue(this.cities[0]);
        // this.form.controls['senderCity'].disable();
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }

  clearSelectedDocAttachment(file: any) {
    this.senderPrimaryDocFile = this.senderPrimaryDocFile?.filter(
      (list) => list.name !== file.name,
    );
    this.docFile = this.docFile?.filter((list) => list.name !== file.name);
    this.primaryDocFileUrl = [];
    this.primaryDocFileElem.nativeElement.value = null;
  }

  clearSelectedAuthorizedDocAttachment() {
    this.authorizedSignatureDocFile = [];
    this.authorizedSignatureDocs = [];
    this.authorizedSignatureDocFileUrl = [];
    this.authorizedSignatureFileUploadElem.nativeElement.value = null;
  }

  getFullName(firstName: string, middleName: string, lastName: string) {
    return middleName
      ? `${firstName} ${middleName} ${lastName}`
      : `${firstName} ${lastName}`;
  }

  onFocusout(event: any) {
    const regexLiteral = this.selectedDoctype.regularExpression;
    if(regexLiteral) {
      const [, pattern, flags] = regexLiteral?.match(/\/(.*)\/(.*)/);
      const regex = new RegExp(pattern, flags);
      if (!regex.test(event.target.value)) {
        this.form.get('primaryDocNumber')?.setErrors({invalid: true});
      } else {
        this.form.get('primaryDocNumber')?.setErrors(null);
      }
    }
  }

  onCustomerTypeChanged(event: any) {
    if(!event?.isCustomerSelected){
      this.resetForm();
    }
    this.customerTypeId = event.value.id || event.value;
    this.requiredFields = {};

    if(this.customerTypeId == CustomerTypes.Corporate){
      this.panelOpenState = true;
      this.primaryDocTypes = this.filterDocTypes(true, null);
      this.setFieldRequired('companyTitle');
      this.setFieldRequired('taxIdentificationNumber');
      this.setFieldRequired('tradeRegistryNumber');
      this.setFieldRequired('fieldOfActivity');
      this.setFieldRequired('fieldOfActivity');
      this.setFieldRequired('companyMobilePhone');
      this.setFieldRequired('companyCountry');
      this.setFieldRequired('companyCity');
      this.setFieldRequired('companyStreet');
      this.setFieldRequired('companyBuildingNumber');
      this.setFieldRequired('companyArea');
      this.setFieldRequired('position');
      this.setFieldRequired('conductorFartherName');
      this.setFieldRequired('conductorMotherName');
      this.setFieldRequired('conductorFirstName');
      this.setFieldRequired('conductorLastName');
      this.setFieldRequired('conductorMobilePhone');
      this.setFieldRequired('conductorDateOfBirth');
      this.setFieldRequired('conductorPlaceOfBirth');
      this.setFieldRequired('conductorPosition');
      this.unsetFieldRequired('buildingNumber');
      this.unsetFieldRequired('gender');
      this.unsetFieldRequired('occupation');
      this.unsetFieldRequired('sourceOfIncome');
      this.unsetFieldRequired('street');
      this.unsetFieldRequired('houseNumber');
      this.unsetFieldRequired('senderFirstName');
      this.unsetFieldRequired('senderLastName');
      this.unsetFieldRequired('senderMobilePhone');
      this.unsetFieldRequired('dateOfBirth');
      this.unsetFieldRequired('placeOfBirth');

      this.unsetFieldRequired('remittanceType');
      this.unsetFieldRequired('primaryDocType');
      this.unsetFieldRequired('primaryDocNumber');
      this.unsetFieldRequired('primaryDocIssueDate');
      this.unsetFieldRequired('primaryDocExpiryDate');
      this.unsetFieldRequired('primaryDocExpiryDate');
      this.unsetFieldRequired('primaryDocIssuedBy');
    } else {
      this.panelOpenState = false;
      this.primaryDocTypes = this.filterDocTypes(true, null);
      this.unsetFieldRequired('companyTitle');
      this.unsetFieldRequired('taxIdentificationNumber');
      this.unsetFieldRequired('tradeRegistryNumber');
      this.unsetFieldRequired('fieldOfActivity');
      this.unsetFieldRequired('companyMobilePhone');
      this.unsetFieldRequired('companyCountry');
      this.unsetFieldRequired('companyCity');
      this.unsetFieldRequired('companyStreet');
      this.unsetFieldRequired('companyBuildingNumber');
      this.unsetFieldRequired('companyArea');
      this.unsetFieldRequired('position');
      this.unsetFieldRequired('conductorFartherName');
      this.unsetFieldRequired('conductorMotherName');

      this.unsetFieldRequired('conductorFartherName');
      this.unsetFieldRequired('conductorMotherName');
      this.unsetFieldRequired('conductorFirstName');
      this.unsetFieldRequired('conductorLastName');
      this.unsetFieldRequired('conductorMobilePhone');
      this.unsetFieldRequired('conductorDateOfBirth');
      this.unsetFieldRequired('conductorPlaceOfBirth');
      this.unsetFieldRequired('conductorPosition');

      this.setFieldRequired('gender');
      this.setFieldRequired('occupation');
      this.setFieldRequired('sourceOfIncome');
      this.setFieldRequired('street');
      this.setFieldRequired('buildingNumber');
      this.setFieldRequired('houseNumber');
      this.setFieldRequired('senderFirstName');
      this.setFieldRequired('senderLastName');
      this.setFieldRequired('senderMobilePhone');
      this.setFieldRequired('dateOfBirth');
      this.setFieldRequired('placeOfBirth');
      this.setFieldRequired('remittanceType');
    }
  }

  onConductorChanged(event: any){
    this.selectedConductor = event;

    this.senderDocs = this.selectedConductor?.conductorDocument
      ?.map((d: any) => ({
        ...d,
        displayName: `${
          this.customerDocTypes.find((t) => t.id === d.documentTypeId)
            ?.name
        } - ${d.documentDetails.number}`,
        ...d.documentDetails
      }))
      .filter((doc: any) => doc.isActive) || [];

    this.senderPrimaryDocs = this.filterSenderDocs(
      null,
      this.primaryDocTypes,
    );



    this.form.controls['conductorFirstName'].setValue(event?.firstName);
    this.form.controls['conductorFirstName'].disable();

    this.form.controls['conductorLastName'].setValue(event?.lastName);
    this.form.controls['conductorLastName'].disable();

    this.form.controls['conductorMobilePhone'].setValue(event?.mobile);
    this.form.controls['conductorMobilePhone'].disable();

    this.form.controls['conductorDateOfBirth'].setValue(event?.dateOfBirth);
    this.form.controls['conductorDateOfBirth'].disable();

    this.form.controls['conductorPlaceOfBirth'].setValue(event?.placeOfBirth);
    this.form.controls['conductorPlaceOfBirth'].disable();

    this.form.controls['conductorPosition'].setValue(event?.position);
    this.form.controls['conductorPosition'].disable();

    this.form.controls['conductorFartherName'].setValue(event?.fartherName);
    this.form.controls['conductorFartherName'].disable();

    this.form.controls['conductorMotherName'].setValue(event?.motherName);
    this.form.controls['conductorMotherName'].disable();

    const conductorCountry = this.allCountries.find((f) => f.id === event?.nationalityId);
    this.form.controls[`conductorNationality`].setValue(conductorCountry);
    this.form.controls['conductorNationality'].disable();
    //@ts-ignore
    this.isThisTrkCountry = conductorCountry?.iso2 == CountryISO2s.TURKEY;
  }

  onConductorCleared(){
    this.form.controls['conductorFirstName'].setValue('');
    this.form.controls['conductorLastName']?.setValue('');
    this.form.controls['conductorMobilePhone']?.setValue('');
    this.form.controls['conductorDateOfBirth']?.setValue('');
    this.form.controls['conductorPlaceOfBirth']?.setValue('');
    this.form.controls['conductorFartherName']?.setValue('');
    this.form.controls['conductorMotherName']?.setValue('');
    this.form.controls['conductorNationality']?.setValue('');
    this.form.controls['conductorPosition']?.setValue('');

    this.form.controls['conductorFirstName'].enable();
    this.form.controls['conductorLastName'].enable();
    this.form.controls['conductorMobilePhone'].enable();
    this.form.controls['conductorDateOfBirth'].enable();
    this.form.controls['conductorPlaceOfBirth'].enable();
    this.form.controls['conductorFartherName'].enable();
    this.form.controls['conductorMotherName'].enable();
    this.form.controls['conductorNationality'].enable();
    this.form.controls['conductorPosition'].enable();

    this.selectedConductor = null;
  }

  onConductorCountrySelected(event: any){
   this.isThisTrkCountry = event.value?.iso2 == CountryISO2s.TURKEY;

   if(!this.isThisTrkCountry){
     this.unsetFieldRequired('conductorFartherName');
     this.unsetFieldRequired('conductorMotherName');
   } else {
     this.setFieldRequired('conductorFartherName');
     this.setFieldRequired('conductorMotherName');
   }
  }

  handleMobileOwnershipVerification(customerWithSameMobile: any, mobilePhone: string) {
    const dialogRef = this.dialog.open(MobileOwnershipVerifPopupComponent, {
      width: '850px',
      data: {
        customerWithSameMobile, mobilePhone
      },
    });

    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') {
        this.duplicateCustomers = [];
        return;
      }

      this.isMobileOwnershipVerified = resp;
      this.onSubmit();

    });
  }


}
