<h1 mat-dialog-title>{{ 'updateStatus' | translate }}</h1>
<div mat-dialog-content>
  <div class="py-1 overflow-y-auto">
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2 overflow-hidden"
      *ngIf="data"
    >
      <div
        class="d-flex align-items-center h-9x bg-light px-2 border-bottom border-dark"
      >
        <b>{{ 'roleRequestDetails' | translate }}</b>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'userType' | translate }}:
          <p>{{ data.newUserType }}</p>
        </div>
        <div class="col">
          {{ 'requestingUser' | translate }}:
          <p>{{ data.requestedBy }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'name' | translate }}:
          <p>{{ data.newName }}</p>
        </div>
        <div class="col">
          {{ 'description' | translate }}:
          <p>{{ data.newDescription }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'isActive' | translate }}:
          <p>{{ data.newIsActive }}</p>
        </div>
        <div class="col">
          {{ 'requestDate' | translate }}:
          <p>{{ convertDateToLocaleString(data.createdDate) }}</p>
        </div>
        <div class="col">
          {{ 'requesterComments' | translate }}:
          <p>{{ data.requesterComments }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom mb-5">
        <div class="row" *ngFor="let group of permissions">
          <h2>{{ group.name }}</h2>
          <div class="col-6" *ngFor="let subGroup of group.subGroups">
            <h3>{{ subGroup.name }}</h3>
            <ul>
              <li *ngFor="let permission of subGroup.permissions">
                {{ permission.displayName }}
              </li>
            </ul>
          </div>
        </div>
      </div>      
    </div>
  </div>

  <!-- ... -->
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-label class="mb-4">{{ 'status' | translate }}:</mat-label>
      <mat-radio-group formControlName="statusType" class="mb-4">
        <mat-radio-button value="approveRoleRequest">
          {{ 'approveRoleRequest' | translate }}
        </mat-radio-button>
        <mat-radio-button value="rejectRoleRequest">
          {{ 'rejectRoleRequest' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">{{ 'close' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!form.valid"
    (click)="onSubmit()"
  >
    {{ 'update' | translate }}
  </button>
</div>
