import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Utilities } from '../_common/utils';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-account-activity-report',
  templateUrl: './accountActivityReportPopup.component.html',
})
export class AccountActivityReportPopupComponent {
  displayedColumns: string[] = [
    'date',
    'transNo',
    'description',
    'debit',
    'credit',
    'balance',
  ];
  debitSum!: string;
  creditSum!: string;
  balanceSum: string = '';
  sortedData!: any[];
  constructor(
    public dialogRef: MatDialogRef<AccountActivityReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.sortedData = data.accountTransactions;
    if (this.sortedData[this.sortedData.length - 1].description === 'Sums') {
      const sums = this.sortedData.pop();
      this.debitSum = sums.debit;
      this.creditSum = sums.credit;
      this.balanceSum = sums.balance;
    }
  }

  print = () => {
    const printContent = document.getElementById('accountActivity');
    const WindowPrt = window.open(
      '',
      '',
      'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0',
    );
    WindowPrt?.document.write(printContent!.innerHTML);
    WindowPrt?.document.close();
    WindowPrt?.focus();
    WindowPrt?.print();
  };
  sortData(sort: Sort) {
    const data = this.data.accountTransactions.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
    } else {
      this.sortedData = data.sort((a: any, b: any) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  exportToExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(
      document.getElementById('accountsData'),
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const fileName =
      'Account Activity on ' +
      this.convertDateToLocaleString(new Date()) +
      '.xlsx';
    XLSX.writeFile(wb, fileName);
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
