import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { CustomerBlacklistSantionedAuditLogsFilter, CustomerFilter, CustomerService } from './data/customer.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CustomerModificationChangeLogPopupComponent } from './customerModificationChangeLogPopup.component';
import { Utilities } from '../_common/utils';


@Component({
    selector: 'app-customers',
    templateUrl: './blacklistSanctionedAuditLogs.component.html',
    styleUrls: ['../_common/styles/table.less'],
})
export class BlacklistSactionedAuditLogsComponent implements OnInit {
    // @ts-ignore
    @ViewChild(MatPaginator) paginator: MatPaginator;
    form!: UntypedFormGroup;

    permissions = Permissions.customerManagement.blackListSanctionAuditLogs;
    loading = false;
    resultsLength = 0;
    displayedColumns: string[] = [
        'number',
        'action',
        'stage',
        'name',
        'dateCreated',
        'actions'
    ];
    startDate: Date | null = null
    endDate: Date | null = null;
    dataSource = new MatTableDataSource<any>();
    constructor(
        private fb: UntypedFormBuilder,
        private customerService: CustomerService,
        public authService: AuthService,
        private dialog: MatDialog,
    ) { 

        this.startDate = new Date();
        this.endDate = new Date();

        this.form = this.fb.group({
            startDate: [this.startDate], 
            endDate: [this.endDate],     
        });
    }


    ngOnInit() {
        this.loadData();
    } 

    loadData() {
        this.customerService.customersBlacklistSacntinedAuditLogs.subscribe(
            ({ customersBlacklistSanctionAuditLogs, appliedFilters }) => {
                this.loading = false;
                this.dataSource.data = customersBlacklistSanctionAuditLogs;
                this.dataSource.paginator = this.paginator;
                this.resultsLength = customersBlacklistSanctionAuditLogs.length;
                this.form.controls['startDate'].setValue(appliedFilters?.startDate);
                this.form.controls['endDate'].setValue(appliedFilters?.endDate);
            },
        );
    }

    onSubmit() {
        const { startDate, endDate } = this.form.value;
        const filter: CustomerBlacklistSantionedAuditLogsFilter = {
            startDate: startDate,
            endDate: endDate,
        };
        this.customerService.searchCustomersBlacklistSanctionAuditLogs(filter);
    }

    viewChangeLog(matchedEntity: any) {
        this.dialog.open(CustomerModificationChangeLogPopupComponent, {
            disableClose: true,
            width: '54rem',
            data: matchedEntity,
        });
    }

    convertDateToLocaleString(date: Date, type: string) {
        return Utilities.convertDateToLocaleString(date, '');
    }

}
