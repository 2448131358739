<h1 mat-dialog-title>{{ 'editConductor' | translate }}</h1>
<form
  form
  class="container-fluid flex-column p-0"
  name="form"
  [formGroup]="form"
  #conductorForm
  novalidate>

<mat-card>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'firstName' | translate }}:</mat-label>
          <input matInput #conductorFirstName formControlName="conductorFirstName" />
        </mat-form-field>
        <app-validation-error [control]="$any(form.get('conductorFirstName'))"></app-validation-error>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'lastName' | translate }}:</mat-label>
          <input matInput #conductorLastName formControlName="conductorLastName" />
        </mat-form-field>
        <app-validation-error [control]="$any(form.get('conductorLastName'))"></app-validation-error>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'mobile' | translate }}:</mat-label>
          <input matInput #conductorMobilePhone formControlName="conductorMobilePhone" />
        </mat-form-field>
        <app-validation-error [control]="$any(form.get('conductorMobilePhone'))"></app-validation-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'placeOfBirth' | translate }}:</mat-label>
          <input matInput #conductorPlaceOfBirth formControlName="conductorPlaceOfBirth" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'dob' | translate }}</mat-label>
          <input matInput [max]="maxDOB" [matDatepicker]="dob" formControlName="conductorDateOfBirth" />
          <mat-hint>DD-MM-YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
          <mat-datepicker startView="month" #dob></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline" class="col-md-4">
        <mat-label>{{ 'position' | translate }}:</mat-label>
        <input matInput #conductorPosition formControlName="conductorPosition" />
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'nationality' | translate }}</mat-label>
          <mat-select formControlName="conductorNationality">
            <mat-option *ngFor="let country of allCountries" [value]="country">{{ country.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'fatherName' | translate }}:</mat-label>
          <input matInput #conductorFartherName formControlName="conductorFartherName" />
        </mat-form-field>
        <app-validation-error [control]="$any(form.get('conductorFartherName'))"></app-validation-error>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="col-12">
          <mat-label>{{ 'motherName' | translate }}:</mat-label>
          <input matInput #conductorMotherName formControlName="conductorMotherName" />
        </mat-form-field>
        <app-validation-error [control]="$any(form.get('conductorMotherName'))"></app-validation-error>
      </div>
    </div>
</mat-card>

<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'updateConductor' | translate }}
  </button>
</div>
</form>
