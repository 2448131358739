<div mat-dialog-title>
  <h1>{{ 'remittancePurpose' | translate }}</h1>
</div>
<form
  class="container-fluid flex-column p-0"
  id="form"
  name="form"
  [formGroup]="form"
  novalidate
  (submit)="onSubmit()"
>
  <div mat-dialog-content>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'purposeName' | translate }}:</mat-label>
        <input matInput #purposeName formControlName="purposeName" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'code' | translate }}:</mat-label>
        <input matInput #code formControlName="purposeCode" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-checkbox
        matInput
        class="mx-1"
        color="primary"
        formControlName="isActive"
        checked
        value="true"
      >
        {{ 'isActive' | translate }}
      </mat-checkbox>
    </div>
  </div>
  <div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-raised-button type="submit" color="primary">
      {{ 'submit' | translate }}
    </button>
  </div>
</form>
