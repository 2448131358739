import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CurrentUser } from './currentUser.model';
import { UsersService } from '../../user/data/users.service';
import { HttpService } from '../../_common/data/http.service';
import { LoginRequest } from './loginRequest.dto';
import { LoginTypes } from 'src/app/_common/enums/loginTypes.enums';

const CURRENT_USER = 'currentUser';
const AUTH_TOKEN = 'authToken';
const REFRESH_TOKEN = 'refreshToken';
interface LoginResp {
  authToken: string;
  refreshToken: string;
  responseType: string;
  pageTitle: string;
  message: string;
}

export interface OtpDto {
  qrCodeImg: string;
  isSmsCodeValid: boolean;
  smsCode: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  // @ts-ignore
  private currentUserSubject: BehaviorSubject<CurrentUser>;
  // @ts-ignore
  currentUser: Observable<CurrentUser>;
  authToken: string | null = null;
  refreshToken: string | null = null;

  constructor(
    private userService: UsersService,
    private httpService: HttpService,
  ) {
    this.authToken = localStorage.getItem(AUTH_TOKEN) || '';
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN) || '';

    const storageUser = localStorage.getItem(CURRENT_USER);
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(
      storageUser ? JSON.parse(storageUser) : null,
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  get currentUserValue(): CurrentUser | null {
    return this.currentUserSubject.value;
  }

  get isLoggedIn(): boolean {
    if (!this.authToken) {
      return false;
    }

    return true;
    //const parsedPayload = JSON.parse(atob(this.authToken.split('.')[1])); // decode payload of token and convert payload into an Object
    //return parsedPayload.exp > Date.now() / 1000; // check if token is expired
  }

  login(requestData: LoginRequest): Observable<LoginResp> {
    let requestUrl;
    if (requestData.forceResetPassword) {
      requestUrl = 'auth/resetpassword';
    } else if (requestData.loginType == LoginTypes.GOOGLE) {
      requestUrl = 'auth/loginwithgoogle';
    } else if (requestData.loginType == LoginTypes.SMS) {
      requestUrl = 'auth/loginwithsms';
    } else if (requestData.loginType == LoginTypes.CONFIGOTP) {
      requestUrl = 'auth/generateqrcode';
    } else if (requestData.loginType == LoginTypes.ENABLE2FA) {
      requestUrl = 'auth/setotpenabled';
    } else if (requestData.loginType == LoginTypes.SENDFORGOTPASSWORDEMAIL) {
      requestUrl = 'auth/sendforgotpasswordemail';
    }else if (requestData.loginType == LoginTypes.FORGOTPASSWORD) {
      requestUrl = 'auth/forgotpassword';
    } else {
      requestUrl = 'auth/agent/login';
    }
    return this.httpService.post<LoginResp>(`${requestUrl}`, requestData);
  }

  refreshAuthToken(): Observable<LoginResp> {
    return this.httpService.post<LoginResp>(`auth/refresh`, {});
  }

  setAuthToken(data: Observable<LoginResp>): Observable<boolean> {
    return data.pipe(
      switchMap(({ authToken, refreshToken }) => {
        this.authToken = authToken;
        this.refreshToken = refreshToken;
        return this.userService.userDetails();
      }),
      map((user) => {
        if (localStorage.getItem(AUTH_TOKEN)) {
          window.location.href = '/login';
          return false;
        } else {
          localStorage.setItem(AUTH_TOKEN, this.authToken as string);
          localStorage.setItem(REFRESH_TOKEN, this.refreshToken as string);
          localStorage.setItem(CURRENT_USER, JSON.stringify(user));
        }
        this.currentUserSubject.next(user as CurrentUser);
        return true;
      }),
    );
  }

  setNewAuthAndRefreshTokens(data: any) {
    this.authToken = data.authToken;
    this.refreshToken = data.refreshToken;
    localStorage.setItem(AUTH_TOKEN, this.authToken as string);
    localStorage.setItem(REFRESH_TOKEN, this.refreshToken as string);
  }

  isUserAuthorized(requiredPermission: string | string[]): boolean {
    if (!requiredPermission || !requiredPermission.length) {
      return true;
    }

    const userPermissions = this.currentUserValue?.permissions;
    if (!userPermissions || !userPermissions.length) {
      return false;
    }

    const permissions = Array.isArray(requiredPermission)
      ? requiredPermission
      : [requiredPermission];
    return permissions.some((p) => userPermissions.includes(p));
  }
  isCurrentUser(userId: any) {
    return this.currentUserValue?.id == userId;
  }
  logout() {
    this.httpService.post(`auth/logout`, {}).subscribe((o) => {
      localStorage.clear();
      window.location.href = '/login';
    });
  }
}
