<app-index-container label="{{ 'users' | translate }}">
  <button
    headerActions
    *ngIf="authService.isUserAuthorized(permissions.CREATE_USER)"
    mat-raised-button
    color="primary"
    routerLink="/users/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addUser' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <div class="col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'username' | translate }}:</mat-label>
          <input matInput #username formControlName="userName" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'userType' | translate }}:</mat-label>
          <mat-select #userTypeId formControlName="userTypeId">
            <mat-option
              *ngFor="let userType of userTypes"
              [value]="userType.id"
            >
              {{
                userType.name
              }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'userStatus' | translate }}:</mat-label>
          <mat-select #uStatus formControlName="statusId">
            <mat-option value="1">
              {{ 'active' | translate }}
            </mat-option>
            <mat-option value="0">
              {{ 'inactive' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'branchCode' | translate }}:</mat-label>
          <input matInput #branchCode formControlName="branchCode" />
        </mat-form-field>
      </div>

      <!-- <div class="col-md-4 mt-4">
        <mat-checkbox matInput #isActive color="primary" formControlName="isActive" class="col d-flex align-items-center pb-3">
          {{'isActive' | translate}}
        </mat-checkbox>
      </div> -->
    </div>
    <div class="col-md-12">
      <div class="d-flex justify-content-end">
        <button
          mat-raised-button
          [disabled]="!form.valid"
          type="submit"
          color="primary"
        >
          <i class="fa-solid fa-magnifying-glass"></i>
          {{ 'search' | translate }}
        </button>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Username Column -->
    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>{{ 'username' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.username }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.name }}</td>
    </ng-container>
    <!-- Role Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>{{ 'role' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.role }}</td>
    </ng-container>
    <!-- User Type Column -->
    <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef>{{ 'userType' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.userType }}</td>
    </ng-container>
    <!-- Branch Column -->
    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef>{{ 'branch' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{
          row.branch === 'CPO Branch' ? ('hqBranch' | translate) : row.branch
        }}
      </td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ (row.isActive ? 'active' : 'inactive') | translate }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="
              authService.isUserAuthorized([
                permissions.UPDATE_USER,
                permissions.VIEW_USER
              ])
            "
            color="primary"
            routerLink="/users/view/{{ row.id }}"
          >
            <mat-icon>{{
              authService.isUserAuthorized(permissions.UPDATE_USER)
                ? 'edit'
                : 'visibility'
            }}</mat-icon>
            {{
              (authService.isUserAuthorized(permissions.UPDATE_USER)
                ? 'edit'
                : 'view'
              ) | translate
            }}</a
          >
          <a
            mat-menu-item
            *ngIf="canResetPassword(row.username) && row.isActive"
            class="primary"
            (click)="resetUserPassword(row.id)"
          >
            <mat-icon>vpn_key</mat-icon>
            {{ 'resetPassword' | translate }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
