<app-index-container label="{{ 'remittances' | translate }}">
  <button
    headerActions
    *ngIf="
      authService.isUserAuthorized(permissions.CREATE_REMITTANCE) && !isAdmin
    "
    mat-raised-button
    color="primary"
    routerLink="/remittances/add"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addRemittance' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row mb-3">
      <div class="col-sm-6 col-md-6 col-lg-3">
        <mat-radio-group
          aria-label="Select an option"
          class="mt-2"
          matInput
          formControlName="direction"
        >
          <!-- <mat-radio-button
            class="material-radio"
            checked
            value="1"
            (change)="onChangeDirection($event.value)"
            >{{ 'incoming' | translate }}</mat-radio-button
          > -->
          <mat-radio-button
            class="material-radio"
            checked
            value="2"
            style="margin-left: 5px"
            (change)="onChangeDirection($event.value)"
            >{{ 'outgoing' | translate }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div class="col-sm-6 col-md-3 col-lg-3" *ngIf="direction === '1'">
        <mat-checkbox
          matInput
          class="mb-3"
          color="primary"
          formControlName="includePartnerRemittance"
        >
          {{ 'includePartnerRemittance' | translate }}
        </mat-checkbox>
      </div>
    </div>

   
      <div class="row mt-4 mb-2">
        <div class="col-4 mx-1">
          <label for="Customer Status">Remittance Filter Type</label>
          <mat-radio-group formControlName="remittanceFilterType" class="col-3 mb-2 mt-3" (change)="onRemittanceFilterTypeChanged($event)">
            <mat-radio-button [value]="1" checked style="margin-left: 8px;">
              Remittance Code
            </mat-radio-button>
      
            <mat-radio-button [value]="2" style="margin-left: 8px;">
              Remittance Id
            </mat-radio-button>
      
            <mat-radio-button [value]="3" style="margin-left: 8px;">
              Partner Remittance Id
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

    <div class="row">

      <!-- <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceCode' | translate }}:</mat-label>
        <input matInput #remittanceCode formControlName="remittanceCode"/>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'remittanceId' | translate }}:</mat-label>
        <input matInput #remittanceId formControlName="remittanceId"/>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'partnerRemittanceId' | translate }}:</mat-label>
        <input matInput #partnerRemittanceId formControlName="partnerRemittanceId"/>
      </mat-form-field> -->

      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'Remittance Number' | translate }}:</mat-label>
        <input matInput #remittanceCode formControlName="remittanceNumber" />
      </mat-form-field>

      <!-- <mat-form-field
        appearance="outline"
        class="col"
        *ngIf="direction === '2'"
      >
        <mat-label>{{ 'senderMobile' | translate }}:</mat-label>
        <input matInput #senderMobile formControlName="senderMobile" />
      </mat-form-field> -->
      <mat-form-field
        appearance="outline"
        class="col"
        *ngIf="direction === '2'"
      >
        <mat-label>sender name or sender mobile':</mat-label>
        <input matInput #senderNameOrMobile formControlName="senderNameOrMobile" />
      </mat-form-field>

      <!-- <mat-form-field
        appearance="outline"
        class="col"
        *ngIf="direction === '1'"
      >
        <mat-label>{{ 'receiverMobile' | translate }}:</mat-label>
        <input matInput #receiverMobile formControlName="receiverMobile" />
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="col"
        *ngIf="direction === '1'"
      >
        <mat-label>{{ 'receiverName' | translate }}:</mat-label>
        <input matInput #receiverName formControlName="receiverName" />
      </mat-form-field> -->
    </div>
    <div class="row">
      <div appearance="fill" class="col">
        <!-- <mat-label class="mb-1">{{ 'status' | translate }}:</mat-label> -->
        <ng-select formControlName="statusId" placeholder="status" [disabled]="!form.get('remittanceNumber')?.value || 
          !form.get('senderNameOrMobile')?.value ">
          <ng-option *ngFor="let remittanceStatus of remittanceStatuses" [value]="remittanceStatus.id">
          {{ remittanceStatus.name }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row" *ngIf="direction === '2'">
      <div appearance="fill" class="col">
        <ng-select
          formControlName="parentBranchId"
          (change)="onParentBranchSelected($event)"
          placeholder="{{ 'parentBranch' | translate }}"
          [clearable]="false"
        >
          <ng-option value="" *ngIf="isAdmin">{{
            'all' | translate
          }}</ng-option>
          <ng-option *ngFor="let pBranch of parentBranches" [value]="pBranch.id"
            >{{ pBranch.code + ' - ' + pBranch.title }}
          </ng-option>
        </ng-select>
      </div>
      <div appearance="fill" class="col-sm-6 col-md-6 col-lg-6">
        <ng-select
          formControlName="branchId"
          placeholder="{{ 'branch' | translate }}"
          [clearable]="false"
        >
          <ng-option value="" *ngIf="isAdmin || isRegional">{{
            'all' | translate
          }}</ng-option>
          <ng-option *ngFor="let branch of branches" [value]="branch.id"
            >{{ branch.code + ' - ' + branch.title }}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row">
      <!--      <mat-form-field appearance="outline" class="col">
        <mat-label>{{'senderBranch' | translate}}:</mat-label>
        <mat-select #senderBranchId formControlName="senderBranchId">
          <mat-option *ngFor="let branch of senderBranches" [value]="branch.id">
            {{branch.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>-->
    </div>
    <div class="d-flex justify-content-end">
      <button mat-raised-button type="submit" color="primary">
        <i class="fa-solid fa-magnifying-glass"></i> {{ 'search' | translate }}
      </button>
    </div>
  </form>
  <table table mat-table [dataSource]="remittances" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="remittanceId">
      <th mat-header-cell *matHeaderCellDef>{{ 'remittanceId' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.code }}</td>
    </ng-container>

    <ng-container matColumnDef="senderName">
      <th mat-header-cell *matHeaderCellDef> {{ 'senderFullName' | translate}} </th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.senderName" matTooltipPosition="after">
        {{ row.senderName }} &nbsp;<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="receiverName">
      <th mat-header-cell *matHeaderCellDef> {{ 'receiverFullName' | translate}} </th>
      <td mat-cell *matCellDef="let row" [matTooltip]="row.receiverName" matTooltipPosition="after">
        {{ row.receiverName }} &nbsp;<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>{{ 'amount' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.amount | number:'1.2-2'  }}{{' '+row.currency}}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- External Status Column -->
    <ng-container matColumnDef="complianceStatus">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'complianceStatus' | translate }}
      </th>
      <td
        mat-cell
        *matCellDef="let row"
        [matTooltip]="row.complianceStatus"
        matTooltipPosition="after"
      >
        {{ row.complianceStatus }}
      </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a *ngIf="row.canPay" mat-menu-item routerLink="/remittances/pay/{{ row.id }}">
            <mat-icon>send</mat-icon>{{ 'pay' | translate }}
          </a>
          <a *ngIf="row.canApprove" mat-menu-item (click)="onApproval(row.id)">
            <mat-icon>check_circle</mat-icon>{{ 'approve' | translate }}
          </a>
          <a
            *ngIf="row.canBlock"
            mat-menu-item
            (click)="onBlockUnblock(row.id, 'yes')"
          >
            <mat-icon>block</mat-icon>{{ 'block' | translate }}
          </a>
          <a *ngIf="row.canViewHistory && row.id.toString().startsWith('tj')" mat-menu-item (click)="viewHistoryDialog(row.id)">
            <mat-icon>history</mat-icon>{{ 'history' | translate }}
          </a>
          <a *ngIf="row.canUnfreeze && row.id.toString().startsWith('tj')" mat-menu-item (click)="unfreezeRemittance(row.id)">
            <i class="fa fa-lock-open align-item-center ms-auto p-1"></i>
            {{ 'unfreeze' | translate }}
          </a>
        </div>
        <div class="action-menu" *ngIf="!row.id.toString().startsWith('tj')">
          <a [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </a>
          <mat-menu #menu="matMenu" class="action-menu-item">
            <!-- <a *ngIf="row.canPayback" mat-menu-item routerLink="/remittances/payback/{{ row.id }}">
              <mat-icon>reply_all</mat-icon>{{ 'payback' | translate }}
            </a>
            <a *ngIf="row.canUnblock" mat-menu-item (click)="onBlockUnblock(row.id, 'no')">
              <mat-icon>recommend</mat-icon>{{ 'Unblock' | translate }}
            </a> -->
            <a *ngIf="row.canTrace" mat-menu-item (click)="traceRemittanceDialog(row.id)">
              <mat-icon>{{
                row.isTraced ? 'find_replace' : 'youtube_searched_for'
                }}</mat-icon>{{ (row.isTraced ? 'retrace' : 'trace') | translate }}
            </a>
            <!-- <a *ngIf="row.canViewSendReceipt" mat-menu-item (click)="sendReceiptPrintDialog(row.id)">
              <mat-icon>receipt_long</mat-icon>{{ 'sendReceipt' | translate }}
            </a> -->
            <!-- <a *ngIf="row.canViewPayReceipt" mat-menu-item (click)="payReceiptPrintDialog(row.id)">
              <mat-icon>receipt</mat-icon>{{ 'payReceipt' | translate }}
            </a> -->
            <!-- <a *ngIf="row.canViewPayBackReceipt" mat-menu-item (click)="payBackReceiptPrintDialog(row.id)">
              <mat-icon>receipt</mat-icon>{{ 'payBackReceipt' | translate }}
            </a> -->
            <!-- <a *ngIf="row.canReturn" mat-menu-item (click)="returnRemittanceDialog(row.id)">
              <mat-icon>undo</mat-icon>{{ 'return' | translate }}
            </a> -->
            <a *ngIf="row.canViewHistory" mat-menu-item (click)="viewHistoryDialog(row.id)">
              <mat-icon>history</mat-icon>{{ 'history' | translate }}
            </a>
            <!-- <a *ngIf="row.canUpdateReceiverName || row.canUpdateReceiverPhone" mat-menu-item (click)="
                        viewReceiverUpdateDialog(
                          row.id,
                          row.receiverName,
                          row.receiverMobile,
                          row.canUpdateReceiverName,
                          row.canUpdateReceiverPhone
                        )
                      ">
              <mat-icon>update</mat-icon>{{ 'update' | translate }}
            </a> -->
            <!-- <a *ngIf="row.canViewAttachment" mat-menu-item (click)="viewAttachments(row.id)">
              <mat-icon>attachment</mat-icon>
              {{ 'cancellationForm' | translate }}
            </a> -->
            <!-- <a *ngIf="row.canUploadSendReceipt" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 1)">
              <mat-icon>attach_file</mat-icon>{{ 'uploadSendReceipt' | translate }}
            </a>

            <a *ngIf="row.canUploadPayReceipt" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 2)">
              <mat-icon>attach_file</mat-icon>{{ 'uploadPayReceipt' | translate }}
            </a>

            <a *ngIf="row.canUploadSenderSignedForm" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 3)">
              <mat-icon>attach_file</mat-icon>{{ 'uploadSenderSignedForm' | translate }}
            </a>

            <a *ngIf="row.canUploadReceiverSignedForm" mat-menu-item (click)="uploadRemittanceReceipt(row.id, 4)">
              <mat-icon>attach_file</mat-icon>{{ 'uploadReceiverSignedForm' | translate }}
            </a>

            <a *ngIf="row.canViewRemittanceReceiptDocument" mat-menu-item (click)="remittanceReceipts(row.id)">
              <mat-icon>attach_file</mat-icon>{{ 'remittanceAttachments' | translate }}
            </a> -->
            <a *ngIf="row.canViewPayReceipt" mat-menu-item (click)="viewReceipt(row.code)">
              <mat-icon>receipt</mat-icon>{{ 'transfersreceipt' | translate }}
            </a>
          </mat-menu>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
        {{ 'noRecordsAvailable' | translate }}
      </td>
    </tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
