import { CustomerDocumentDto } from './customerDocument.dto';

export interface CustomerDto {
  id?: number;
  firstName: string;
  middleName: string;
  lastName: string;
  mobilePhone: string;
  addressLine1?: string;
  addressLine2?: string;
  genderId?: number;
  dateOfBirth?: any;
  placeOfBirth?: string;
  houseNumber?: string;
  buildingNumber?: string;
  street?: string;
  occupationId?: number;
  sourceOfIncomeId?: number;
  cityId?: number;
  countryId?: number;
  localBlacklistGuid?: string;
  isActive?: boolean;
  conductors?: GetCustomerDto[];
  customerTypeId?: number;
  tradeRegistryNumber?: string;
  taxIdentificationNumber?: string;
  fieldOfActivity?: string;
  companyMobilePhone?: string;
  companyArea?: string;
  position?: string;
  companyStreet?: string;
  companyBuildingNumber?: number;
}
export interface ReceiverDto {
  id?: number;
  firstName: string;
  middleName: string;
  lastName: string;
  mobilePhone: string;
  addressLine1?: string;
  addressLine2?: string;
  genderId?: number;
  dateOfBirth?: string;
  placeOfBirth?: string;
  localityId?: number;
  subLocalityId?: string;
  houseNumber?: string;
  street?: string;
  occupationId?: number;
  sourceOfIncomeId?: number;
  cityId?: number;
  countryId?: number;
  purpose: any;
  relationship: any;
}
export interface UpsertCustomerDto extends CustomerDto {
  isSender?: boolean;
}

export interface UpsertReceiverCustomerDto extends CustomerDto {}

export interface GetCustomerDto extends CustomerDto {
  id: number;
  createdDate: Date;
  lastModifiedDate: Date;
  docs?: CustomerDocumentDto[];
  receivers?: CustomerDto[];
}

export interface GetCustomerBlacklistDto {
  id: number;
  name: string;
  mobile: string;
  Blocked: number;
  ApprovalRequired: number;
  requestedBy: string;
  modifiedBy: string;
  reasons: string;
  comments: string;
  country: string;
  branch: string;
}

export interface GetCustomerBlacklistSantionedAuditLogsDto {
  stage: string;
  matchEntity: any;
  action: string;
  dateCreated: Date;
}

export interface SearchCustomerResponseDto extends CustomerDto {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  mobilePhone: string;
  createdDate: Date;
  lastModifiedDate: Date;
  customerAge: number;
  onboardDate: Date;
}

export interface SearchCustomerBlacklistResponseDto {
  name: string;
  mobile: string;
}

export interface CustomerDocTypesResponseDto {
  id: number;
  name: string;
  code: string;
  isPrimary?: boolean;
  isTwoPageAttachmentRequired?: boolean;
  minimumLength?: number;
  maximumLength?: number;
  regularExpression?: string;
  isFirstAttachmentRequired?: boolean;
  isSecondAttachmentRequired?: boolean;
  isThirdAttachmentRequired?: boolean;
  requiredFields: string;
  isActive?: number;
  documentTypeId?: number;
}


export interface upsertConductor {
  id?: number;
  firstName: string;
  lastName: string;
  mobile: string;
  placeOfBirth: string;
  dateOfBirth?: string;
  position: string;
  fartherName: string;
  motherName: string;
  customerId?: number
  nationalityId?: number
}


export interface GetConductor {
  id?: number;
  firstName: string;
  lastName: string;
  mobile: string;
  placeOfBirth: string;
  dateOfBirth?: string;
  position: string;
  fartherName: string;
  motherName: string;
  nationalityId: string;
}

export interface GetCustomerTypesDto {
  id?: number;
  name?: string;
  isActive?: string;
}
