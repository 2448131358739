import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  EMPTY,
  catchError,
  throwError,
  BehaviorSubject,
  filter,
  switchMap,
  take,
} from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

const openUrls: string[] = [
  'auth/agent/login',
  'auth/loginwithgoogle',
  'auth/loginwithsms',
  'auth/resetpassword',
  '/assets/i18n/en.json',
  '/assets/i18n/tr.json',
  'auth/generateqrcode',
  'auth/setotpenabled',
  'auth/sendforgotpasswordemail',
  'auth/forgotpassword'
];

const getUrl = (url: string | null) =>
  url?.replace(environment.apiBaseUrl, '') || '';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null,
  );
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let authReq = req;
    if (openUrls.includes(getUrl(authReq.url))) {
      return next.handle(authReq);
    }

    const { authToken, refreshToken } = this.authService;

    // if there is no auth token, it means user logged out, so need to log in again
    if (!authToken) {
      this.handleUnauthenticated();
      return EMPTY;
    }

    if (getUrl(authReq.url) === 'auth/refresh') {
      authReq = this.addAuthHeader(req, refreshToken || '');
    } else {
      authReq = this.addAuthHeader(req, authToken);
    }

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (getUrl(authReq.url) === 'auth/refresh') {
            this.handleUnauthenticated();
            return EMPTY;
          } else if (getUrl(authReq.url) === 'auth/logout') {
            localStorage.clear();
            window.location.href = '/login';
            return EMPTY;
          }

          return this.handle401Error(authReq, next);
        }

        return throwError(() => error);
      }),
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authService.refreshToken;
      if (token) {
        return this.authService.refreshAuthToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.authService.setNewAuthAndRefreshTokens(token);
            this.refreshTokenSubject.next(token.authToken);

            return next.handle(this.addAuthHeader(request, token.authToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.handleUnauthenticated();
            return throwError(() => err);
          }),
        );
      }
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token !== null),
      take(1),
      switchMap((token) => {
        return next.handle(this.addAuthHeader(request, token));
      }),
    );
  }

  private addAuthHeader(request: HttpRequest<any>, authToken: string) {
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${authToken as string}`,
      ),
    });
  }

  handleUnauthenticated() {
    this.authService.logout();
  }
}
