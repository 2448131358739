<h1 mat-dialog-title>{{ 'logDetails' | translate }}</h1>
<div mat-dialog-content>
  <pre>
    {{ json }}
  </pre>
</div>
<div mat-dialog-actions class="justify-content-end mt-1">
  <button
    mat-raised-button
    mat-dialog-close="close"
    class="ms-1"
    color="secondary"
    (click)="closeDialog()"
  >
    {{ 'close' | translate }}
  </button>
</div>
