import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsersService } from './users.service';
import { LookupDto } from '../../_common/data/dto/api.dto';

@Injectable()
export class UserTypeResolver implements Resolve<any> {
  constructor(private usersService: UsersService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<LookupDto[]> {
    return from(this.usersService.userTypes());
  }
}
