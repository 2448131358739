import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AuthService } from '../auth/data/auth.service';
import {
  RemittanceHistoriesDto,
  RemittancesHistoryDto,
} from './data/dto/remittances.dto';
import { RemittanceHistoryChangeLogPopupComponent } from './remittanceHistoryChangeLogPopup.component';
import { Permissions } from '../_common/enums/permissions.enums';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-remittance-history',
  templateUrl: './remittanceHistoryPopup.component.html',
  styleUrls: ['./receipt.css'],
})
export class RemittanceHistoryPopupComponent {
  permissions = Permissions.remittanceManagement.remittances;

  constructor(
    public dialogRef: MatDialogRef<RemittanceHistoryPopupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: RemittancesHistoryDto,
    public authService: AuthService,
  ) {}

  setIsApproved(isApproved: boolean) {
    return isApproved ? 'approved' : 'notApproved';
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  checkNullValue(value: string | undefined) {
    if (value === null) value = '';
    return value;
  }

  viewChangeLog(data: RemittanceHistoriesDto) {
    this.dialog.open(RemittanceHistoryChangeLogPopupComponent, {
      disableClose: true,
      width: '54rem',
      data: data.snapshot,
    });
  }

  round(amount: number){
    return amount.toFixed(2);
  }
}
