import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { LogDto } from '../data/dto/log/log.dto';
import { LogsService } from '../data/logs.service';
import { MatDialog } from '@angular/material/dialog';
import { LogDetailPopupComponent } from './logDetailPopup.component';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class LogsComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;
  sinceHours = '12';
  resultsLength = 0;
  formatLabel(value: number): string {
    return `${value} hr`;
  }
  displayedColumns: string[] = [
    'requestId',
    'accessedAt',
    'username',
    'type',
    'path',
    'actions',
  ];
  data = new MatTableDataSource<LogDto>();

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private logsService: LogsService,
    public dailog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      sinceHours: this.sinceHours,
      reqId: [],
    });

    this.logsService.logs.subscribe(({ logs, appliedFilters }) => {
      this.data.data = logs;
      this.data.paginator = this.paginator;
      this.resultsLength = logs.length;
      this.form.controls['sinceHours'].setValue(appliedFilters?.since);
      this.form.controls['reqId'].setValue(appliedFilters?.reqId);
    });
  }

  onSubmit(requestId: string = '') {
    const reqId = requestId || this.form.value.reqId;
    const since = this.form.value.sinceHours;
    const filters = { since, reqId };
    this.logsService.getLogs(filters);
  }

  onViewLogClick(id: number) {
    const log = this.data.data.find((l) => l.id === id);
    if (!log) return;

    if (this.form.value.reqId) {
      this.dailog.open(LogDetailPopupComponent, {
        disableClose: true,
        autoFocus: false,
        width: '800px',
        data: log.log,
      });
      return;
    }

    this.onSubmit(log.requestId);
  }
}
