import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { BranchesService } from './data/branches.service';
import { AuthService } from '../auth/data/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LookupDto } from '../_common/data/dto/api.dto';
import {
  GetBranchesDto,
  CreateAccountDto,
  UpdateAccountDto,
  GetAccountDto,
} from './data/dto/branches.dto';
import { MatRadioModule } from '@angular/material/radio';
import { SnackBarService } from '../_common/snackBar.service';
import { Observable } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GetUserDto } from '../user/data/dto/user/users.dto';
@Component({
  selector: 'app-branch-account-details',
  templateUrl: './accountDetails.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class AccountDetailsComponent implements OnInit {
  form!: FormGroup;
  permissions = Permissions.accountsManagement.accounts;

  branchesList: GetBranchesDto[] = [];
  branches: GetBranchesDto[] = [];
  groups: LookupDto[] = [];
  types: LookupDto[] = [];
  parents: LookupDto[] = [];
  currencies: LookupDto[] = [];
  addMode = true;
  loading = false;
  title = '';
  showOptions = false;
  showUsers = false;
  isTeller = false;
  groupValue = '';
  typeValue = '';
  branchValue = '';
  branchUsers!: GetUserDto[];
  isRegional: boolean | undefined;
  isAdmin: boolean | undefined;
  accountData: GetAccountDto = {} as GetAccountDto;
  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private branchesService: BranchesService,
    public authService: AuthService,
    public dialog: MatDialog,
    public radio: MatRadioModule,
    private snackBar: SnackBarService,
  ) {}
  ngOnInit() {
    this.router.data.subscribe(({ addMode, branches, groups, account }) => {
      this.branchesList = branches;
      this.branches = branches.map(function (obj: GetBranchesDto) {
        return { id: obj.id, code: obj.code, title: obj.title };
      });
      this.groups = groups.sort((a: LookupDto, b: LookupDto) =>
        a.name.localeCompare(b.name),
      );
      this.addMode = addMode;
      if (this.addMode) {
        this.title = 'addAccount';
      } else {
        this.title = 'updateAccount';
      }
      if (account) {
        this.accountData = account;
      }

      this.branchValue = this.accountData?.branchId;
      if (this.branchValue) {
        this.onBranchSelected(this.branchValue);
      }

      this.isTeller = this.accountData.userId !== null;

      if(!addMode){
        if(this.isTeller) this.showUsers = true;
        this.getBranchUsers(parseInt(this.accountData.branchId));
      }

      this.form = this.fb.group({
        branchId: [this.accountData?.branchId, Validators.required],
        userId: [this.branchUsers ? this.branchUsers.find((user)=>user.id) : this.accountData.userId],
        groupId: [this.accountData?.groupId, Validators.required],
        typeId: [this.accountData?.typeId, Validators.required],
        parentId: [this.accountData?.parentId, Validators.required],
        currencyId: [this.accountData?.currencyId, Validators.required],
        accountName: [this.accountData?.accountName, Validators.required],
        isActive: [this.addMode ? 'active' : this.accountData.isActive === true ? 'active' : 'inactive',],
        isAllowManualEntry: [
          this.addMode ? 'yes' : this.accountData.isAllowManualEntry === true ? 'yes' : 'no',
        ],
        isCreditLocked: [this.accountData?.isCreditLocked ? 'yes' : 'no'],
        isDebitLocked: [this.accountData?.isDebitLocked ? 'yes' : 'no'],
        isTeller: [this.accountData.userId ? true : false],
      });
    });
  }
  onBranchSelected(branch: any) {
    this.parents = [];
    this.getBranchCurrencies();
    this.branchesService.getAccountLevels(branch).subscribe((accountTypes) => {
      this.types = accountTypes;
      this.groupValue = this.accountData?.groupId;
      this.typeValue = this.accountData?.typeId;
    }, ({ message }) => {
      this.snackBar.open(message);
    });
  }
  getParents() {
    this.branchesService
      .getParents(this.branchValue, this.groupValue, this.typeValue)
      .subscribe((parents) => {
        this.parents = parents.sort((a: LookupDto, b: LookupDto) =>
          a.name.localeCompare(b.name),
        );
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }

  getBranchCurrencies() {
    this.branchesService
      .branchCurrenciesLookup(+this.branchValue)
      .subscribe((currencies) => {
        this.currencies = currencies;
        if (this.accountData?.typeId) {
          this.showHideOptions(this.accountData.typeId);
        }
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }
  showHideOptions(type: any) {
    if (this.types.filter((x) => x.id == type)[0].name === 'Entry') {
      this.showOptions = true;
    } else {
      this.showOptions = false;
    }
    if (this.groupValue && this.typeValue) this.getParents();
  }
  onTellerChecked(e: MatCheckboxChange){
    if(e.checked){
      this.form.controls['userId'].setValidators([Validators.required]);
      this.form.updateValueAndValidity();
      this.showUsers = true;
      const { branchId } = this.form.value;
      this.getBranchUsers(branchId);
    } else {
      this.isTeller = false;
      this.showUsers = false;
      this.branchUsers = [];
      this.form.controls['userId'].clearValidators();
      this.form.controls['userId'].setValue(null);
      this.form.updateValueAndValidity();
    }
  }

  getBranchUsers(branchId: number) {
    this.branchesService.getBranchUsers(branchId).subscribe((users)=>{
      this.branchUsers = users;
    },({ message }) => {
      this.snackBar.open(message);
    });
  }

  onSubmit() {
    if (this.form.valid) {
      let handler: Observable<any>;
      const formValues = this.form.getRawValue() || {};
      if (this.addMode) {
        const accountDto: CreateAccountDto = {
          branchId: formValues.branchId,
          userId: formValues.userId,
          typeId: formValues.typeId,
          groupId: formValues.groupId,
          parentId: formValues.parentId,
          currencyId: formValues.currencyId,
          accountName: formValues.accountName,
          isActive: formValues.isActive === 'active',
          isAllowManualEntry: formValues.isAllowManualEntry === 'yes',
          isCreditLocked: formValues.isCreditLocked === 'yes',
          isDebitLocked: formValues.isDebitLocked === 'yes',
        };

        handler = this.branchesService.createAccount(accountDto);
      } else {
        const updateAccountDto: UpdateAccountDto = {
          id: this.accountData.id,
          accountName: formValues.accountName,
          isActive: formValues.isActive == 'active',
        };
        handler = this.branchesService.updateAccount(updateAccountDto);
      }
      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }
}
