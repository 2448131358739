<h1 mat-dialog-title>{{ 'blockRemittance' | translate }}</h1>
<div mat-dialog-content>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
        ></textarea>
      </mat-form-field>
    </div>
    <!-- <div class="row"> -->
      <!-- <div appearance="outline" class="col pt-2">
        <input
          type="file"
          style="display: none"
          accept=".png, .jpg, .jpeg, .pdf"
          (change)="onDocAttachmentSelected($event)"
          #docFile
        />
        <button
          mat-mini-fab
          color="primary"
          class="mx-1"
          type="button"
          (click)="docFileElem.nativeElement.click()"
        >
          <mat-icon>attach_file</mat-icon>
        </button>
        <mat-label>{{
          docFile?.name || ('uploadAttachment' | translate)
        }}</mat-label>
      </div> -->
      <!-- <div
        class="col"
        *ngIf="docFileUrl && docFile?.type !== 'application/pdf' && !isPdfFile"
      >
        <img
          class="img-thumbnail"
          [src]="docFileUrl"
          style="width: auto; height: 100px; margin-right: 3px"
        />
      </div>
      <div class="col" *ngIf="isPdfFile">
        <mat-icon
          style="font-size: 100px; color: gray; margin-right: 50px"
          (click)="onViewAttachment(docFileUrl)"
          >picture_as_pdf</mat-icon
        >
      </div> -->
    <!-- </div>
    <div
      class="alert alert-danger"
      style="margin-top: 5px"
      *ngIf="!isFileSelected"
    >
      {{ 'noFile' | translate }}
    </div> -->
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close" (click)="closeDialog()">
    {{ 'close' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'block' | translate }}
  </button>
</div>
