<h1 mat-dialog-title>{{ 'checkGoogleOtp' | translate }}</h1>
<div mat-dialog-content>
  <form
    name="form"
    id="googleOtp"
    [formGroup]="form"
    novalidate
    autocomplete="off"
  >
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'googleOtp' | translate }}</mat-label>
          <input
            type="password"
            matInput
            #googleOtpCode
            formControlName="googleOtpCode"
            autocomplete="new-password"
          />
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end mt-1">
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'confirm' | translate }}
  </button>
  <button
    mat-raised-button
    mat-dialog-close="close"
    class="ms-1"
    color="secondary"
    (click)="closeDialog()"
  >
    {{ 'close' | translate }}
  </button>
</div>
