<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'UnPaidRemittanceReportDetails' | translate }}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table id="unpaidRemittanceReport" class="table table-borderless">
        <tbody *ngIf="data.data.length">
          <tr>
            <th class="text-nowrap">{{ 'senderBranch' | translate }}</th>
            <th class="text-nowrap">{{ 'senderName' | translate }}</th>
            <th class="text-nowrap">{{ 'senderMobile' | translate }}</th>
            <th class="text-nowrap">{{ 'dateSend' | translate }}</th>
            <th class="text-nowrap">{{ 'amount' | translate }}</th>
            <th class="text-nowrap">{{ 'receiverBranch' | translate }}</th>
            <th class="text-nowrap">{{ 'receiverName' | translate }}</th>
            <th class="text-nowrap">{{ 'receiverMobile' | translate }}</th>
            <th class="text-nowrap">{{ 'status' | translate }}</th>
            <th class="text-nowrap">{{ 'sendingLocalAmount' | translate }}</th>
          </tr>
          <tr *ngFor="let remittance of data.data">
            <td class="text-nowrap">{{ remittance.senderBranch }}</td>
            <td class="text-nowrap">{{ remittance.senderName }}</td>
            <td class="text-nowrap">{{ remittance.senderMobile }}</td>
            <td class="text-nowrap">{{ remittance.dateSent }}</td>
            <td class="text-nowrap">{{ remittance.amount | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ remittance.receiverBranch }}</td>
            <td class="text-nowrap">{{ remittance.receiverName }}</td>
            <td class="text-nowrap">{{ remittance.receiverMobile }}</td>
            <td class="text-nowrap">{{ remittance.remittanceState }}</td>
            <td class="text-nowrap">{{ remittance.sendingLocalAmount | number:'1.2-2'  }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="report"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
