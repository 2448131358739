export interface LookupsDto {
  id?: string;
  name: string;
  code?: string;
  parentId?: number;
  parentName?: string;
  isActive?: boolean;
  isPrimary?: boolean;
}

export interface customerDocs extends LookupsDto {
  minimumLength?: number;
  maximumLength?: number;
  regularExpression?: string;
  code2: string;
  requiredFields: string;
}
