import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import * as XLSX from 'xlsx';
import { Utilities } from "../_common/utils";

@Component({
    selector: 'app-finsurv-transactions-popup',
    templateUrl: './finsurvTransactionsPopup.component.html',
    styleUrls: ['./finsurvTransactionsPopup.component.css'],
})
export class FinsurvTransactionsPopupComponent {

    constructor(
    public dialogRef: MatDialogRef<FinsurvTransactionsPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { data: any[any], startDate: any, endDate: any},
     private dialog: MatDialog,
  ) {
  }
  

    convertDateToLocaleString(date: Date, printable: boolean) {
    return printable
    ? Utilities.convertDateToLocaleString(date, '')
    : Utilities.convertDateToLocaleString(date, 'date');
  }

   formatDate(date: Date) {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const year = d.getFullYear();
  
    return `${month}/${day}/${year}`;
}

 formatCustomString(dateFrom: Date, dateTo: Date): string {
  const year = dateFrom.getFullYear().toString();
  const lastThreeDigits = year.slice(-3);

  const startMonth = (dateFrom.getMonth() + 1).toString().padStart(2, '0');
  const startDay = dateFrom.getDate().toString().padStart(2, '0');
  const startDate = `${year}${startMonth}${startDay}`;

  const endMonth = (dateTo.getMonth() + 1).toString().padStart(2, '0');
  const endDay = dateTo.getDate().toString().padStart(2, '0');
  const endDate = `${year}${endMonth}${endDay}`;


  // Concatenate the parts with underscores and add "SARB"
  return `${lastThreeDigits}_${startDate}_${endDate}_SARB`;
}



 exportToExcel() {
  const table = document.getElementById('finsurvTransactions');
  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);

  const headerMapping: any = {
    'Ad Code': 'Ad_Code',
    'Ad Name': 'Ad_Name',
    'Reporting Qualifier': 'Reporting_Qualifier',
    'Value Date': 'Value_Date',
    'Flow': 'Flow',
    'Trn Reference Number': 'Trn_Reference_Number',
    'Sequence': 'Sequence',
    'Sub-Sequence': 'Sub_Sequence',
    'Status': 'Status',
    'Resident Name': 'Resident_Name',
    'Resident Surname': 'Resident_Surname',
    'Company Name': 'Company_Name',
    'Currency': 'Currency',
    'Rand Amount': 'Rand_Amount',
    'Foreign Amount': 'Foreign_Amount',
    'Bop Category': 'Bop_Category',
    'SubBop Category': 'SubBop_Category'
  };

  
  const headers = Object.keys(ws)
    .filter((key) => key[0] !== '!' && ws[key].v)
    .map((key) => ({
      original: key,
      value: ws[key].v.toString().trim()
    }));

  headers.forEach(({ original, value }, index) => {
    const snakeCaseHeader = headerMapping[value] || value.toLowerCase().replace(/ /g, '_');

    const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!ws[cellAddress]) {
      ws[cellAddress] = {};
    }
    ws[cellAddress].v = snakeCaseHeader;
  });

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, this.formatCustomString(this.data.startDate, this.data.endDate));
  XLSX.writeFile(
    wb,
    this.formatCustomString(this.data.startDate, this.data.endDate)+
      '.csv'
  );
}

}