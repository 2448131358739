<h1 mat-dialog-title align="center">
  {{ 'updateCommissionStatus' | translate }}
</h1>
<div mat-dialog-content>
  <div class="py-1 overflow-y-auto">
    <div
      class="d-flex flex-column justify-content-end mat-elevation-z2 overflow-hidden"
      *ngIf="data"
    >
      <div
        class="d-flex align-items-center h-9x bg-light px-2 border-bottom border-dark"
      >
        <b>{{ 'commissionApprovalRequestDetails' | translate }}</b>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'branchCode' | translate }}:
          <p>{{ data.branchCode }}</p>
        </div>
        <div class="col">
          {{ 'requestingUser' | translate }}:
          <p>{{ data.requestedBy }}</p>
        </div>
      </div>
      <div class="row p-2 pb-0 border-bottom">
        <div class="col">
          {{ 'currentSendingCommission' | translate }}:
          <p>{{ data.oldSendCashCommission }}</p>
        </div>
        <div class="col">
          {{ 'requestedSendingCommission' | translate }}:
          <p>{{ data.newSendCashCommission }}</p>
        </div>
        <div class="col">
          {{ 'currentPayingCommission' | translate }}:
          <p>{{ data.oldPayCashCommission }}</p>
        </div>
        <div class="col">
          {{ 'requestedPayingCommission' | translate }}:
          <p>{{ data.newPayCashCommission }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- ... -->
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
  >
    <div class="row">
      &nbsp;
      <mat-label class="mb-4">{{ 'status' | translate }}:</mat-label>
      <mat-radio-group
        formControlName="commissionChangeRequestStatus"
        class="mb-4"
        [(ngModel)]="selectedRadio"
      >
        <mat-radio-button value="Approved">
          {{ 'approveCommissionRequest' | translate }}
        </mat-radio-button>
        <mat-radio-button value="Rejected">
          {{ 'rejectCommissionRequest' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="row">
      <mat-form-field class="w-100 mt-2" appearance="fill" cdkFocusInitial>
        <mat-label>{{ 'leaveAComment' | translate }}</mat-label>
        <textarea
          matInput
          formControlName="comments"
          placeholder="{{ 'comments' | translate }}"
          [(ngModel)]="comments"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!form.valid"
    (click)="onSubmit()"
  >
    {{ 'update' | translate }}
  </button>
</div>
