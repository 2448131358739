<app-index-container label="{{ 'limitApprovalRequests' | translate }}">
  <form
    form
    class="container-fluid p-0 flex-column"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branches' | translate }}:</mat-label>
        <mat-select #branch formControlName="branchId">
          <mat-option *ngFor="let branch of branches" [value]="branch">
            {{ branch.code + ' - ' + branch.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'limitRequestStatuses' | translate }}:</mat-label>
        <mat-select #status formControlName="statusId">
          <mat-option
            *ngFor="let status of limitChangeStatuses"
            [value]="status"
          >
            {{ status.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="col">
        <button
          class="float-end"
          mat-raised-button
          [disabled]="!form.valid"
          type="submit"
          color="primary"
        >
          <i class="fa-solid fa-magnifying-glass"></i>
          {{ 'search' | translate }}
        </button>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Branch Code Column -->
    <ng-container matColumnDef="branchCode">
      <th mat-header-cell *matHeaderCellDef>{{ 'branchCode' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.branchCode }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'status' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- Request Date Column -->
    <ng-container matColumnDef="requestedDate">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'requestedDate' | translate }}
      </th>
      <td mat-cell *matCellDef="let row">
        {{ convertDateToLocaleString(row.createdDate) }}
      </td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            mat-menu-item
            *ngIf="row.canApprove"
            color="primary"
            (click)="onBranchLimit(row.id)"
          >
            <mat-icon>edit</mat-icon>{{ 'action' | translate }}</a
          >
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="branchLimitsColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: branchLimitsColumns"></tr>
  </table>

  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
