<app-index-container label="{{ 'supportTickets' | translate }}">
  <button
    headerActions
    mat-raised-button
    color="primary"
    routerLink="/support/tickets/add"
    *ngIf="authService.isUserAuthorized(permissions.CREATE_TICKET)"
  >
    <i class="fa-solid fa-circle-plus"></i> {{ 'addTicket' | translate }}
  </button>
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit()"
  >
    <div class="row mb-1">
      <div class="col-sm-6 col-md-3 col-lg-3">
        <mat-checkbox (change)="onChangeMyTickets($event)"
                      matInput
                      class="mb-3"
                      color="primary"
                      formControlName="onlyMyTickets">
          {{ 'myTicketsOnly' | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'branch' | translate }}:</mat-label>
        <mat-select
          formControlName="branchId"
          (selectionChange)="onBranchSelected($event.value)"
        >
          <mat-option *ngFor="let branch of branches" [value]="branch.id">
            {{
              branch.code === 'TKCPO'
                ? ('hqBranch' | translate)
                : branch.code + ' - ' + branch.title
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'assignee' | translate }}</mat-label>
        <mat-select
          formControlName="assigneeId"
          (selectionChange)="onAssigneeSelected($event.value)"
        >
          <mat-option
            *ngFor="let assignee of assignees"
            [value]="assignee.id"
            >{{ assignee.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'status' | translate }}</mat-label>
        <mat-select formControlName="statusId" multiple>
          <mat-option *ngFor="let status of statuses" [value]="status.id">{{
            status.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="row">
      <div class="d-flex justify-content-end">
        <button
          mat-raised-button
          [disabled]="!form.valid"
          type="submit"
          color="primary"
        >
          <i class="fa-solid fa-magnifying-glass"></i>
          {{ 'search' | translate }}
        </button>
      </div>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Title Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>{{ 'title' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.title }}</td>
    </ng-container>
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>
    <!-- branch Column -->
    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef>{{ 'branch' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        {{
          row.branch === 'CPO Branch' ? ('hqBranch' | translate) : row.branch
        }}
      </td>
    </ng-container>
    <!-- branch Column -->
    <ng-container matColumnDef="assignee">
      <th mat-header-cell *matHeaderCellDef>{{ 'assignee' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.assigneeName }}</td>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-button-menu">
          <a
            *ngIf="authService.isUserAuthorized([
            permissions.UPDATE_TICKET,
            permissions.VIEW_TICKET
          ])"
            mat-menu-item
            routerLink="/support/tickets/view/{{ row.id }}">
            <mat-icon>{{
              authService.isUserAuthorized(permissions.UPDATE_TICKET)
                ? 'edit'
                : 'visibility'}}
            </mat-icon>{{ (authService.isUserAuthorized(permissions.UPDATE_TICKET) ? 'edit' : 'view') | translate }}
          </a>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, resultsLength]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
