import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../../_common/enums/permissions.enums';
import { AuthService } from '../../auth/data/auth.service';
import {
  CommissionConfigRequestFilter,
  CommissionConfigurationService,
} from '../data/commissionConfigurations.service';
import { RemittanceCommissionConfigurationsRequestsDto } from '../data/dto/commissionConfigurations.dto';
import { MatTableDataSource } from '@angular/material/table';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';
import { ActivatedRoute } from '@angular/router';
import { CommissionConfigurationRequestPopupComponent } from './commConfigRequestPopup.component';
import { MatDialog } from '@angular/material/dialog';
import { Utilities } from 'src/app/_common/utils';

@Component({
  selector: 'app-remit-commission-configs-requests',
  templateUrl: './commissionConfigsRequests.component.html',
  styleUrls: ['../../_common/styles/table.less'],
})
export class CommissionConfigsRequetsComponent implements OnInit {
  form!: UntypedFormGroup;

  permissions =
    Permissions.remittanceManagement.commissionConfigurationRequests;
  displayedColumns: string[] = [
    '#',
    'senderCountry',
    'receiverCountry',
    'remittanceType',
    'remittanceSubType',
    'minAmount',
    'maxAmount',
    'commission',
    'status',
    'requestDate',
    'requesterComments',
    'statusChangeDate',
    'statusChangeComments',
    'actions',
  ];
  remitCommConfigRequests =
    new MatTableDataSource<RemittanceCommissionConfigurationsRequestsDto>();
  remitCommConfigRequestStatus: LookupDto[] = [];

  loading = false;
  resultsLength = 0;

  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private fb: UntypedFormBuilder,
    private commConfigsService: CommissionConfigurationService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(({ statuses }) => {
      this.remitCommConfigRequestStatus = statuses;
    });

    this.form = this.fb.group({
      statusId: [null],
    });

    this.commConfigsService.commissionConfigsRequest.subscribe(
      ({ commissionConfigRequests, appliedFilters }) => {
        this.loading = false;
        this.remitCommConfigRequests.data = commissionConfigRequests;
        this.remitCommConfigRequests.paginator = this.paginator;
        this.resultsLength = commissionConfigRequests.length;
        this.form.controls['statusId'].setValue(
          this.remitCommConfigRequestStatus.find(
            (status) => status.id === appliedFilters?.statusId,
          ),
        );
      },
    );
  }

  onSubmit() {
    const statusId = this.form.value.statusId;
    const filters: CommissionConfigRequestFilter = {};
    if (statusId) filters.statusId = statusId.id;
    this.commConfigsService.triggerGetCommissionConfigRequests(filters);
  }

  onCommConfigApproval(commConfigRequestId: number) {
    const dialogRef = this.dialog.open(
      CommissionConfigurationRequestPopupComponent,
      {
        width: '650px',
        disableClose: true,
        data: this.remitCommConfigRequests.data.find((commConfig)=>commConfig.id === commConfigRequestId),
      },
    );
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      if (resp.success) this.onSubmit();
    });
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
