import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/data/auth.guard';
import { Permissions } from '../_common/enums/permissions.enums';
import { OutletComponent } from '../_common/components/outlet.component';
import { CustomersComponent } from './customers.component';
import { CustomerDetailsComponent } from './customerDetails.component';
import {
  CountriesResolver,
  CitiesResolver,
  LocalitiesResolver,
} from '../branch/data/branches.resolver';
import {
  CustomerByIdResolver,
  CustomerGenderResolver,
  CustomerOccupationsResolver,
  CustomerSourceOfIncomeResolver,
} from './data/customer.resolver';
import { TellerAccountsResolver } from '../remittance/data/remittance.resolver';
import { BlacklistComponent } from './blacklist.component';
import { AddCustomerBlacklistComponent } from './addBlacklist.component';
import { BlacklistSactionedAuditLogsComponent } from './blacklistSanctionedAuditLogs.component';
import { ClientScreeningConponent } from './clientScreening.component';

const {
  customerManagement: { customers, blackList, blackListSanctionAuditLogs, ClientScreening},
} = Permissions;

const routes: Routes = [
  {
    path: 'customers',
    component: OutletComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(customers)] },
        component: CustomersComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        data: { permissions: [customers.CREATE_CUSTOMER], addMode: true },
        resolve: {
          tellerAccounts: TellerAccountsResolver,
          countries: CountriesResolver,
          customerGenders: CustomerGenderResolver,
          customerOccupations: CustomerOccupationsResolver,
          customerSourceOfIncome: CustomerSourceOfIncomeResolver,
          cities: CitiesResolver,
          localities: LocalitiesResolver,
        },
        component: CustomerDetailsComponent,
      },
      {
        path: 'view/:customerId',
        canActivate: [AuthGuard],
        data: {
          permissions: [customers.UPDATE_CUSTOMER, customers.VIEW_CUSTOMER],
          addMode: false,
        },
        resolve: {
          tellerAccounts: TellerAccountsResolver,
          countries: CountriesResolver,
          customerGenders: CustomerGenderResolver,
          customerOccupations: CustomerOccupationsResolver,
          customerSourceOfIncome: CustomerSourceOfIncomeResolver,
          cities: CitiesResolver,
          localities: LocalitiesResolver,
          customers: CustomerByIdResolver,
        },
        component: CustomerDetailsComponent,
      },
      {
        path: 'blacklist',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(blackList)] },
        component: BlacklistComponent,
      },
      {
        path: 'audit-logs',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(blackListSanctionAuditLogs)] },
        component: BlacklistSactionedAuditLogsComponent,
      },
      {
        path: 'client-screening',
        canActivate: [AuthGuard],
        data: { permissions: [...Object.values(ClientScreening)] },
        component: ClientScreeningConponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomerRoutesModule {}
