import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../_common/enums/permissions.enums';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SnackBarService } from '../_common/snackBar.service';
import { AuthService } from '../auth/data/auth.service';
import { CustomerService } from './data/customer.service';
import { BranchesService } from '../branch/data/branches.service';
import { CustomerDto, UpsertCustomerDto } from './data/dto/customer.dto';
import { regExp } from '../_common/data/validationRules';
import moment from 'moment';
import { splitFullName } from "../_common/utils";
import {CustomerTypes} from "../_common/enums/systemBranches.enums";

@Component({
  selector: 'app-customer-details',
  templateUrl: './customerDetails.component.html',
})
export class CustomerDetailsComponent implements OnInit {
  form!: UntypedFormGroup;

  permissions = Permissions.customerManagement.customers;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;

  // @ts-ignore
  @ViewChild('customerForm') el: ElementRef;

  countries: LookupDto[] = [];
  customerCountries: LookupDto[] = [];
  customerCities: LookupDto[] = [];
  customerLocalities: LookupDto[] = [];
  allCountries: LookupDto[] = [];
  cities: LookupDto[] = [];
  localities: LookupDto[] = [];
  customerGenders: LookupDto[] = [];
  customerOccupations: LookupDto[] = [];
  customerSourceOfIncome: LookupDto[] = [];
  tellerAccounts: LookupDto[] = [];
  maxDOB = new Date(
    new Date().getFullYear() - 18,
    new Date().getMonth(),
    new Date().getDate(),
  );
  customer?: CustomerDto;
  addMode = true;
  title = '';
  customerTypeId = CustomerTypes.Person;
  customerType = CustomerTypes;
  constructor(
    private fb: UntypedFormBuilder,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
    public branchService: BranchesService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({
        addMode,
        countries,
        customerGenders,
        customerOccupations,
        customerSourceOfIncome,
        customers,
        cities,
        localities,
        tellerAccounts,
      }) => {
        this.allCountries = countries;
        this.tellerAccounts = tellerAccounts;
        this.customerGenders = customerGenders;
        this.customerOccupations = [...customerOccupations].filter(
          (f) => f.isActive != 0,
        );
        this.customerSourceOfIncome = [...customerSourceOfIncome].filter(
          (f) => f.isActive != 0,
        );
        this.addMode = addMode;
        if (customers && customers.length) {
          this.customer = customers[0];
        }
        //@ts-ignore
        this.customerTypeId = this.customer?.customerTypeId;
        if (!addMode) {
          this.countries = countries;
          this.cities = [...cities].filter((f) => f.isActive);
          this.cities = this.cities.filter(
            (f) => f.parentId === this.customer?.countryId,
          );
        }

        const formFields: any = {
          firstName: [
            this.customer?.firstName,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(40),
              Validators.pattern(regExp.alphaSpace)
            ]
          ],
          middleName: [
            this.customer?.middleName,
            [
              Validators.minLength(2),
              Validators.maxLength(10),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          lastName: [
            this.customer?.lastName,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(50),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          mobilePhone: [
            this.customer?.mobilePhone,
            [Validators.required,Validators.maxLength(15), Validators.pattern(regExp.mobile)],
          ],
          gender: [
            this.customerGenders.find((f) => f.id === this.customer?.genderId),
            [Validators.required],
          ],
          dateOfBirth: [this.customer?.dateOfBirth, [Validators.required ]],
          sourceOfIncome: [
            this.customerSourceOfIncome.find(
              (f) => f.id === this.customer?.sourceOfIncomeId,
            ),
            [Validators.required],
          ],
          street: [
            this.customer?.street,
            [
              Validators.minLength(2),
              Validators.maxLength(25),
              Validators.pattern(regExp.alphaNumericSpace),
            ],
          ],
          buildingNumber: [
            this.customer?.buildingNumber,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpace),
            ],
          ],
          houseNumber: [
            this.customer?.houseNumber,
            [
              Validators.required,
              Validators.minLength(1),
              Validators.maxLength(15),
              Validators.pattern(regExp.alphaNumericSpace),
            ],
          ],
          placeOfBirth: [
            this.customer?.placeOfBirth,
            [
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(regExp.alphaSpace),
            ],
          ],
          occupation: [
            this.customerOccupations.find(
              (f) => f.id === this.customer?.occupationId,
            ),
            [Validators.required],
          ],
          city: [
            this.cities.find((f) => f.id === this.customer?.cityId),
            [Validators.required],
          ],
          country: [
            this.countries.find((f) => f.id === this.customer?.countryId),
            [Validators.required],
          ],
          isActive: [this.customer?.isActive],
          companyTitle: [this.customerTypeId == CustomerTypes.Corporate ? (this.customer?.firstName + ' '+ this.customer?.lastName) : '', this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          tradeRegistryNumber: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.tradeRegistryNumber : '', this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          taxIdentificationNumber: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.taxIdentificationNumber : '', this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          fieldOfActivity: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.fieldOfActivity : '',this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyMobilePhone: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.mobilePhone : '',this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyCountry: [this.customerTypeId == CustomerTypes.Corporate ? this.countries.find((f) => f.id === this.customer?.countryId): null,this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyCity: [this.customerTypeId == CustomerTypes.Corporate ? this.cities.find((f) => f.id == this.customer?.cityId) : null , this.customerTypeId == CustomerTypes.Corporate ? Validators.required : null],
          companyStreet: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.street : '' ,this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyBuildingNumber: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.buildingNumber : '' ,this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
          companyArea: [this.customerTypeId == CustomerTypes.Corporate ? this.customer?.companyArea : '',this.customerTypeId == CustomerTypes.Corporate ? Validators.required: null],
        };

        this.form = this.fb.group(formFields);

        if (this.customerTypeId == CustomerTypes.Corporate){
          this.unsetFieldRequired('firstName');
          this.unsetFieldRequired('middleName');
          this.unsetFieldRequired('lastName');
          this.unsetFieldRequired('mobilePhone');
          this.unsetFieldRequired('gender');
          this.unsetFieldRequired('dateOfBirth');
          this.unsetFieldRequired('sourceOfIncome');
          this.unsetFieldRequired('street');
          this.unsetFieldRequired('buildingNumber');
          this.unsetFieldRequired('houseNumber');
          this.unsetFieldRequired('placeOfBirth');
          this.unsetFieldRequired('occupation');
          this.unsetFieldRequired('city');
          this.unsetFieldRequired('country');
        } else {
          this.setFieldRequired('firstName');
          this.setFieldRequired('middleName');
          this.setFieldRequired('lastName');
          this.setFieldRequired('mobilePhone');
          this.setFieldRequired('gender');
          this.setFieldRequired('dateOfBirth');
          this.setFieldRequired('sourceOfIncome');
          this.setFieldRequired('street');
          this.setFieldRequired('buildingNumber');
          this.setFieldRequired('houseNumber');
          this.setFieldRequired('placeOfBirth');
          this.setFieldRequired('occupation');
          this.setFieldRequired('city');
          this.setFieldRequired('country');
        }
        if (this.addMode) {
          this.title = 'addCustomer';
        } else if (
          this.authService.isUserAuthorized(this.permissions.UPDATE_CUSTOMER)
        ) {
          this.title = 'updateCustomer';
        } else {
          this.title = 'viewCustomer';
          this.form.disable();
        }
      },
    );

    this.getBranchCountryCity(this.allCountries);
  }

  getBranchCountryCity(countries: LookupDto[]) {
    if (this.tellerAccounts.length) {
      this.branchService
        .branchById(
          this.tellerAccounts[0].parentId ? this.tellerAccounts[0].parentId : 0,
        )
        .subscribe((s) => {
          this.countries = countries.filter((f) => f.id === s[0].countryId);
          this.cities = this.cities.filter(
            (f) => f.parentId === this.countries[0].id,
          );
          this.form.controls['country'].setValue(this.countries[0]);
          this.form.controls['country'].disable();
          if (this.addMode) {
            this.onCountryChange(this.form.controls['country'].value.id);
          }
        }, ({ message }) => {
          this.snackBar.open(message);
        });
    } else {
      this.countries = countries;
    }
  }

  onCityChange(cityId: number) {
    // this.branchService.getLocalities(cityId).subscribe((localities) => {
    //   this.localities = localities;
    // });
  }

  onCountryChange(countryId: number) {
    this.branchService.branchCities(countryId).subscribe((cities) => {
      this.cities = cities;
    });
  }

  onSubmit() {

    if (!this.form.valid) {
      for (const key of Object.keys(this.form.controls)) {
        if (this.form.controls[key].invalid) {
          const invalidControl = this.el.nativeElement.querySelector(
            '[formcontrolname="' + key + '"]',
          );
          invalidControl.focus();
          break;
        }
      }
      return;
    }
    if (this.form.valid) {
      const formValues = this.form.getRawValue() || {};
      const dateOfBirth = formValues.dateOfBirth ? moment(formValues.dateOfBirth).format('YYYY-MM-DD'): null;

      var name: any;
      var firstName: any;
      var lastName: any;
      if(this.customerTypeId == CustomerTypes.Corporate) {
        name = splitFullName(formValues?.companyTitle, true);
        firstName =   name.firstName
        lastName =   name.lastName
      } else {
        firstName = formValues.firstName;
        lastName = formValues.lastName;
      }

      const customerDto: UpsertCustomerDto = {
        firstName: firstName,
        middleName: formValues.middleName,
        lastName: lastName,
        mobilePhone: this.customerTypeId == CustomerTypes.Corporate ? formValues.companyMobilePhone : formValues.mobilePhone,
        genderId: this.customerTypeId == CustomerTypes.Corporate ? this.customer?.genderId : formValues.gender?.id,
        cityId: this.customerTypeId == CustomerTypes.Corporate ? this.customer?.cityId : formValues.city?.id,
        countryId: this.customerTypeId == CustomerTypes.Corporate ? formValues.companyCountry?.id : formValues.country.id,
        street: this.customerTypeId == CustomerTypes.Corporate ? formValues.companyStreet : formValues.street,
        houseNumber: this.customerTypeId == CustomerTypes.Corporate ? this.customer?.houseNumber : formValues.houseNumber,
        buildingNumber: this.customerTypeId == CustomerTypes.Corporate ? formValues.companyBuildingNumber : formValues.buildingNumber,
        placeOfBirth: formValues.placeOfBirth,
        dateOfBirth: dateOfBirth,
        occupationId: this.customerTypeId == CustomerTypes.Corporate ? this.customer?.occupationId : formValues.occupation?.id,
        sourceOfIncomeId: this.customerTypeId == CustomerTypes.Corporate ? formValues.sourceOfIncome : formValues.sourceOfIncome?.id,
        isActive: formValues.isActive,
        customerTypeId: this.customer?.customerTypeId
      };

      if(this.customerTypeId == CustomerTypes.Corporate){
        customerDto.taxIdentificationNumber = formValues.taxIdentificationNumber;
        customerDto.tradeRegistryNumber = formValues.tradeRegistryNumber;
        customerDto.fieldOfActivity = formValues.fieldOfActivity;
        customerDto.companyArea = formValues.companyArea;
      }

      const handler = this.customer
        ? this.customerService.updateCustomer({
            id: this.customer?.id,
            ...customerDto,
          })
        : this.customerService.upsertCustomer(customerDto);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          if (this.addMode) {
            this.resetBtn?.nativeElement.click();
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  setFieldRequired(ctrlName: string) {
    const ctrl = this.form.controls[ctrlName];
    if (!ctrl) return;

    ctrl.setValidators([Validators.required]);
    ctrl.updateValueAndValidity();
  }

  unsetFieldRequired(ctrlName: string) {
    const ctrl = this.form.controls[ctrlName];
    if (!ctrl) return;

    ctrl.clearValidators();
    ctrl.updateValueAndValidity();
  }
}
