import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Permissions } from '../_common/enums/permissions.enums';
import {
  RemittancesDto,
  RemittanceReceiverUpdateDto,
} from './data/dto/remittances.dto';
import { AmbiguousRemittanceFilter, RemittanceFilter, RemittanceService } from './data/remittance.service';
import { AuthService } from '../auth/data/auth.service';
import { SnackBarService } from '../_common/snackBar.service';
import { RemittanceApprovalPopupComponent } from './remittanceApprovalPopup.component';
import { RemittanceBlockingPopupComponent } from './remittanceBlockingPopup.component';
import { RemittancePayReceiptPopupComponent } from './remittancePayReceiptPopup.component';
import { RemittanceSendReceiptPopupComponent } from './remittanceSendReceiptPopup.component';
import { ActivatedRoute } from '@angular/router';
import { LookupDto } from '../_common/data/dto/api.dto';
import { RemittanceReturnPopupComponent } from './remittanceReturnPopup.component';
import { RemittanceUnblockingPopupComponent } from './remittanceUnblockingPopup.component';
import { RemittanceHistoryPopupComponent } from './remittanceHistoryPopup.component';
import { RemittanceTracePopupComponent } from './remittanceTracePopup.component';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RemittanceReceiverInformationUpdateComponent } from './remittanceReceiverInformationUpdate.component';
import { GetBranchesDto } from '../branch/data/dto/branches.dto';
import { RemittancePaybackReceiptPopupComponent } from './remittancePaybackReceiptPopup.component';
import { RemittanceResolvePopupComponent } from './remittanceResolvePopup.component';
@Component({
  selector: 'app-ambiguous-remittances',
  templateUrl: './ambiguousRemittances.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class AmbiguousRemittancesComponent implements OnInit {
  form!: UntypedFormGroup;

  permissions = Permissions.remittanceManagement.remittances;
  direction: string = '1';
  displayedColumns: string[] = [
    '#',
    'code',
    'partnerRemittanceId',
    'senderName',
    'receiverName',
    'amount',
    'status',
    'actions',
  ];
  remittances = new MatTableDataSource<RemittancesDto>();
  loading = false;
  resultsLength = 0;
  isAdmin? = false;
  isRegional? = false;
  selected: string = '';
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isPdfFile: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: SnackBarService,
    private remittanceService: RemittanceService,
    public authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      remittanceCode: [null],
      remittanceId: [null],
      partnerRemittanceId: [null],
    });
  }

  onSubmit() {
    const { remittanceId, remittanceCode, partnerRemittanceId } = this.form.value;
    const filter: AmbiguousRemittanceFilter = {};
    if(remittanceCode) filter.remittanceCode = remittanceCode;
    if(remittanceId) filter.remittanceId = remittanceId;
    if(partnerRemittanceId) filter.partnerRemittanceId = partnerRemittanceId;

    this.remittanceService.getAmbiguousRemittances(filter)
      .subscribe((data)=>{
        this.remittances.data = data;
        this.remittances.paginator = this.paginator;
      });
  }

  viewHistoryDialog(remittanceId: number) {
    this.remittanceService
      .getRemittanceHistory(remittanceId)
      .subscribe((remittanceHistory) => {
        this.dialog.open(RemittanceHistoryPopupComponent, {
          disableClose: true,
          width: '900px',
          data: remittanceHistory,
        });
      });
  }

  unfreezeAndReverseRemittance(remittanceId: string) {
    const dialogRef = this.dialog.open(RemittanceResolvePopupComponent, {
      disableClose: true,
      width: '850px',
    });
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp === 'close') return;

      this.remittanceService
        .unfreezeAndReverseRemittance(remittanceId, resp.comments)
        .subscribe(
          (resp) => {
            this.onSubmit();
            this.snackBar.open(resp?.message);
          },
          ({ message }) => {
            this.snackBar.open(message);
          },
        );
    });
  }
}
