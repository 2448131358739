<div>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
      </div>
    </div>

    <div class="row" style="margin: 10px">
      <table
        id="conductors"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody *ngIf="!data.conductors.length">
        <tr>
          <td class="text-center">{{ 'noRecordsAvailable' | translate }}</td>
        </tr>
        </tbody>
        <tbody *ngIf="data.conductors.length">
        <tr>
          <th class="text-nowrap">{{ 'id' | translate }}</th>
          <th class="text-nowrap">{{ 'firstName' | translate }}</th>
          <th class="text-nowrap">
            {{ 'lastName' | translate }}
          </th>
          <th class="text-nowrap">{{ 'mobile' | translate }}</th>
          <th class="text-nowrap">{{ 'placeOfBirth' | translate }}</th>
          <th class="text-nowrap">{{ 'dateOfBirth' | translate }}</th>
          <th class="text-nowrap">{{ 'position' | translate }}</th>
          <th class="text-nowrap">{{ 'fartherName' | translate }}</th>
          <th class="text-nowrap">
            {{ 'motherName' | translate }}
          </th>
          <th class="text-nowrap">{{ 'actions' | translate }}</th>
        </tr>
        <tr *ngFor="let conductor of data.conductors">
          <td class="text-nowrap">{{ conductor.id }}</td>
          <td class="text-nowrap">{{ conductor.firstName }}</td>
          <td class="text-nowrap">{{ conductor.lastName }}</td>
          <td class="text-nowrap">{{ conductor.mobile }}</td>
          <td class="text-nowrap">{{ conductor.placeOfBirth }}</td>
          <td class="text-nowrap">
            {{ conductor.dateOfBirth }}
          </td>
          <td class="text-nowrap">
            {{ conductor.position }}
          </td>
          <td class="text-nowrap">{{ conductor.fartherName }}</td>
          <td class="text-nowrap">{{ conductor.motherName }}</td>

          <a
             mat-menu-item
             color="primary" (click)="editConductor(conductor?.id)">
            <mat-icon>edit</mat-icon> {{ 'edit' | translate }}
          </a>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
