<!-- date filter -->
<mat-card class="date-filter-card">
    <div class="date-filters">
        <div class="date-inputs">
            <!-- Start Date Picker -->
            <mat-form-field appearance="outline">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="startPicker" placeholder="Choose a start date" [(ngModel)]="startDate">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <!-- End Date Picker -->
            <mat-form-field appearance="outline">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="endPicker" placeholder="Choose an end date" [(ngModel)]="endDate">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>
        <button mat-raised-button color="primary" (click)="onSubmit()" class="search-button">Search</button>
    </div>
</mat-card>



<mat-card class="table-card">
    <mat-card-title>{{ 'referalDashboard' | translate }}</mat-card-title>
    <div class="row cust-count my-2">
        <!-- total customers -->
        <div class="col-md-3" *ngFor="let custCount of customersDataSource.data">
            <mat-card>
                <mat-card-title class="count-number-clickable"  *ngIf="custCount.count > 0"
                    (click)="ormReferalCustomerCountClick(custCount.recruiterCode, custCount!.count)">
                    {{custCount!.count}}
                </mat-card-title>
                <mat-card-title class="count-number"  *ngIf="custCount.count == 0">
                    {{custCount!.count}}
                </mat-card-title>
                <button (click)="getReferalCustomerCount()" class="float-end text-muted" mat-icon-button>
                    <mat-icon>refresh</mat-icon>
                </button>
                <span class="float-end" *ngIf="custCount.isLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                </span>
                <p>{{custCount.status}}</p>

            </mat-card>

        </div>

        <!-- total remittanes -->
        <div class="col-md-3" *ngFor="let remittanceCount of remittancesDataSource.data">
            <mat-card>
                <mat-card-title class="count-number-clickable" *ngIf="remittanceCount.count > 0"
                    (click)="ormReferalRemittanceCountClick(remittanceCount.recruiterCode, remittanceCount!.count)">
                    {{remittanceCount!.count}}
                </mat-card-title>
                <mat-card-title class="count-number" *ngIf="remittanceCount.count == 0">
                    {{remittanceCount!.count}}
                </mat-card-title>
                <button (click)="getReferalRemittanceCount()" class="float-end text-muted" mat-icon-button>
                    <mat-icon>refresh</mat-icon>
                </button>
                <span class="float-end" *ngIf="remittanceCount.isLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                </span>
                <p>{{remittanceCount.status}}</p>

            </mat-card>
        </div>

         <!-- balance commission -->
         <div class="col-md-3" *ngFor="let balanceCommissionCount of blanceCommissionDataSource.data">
            <mat-card>
                <mat-card-title class="count-number-clickable" *ngIf="balanceCommissionCount.count > 0"
                    (click)="ormReferalBalanceCommissionCountClick(balanceCommissionCount.recruiterCode)">
                    {{balanceCommissionCount!.count}}
                </mat-card-title>
                <mat-card-title class="count-number" *ngIf="balanceCommissionCount.count == 0"> 
                    {{balanceCommissionCount!.count}}
                </mat-card-title>
                <button (click)="getReferalBlanaceCommision()" class="float-end text-muted" mat-icon-button>
                    <mat-icon>refresh</mat-icon>
                </button>
                <span class="float-end" *ngIf="balanceCommissionCount.isLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                </span>
                <p>{{balanceCommissionCount.status}}</p>

            </mat-card>
        </div>


        <!-- commission -->
        <div class="col-md-3" *ngFor="let commissionCount of commissionDataSource.data">
            <mat-card>
                <mat-card-title class="count-number-clickable" *ngIf="commissionCount.count > 0" 
                    (click)="ormReferalCommissionCountClick(commissionCount.recruiterCode)">
                    {{commissionCount!.count}}
                </mat-card-title>
                <mat-card-title class="count-number" *ngIf="commissionCount.count == 0">
                    {{commissionCount!.count}}
                </mat-card-title>
                <button (click)="getReferalCommision()" class="float-end text-muted" mat-icon-button>
                    <mat-icon>refresh</mat-icon>
                </button>
                <span class="float-end" *ngIf="commissionCount.isLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                </span>
                <p>{{commissionCount.status}}</p>

            </mat-card>
        </div>

    </div>
</mat-card>