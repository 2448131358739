import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {CbkRemittanceReportDto, CbkReportDetailFilter, CbkReportFilter} from "./data/Dto/CbkRemittance.dto";
import {CbkReportService} from "./data/cbkReports.service";
import { Utilities } from '../_common/utils';
import { BsmvReportPopupComponent } from './bsmv-report-popup';
import {SnackBarService} from "../_common/snackBar.service";
import { LookupDto } from '../_common/data/dto/api.dto';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bsmvtax',
  templateUrl: './bsmvtax.component.html'
})
export class BsmvTaxComponent implements OnInit {

// @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public form!: FormGroup;
  fromDate = new UntypedFormControl(new Date(), [Validators.required]);
  toDate = new UntypedFormControl(new Date(), [Validators.required]);
  currencies!: LookupDto[];
  constructor(private fb : UntypedFormBuilder,
    private cbkRemittanceService : CbkReportService,
    private dialog : MatDialog, 
    private snackBar: SnackBarService, 
    private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {

    this.route.data.subscribe(({currencies})=>{
      this.currencies = currencies;
    });

    this.form = this.fb.group({
      startDate: this.fromDate,
      endDate: this.toDate,
      currencyId: [null, [Validators.required]]
    });
  }

  onSubmit(){
    if(this.form.valid){
      const { startDate, endDate } =
        this.form.value;
      const filter: CbkReportFilter = {
        startDate: startDate,
        endDate: endDate,
        currencyId: this.form.value.currencyId,
      };
      
      this.cbkRemittanceService
      .getBsmvTaxReportDetail(filter)
      .subscribe((resp: any) => {
        const dialogRef = this.dialog.open(
          BsmvReportPopupComponent,
          {
            data: resp,
            width: '1000px',
            maxHeight: '90vh',
            position: { top: '8rem', left : '15rem' },
          },
        );

        dialogRef.afterClosed().subscribe((resp: string) => {
          if (resp === 'close') {
            return;
          }
        });
      }, ({ message }) => {
        this.snackBar.open(message);
      });

    }
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, 'date');
  }
}
