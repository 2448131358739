import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Permissions } from '../../_common/enums/permissions.enums';
import { SnackBarService } from '../../_common/snackBar.service';
import { AuthService } from '../../auth/data/auth.service';
import { LookupDto } from '../../_common/data/dto/api.dto';
import { RemittanceCommissionConfigurationsDto } from '../data/dto/commissionConfigurations.dto';
import { RemittanceType } from '../data/dto/remittances.dto';
import { mapRemittanceSubTypesToRemittanceTypes } from '../data/remittance.helper';
import { CommissionConfigurationService } from '../data/commissionConfigurations.service';

@Component({
  selector: 'app-remit-comm-config-details',
  templateUrl: './commissionConfigDetails.component.html',
})
export class CommissionConfigDetailsComponent implements OnInit {
  form!: UntypedFormGroup;

  permissions = Permissions.remittanceManagement.commissionConfiguration;
  //@ts-ignore
  @ViewChild('resetBtn', { read: ElementRef }) resetBtn: ElementRef;

  countries: LookupDto[] = [];
  remittanceTypes: RemittanceType[] = [];

  commissionConfig?: RemittanceCommissionConfigurationsDto;
  addMode = true;
  title = '';

  constructor(
    private fb: UntypedFormBuilder,
    private commissionConfigurationService: CommissionConfigurationService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    public authService: AuthService,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({ addMode, countries, remittanceSubTypes, commissionConfigs }) => {
        this.addMode = addMode;
        this.countries = countries;
        this.remittanceTypes =
          mapRemittanceSubTypesToRemittanceTypes(remittanceSubTypes);

        if (commissionConfigs && commissionConfigs.length) {
          this.commissionConfig = commissionConfigs[0];
        }

        this.form = this.fb.group({
          senderCountryId: [this.commissionConfig?.senderCountryId],
          receiverCountryId: [this.commissionConfig?.receiverCountryId],
          remittanceTypeId: [this.commissionConfig?.remittanceTypeId],
          remittanceSubTypeId: [this.commissionConfig?.remittanceSubTypeId],
          minAmount: [this.commissionConfig?.minAmount, [Validators.required]],
          maxAmount: [this.commissionConfig?.maxAmount],
          minCommission: [this.commissionConfig?.minCommission],
          commission: [
            this.commissionConfig?.commission,
            [Validators.required],
          ],
          maxCommission: [this.commissionConfig?.maxCommission],
          isActive: [this.commissionConfig?.isActive ? 'active' : 'inactive'],
          requesterComments: [null, [Validators.required]],
        });

        if (this.addMode) {
          this.title = 'addCommissionConfig';
        } else if (
          this.authService.isUserAuthorized(
            this.permissions.UPDATE_REMIT_COMM_CONFIG,
          )
        ) {
          this.title = 'updateCommissionConfig';
        } else {
          this.title = 'viewCommissionConfig';
          this.form.disable();
        }
      },
    );
  }

  get remittanceSubTypes(): LookupDto[] {
    const selectedRemittanceTypeId =
      this.form?.controls['remittanceTypeId'].value;
    if (!selectedRemittanceTypeId) return [];

    return (
      this.remittanceTypes.find((rt) => rt.id === selectedRemittanceTypeId)
        ?.remittanceSubTypes || []
    );
  }

  onSubmit() {
    if (this.form.valid) {
      const { value: formData } = this.form || {};
      if (formData && !this.addMode)
        formData.isActive = formData.isActive === 'active';

      const handler = this.commissionConfig
        ? this.commissionConfigurationService.updateCommissionConfig({
            id: this.commissionConfig.id,
            ...formData,
          })
        : this.commissionConfigurationService.createCommissionConfig(formData);

      handler.subscribe(
        (resp) => {
          const message = resp?.message;
          this.snackBar.open(message);
          if (this.addMode) {
            this.resetBtn?.nativeElement.click();
          }
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }
}
