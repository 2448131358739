import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-images',
  templateUrl: './documentImages.component.html',
  styleUrls: ['./domentImages.component.css']
})
export class DocumentImagesComponent {
  images: { src: string; alt: string; title: string }[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.images = [
      { src: data.imageUrl, alt: 'Selfie Photo', title: 'Selfie Photo' },
      { src: data.DocumentImages, alt: 'Document Image', title: 'Document Image' },
      {
        src: data.ormIdVerificationResultImage,
        alt: data.ormIdVerificationResultImage ? 'Home Affairs Result' : 'Home Affairs Result Not Found', 
        title: data.ormIdVerificationResultImage ? 'Home Affairs Result' : 'Home Affairs Result Not Found'
      }    
    ];
  }
}
