import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { SnackBarService } from '../../_common/snackBar.service';
import { LookupsService } from '../data/lookups.service';
import { LookupsDto } from '../data/lookups.dto';
import { LookupDto } from 'src/app/_common/data/dto/api.dto';

@Component({
  selector: 'app-localities-popup',
  templateUrl: './localityPopup.component.html',
})
export class LocalityPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  addMode: boolean = true;

  locality!: LookupsDto;
  cities!: LookupDto[];
  countries!: LookupDto[];
  localityCities: LookupDto[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private lookupsService: LookupsService,
    private snackBar: SnackBarService,
    public dialogRef: MatDialogRef<LocalityPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      locality: LookupsDto[];
      cities: LookupDto[];
      countries: LookupDto[];
      cityId: number;
    },
  ) {
    if (data.cityId) {
      this.locality = data.locality[0];
      if (this.locality) {
        this.addMode = false;
      }
    }
    this.countries = data.countries;
    this.localityCities = data.cities;
  }

  ngOnInit() {
    this.form = this.fb.group({
      localityName: [this.locality?.name, [Validators.required]],
      city: [
        this.data.cityId
          ? this.localityCities.find((city) => city.id === this.data.cityId)
          : null,
        [Validators.required],
      ],
      country: [
        this.data.cityId
          ? this.countries.find(
              (country) =>
                country.id ===
                this.localityCities.filter(
                  (city) => city.id === this.data.cityId,
                )[0].parentId,
            )
          : null,
        [Validators.required],
      ],
      isActive: [this.locality?.isActive || this.addMode],
    });
  }

  onCountryChange(countryId: number) {
    this.localityCities = this.data.cities;
    this.localityCities = this.localityCities.filter(
      (city) => city.parentId === countryId,
    );
  }

  onSubmit() {
    if (this.form.valid) {
      const formValues = this.form.value;
      const locality: LookupsDto = {
        name: formValues.localityName,
        parentId: formValues.city.id,
        isActive: formValues.isActive || false,
      };

      const handler = this.locality
        ? this.lookupsService.updateLocality({
            id: this.locality.id,
            ...locality,
          })
        : this.lookupsService.createLocality(locality);

      handler.subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close('success');
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
