<h1 mat-dialog-title>{{ 'settlementReport' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px; text-align: center">
      <div class="col justify-content-center">
        <h1>{{ 'settlementReport' | translate }}</h1>
      </div>
    </div>
    <div class="row" style="margin: 10px">
      <table class="table table-borderless">
        <tr>
          <td>{{ 'branchCode' | translate }}:</td>
          <td>{{ data.data.branchCode }}</td>
          <td>{{ 'branchTitle' | translate }}:</td>
          <td>{{ data.data.branchTitle }}</td>
        </tr>
        <tr>
          <td>{{ 'address' | translate }}:</td>
          <td>{{ data.data.branchAddress }}</td>
          <td>{{ 'mobile' | translate }}:</td>
          <td>{{ data.data.branchMobilePhone }}</td>
        </tr>
        <tr>
          <td>{{ 'startDate' | translate }}:</td>
          <td>{{ convertDateToLocaleString(data.startDate, false) }}</td>
          <td>{{ 'endDate' | translate }}:</td>
          <td>{{ convertDateToLocaleString(data.endDate, false) }}</td>
          <td class="onlyPrintable">{{ 'printedDate' | translate }}:</td>
          <td class="onlyPrintable">
            {{ convertDateToLocaleString(data.startDate, true) }}
          </td>
        </tr>
      </table>
    </div>
    <div class="row" style="margin: 10px">
      <table
        id="settlements"
        class="table table-bordered table-hover"
        width="100%"
      >
        <tbody *ngIf="!data.data.settlements.length">
          <tr>
            <td class="text-center">{{ 'noRecordsAvailable' | translate }}</td>
          </tr>
        </tbody>
        <tbody *ngIf="data.data.settlements.length">
          <tr>
            <th class="text-nowrap">{{ 'settlerCode' | translate }}</th>
            <th class="text-nowrap">{{ 'settlerAccount' | translate }}</th>
            <th class="text-nowrap">
              {{ 'settlerAccountAmount' | translate }}
            </th>
            <th class="text-nowrap">{{ 'settlerBaseAmount' | translate }}</th>
            <th class="text-nowrap">{{ 'amountUsd' | translate }}</th>
            <th class="text-nowrap">{{ 'beneficiaryCode' | translate }}</th>
            <th class="text-nowrap">{{ 'beneficiaryAccount' | translate }}</th>
            <th class="text-nowrap">{{ 'beneficiaryAmount' | translate }}</th>
            <th class="text-nowrap">
              {{ 'beneficiaryBaseAmount' | translate }}
            </th>
            <th class="text-nowrap">{{ 'status' | translate }}</th>
            <th class="text-nowrap">{{ 'dateCreated' | translate }}</th>
            <th class="text-nowrap">{{ 'dateStatusChanged' | translate }}</th>
          </tr>
          <tr *ngFor="let settlement of data.data.settlements">
            <td class="text-nowrap">{{ settlement.settlerCode }}</td>
            <td class="text-nowrap">{{ settlement.settlerAccount }}</td>
            <td class="text-nowrap">
              {{ settlement.settlerGlAmount| number:'1.2-2' }} {{ settlement.settlerAccountCurrency }}
            </td>
            <td class="text-nowrap">
              {{ settlement.settlerBaseCurrencyAmount | number:'1.2-2' }} {{ settlement.settlerBaseCurrency }}
            </td>
            <td class="text-nowrap">{{ settlement.amount | number:'1.2-2' }}</td>
            <td class="text-nowrap">{{ settlement.beneficiaryCode }}</td>
            <td class="text-nowrap">{{ settlement.beneficiaryAccount }}</td>
            <td class="text-nowrap">
              {{ settlement.beneficiaryGlAmount | number:'1.2-2' }} {{ settlement.beneficiaryAccountCurrency }}
            </td>
            <td class="text-nowrap">
              {{ settlement.beneficiaryBaseCurrencyAmount | number:'1.2-2' }} {{ settlement.beneficiaryBaseCurrency }}
            </td>
            <td class="text-nowrap">{{ settlement.status }}</td>
            <td class="text-nowrap">
              {{ convertDateToLocaleString(settlement.createdDate, false) }}
            </td>
            <td class="text-nowrap">
              {{
                settlement.statusChangeDate
                  ? convertDateToLocaleString(
                      settlement.statusChangeDate,
                      false
                    )
                  : ''
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="report"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
