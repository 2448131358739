import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { BranchesService } from './data/branches.service';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import {
  BranchLimitApprovalRequestDto,
  SearchBranchesDto,
} from './data/dto/branches.dto';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['../_common/styles/table.less'],
})
export class BranchesComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: UntypedFormGroup;

  permissions = Permissions.branchManagement.branches;
  resultsLength = 0;
  loading = false;
  displayedColumns: string[] = [
    'number',
    'code',
    'title',
    'type',
    'city',
    'country',
    'status',
    'totalLimit',
    'usedLimit',
    'balance',
    'actions',
  ];
  data = new MatTableDataSource<SearchBranchesDto>();
  branchLimitApproval: BranchLimitApprovalRequestDto[] = [];
  isRegional: boolean | undefined;
  isAdmin: boolean | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private router: ActivatedRoute,
    private branchesService: BranchesService,
    public authService: AuthService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      branchCode: [null],
      branchStatus: [null],
    });
    this.branchesService.getbranches().subscribe(({ appliedFilters, data }) => {
      this.loading = false;
      this.data.data = data;
      this.data.paginator = this.paginator;
      this.resultsLength = data.length;
      this.form.controls['branchCode'].setValue(appliedFilters?.branchCode);
      this.form.controls['branchStatus'].setValue(appliedFilters?.branchStatus);
    });
    this.isAdmin = this.authService.currentUserValue?.isAdmin;
    this.isRegional = this.authService.currentUserValue?.isRegional;
  }

  onSubmit() {
    const { branchCode, branchStatus } = this.form.value;
    const filters =
      branchCode || branchStatus
        ? { branchCode: branchCode || null, branchStatus: branchStatus || null }
        : null;
    this.branchesService.searchBranches(filters);
  }
}
