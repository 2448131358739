<app-index-container [label]="'parquetReport' | translate">
  <form
    form
    class="container-fluid flex-column p-0"
    name="form"
    [formGroup]="form"
    novalidate
    (submit)="onSubmit(false)"
    autocomplete="off"
  >
    <div class="row">
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'date' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="oneDate"
          formControlName="date"
          [value]="date.value"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="oneDate"
        ></mat-datepicker-toggle>
        <mat-datepicker startView="month" #oneDate></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'type' | translate }}</mat-label>
        <mat-select formControlName="type">
          <mat-option value="outgoing">{{'outgoing' | translate}}</mat-option>
          <mat-option value="incoming">{{'incoming' | translate}}</mat-option>
          <mat-option value="internal">{{'internal' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col">
        <mat-label>{{ 'recordType' | translate }}:</mat-label>
        <input matInput #recordType formControlName="recordType" />
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-end my-3">
      <button mat-raised-button type="submit" color="primary">
        <i class="fa-solid fa-magnifying-glass"></i>
        {{ 'search' | translate }}
      </button>
    </div>
  </form>
  <table table mat-table [dataSource]="data" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>{{ 'name' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ row.reportFileName }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="reportType">
      <th mat-header-cell *matHeaderCellDef>{{ 'type' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ (row.reportType) | translate }}</td>
    </ng-container>

    <!-- code Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'date' | translate }}</th>
      <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.createdDate) }}</td>
    </ng-container>
    <!-- Country Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'actions' | translate }}</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-menu">
          <a [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </a>
          <mat-menu #menu="matMenu" class="action-menu-item">
            <a
              *ngIf="!row.isDeleted"
              mat-menu-item
              (click)="downloadParquetReport(row.reportFileName, row.filePath, row.reportType)"
            >
              <mat-icon>download</mat-icon>{{ 'download' | translate }}
            </a>
            <a
              mat-menu-item
              *ngIf="!row.isDeleted"
              (click)="deleteTheReport(row.id)"
            >
              <mat-icon>delete_forever</mat-icon>{{ 'delete' | translate }}
            </a>
            <a
              mat-menu-item
              *ngIf="row.isDeleted"
              (click)="regenerateTheReport(row)"
            >
              <mat-icon>undo</mat-icon>{{ 'regenerate' | translate }}
            </a>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    paginator
    [pageSizeOptions]="[10, 25, 50]"
    [pageSize]="10"
  ></mat-paginator>
</app-index-container>
