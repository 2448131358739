import { Component, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { AuthService } from '../auth/data/auth.service';
import { GetSettlementDetailsDto } from './data/dto/settlement.dto';
import { Permissions } from '../_common/enums/permissions.enums';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-settlement-confirm-receipt',
  templateUrl: './settlementConfirmReceiptPopup.component.html',
  styleUrls: ['./settlement.css'],
})
export class SettlementConfirmReceiptPopupComponent {
  permissions = Permissions.settlementManagement.settlements;

  constructor(
    public dialogRef: MatDialogRef<SettlementConfirmReceiptPopupComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: GetSettlementDetailsDto,
    public authService: AuthService,
  ) {}

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  checkNullValue(value: string | undefined) {
    if (value === null) value = '';
    return value;
  }
}
