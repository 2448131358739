import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SettlementService } from './settlement.service';
import { LookupDto } from '../../_common/data/dto/api.dto';

@Injectable()
export class SettlementTypesResolver implements Resolve<LookupDto[]> {
  constructor(private settlementService: SettlementService) {}
  resolve(): Observable<LookupDto[]> {
    return this.settlementService.getSettlementTypes();
  }
}

@Injectable()
export class SettlementStatusResolver implements Resolve<LookupDto[]> {
  constructor(private settlementService: SettlementService) {}
  resolve(): Observable<LookupDto[]> {
    return this.settlementService.getSettlementStatus();
  }
}
