import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Permissions } from '../_common/enums/permissions.enums';
import { AuthService } from '../auth/data/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { GetSettlementDto } from './data/dto/settlement.dto';
import { SettlementFilter, SettlementService } from './data/settlement.service';
import { ActivatedRoute } from '@angular/router';
import { LookupDto } from '../_common/data/dto/api.dto';
import { SearchBranchesDto } from '../branch/data/dto/branches.dto';
import { SettlementDetailsPopupComponent } from './settlementDetailsPopup.component';
import { SettlementHistoryPopupComponent } from './settlementHistoryPopup.component';
import { SettlementTransferPopupComponent } from './settlementTransferPopup.component';
import { SnackBarService } from '../_common/snackBar.service';
import { SettlementRejectPopupComponent } from './settlementRejectPopup.component';
import { SettlementRollbackPopupComponent } from './settlementRollbackPopup.component';
import { SettlementConfirmPopupComponent } from './settlementConfirmPopup.component';
import { BranchesService } from '../branch/data/branches.service';
import { forkJoin } from 'rxjs';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
})
export class SettlementComponent implements OnInit {
  // @ts-ignore
  @ViewChild(MatPaginator) paginator: MatPaginator;
  form!: FormGroup;

  permissions = Permissions.settlementManagement.settlements;
  loading = false;
  resultsLength = 0;
  settlementTypes: LookupDto[] = [];
  settlers: SearchBranchesDto[] = [];
  beneficiaries: SearchBranchesDto[] = [];
  statuses: LookupDto[] = [];
  displayedColumns: string[] = [
    'number',
    'date',
    'amount',
    'confirmedAmount',
    'settler',
    'beneficiary',
    'type',
    'status',
    'actions',
  ];
  data = new MatTableDataSource<GetSettlementDto>();

  constructor(
    private fb: FormBuilder,
    private settlementService: SettlementService,
    private dialog: MatDialog,
    private branchService: BranchesService,
    public authService: AuthService,
    private snackBar: SnackBarService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(
      ({ settlementTypes, settlers, beneficiaries, statuses }) => {
        this.settlementTypes = settlementTypes;
        this.settlers = settlers;
        this.beneficiaries = beneficiaries;
        this.statuses = statuses.sort((a: LookupDto, b: LookupDto) =>
          a.name.localeCompare(b.name),
        );
      },
    );

    this.form = this.fb.group({
      direction: [null],
      type: [null],
      settler: [null],
      beneficiary: [null],
      status: [null],
      settlementId: [null],
      partnerSettlement: [false],
    });

    this.settlementService.settlements.subscribe(
      ({ settlements, appliedFilters }) => {
        this.loading = false;
        this.data.data = settlements;
        this.data.paginator = this.paginator;
        this.resultsLength = settlements.length;
        this.form.controls['direction'].setValue(appliedFilters?.direction);
        this.form.controls['settler'].setValue(
          this.settlers.find(
            (settler) => settler.id === appliedFilters?.settlerId,
          ),
        );
        this.form.controls['beneficiary'].setValue(
          this.beneficiaries.find(
            (beneficiary) => beneficiary.id === appliedFilters?.beneficiaryId,
          ),
        );
        this.form.controls['status'].setValue(
          this.statuses.find(
            (status) => status.id === appliedFilters?.statusId,
          ),
        );
        this.form.controls['type'].setValue(
          this.settlementTypes.find(
            (type) => type.id === appliedFilters?.settlementTypeId,
          ),
        );
        this.form.controls['settlementId'].setValue(
          appliedFilters?.settlementId
        );
      },
    );
  }

  onSubmit() {
    const { direction, type, settler, beneficiary, status, settlementId, partnerSettlement } = this.form.value;
    const filters: SettlementFilter = {};

    if (direction) filters.direction = direction;
    if (type) filters.settlementTypeId = type.id;
    if (settler) filters.settlerId = settler.id;
    if (beneficiary) filters.beneficiaryId = beneficiary.id;
    if (status) filters.statusId = status.id;
    if (settlementId) filters.settlementId = settlementId;
    if (partnerSettlement) filters.partnerSettlement = partnerSettlement;

    this.settlementService.getSettlements(filters);
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }

  viewSettlementDialog(settlementId: number) {
    this.settlementService
      .getSettlementDetails(settlementId)
      .subscribe((settlementDetails) => {
        this.dialog.open(SettlementDetailsPopupComponent, {
          disableClose: true,
          width: '900px',
          data: settlementDetails,
        });
      }, ({ message }) => {
        this.snackBar.open(message);
      });
  }

  viewHistoryDialog(settlementId: number) {
    this.settlementService
      .getSettlementHistory(settlementId)
      .subscribe((settlementHistories) => {
        this.dialog.open(SettlementHistoryPopupComponent, {
          disableClose: true,
          width: '900px',
          data: settlementHistories,
        });
      },({ message }) => {
      this.snackBar.open(message);
    });
  }

  transferSettlementDialog(
    settlementId: number,
    settlerId: number,
    settlerComments: string,
  ) {
    this.dialog.open(SettlementTransferPopupComponent, {
      disableClose: true,
      width: '900px',
      data: { settlementId, settlerComments, settlerId },
    });
  }

  rejectSettlementDialog(settlementId: number, settlerComments: string, partnerSettlementId: number, isPartnerSettlement: boolean) {
    this.dialog.open(SettlementRejectPopupComponent, {
      disableClose: true,
      width: '900px',
      data: { settlerComments, settlementId, partnerSettlementId, isPartnerSettlement },
    });
  }

  rollbackSettlementDialog(settlementId: number, settlerComments: string, partnerSettlementId: number, isPartnerSettlement: boolean) {
    this.dialog.open(SettlementRollbackPopupComponent, {
      disableClose: true,
      width: '900px',
      data: { settlementId, settlerComments, partnerSettlementId, isPartnerSettlement },
    });
  }

  confirmSettlementDialog(
    settlementId: number,
    settlerComments: string,
    amount: string,
    beneficiaryId: number,
  ) {
      forkJoin([
        this.branchService.getBranchAccounts(beneficiaryId),
        this.branchService.getBranchExchangeRates(
          beneficiaryId,
          'Exchange Rate for Settlements',
        ),
        this.branchService.getBranches({ branchId: beneficiaryId }),
        this.settlementService.getSettlementDetails(settlementId),
      ]).subscribe(
        ([accounts, exchangeRates, branches, settlement]) => {
          const [branch] = branches;
          if (!branch) {
            this.snackBar.open('Beneficiary branch not found');
            return;
          }
          this.dialog.open(SettlementConfirmPopupComponent, {
            disableClose: true,
            width: '900px',
            data: { settlement, accounts, exchangeRates, branch },
          });
        },
        ({ message }) => {
          this.snackBar.open(message);
        },
      );
  }
}
