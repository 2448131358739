<div class="signup-container">
    <h1>Sign-Up And Mandate</h1>
    <p>
      I agree to use the Tayo Pay cross-border payment service ('Service') in
      accordance with the terms and conditions ('Terms') outlined at
      <a href="https://www.tayopay.co.za" target="_blank">www.tayopay.co.za</a> and hereby declare that:
    </p>
    <ul>
      <li>I have read the Terms and know and understand the contents thereof;</li>
      <li>
        The information furnished by me to Tayo Pay when registering as a user
        of the Service is in all respects true, correct, and authentic;
      </li>
      <li>
        The currency I will apply for will only be used for the specific purpose
        stated in the instruction I will give Tayo Pay when sending money to
        someone;
      </li>
      <li>
        I have been informed of the payment limits applicable to transactions
        processed on the Service and confirm that these limits will not be
        exceeded as a result of the conclusion of any transaction via the
        Service.
      </li>
      <li>
        I consent to this information being given to the South African Revenue
        Service and/or the Financial Intelligence Centre.
      </li>
      <li>
        I hereby mandate Tayo Pay to act on my behalf in accordance with the
        Currency and Exchanges Manual for ADLAs.
      </li>
    </ul>
  </div>
  