import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { RemittanceViolationsDto } from './data/dto/remittances.dto';
import { AttachmentsPopupComponent } from './attachmentsPopup.component';
import { SnackBarService } from '../_common/snackBar.service';
import { RemittanceService } from './data/remittance.service';
import { Utilities } from '../_common/utils';

@Component({
  selector: 'app-remittance-approval',
  templateUrl: './remittanceApprovalPopup.component.html',
})
export class RemittanceApprovalPopupComponent implements OnInit {
  comments: string = '';
  data!: RemittanceViolationsDto;

  constructor(
    public dialogRef: MatDialogRef<RemittanceApprovalPopupComponent>,
    private snackBar: SnackBarService,
    private remittanceService: RemittanceService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public remittance: { id: number },
  ) {}

  ngOnInit() {
    this.remittanceService
      .getRemittanceViolations(this.remittance.id)
      .subscribe((approvalData) => {
        this.data = approvalData;
      });
  }

  onShowDocs() {
    this.remittanceService
      .getRemittanceDetails(this.remittance.id)
      .subscribe((rem) => {
        const documentPath = rem.senderPrimaryDoc?.documentPaths[0];
        const cleanPath = documentPath[Object.keys(documentPath)[0]];
        const strPath = JSON.stringify(cleanPath);
        const documentExtension = strPath.split('/')[3].split('.')[1];
        this.remittanceService
          .getAttachment(cleanPath)
          .subscribe((attachment) => {
            if (documentExtension.toUpperCase().includes('PDF')) {
              const pdfFile = new Blob([attachment], {
                type: 'application/pdf',
              });
              window.open(
                URL.createObjectURL(pdfFile),
                'Customer document',
                'width=1000,height=500',
              );
            } else {
              this.dialog
                .open(AttachmentsPopupComponent, {
                  disableClose: true,
                  restoreFocus: true,
                  width: '800px',
                  data: URL.createObjectURL(attachment),
                })
                .afterClosed()
                .subscribe((res) => {});
            }
          });
      });
  }

  onApproval() {
    this.remittanceService
      .approveRemittance({
        remittanceId: this.remittance.id,
        isApproved: true,
        comments: this.comments,
      })
      .subscribe(
        (resp) => {
          this.snackBar.open(resp?.message);
          this.dialogRef.close({ success: true });
        },
        ({ message }) => {
          this.snackBar.open(message);
          this.dialogRef.close({ success: false });
        },
      );
  }

  convertDateToLocaleString(date: Date) {
    return Utilities.convertDateToLocaleString(date, '');
  }
}
