<h2>{{ 'Commission Earned' | translate }}</h2>
<table table mat-table [dataSource]="remittances" class="table">
    <!-- Number Column -->
    <ng-container matColumnDef="#">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      </ng-container>
    <!-- Code Column -->
    <ng-container matColumnDef="remittanceId">
        <th mat-header-cell *matHeaderCellDef>{{ 'remittanceId' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
    </ng-container>

    <ng-container matColumnDef="recruiterName">
        <th mat-header-cell *matHeaderCellDef> {{ 'recruiterName' | translate}} </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.recruiterName" matTooltipPosition="after">
            {{ row.fullName }}<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="recruiterCode">
        <th mat-header-cell *matHeaderCellDef> {{ 'recruiterCode' | translate}} </th>
        <td mat-cell *matCellDef="let row" [matTooltip]="row.recruiterCode" matTooltipPosition="after">
            {{ row.recruiterCode }} &nbsp;<i *ngIf="row.smsSent" class="fa-solid fa-message"></i>
        </td>
    </ng-container>

    <ng-container matColumnDef="commission">
        <th mat-header-cell *matHeaderCellDef>{{ 'commission ' | translate }}( USD )</th>
        <td mat-cell *matCellDef="let row">{{ row.recruiterCommInUsd }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>{{ 'status' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ row.status }}</td>
    </ng-container>       

    <ng-container matColumnDef="dateProcessed">
        <th mat-header-cell *matHeaderCellDef>{{ 'dateProcessed' | translate }}</th>
        <td mat-cell *matCellDef="let row">{{ convertDateToLocaleString(row.dateProcessed) }}</td>
    </ng-container>

    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" [attr.colspan]="displayedColumns.length">
            {{ 'noRecordsAvailable' | translate }}
        </td>
    </tr>
</table>
<mat-paginator paginator [pageSizeOptions]="[10, 25, resultsLength]" [pageSize]="10"></mat-paginator>