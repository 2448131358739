<h1 mat-dialog-title style="text-align: center">{{ 'complianceReportDetails' | translate }}</h1>
<div mat-dialog-content>
  <div role="content" style="font-size: 9px; margin-top: 5px" id="report">
    <div class="row" style="margin: 10px">
      <table id="complianceReport" class="table table-borderless">
        <tbody *ngIf="data.data.length">
          <tr>
            <th class="text-nowrap">{{ 'code' | translate }}</th>
            <th class="text-nowrap">{{ 'RID' | translate }}</th>
            <th class="text-nowrap">{{ 'senderBranch' | translate }}</th>
            <th class="text-nowrap">{{ 'senderName' | translate }}</th>
            <th class="text-nowrap">{{ 'senderMobile' | translate }}</th>
            <th class="text-nowrap">{{ 'dateSend' | translate }}</th>
            <th class="text-nowrap">{{ 'currencyCode' | translate }}</th>
            <th class="text-nowrap">{{ 'amount' | translate }}</th>
            <th class="text-nowrap">{{ 'receiverName' | translate }}</th>
            <th class="text-nowrap">{{ 'receiverMobile' | translate }}</th>
            <th class="text-nowrap">{{ 'status' | translate }}</th>
            <th class="text-nowrap">{{ 'complianceStatus' | translate }}</th>
            <th class="text-nowrap">{{ 'description' | translate }}</th>
          </tr>
          <tr *ngFor="let remittance of data.data">
            <td class="text-nowrap">{{ remittance.remittanceCode }}</td>
            <td class="text-nowrap">{{ remittance.remittanceId }}</td>
            <td class="text-nowrap">{{ remittance.senderBranch }}</td>
            <td class="text-nowrap">{{ remittance.senderName }}</td>
            <td class="text-nowrap">{{ remittance.senderMobile }}</td>
            <td class="text-nowrap">{{ remittance.dateSent }}</td>
            <td class="text-nowrap">{{ remittance.currencyCode }}</td>
            <td class="text-nowrap">{{ remittance.amount | number:'1.2-2'  }}</td>
            <td class="text-nowrap">{{ remittance.receiverName }}</td>
            <td class="text-nowrap">{{ remittance.receiverMobile }}</td>
            <td class="text-nowrap">{{ remittance.remittanceState }}</td>
            <td class="text-nowrap">{{ remittance.complianceStatus }}</td>
            <td class="text-nowrap">{{ remittance.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="justify-content-right">
  <button mat-button mat-dialog-close="close">{{ 'close' | translate }}</button>
  <button
    mat-button
    class="btn btn-outline-primary"
    [useExistingCss]="true"
    printSectionId="report"
    ngxPrint
  >
    {{ 'print' | translate }}
  </button>
  <button mat-button class="btn btn-outline-info" (click)="exportToExcel()">
    {{ 'export' | translate }}
  </button>
</div>
